import { createContext } from 'react'

import { initialState } from '@/store/createVault/createVault.state'
import { CreateVaultContextProps } from '@/store/createVault/createVault.types'

export const CreateVaultContext = createContext<CreateVaultContextProps>({
  ...initialState,
  setCurrentStep: () => undefined,
  setSelectedRiskModel: () => undefined,
  setVaultName: () => undefined,
  setSvtSymbol: () => undefined,
  setIsSvtSymbolValid: () => undefined,
  setIsVaultNameValid: () => undefined,
  setSelectedMarket: () => undefined,
  setSelectedStrategies: () => undefined,
  setSelectedAssetGroupId: () => undefined,
  setSelectedManagementFee: () => undefined,
  setSelectedPerformanceFee: () => undefined,
  setSelectedAllocationProvider: () => undefined,
  setSelectedRiskScore: () => undefined,
  setStepCompletedStatus: () => undefined,
  setFilterByAssetGroup: () => undefined,
  setFilterBySearchTerm: () => undefined,
  getStepData: () => undefined,
  validateVaultName: () => undefined,
  validateSvtSymbol: () => undefined,
  filterStrategy: () => undefined,
  handleSortClick: () => undefined,
  setGuardsWhitelist: () => undefined,
  setGuardsAssetAmount: () => undefined,
  setGuardsBool: () => undefined,
  setGuardsTime: () => undefined,
  setGuardsConfigured: () => undefined,
  setGuardsChecked: () => undefined,
  setSelectedGuards: () => undefined,
  setActionsChecked: () => undefined,
  setActionsConfigured: () => undefined,
  setActionsData: () => undefined,
  resetAllSelectedFields: () => undefined,
})
