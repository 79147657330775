import { memoize } from 'lodash'

import localeIntegration from '@/locales/en.json'

const flattenLocale = memoize(() => {
  const result: Record<string, unknown> = {}
  function recurse(cur: Record<string, unknown>, prop: string) {
    if (Object(cur) !== cur) {
      result[prop as keyof Record<string, string>] = cur
    } else if (Array.isArray(cur)) {
      const l = cur.length
      for (let i = 0; i < l; i++) recurse(cur[i], prop + '[' + i + ']')
      if (l == 0) result[prop] = []
    } else {
      let isEmpty = true
      for (const p in cur) {
        isEmpty = false
        cur[p]
        recurse(cur[p] as Record<string, unknown>, prop ? prop + '.' + p : p)
      }
      if (isEmpty && prop) result[prop] = {}
    }
  }
  recurse(localeIntegration, '')
  return result as Record<string, string>
})

export default flattenLocale
