import { HeadCell } from '@/types/table'

export const MAX_METAVAULTSELECTION = 8

export const eligibleVasultsHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Vaults',
  },
  {
    id: 'apy',
    numeric: true,
    label: 'APY',
    align: 'right',
  },
  {
    id: 'tvr',
    numeric: true,
    label: 'TVR',
    align: 'right',
  },
]

export const selectedVaultsHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Protocol',
    notSortable: true,
  },
  {
    id: 'apy',
    numeric: true,
    label: '7D APY',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'tvr',
    numeric: true,
    label: 'TVR',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'allocation',
    numeric: true,
    label: 'Allocation',
    align: 'right',
    notSortable: true,
  },
]

export const reviewHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Protocol',
    notSortable: true,
  },
  {
    id: 'allocation',
    numeric: true,
    label: 'Allocation',
    align: 'right',
    notSortable: true,
  },
]

export const METAVAULT_BASE_POINTS = 10000
