import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { lazy, Suspense, useEffect } from 'react'

import useModalState from '@/hooks/context/useModalState'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'

const ConnectWalletModalOptions = lazy(
  () =>
    import(
      '@/components/organisms/ConnectWalletModal/ConnectWalletModalOptions'
    )
)

import useLocale from '@/hooks/context/useLocale'

import ModalBox from '@/components/organisms/Modals/ModalBox'

const ConnectWalletModal: React.FC = () => {
  const t = useLocale()
  const { account } = useWeb3React()

  const { closeModal } = useModalState()

  useEffect(() => {
    closeModal('connectWallet')
  }, [account])

  return (
    <ModalBox id='connectWallet'>
      <ModalBoxOuterContainer
        sx={{
          width: '760px',
          paddingX: 3,
        }}
      >
        <Typography variant='h6'>
          {t('components.organisms.connectWalletModal.title')}
        </Typography>
        <Stack display='flex' gap={2} marginTop={3}>
          <Typography variant='body1'>
            {t('components.organisms.connectWalletModal.descriptionPlease')}
            <br />
            <br />
            {t('components.organisms.connectWalletModal.descriptionWe')}
          </Typography>
          <Grid
            container
            padding={3}
            border='1px solid rgba(0, 0, 0, 0.23)'
            borderRadius='4px'
            gap={2}
            justifyContent='center'
          >
            <Suspense fallback={<CircularProgress />}>
              <ConnectWalletModalOptions />
            </Suspense>
          </Grid>
        </Stack>
        <Box display='flex' justifyContent='flex-end' marginTop={1}>
          <Button
            variant='text'
            color='error'
            onClick={() => closeModal('connectWallet')}
          >
            {t('components.organisms.connectWalletModal.cancel')}
          </Button>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default ConnectWalletModal
