const Telegram = () => {
  return (
    <svg
      width='20'
      height='16'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.84784 10.5451L7.517 15.0122C7.99034 15.0122 8.19534 14.817 8.44118 14.5827L10.6604 12.5467L15.2588 15.7794C16.1022 16.2306 16.6964 15.993 16.9239 15.0346L19.9423 1.45712L19.9431 1.45632C20.2106 0.259523 19.4923 -0.208475 18.6706 0.085124L0.928504 6.60589C-0.282359 7.05709 -0.264025 7.70508 0.722666 7.99868L5.25861 9.35308L15.7947 3.02431C16.2905 2.70911 16.7414 2.88351 16.3705 3.19871L7.84784 10.5451Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Telegram
