import {
  SmartVaultBase,
  SmartVaultSorting,
  SortOrder,
} from '@solidant/spool-v2-fe-lib'
import { memo, useCallback, useMemo, useReducer } from 'react'
import { useSearchParams } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

import {
  initialState,
  SmartVaultsTableContext,
} from '@/store/smartVaultsTable/smartVaultsTable.context'
import smartVaultsTableReducer from '@/store/smartVaultsTable/smartVaultsTable.reducer'
import { SmartVaultsTableActionTypes } from '@/store/smartVaultsTable/smartVaultsTable.types'

import { Routes } from '@/constants/routes'

import { RCNode } from '@/types/index'
import { SmartVaultData } from '@/types/smart-vault'
import { SmartVaultTableSort } from '@/types/table'

export const SmartVaultsTableProvider = memo(({ children }: RCNode) => {
  const { chain } = useConnectionState()

  const routes = useMemo(() => Routes(chain), [chain])

  const [searchParams] = useSearchParams()
  const { setPathWithHistory, setIsVaultsRowClicked } = useUniversalNavigation()

  const [smartVaultsTable, dispatch] = useReducer(smartVaultsTableReducer, {
    ...initialState,
    page: initialState.page,
    limit: initialState.limit,
    marketId: initialState.marketId,
    assetGroupId: initialState.assetGroupId,
    riskModelProviderId: initialState.riskModelProviderId,
    search: initialState.search,
    smartVaultsTableSort: {
      key:
        (searchParams.get('sortKey') as SmartVaultSorting) ||
        initialState.smartVaultsTableSort?.key,
      direction:
        (searchParams.get('sortOrder') as SortOrder) ||
        initialState.smartVaultsTableSort?.direction,
    },
  })

  const updateStateAndUrl = useCallback(
    (actionType: SmartVaultsTableActionTypes, key: string, value: any) => {
      dispatch({ type: actionType, payload: value })
      value ? searchParams.set(key, value.toString()) : searchParams.delete(key)

      if (searchParams.size > 0) {
        setPathWithHistory(`${routes.smartVaults.root.url}?${searchParams}`)
      } else {
        setPathWithHistory(routes.smartVaults.root.url)
      }
    },
    [routes]
  )

  const setPage = (page: number) => {
    updateStateAndUrl(SmartVaultsTableActionTypes.SET_PAGE, 'page', page)
  }

  const setLimit = (limit: number) => {
    updateStateAndUrl(SmartVaultsTableActionTypes.SET_LIMIT, 'limit', limit)
  }

  const setMarketId = (marketId: string) => {
    updateStateAndUrl(
      SmartVaultsTableActionTypes.SET_MARKET,
      'market',
      marketId
    )
  }

  const setAssetGroupId = (assetGroupId: string) => {
    updateStateAndUrl(
      SmartVaultsTableActionTypes.SET_ASSET_GROUP,
      'assetGroup',
      assetGroupId
    )
    updateStateAndUrl(SmartVaultsTableActionTypes.SET_PAGE, 'page', 0)
  }

  const setRiskModelProviderId = (riskModelProviderId: string) => {
    updateStateAndUrl(
      SmartVaultsTableActionTypes.SET_RISK_MODEL_PROVIDER,
      'riskModelProvider',
      riskModelProviderId
    )
  }

  const setSearch = (search: string) => {
    updateStateAndUrl(SmartVaultsTableActionTypes.SET_SEARCH, 'search', search)
  }

  const handleSmartVaultsTableSort = (
    key: SmartVaultSorting,
    sortType: SmartVaultTableSort
  ) => {
    if (!sortType?.key || sortType?.key !== key) {
      dispatch({
        type: SmartVaultsTableActionTypes.SET_SMART_VAULTS_TABLE_SORT,
        payload: key,
      })
      searchParams.set('sortKey', key)
    }

    if (!sortType?.direction || sortType?.key === key) {
      dispatch({
        type: SmartVaultsTableActionTypes.SET_SMART_VAULTS_TABLE_SORT_DIRECTION,
        payload:
          sortType.direction === SortOrder.DESC
            ? SortOrder.ASC
            : SortOrder.DESC,
      })
      searchParams.set(
        'sortOrder',
        sortType.direction === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
      )
    }

    if (searchParams.size > 0) {
      setPathWithHistory(
        `${Routes(chain).smartVaults.root.url}?${searchParams}`
      )
    } else {
      setPathWithHistory(Routes(chain).smartVaults.root.url)
    }
  }

  const setSelectedVault = (vault: SmartVaultBase | SmartVaultData) => {
    dispatch({
      type: SmartVaultsTableActionTypes.SET_SELECTED_VAULT,
      payload: vault,
    })

    if (location.pathname.includes(Routes(chain).smartVaults.root.url)) {
      if (vault?.address) {
        setIsVaultsRowClicked(true)
      } else if (searchParams.size > 0) {
        setPathWithHistory(
          `${Routes(chain).smartVaults.root.url}?${searchParams}`
        )
      } else {
        // setPathWithHistory(Routes.smartVaults.root.url)
      }
    }
  }

  const setSelectedVaultId = (selectedVaultId: string | undefined) => {
    dispatch({
      type: SmartVaultsTableActionTypes.SET_SELECTED_VAULT_ID,
      payload: selectedVaultId,
    })
  }

  const setRewardsClaimedVault = (rewardsClaimedVault: string) => {
    dispatch({
      type: SmartVaultsTableActionTypes.SET_REWARDS_CLAIMED_VAULT,
      payload: rewardsClaimedVault,
    })
  }

  const setNextRewardsSyncTime = (nextRewardsSyncTime: number) => {
    dispatch({
      type: SmartVaultsTableActionTypes.SET_NEXT_REWARDS_SYNC_TIME,
      payload: nextRewardsSyncTime,
    })
  }

  const contextValue = useMemo(() => {
    return {
      ...smartVaultsTable,
      setPage,
      setLimit,
      setMarketId,
      setAssetGroupId,
      setRiskModelProviderId,
      setSearch,
      handleSmartVaultsTableSort,
      setSelectedVault,
      setSelectedVaultId,
      setRewardsClaimedVault,
      setNextRewardsSyncTime,
    }
  }, [
    chain,
    smartVaultsTable.page,
    smartVaultsTable.limit,
    smartVaultsTable.marketId,
    smartVaultsTable.assetGroupId,
    smartVaultsTable.riskModelProviderId,
    smartVaultsTable.search,
    smartVaultsTable.smartVaultsTableSort,
    smartVaultsTable.selectedVault,
    smartVaultsTable.selectedVaultId,
    smartVaultsTable.rewardsClaimedVault,
    smartVaultsTable.nextRewardsSyncTime,
  ])

  return (
    <SmartVaultsTableContext.Provider value={contextValue}>
      {children}
    </SmartVaultsTableContext.Provider>
  )
})
