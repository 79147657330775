import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography } from '@mui/material'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useCreateVault from '@/hooks/createVault/useCreateVault'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

const SPOOL_LABS_RISK_MODEL = [
  '0xc216ad6280f4fa92a5159ef383a1206d432481c8',
  '0x2f75193ebef14541266696cd87dd84ff90c02b5c',
]

const OneStrategyModal: React.FC = () => {
  const t = useLocale()
  const { closeModal } = useModalState()
  const handleCreateVault = useCreateVault()
  const { selectedRiskModel } = useCreateVaultState()

  return (
    <ModalBox id='oneStrategyModal'>
      <ModalBoxOuterContainer sx={{ width: '880px', paddingBottom: '30px' }}>
        <Box display='flex' justifyContent='center' flexDirection='column'>
          <Box display='flex' flexDirection='row-reverse'>
            <Box onClick={() => closeModal(Modals.ONE_STRATEGY_MODAL)}>
              <CloseIcon
                color='primary'
                sx={{ cursor: 'pointer', width: '35px', height: '35px' }}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='alertTitle' textAlign='center'>
              {t('components.organisms.oneStrategyModal.spoolLabsWarning')}
            </Typography>
            {!SPOOL_LABS_RISK_MODEL.includes(selectedRiskModel) && (
              <Typography variant='alertTitle' textAlign='center'>
                {t(
                  'components.organisms.oneStrategyModal.otherProviderWarning'
                )}
              </Typography>
            )}
            <Button
              sx={{ mt: '0.5rem' }}
              variant='contained'
              onClick={() => {
                closeModal(Modals.ONE_STRATEGY_MODAL)
                handleCreateVault()
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default OneStrategyModal
