import { Box, Typography } from '@mui/material'
import { StrategyListType } from '@solidant/spool-v2-fe-lib'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'

import getLocale from '@/locales/getLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { currency } from '@/constants/currency'
import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'

interface StrategiesMobileRowProps {
  row: StrategyListType
}

const StrategiesMobileRow: React.FC<StrategiesMobileRowProps> = ({ row }) => {
  const t = getLocale()

  const { chain } = useConnectionState()

  const { address, name, assetGroup, balanceUSD, apy } = row

  const navigate = useNavigate()

  const onRowClick = () => {
    navigate(`${Routes(chain).strategies.root.url}/${address}`)
  }
  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='0.5rem'
        onClick={onRowClick}
        mb='1rem'
      >
        <Box display='flex' alignItems='center'>
          <Box>
            <AssetGroupTokens assetGroup={assetGroup} />
          </Box>
          <Box display='flex' justifyContent='space-between' pl='0.5rem'>
            <NameCellContent>
              <SmartVaultNameTableTypography variant='body2'>
                {name}
              </SmartVaultNameTableTypography>
            </NameCellContent>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' mt='1.5rem'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.7dapy')}
          </Typography>
          <Typography fontSize='14px'>{formatUSD(apy)} %</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' mt='1rem'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.tvr')}
          </Typography>
          <Typography fontSize='14px'>
            {currency} {formatUSD(balanceUSD)}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default memo(StrategiesMobileRow)
