import { Box, TextField, Typography } from '@mui/material'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import SectionColumn from '@/components/molecules/SectionColumn'

const NameConfiguration = () => {
  const {
    vaultName,
    svtSymbol,
    isVaultNameValid,
    isSvtSymbolValid,
    setVaultName,
    setSvtSymbol,
    setIsSvtSymbolValid,
    setIsVaultNameValid,
  } = useCreateVaultState()

  const t = useLocale()

  const handleVaultNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setIsVaultNameValid(true)
    setVaultName(event.target.value as string)
  }

  const handleSvtSymbolChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setIsSvtSymbolValid(true)
    setSvtSymbol(event.target.value as string)
  }

  return (
    <SectionColumn
      title={t(
        'components.organisms.createVault.launch.nameConfiguration.title'
      )}
      sx={{ gap: 0 }}
    >
      <Typography variant='subtitle1'>
        {t(
          'components.organisms.createVault.launch.nameConfiguration.description'
        )}
      </Typography>
      <TextField
        fullWidth
        label={t(
          'components.organisms.createVault.launch.nameConfiguration.textField.label'
        )}
        sx={{ fontWeight: 500 }}
        onChange={handleVaultNameChange}
        value={vaultName}
        error={!isVaultNameValid}
        autoFocus
        helperText={
          !isVaultNameValid &&
          t(
            'components.organisms.createVault.launch.nameConfiguration.textField.helperText'
          )
        }
      />
      <Typography variant='subtitle1'>
        {t(
          'components.organisms.createVault.launch.dnftConfiguration.description'
        )}
        <br />
        {t(
          'components.organisms.createVault.launch.dnftConfiguration.disclaimer'
        )}
      </Typography>
      <Box>
        <TextField
          fullWidth
          label={t(
            'components.organisms.createVault.launch.dnftConfiguration.textField.label'
          )}
          sx={{ fontWeight: 500 }}
          onChange={handleSvtSymbolChange}
          value={svtSymbol}
          error={!isSvtSymbolValid}
          helperText={
            !isSvtSymbolValid
              ? t(
                  'components.organisms.createVault.launch.dnftConfiguration.textField.helperText'
                )
              : t(
                  'components.organisms.createVault.launch.dnftConfiguration.optional'
                )
          }
        />
      </Box>
    </SectionColumn>
  )
}

export default NameConfiguration
