import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import WarningIcon from '@mui/icons-material/Warning'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'

import getLocale from '@/locales/getLocale'

import { TransactionStatus } from '@/store/modal/modals.types'

const t = getLocale()

const icon = {
  [TransactionStatus.Success]: {
    title: t('components.organisms.actionModal.config.success.title'),
    icon: (
      <CheckCircleIcon
        sx={{ width: '100%', height: '100%', color: 'success.main' }}
      />
    ),
  },
  [TransactionStatus.Failure]: {
    title: t('components.organisms.actionModal.config.failure.title'),
    icon: (
      <ErrorIcon sx={{ width: '100%', height: '100%', color: 'error.main' }} />
    ),
  },
  [TransactionStatus.Processing]: {
    title: t('components.organisms.actionModal.config.processing.title'),
    icon: <CircularProgress className='hook-loading' size='100%' />,
  },
  [TransactionStatus.PendingSignature]: {
    title: t('components.organisms.actionModal.config.processing.title'),
    icon: <CircularProgress className='hook-loading' size='100%' />,
  },
}

export const mapTransactionStatus = (key: TransactionStatus) => {
  if (!icon[key]) {
    // Just in case there is an unknown status
    return {
      title: 'Unknown status',
      icon: (
        <Tooltip title='Unknown status' arrow placement='left'>
          <IconButton sx={{ padding: '1px' }} disableRipple>
            <WarningIcon className='lg' sx={{ color: 'primary.main' }} />
          </IconButton>
        </Tooltip>
      ),
    }
  }
  return icon[key]
}
