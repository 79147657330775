import WarningIcon from '@mui/icons-material/Warning'
import {
  Box,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@mui/material'
import { StrategyListType } from '@solidant/spool-v2-fe-lib'
import { useEffect } from 'react'

import useStrategiesState from '@/hooks/context/useStrategiesState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useStrategies from '@/hooks/strategies/useStrategies'

import DataMissing from '@/components/molecules/DataMissing'
import TableWrapper from '@/components/molecules/TableWrapper'
import StrategiesTableRow from '@/components/organisms/Strategies/StrategiesTableRow'

import StrategiesMobileRow from '@/pages/Strategies/StrategiesMobileRow'

import { strategiesHeadCells } from '@/constants/strategies'
import { sort } from '@/utils/table'

export const HIDDEN_STRATEGIES: string[] = [
  '0xb5d158dc42b329214f7ecc020f715f93501a8f9e',
  '0xa51c0a9f2d688c8943f029fec00a3c9193aadf81',
  '0xb7364af55b4a0393d070aeeb2d8a6468f82e178e',
]

interface StrategiesTableProps {
  strategiesList: StrategyListType[]
  setStrategiesList: React.Dispatch<React.SetStateAction<StrategyListType[]>>
}

const StrategiesTable: React.FC<StrategiesTableProps> = ({
  strategiesList,
  setStrategiesList,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const {
    strategiesSort,
    strategiesPage,
    strategiesLimit,
    filterByAssetGroup,
    filterBySearchTerm,
    setStrategiesPage,
    setStrategiesLimit,
    handleSortClick,
    filterStrategy,
  } = useStrategiesState()

  const { strategies } = useStrategies()

  useEffect(
    () =>
      setStrategiesList(
        strategies
          .filter(({ address }) => !HIDDEN_STRATEGIES.includes(address))
          .filter((strategy) => filterStrategy(strategy))
      ),
    [filterByAssetGroup, filterBySearchTerm, strategies]
  )

  const handleChangePage = (_: unknown, newPage: number) => {
    setStrategiesPage(newPage)
  }

  return (
    <Box
      sx={{
        border: !belowPhabletBreakpoint && '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        mb: (theme) => theme.spacing(1),
        mt: '1rem',
      }}
    >
      {!belowPhabletBreakpoint ? (
        <TableWrapper
          header={strategiesHeadCells}
          headerClass='default-table-head'
          pagination={
            strategies?.length > 9 && {
              rowsPerPageOptions: [10, 20, 30],
              page: strategiesPage,
              rowsPerPage: strategiesLimit,
              count: strategiesList?.length || 0,
              setPage: setStrategiesPage,
              setRowsPerPage: setStrategiesLimit,
            }
          }
          handleSortClick={handleSortClick}
          sortType={strategiesSort}
          tokens
          whiteFooter
        >
          {strategiesList?.length ? (
            <TableBody>
              {strategiesList
                .sort((a, b) => sort(a, b, strategiesSort))
                .slice(
                  strategiesPage * strategiesLimit,
                  (strategiesPage + 1) * strategiesLimit
                )
                .map((strategy) => (
                  <StrategiesTableRow key={strategy.address} row={strategy} />
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    my='2rem'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <WarningIcon
                      sx={{ height: '90px', width: '90px' }}
                      color='primary'
                    />
                    <DataMissing text='Strategies not found...' />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </TableWrapper>
      ) : strategiesList?.length ? (
        strategiesList
          .sort((a, b) => sort(a, b, strategiesSort))
          .slice(
            strategiesPage * strategiesLimit,
            (strategiesPage + 1) * strategiesLimit
          )
          .map((strategy) => (
            <StrategiesMobileRow key={strategy.address} row={strategy} />
          ))
      ) : (
        <Box
          my='2rem'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <WarningIcon sx={{ height: '90px', width: '90px' }} color='primary' />
          <DataMissing text='Strategies not found...' />
        </Box>
      )}
      {belowPhabletBreakpoint && (
        <TablePagination
          rowsPerPageOptions={[]}
          component='div'
          count={strategiesList?.length || 0}
          rowsPerPage={strategiesLimit}
          page={strategiesPage}
          onPageChange={handleChangePage}
        />
      )}
    </Box>
  )
}

export default StrategiesTable
