import { HistoricalWithdawal } from '@solidant/spool-v2-fe-lib'

import { CreatorVault } from '@/types/dashboard'

export enum TransactionStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Processing = 'PROCESSING',
  PendingSignature = 'PENDING_SIGNATURE',
}

export enum TransactionType {
  Approve = 'APPROVE',
  MultiTokenApproval = 'MULTI_TOKEN_APPROVAL',
  Withdraw = 'WITHDRAW',
  FastWithdraw = 'FAST_WITHDRAW',
  Deposit = 'DEPOSIT',
  ClaimWithdraw = 'CLAIM_WITHDRAW',
  SwapAndDeposit = 'SWAP_AND_DEPOSIT',
  CreateVault = 'CREATE_VAULT',
  CreateMetavault = 'CREATE_METAVAULT',
  UpdateMetavaultAllocations = 'UPDATE_METAVAULT_ALLOCATIONS',
  ManageMetavaultVaults = 'MANAGE_METAVAULT_VAULTS',
  Stake = 'STAKE',
  Unstake = 'UNSTAKE',
  ClaimToken = 'CLAIM',
  ClaimAndStakeToken = 'CLAIM_AND_STAKE',
  IncentivizeToken = 'INCENTIVIZE_TOKEN',
  ReincentivizeToken = 'REINCENTIVIZE_TOKEN',
  GovernanceVote = 'GOVERNANCE_VOTE',
  FutureApy = 'FUTURE_APY',
  ClaimSvts = 'CLAIM_SVTS',
}

export type IncentivizeVaultModalType = {
  vault: CreatorVault
}

export type WithdrawModalType = {
  vault: CreatorVault
}

export type ActionModalType = {
  transactionStatus: TransactionStatus
  transactionType: TransactionType
  txHash?: string
}

export type WarningModalType = {
  message: string
  isActionRequired?: boolean
  action?: () => void
}

export type ReceiptsModalType = {
  receipt: HistoricalWithdawal
}

export type MetaVaultModalType = {
  metavault: CreatorVault
}

export type MetavaultAddVaultsModalType = {
  metavaultId: string
  assetId: string
}

export type ConfirmationModalType = {
  message: string
  action: () => void
}

export type ModalType = {
  actionModalType?: ActionModalType
  warningModalType?: WarningModalType
  receiptsModalType?: ReceiptsModalType
  incentivizeVaultModalType?: IncentivizeVaultModalType
  withdrawModalType?: IncentivizeVaultModalType
  metavaultModalType?: MetaVaultModalType
  metavaultAddVaultsModalType?: MetavaultAddVaultsModalType
  confirmationModalType?: ConfirmationModalType
}

export enum Modals {
  BASIC = 'basic',
  CONNECT_WALLET = 'connectWallet',
  ACTION_MODAL = 'actionModal',
  WARNING_MODAL = 'warningModal',
  DETECT_SMALL_SCREEN_MODAL = 'detectSmallScreenModal',
  MAINTENANCE_MODAL = 'maintenanceModal',
  WELCOME_MODAL = 'welcomeModal',
  ONE_STRATEGY_MODAL = 'oneStrategyModal',
  RECEIPTS_MODAL = 'receiptsModal',
  INCENTIVIZE_VAULT_MODAL = 'incentivizeVaultModal',
  WITHDRAW_MODAL = 'withdrawModal',
  METAVAULT_MODAL = 'metavaultModal',
  METAVAULT_ADD_VAULTS_MODAL = 'metavaultAddVaultsModal',
  CONFIRMATION_MODAL = 'confirmationModal',
}

export enum ModalsActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SET_MODAL_TYPE = 'SET_MODAL_TYPE',
  MINIMIZE_MODAL = 'MINIMIZE_MODAL',
}

export type ModalsAction =
  | { type: ModalsActionTypes.OPEN_MODAL; payload: string }
  | { type: ModalsActionTypes.CLOSE_MODAL; payload: string }
  | { type: ModalsActionTypes.SET_MODAL_TYPE; payload: ModalType }
  | { type: ModalsActionTypes.MINIMIZE_MODAL; payload: string }

export type ModalState = {
  isOpen: boolean
  isMinimized?: boolean
  modalType?: ModalType
}

export interface ModalContext {
  openModal: (id: Modals) => void
  closeModal: (id: Modals | string) => void
  setModalType: (modalType: ModalType) => void
  minimizeModal: (id: Modals) => void
}
