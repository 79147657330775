import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useStakedSYLay from '@/hooks/staking/useStakedSpool'

import SectionItem from '@/components/molecules/SectionItem'

import { Token } from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

const SpoolStaked = () => {
  const t = useLocale()

  const { account } = useWeb3React()

  const { stakedYLay, isLoading } = useStakedSYLay(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.overview.sectionItems.stakedSpoolTokens.title'
      )}
      sx={{ width: '100%' }}
    >
      <Box display='flex' alignItems='baseline'>
        <Typography variant='h6'>
          {!isLoading ? formatUSD(stakedYLay) : '0'}
        </Typography>
        <Typography variant='body1'>&nbsp;{Token.YLAY}</Typography>
      </Box>
      {/* <AmountTextUSD amount={stakedYLay ?? '0'} /> */}
    </SectionItem>
  )
}

export default SpoolStaked
