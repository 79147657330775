import FORBIDDEN_WORDS from '@/constants/filters/forbiddenWords.json'
import WHITELIST_WORDS from '@/constants/filters/whitelistWords.json'

const isProfane = (text: string) => {
  for (const word of FORBIDDEN_WORDS.words) {
    const wordExp = new RegExp(`\\b${word.replace(/(\W)/g, '\\$1')}\\b`, 'gi')
    const customBoundaryExp = new RegExp(
      `(^|[^a-zA-Z])${word.replace(/(\W)/g, '\\$1')}([^a-zA-Z]|$)`,
      'gi'
    )

    // Word matched in the blacklist
    if (wordExp.test(text) || customBoundaryExp.test(text)) {
      // Word also matched in the whitelist
      for (const whitelistWord of WHITELIST_WORDS.words) {
        const whitelistWordExp = new RegExp(
          `\\b${whitelistWord.replace(/(\W)/g, '\\$1')}\\b`,
          'gi'
        )
        if (whitelistWordExp.test(text)) {
          // It's a blacklisted word but also whitelisted
          return false
        }
      }
      // It's a blacklisted word and not whitelisted
      return true
    }
  }
  // Didn't match any blacklisted words
  return false
}

export default isProfane
