import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { tokenDetails } from '@/components/atoms/Tokens'
import SectionColumn from '@/components/molecules/SectionColumn'
import DashboardLineChart from '@/components/organisms/Charts/DashboardLineChart'
import DashboardPieChart from '@/components/organisms/Charts/DashboardPieChart'
import InvestorPieChartLegend from '@/components/organisms/Dashboard/Investor/InvestorPieChartLegend'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'
import { calculateCreatorAssetAllocation } from '@/utils/helpers/calculateAssetAllocation'
import { calculateDashboardStrategyAllocation } from '@/utils/helpers/calculateStrategyAllocation'

const CreatorAllocations = () => {
  const t = useLocale()

  const { belowTabletBreakpoint, belowPhabletBreakpoint } =
    useBreakpointDetection()

  const { tvrChartValues } = useCreatorDashboardState()

  const { chain } = useConnectionState()

  const { vaults, metrics } = useCreatorDashboard()
  const { totalTvr } = metrics

  const assetAllocation = useMemo(
    () => calculateCreatorAssetAllocation(vaults ?? []),
    [vaults]
  )

  const strategyAllocation = useMemo(
    () => calculateDashboardStrategyAllocation(vaults),
    [vaults]
  )

  const sumAssets = Object.values(assetAllocation).reduce(
    (acc: number, allocation) => acc + allocation,
    0
  )

  const sumStrategies = Object.values(strategyAllocation).reduce(
    (acc: number, allocation) => acc + +allocation,
    0
  )

  return (
    <SectionColumn
      title={t(
        'components.organisms.dashboard.investor.investorAllocations.vaultsAllocation.sectionTitle'
      )}
      sx={{ px: 0, pb: '4rem' }}
    >
      <Box
        border='1px solid rgba(8, 8, 8, 0.12)'
        padding='2rem'
        display='flex'
        flexDirection='column'
      >
        <Box display='flex' gap={0.5} alignItems='start' pb='2rem'>
          <InfoOutlinedIcon sx={{ color: 'text.secondary' }} fontSize='small' />
          <Typography variant='body2' fontSize='12px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.investor.investorMetrics.summary.chart.title'
            )}
          </Typography>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          sx={{ height: '100%', mb: 1, pb: '2rem' }}
        >
          <Box flexGrow={1} pb={2}>
            <Typography variant='body2' color='gray'>
              {t(
                'components.organisms.dashboard.creator.creatorMetrics.tvrOfVaultsOverTime'
              )}
            </Typography>
            <Typography variant='h6' fontWeight={500}>
              {' '}
              {currency} {formatUSD(totalTvr)}
            </Typography>
          </Box>
          <DashboardLineChart
            data={[
              {
                id: 'Deposit Balance',
                color: '#039BE5',
                data: tvrChartValues,
              },
            ]}
            xTickLines={0}
          />
        </Box>
        {!!sumAssets && (
          <Box
            display='flex'
            justifyContent='space-between'
            pt='2rem'
            borderTop='1px solid rgba(8, 8, 8, 0.12)'
            flexDirection={belowTabletBreakpoint ? 'column' : 'row'}
          >
            <SectionColumn
              title={t(
                'components.organisms.dashboard.investor.investorAllocations.yourAssetAllocation.sectionTitle'
              )}
              noPadding
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: (theme) => theme.spacing(1),
                borderBottomLeftRadius: (theme) => theme.spacing(1),
                borderBottomRightRadius: (theme) => theme.spacing(1),
                mb: '2rem',
                maxWidth: belowPhabletBreakpoint ? '100%' : '50%',
              }}
            >
              <Box
                display='flex'
                gap={3}
                alignContent='center'
                flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
              >
                <DashboardPieChart assets={assetAllocation} format='vaults' />
                <InvestorPieChartLegend
                  allocationData={assetAllocation}
                  sum={sumAssets}
                  formatLabel={(tokenAddress) =>
                    tokenDetails[chain][tokenAddress].symbol
                  }
                  gridTemplateColumns='1fr 1fr 1fr'
                />
              </Box>
            </SectionColumn>
            <SectionColumn
              noPadding
              sx={{
                bgcolor: 'white',
                borderTopRightRadius: (theme) => theme.spacing(1),
                borderBottomRightRadius: (theme) => theme.spacing(1),
                mb: '2rem',
              }}
              title={t(
                'components.organisms.dashboard.investor.investorCharts.balance.sectionTitle'
              )}
            >
              <Box
                display='flex'
                gap={3}
                alignContent='center'
                flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
              >
                <DashboardPieChart
                  assets={strategyAllocation}
                  format='strategies'
                />
                <InvestorPieChartLegend
                  allocationData={strategyAllocation}
                  sum={sumStrategies}
                  formatLabel={(strategy) => strategy}
                  gridTemplateColumns='3fr 0.5fr 2fr'
                />
              </Box>
            </SectionColumn>
          </Box>
        )}
      </Box>
    </SectionColumn>
  )
}

export default CreatorAllocations
