import { formatUnits, parseEther } from '@ethersproject/units'
import { Box, Button, Stack, SxProps, TextField, Theme } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'

import useStakedSYLay from '@/hooks/staking/useStakedSpool'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import { DEFAULT_INPUT_AMOUNT } from '@/constants'

interface StakingTransactionInputProps {
  inputLabel: string
  tokenId: string
  value: string
  page: string
  setValue: (value: any) => void
  sx?: SxProps<Theme>
}

const StakingTransactionInput: React.FC<StakingTransactionInputProps> = ({
  inputLabel,
  tokenId,
  value,
  page,
  sx,
  setValue,
}) => {
  const { account } = useWeb3React()

  const [balance, setBalance] = useState(BigNumber.from('0'))

  const { stakedYLay } = useStakedSYLay(account)

  // to get token related data if there is tokenId or if tokenId is a valid address
  const { decimals } = useTokenDetails(tokenId)

  const { balance: userBalance } = useUserBalance(tokenId)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (!account) return

    if (
      value !== '.' &&
      +value < Number.MAX_SAFE_INTEGER &&
      value.match('^[0-9]*[.]?[0-9]*$')
    ) {
      setValue(value)
    }
  }

  useEffect(() => {
    setBalance(page === 'unstake' ? parseEther(stakedYLay ?? '0') : userBalance)
  }, [page, stakedYLay, userBalance])

  return (
    <Stack flexGrow={1}>
      <Box
        display='flex'
        flexGrow='1'
        gap={(theme) => theme.spacing(1)}
        sx={{
          ...sx,
        }}
      >
        <TextField
          size='small'
          label={inputLabel}
          onChange={handleChange}
          value={value}
          placeholder={DEFAULT_INPUT_AMOUNT}
          error={!!value && parseEther(value).gt(balance)}
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-root': {
              backgroundColor: '#fff',
              fontWeight: 500,
            },
          }}
        />
        <Button
          variant='outlined'
          disabled={!account}
          onClick={() => {
            setValue(formatUnits(balance, decimals))
          }}
          sx={{ width: '48px' }}
        >
          Max
        </Button>
      </Box>
    </Stack>
  )
}

export default StakingTransactionInput
