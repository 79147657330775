import { InvestorSmartVault } from '@solidant/spool-v2-fe-lib'

const useImportDNfts = () => {
  const importDNftsOnClick = async (
    address: string,
    dNfts: InvestorSmartVault['dNfts']
  ) => {
    const unburnedNfts = dNfts
      .filter((dNft) => !dNft.isBurned)
      .map((dNft) => ({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC1155',
          options: {
            address: address,
            tokenId: dNft.nftId,
          },
        },
      }))
    try {
      await (window.ethereum as any).sendAsync(unburnedNfts)
    } catch (error) {
      console.error(error)
    }
  }

  return { importDNftsOnClick }
}

export default useImportDNfts
