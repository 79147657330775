import { SortOrder } from '@solidant/spool-v2-fe-lib'

import {
  CreateVaultAction,
  CreateVaultActionTypes,
  CreateVaultState,
} from '@/store/createVault/createVault.types'

const createVaultReducer = (
  state: CreateVaultState,
  action: CreateVaultAction
): CreateVaultState => {
  switch (action.type) {
    case CreateVaultActionTypes.SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload }
    case CreateVaultActionTypes.SET_STEP_COMPLETED:
      return {
        ...state,
        steps: state.steps.map((step, index) => {
          return action.payload.stepNumber === index + 1
            ? { ...step, completed: action.payload.status }
            : step
        }),
      }
    case CreateVaultActionTypes.SET_SELECTED_RISK_MODEL:
      return { ...state, selectedRiskModel: action.payload }
    case CreateVaultActionTypes.SET_VAULT_NAME:
      return { ...state, vaultName: action.payload }
    case CreateVaultActionTypes.SET_SVT_SYMBOL:
      return { ...state, svtSymbol: action.payload }
    case CreateVaultActionTypes.SET_IS_SVT_SYMBOL_VALID:
      return { ...state, isSvtSymbolValid: action.payload }
    case CreateVaultActionTypes.SET_IS_VAULT_NAME_VALID:
      return { ...state, isVaultNameValid: action.payload }
    case CreateVaultActionTypes.SET_SELECTED_STRATEGIES:
      return { ...state, selectedStrategies: action.payload }
    case CreateVaultActionTypes.SET_SELECTED_ASSET_GROUP_ID:
      return { ...state, selectedAssetGroupId: action.payload }
    case CreateVaultActionTypes.SET_SELECTED_PERFORMANCE_FEE:
      return { ...state, selectedPerformanceFee: action.payload }
    case CreateVaultActionTypes.SET_SELECTED_MANAGEMENT_FEE:
      return { ...state, selectedManagementFee: action.payload }
    case CreateVaultActionTypes.SET_SELECTED_ALLOCATION_PROVIDER:
      return { ...state, selectedAllocationProvider: action.payload }
    case CreateVaultActionTypes.SET_SELECTED_RISK_SCORE:
      return { ...state, selectedRiskScore: action.payload }
    case CreateVaultActionTypes.SET_FILTER_BY_ASSET_GROUP:
      return { ...state, filterByAssetGroup: action.payload }
    case CreateVaultActionTypes.SET_FILTER_BY_SEARCH_TERM:
      return { ...state, filterBySearchTerm: action.payload }
    case CreateVaultActionTypes.CHANGE_STRATEGY_SORT_DIRECTION:
      return {
        ...state,
        strategySort: {
          ...state.strategySort,
          direction:
            state.strategySort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case CreateVaultActionTypes.SET_STRATEGY_SORT:
      return {
        ...state,
        strategySort: action.payload,
      }
    case CreateVaultActionTypes.SET_GUARDS_ASSET_AMOUNT:
      return {
        ...state,
        selectedGuards: {
          ...state.selectedGuards,
          [action.id]: {
            ...state.selectedGuards[action.id],
            assetAmount: action.payload.assetAmount,
            conditions: action.payload.conditions,
          },
        },
      }
    case CreateVaultActionTypes.SET_GUARDS_WHITELIST:
      return {
        ...state,
        selectedGuards: {
          ...state.selectedGuards,
          [action.id]: {
            ...state.selectedGuards[action.id],
            whitelist: action.payload.whitelist,
            conditions: action.payload.conditions,
          },
        },
      }
    case CreateVaultActionTypes.SET_GUARDS_BOOL:
      return {
        ...state,
        selectedGuards: {
          ...state.selectedGuards,
          [action.id]: {
            ...state.selectedGuards[action.id],
            bool: action.payload.bool,
            conditions: action.payload.conditions,
          },
        },
      }
    case CreateVaultActionTypes.SET_GUARDS_TIME:
      return {
        ...state,
        selectedGuards: {
          ...state.selectedGuards,
          [action.id]: {
            ...state.selectedGuards[action.id],
            time: action.payload.time,
            conditions: action.payload.conditions,
          },
        },
      }
    case CreateVaultActionTypes.SET_GUARDS_CONFIGURED:
      return {
        ...state,
        selectedGuards: {
          ...state.selectedGuards,
          [action.id]: {
            ...state.selectedGuards[action.id],
            configured: action.payload,
          },
        },
      }
    case CreateVaultActionTypes.SET_GUARDS_CHECKED:
      return {
        ...state,
        selectedGuards: {
          ...state.selectedGuards,
          [action.id]: {
            ...state.selectedGuards[action.id],
            checked: action.payload.checked,
            title: action.payload.title,
            type: action.payload.type,
          },
        },
      }
    case CreateVaultActionTypes.SET_ACTIONS_CHECKED:
      return {
        ...state,
        selectedActions: {
          ...state.selectedActions,
          [action.id]: {
            ...state.selectedActions[action.id],
            checked: action.payload.checked,
            title: action.payload.title,
            type: action.payload.type,
          },
        },
      }
    case CreateVaultActionTypes.SET_ACTIONS_CONFIGURED:
      return {
        ...state,
        selectedActions: {
          ...state.selectedActions,
          [action.id]: {
            ...state.selectedActions[action.id],
            configured: action.payload,
          },
        },
      }
    case CreateVaultActionTypes.SET_ACTIONS_DATA:
      return {
        ...state,
        selectedActions: {
          ...state.selectedActions,
          [action.id]: {
            ...state.selectedActions[action.id],
            actionData: action.payload.actionData,
            conditions: action.payload.conditions,
          },
        },
      }
    case CreateVaultActionTypes.SET_SELECTED_GUARDS:
      return { ...state, selectedGuards: action.payload }
    case CreateVaultActionTypes.RESET_SELECTED_GUARDS:
      return {
        ...state,
        selectedGuards: {},
      }
    default:
      return state
  }
}

export default createVaultReducer
