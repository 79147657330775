const Usd = () => {
  return (
    <svg
      width='23'
      height='24'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.1423 30.0576C34.1423 24.9999 31.2335 23.2658 25.416 22.5434C21.2606 21.9652 20.4295 20.8093 20.4295 18.7859C20.4295 16.7625 21.8148 15.4623 24.5849 15.4623C27.0781 15.4623 28.4634 16.3294 29.1558 18.497C29.2944 18.9305 29.71 19.2194 30.1255 19.2194H32.3415C32.8957 19.2194 33.3112 18.7859 33.3112 18.2081V18.0635C32.757 14.8841 30.2638 12.4276 27.0781 12.1387V8.67047C27.0781 8.09232 26.6626 7.65879 25.9701 7.51416H23.8925C23.3383 7.51416 22.9228 7.94769 22.7841 8.67047V11.9941C18.6288 12.5722 15.9972 15.4623 15.9972 19.0752C15.9972 23.844 18.7674 25.7224 24.5849 26.4451C28.4634 27.1676 29.71 28.0346 29.71 30.3469C29.71 32.6592 27.7706 34.2487 25.1391 34.2487C21.5375 34.2487 20.2909 32.6588 19.8754 30.4912C19.7371 29.9134 19.3215 29.6241 18.906 29.6241H16.5511C15.9972 29.6241 15.5817 30.0576 15.5817 30.6358V30.7804C16.1355 34.3929 18.3518 36.9941 22.9228 37.7169V41.1851C22.9228 41.7629 23.3383 42.1964 24.0307 42.3411H26.1084C26.6626 42.3411 27.0781 41.9075 27.2168 41.1851V37.7169C31.3721 36.9941 34.1423 33.9594 34.1423 30.0576Z'
        fill='black'
        fillOpacity='0.87'
      />
    </svg>
  )
}

export default Usd
