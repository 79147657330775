import { KeyboardDoubleArrowRight } from '@mui/icons-material'
import { Box, Drawer, IconButton, Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'

import useTopMenuState from '@/hooks/context/useTopMenuState'

import ConnectWalletModalOptions from '@/components/organisms/ConnectWalletModal/ConnectWalletModalOptions'

const WalletDrawer = () => {
  const theme = useTheme()
  const { isOpen, toggleMenu } = useTopMenuState()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        toggleMenu(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [toggleMenu])

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          overflowX: 'hidden',
          easing: theme.transitions.easing.sharp,
          zIndex: 1002,
          maxWidth: 310,
          border: 'none',
          backgroundColor: 'transparent',
        },
      }}
      variant='persistent'
      anchor='right'
      open={isOpen}
    >
      <Box display='flex' flexGrow={1}>
        <Box
          sx={{
            background: 'transparent',
            cursor: 'pointer',
            pr: 1,
            transition: theme.transitions.create('background-color', {
              duration: theme.transitions.duration.standard,
              easing: theme.transitions.easing.easeIn,
            }),
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '& .MuiIconButton-root': {
              transform: 'translateX(0)',
              transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.short,
                easing: theme.transitions.easing.easeOut,
              }),
            },
            '&:hover .MuiIconButton-root': {
              transform: 'translateX(5px)',
            },
          }}
          onClick={() => toggleMenu(false)}
        >
          <IconButton size='large' disableRipple>
            <KeyboardDoubleArrowRight />
          </IconButton>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.5)',
            overflow: 'hidden',
            px: (theme) => theme.spacing(2),
            width: '300px',
          }}
        >
          <Typography
            variant='alertTitle'
            component={Box}
            sx={{ p: (theme) => theme.spacing(1.5) }}
          >
            Connect wallet
          </Typography>
          <ConnectWalletModalOptions />
        </Box>
      </Box>
    </Drawer>
  )
}

export default WalletDrawer
