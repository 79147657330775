export const isIframe = (): boolean => {
  try {
    return window.self !== window.top
  } catch (error) {
    return false
  }
}

export const isLedgerDappBrowserProvider = (() => {
  let state: boolean | null = null

  return (): boolean => {
    if (typeof state === 'boolean') return state
    if (typeof window === 'undefined') return false

    try {
      const params = new URLSearchParams(window.self.location.search)
      const isEmbed = !!params.get('embed')

      state = isIframe() && isEmbed
    } catch (error) {
      state = false
    }

    return !!state
  }
})()

export const assert = (
  condition: any,
  message: string
): asserts condition is NonNullable<typeof condition> => {
  if (!condition) {
    throw new Error(message)
  }
}
