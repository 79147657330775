import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from '@mui/material'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import getLocale from '@/locales/getLocale'

import TableWrapper from '@/components/molecules/TableWrapper'
import AllocationTableRow from '@/components/organisms/CreateMetavault/Allocation/AllocationTableRow'
import AllocationTableRowMobile from '@/components/organisms/CreateMetavault/Allocation/AllocationTableRowMobile'

import { selectedVaultsHeadCells } from '@/constants/metavaults'

interface AllocationTableProps {
  manage?: boolean
}

const AllocationTable: React.FC<AllocationTableProps> = ({ manage }) => {
  const t = getLocale()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { selectedVaults, selectedVaultsAllocations } =
    useCreateMetavaultState()

  const totalAllocation = selectedVaultsAllocations.reduce(
    (acc, allocation) => {
      return acc + allocation
    },
    0
  )

  return (
    <>
      {!belowPhabletBreakpoint && (
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            marginLeft: !belowPhabletBreakpoint && !manage && '-1px',
            borderRadius: manage ? 2 : 0,
            ...(manage
              ? {}
              : {
                  borderTopRightRadius: 2,
                  borderBottomRightRadius: belowPhabletBreakpoint ? 0 : 2,
                  borderTopLeftRadius: belowPhabletBreakpoint ? 2 : 0,
                }),
            flexGrow: 1,
          }}
        >
          <TableWrapper
            colorLegend={!manage}
            longName={manage}
            header={selectedVaultsHeadCells}
            headerClass='white-table-head'
            headerSX={{ py: 1.5 }}
          >
            {selectedVaults && (
              <TableBody>
                {selectedVaults.map((vault, index) => (
                  <AllocationTableRow
                    key={vault.id}
                    row={vault}
                    index={index}
                    condensed={manage}
                  />
                ))}
              </TableBody>
            )}
            <TableFooter sx={{ borderTop: '1px solid rgba(33, 35, 34, 0.12)' }}>
              <TableRow>
                <TableCell colSpan={2} sx={{ pl: manage ? 2 : 4, py: 2 }}>
                  <Typography
                    variant='tableHeader'
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  >
                    {t(
                      'components.organisms.createMetavault.allocation.table.footer.label'
                    )}
                  </Typography>
                </TableCell>
                <TableCell colSpan={3} align='right'>
                  <Typography
                    variant='tableHeader'
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  >
                    {totalAllocation.toString()}%
                  </Typography>
                  {totalAllocation > 100 && (
                    <Typography variant='body2' color='error'>
                      {t(
                        'components.organisms.createMetavault.allocation.errors.totalOver'
                      )}
                    </Typography>
                  )}
                  {totalAllocation < 100 && (
                    <Typography variant='body2' color='error'>
                      {t(
                        'components.organisms.createMetavault.allocation.errors.totalBelow'
                      )}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </TableWrapper>
        </Box>
      )}
      {belowPhabletBreakpoint && (
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderTopRightRadius: 1,
            borderTopLeftRadius: 1,
          }}
        >
          {selectedVaults.map((vault, index) => (
            <AllocationTableRowMobile
              key={vault.id}
              row={vault}
              index={index}
              condensed={manage}
            />
          ))}
          <Box
            display='flex'
            flexDirection='column'
            gap={1}
            sx={{ px: 3, py: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='body1'>
                {t(
                  'components.organisms.createMetavault.allocation.table.footer.label'
                )}
              </Typography>
              <Typography variant='body1'>
                {totalAllocation.toString()}%
              </Typography>
            </Box>
            {totalAllocation > 100 && (
              <Typography variant='body2' color='error'>
                {t(
                  'components.organisms.createMetavault.allocation.errors.totalOver'
                )}
              </Typography>
            )}
            {totalAllocation < 100 && (
              <Typography variant='body2' color='error'>
                {t(
                  'components.organisms.createMetavault.allocation.errors.totalBelow'
                )}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default AllocationTable
