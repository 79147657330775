import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

import { Routes } from '@/constants/routes'

const WithdrawSuccess = () => {
  const { chain } = useConnectionState()

  const t = useLocale()
  const { closeModal } = useModalState()
  const navigate = useNavigate()

  const handleTrack = () => {
    navigate(Routes(chain).dashboard.history.url)
    closeModal(Modals.ACTION_MODAL)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={2}
      px={2}
      alignContent='center'
      sx={{ maxWidth: '400px' }}
    >
      <AccessTimeIcon
        sx={{
          alignSelf: 'center',
          width: '48px',
          height: '48px',
          color: 'primary.main',
        }}
      />
      <Typography variant='h5' textAlign='center'>
        {t('components.organisms.modals.withdrawSuccessModal.title')}
      </Typography>
      <Box textAlign='center' sx={{ lineHeight: 'normal' }}>
        <Typography variant='body2'>
          {t('components.organisms.modals.withdrawSuccessModal.description1')}
        </Typography>
        <Typography variant='body2' sx={{ color: 'success.main' }}>
          {t(
            'components.organisms.modals.withdrawSuccessModal.descriptionGreen'
          )}
          .
        </Typography>
      </Box>
      <Typography textAlign='center' variant='body2'>
        {t('components.organisms.modals.withdrawSuccessModal.description2')}
      </Typography>
      <Box display='flex' flexDirection='column' gap={1.5} pt={1}>
        <Button fullWidth variant='outlined' onClick={handleTrack}>
          {t('components.organisms.modals.withdrawSuccessModal.buttonTrack')}
        </Button>
        <Button
          fullWidth
          variant='contained'
          onClick={() => closeModal(Modals.ACTION_MODAL)}
        >
          {t('components.organisms.modals.withdrawSuccessModal.buttonDone')}
        </Button>
      </Box>
    </Box>
  )
}

export default WithdrawSuccess
