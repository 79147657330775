import { Box, Button, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'

import ZeroDataPlaceholder from '@/assets/icons/dashboards/ZeroDataPlaceholder'

interface ZeroDataDisplayProps {
  displayMessage: string
  buttonText: string
  navigationRoute: string
}

const ZeroDataDisplay: React.FC<ZeroDataDisplayProps> = ({
  displayMessage,
  buttonText,
  navigationRoute,
}) => {
  const navigate = useNavigate()

  return (
    <Section>
      <SectionColumn
        sx={{
          gap: 0,
          background: '#ffffff',
          borderRadius: (theme) => theme.spacing(1),
          p: 7,
        }}
      >
        <Stack gap={3}>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={2}
            margin='auto'
          >
            <Box sx={{ p: 0.5 }}>
              <ZeroDataPlaceholder />
            </Box>
            <Typography variant='subtitle1' align='center'>
              {displayMessage}
            </Typography>
            <Button
              variant='contained'
              size='large'
              onClick={() => {
                navigate(navigationRoute)
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Stack>
      </SectionColumn>
    </Section>
  )
}

export default ZeroDataDisplay
