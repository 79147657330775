import { Box, Skeleton, Typography } from '@mui/material'
import { RiskScores, StrategyAllocation } from '@solidant/spool-v2-fe-lib'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import { useRiskMetrics } from '@/hooks/createVault/useRiskMetrics'

import SectionItem from '@/components/molecules/SectionItem'

import { formatNumber } from '@/utils/formats'

export type RiskToAllocationMapping = Record<RiskScores, StrategyAllocation>

const RiskMetrics = () => {
  const { isUnavailableProvider } = useCreateVaultState()

  const { projectedAPY, strategyRiskScore, assetsLabels, isLoading } =
    useRiskMetrics()

  const t = useLocale()

  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridAutoColumns: 'minmax(0, 1fr)',
        gridAutoFlow: 'column',
        gap: (theme) => theme.spacing(2),
      }}
    >
      <SectionItem
        sx={{ height: '100%' }}
        title={t(
          'components.organisms.createVault.allocation.riskMetrics.assets.title'
        )}
      >
        <Typography variant='h6'>
          {isLoading || isUnavailableProvider ? (
            <Skeleton variant='text' animation='wave' width={150} />
          ) : (
            assetsLabels
          )}
        </Typography>
      </SectionItem>
      <SectionItem
        title={t(
          'components.organisms.createVault.allocation.riskMetrics.apy.title'
        )}
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'>
            {isLoading || isUnavailableProvider ? (
              <Skeleton variant='text' animation='wave' width={150} />
            ) : (
              `${formatNumber(projectedAPY, 2)} %`
            )}
          </Typography>
          {/*<Tooltip title='Placeholder' arrow placement='left'>
            <Box
              display='flex'
              alignItems='center'
              p={0.5}
              sx={{ cursor: 'pointer' }}
            >
              <InfoIcon className='lg' sx={{ color: 'primary.main' }} />
            </Box>
          </Tooltip>*/}
        </Box>
      </SectionItem>
      <SectionItem
        title={t(
          'components.organisms.createVault.allocation.riskMetrics.riskScore.title'
        )}
      >
        <Typography variant='h6'>
          {isLoading || isUnavailableProvider ? (
            <Skeleton variant='text' animation='wave' width={150} />
          ) : (
            formatNumber(strategyRiskScore, 1)
          )}
        </Typography>
      </SectionItem>
    </Box>
  )
}

export default RiskMetrics
