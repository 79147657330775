import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import AllocationProviderSelector from '@/components/organisms/CreateVault/Allocation/AllocationProviderSelector'
import RiskAllocationTable from '@/components/organisms/CreateVault/Allocation/RiskAllocationTable'
import RiskConfiguration from '@/components/organisms/CreateVault/Allocation/RiskConfiguration'
import RiskMetrics from '@/components/organisms/CreateVault/Allocation/RiskMetrics'

import { ALLOCATION_PROVIDERS } from '@/config/sdk'

const Allocation = () => {
  const { chain } = useConnectionState()

  const t = useLocale()

  const { isUnavailableProvider, selectedAllocationProvider } =
    useCreateVaultState()

  const allocationProvider = useMemo(() => ALLOCATION_PROVIDERS[chain], [chain])

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Section contentBoxClassName='allocation-section-content'>
        <SectionColumn
          title={t('components.organisms.createVault.launch.allocation.title')}
          sx={{ gap: 0 }}
        >
          <Typography variant='subtitle1'>
            {t(
              'components.organisms.createVault.launch.allocation.description'
            )}
          </Typography>
          <AllocationProviderSelector />
        </SectionColumn>
      </Section>
      <Section>
        <SectionColumn sx={{ gap: 0, p: 0 }}>
          {!isUnavailableProvider &&
          allocationProvider[selectedAllocationProvider] &&
          allocationProvider[selectedAllocationProvider].name !==
            'Equal allocation' ? (
            <SectionColumn
              sx={{ gap: 0 }}
              title={t(
                'components.organisms.createVault.launch.riskAppetite.title'
              )}
            >
              <Typography variant='subtitle1'>
                <span>
                  {t(
                    'components.organisms.createVault.launch.riskAppetite.description1'
                  )}
                  <br />
                  {t(
                    'components.organisms.createVault.launch.riskAppetite.description2'
                  )}
                </span>
              </Typography>
              <RiskConfiguration />
            </SectionColumn>
          ) : undefined}
          {!isUnavailableProvider && (
            <SectionColumn sx={{ gap: 0, p: 0 }}>
              <RiskMetrics />
            </SectionColumn>
          )}
        </SectionColumn>
      </Section>
      {!isUnavailableProvider && (
        <Section>
          <RiskAllocationTable />
        </Section>
      )}
    </Box>
  )
}

export default Allocation
