import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import { PropsWithChildren } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'

interface VaultFeatureProps extends PropsWithChildren {
  id: string
  title: string
  checked: boolean
  handleChecked: (title: string, checked: boolean) => void
  focus: boolean
  setFocused: (focused: string) => void
}

const VaultFeature: React.FC<VaultFeatureProps> = ({
  id,
  title,
  checked,
  handleChecked,
  focus,
  setFocused,
  children,
}) => {
  const { selectedGuards } = useCreateVaultState()

  return (
    <Box
      sx={{
        backgroundColor: focus && checked ? 'highlight.background' : '#fff',
        outline:
          focus && checked
            ? (theme) => `1px solid ${theme.palette.highlight.border}`
            : undefined,
        outlineOffset: focus && checked ? '-1px' : undefined,
        userSelect: 'none',
        '&:not(:last-child)': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      }}
      onClick={() => setFocused(id)}
    >
      <FormControlLabel
        control={
          <Checkbox
            size='small'
            checked={checked}
            onChange={() => handleChecked(id, !checked)}
            name={title}
            sx={{
              '& .MuiSvgIcon-root': {
                width: '24px',
                height: '24px',
              },
              padding: (theme) => theme.spacing(1),
              marginRight: (theme) => theme.spacing(2),
            }}
            color={focus ? 'primary' : 'default'}
          />
        }
        label={
          <Stack sx={{ userSelect: 'none' }}>
            <Typography variant='subtitle2'>{title}</Typography>
            {selectedGuards[id] &&
              selectedGuards[id]?.conditions &&
              selectedGuards[id]?.conditions.map((condition, index) => (
                <Typography variant='body2' color='text.secondary' key={index}>
                  {condition}
                </Typography>
              ))}
          </Stack>
        }
        sx={{ padding: (theme) => theme.spacing(1), margin: 0 }}
      />
      {checked && children}
    </Box>
  )
}

export default VaultFeature
