import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

const ConfirmationModal: React.FC = () => {
  const { modals, closeModal } = useModalState()
  const t = useLocale()

  return (
    <ModalBox id='confirmationModal'>
      <ModalBoxOuterContainer sx={{ width: '450px', p: 3 }}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={4}
          pt={1}
        >
          <Box
            display='flex'
            gap={1.5}
            alignContent='start'
            sx={{ width: '100%' }}
          >
            <ErrorOutlineIcon
              sx={{ height: '22px', width: '22px', mt: 0.5 }}
              color='error'
            />
            <Typography variant='subtitle1'>
              {
                modals['confirmationModal']?.modalType?.confirmationModalType
                  ?.message
              }
            </Typography>
          </Box>
          <Box
            display='flex'
            gap={1}
            sx={{ width: '100%' }}
            justifyContent='end'
          >
            <Button
              variant='outlined'
              onClick={() => closeModal(Modals.CONFIRMATION_MODAL)}
            >
              {t('components.organisms.confirmationModal.cancel')}
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                closeModal(Modals.CONFIRMATION_MODAL)
                modals[
                  'confirmationModal'
                ]?.modalType?.confirmationModalType.action()
              }}
            >
              {t('components.organisms.confirmationModal.confirm')}
            </Button>
          </Box>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default ConfirmationModal
