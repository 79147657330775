import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import usePendingWithdrawals from '@/hooks/smartVaults/usePendingWithdrawals'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import { BorderedBox } from '@/components/atoms/BorderedBox'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { Modals } from '@/store/modal/modals.types'

import { formatUSD, getUnixDateTimeString } from '@/utils/formats'

const WithdrawReceipts = () => {
  const { chain } = useConnectionState()

  const t = useLocale()
  const { smartVaultData } = useVaultsPage()
  const { openModal, setModalType } = useModalState()

  const { account } = useWeb3React()

  const { pendingWithdrawals } = usePendingWithdrawals(
    smartVaultData.address,
    account
  )

  return (
    <BorderedBox mb='1rem'>
      <Box padding='1rem' display='flex' alignItems='center' bgcolor='#f5f5f5'>
        <AccessTimeIcon
          sx={{ color: 'text.secondary', marginRight: '0.2rem' }}
          className='lg'
        />
        <Typography fontSize='14px' fontWeight={500}>
          {t('pages.smartVaults.vaultDetailsPage.receipts.pendingWithdrawals')}
        </Typography>
      </Box>
      {pendingWithdrawals.map((withdrawalReceipt, index) => {
        const formattedTime = getUnixDateTimeString(
          withdrawalReceipt.createdOn.toString()
        )
        const { dateOfDeposit } = formattedTime

        return (
          <Box
            key={index}
            px='1rem'
            py='0.5rem'
            display='flex'
            justifyContent='space-between'
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
            borderBottom={
              index !== pendingWithdrawals.length - 1 && '1.5px solid #f5f5f5'
            }
            onClick={() => {
              openModal(Modals.RECEIPTS_MODAL)
              setModalType({
                receiptsModalType: {
                  receipt: withdrawalReceipt,
                },
              })
            }}
          >
            <Box display='flex' alignItems='start'>
              <Box>
                {withdrawalReceipt.withdrawnAssets.map(
                  ({ amount, asset }, index) => {
                    return (
                      <Box key={index} display='flex' alignItems='center'>
                        <Box display='flex' mr='0.2rem'>
                          {mapAssetTokenIcon(chain, asset.address ?? '')}
                        </Box>
                        <Typography fontSize='14px'>
                          {formatUSD(amount)} {asset.symbol}
                        </Typography>
                      </Box>
                    )
                  }
                )}
              </Box>
            </Box>
            <Box>
              <Typography color='text.secondary' variant='body2'>
                {dateOfDeposit}
              </Typography>
            </Box>
          </Box>
        )
      })}
    </BorderedBox>
  )
}

export default WithdrawReceipts
