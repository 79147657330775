import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

const useWithdrawalDetails = (userAddress: string, vaultAddress: string) => {
  const { sdk } = useContext(AppContext)

  const { data, error, mutate } = useSWR(
    sdk && userAddress && vaultAddress
      ? ['withdrawalDetails', userAddress, vaultAddress]
      : null,
    async () => {
      if (!vaultAddress) return

      const withdrawalDetails = await sdk.smartVault.getWithdrawDetails(
        userAddress.toLowerCase(),
        vaultAddress.toLowerCase()
      )

      return withdrawalDetails
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    withdrawalDetails: data,
    mutate: mutate,
    isLoading: !error && !data,
    error,
  }
}

export default useWithdrawalDetails
