const ExponentialNormalised = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 14.3077C15.5 14.3077 17 8.27462 17 5L19 5C19 9.20538 17 16 4 16L4 14.3077Z'
        fill='black'
        fillOpacity='0.54'
      />
      <path d='M13 20H11V18H13V20Z' fill='black' fillOpacity='0.54' />
      <path d='M5 20H3V18H5V20Z' fill='black' fillOpacity='0.54' />
      <path d='M9 20H7V18H9V20Z' fill='black' fillOpacity='0.54' />
      <path d='M17 20H15V18H17V20Z' fill='black' fillOpacity='0.54' />
      <path d='M21 20H19V18H21V20Z' fill='black' fillOpacity='0.54' />
    </svg>
  )
}

export default ExponentialNormalised
