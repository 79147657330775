import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import sepolia from '@/config/testnet/sepolia.contracts.json'
import { tokenAddresses } from '@/constants/tokens'

export const SEPOLIA_CONFIG = {
  RPC_PROVIDER: 'https://1rpc.io/sepolia',
  CORE_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spool-v2-sepolia/api',
  API_URL: 'https://jobs.dev.spool.fi/graphql',
  FAST_WITHDRAW_API_URL: 'https://fastwithdraw.dev.spool.fi/sepolia',
  STAKING_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spoolfi-staking/api',
  ANALYTICS: 'https://analytics.spool.fi',
  SNAPSHOT_SUBGRAPH: 'https://hub.snapshot.org/graphql',
  SNAPSHOT_HUB: 'https://hub.snapshot.org',
  SNAPSHOT_SPACE: 'egov.spool.eth',
  REWARDS_URL: 'https://rewards.dev.spool.fi/sepolia',
  PRICE_FEED: 'https://pricefeed.dev.spool.fi/',
  SMART_VAULT_MANAGER: sepolia.SmartVaultManager.proxy,
  DEPOSIT_SWAP_MANAGER: sepolia.DepositSwap.proxy,
  DEPOSIT_MANAGER: sepolia.DepositManager.proxy,
  SMART_VAULT_FACTORY: sepolia.SmartVaultFactory,
  SMART_VAULT_FACTORY_HPF: sepolia.SmartVaultFactoryHpf,
  REWARD_MANAGER: sepolia.RewardManager.proxy,
  YELAY_STAKING: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
  SYLAY: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
  SPOOL_LENS: sepolia.SpoolLens.proxy,
  YELAY_TOKEN: tokenAddresses[SupportedNetworks.ARBITRUM]['SPOOL'],
  LINEAR_ALLOCATION_PROVIDER: sepolia.LinearAllocationProvider.proxy,
  ONE_INCH_ROUTER: '0x111111125421ca6dc452d289314280a0f8842a65',
  SWAPPER: sepolia.Swapper.proxy,
  ALLOW_LIST_GUARD: sepolia.AllowlistGuard,
  IGUARD_MANAGER: sepolia.GuardManager.proxy,
  STRATEGY_REGISTRY: sepolia.StrategyRegistry.proxy,
  API_CHAIN_NAME: 'sepolia',
  SPOOL_ACCESS_CONTROL: sepolia.SpoolAccessControl.proxy,
  META_VAULT_FACTORY: sepolia.MetaVaultFactory,
}
