import { HistoricalWithdawal, SortOrder } from '@solidant/spool-v2-fe-lib'
import { memo, useCallback, useMemo, useReducer } from 'react'

import {
  initialState,
  WithdrawContext,
} from '@/store/withdraw/withdraw.context'
import withdrawReducer from '@/store/withdraw/withdraw.reducer'
import { WithdrawActionTypes } from '@/store/withdraw/withdraw.types'

import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const WithdrawProvider = memo(({ children }: RCNode) => {
  const [withdraw, dispatch] = useReducer(withdrawReducer, initialState)

  const handleSortClick = useCallback(
    (
      key: keyof HistoricalWithdawal,
      sortType: SortType<HistoricalWithdawal>
    ) => {
      if (!sortType?.key || sortType?.key !== key) {
        dispatch({
          type: WithdrawActionTypes.SET_WITHDRAWAL_RECEIPT_SORT,
          payload: {
            key,
            direction: SortOrder.DESC,
          },
        })
        return
      }

      dispatch({
        type: WithdrawActionTypes.CHANGE_WITHDRAWAL_SORT_DIRECTION,
      })
    },
    []
  )

  const setWithdrawalAmount = (withdrawalAmount: string) => {
    dispatch({
      type: WithdrawActionTypes.SET_WITHDRAWAL_AMOUNT,
      payload: withdrawalAmount,
    })
  }

  const contextValue = useMemo(() => {
    return {
      ...withdraw,
      handleSortClick,
      setWithdrawalAmount,
    }
  }, [withdraw.withdrawReceiptSort, withdraw.withdrawalAmount])

  return (
    <WithdrawContext.Provider value={contextValue}>
      {children}
    </WithdrawContext.Provider>
  )
})
