import { Box, Grid, Typography } from '@mui/material'
import { Serie } from '@nivo/line'

import LegendLine from '@/components/organisms/Charts/LegendLine'

interface NormalChartProps {
  data: Serie[]
}

const LegendChart: React.FC<NormalChartProps> = ({ data }) => (
  <Grid container justifyContent='end'>
    <Box mr='3rem'>
      {data.map(({ id, color }) => (
        <Grid
          item
          key={id}
          gap={(theme) => theme.spacing(1)}
          display='flex'
          xs={12}
          justifyContent='start'
        >
          <Box sx={{ display: 'flex', alignSelf: 'center' }}>
            <LegendLine fill={color} height={2} width={25} />
          </Box>

          <Typography variant='caption'>{id}</Typography>
        </Grid>
      ))}
    </Box>
  </Grid>
)

export default LegendChart
