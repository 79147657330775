import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

const useGlobalTvr = () => {
  const { sdk } = useContext(AppContext)
  const { chain } = useConnectionState()

  const { data, error } = useSWR(
    sdk ? ['globalTvr', chain] : null,
    async () => await sdk.smartVault.getGlobalTvr()
  )

  if (error) {
    console.error(error)
  }

  return {
    globalTvr: data,
    isLoading: !error && !data,
    error,
  }
}

export default useGlobalTvr
