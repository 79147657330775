import { useContext } from 'react'

import { WithdrawContext } from '@/store/withdraw/withdraw.context'

const useWithdrawState = () => {
  const withdrawContext = useContext(WithdrawContext)
  if (!Object.keys(withdrawContext).length) {
    throw new Error('WithdrawContext must be used within its provider')
  }
  return withdrawContext
}

export default useWithdrawState
