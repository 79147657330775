import { formatEther } from '@ethersproject/units'
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { UserTransactionHistory } from '@solidant/spool-v2-fe-lib'
import { Fragment } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import NameCellTypography from '@/components/atoms/NameCell'
import { mapAssetTokenIcon, tokenDetails } from '@/components/atoms/Tokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { DEFAULT_ROW_HEIGHT } from '@/constants'
import { transactionType } from '@/constants/dashboard'
import { formatNumber, formatUSD, getUnixDateTimeString } from '@/utils/formats'

interface TransactionHistoryTableRowProps {
  transactionHistory: UserTransactionHistory
}

const TransactionHistoryTableRow: React.FC<TransactionHistoryTableRowProps> = ({
  transactionHistory,
}) => {
  const { assetAddresses, vault, timestamp, amount, type, balance } =
    transactionHistory

  const { chain } = useConnectionState()

  const { name: vaultName } = vault

  const { total } = balance

  const formattedTime = getUnixDateTimeString(timestamp)

  const { UTCTimeZone, dateOfDeposit, time } = formattedTime

  return (
    <>
      <TableRow
        hover
        role='checkbox'
        tabIndex={-1}
        sx={{
          height: DEFAULT_ROW_HEIGHT,
          backgroundColor: '#fff',
          userSelect: 'none',
        }}
      >
        <TableCell>
          <Stack>
            <Typography variant='body2'>{dateOfDeposit}</Typography>
            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
              {time} {UTCTimeZone}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Box display='flex' justifyContent='space-between'>
            <NameCellContent>
              <NameCellTypography variant='body2'>
                {vaultName}
              </NameCellTypography>
            </NameCellContent>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography
              variant='body2'
              fontWeight={500}
              sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
            >
              {transactionType[type]}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Box justifyContent='right' alignItems='center' display='flex'>
            {amount &&
              amount?.map(({ amount, assetAddress }, index) => (
                <Typography
                  variant='subtitle2'
                  key={index}
                  sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                >
                  {assetAddress === 'SVT'
                    ? formatUSD(formatEther(amount))
                    : formatUSD(amount)}{' '}
                  {tokenDetails[chain][assetAddress].symbol}
                </Typography>
              ))}
            <Box display='flex' ml='0.2rem'>
              {assetAddresses &&
                assetAddresses?.map((address, index) => (
                  <Fragment key={index}>
                    {mapAssetTokenIcon(chain, address ?? '')}
                  </Fragment>
                ))}
            </Box>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Stack>
            <Typography variant='body2' fontWeight={500}>
              $ {formatUSD(total)}
            </Typography>
            {balance &&
              balance?.asset.map(({ amount, asset }, index) => (
                <Typography
                  variant='body2'
                  sx={{
                    color: 'text.secondary',
                  }}
                  key={index}
                >
                  {formatNumber(amount, 2)} {asset.symbol}
                </Typography>
              ))}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  )
}

export default TransactionHistoryTableRow
