const WalletsLogo = () => {
  return (
    <svg
      width='113'
      height='48'
      viewBox='0 0 113 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='92.5' cy='24' r='19.5' fill='white' stroke='#EEEEEE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M90.214 28.2422C88.9285 30.2121 86.7743 32.7049 83.9079 32.7049C82.5529 32.7049 81.25 32.147 81.25 29.7239C81.25 23.5529 89.6755 14 97.4929 14C101.94 14 103.712 17.0855 103.712 20.5894C103.712 25.0869 100.794 30.2295 97.8925 30.2295C96.9718 30.2295 96.5201 29.7239 96.5201 28.9221C96.5201 28.7129 96.5548 28.4862 96.6243 28.2422C95.6341 29.9331 93.7232 31.502 91.9338 31.502C90.631 31.502 89.9708 30.6827 89.9708 29.5322C89.9708 29.1138 90.0577 28.678 90.214 28.2422ZM96.948 20.4679C96.948 21.4889 96.3457 21.9994 95.6718 21.9994C94.9877 21.9994 94.3955 21.4889 94.3955 20.4679C94.3955 19.4468 94.9877 18.9363 95.6718 18.9363C96.3457 18.9363 96.948 19.4468 96.948 20.4679ZM100.776 20.4679C100.776 21.4889 100.174 21.9994 99.5002 21.9994C98.8161 21.9994 98.2239 21.4889 98.2239 20.4679C98.2239 19.4469 98.8161 18.9364 99.5002 18.9364C100.174 18.9364 100.776 19.4469 100.776 20.4679Z'
        fill='#AB9FF2'
      />
      <circle cx='20.5' cy='24' r='19.5' fill='white' stroke='#EEEEEE' />
      <path
        d='M13.5699 19.4843C17.5551 15.592 24.0244 15.592 28.0096 19.4843L28.4893 19.9564C28.6905 20.1498 28.6905 20.4671 28.4893 20.6606L26.8488 22.2624C26.7482 22.363 26.5857 22.363 26.4851 22.2624L25.8274 21.6201C23.0416 18.904 18.5379 18.904 15.7521 21.6201L15.0479 22.3088C14.9473 22.4094 14.7848 22.4094 14.6842 22.3088L13.0437 20.707C12.8425 20.5135 12.8425 20.1963 13.0437 20.0028L13.5699 19.4843ZM31.4067 22.7963L32.8692 24.2202C33.0704 24.4136 33.0704 24.7309 32.8692 24.9244L26.2839 31.3549C26.0827 31.5483 25.7577 31.5483 25.5643 31.3549L20.8903 26.7893C20.8439 26.7429 20.7588 26.7429 20.7124 26.7893L16.0384 31.3549C15.8372 31.5483 15.5122 31.5483 15.3188 31.3549L8.71028 24.9244C8.50909 24.7309 8.50909 24.4136 8.71028 24.2202L10.1728 22.7963C10.374 22.6029 10.699 22.6029 10.8925 22.7963L15.5664 27.3619C15.6128 27.4084 15.698 27.4084 15.7444 27.3619L20.4183 22.7963C20.6195 22.6029 20.9445 22.6029 21.138 22.7963L25.8119 27.3619C25.8583 27.4084 25.9435 27.4084 25.9899 27.3619L30.6638 22.7963C30.8805 22.6029 31.2055 22.6029 31.4067 22.7963Z'
        fill='#0974FF'
      />
      <circle cx='56.5' cy='24' r='23.5' fill='white' stroke='#EEEEEE' />
      <path
        d='M67.3044 12.733L57.9397 19.7174L59.6715 15.5967L67.3044 12.733Z'
        fill='#E2761B'
      />
      <path
        d='M45.6856 12.733L54.975 19.7836L53.328 15.5967L45.6856 12.733ZM63.935 28.9229L61.4409 32.76L66.7774 34.2344L68.3115 29.0079L63.935 28.9229ZM44.6974 29.0079L46.2221 34.2344L51.5585 32.76L49.0644 28.9229L44.6974 29.0079Z'
        fill='#E4761B'
      />
      <path
        d='M51.2574 22.4394L49.7703 24.6982L55.0691 24.9345L54.8809 19.2165L51.2574 22.4394ZM61.7326 22.4394L58.0621 19.1504L57.9397 24.9345L63.2291 24.6982L61.7326 22.4394ZM51.5585 32.76L54.7397 31.2006L51.9915 29.0457L51.5585 32.76ZM58.2503 31.2006L61.4409 32.76L60.9985 29.0457L58.2503 31.2006Z'
        fill='#E4761B'
      />
      <path
        d='M61.4409 32.76L58.2503 31.2006L58.5044 33.2893L58.4762 34.1682L61.4409 32.76ZM51.5585 32.76L54.5232 34.1682L54.5044 33.2893L54.7397 31.2006L51.5585 32.76Z'
        fill='#D7C1B3'
      />
      <path
        d='M54.5703 27.6658L51.9162 26.8814L53.7891 26.0213L54.5703 27.6658ZM58.4197 27.6658L59.2009 26.0213L61.0833 26.8814L58.4197 27.6658Z'
        fill='#233447'
      />
      <path
        d='M51.5585 32.76L52.0103 28.9229L49.0644 29.0079L51.5585 32.76ZM60.9891 28.9229L61.4409 32.76L63.935 29.0079L60.9891 28.9229ZM63.2291 24.6982L57.9397 24.9345L58.4291 27.6659L59.2103 26.0214L61.0926 26.8814L63.2291 24.6982ZM51.9162 26.8814L53.7985 26.0214L54.5703 27.6659L55.0691 24.9345L49.7703 24.6982L51.9162 26.8814Z'
        fill='#CD6116'
      />
      <path
        d='M49.7703 24.6982L51.9915 29.0457L51.9162 26.8814L49.7703 24.6982ZM61.0926 26.8814L60.9985 29.0457L63.2291 24.6982L61.0926 26.8814ZM55.0691 24.9345L54.5703 27.6659L55.1915 30.8887L55.3326 26.6451L55.0691 24.9345ZM57.9397 24.9345L57.6856 26.6357L57.7985 30.8887L58.4291 27.6659L57.9397 24.9345Z'
        fill='#E4751F'
      />
      <path
        d='M58.4291 27.6659L57.7986 30.8887L58.2503 31.2006L60.9986 29.0457L61.0927 26.8814L58.4291 27.6659ZM51.9162 26.8814L51.9915 29.0457L54.7397 31.2006L55.1915 30.8887L54.5703 27.6659L51.9162 26.8814Z'
        fill='#F6851B'
      />
      <path
        d='M58.4762 34.1682L58.5044 33.2893L58.2691 33.0814H54.7209L54.5044 33.2893L54.5232 34.1682L51.5585 32.76L52.5938 33.6106L54.6926 35.0756H58.2973L60.4056 33.6106L61.4409 32.76L58.4762 34.1682Z'
        fill='#C0AD9E'
      />
      <path
        d='M58.2503 31.2006L57.7985 30.8887H55.1915L54.7397 31.2006L54.5044 33.2893L54.7209 33.0814H58.2691L58.5044 33.2893L58.2503 31.2006Z'
        fill='#161616'
      />
      <path
        d='M67.6997 20.1711L68.4997 16.315L67.3044 12.733L58.2503 19.4812L61.7326 22.4394L66.655 23.8854L67.7468 22.6095L67.2762 22.2692L68.0291 21.5793L67.4456 21.1257L68.1985 20.5491L67.6997 20.1711ZM44.4997 16.315L45.2997 20.1711L44.7915 20.5491L45.5444 21.1257L44.9703 21.5793L45.7232 22.2692L45.2526 22.6095L46.335 23.8854L51.2574 22.4394L54.7397 19.4812L45.6856 12.733L44.4997 16.315Z'
        fill='#763D16'
      />
      <path
        d='M66.655 23.8854L61.7327 22.4394L63.2291 24.6982L60.9985 29.0457L63.935 29.0079H68.3115L66.655 23.8854ZM51.2574 22.4394L46.335 23.8854L44.6974 29.0079H49.0644L51.9915 29.0457L49.7703 24.6982L51.2574 22.4394ZM57.9397 24.9345L58.2503 19.4811L59.6809 15.5967H53.328L54.7397 19.4811L55.0691 24.9345L55.1821 26.6546L55.1915 30.8887H57.7985L57.8174 26.6546L57.9397 24.9345Z'
        fill='#F6851B'
      />
    </svg>
  )
}

export default WalletsLogo
