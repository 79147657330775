import { TableBody } from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'

import useRiskModelAndStrategies from '@/hooks/createVault/useRiskModelAndStrategies'

import DataLoading from '@/components/molecules/DataLoading'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import TableWrapper from '@/components/molecules/TableWrapper'
import RiskModelTableRow from '@/components/organisms/CreateVault/RiskModel/RiskModelTableRow'

import { riskModelHeadCells } from '@/constants/createVault'

import { RiskModelDto } from '@/types/create-vault'

const RiskModelTable: React.FC = () => {
  const { riskModelsAndStrategies, isLoading, error } =
    useRiskModelAndStrategies()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  return (
    <>
      {!isLoading ? (
        riskModelsAndStrategies && riskModelsAndStrategies.length && !error ? (
          <Box
            width='100%'
            border='1px solid rgba(0, 0, 0, 0.12)'
            borderRadius='4px'
          >
            <TableWrapper
              header={riskModelHeadCells}
              headerClass='create-vault-table-head'
              withCheckbox
              pagination={
                riskModelsAndStrategies.length > 9 && {
                  rowsPerPageOptions: [10, 20, 30],
                  page: page,
                  rowsPerPage: rowsPerPage,
                  count: riskModelsAndStrategies.length ?? 0,
                  setPage: setPage,
                  setRowsPerPage: setRowsPerPage,
                }
              }
            >
              <TableBody>
                {riskModelsAndStrategies
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((riskModelData: RiskModelDto) => (
                    <RiskModelTableRow
                      key={riskModelData.riskModelName}
                      riskModelData={riskModelData}
                    />
                  ))}
              </TableBody>
            </TableWrapper>
          </Box>
        ) : (
          <ErrorWithText errorText='Something went wrong when loading Risk Models' />
        )
      ) : (
        <DataLoading loadingText='Loading Risk Model' />
      )}
    </>
  )
}

export default RiskModelTable
