import InfoIcon from '@mui/icons-material/Info'
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { StrategyAllocationDetails } from '@solidant/spool-v2-fe-lib'
import { memo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import LinearProgressWithLabel from '@/components/atoms/LinearProgressWithLabel'
import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { NOT_AVILABLE } from '@/constants'
import { strategiesDescriptions } from '@/mockdata/create-vault.json'
import { formatNumber } from '@/utils/formats'

interface StrategiesTableRowProps {
  strategy: StrategyAllocationDetails
  totalAllocation: number
}

const StrategiesTableRow: React.FC<StrategiesTableRowProps> = ({
  strategy,
  totalAllocation,
}) => {
  const { chain } = useConnectionState()

  const {
    name,
    assetGroup,
    apy,
    allocation,
    riskScore,
    errorState,
    // auditor,
    // logo,
  } = strategy

  const info =
    strategiesDescriptions[name as keyof typeof strategiesDescriptions]

  return (
    <TableRow
      sx={{
        backgroundColor: '#fff',
        opacity: !errorState || errorState === 'normal' ? 1 : 0.35,
      }}
    >
      <TableCell
        sx={{
          display: 'table-cell',
          justifyContent: 'space-between',
        }}
      >
        <Box
          whiteSpace='normal'
          maxWidth='400px'
          display='flex'
          alignItems='center'
          sx={{
            overflowWrap: 'anywhere',
          }}
        >
          <NameCellContent>
            <Box display='flex' mr='0.2rem'>
              {assetGroup &&
                assetGroup?.assetGroupTokens.map(({ address }, index) => (
                  <Box
                    key={index}
                    padding='2px'
                    sx={{
                      position: 'relative',
                      ml: index !== 0 ? '-12px' : '0',
                    }}
                    height={20}
                    width={20}
                  >
                    {mapAssetTokenIcon(chain, address ?? '')}
                  </Box>
                ))}
            </Box>
            <SmartVaultNameTableTypography variant='body2'>
              {name}
            </SmartVaultNameTableTypography>
            <Tooltip title={info} arrow placement='left'>
              <span>
                <IconButton
                  sx={{
                    padding: '4px',
                    opacity: info ? '1' : '0.4',
                  }}
                  disableRipple
                  disabled={!info}
                >
                  <InfoIcon sx={{ color: 'primary.main', fontSize: '22px' }} />
                </IconButton>
              </span>
            </Tooltip>
          </NameCellContent>
        </Box>
      </TableCell>
      <TableCell align='right'>{formatNumber(Number(apy), 2)} %</TableCell>
      <TableCell align='right'>
        <Box maxWidth='100px' marginLeft='auto'>
          <LinearProgressWithLabel
            value={(Number(allocation) / totalAllocation) * 100}
          />
        </Box>
      </TableCell>
      <TableCell align='center'>
        {riskScore ? `${formatNumber(riskScore, 1)}/10` : NOT_AVILABLE}
      </TableCell>
    </TableRow>
  )
}

export default memo(StrategiesTableRow)
