import { DatumValue, Serie } from '@nivo/line'
import dayjs from 'dayjs'

export type TimeRangeOptions = '6M' | '7D'

export const getMaxValue = (data: Serie[]) => {
  try {
    // find max value in array
    const n = Math.max(
      ...data.flatMap((item) => item.data.map((val) => (!val.y ? 0 : +val.y)))
    )
    // count number of digits before decimal point: 183.55 -> 3
    const length = (Math.log(n) * Math.LOG10E + 1) | 0
    // ceil the number to the same power: 183.55 -> 100
    const quotient = Math.pow(10, length - 1)
    // divide without remainder: 183.55 / 100 -> 1
    const division = Math.floor(n / quotient)
    // calculate max value: 100 * 3 -> 300
    return quotient * (division + 1)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    return 0
  }
}

export const getYTickValues = (maxValue: number) => {
  const values = [0, 1, 2, 3, 4, 5]
  const oneUnit = maxValue / 5
  return values.map((x) => x * oneUnit)
}

export const formatXAxisValue = (value: string, xTickValues: DatumValue[]) => {
  // only display dates that are inside xTickValues
  if (xTickValues.includes(value)) {
    const parsedDate = dayjs(value, 'YYYY-MM-DD')
    return parsedDate.format('D.M.YY')
  } else {
    return ''
  }
}

export const notEmpty = (
  value: DatumValue | null | undefined
): value is DatumValue => {
  return value !== null && value !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
// export const getXTickValues = (
//   data: Serie[]
//   // timeRange: TimeRangeOptions = '6M'
// ) => {
//   try {
//     const dates = data[0].data.map((val) => val.x).filter(notEmpty)
//     const unformattedResult: DatumValue[] = []
//     const result: DatumValue[] = []

//     unformattedResult.push(dates[0])
//     unformattedResult.push(dates[dates.length - 1])

//     for (const date of unformattedResult) {
//       const formattedDate = unix(date as number).format('DD/MM/YYYY')
//       result.push(formattedDate)
//     }

//     return result.sort()
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.log(e)
//     return []
//   }
// }

export const getXTickerValues = (
  data: Serie[],
  xTickLines: number,
  skipGetFinalDate?: boolean
) => {
  try {
    const dates = data[0].data.map((val) => val.x)

    let i = 0
    const fiveTickers = Math.floor(dates.length / xTickLines)

    const xTicker: DatumValue[] = []

    if (fiveTickers !== 0) {
      do {
        xTicker.push(dates[i])
        i += fiveTickers
      } while (i < dates.length)
    }

    if (fiveTickers === 0) {
      xTicker.push(...dates)
    }

    if (
      !skipGetFinalDate &&
      dates[dates.length - 1] !== xTicker[xTicker.length - 1]
    ) {
      xTicker.pop()
      xTicker.push(dates[dates.length - 1])
    }

    return xTicker
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    return []
  }
}

export const getXTickValues = (
  data: Serie[]

  // timeRange: TimeRangeOptions = '6M'
) => {
  try {
    const dates = data[0].data.map((val) => val.x).filter(notEmpty)
    const result: DatumValue[] = []

    // first and last date
    result.push(dates[0])
    result.push(dates[dates.length - 1])

    // 1st day of each month
    for (const date of dates) {
      const parsedDate = dayjs(date, 'YYYY-MM-DD')
      if (parsedDate.date() === 1 && !result.includes(date)) {
        result.push(date)
      }
    }

    return result.sort()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    return []
  }
}

// see https://github.com/plouc/nivo/issues/140, margins can't be automatically calculated
export const getDashboardLineChartMarginLeft = (
  lastYTickValueLength: number
) => {
  switch (lastYTickValueLength) {
    case 8:
      return 80
    case 7:
      return 80
    case 6:
      return 68
    case 5:
      return 60
    case 4:
      return 52
    case 3:
      return 45
    case 2:
      return 32
    case 1:
      return 30
    default:
      return 50
  }
}

export const getMarginTop = (items: number) => {
  if (items === 0 || items === 1) return 10
  else return 10 + Math.ceil(items / 2) * 20
}
