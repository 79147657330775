import { ApiTransactionType } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'
import useTransactionHistoryState from '@/hooks/context/useTransactionHistoryState'

import { AppContext } from '@/store/app/app.context'

const useUserTransactionHistory = () => {
  const { sdk } = useContext(AppContext)
  const { chain } = useConnectionState()

  const {
    page,
    assetGroupId,
    limit,
    transactionHistorySort,
    search,
    filteredVaults,
  } = useTransactionHistoryState()

  const { filteredTransactionTypes } = useTransactionHistoryState()

  const { account } = useWeb3React()

  const { data, error, mutate } = useSWR(
    sdk && account
      ? [
          'transactionHistory',
          chain,
          page,
          limit,
          assetGroupId,
          filteredTransactionTypes,
          transactionHistorySort,
          search,
          account,
          filteredVaults,
        ]
      : null,
    async () => {
      return await sdk.dashboard.getUserTransactionHistory({
        offset: page * limit,
        limit,
        assetGroupId: assetGroupId,
        transactionTypes: filteredTransactionTypes as ApiTransactionType[],
        userAddress: account,
        sortBy: transactionHistorySort?.key,
        sortOrder: transactionHistorySort?.direction,
        smartVaultAddresses: filteredVaults,
      })
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    transactionHistory: data?.transactionHistory,
    totalTransactions: data?.totalCount,
    isLoading: !error && !data,
    mutate,
    error,
  }
}

export default useUserTransactionHistory
