import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { createContext } from 'react'

import {
  TransactionHistoryContextProps,
  TransactionHistoryState,
} from '@/store/transactionHistory/transactionHistory.types'

export const initialState: TransactionHistoryState = {
  page: 0,
  limit: 10,
  search: '',
  filteredVaults: [],
  filteredTransactionTypes: [],
  transactionHistorySort: {
    key: 'timestamp',
    direction: SortOrder.DESC,
  },
}

export const TransactionHistoryContext =
  createContext<TransactionHistoryContextProps>({
    ...initialState,
    setPage: () => undefined,
    setLimit: () => undefined,
    setMarketId: () => undefined,
    setAssetGroupId: () => undefined,
    setFilteredVaults: () => undefined,
    setTransactionTypes: () => undefined,
    setSearch: () => undefined,
    handleTransactionHistoryTableSort: () => undefined,
  })
