import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'

import { CHAINS } from '@/config/web3/chains'
import { Routes } from '@/constants/routes'

interface WithdrawalNotificationProps {
  descriptionText: string
  withdrawNotification: {
    showNotification: boolean
    multiNft: boolean
    text: string
  }
  setWithdrawNotification: React.Dispatch<
    React.SetStateAction<{
      showNotification: boolean
      multiNft: boolean
      text: string
    }>
  >
}

const WithdrawalNotification: React.FC<WithdrawalNotificationProps> = ({
  descriptionText,
  withdrawNotification,
  setWithdrawNotification,
}) => {
  const t = useLocale()
  const { account } = useWeb3React()
  const { chain } = useConnectionState()

  const navigate = useNavigate()

  const localStorageKey =
    account + `-${CHAINS[chain].name}-` + '-latestClaimableWnft'

  const localStoredNft = localStorage.getItem(localStorageKey)

  return (
    <Box
      position='fixed'
      zIndex='1000'
      width='375px'
      bgcolor='white'
      bottom='2rem'
      boxShadow={24}
      right='1rem'
      padding='1rem'
      display='flex'
      flexDirection='column'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mb='0.5rem'
      >
        <Typography fontSize='14px' fontWeight={500}>
          {t('notification.claimWithdrawal.title')}
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            localStorage.setItem(
              localStorageKey,
              JSON.stringify({ ...JSON.parse(localStoredNft), hasClosed: true })
            )
            setWithdrawNotification({
              ...withdrawNotification,
              showNotification: false,
            })
          }}
        >
          <CloseIcon fontSize='small' />
        </Box>
      </Box>
      <Box display='flex' mb='1rem'>
        <Typography color='text.secondary' fontSize='14px'>
          {descriptionText}
        </Typography>
      </Box>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          navigate(`${Routes(chain).dashboard.investor.url}`)
        }}
      >
        <Typography fontSize='14px' color='primary'>
          {t('notification.claimWithdrawal.cta')}
        </Typography>
      </Box>
    </Box>
  )
}

export default WithdrawalNotification
