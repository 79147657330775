import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

export interface ConnectionState {
  chain: SupportedNetworks
  activatingWallet: string | undefined
}

export interface ConnectionContextProps extends ConnectionState {
  setChain: (network: SupportedNetworks) => void
  setActivatingWallet: (wallet: string) => void
}

export enum ConnectionActionTypes {
  SET_CHAIN = 'SET_CHAIN',
  TARGET_WALLET = 'TARGET_WALLET',
}

export type ConnectionAction =
  | {
      type: ConnectionActionTypes.SET_CHAIN
      payload: number
    }
  | { type: ConnectionActionTypes.TARGET_WALLET; payload?: string }
