import { Box, styled } from '@mui/material'

export const MainContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1250px',
  width: '100%',
  flexGrow: 1,
  marginTop: '1rem',
  [theme.breakpoints.down('lg')]: {
    padding: '0rem 1rem',
  },
}))
