import { Warning } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

interface ErrorWithTextProps {
  errorText: string
}

const ErrorWithText: React.FC<ErrorWithTextProps> = ({ errorText }) => (
  <Box maxWidth='100%' display='flex' justifyContent='center' sx={{ p: 1 }}>
    <Box display='flex' flexDirection='column' alignItems='center' gap='1.5rem'>
      <Warning
        style={{ width: '50', height: '50' }}
        color='error'
        className='hook-loading'
      />
      <Typography>{errorText}...</Typography>
    </Box>
  </Box>
)

export default ErrorWithText
