import { SortOrder } from '@solidant/spool-v2-fe-lib'

import { SortTypes } from '@/types/table'

export const sort = (a: any, b: any, sortTypes: SortTypes) => {
  if (!sortTypes?.key) return 0
  let aValue, bValue

  const direction = sortTypes.direction === SortOrder.ASC ? 1 : -1

  if (sortTypes?.key === 'name') {
    const comparisonResult = a.name.localeCompare(b.name)
    return comparisonResult * (sortTypes.direction === SortOrder.ASC ? -1 : 1)
  }

  if (sortTypes.key === 'assetGroup') {
    aValue = parseFloat(a[sortTypes.key].id.toString())
    bValue = parseFloat(b[sortTypes.key].id.toString())
  } else if (sortTypes.key === 'depositedAmount') {
    aValue = parseFloat(a.totalDepositUSD || 0)
    bValue = parseFloat(b.totalDepositUSD || 0)
  } else if (sortTypes.key === 'deposits') {
    aValue = parseFloat(a.deposits.total || 0)
    bValue = parseFloat(b.deposits.total || 0)
  } else if (sortTypes.key === 'withdrawAmount') {
    aValue = parseFloat(a.withdrawAmount || 0)
    bValue = parseFloat(b.withdrawAmount || 0)
  } else if (sortTypes.key === 'totalVolumeRouted') {
    if (typeof a.totalVolumeRouted === 'object') {
      aValue = parseFloat(a.totalVolumeRouted.total || 0)
      bValue = parseFloat(b.totalVolumeRouted.total || 0)
    } else {
      aValue = parseFloat(a.totalVolumeRouted || 0)
      bValue = parseFloat(b.totalVolumeRouted || 0)
    }
  } else if (sortTypes.key === 'balanceUSD') {
    aValue = parseFloat(a.balanceUSD || 0)
    bValue = parseFloat(b.balanceUSD || 0)
  } else if (sortTypes.key === 'allocationWeight') {
    aValue = parseFloat(a.sortableAllocationWeight || 0)
    bValue = parseFloat(b.sortableAllocationWeight || 0)
  } else if (sortTypes.key === 'allocationAmount') {
    aValue = parseFloat(a.sortableAllocationAmount || 0)
    bValue = parseFloat(b.sortableAllocationAmount || 0)
  } else if (sortTypes.key === 'wnftId') {
    return a.wnftId.localeCompare(b.wnftId) * direction
  } else if (sortTypes.key === 'dnftId') {
    return a.nftId.localeCompare(b.nftId) * direction
  } else if (sortTypes.key === 'apy') {
    aValue = parseFloat(a.apy || 0)
    bValue = parseFloat(b.apy || 0)
  } else if (sortTypes.key === 'baseAPY') {
    aValue = parseFloat(a.adjustedApy || 0)
    bValue = parseFloat(b.adjustedApy || 0)
  } else if (sortTypes.key === 'rewards') {
    aValue = parseFloat(a.totalClaimableRewards || 0)
    bValue = parseFloat(b.totalClaimableRewards || 0)
  } else if (sortTypes.key === 'balance') {
    if (a.balance.total && b.balance.total) {
      aValue = parseFloat(a.balance.total || 0)
      bValue = parseFloat(b.balance.total || 0)
    } else {
      aValue = parseFloat(a.balance || 0)
      bValue = parseFloat(b.balance || 0)
    }
  } else if (sortTypes.key === 'amountInUSD') {
    aValue = parseFloat(a.allocation.allocationAmountInUSD || 0)
    bValue = parseFloat(b.allocation.allocationAmountInUSD || 0)
  } else if (sortTypes.key === 'amountInUSDPercentage') {
    aValue = parseFloat(a.allocation.allocationAmountInUSD || 0)
    bValue = parseFloat(b.allocation.allocationAmountInUSD || 0)
  } else if (sortTypes.key === 'percentage') {
    aValue = parseFloat(a.allocation.allocationPercentage || 0)
    bValue = parseFloat(b.allocation.allocationPercentage || 0)
  } else {
    aValue = parseFloat(a[sortTypes.key].toString())
    bValue = parseFloat(b[sortTypes.key].toString())
  }

  return (aValue - bValue) * direction
}
