import { parseUnits } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import { constants } from 'ethers'

import useAppState from '@/hooks/context/useAppState'
import useDepositState from '@/hooks/context/useDepositState'
import useModalState from '@/hooks/context/useModalState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useDepositReceipts from '@/hooks/vaultList/Transactions/useDepositReceipts'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { DEPOSIT_FLUSH } from '@/config/flushes.config'
import { sleep } from '@/utils/web3'

const useDeposit = () => {
  const { openModal, setModalType } = useModalState()

  const { sdk } = useAppState()

  const { account } = useWeb3React()

  const { singleDeposit, setSingleDepositAmount } = useDepositState()

  const { decimals } = useTokenDetails(singleDeposit.tokenAddress)

  const { smartVaultData } = useVaultsPage()

  const { address } = smartVaultData

  const { depositReceipts, updateDepositReceipts } = useDepositReceipts(address)

  const deposit = async () => {
    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.Deposit,
        },
      })

      const tx = await sdk.smartVault.deposit(
        address,
        [parseUnits(singleDeposit.amount, decimals)],
        account,
        // flush permenantly set to false
        DEPOSIT_FLUSH,
        constants.AddressZero
      )
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.Deposit,
        },
      })

      await tx.wait()

      // delay so that subgraph gets updated
      await sleep(7000)

      await updateDepositReceipts(depositReceipts)

      setSingleDepositAmount({
        ...singleDeposit,
        amount: '',
      })
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.Deposit,
          txHash: tx.hash,
        },
      })
    } catch (error) {
      //setError(error);
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.Deposit,
        },
      })
      console.error(error)
    }
  }

  return deposit
}

export default useDeposit
