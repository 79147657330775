import { HistoricalDeposit, SortOrder } from '@solidant/spool-v2-fe-lib'
import { memo, useCallback, useMemo, useReducer } from 'react'

import { DepositContext, initialState } from '@/store/deposit/deposit.context'
import depositReducer from '@/store/deposit/deposit.reducer'
import { Deposit, DepositActionTypes } from '@/store/deposit/deposit.types'

import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const DepositProvider = memo(({ children }: RCNode) => {
  const [deposit, dispatch] = useReducer(depositReducer, initialState)

  const setSingleDepositAmount = (singleDeposit: Deposit) => {
    dispatch({
      type: DepositActionTypes.SET_SINGLE_DEPOSIT_AMOUNT,
      payload: singleDeposit,
    })
  }

  const setMultiAssetUsdDepositAmount = (
    multiAssetUsdDepositAmount: string
  ) => {
    dispatch({
      type: DepositActionTypes.SET_MULTI_ASSET_USD_DEPOSIT_AMOUNT,
      payload: multiAssetUsdDepositAmount,
    })
  }

  const setDepositReceiptPage = (depositReceiptPage: number) => {
    dispatch({
      type: DepositActionTypes.SET_DEPOSIT_RECEIPT_PAGE,
      payload: depositReceiptPage,
    })
  }

  const setDepositReceiptLimit = (depositReceiptLimit: number) => {
    dispatch({
      type: DepositActionTypes.SET_DEPOSIT_RECEIPT_LIMIT,
      payload: depositReceiptLimit,
    })
  }

  const setMultiAssetSwap = (multiAssetSwap: Deposit) => {
    dispatch({
      type: DepositActionTypes.SET_MULTI_ASSET_SWAP_DEPOSIT,
      payload: multiAssetSwap,
    })
  }

  const handleSortClick = useCallback(
    (key: keyof HistoricalDeposit, sortType: SortType<HistoricalDeposit>) => {
      if (!sortType?.key || sortType?.key !== key) {
        dispatch({
          type: DepositActionTypes.SET_DEPOSIT_RECEIPT_SORT,
          payload: {
            key,
            direction: SortOrder.DESC,
          },
        })
        return
      }

      dispatch({
        type: DepositActionTypes.CHANGE_DEPOSIT_SORT_DIRECTION,
      })
    },
    []
  )

  const contextValue = useMemo(() => {
    return {
      ...deposit,
      setSingleDepositAmount,
      setMultiAssetUsdDepositAmount,
      handleSortClick,
      setDepositReceiptPage,
      setDepositReceiptLimit,
      setMultiAssetSwap,
    }
  }, [
    deposit.depositReceiptLimit,
    deposit.depositReceiptPage,
    deposit.singleDeposit,
    deposit.multiAssetUsdDepositAmount,
    deposit.depositReceiptSort,
    deposit.multiAssetSwap,
  ])

  return (
    <DepositContext.Provider value={contextValue}>
      {children}
    </DepositContext.Provider>
  )
})
