import { Box, TextField } from '@mui/material'
import { isAddress } from 'ethers/lib/utils'
import { useEffect, useMemo, useState } from 'react'

import useAppState from '@/hooks/context/useAppState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import CustomButton from '@/components/atoms/CustomButton'

import { Role } from '@/store/createVault/createVault.types'

interface AddressInputProps {
  id: string
  isAddressInput: boolean
  inputLabel: string
  role: Role
  address: string
  setAddress: (address: string) => void
  nftAmount: string
  setNftAmount: (name: string) => void
  selected: string[]
  setSelected: (selected: string[]) => void
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  nft?: boolean
}

const AddressInput: React.FC<AddressInputProps> = ({
  id,
  isAddressInput = true,
  inputLabel,
  role,
  address,
  setAddress,
  nftAmount,
  setNftAmount,
  selected,
  setSelected,
  editMode,
  setEditMode,
  nft = false,
}) => {
  const [notNFTAddress, setNotNFTAddress] = useState<boolean>(false)
  const { selectedGuards, setGuardsWhitelist } = useCreateVaultState()
  const { sdk } = useAppState()
  const addressExistsInTable = useMemo(
    () =>
      selectedGuards[id]?.whitelist &&
      !!selectedGuards[id]?.whitelist.filter((whitelist) => {
        return whitelist.address === address
      }).length,
    [address]
  )

  const handleAdd = () => {
    if (address !== '') {
      setGuardsWhitelist(
        id,
        [
          ...(selectedGuards[id]?.whitelist || []),
          {
            nftAmount,
            address,
            role,
          },
        ],
        false,
        true
      )
      setAddress('')
      setNftAmount('')
    }
  }

  useEffect(() => {
    if (!isAddress(address)) {
      setNotNFTAddress(true)
      return
    }

    if (nft) {
      const checkIsNftAddress = async (address: string) => {
        const isNftAddress = await sdk.guards.isNftAddress(address)
        setNotNFTAddress(!isNftAddress)
      }
      checkIsNftAddress(address)
      return
    }

    setNotNFTAddress(false)
  }, [address])

  const handleUpdate = () => {
    if (address !== '') {
      setGuardsWhitelist(
        id,
        selectedGuards[id]?.whitelist.map((x) =>
          x.address === selected[0]
            ? { id: x.id, address, nftAmount, role: x.role }
            : x
        )
      )
      setEditMode(false)
      setAddress('')
      setNftAmount('')
      setSelected([])
    }
  }

  const addressInputError = (address && notNFTAddress) || addressExistsInTable
  const addressInputDisableButton =
    address === '' || notNFTAddress || addressExistsInTable

  const t = useLocale()

  return (
    <Box display='flex' alignItems='baseline' gap={1}>
      <TextField
        value={address}
        onChange={(e) => {
          const { value } = e.currentTarget

          setAddress(value)
        }}
        error={isAddressInput && addressInputError}
        label={inputLabel}
        size='small'
        fullWidth
        variant='outlined'
        helperText={
          !isAddressInput
            ? t(
                'components.organisms.createVault.guards.modals.whiteListModal.addressInput.required.nftRequired'
              )
            : addressExistsInTable
            ? t(
                'components.organisms.createVault.guards.modals.whiteListModal.addressInput.required.duplicateAddress'
              )
            : nft
            ? t(
                'components.organisms.createVault.guards.modals.whiteListModal.addressInput.required.nftRequired'
              )
            : t(
                'components.organisms.createVault.guards.modals.whiteListModal.addressInput.required.text'
              )
        }
      />
      <CustomButton
        color='primary'
        disabled={isAddressInput && addressInputDisableButton}
        size='large'
        variant='contained'
        onClick={editMode ? handleUpdate : handleAdd}
      >
        {editMode
          ? t(
              'components.organisms.createVault.guards.modals.whiteListModal.addressInput.button.update'
            )
          : t(
              'components.organisms.createVault.guards.modals.whiteListModal.addressInput.button.add'
            )}
      </CustomButton>
    </Box>
  )
}

export default AddressInput
