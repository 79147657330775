import { useMemo } from 'react'

import flattenLocale from '@/locales/flattenedLocale'

const useLocale = () => {
  const flatLocale = flattenLocale()
  const t = useMemo(() => {
    return (key: string) => {
      if (!flatLocale[key]) {
        console.warn('Locale:', flatLocale)
        console.warn('Invalid path:', key)
        throw new Error(`Invalid locale key: ${key}`)
      }
      return flatLocale[key]
    }
  }, [])

  return t
}

export default useLocale
