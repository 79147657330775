import { SortOrder, TransactionHistorySorting } from '@solidant/spool-v2-fe-lib'

import { TransactionHistoryTableSort } from '@/types/table'

export interface TransactionHistoryState {
  page: number
  limit: number
  assetGroupId?: string
  marketId?: string
  filteredVaults: string[]
  filteredTransactionTypes?: string[]
  search?: string
  transactionHistorySort?: TransactionHistoryTableSort
}

export interface TransactionHistoryContextProps
  extends TransactionHistoryState {
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  setMarketId: (marketId?: string) => void
  setAssetGroupId: (assetGroupId?: string) => void
  setFilteredVaults: (vaults?: string[]) => void
  setTransactionTypes: (transactionType?: string[]) => void
  setSearch: (search: string) => void
  handleTransactionHistoryTableSort: (
    key: TransactionHistorySorting,
    sortType: TransactionHistoryTableSort
  ) => void
}

export enum TransactionHistoryActionTypes {
  SET_PAGE = 'SET_PAGE',
  SET_LIMIT = 'SET_LIMIT',
  SET_MARKET = 'SET_MARKET',
  SET_ASSET_GROUP = 'SET_ASSET_GROUP',
  SET_FILTERED_VAULTS = 'SET_FILTERED_VAULTS',
  SET_SEARCH = 'SET_SEARCH',
  SET_TRANSACTION_TYPES = 'SET_TRANSACTION_TYPES',
  SET_TRANSACTION_HISTORY_TABLE_SORT = 'SET_TRANSACTION_HISTORY_TABLE_SORT',
  SET_TRANSACTION_HISTORY_TABLE_SORT_DIRECTION = 'SET_TRANSACTION_HISTORY_TABLE_SORT_DIRECTION',
}

export type TransactionHistoryAction =
  | {
      type: TransactionHistoryActionTypes.SET_PAGE
      payload: number
    }
  | {
      type: TransactionHistoryActionTypes.SET_LIMIT
      payload: number
    }
  | {
      type: TransactionHistoryActionTypes.SET_MARKET
      payload: string
    }
  | {
      type: TransactionHistoryActionTypes.SET_ASSET_GROUP
      payload: string
    }
  | {
      type: TransactionHistoryActionTypes.SET_FILTERED_VAULTS
      payload: string[]
    }
  | {
      type: TransactionHistoryActionTypes.SET_SEARCH
      payload: string
    }
  | {
      type: TransactionHistoryActionTypes.SET_TRANSACTION_TYPES
      payload: string[]
    }
  | {
      type: TransactionHistoryActionTypes.SET_TRANSACTION_HISTORY_TABLE_SORT
      payload: TransactionHistorySorting
    }
  | {
      type: TransactionHistoryActionTypes.SET_TRANSACTION_HISTORY_TABLE_SORT_DIRECTION
      payload: SortOrder
    }
