import { Box, Typography } from '@mui/material'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import SectionColumn from '@/components/molecules/SectionColumn'
import SectionItem from '@/components/molecules/SectionItem'
import GuardsActionsTable from '@/components/organisms/CreateVault/Launch/GuardsActions/GuardsActionsTable'

import { formatNumber } from '@/utils/formats'

import { GuardsActionsData } from '@/types/create-vault'

const GuardsActions = () => {
  const { selectedGuards, selectedActions } = useCreateVaultState()

  const t = useLocale()

  const guardsAndActions = (): GuardsActionsData[] => {
    const guards: GuardsActionsData[] = Object.values(selectedGuards).map(
      (guard) => {
        return {
          type: guard.type,
          name: guard.title,
          conditions: guard.conditions,
        }
      }
    )
    const actions: GuardsActionsData[] = Object.values(selectedActions).map(
      (action) => {
        return {
          type: action.type,
          name: action.title,
          conditions: action.conditions,
        }
      }
    )
    return [...guards, ...actions]
  }

  return guardsAndActions().length === 0 ? (
    <></>
  ) : (
    <SectionColumn
      title={t('components.organisms.createVault.launch.guardsActions.title')}
      sx={{ paddingBottom: 0 }}
    >
      <Box
        width='100%'
        display='inline-grid'
        gridAutoColumns='2.05fr minmax(0, 1fr)'
        gridAutoFlow='column'
        gap={(theme) => theme.spacing(2)}
      >
        <GuardsActionsTable data={guardsAndActions()} />
        {Object.values(selectedActions).length > 0 && (
          <Box
            display='flex'
            flexDirection='column'
            gap={(theme) => theme.spacing(1.5)}
          >
            {Object.values(selectedActions)
              .filter((action) => !!action.actionData)
              .map(({ title, actionData }, index) => (
                <SectionItem key={index} title={title}>
                  <Typography variant='h6'>
                    {formatNumber(actionData.percent, 2)}%
                  </Typography>
                </SectionItem>
              ))}
          </Box>
        )}
      </Box>
    </SectionColumn>
  )
}

export default GuardsActions
