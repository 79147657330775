import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import ActionsCard from '@/components/organisms/CreateVault/Actions/ActionsCard'

import { Action } from '@/constants/createVault'
import { entryActions, exitActions } from '@/mockdata/create-vault.json'

const Actions = () => {
  const [focused, setFocused] = useState('')
  const { selectedActions, setStepCompletedStatus } = useCreateVaultState()

  useEffect(() => {
    const isCompleted =
      Object.values(selectedActions).filter(
        (action) => action.configured && action.checked
      ).length > 0 &&
      !Object.values(selectedActions).some(
        (action) => action.checked && !action.configured
      )
    setStepCompletedStatus(5, isCompleted)
  }, [selectedActions])

  return (
    <Section>
      <SectionColumn sx={{ gap: 0 }}>
        <Box
          display='inline-grid'
          gridAutoColumns='minmax(0, 1fr)'
          gridAutoFlow='column'
          gap={(theme) => theme.spacing(2)}
          width='100%'
        >
          <ActionsCard
            title='Entry Actions'
            avatar={<LoginIcon />}
            actions={entryActions as Action[]}
            focused={focused}
            setFocused={setFocused}
          />
          <ActionsCard
            title='Exit Actions'
            avatar={<LogoutIcon />}
            actions={exitActions as Action[]}
            focused={focused}
            setFocused={setFocused}
          />
        </Box>
      </SectionColumn>
    </Section>
  )
}

export default Actions
