import { SortOrder } from '@solidant/spool-v2-fe-lib'

import {
  InvestorDashboardAction,
  InvestorDashboardActionTypes,
  InvestorDashboardState,
} from '@/store/investorDashboard/investorDashboard.types'

const investorDashboardReducer = (
  state: InvestorDashboardState,
  action: InvestorDashboardAction
): InvestorDashboardState => {
  switch (action.type) {
    case InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_PAGE:
      return { ...state, investorSmartVaultPage: action.payload }
    case InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_LIMIT:
      return { ...state, investorSmartVaultLimit: action.payload }
    case InvestorDashboardActionTypes.CHANGE_INVESTOR_SMART_VAULT_SORT_DIRECTION:
      return {
        ...state,
        investorSmartVaultSort: {
          ...state.investorSmartVaultSort,
          direction:
            state.investorSmartVaultSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_SORT:
      return {
        ...state,
        investorSmartVaultSort: action.payload,
      }
    case InvestorDashboardActionTypes.SET_SELECTED_VAULTS:
      return { ...state, selectedVaults: action.payload }
    case InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_PAGE:
      return { ...state, investorStrategiesPage: action.payload }
    case InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_LIMIT:
      return { ...state, investorStrategiesLimit: action.payload }
    case InvestorDashboardActionTypes.CHANGE_INVESTOR_STRATEGY_SORT_DIRECTION:
      return {
        ...state,
        investorStrategiesSort: {
          ...state.investorStrategiesSort,
          direction:
            state.investorStrategiesSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_SORT:
      return {
        ...state,
        investorStrategiesSort: action.payload,
      }
    case InvestorDashboardActionTypes.SET_BALANCE_CHARTS_VALUE:
      return {
        ...state,
        balanceChartValues: action.payload,
      }
  }
}

export default investorDashboardReducer
