import { Box, Collapse, TableCell, TableRow } from '@mui/material'
import { UserClaimableNFTs } from '@solidant/spool-v2-fe-lib'
import { useMemo } from 'react'

import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import TableWrapper from '@/components/molecules/TableWrapper'
import CreatorVaultsMobileRowExpand from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsMobileRowExpand'
import CreatorVaultsRewardsTableRow from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsRewardsTableRow'

import { creatorDashboardRewardsHeadCells } from '@/constants/dashboard'

import { CreatorVault } from '@/types/dashboard'
interface CreatorVaultsTableRowExpandProps {
  row: CreatorVault
  vaultClaimableNFTs: UserClaimableNFTs[]
}

const CreatorVaultsTableRowExpand: React.FC<
  CreatorVaultsTableRowExpandProps
> = ({ row }) => {
  const { address, incentivizedRewards } = row

  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { selectedVaults } = useCreatorDashboardState()

  const isSelected = useMemo(
    () => !!selectedVaults.find((vault) => vault.address === address),
    [selectedVaults, row]
  )

  return belowTabletBreakpoint ? (
    <Collapse in={isSelected} timeout='auto' unmountOnExit>
      {incentivizedRewards?.rewards?.map((incentivizedReward) => {
        return (
          <CreatorVaultsMobileRowExpand
            key={row.address + '-' + incentivizedReward.asset.address}
            row={row}
            incentivizeReward={incentivizedReward}
          />
        )
      })}
    </Collapse>
  ) : (
    <TableRow>
      <TableCell sx={{ p: 0 }} colSpan={10}>
        <Collapse in={isSelected} timeout='auto' unmountOnExit>
          <Box
            sx={{ mx: 3, py: '0.5rem', mt: '0.5rem' }}
            display='flex'
            flexDirection='column'
            gap={2}
            borderTop={isSelected && '1px solid rgba(0, 0, 0, 0.12)'}
          >
            <TableWrapper
              header={creatorDashboardRewardsHeadCells}
              headerClass='default-table-head'
              headerSX={{ bgcolor: 'white', borderBottom: 'none' }}
              moreOptions
            >
              <>
                {incentivizedRewards?.rewards?.map((incentivizedReward) => {
                  return (
                    <CreatorVaultsRewardsTableRow
                      key={row.address + '-' + incentivizedReward.asset.address}
                      row={row}
                      incentivizeReward={incentivizedReward}
                    />
                  )
                })}
              </>
            </TableWrapper>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default CreatorVaultsTableRowExpand
