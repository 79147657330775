import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, IconButton, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const ValidNotification = () => {
  const t = useLocale()
  return (
    <Box
      display='flex'
      height='56px'
      alignItems='center'
      bgcolor='white'
      borderRadius='4px'
      gap='4px'
    >
      <IconButton disableRipple>
        <CheckCircleOutlineIcon className='lg' color='success' />
      </IconButton>
      <Typography variant='alertTitle'>
        {t('components.molecules.informational.validNotification')}
      </Typography>
    </Box>
  )
}

export default ValidNotification
