import { Box, CircularProgress, Typography } from '@mui/material'

interface DataLoadingProps {
  loadingText: string
}

const DataLoading: React.FC<DataLoadingProps> = ({ loadingText, ...rest }) => (
  <Box maxWidth='100%' display='flex' justifyContent='center' {...rest} p={1}>
    <Box display='flex' flexDirection='column' alignItems='center' gap='1.5rem'>
      <CircularProgress className='hook-loading' />
      <Typography>{loadingText}...</Typography>
    </Box>
  </Box>
)

export default DataLoading
