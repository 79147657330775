import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import SpoolSelect from '@/components/atoms/SpoolSelect'

import { ALLOCATION_PROVIDERS } from '@/config/sdk'
import { NOT_AVILABLE } from '@/constants'

const AllocationProviderSelector: React.FC = () => {
  const { chain } = useConnectionState()

  const t = useLocale()

  const { selectedAllocationProvider, setSelectedAllocationProvider } =
    useCreateVaultState()

  const allocationProvider = useMemo(() => ALLOCATION_PROVIDERS[chain], [chain])

  return (
    <Box
      display='flex'
      bgcolor='#FFFFFF'
      border='1px solid rgba(0, 0, 0, 0.12)'
      borderRadius='4px'
      sx={{ px: (theme) => theme.spacing(3), py: (theme) => theme.spacing(2) }}
    >
      <Box display='flex'>
        <Box width='220px'>
          <SpoolSelect
            value={selectedAllocationProvider ?? ''}
            label={t(
              'components.organisms.createVault.launch.allocation.dropdown'
            )}
            handleChange={(event: SelectChangeEvent<unknown>) => {
              const selectedAllocationProvider = event.target.value as string

              setSelectedAllocationProvider(selectedAllocationProvider)
            }}
          >
            {Object.entries(ALLOCATION_PROVIDERS[chain]).map(
              ([address, details]) => (
                <MenuItem
                  key={address}
                  value={address}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 12px',
                    gap: '0.8rem',
                  }}
                >
                  <Box height='24px' className='allocation-provider-icon'>
                    {details.icon}
                  </Box>
                  <Box>
                    <Typography
                      fontSize='14px'
                      className='allocation-provider-type'
                    >
                      {details.name}
                    </Typography>
                    <Typography
                      fontSize='12px'
                      color='gray'
                      className='allocation-provider-description'
                    >
                      {details?.type}
                    </Typography>
                  </Box>
                </MenuItem>
              )
            )}
          </SpoolSelect>
        </Box>
        <Box marginLeft='2rem' display='flex' alignItems='center' gap='0.5rem'>
          <IconButton sx={{ padding: '4px' }} disableRipple>
            <InfoIcon className='lg' sx={{ color: 'gray' }} />
          </IconButton>
          <Typography fontSize='14px'>
            {allocationProvider[selectedAllocationProvider]
              ? allocationProvider[selectedAllocationProvider].description
              : NOT_AVILABLE}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default AllocationProviderSelector
