import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Fragment } from 'react'
import GaugeChart from 'react-gauge-chart'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import { MainContainer } from '@/components/atoms/MainContainer'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import VaultPageTitleDetails from '@/components/atoms/VaultPageTitleDetails'
import BackButton from '@/components/molecules/BackButton'
import ChainDetails from '@/components/organisms/SmartVaults/VaultDetails/ChainDetails'

import Verified from '@/assets/icons/general/Verified'

import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'

const VaultTitle = () => {
  const { chain } = useConnectionState()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const navigate = useNavigate()

  const { isVaultsRowClicked, setIsVaultsRowClicked } = useUniversalNavigation()

  const t = useLocale()

  const handleClick = () => {
    if (isVaultsRowClicked) {
      history.back()
      setIsVaultsRowClicked((state) => !state)
      return
    }
    navigate(Routes(chain).smartVaults.root.url)
  }

  const { isLoading, smartVaultData } = useVaultsPage()

  return (
    <>
      {!isLoading && !!Object.values(smartVaultData).length && (
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          paddingTop='1rem'
          sx={{
            bgcolor: '#006BA60D',
          }}
          mb='1rem'
        >
          <MainContainer mb='1rem'>
            <BackButton handleClick={handleClick} />
            <Box
              display='flex'
              flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
              justifyContent='space-between'
              alignItems={belowPhabletBreakpoint ? 'start' : 'center'}
              gap={belowPhabletBreakpoint ? '0rem' : '1rem'}
              marginTop='2rem'
              paddingLeft='0.3rem'
            >
              <Box
                display='flex'
                alignItems='center'
                gap='1rem'
                ml={belowPhabletBreakpoint ? '0.2rem' : '0rem'}
              >
                {smartVaultData.assetGroup &&
                  smartVaultData.assetGroup?.assetGroupTokens.map(
                    ({ address }, index) => (
                      <Fragment key={index}>
                        <Box
                          display='flex'
                          sx={{
                            position: 'relative',
                            ml: index !== 0 ? '-12px' : '0',
                            transform: 'scale(2)',
                          }}
                        >
                          {mapAssetTokenIcon(chain, address ?? '')}
                        </Box>
                      </Fragment>
                    )
                  )}
                <Box display='flex' flexDirection='column'>
                  <Typography variant='body1' fontSize='24px'>
                    {smartVaultData.name}
                  </Typography>
                  {smartVaultData.createdBy && (
                    <Box display='flex' gap='0.3rem' alignItems='center'>
                      <Typography variant='caption' color='gray'>
                        Created By
                      </Typography>
                      <Typography variant='caption'>
                        {smartVaultData.createdBy}
                      </Typography>
                      <Tooltip placement='right' title='Official Partner'>
                        <IconButton sx={{ padding: '0' }}>
                          <Verified />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>
              <ChainDetails />
            </Box>

            <Box
              display='flex'
              flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
              justifyContent='space-between'
              alignItems={belowPhabletBreakpoint ? 'start' : 'center'}
              gap='1rem'
              marginTop='4rem'
              paddingLeft='0.3rem'
            >
              <Box display='flex' gap='4rem'>
                <VaultPageTitleDetails
                  title={t(
                    'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.adjustedApy.label'
                  )}
                  value={
                    <Typography fontSize='20px'>
                      {formatUSD(smartVaultData.adjustedApy)} %
                    </Typography>
                  }
                />
                {/* <VaultPageTitleDetails
                  title={t(
                    'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.historicalAverageApy.label'
                  )}
                  value={
                    <Typography fontSize='20px'>
                      {formatUSD(smartVaultData.adjustedApy)} %
                    </Typography>
                  }
                /> */}
                <VaultPageTitleDetails
                  title={t(
                    'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.volumeRouted.label'
                  )}
                  value={
                    <Typography fontSize='20px'>{`$ ${formatUSD(
                      smartVaultData.totalVolumeRouted?.total ?? '0'
                    )}`}</Typography>
                  }
                />
                {!belowPhabletBreakpoint && (
                  <VaultPageTitleDetails
                    title={t(
                      'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.riskScore.label'
                    )}
                    value={
                      <Box display='flex' justifyContent='center'>
                        <GaugeChart
                          nrOfLevels={3}
                          percent={+smartVaultData.riskScore / 10}
                          colors={['#4CAF50', '#2E7D32', '#FFB74D']}
                          cornerRadius={0}
                          hideText
                          arcWidth={0.5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '45px',
                          }}
                        />
                        <Typography fontSize='20px'>
                          {formatUSD(smartVaultData.riskScore || 0, 1, 1)}/10
                        </Typography>
                      </Box>
                    }
                  />
                )}
              </Box>
              {!belowPhabletBreakpoint && (
                <VaultPageTitleDetails
                  title={t(
                    'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.myBalance.label'
                  )}
                  value={
                    <Typography fontSize='20px'>{`$ ${formatUSD(
                      (+smartVaultData.balance.total ?? 0) +
                        (+smartVaultData.pendingDeposits.totalUSD ?? 0)
                    )}`}</Typography>
                  }
                  textAlign='right'
                />
              )}
              {belowPhabletBreakpoint && (
                <Box display='flex' gap='3.5rem'>
                  <VaultPageTitleDetails
                    title={t(
                      'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.riskScore.label'
                    )}
                    value={
                      <Box display='flex' justifyContent='center'>
                        <GaugeChart
                          nrOfLevels={3}
                          percent={+smartVaultData.riskScore / 10}
                          colors={['#4CAF50', '#2E7D32', '#FFB74D']}
                          cornerRadius={0}
                          hideText
                          arcWidth={0.5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '45px',
                          }}
                        />
                        <Typography fontSize='20px'>
                          {formatUSD(smartVaultData.riskScore || 0, 1, 1)}/10
                        </Typography>
                      </Box>
                    }
                  />
                  <VaultPageTitleDetails
                    title={t(
                      'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.myBalance.label'
                    )}
                    value={
                      <Typography fontSize='20px'>{`$ ${formatUSD(
                        (+smartVaultData.balance.total ?? 0) +
                          (+smartVaultData.pendingDeposits.totalUSD ?? 0)
                      )}`}</Typography>
                    }
                    textAlign={belowPhabletBreakpoint ? 'left' : 'right'}
                  />
                </Box>
              )}
            </Box>
          </MainContainer>
        </Box>
      )}
    </>
  )
}

export default VaultTitle
