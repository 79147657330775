import { useContext } from 'react'

import { TransactionHistoryContext } from '@/store/transactionHistory/transactionHistory.context'

const useTransactionHistoryState = () => {
  const transactionHistoryContext = useContext(TransactionHistoryContext)
  if (!Object.keys(transactionHistoryContext).length) {
    throw new Error(
      'TransactionHistoryContext must be used within its provider'
    )
  }
  return transactionHistoryContext
}

export default useTransactionHistoryState
