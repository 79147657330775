import { PropsWithChildren } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'

import VaultFeature from '@/components/molecules/VaultFeature'
import VaultFeatureGroup from '@/components/molecules/VaultFeatureGroup'
import VaultFeaturesChecklist from '@/components/molecules/VaultFeaturesChecklist'
import ActionItem from '@/components/organisms/CreateVault/Actions/ActionItem'

import { Action } from '@/constants/createVault'

interface ActionsCardProps extends PropsWithChildren {
  title: string
  avatar: React.ReactNode
  actions: Action[]
  focused: string
  setFocused: (focused: string) => void
}

const ActionsCard: React.FC<ActionsCardProps> = ({
  title,
  avatar,
  actions,
  focused,
  setFocused,
}) => {
  const { selectedActions, setActionsChecked, setActionsConfigured } =
    useCreateVaultState()

  const handleChange = (
    id: string,
    checked: boolean,
    title: string,
    type: string
  ) => {
    setFocused(id)
    setActionsChecked(id, checked, title, type)
    if (
      !actions.some(
        (action) => action.items.find((item) => item.id === id)?.form
      )
    ) {
      setActionsConfigured(id, checked)
    }
  }

  return (
    <VaultFeaturesChecklist title={title} avatar={avatar}>
      {actions.map((action) => (
        <VaultFeatureGroup key={action.title} title={action.title}>
          {action.items.map(({ id, title, description, form, type }) => (
            <VaultFeature
              key={id}
              id={id}
              title={title}
              description={description}
              checked={!!selectedActions[id]?.checked}
              handleChecked={() =>
                handleChange(id, !selectedActions[id]?.checked, title, type)
              }
              focus={focused === id}
              setFocused={setFocused}
            >
              {!!form && <ActionItem itemId={id} form={form} />}
            </VaultFeature>
          ))}
        </VaultFeatureGroup>
      ))}
    </VaultFeaturesChecklist>
  )
}

export default ActionsCard
