import { useContext } from 'react'

import { StrategiesContext } from '@/store/strategies/strategies.context'

const useStrategiesState = () => {
  const strategiesContext = useContext(StrategiesContext)
  if (!Object.keys(strategiesContext).length) {
    throw new Error('StrategiesContext must be used within its provider')
  }
  return strategiesContext
}

export default useStrategiesState
