import WarningIcon from '@mui/icons-material/Warning'
import { Box } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const StrategiesDisabledTooltip = () => {
  const t = useLocale()

  return (
    <Box
      gap={(theme) => theme.spacing(2)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <WarningIcon className='sm' sx={{ color: 'primary.main' }} />
      {t('components.organisms.tooltips.StrategiesDisabledTooltip.title')}
    </Box>
  )
}

export default StrategiesDisabledTooltip
