import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography } from '@mui/material'
import { Datum } from '@nivo/line'
import { InvestorDepositsHistory } from '@solidant/spool-v2-fe-lib'
import { useEffect, useMemo, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useInvestorDashboardState from '@/hooks/context/useInvestorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useInvestorDashboard from '@/hooks/dashboard/useInvestorDashboard'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { tokenDetails } from '@/components/atoms/Tokens'
import SectionColumn from '@/components/molecules/SectionColumn'
import DashboardLineChart from '@/components/organisms/Charts/DashboardLineChart'
import DashboardPieChart from '@/components/organisms/Charts/DashboardPieChart'
import DashboardPieChartLegend from '@/components/organisms/Dashboard/Investor/InvestorPieChartLegend'

import { currency } from '@/constants/currency'
import { getHistoricalDatum } from '@/utils/accumulateHistoricalData'
import { formatUSD } from '@/utils/formats'
import { calculateInvestorAssetAllocation } from '@/utils/helpers/calculateAssetAllocation'
import { calculateDashboardStrategyAllocation } from '@/utils/helpers/calculateStrategyAllocation'

const InvestorAllocations = () => {
  const t = useLocale()

  const { belowTabletBreakpoint, belowPhabletBreakpoint } =
    useBreakpointDetection()

  const { chain } = useConnectionState()
  const { selectedVaults } = useInvestorDashboardState()

  const { vaults, balanceHistory, metrics } = useInvestorDashboard()
  const [balanceChartValues, setBalanceChartValues] = useState<Datum[]>([])

  useEffect(() => {
    if (vaults) {
      const vaultsIds =
        /*selectedVaults.length ? selectedVaults : */ vaults.map(
          ({ address }) => address
        )

      const vaultsBalanceHistory = Object.entries(balanceHistory).reduce(
        (acc, [key, value]) => {
          if (vaultsIds.includes(key)) {
            acc.push(value)
            return acc
          }

          return acc
        },
        [] as InvestorDepositsHistory[][]
      )

      setBalanceChartValues(
        getHistoricalDatum(vaultsBalanceHistory, 'userBalance')
      )
    }
  }, [selectedVaults])

  const totalDepositBalance = useMemo(
    () => +metrics?.depositedAmount + +metrics?.pendingDeposits,
    [metrics]
  )

  const assetAllocation = useMemo(
    () => calculateInvestorAssetAllocation(vaults),
    [vaults]
  )

  const strategyAllocation = useMemo(
    () => calculateDashboardStrategyAllocation(vaults),
    [vaults]
  )

  const sumAssets = Object.values(assetAllocation).reduce(
    (acc: number, allocation) => acc + allocation,
    0
  )

  const sumStrategies = Object.values(strategyAllocation).reduce(
    (acc: number, allocation) => acc + allocation,
    0
  )

  return (
    <SectionColumn
      title={t(
        'components.organisms.dashboard.investor.investorAllocations.vaultsAllocation.sectionTitle'
      )}
      sx={{ px: 0, py: '4rem', mb: belowTabletBreakpoint ? '6rem' : '0rem' }}
    >
      <Box
        border='1px solid rgba(8, 8, 8, 0.12)'
        padding='2rem'
        display='flex'
        flexDirection='column'
      >
        <Box display='flex' gap={0.5} alignItems='start' pb='2rem'>
          <InfoOutlinedIcon sx={{ color: 'text.secondary' }} fontSize='small' />
          <Typography variant='body2' fontSize='12px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.investor.investorMetrics.summary.chart.title'
            )}
          </Typography>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          sx={{ height: '100%', mb: 1, pb: '2rem' }}
        >
          <Box flexGrow={1} pb={2}>
            <Typography variant='body2' color='gray'>
              Deposit Balance
            </Typography>
            <Typography variant='h6' fontWeight={500}>
              {currency} {formatUSD(totalDepositBalance)}
            </Typography>
          </Box>
          <DashboardLineChart
            data={[
              {
                id: 'Deposit Balance',
                color: '#039BE5',
                data: balanceChartValues,
              },
            ]}
            xTickLines={0}
          />
        </Box>
        {!!sumAssets && (
          <Box
            display='flex'
            justifyContent='space-between'
            pt='2rem'
            borderTop='1px solid rgba(8, 8, 8, 0.12)'
            flexDirection={belowTabletBreakpoint ? 'column' : 'row'}
          >
            <SectionColumn
              title={t(
                'components.organisms.dashboard.investor.investorAllocations.yourAssetAllocation.sectionTitle'
              )}
              noPadding
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: (theme) => theme.spacing(1),
                borderBottomLeftRadius: (theme) => theme.spacing(1),
                borderBottomRightRadius: (theme) => theme.spacing(1),
                mb: '2rem',
                maxWidth: belowPhabletBreakpoint ? '100%' : '50%',
              }}
            >
              <Box
                display='flex'
                gap={3}
                alignContent='center'
                flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
              >
                <DashboardPieChart assets={assetAllocation} format='vaults' />
                <DashboardPieChartLegend
                  allocationData={assetAllocation}
                  sum={sumAssets}
                  formatLabel={(tokenAddress) =>
                    tokenDetails[chain][tokenAddress].symbol
                  }
                  gridTemplateColumns='1fr 1fr 1fr'
                />
              </Box>
            </SectionColumn>
            <SectionColumn
              noPadding
              sx={{
                bgcolor: 'white',
                borderTopRightRadius: (theme) => theme.spacing(1),
                borderBottomRightRadius: (theme) => theme.spacing(1),
                mb: '2rem',
              }}
              title={t(
                'components.organisms.dashboard.investor.investorCharts.balance.sectionTitle'
              )}
            >
              <Box
                display='flex'
                gap={3}
                alignContent='center'
                flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
              >
                <DashboardPieChart
                  assets={strategyAllocation}
                  format='strategies'
                />
                <DashboardPieChartLegend
                  allocationData={strategyAllocation}
                  sum={sumStrategies}
                  formatLabel={(strategy) => strategy}
                  gridTemplateColumns='3fr 0.5fr 2fr'
                />
              </Box>
            </SectionColumn>
          </Box>
        )}
      </Box>
    </SectionColumn>
  )
}

export default InvestorAllocations
