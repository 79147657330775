import { Box } from '@mui/material'
import { useMemo } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useInvestorDashboard from '@/hooks/dashboard/useInvestorDashboard'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import DashboardMetricsField from '@/components/molecules/DashboardMetricsField'
import SectionColumn from '@/components/molecules/SectionColumn'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

const InvestorMetrics = () => {
  const t = useLocale()
  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { metrics, totalUserRewards, vaults } = useInvestorDashboard()

  const totalDepositBalance = useMemo(
    () => +metrics?.depositedAmount + +metrics?.pendingDeposits,
    [metrics]
  )

  return (
    <Box display='flex' gap={2}>
      <SectionColumn
        noVerticalGap
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
          background: '#ffffff',
          flex: 1,
          paddingX: '1.5rem',
          padding: belowTabletBreakpoint ? '1.5rem' : '1rem',
          textAlign: 'end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: belowTabletBreakpoint ? 'column' : 'row',
            gap: belowTabletBreakpoint ? '1rem' : '',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.investor.investorMetrics.summary.sectionItems.depositsBalance.title'
            )}
            value={`${currency} ${formatUSD(totalDepositBalance ?? '0')}`}
          />
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.investor.investorMetrics.summary.sectionItems.incentives.title'
            )}
            value={`${currency} ${formatUSD(totalUserRewards ?? 0)}`}
          />

          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.investor.investorMetrics.summary.sectionItems.vaults.title'
            )}
            value={`${vaults.length ?? 0}`}
          />
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.investor.investorMetrics.summary.sectionItems.pendingWithdrawals.title'
            )}
            value={`${currency} ${formatUSD(metrics?.pendingWithdrawals ?? 0)}`}
          />
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.investor.investorMetrics.summary.sectionItems.readyToWithdraw.title'
            )}
            value={`${currency} ${formatUSD(
              metrics?.readyToWithdrawAmount ?? 0
            )}`}
          />
        </Box>
      </SectionColumn>
    </Box>
  )
}

export default InvestorMetrics
