import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { InvestorSmartVault } from '@solidant/spool-v2-fe-lib'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useInvestorDashboardState from '@/hooks/context/useInvestorDashboardState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useUserClaimableNfts from '@/hooks/user/useUserClaimableNfts'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import InvestorTableRowExpand from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorTableRowExpand'
import InvestorTableRowWithdrawal from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorTableRowWithdrawal'
import InvestorVaultOptions from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorVaultOptions'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { currency } from '@/constants/currency'
import { gaIds } from '@/constants/googleAnalyticIDs'
import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'

interface InvestorVaultTableRowProps {
  row: InvestorSmartVault
  optimisticallyBurnDNfts: (
    vaultAddress: string,
    dNftIds: string[]
  ) => Promise<void>
}

const InvestorVaultTableRow: React.FC<InvestorVaultTableRowProps> = ({
  row,
  optimisticallyBurnDNfts,
}) => {
  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { userClaimablewNFTs } = useUserClaimableNfts()

  const { chain } = useConnectionState()

  const {
    address,
    name,
    assetGroup,
    balance,
    totalClaimableRewards,
    pendingDeposits,
    pendingWithdrawals,
    wNfts,
  } = row

  const vaultClaimableNFTs = useMemo(() => {
    if (!userClaimablewNFTs) return []

    return userClaimablewNFTs.filter(({ vaultId }) => vaultId === address)
  }, [userClaimablewNFTs])

  const { setIsVaultsRowClicked } = useUniversalNavigation()

  const { selectedVaults, setSelectedVaults } = useInvestorDashboardState()

  const isSelected = useMemo(
    () => !!selectedVaults.find((vault) => vault.address === address),
    [selectedVaults, row]
  )

  const onRowClick = () => {
    setIsVaultsRowClicked(true)
  }

  const onCheckboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const updatedVaults = isSelected
      ? selectedVaults.filter((vault) => vault.address !== address)
      : [...selectedVaults, row]

    setSelectedVaults(updatedVaults)
  }

  return (
    <>
      <TableRow
        component={Link}
        to={`${Routes(chain).smartVaults.root.url}/${address}`}
        sx={{
          '& > td': {
            borderBottom: 'unset',
          },

          backgroundColor: '#fff',
        }}
        onClick={onRowClick}
        id={
          gaIds.smartVaultRow.main + '-' + gaIds.smartVaultRow.investorDashboard
        }
      >
        <TableCell sx={{ border: 'unset', width: '90px' }}>
          <AssetGroupTokens assetGroup={assetGroup} />
        </TableCell>
        <TableCell sx={{ pl: 0, border: 'unset' }}>
          <Box
            display='flex'
            justifyContent='left'
            minWidth={belowTabletBreakpoint ? '150px' : '300px'}
            gap={2}
          >
            <NameCellContent>
              <SmartVaultNameTableTypography variant='body2'>
                {name}
              </SmartVaultNameTableTypography>
            </NameCellContent>
          </Box>
        </TableCell>
        <TableCell align='right' sx={{ border: 'unset' }}>
          <Typography variant='subtitle2'>
            {currency} {formatUSD(+balance.total + +pendingDeposits.totalUSD)}
          </Typography>
        </TableCell>
        <TableCell align='right' sx={{ border: 'unset' }}>
          <Stack>
            <Typography variant='subtitle2'>
              {currency} {formatUSD(totalClaimableRewards)}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ border: 'unset' }}>
          <Box display='flex' justifyContent='space-around' position='relative'>
            <InvestorVaultOptions
              row={row}
              optimisticallyBurnDNfts={optimisticallyBurnDNfts}
            />
            <IconButton
              size='small'
              onClick={(event) => onCheckboxClick(event)}
            >
              {isSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <InvestorTableRowExpand row={row} />
      {(!!wNfts?.length || !!+pendingWithdrawals.total) && (
        <InvestorTableRowWithdrawal
          vaultClaimableNFTs={vaultClaimableNFTs}
          row={row}
        />
      )}
    </>
  )
}

export default InvestorVaultTableRow
