import { Box, List, ListItem, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const DepositDisclaimer = () => {
  const t = useLocale()

  return (
    <Box paddingX='1rem'>
      <List
        sx={{
          // listStyleType: 'disc',
          color: 'text.secondary',
        }}
      >
        <ListItem
          sx={{
            display: 'list-item',
          }}
          disablePadding
        >
          <Typography fontSize='12px'>
            {t('pages.smartVaults.vaultDetailsPage.depositDisclaimer.1stPoint')}
          </Typography>
        </ListItem>
      </List>
    </Box>
  )
}

export default DepositDisclaimer
