import { TableBody } from '@mui/material'
import Box from '@mui/material/Box'
import { GuardConditions } from '@solidant/spool-v2-fe-lib'
import { Fragment } from 'react'

import TableWrapper from '@/components/molecules/TableWrapper'
import ConditionsTableRow from '@/components/organisms/SmartVaults/CollapsibleRow/DepositConditions/GuardConditionsTableRow'

import { guardConditionsHeadCells } from '@/constants/smartVault'

interface GuardConditionsTableProps {
  guardConditions?: GuardConditions[]
}

const GuardConditionsTable: React.FC<GuardConditionsTableProps> = ({
  guardConditions,
}) => {
  return (
    <>
      <Box
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
      >
        <TableWrapper
          header={guardConditionsHeadCells}
          headerClass='default-table-head'
        >
          <TableBody>
            {guardConditions
              // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((guardConditions: GuardConditions, index) => (
                <Fragment key={index}>
                  <ConditionsTableRow guardConditions={guardConditions} />
                </Fragment>
              ))}
          </TableBody>
        </TableWrapper>
      </Box>
      {/* <Box display='flex' gap={1} alignItems='center'>
        <InfoIcon color='disabled' />
        <Typography variant='body2'>
          {t(
            'components.organisms.smartVaults.collapsibleRow.guardConditions.infoText'
          )}
        </Typography>
      </Box> */}
    </>
  )
}

export default GuardConditionsTable
