import { Box, Stack, styled, TextField, Typography } from '@mui/material'
import { useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'

import ValidityTag from '@/components/atoms/ValidityTag'
import TransactionInput from '@/components/molecules/TransactionInput'

import { ActionItemForm } from '@/constants/createVault'

interface ActionItemProps {
  itemId: string
  form: ActionItemForm
}

const ActionBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const ActionItem: React.FC<ActionItemProps> = ({ itemId, form }) => {
  const { selectedActions, setActionsConfigured, setActionsData } =
    useCreateVaultState()
  const [fee, setFee] = useState(
    selectedActions[itemId]?.actionData
      ? selectedActions[itemId].actionData.percent
      : 0
  )
  const [period, setPeriod] = useState(
    selectedActions[itemId]?.actionData
      ? selectedActions[itemId]?.actionData.period
      : 0
  )

  const handleChangeFee = (value: number) => {
    setFee(value)
    if (value <= 100 && value > 0) {
      setActionsData(itemId, {
        percent: value,
        period: form.namePeriod ? period : undefined,
      })
    }
    setActionsConfigured(
      itemId,
      value <= 100 && value > 0 && (!form.namePeriod || period > 0)
    )
  }

  const handleChangePeriod = (value: number) => {
    setPeriod(value)
    setActionsData(itemId, {
      percent: fee,
      period: value,
    })
    setActionsConfigured(
      itemId,
      fee <= 100 && fee > 0 && (!form.namePeriod || value > 0)
    )
  }

  return (
    <Stack
      padding={(theme) => theme.spacing(0, 2, 2, 2)}
      gap={(theme) => theme.spacing(2)}
    >
      <ActionBox>
        <Typography variant='body2'>{form.nameFee}</Typography>
        <TransactionInput
          maxFiatValue={form.max.toString() || '0'}
          value={fee.toString()}
          setValue={handleChangeFee}
          sx={{ width: '180px', marginLeft: 'auto' }}
          inputLabel=''
        />
      </ActionBox>
      {form.namePeriod && (
        <ActionBox>
          <Typography variant='body2'>{form.namePeriod}</Typography>
          <TextField
            size='small'
            label={form.labelPeriod}
            onChange={(event) =>
              Number(event.target.value) &&
              handleChangePeriod(Number(event.target.value))
            }
            value={period}
            sx={{
              width: '180px',
              marginLeft: 'auto',
              '& .MuiInputBase-root': {
                fontWeight: 500,
              },
            }}
          />
        </ActionBox>
      )}
      {fee <= 100 && fee > 0 && (!form.namePeriod || period > 0) && (
        <ValidityTag label='Valid' />
      )}
    </Stack>
  )
}

export default ActionItem
