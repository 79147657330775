const MetaVaultIcon = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.33333 12L0 4H12L10.6667 12H1.33333ZM4.66667 8H7.33333C7.52222 8 7.68056 7.93611 7.80833 7.80833C7.93611 7.68056 8 7.52222 8 7.33333C8 7.14444 7.93611 6.98611 7.80833 6.85833C7.68056 6.73056 7.52222 6.66667 7.33333 6.66667H4.66667C4.47778 6.66667 4.31944 6.73056 4.19167 6.85833C4.06389 6.98611 4 7.14444 4 7.33333C4 7.52222 4.06389 7.68056 4.19167 7.80833C4.31944 7.93611 4.47778 8 4.66667 8ZM2 3.33333C1.81111 3.33333 1.65278 3.26944 1.525 3.14167C1.39722 3.01389 1.33333 2.85556 1.33333 2.66667C1.33333 2.47778 1.39722 2.31944 1.525 2.19167C1.65278 2.06389 1.81111 2 2 2H10C10.1889 2 10.3472 2.06389 10.475 2.19167C10.6028 2.31944 10.6667 2.47778 10.6667 2.66667C10.6667 2.85556 10.6028 3.01389 10.475 3.14167C10.3472 3.26944 10.1889 3.33333 10 3.33333H2ZM3.33333 1.33333C3.14444 1.33333 2.98611 1.26944 2.85833 1.14167C2.73056 1.01389 2.66667 0.855556 2.66667 0.666667C2.66667 0.477778 2.73056 0.319444 2.85833 0.191667C2.98611 0.0638889 3.14444 0 3.33333 0H8.66667C8.85556 0 9.01389 0.0638889 9.14167 0.191667C9.26944 0.319444 9.33333 0.477778 9.33333 0.666667C9.33333 0.855556 9.26944 1.01389 9.14167 1.14167C9.01389 1.26944 8.85556 1.33333 8.66667 1.33333H3.33333Z'
        fill='#212322'
        fillOpacity='0.6'
      />
    </svg>
  )
}

export default MetaVaultIcon
