import { Box, Stack, Typography } from '@mui/material'

import { mapIcons } from '@/components/atoms/CryptoIcons'

import { RiskModelStrategiesDto } from '@/types/create-vault'

interface StrategiesTooltipProps {
  strategies: RiskModelStrategiesDto[]
}

const StrategiesTooltip: React.FC<StrategiesTooltipProps> = ({
  strategies,
}) => {
  return (
    <Stack
      width='600px'
      maxWidth='none'
      maxHeight='450px'
      overflow='auto'
      className='customScrollbar'
      display='grid'
      gridTemplateColumns='1fr 1fr'
      gridAutoRows='auto'
      sx={{ mt: 0 }}
    >
      {strategies.map(({ chainId, name }, index) => (
        <Box
          display='flex'
          key={index}
          alignItems='center'
          justifyContent='space-between'
          py='6px'
          width='calc(100% - 16px)'
          borderBottom={
            index === strategies.length - 1 ? '' : '1px solid rgba(0,0,0,0.2)'
          }
        >
          <Box display='flex' alignItems='center'>
            {mapIcons(chainId)}
            <Typography
              variant='body2'
              sx={{
                overflowWrap: 'anywhere',
                whiteSpace: 'normal',
                marginLeft: '0.5rem',
              }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Stack>
  )
}

export default StrategiesTooltip
