import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import SectionColumn from '@/components/molecules/SectionColumn'

const NoteDetails = () => {
  const t = useLocale()

  return (
    <SectionColumn
      sx={{
        padding: '0',
        marginBottom: '1rem',
      }}
      noVerticalGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.disclaimer'
      )}
    >
      <Typography
        variant='caption'
        fontSize='12px'
        color='text.secondary'
        sx={{ whiteSpace: 'normal', overflowY: 'auto' }}
      >
        {t('pages.smartVaults.vaultDetailsPage.notes')}
      </Typography>
    </SectionColumn>
  )
}

export default NoteDetails
