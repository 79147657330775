const EqualAllocation = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.59 4L16 5.41L12.96 8.83L11.55 10.42L6 16.5L5.5 17L4 15.53L4.5 15L10.38 8.73L11.74 7.21L14.59 4Z'
        fill='black'
        fillOpacity='0.54'
      />
      <path
        d='M17.59 7L19 8.41L15.96 11.83L14.55 13.42L9 19.5L8.5 20L7 18.53L7.5 18L13.38 11.73L14.74 10.21L17.59 7Z'
        fill='black'
        fillOpacity='0.54'
      />
    </svg>
  )
}

export default EqualAllocation
