import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { createContext } from 'react'

import {
  IncentiviseVaultContextProps,
  IncentiviseVaultState,
} from '@/store/incentiviseVault/incentiviseVault.types'

export const initialState: IncentiviseVaultState = {
  tablePage: 0,
  tableRowsPerPage: 10,
  tableSortType: {
    key: 'address',
    direction: SortOrder.DESC,
  },
  openId: undefined,
  page: undefined,
  targetToken: undefined,
  tokenAmount: '',
  days: '',
  isCustomToken: false,
  tokenTableSortType: {
    key: 'name',
    direction: SortOrder.DESC,
  },
  customTokenAddress: '',
}

export const IncentivizeVaultContext =
  createContext<IncentiviseVaultContextProps>({
    ...initialState,
    setTablePage: () => undefined,
    setTableRowPerPage: () => undefined,
    setTableSortType: () => undefined,
    handleSortClick: () => undefined,
    setOpenId: () => undefined,
    setPage: () => undefined,
    setTargetToken: () => undefined,
    setTokenAmount: () => undefined,
    setDays: () => undefined,
    setIsCustomToken: () => undefined,
    setTokenTableSortType: () => undefined,
    handleTokenTableSortClick: () => undefined,
    setCustomTokenAddress: () => undefined,
  })
