import {
  IncentiviseVaultState,
  IncentivizeVaultAction,
  IncentivizeVaultActionTypes,
} from '@/store/incentiviseVault/incentiviseVault.types'

const incentivizeVaultReducer = (
  state: IncentiviseVaultState,
  action: IncentivizeVaultAction
): IncentiviseVaultState => {
  switch (action.type) {
    case IncentivizeVaultActionTypes.SET_TABLE_PAGE:
      return { ...state, tablePage: action.payload }
    case IncentivizeVaultActionTypes.SET_TABLE_ROW_PER_PAGE:
      return { ...state, tableRowsPerPage: action.payload }
    case IncentivizeVaultActionTypes.SET_TABLE_SORT_TYPE:
      return { ...state, tableSortType: action.payload }
    case IncentivizeVaultActionTypes.SET_OPEN_ID:
      return { ...state, openId: action.payload }
    case IncentivizeVaultActionTypes.SET_PAGE:
      return { ...state, page: action.payload }
    case IncentivizeVaultActionTypes.SET_TARGET_TOKEN:
      return { ...state, targetToken: action.payload }
    case IncentivizeVaultActionTypes.SET_TOKEN_AMOUNT:
      return { ...state, tokenAmount: action.payload }
    case IncentivizeVaultActionTypes.SET_DAYS:
      return { ...state, days: action.payload }
    case IncentivizeVaultActionTypes.SET_IS_CUSTOM_TOKEN:
      return { ...state, isCustomToken: action.payload }
    case IncentivizeVaultActionTypes.SET_TOKEN_TABLE_SORT_TYPE:
      return { ...state, tokenTableSortType: action.payload }
    case IncentivizeVaultActionTypes.SET_CUSTOM_TOKEN_ADDRESS:
      return { ...state, customTokenAddress: action.payload }
  }
}

export default incentivizeVaultReducer
