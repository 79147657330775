import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useModalState from '@/hooks/context/useModalState'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import AssetsModal from '@/components/organisms/CreateVault/Guards/Modals/AssetsModal/AssetsModal'
import SVTModal from '@/components/organisms/CreateVault/Guards/Modals/SVTModal/SVTModal'
import WhitelistModal from '@/components/organisms/CreateVault/Guards/Modals/WhitelistModal/WhitelistModal'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Guards, Role } from '@/store/createVault/createVault.types'

import { GuardModal } from '@/constants/createVault'

interface GuardsModalsContainerProps {
  modalId: GuardModal
  guardItemId: string
  title: string
  breadcrumbs: string[]
}

const GuardsModalsContainer: React.FC<GuardsModalsContainerProps> = ({
  modalId,
  guardItemId,
  title,
  breadcrumbs,
}) => {
  const { closeModal } = useModalState()
  const { setSelectedGuards } = useCreateVaultState()

  const [initialConditions, setInitialConditions] = useState<Guards>({})

  const modals: { [key in GuardModal]: React.ReactNode } = {
    [GuardModal.WHITELIST_DEPOSITORS]: (
      <WhitelistModal
        id={guardItemId}
        role={Role.DEPOSITOR}
        setInitialConditions={setInitialConditions}
      />
    ),
    [GuardModal.WHITELIST_BENEFICIARIES]: (
      <WhitelistModal
        id={guardItemId}
        role={Role.BENEFICIARIES}
        setInitialConditions={setInitialConditions}
      />
    ),
    [GuardModal.WHITELIST_SVT]: (
      <WhitelistModal
        id={guardItemId}
        role={Role.NO_ROLE}
        setInitialConditions={setInitialConditions}
      />
    ),
    [GuardModal.NFT_DEPOSITORS]: (
      <WhitelistModal
        id={guardItemId}
        role={Role.DEPOSITOR}
        nft
        setInitialConditions={setInitialConditions}
      />
    ),
    [GuardModal.NFT_BENEFICIARIES]: (
      <WhitelistModal
        id={guardItemId}
        role={Role.BENEFICIARIES}
        nft
        setInitialConditions={setInitialConditions}
      />
    ),
    [GuardModal.ASSETS]: <AssetsModal id={guardItemId} />,
    [GuardModal.SVT_NONTRANSFERABLE]: <SVTModal id={guardItemId} />,
    [GuardModal.HOLDING_PERIOD]: <SVTModal id={guardItemId} isTimePeriod />,
  }

  return (
    <ModalBox id={guardItemId} disabledClose>
      <ModalBoxOuterContainer
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '1152px',
          borderRadius: '4px',
        }}
      >
        <Box>
          <Stack padding={2}>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' flexDirection='column'>
                <SpoolBreadcrumbs mode='injected' breadcrumb={breadcrumbs} />
                <Typography variant='subtitle1' paddingBottom={4}>
                  {title}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  closeModal(guardItemId)
                  setSelectedGuards(initialConditions)
                }}
              >
                <CloseIcon
                  sx={{ cursor: 'pointer', width: '35px', height: '35px' }}
                />
              </Box>
            </Box>
            {modals[modalId]}
          </Stack>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}
export default GuardsModalsContainer
