import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const AllocationDescription = () => {
  const t = useLocale()
  return (
    <>
      <Typography variant='body1'>
        {t(
          'components.atoms.pageDescriptions.allocationDescription.instruction'
        )}
        <br />
        <br />
        {t(
          'components.atoms.pageDescriptions.allocationDescription.description'
        )}
      </Typography>
    </>
  )
}

export default AllocationDescription
