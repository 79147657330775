import {
  SortOrder,
  Strategy,
  StrategyListType,
  StrategyVault,
} from '@solidant/spool-v2-fe-lib'
import { memo, useMemo, useReducer } from 'react'

import { StrategiesContext } from '@/store/strategies/strategies.context'
import strategiesReducer from '@/store/strategies/strategies.reducer'
import { initialState } from '@/store/strategies/strategies.state'
import { StrategiesActionTypes } from '@/store/strategies/strategies.types'

import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const StrategiesProvider = memo(({ children }: RCNode) => {
  const [strategies, dispatch] = useReducer(strategiesReducer, initialState)

  const handleSortClick = (
    key: keyof Strategy,
    sortType: SortType<Strategy>
  ) => {
    if (!sortType?.key || sortType?.key !== key) {
      dispatch({
        type: StrategiesActionTypes.SET_STRATEGIES_SORT,
        payload: {
          key,
          direction: SortOrder.DESC,
        },
      })
      return
    }

    dispatch({
      type: StrategiesActionTypes.CHANGE_STRATEGIES_SORT_DIRECTION,
    })
  }

  const setStrategiesPage = (strategiesPage: number) => {
    dispatch({
      type: StrategiesActionTypes.SET_STRATEGIES_PAGE,
      payload: strategiesPage,
    })
  }

  const setStrategiesLimit = (strategiesLimit: number) => {
    dispatch({
      type: StrategiesActionTypes.SET_STRATEGIES_LIMIT,
      payload: strategiesLimit,
    })
  }

  const setFilterByAssetGroup = (filterByAssetGroup: string) => {
    dispatch({
      type: StrategiesActionTypes.SET_FILTER_BY_ASSET_GROUP,
      payload: filterByAssetGroup,
    })
  }

  const setFilterBySearchTerm = (filterBySearchTerm: string) => {
    dispatch({
      type: StrategiesActionTypes.SET_FILTER_BY_SEARCH_TERM,
      payload: filterBySearchTerm,
    })
  }

  const handleSortClickVaults = (
    key: keyof StrategyVault,
    sortType: SortType<StrategyVault>
  ) => {
    if (!sortType?.key || sortType?.key !== key) {
      dispatch({
        type: StrategiesActionTypes.SET_STRATEGY_VAULTS_SORT,
        payload: {
          key,
          direction: SortOrder.DESC,
        },
      })
      return
    }

    dispatch({
      type: StrategiesActionTypes.CHANGE_STRATEGY_VAULTS_SORT_DIRECTION,
    })
  }

  const filterStrategy = (data: StrategyListType) => {
    const strategyNameFilter = strategies.filterBySearchTerm
      ? data.name
          .toUpperCase()
          .includes(strategies.filterBySearchTerm.toUpperCase())
      : true

    const strategyAddressFilter = strategies.filterBySearchTerm
      ? data.address
          .toUpperCase()
          .includes(strategies.filterBySearchTerm.toUpperCase())
      : true

    const searchResults = strategyNameFilter || strategyAddressFilter

    if (!strategies.filterBySearchTerm) {
      strategies.filterByAssetGroup === data.assetGroup.id
    }

    if (strategies.filterByAssetGroup === '0') {
      return searchResults
    }

    return strategies.filterByAssetGroup === data.assetGroup.id && searchResults
  }

  const contextValue = useMemo(() => {
    return {
      ...strategies,
      handleSortClick,
      setStrategiesPage,
      setStrategiesLimit,
      handleSortClickVaults,
      filterStrategy,
      setFilterByAssetGroup,
      setFilterBySearchTerm,
    }
  }, [
    strategies.strategiesSort,
    strategies.strategiesPage,
    strategies.strategiesLimit,
    strategies.strategyVaultsSort,
    strategies.filterByAssetGroup,
    strategies.filterBySearchTerm,
  ])

  return (
    <StrategiesContext.Provider value={contextValue}>
      {children}
    </StrategiesContext.Provider>
  )
})
