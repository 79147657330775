import { useCallback, useState } from 'react'

const useClipboard = () => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      setIsCopied(true)

      // Reset after 2 seconds
      setTimeout(() => setIsCopied(false), 2000)
    } catch (err) {
      console.warn('Clipboard copy failed', err)
      setIsCopied(false)
    }
  }, [])

  return { isCopied, copyToClipboard }
}

export default useClipboard
