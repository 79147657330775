import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, TableCell, TableRow, Typography } from '@mui/material'
import {
  InvestorSmartVault,
  UserClaimableNFTs,
} from '@solidant/spool-v2-fe-lib'
import { useMemo } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useMultiNftClaim from '@/hooks/vaultList/Transactions/useMultiNftClaim'

import { formatUSD } from '@/utils/formats'

interface InvestorTableRowWithdrawal {
  vaultClaimableNFTs: UserClaimableNFTs[]
  row: InvestorSmartVault
}

const InvestorTableRowWithdrawal: React.FC<InvestorTableRowWithdrawal> = ({
  vaultClaimableNFTs,
  row,
}) => {
  const t = useLocale()

  const claimMultipleNft = useMultiNftClaim()

  const { address, wNfts, pendingWithdrawals } = row

  const totalClaimableAmount = useMemo(() => {
    return vaultClaimableNFTs.reduce((curr, { withdrawAmount }) => {
      return (curr += +withdrawAmount)
    }, 0)
  }, [vaultClaimableNFTs])

  return (
    <TableRow>
      <TableCell sx={{ pb: 2 }} colSpan={9}>
        <Box
          sx={{ mx: 1, py: 1, px: 1 }}
          borderRadius={1}
          border='1px solid rgba(33, 35, 34, 0.12) '
          display='flex'
          flexDirection='column'
          gap='0.2rem'
        >
          {!!wNfts.length && (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box display='flex' flexDirection='column'>
                <Box display='flex' alignItems='center' gap='0.4rem'>
                  <Box display='flex' alignItems='center'>
                    <InfoOutlinedIcon
                      sx={{ color: 'text.secondary' }}
                      fontSize='small'
                    />
                  </Box>
                  <Typography fontSize='14px'>
                    {totalClaimableAmount
                      ? `$${formatUSD(totalClaimableAmount)} is `
                      : 'There are deposits '}
                    available to withdraw
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{ cursor: 'pointer', mr: '0.1rem' }}
                onClick={() => {
                  claimMultipleNft(wNfts, address)
                }}
              >
                <Typography fontSize='14px' color='primary'>
                  {t(
                    'components.organisms.dashboard.investor.investorAllocations.vaultsAllocation.buttons.withdrawFunds.label'
                  )}
                </Typography>
              </Box>
            </Box>
          )}
          {Number(pendingWithdrawals.total ?? '0') > 0 && (
            <Box display='flex' alignItems='center' gap='0.4rem'>
              <Box display='flex' alignItems='center'>
                <InfoOutlinedIcon
                  sx={{ color: 'text.secondary' }}
                  fontSize='small'
                />
              </Box>
              <Typography fontSize='14px'>
                ${formatUSD(pendingWithdrawals.total)} withdrawal has been
                initiated. It will be available to be claimed within 24 hours
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default InvestorTableRowWithdrawal
