import { Box, Button, Stack, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useTopMenuState from '@/hooks/context/useTopMenuState'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'

import WalletsLogo from '@/assets/icons/general/WalletsLogo'

interface UnconnectedWalletDisplayProps {
  sectionColumnTitle?: string
}

const UnconnectedWalletDisplay: React.FC<UnconnectedWalletDisplayProps> = ({
  sectionColumnTitle,
}) => {
  const t = useLocale()
  const { toggleMenu } = useTopMenuState()

  return (
    <Section>
      <SectionColumn
        title={sectionColumnTitle}
        sx={{
          gap: 0,
          bgcolor: 'white',
          borderRadius: '4px',
        }}
      >
        <Stack
          py='2rem'
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Box>
            <WalletsLogo />
          </Box>
          <Box display='flex' pb='1.5rem' alignItems='center' margin='auto'>
            <Typography>{t('general.connectYourWallet')}</Typography>
          </Box>
          <Button
            variant='contained'
            size='small'
            sx={{ justifySelf: 'center', whiteSpace: 'nowrap' }}
            onClick={() => toggleMenu(true)}
          >
            {t(
              'components.organisms.sideMenu.walletConnectionMenu.buttons.connect.label'
            )}
          </Button>
        </Stack>
      </SectionColumn>
    </Section>
  )
}

export default UnconnectedWalletDisplay
