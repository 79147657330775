import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Box } from '@mui/material'

const ArrowDownDivider = () => {
  return (
    <Box
      width='100%'
      mt='0.5rem'
      display='flex'
      gridTemplateColumns='45% 10% 45%'
    >
      <Box mt='0.6rem' height='1px' width='100%' border='1px solid #2123221F' />
      <Box paddingX='0.2rem'>
        <ArrowDownwardIcon />
      </Box>
      <Box mt='0.6rem' height='1px' width='100%' border='1px solid #2123221F' />
    </Box>
  )
}

export default ArrowDownDivider
