import { DatumValue, ResponsiveLine, Serie } from '@nivo/line'

import { StakedBiAxialChartDashboardTheme } from '@/themes/StakedBiAxialChartDashboardTheme'
import { formatUSD } from '@/utils/formats'
import { formatXAxisValue } from '@/utils/stakedChart.biaxial'

interface NormalChartProps {
  data: Serie[]
  colors: string[]
  maxValue: number
  xTickValues: DatumValue[]
  yTickValues: number[]
}

const NormalChart: React.FC<NormalChartProps> = ({
  data,
  colors,
  maxValue,
  xTickValues,
  yTickValues,
}) => (
  <ResponsiveLine
    theme={StakedBiAxialChartDashboardTheme}
    data={data}
    colors={colors}
    curve='linear'
    enableArea={true}
    areaOpacity={0.3}
    axisLeft={{
      legendPosition: 'middle',
      legendOffset: -60,
      tickValues: yTickValues.filter((x) => x !== yTickValues[0]),
      format: (value: number) => `$ ${formatUSD(value, 4, 0)}`,
    }}
    axisBottom={{
      format: (value) => formatXAxisValue(value, xTickValues),
      tickSize: 0,
      tickPadding: 11,
    }}
    yScale={{
      type: 'linear',
      min: 0,
      max: maxValue,
    }}
    lineWidth={1}
    gridYValues={yTickValues}
    gridXValues={xTickValues}
    margin={{ top: 10, right: 70, bottom: 30, left: 100 }}
    enablePoints={false}
  />
)

export default NormalChart
