import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button, Typography } from '@mui/material'

import useModalState from '@/hooks/context/useModalState'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

const WarningModal: React.FC = () => {
  const { modals, closeModal } = useModalState()

  return (
    <ModalBox id='warningModal'>
      <ModalBoxOuterContainer sx={{ width: '450px', paddingBottom: '30px' }}>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          px={2}
        >
          <Box
            onClick={() => closeModal(Modals.WARNING_MODAL)}
            sx={{ position: 'absolute', top: '24px', right: '24px' }}
          >
            <CloseIcon
              sx={{
                cursor: 'pointer',
                width: '24px',
                height: '24px',
                color: 'grey',
              }}
            />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={2}
            pt={1}
          >
            <ErrorOutlineIcon
              sx={{ height: '48px', width: '48px' }}
              color='error'
            />
            <Typography variant='body1' textAlign='center'>
              {modals['warningModal']?.modalType?.warningModalType?.message}
            </Typography>
            {modals['warningModal']?.modalType?.warningModalType
              ?.isActionRequired && (
              <Button
                variant='contained'
                onClick={() => {
                  closeModal(Modals.WARNING_MODAL)
                  modals['warningModal']?.modalType?.warningModalType.action()
                }}
              >
                Ok
              </Button>
            )}
          </Box>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default WarningModal
