import { RiskScores } from '@solidant/spool-v2-fe-lib'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useStrategiesAllocation from '@/hooks/createVault/useStrategiesAllocation'

import { ECOSYSTEM_FEE, PLATFORM_FEE } from '@/constants'
import { mapAssetConfig } from '@/constants/assets/assets'

export const useRiskMetrics = () => {
  const { chain } = useConnectionState()
  const {
    selectedStrategies,
    selectedRiskScore,
    selectedManagementFee,
    selectedPerformanceFee,
  } = useCreateVaultState()
  const { strategiesAllocation, isLoading, error } = useStrategiesAllocation()

  let projectedAPY = 0
  let strategyRiskScore = 0
  let apyAfterFees = 0
  let assetsLabels = ''

  if (
    !isLoading &&
    !error &&
    typeof selectedStrategies === 'object' &&
    typeof strategiesAllocation === 'object'
  ) {
    try {
      const _APY = selectedStrategies.map(({ address, apy }) => {
        const allocationForStrategy =
          strategiesAllocation[selectedRiskScore as unknown as RiskScores][
            address
          ]
        return allocationForStrategy
          ? ((allocationForStrategy / 100) * Number(apy)) / 100
          : 0
      })

      projectedAPY = _APY.reduce((total, num) => total + num, 0)
    } catch (e) {
      console.warn(e)
    }

    try {
      const _riskScore = selectedStrategies.map(({ address, riskScore }) => {
        const allocationForStrategy =
          strategiesAllocation[selectedRiskScore as unknown as RiskScores][
            address
          ]

        return allocationForStrategy
          ? ((allocationForStrategy / 100) * riskScore) / 100
          : 0
      })

      strategyRiskScore = _riskScore.reduce((total, num) => total + num, 0)
    } catch (e) {
      console.warn(e)
    }

    try {
      const depositFeePct = 0 / 100
      const managementFeePct = selectedManagementFee / 100
      const performanceFeePct = selectedPerformanceFee / 100
      const ecosystemFeePct = ECOSYSTEM_FEE / 100
      const platformFeePct = PLATFORM_FEE / 100
      const baseApyPct = projectedAPY / 100
      apyAfterFees =
        ((1 - depositFeePct) *
          (1 + baseApyPct * (1 - ecosystemFeePct - platformFeePct)) *
          (1 - managementFeePct) -
          baseApyPct * performanceFeePct -
          1) *
        100
    } catch (e) {
      console.warn(e)
    }

    try {
      const getAssets = selectedStrategies.flatMap((strategy) =>
        strategy.assetGroup?.assetGroupTokens.map(({ address }) => address)
      )
      const uniqueAssets = [...new Set(getAssets)]

      assetsLabels = uniqueAssets
        .map((asset) =>
          mapAssetConfig(chain)[asset]?.assetLabel.replace('$', '')
        )
        .join(', ')
    } catch (e) {
      console.warn(e)
    }
  }

  return {
    projectedAPY,
    strategyRiskScore,
    apyAfterFees,
    assetsLabels,
    isLoading,
  }
}
