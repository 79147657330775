import { ChevronLeft } from '@mui/icons-material'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useSteps from '@/hooks/createVault/useSteps'

import CustomButton from '@/components/atoms/CustomButton'

import { STEP_SEQUENCE } from '@/store/createVault/createVault.state'
import { StepData } from '@/store/createVault/createVault.types'

const BackNavigation: React.FC<{ step: StepData; isAdvanced: boolean }> = ({
  step,
  isAdvanced,
}) => {
  const { currentStep, setCurrentStep, getStepData } = useCreateVaultState()

  const steps = useSteps()

  const prevStepData = getStepData(currentStep - 1)
  let prevStep = prevStepData.nextBtn.toStep - 1
  const prevLabel = currentStep > 1 && step.prevBtn.label

  const handleBackNavigation = () => {
    // if on last step always go to step 3
    if (currentStep === steps.length) {
      setCurrentStep(STEP_SEQUENCE.ALLOCATIONS)
      return
    }

    if (
      isAdvanced &&
      (currentStep === STEP_SEQUENCE.GUARDS ||
        currentStep === STEP_SEQUENCE.ACTIONS)
    ) {
      prevStep = STEP_SEQUENCE.ALLOCATIONS
      setCurrentStep(STEP_SEQUENCE.ALLOCATIONS)
      return
    }

    setCurrentStep(prevStep)
    window.scrollTo(0, 0)
  }

  const getButtonLabel = () => {
    if (!isAdvanced && currentStep === steps.length) {
      return getStepData(STEP_SEQUENCE.STRATEGIES).nextBtn.label
    }

    if (!isAdvanced) {
      return prevLabel
    }

    if (
      isAdvanced &&
      (currentStep === STEP_SEQUENCE.GUARDS ||
        currentStep === STEP_SEQUENCE.ACTIONS ||
        currentStep === STEP_SEQUENCE.LAUNCH)
    ) {
      return getStepData(STEP_SEQUENCE.STRATEGIES).nextBtn.label
    } else {
      return prevLabel
    }
  }

  return (
    currentStep > 1 && (
      <CustomButton
        variant='contained'
        sx={{
          my: (theme) => theme.spacing(2),
          gap: (theme) => theme.spacing(1),
        }}
        onClick={handleBackNavigation}
      >
        <ChevronLeft />
        {getButtonLabel()}
      </CustomButton>
    )
  )
}

export default BackNavigation
