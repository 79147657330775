import { memo, useMemo, useReducer } from 'react'

import {
  initialState,
  SmartVaultRowContext,
} from '@/store/smartVaultRow/smartVaultRow.context'
import smartVaultRowReducer from '@/store/smartVaultRow/smartVaultRow.reducer'
import { SmartVaultRowActionTypes } from '@/store/smartVaultRow/smartVaultRow.types'

import { VaultsListAction } from '@/constants/smartVault'

import { RCNode } from '@/types/index'

export const SmartVaultRowProvider = memo(({ children }: RCNode) => {
  const [smartVaultRow, dispatch] = useReducer(
    smartVaultRowReducer,
    initialState
  )

  const setVaultRowAction = (vaultRowAction: VaultsListAction) => {
    dispatch({
      type: SmartVaultRowActionTypes.SET_VAULT_ROW_ACTION,
      payload: vaultRowAction,
    })
  }

  const contextValue = useMemo(() => {
    return {
      ...smartVaultRow,
      setVaultRowAction,
    }
  }, [smartVaultRow.vaultRowAction])

  return (
    <SmartVaultRowContext.Provider value={contextValue}>
      {children}
    </SmartVaultRowContext.Provider>
  )
})
