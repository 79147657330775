import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers/lib/ethers'

import useAppState from '@/hooks/context/useAppState'
import useModalState from '@/hooks/context/useModalState'
import useWithdrawState from '@/hooks/context/useWithdrawState'
import usePendingWithdrawals from '@/hooks/smartVaults/usePendingWithdrawals'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useWithdrawalDetails from '@/hooks/smartVaults/useWithdrawalDetails'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { WITHDRAW_FLUSH } from '@/config/flushes.config'
import { IS_STAGING, IS_TESTNET } from '@/config/sdk'
import { sleep } from '@/utils/web3'
import { getRequiredNFTShares, getUsdToSvt } from '@/utils/web3/withdrawals'

const useWithdraw = (withdrawFast: boolean) => {
  const { openModal, setModalType } = useModalState()

  const { account, provider } = useWeb3React()

  const { withdrawalAmount, setWithdrawalAmount } = useWithdrawState()

  const { sdk } = useAppState()

  const { smartVaultData } = useVaultsPage()

  const { address } = smartVaultData

  const { mutate } = useWithdrawalDetails(account, address)

  const { pendingWithdrawals, updatePendingReceipts } = usePendingWithdrawals(
    address,
    account
  )

  const withdraw = async () => {
    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.Withdraw,
        },
      })

      const withdrawalDetails = await sdk.smartVault.getWithdrawDetails(
        account.toLowerCase(),
        address.toLowerCase()
      )

      const userSVTsOwned = await sdk.smartVault.getUserSVTsOwned(
        account.toLowerCase(),
        address.toLowerCase()
      )

      const { userBalance } = withdrawalDetails

      const userNfts = await sdk.smartVault.getUserWithdrawableNftIds(
        account,
        address
      )

      const svtValues = await sdk.smartVault.getUserSVTsfromNFTs(
        address.toLowerCase(),
        userNfts.nftId,
        account.toLowerCase()
      )

      const svtUserBalance = await sdk.smartVault.getUserSVTBalance(
        address.toLowerCase(),
        account.toLowerCase(),
        userNfts.nftId
      )

      const svtsToWithdraw = getUsdToSvt(
        withdrawalAmount,
        svtUserBalance.toString(),
        userBalance
      )

      if (svtsToWithdraw.isZero()) {
        throw new Error('Trying to withdraw 0 SVTs')
      }

      let svtDiff = BigNumber.from('0')

      const requiredNfts: string[] = []
      const requiredShares: BigNumber[] = []

      const userSVTsOwnedInBN = BigNumber.from(userSVTsOwned)

      const svtsRequiringShares = svtsToWithdraw.sub(userSVTsOwnedInBN)
      //get nftIds and shares needed, to save on gas cost
      if (svtsToWithdraw.gt(userSVTsOwnedInBN)) {
        userNfts.shares.reduce((acc, curr, index) => {
          if (acc.lt(BigNumber.from('0'))) return acc

          // svt amount for each dNFT
          const svtAmount = svtValues[index]

          if (svtAmount.isZero()) return

          requiredNfts.push(userNfts.nftId[index])

          // if the SVTAmount for the particular dNFT is less than the SVTsToBurn, we can just add the full shares amount
          if (acc.gt(svtAmount)) {
            requiredShares.push(BigNumber.from(curr))
            return acc.sub(svtAmount)
          }

          // calculate the shares based on leftover svtsToBurn and svtsAmount for particular dNFT
          const calculatedShares = getRequiredNFTShares(acc, svtAmount, curr)

          // calculation for shares required causes an accuracy issue, this is to check for the difference between SVTs
          const calculatedSharesDiff = calculatedShares.mul(svtAmount).div(curr)

          svtDiff = acc.sub(calculatedSharesDiff)

          requiredShares.push(calculatedShares)

          return acc.sub(svtAmount)
        }, svtsRequiringShares)
      }

      const blockNumber = await provider.getBlockNumber()

      const tx = withdrawFast
        ? await sdk.smartVault.withdrawFast(
            address,
            {
              nftId: requiredNfts,
              nftAmounts: requiredShares,
            },
            svtsToWithdraw.sub(svtDiff),
            account.toLowerCase(),
            blockNumber,
            provider.getSigner(),
            IS_TESTNET && !IS_STAGING
          )
        : await sdk.smartVault.withdraw(
            address,
            svtsToWithdraw.sub(svtDiff),
            {
              nftId: requiredNfts,
              shares: requiredShares,
            },
            account,
            // flush permenantly set to false
            WITHDRAW_FLUSH
          )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.Withdraw,
        },
      })

      await tx.wait()

      await sleep(5000)

      await updatePendingReceipts(pendingWithdrawals)

      setWithdrawalAmount('')

      if (withdrawFast) {
        setModalType({
          actionModalType: {
            transactionStatus: TransactionStatus.Success,
            transactionType: TransactionType.FastWithdraw,
            txHash: tx.hash,
          },
        })
        return
      } else {
        setModalType({
          actionModalType: {
            transactionStatus: TransactionStatus.Success,
            transactionType: TransactionType.Withdraw,
          },
        })
      }
    } catch (error) {
      console.warn('error', error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.Withdraw,
        },
      })
    } finally {
      mutate()
    }
  }

  return withdraw
}

export default useWithdraw
