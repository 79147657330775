export const withdrawNotificationText = (
  multiNft: boolean,
  usdAmount?: string,
  vaultName?: string
): string => {
  if (multiNft) {
    return 'There are multiple withdrawals ready to be claimed.'
  }

  return `Your withdrawal request for ${usdAmount} USD from ${vaultName} is ready to be claimed.`
}
