import { formatUnits, parseUnits } from '@ethersproject/units'
import {
  IncentivizeRewardToken,
  SortOrder,
  TimeConversions,
} from '@solidant/spool-v2-fe-lib'
import dayjs, { unix } from 'dayjs'
import { BigNumber } from 'ethers'
import { parseEther } from 'ethers/lib/utils'

import { DAYS_PER_YEAR } from '@/constants'

import { IncentivesToken } from '@/types/incentives'
import { SortType } from '@/types/table'

const sortComparision = (
  a: string | number,
  b: string | number,
  direction: SortOrder
) => {
  if (direction === SortOrder.ASC) {
    return a > b ? 1 : a < b ? -1 : 0
  }
  return a > b ? -1 : a < b ? 1 : 0
}

export const sortTokens = (
  tokens: IncentivizeRewardToken[],
  sortType: SortType<IncentivesToken>
) => {
  if (!sortType) return tokens

  const { key, direction } = sortType
  const sortedTokens = [...tokens]

  sortedTokens.sort((a, b) => {
    switch (key) {
      case 'id':
        return a.asset && b.asset
          ? sortComparision(a.asset.name, b.asset.name, direction)
          : 0
      case 'remaining_tokens':
        return sortComparision(
          Number(a.remainingAmount),
          Number(b.remainingAmount),
          direction
        )
      case 'time_left':
        return a.endTime && b.endTime
          ? sortComparision(
              unix(Number(a.endTime)).diff(dayjs(), 'day'),
              unix(Number(b.endTime)).diff(dayjs(), 'day'),
              direction
            )
          : 0
      default:
        return 0
    }
  })

  return sortedTokens
}

export const calculateNewEndTime = (currentEndTime: string, days: string) => {
  const timeFrom =
    Number(currentEndTime) < dayjs().unix()
      ? dayjs()
      : unix(Number(currentEndTime))
  return timeFrom.add(+days, 'day').unix()
}

export const getNewRewardRate = (
  amount: string,
  days: string,
  incentivizedToken: IncentivizeRewardToken,
  isExtend: boolean
) => {
  const tokenEndTime = incentivizedToken?.endTime.toString()
  const timeRemaining = unix(+tokenEndTime).diff(dayjs(), 'seconds')

  // NOTE: previous reward rate calculation
  // const newRewardRate = parseEther(amount)
  //   .mul(parseEther('1'))
  //   .div(
  //     parseEther((Number(days) * TimeConversions.SECONDS_PER_DAY).toString())
  //   )

  // const currentRewardRate = BigNumber.from(
  //   incentivizedToken?.rewardRate.toString()
  // )
  //   .sub(newRewardRate)
  //   .div(BigNumber.from(timeRemaining))

  // const accumulatedRewardRate = BigNumber.from(
  //   incentivizedToken?.rewardRate.toString()
  // ).add(currentRewardRate)

  // return accumulatedRewardRate

  const currentDays =
    timeRemaining > 0 && !isExtend
      ? (timeRemaining / TimeConversions.SECONDS_PER_DAY).toString()
      : days

  return +amount
    ? parseEther(amount)
        .add(parseEther(incentivizedToken?.remainingAmount))
        .mul(parseEther('1'))
        .div(
          parseEther(currentDays).add(
            isExtend
              ? parseEther(timeRemaining.toString()).div(
                  TimeConversions.SECONDS_PER_DAY
                )
              : '0'
          )
        )
    : BigNumber.from('0')
}

export const getRewardApy = (
  days: string,
  tokenAmount: string,
  tokenDecimals: number,
  vaultTvr: string,
  existingRewardsApy: number,
  tokenPrice: string,
  targetToken: string
) => {
  if (!days || !tokenAmount || vaultTvr === '0.0' || !targetToken)
    return existingRewardsApy

  const formattedTokenAmount = parseUnits(tokenAmount, tokenDecimals)

  const rewardsPerDay = BigNumber.from(formattedTokenAmount).div(
    BigNumber.from(days)
  )

  const yearlyReward = rewardsPerDay.mul(BigNumber.from(DAYS_PER_YEAR))

  const tvr = vaultTvr === '0.0' ? 1 : +vaultTvr

  const rewardApy =
    (+formatUnits(yearlyReward, tokenDecimals) / +tvr) * 100 * +tokenPrice

  const totalRewardApy = rewardApy + existingRewardsApy

  return totalRewardApy
}
