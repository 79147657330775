import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { ReactNode } from 'react'

import useEagerlyConnect from '@/hooks/web3/useEagerlyConnect'

import { connections } from '@/connection/utils'

type Web3ProviderProps = {
  children: ReactNode
}

const Web3Provider: React.FC<Web3ProviderProps> = ({ children }) => {
  useEagerlyConnect()
  const connectors = connections.map<[Connector, Web3ReactHooks]>(
    ({ hooks, connector }) => [connector, hooks]
  )

  return (
    <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
  )
}

export default Web3Provider
