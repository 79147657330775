const WBTC = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_30_2)'>
        <path
          d='M20.3847 5.24667L19.6982 5.9331C21.3362 7.72432 22.2445 10.0637 22.2445 12.4909C22.2445 14.9181 21.3362 17.2574 19.6982 19.0486L20.3847 19.735C22.2063 17.7592 23.2176 15.1702 23.2176 12.4828C23.2176 9.79546 22.2063 7.20649 20.3847 5.23065V5.24667Z'
          fill='#5A5564'
        />
        <path
          d='M5.94903 5.30616C7.74025 3.66821 10.0796 2.75989 12.5068 2.75989C14.934 2.75989 17.2733 3.66821 19.0645 5.30616L19.751 4.61973C17.7751 2.79815 15.1862 1.78683 12.4988 1.78683C9.81139 1.78683 7.22242 2.79815 5.24658 4.61973L5.94903 5.30616Z'
          fill='#5A5564'
        />
        <path
          d='M5.30595 19.0555C3.66994 17.2646 2.76281 14.9267 2.76281 12.5011C2.76281 10.0755 3.66994 7.73762 5.30595 5.94681L4.61951 5.26038C2.79794 7.23621 1.78662 9.82519 1.78662 12.5126C1.78662 15.2 2.79794 17.7889 4.61951 19.7648L5.30595 19.0555Z'
          fill='#5A5564'
        />
        <path
          d='M19.0554 19.6893C17.2641 21.3272 14.9248 22.2355 12.4976 22.2355C10.0704 22.2355 7.73107 21.3272 5.93985 19.6893L5.25342 20.3757C7.22925 22.1973 9.81823 23.2086 12.5056 23.2086C15.193 23.2086 17.782 22.1973 19.7578 20.3757L19.0554 19.6893Z'
          fill='#5A5564'
        />
        <path
          d='M16.8336 10.2096C16.6963 8.77722 15.4608 8.29672 13.898 8.15028V6.17792H12.6898V8.11367C12.3718 8.11367 12.0469 8.11367 11.7243 8.11367V6.17792H10.5253V8.16401H8.07471V9.45679C8.07471 9.45679 8.96707 9.44078 8.95335 9.45679C9.11425 9.43909 9.27573 9.48454 9.40378 9.58357C9.53183 9.68259 9.61644 9.82746 9.63978 9.98764V15.4242C9.63634 15.4807 9.62161 15.5359 9.59646 15.5866C9.57131 15.6373 9.53624 15.6825 9.49334 15.7194C9.45125 15.757 9.40198 15.7857 9.3485 15.8038C9.29503 15.8219 9.23844 15.829 9.18216 15.8246C9.19817 15.8384 8.30352 15.8246 8.30352 15.8246L8.07471 17.2684H10.5024V19.2866H11.7105V17.2982H12.6761V19.2774H13.8865V17.2822C15.9275 17.1586 17.3507 16.6552 17.5292 14.7446C17.6734 13.207 16.9503 12.5206 15.7948 12.2437C16.4973 11.8982 16.932 11.2575 16.8336 10.2096ZM15.1404 14.5067C15.1404 16.0077 12.5686 15.8361 11.7494 15.8361V13.1727C12.5686 13.175 15.1404 12.9393 15.1404 14.5067ZM14.5798 10.7542C14.5798 12.127 12.4336 11.96 11.7517 11.96V9.53917C12.4336 9.53917 14.5798 9.32408 14.5798 10.7542Z'
          fill='#F09242'
        />
        <path
          d='M12.4977 25C10.0256 24.9996 7.60921 24.2661 5.55395 22.8924C3.49869 21.5187 1.89689 19.5665 0.951074 17.2825C0.00526186 14.9985 -0.242087 12.4854 0.240301 10.0608C0.722689 7.63627 1.91315 5.40919 3.66117 3.66117C5.40919 1.91315 7.63627 0.722689 10.0608 0.240301C12.4854 -0.242087 14.9985 0.00526186 17.2825 0.951074C19.5665 1.89689 21.5187 3.49869 22.8924 5.55395C24.2661 7.60921 24.9996 10.0256 25 12.4977C25.0003 14.1396 24.6771 15.7655 24.0489 17.2825C23.4207 18.7995 22.4998 20.1778 21.3388 21.3388C20.1778 22.4998 18.7995 23.4207 17.2825 24.0489C15.7655 24.6771 14.1396 25.0003 12.4977 25ZM12.4977 0.974745C10.2202 0.976555 7.9943 1.65352 6.10141 2.92007C4.20852 4.18663 2.7336 5.98592 1.86307 8.09052C0.992541 10.1951 0.765477 12.5106 1.21058 14.7442C1.65568 16.9778 2.75297 19.0293 4.36375 20.6395C5.97453 22.2496 8.0265 23.3461 10.2603 23.7903C12.4941 24.2345 14.8095 24.0066 16.9137 23.1352C19.018 22.2638 20.8167 20.7882 22.0825 18.8948C23.3483 17.0014 24.0244 14.7753 24.0253 12.4977C24.0259 10.9839 23.7281 9.4849 23.1489 8.0863C22.5698 6.6877 21.7206 5.41697 20.65 4.34678C19.5794 3.2766 18.3083 2.42794 16.9095 1.84934C15.5107 1.27074 14.0115 0.973542 12.4977 0.974745Z'
          fill='#282138'
        />
      </g>
      <defs>
        <clipPath id='clip0_30_2'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WBTC
