import { Box, CircularProgress, Typography } from '@mui/material'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

interface SmartVaultDataLoadingProps {
  loadingText: string
}

const SmartVaultDataLoading: React.FC<SmartVaultDataLoadingProps> = ({
  loadingText,
  ...rest
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()
  return (
    <Box
      maxWidth='100%'
      display='flex'
      justifyContent='center'
      {...rest}
      sx={{ p: 1, py: 4 }}
      border='1px solid rgba(0, 0, 0, 0.12)'
      borderTop={!belowPhabletBreakpoint && 'none'}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap='1.5rem'
      >
        <CircularProgress className='hook-loading' />
        <Typography>{loadingText}...</Typography>
      </Box>
    </Box>
  )
}

export default SmartVaultDataLoading
