const ETH = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='8.50141'
        cy='8.00043'
        r='7.04'
        fill='white'
        stroke='#D2D2D2'
        strokeWidth='1.04'
      />
      <g opacity='0.6'>
        <path
          d='M10.629 8.0457L8.56842 9.26355L6.50708 8.04571L8.56843 7.10821L10.629 8.0457Z'
          fill='#010101'
          stroke='#010101'
          strokeWidth='1.33303'
        />
      </g>
      <g opacity='0.45'>
        <path
          d='M7.90197 4.5553V8.86995L5.98434 7.73702L7.90197 4.5553Z'
          fill='#010101'
          stroke='#010101'
          strokeWidth='1.33303'
        />
      </g>
      <g opacity='0.8'>
        <path
          d='M9.23683 4.5561L11.1532 7.73706L9.23683 8.86972V4.5561Z'
          fill='#010101'
          stroke='#010101'
          strokeWidth='1.33303'
        />
      </g>
      <g opacity='0.45'>
        <path
          d='M7.90197 11.0812V11.4751L7.42049 10.7968L7.90197 11.0812Z'
          fill='#010101'
          stroke='#010101'
          strokeWidth='1.33303'
        />
      </g>
      <g opacity='0.8'>
        <path
          d='M9.71922 10.7964L9.23683 11.4758V11.0813L9.71922 10.7964Z'
          fill='#010101'
          stroke='#010101'
          strokeWidth='1.33303'
        />
      </g>
    </svg>
  )
}

export default ETH
