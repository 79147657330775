import { Grid } from '@mui/material'
import { Asset } from '@solidant/spool-v2-fe-lib'
import { useEffect, useState } from 'react'

import useDepositState from '@/hooks/context/useDepositState'
import usePriceFeed from '@/hooks/web3/usePriceFeed'
import useUserBalance from '@/hooks/web3/useUserBalance'

import MultiAssetSwapListToken from '@/components/molecules/MultiAssetDeposit/MultiAssetSwapListToken'
import MultiAssetSwapListTokenSkeleton from '@/components/molecules/MultiAssetDeposit/MultiAssetSwapListTokenSkeleton'

import { MultiAssetDeposit } from '@/store/deposit/deposit.types'

import { getPricePerAssetAmount } from '@/utils/web3'

interface MultiAssetSwapListItemProps {
  asset: Asset
  allocation: string
  multiAssetDeposit: MultiAssetDeposit
  isTokenApproved: boolean
  loading: boolean
  setMultiAssetDeposit: React.Dispatch<React.SetStateAction<MultiAssetDeposit>>
}

const MultiAssetSwapListItem: React.FC<MultiAssetSwapListItemProps> = ({
  asset,
  allocation,
  multiAssetDeposit,
  // isTokenApproved,
  loading,
  setMultiAssetDeposit,
}) => {
  const [amountInUsd, setAmountInUSD] = useState('0')

  const { address } = asset

  const { balance } = useUserBalance(address)
  const { multiAssetSwap } = useDepositState()
  const { tokenAddress } = multiAssetSwap

  const { priceFeed } = usePriceFeed(address)
  const { priceFeed: selectedTokenPriceFeed } = usePriceFeed(tokenAddress)

  useEffect(() => {
    if (multiAssetDeposit && multiAssetDeposit[address]) {
      setMultiAssetDeposit((prev) => {
        return {
          ...prev,
          [address]: {
            ...prev[address],
            priceFeed,
            ratio: allocation,
          },
        }
      })
    }
  }, [address, balance, priceFeed, allocation])

  useEffect(() => {
    if (
      multiAssetDeposit[address]?.amount &&
      multiAssetDeposit[address]?.decimals &&
      selectedTokenPriceFeed
    ) {
      setAmountInUSD(
        getPricePerAssetAmount(
          multiAssetDeposit[address].amount,
          selectedTokenPriceFeed,
          multiAssetDeposit[address].decimals,
          false
        )
      )
    }
  }, [multiAssetDeposit, selectedTokenPriceFeed])

  return (
    <Grid
      key={address}
      bgcolor='#f5f5f5'
      display='flex'
      item
      xs={10}
      sx={{
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <Grid item display='flex' xs={7}>
        {loading ? (
          <MultiAssetSwapListTokenSkeleton />
        ) : (
          <MultiAssetSwapListToken asset={asset} amountInUsd={amountInUsd} />
        )}
      </Grid>
      {/* <Grid item display='flex' xs={3}>
        <MultiAssetSwapListFees
          asset={asset}
          isTokenApproved={isTokenApproved}
          loading={loading}
        />
      </Grid> */}
    </Grid>
  )
}

export default MultiAssetSwapListItem
