import tenderly from '@/config/tenderly/ethereum.contracts.json'

export const ETHEREUM_TENDERLY_CONFIG = {
  RPC_PROVIDER:
    'https://virtual.mainnet.rpc.tenderly.co/2d405d73-7857-4a75-981f-6f697cb33de0',
  CORE_SUBGRAPH:
    'https://spool-v2-testing.spool.fi/subgraphs/name/spool/core-v2',
  API_URL: 'https://jobs.dev.spool.fi/graphql',
  FAST_WITHDRAW_API_URL: 'https://fastwithdraw.dev.spool.fi',
  STAKING_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spoolfi-staking/api',
  ANALYTICS: 'https://analytics.spool.fi',
  SNAPSHOT_SUBGRAPH: 'https://hub.snapshot.org/graphql',
  SNAPSHOT_HUB: 'https://hub.snapshot.org',
  SNAPSHOT_SPACE: 'egov.spool.eth',
  REWARDS_URL: 'https://rewards.dev.spool.fi/tenderly',
  PRICE_FEED: 'https://pricefeed.v2.spool.fi/',
  SMART_VAULT_MANAGER: tenderly.SmartVaultManager.proxy,
  DEPOSIT_SWAP_MANAGER: tenderly.DepositSwap.proxy,
  DEPOSIT_MANAGER: tenderly.DepositManager.proxy,
  SMART_VAULT_FACTORY: tenderly.SmartVaultFactory,
  SMART_VAULT_FACTORY_HPF: tenderly.SmartVaultFactoryHpf,
  REWARD_MANAGER: tenderly.RewardManager.proxy,
  YELAY_STAKING: '0xc870d10ce4bef1ae0e82ae6ddde5d8ee5a8ab9c7',
  SYLAY: '0x4eAb6409Da6e568Be80d5d06d6A8F6A82d0D0AF9',
  SPOOL_LENS: tenderly.SpoolLens.proxy,
  YELAY_TOKEN: '0xb370f95a84d0a8d1150c42c44894c0ba457f18b6',
  LINEAR_ALLOCATION_PROVIDER: tenderly.LinearAllocationProvider.proxy,
  ONE_INCH_ROUTER: '0x1111111254eeb25477b68fb85ed929f73a960582',
  SWAPPER: tenderly.Swapper.proxy,
  ALLOW_LIST_GUARD: tenderly.AllowlistGuard,
  IGUARD_MANAGER: tenderly.GuardManager.proxy,
  API_CHAIN_NAME: 'tenderly',
  STRATEGY_REGISTRY: tenderly.StrategyRegistry.proxy,
  SPOOL_ACCESS_CONTROL: tenderly.SpoolAccessControl.proxy,
  META_VAULT_FACTORY: tenderly.MetaVaultFactory,
}

export const ETHEREUM_TENDERLY_FLUSHES = {
  WITHDRAW: false,
  SWAP_AND_DEPOSIT: false,
  SINGLE_SWAP_DEPOSIT: false,
  MULTI_SWAP_DEPOSIT: false,
  DEPOSIT: false,
}

export const ETHEREUM_TENDERLY_FEATURES = {
  CREATE_VAULT_ADVANCED: true,
  ARBITRUM_ONE_NET: false,
  FAST_WITHDRAW: true,
  MULTI_SWAP_DEPOSIT: false,
}
