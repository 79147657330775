import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useStrategies from '@/hooks/strategies/useStrategies'

import DataLoading from '@/components/molecules/DataLoading'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import StrategyVaultsTable from '@/components/organisms/Strategies/StrategyVaultsTable'

import { StrategiesProvider } from '@/store/strategies/strategies.provider'

const StrategyDetails = () => {
  const t = useLocale()
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { strategyId } = useParams()

  const { isLoading, error, strategies } = useStrategies()

  const currentStrategy = strategies?.find(
    (strategy) => strategy.address === strategyId
  )

  if (error || !currentStrategy) {
    return null
  }

  return (
    <>
      {!isLoading ? (
        !Object.values(currentStrategy).length ? (
          <Box mt='1rem'>
            <ErrorWithText
              errorText={t('pages.strategies.strategyDetailsPage.noVaults')}
            />
          </Box>
        ) : (
          <Box display='flex' flexDirection='column' gap='1rem' mb={10}>
            <Section
              sx={{
                border: belowPhabletBreakpoint
                  ? 'none'
                  : '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <SectionColumn
                title={t(
                  'pages.strategies.strategyDetailsPage.vaultsTableHeader'
                )}
                sx={{
                  gap: 0,
                  borderRadius: (theme) => theme.spacing(1),
                  padding: belowPhabletBreakpoint ? '' : '1rem',
                  border: belowPhabletBreakpoint
                    ? 'none'
                    : '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <StrategiesProvider>
                  <StrategyVaultsTable />
                </StrategiesProvider>
              </SectionColumn>
            </Section>
          </Box>
        )
      ) : (
        <Box
          mt='1rem'
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <DataLoading
            loadingText={t(
              'pages.strategies.strategyDetailsPage.loadingStrategyData'
            )}
          />
        </Box>
      )}
    </>
  )
}

export default StrategyDetails
