import { Box } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useStakedSYLay from '@/hooks/staking/useStakedSpool'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import StakingActions from '@/components/organisms/Staking/StakingActions/StakingActions'
import AccumulatedSYlay from '@/components/organisms/Staking/StakingInfo/AccumulatedSYLay'
//import GetSpool from '@/components/organisms/Staking/StakingInfo/GetSpool'
import SpoolBalance from '@/components/organisms/Staking/StakingInfo/SpoolBalance'
import SpoolStaked from '@/components/organisms/Staking/StakingInfo/SpoolStaked'

import { StakingPageProps } from '@/types/staking'

const StakingOverview: React.FC<StakingPageProps> = ({ page, setPage }) => {
  const t = useLocale()
  const { account } = useWeb3React()
  const { stakedYLay: stakedSpool } = useStakedSYLay(account)

  return (
    <Section>
      <SectionColumn
        title={t('components.organisms.staking.overview.sectionTitle')}
        sx={{
          backgroundColor: 'white',
          borderTopLeftRadius: (theme) => theme.spacing(1),
          borderBottomLeftRadius: (theme) => theme.spacing(1),
        }}
      >
        <Box display='flex' gap={(theme) => theme.spacing(2)}>
          <Box
            display='flex'
            flexDirection='column'
            gap={(theme) => theme.spacing(1)}
            width='100%'
          >
            <SpoolBalance />
          </Box>
          <SpoolStaked />
          {!account || (!!Number(stakedSpool) && <AccumulatedSYlay />)}
        </Box>
        <Box display='flex' gap={(theme) => theme.spacing(2)}>
          <StakingActions page={page} setPage={setPage} />
        </Box>
      </SectionColumn>
    </Section>
  )
}

export default StakingOverview
