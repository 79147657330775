import { createContext } from 'react'

import {
  SmartVaultRowContextProps,
  SmartVaultRowState,
} from '@/store/smartVaultRow/smartVaultRow.types'

export const initialState: SmartVaultRowState = {
  vaultRowAction: null,
}

export const SmartVaultRowContext = createContext<SmartVaultRowContextProps>({
  ...initialState,
  setVaultRowAction: () => undefined,
})
