const Twitter = () => {
  return (
    <svg
      width='19'
      height='15'
      viewBox='0 0 19 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.8158 3.74593C16.8238 3.90793 16.8268 4.07193 16.8268 4.23393C16.8268 9.22393 13.0298 14.9759 6.0868 14.9759C3.9538 14.9759 1.9708 14.3509 0.299805 13.2789C0.595805 13.3139 0.895805 13.3319 1.1998 13.3319C2.9698 13.3319 4.5968 12.7279 5.8878 11.7169C4.2368 11.6859 2.8418 10.5959 2.3618 9.09593C2.5918 9.13893 2.8288 9.16193 3.0718 9.16193C3.4168 9.16193 3.7508 9.11693 4.0668 9.03093C2.3398 8.68293 1.0388 7.15793 1.0388 5.32793C1.0388 5.31193 1.0388 5.29693 1.0388 5.28093C1.5478 5.56393 2.1308 5.73393 2.7488 5.75393C1.7358 5.07593 1.0688 3.92193 1.0688 2.61093C1.0688 1.91993 1.2548 1.27093 1.5808 0.712926C3.4418 2.99793 6.2248 4.49993 9.3618 4.65793C9.2978 4.38093 9.2648 4.09393 9.2648 3.79693C9.2648 1.71293 10.9538 0.0239258 13.0388 0.0239258C14.1248 0.0239258 15.1058 0.480926 15.7948 1.21493C16.6538 1.04493 17.4618 0.730926 18.1918 0.298926C17.9098 1.17993 17.3108 1.91993 16.5318 2.38693C17.2958 2.29493 18.0218 2.09393 18.6998 1.79293C18.1938 2.55093 17.5538 3.21493 16.8158 3.74593Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Twitter
