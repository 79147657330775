import { Box } from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { parseEther } from 'ethers/lib/utils'
import { useMemo, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useWithdrawState from '@/hooks/context/useWithdrawState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useWithdrawalDetails from '@/hooks/smartVaults/useWithdrawalDetails'
import useWithdraw from '@/hooks/vaultList/Transactions/useWithdraw'

import StyledActionButton from '@/components/atoms/StyledActionButton'
import TransactionActionButtons from '@/components/molecules/TransactionActionButtons'
import WithdrawDisclaimer from '@/components/molecules/WithdrawDisclaimer'
import WithdrawOptions from '@/components/molecules/WithdrawOptions'
import WithdrawAmount from '@/components/organisms/SmartVaults/CollapsibleRow/Withdraw/WithdrawAmount'
import { VaultActionType } from '@/components/organisms/SmartVaults/VaultActions/VaultActions'

import { Modals } from '@/store/modal/modals.types'

import { WithdrawMode } from '@/constants/smartVault'

interface WithdrawLayoutProps {
  setAction: React.Dispatch<React.SetStateAction<VaultActionType>>
}

export enum UNFULFILLED_WITHDRAW_CONDITION {
  WRONG_NETWORK = 'WRONG_NETWORK',
}

const WithdrawLayout: React.FC<WithdrawLayoutProps> = ({ setAction }) => {
  const t = useLocale()
  const { chain } = useConnectionState()

  const { openModal, setModalType, closeModal } = useModalState()

  const { account } = useWeb3React()

  const { smartVaultData } = useVaultsPage()

  const { address, pendingDeposits, owner } = smartVaultData

  const { withdrawalDetails } = useWithdrawalDetails(account, address)

  const { withdrawalAmount } = useWithdrawState()

  const [mode, setMode] = useState<WithdrawMode>(
    chain === SupportedNetworks.ETHEREUM
      ? WithdrawMode.STANDARD
      : WithdrawMode.FAST
  )
  const withdraw = useWithdraw(mode === WithdrawMode.FAST)

  const [readMore, setReadMore] = useState<boolean>(false)

  const hasRewards = useMemo(
    () => !!Number(smartVaultData.incentivizedRewards.totalClaimableAmountUSD),
    [smartVaultData]
  )

  return (
    <Box padding='1.5rem 1.5rem'>
      {!!+pendingDeposits.totalUSD && <WithdrawDisclaimer />}
      <WithdrawAmount />
      <WithdrawOptions
        mode={mode}
        readMore={readMore}
        setMode={setMode}
        setReadMore={setReadMore}
      />
      <Box
        display='grid'
        gridTemplateColumns={hasRewards ? '60% 40%' : '100%'}
        marginTop='1rem'
        gap='0.5rem'
        alignItems='center'
      >
        <TransactionActionButtons
          confirmLabel={t(
            'components.organisms.smartVaults.collapsibleRow.withdraw.withdrawTransaction.actionButtons'
          )}
          disabled={
            !withdrawalDetails ||
            (withdrawalDetails
              ? parseEther(withdrawalDetails.userBalance)
              : BigNumber.from('0')
            ).lt(parseEther(withdrawalAmount ? withdrawalAmount : '0')) ||
            !withdrawalAmount
          }
          onConfirm={() => {
            if (owner.toLowerCase() === account.toLowerCase()) {
              setModalType({
                warningModalType: {
                  message: t(
                    'components.organisms.dashboard.creator.creatorVaultsTable.row.warning.claimEarnings'
                  ),
                  isActionRequired: true,
                  action: () => {
                    closeModal(Modals.WARNING_MODAL)
                    withdraw()
                  },
                },
              })
              openModal(Modals.WARNING_MODAL)
              return
            }
            withdraw()
          }}
        />
        {hasRewards && (
          <StyledActionButton
            variant='outlined'
            onClick={() => setAction(VaultActionType.WITHDRAW_OR_CLAIM)}
          >
            {t('pages.smartVaults.vaultDetailsPage.cancel.label')}
          </StyledActionButton>
        )}
      </Box>
    </Box>
  )
}

export default WithdrawLayout
