import flattenLocale from '@/locales/flattenedLocale'

const getLocale = () => {
  const flatLocale = flattenLocale()
  const t = (key: string) => {
    if (!flatLocale[key]) {
      console.warn('Locale:', flatLocale)
      console.warn('Invalid path:', key)
      throw new Error(`Invalid locale key: ${key}`)
    }
    return flatLocale[key]
  }

  return t
}

export default getLocale
