import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useMetaVaultDeployer from '@/hooks/metavaults/useMetaVaultDeployer'

interface CreateVaultButtonProps {
  buttonText: string
  navigationRoute: string
  navigationRouteMetavault?: string
}

const CreateVaultButton: React.FC<CreateVaultButtonProps> = ({
  buttonText,
  navigationRoute,
  navigationRouteMetavault,
}) => {
  const navigate = useNavigate()
  const { isWhitelisted } = useMetaVaultDeployer()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { resetAll } = useCreateMetavaultState()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (route: string) => {
    resetAll()
    navigate(route)
    handleClose()
  }

  return (
    <Box>
      <Button
        variant='contained'
        color='primary'
        sx={{ px: 3, py: 1 }}
        onClick={isWhitelisted ? handleClick : () => navigate(navigationRoute)}
        endIcon={isWhitelisted ? <ExpandMoreIcon /> : null}
      >
        <Typography variant='button' sx={{ whiteSpace: 'nowrap' }}>
          {isWhitelisted ? 'Create' : buttonText}
        </Typography>
      </Button>
      {isWhitelisted && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ mt: 1, '& .MuiPaper-root': { width: '170px' } }}
        >
          <Box sx={{ p: 1 }}>
            <MenuItem
              sx={{
                '&:hover': {
                  color: '#006BA6',
                  backgroundColor: '#fff',
                },
              }}
              onClick={() => handleMenuItemClick(navigationRoute)}
            >
              Vault
            </MenuItem>
            <MenuItem
              sx={{
                '&:hover': {
                  color: '#006BA6',
                  backgroundColor: '#fff',
                },
              }}
              onClick={() => handleMenuItemClick(navigationRouteMetavault)}
            >
              Metavault
            </MenuItem>
          </Box>
        </Menu>
      )}
    </Box>
  )
}

export default CreateVaultButton
