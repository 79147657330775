import WarningIcon from '@mui/icons-material/Warning'
import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import { useParams } from 'react-router-dom'

import useStrategiesState from '@/hooks/context/useStrategiesState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useStrategyVaults from '@/hooks/strategies/useStrategyVaults'

import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import TableStatus from '@/components/molecules/TableStatus'
import TableWrapper from '@/components/molecules/TableWrapper'
import StrategyVaultsMobileRow from '@/components/organisms/Strategies/StrategyVaultsMobileRow'
import StrategyVaultsTableRow from '@/components/organisms/Strategies/StrategyVaultsTableRow'

import { strategyVaultsHeadCells } from '@/constants/strategies'
import { sort } from '@/utils/table'

const StrategyVaultsTable = () => {
  const { strategyId } = useParams()
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { vaults, isLoading } = useStrategyVaults(strategyId)
  const { strategyVaultsSort, handleSortClickVaults } = useStrategiesState()

  return (
    <Box
      sx={{
        border: belowPhabletBreakpoint
          ? 'none'
          : '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        backgroundColor: 'white',
        my: (theme) => theme.spacing(1),
      }}
    >
      {belowPhabletBreakpoint ? (
        isLoading ? (
          <DataLoading loadingText='Loading Smart Vaults' />
        ) : vaults?.length ? (
          vaults
            .sort((a, b) => sort(a, b, strategyVaultsSort))
            .map((vault) => (
              <StrategyVaultsMobileRow key={vault.address} row={vault} />
            ))
        ) : (
          <Box
            my='2rem'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <WarningIcon
              sx={{ height: '90px', width: '90px' }}
              color='primary'
            />
            <DataMissing text='No vaults found...' />
          </Box>
        )
      ) : (
        <TableWrapper
          header={strategyVaultsHeadCells}
          headerClass='default-table-head'
          handleSortClick={handleSortClickVaults}
          sortType={strategyVaultsSort}
          tokens
        >
          {isLoading ? (
            <TableBody>
              <TableStatus colSpan={4}>
                <DataLoading loadingText='Loading Smart Vaults' />
              </TableStatus>
            </TableBody>
          ) : vaults?.length ? (
            <TableBody>
              {vaults
                .sort((a, b) => sort(a, b, strategyVaultsSort))
                .map((vault) => (
                  <StrategyVaultsTableRow key={vault.address} row={vault} />
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <Box
                    my='2rem'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <WarningIcon
                      sx={{ height: '90px', width: '90px' }}
                      color='primary'
                    />
                    <DataMissing text='No vaults found...' />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </TableWrapper>
      )}
    </Box>
  )
}

export default StrategyVaultsTable
