import { Box } from '@mui/material'
import { Network } from '@web3-react/network'
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { useCallback } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import { useOrderedConnections } from '@/hooks/web3/useOrderedConnections'

import ConnectWalletButton from '@/components/molecules/ConnectWalletButton'

import { getAddChainParameters } from '@/config/web3/chains'
import web3reactError from '@/utils/web3ReactError'
import { GnosisSafe } from '@/web3-modules/gnosisSafe'

import { Connection } from '@/types/connectors'

const ConnectWalletModalOptions = () => {
  const { chain, setActivatingWallet } = useConnectionState()
  const { toggleMenu } = useTopMenuState()

  const connections = useOrderedConnections()

  const tryActivation = useCallback(
    async (connection: Connection) => {
      const { connector, getProviderInfo } = connection

      try {
        if (connection.overrideActivate?.()) return
        setActivatingWallet(getProviderInfo().name)
        if (connector instanceof GnosisSafe) {
          await void connector.activate()
        } else if (
          connector instanceof WalletConnect ||
          connector instanceof Network
        ) {
          await connector.activate(chain)
        } else {
          await connector.activate(getAddChainParameters(chain))
        }
        toggleMenu(false)
      } catch (error) {
        // Ideally set to setError global context.
        // console.debug(`web3-react connection error: ${error}`)
        // Ideally set to setError global context.
        web3reactError(error as Error)
      } finally {
        setActivatingWallet(undefined)
      }
    },
    [chain]
  )

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1.5}
      sx={{ px: (theme) => theme.spacing(1.5) }}
    >
      {connections.orderedConnections.map((connection, index) => (
        <ConnectWalletButton
          key={index}
          connection={connection}
          onClick={() => tryActivation(connection)}
        />
      ))}
    </Box>
  )
}

export default ConnectWalletModalOptions
