import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormGroup,
  Typography,
} from '@mui/material'
import { PropsWithChildren } from 'react'

interface VaultFeaturesChecklistProps extends PropsWithChildren {
  title: string
  subtitle?: string | string[]
  avatar: React.ReactNode
}

const VaultFeaturesChecklist: React.FC<VaultFeaturesChecklistProps> = ({
  title,
  subtitle,
  avatar,
  children,
}) => (
  <Card
    sx={{ height: 'fit-content', border: '1px solid rgba(0, 0, 0, 0.12)' }}
    elevation={0}
  >
    <CardHeader
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        padding: (theme) => theme.spacing(1.5, 2),
        gap: (theme) => theme.spacing(2),
      }}
      avatar={avatar}
      title={<Typography variant='subtitle1'>{title}</Typography>}
      subheader={
        Array.isArray(subtitle)
          ? subtitle.map((subtitle, index) => (
              <Typography variant='subtitle1' fontSize={14} key={index}>
                {subtitle}
              </Typography>
            ))
          : subtitle
      }
    />
    <CardContent>
      <FormControl
        component='fieldset'
        variant='standard'
        sx={{ width: '100%' }}
      >
        <FormGroup>{children}</FormGroup>
      </FormControl>
    </CardContent>
  </Card>
)

export default VaultFeaturesChecklist
