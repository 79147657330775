import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography } from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useWithdrawAll from '@/hooks/vaultList/Transactions/useWithdrawAll'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import WithdrawOptions from '@/components/molecules/WithdrawOptions'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

import { WithdrawMode } from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

const WithdrawModal: React.FC = () => {
  const t = useLocale()
  const { chain } = useConnectionState()

  const { withdrawEarningsVault } = useCreatorDashboardState()

  const { closeModal } = useModalState()
  const [mode, setMode] = useState<WithdrawMode>(
    chain === SupportedNetworks.ETHEREUM
      ? WithdrawMode.STANDARD
      : WithdrawMode.FAST
  )

  const vaultDetails = withdrawEarningsVault

  const [readMore, setReadMore] = useState<boolean>(false)

  const withdrawAll = useWithdrawAll(
    mode === WithdrawMode.FAST,
    vaultDetails?.address ?? ''
  )

  return (
    <ModalBox id='withdrawModal'>
      <ModalBoxOuterContainer sx={{ width: '360px', padding: '0rem' }}>
        <Box
          display='flex'
          paddingY='1rem'
          px='1rem'
          alignItems='center'
          justifyContent='space-between'
          borderBottom='1px solid rgba(0, 0, 0, 0.12)'
        >
          <Typography fontWeight={500}>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.row.action'
            )}
          </Typography>
          <Box display='flex' onClick={() => closeModal(Modals.WITHDRAW_MODAL)}>
            <CloseIcon
              sx={{
                color: 'text.secondary',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
        <Box display='flex' margin='1rem' mt='0rem' flexDirection='column'>
          <Box
            mt='1rem'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography fontSize='14px' color='text.secondary'>
              Amount
            </Typography>
            <Typography fontSize='14px'>
              ${formatUSD(vaultDetails ? vaultDetails.userBalance : 0)}
            </Typography>
          </Box>
          <WithdrawOptions
            mode={mode}
            setMode={setMode}
            readMore={readMore}
            setReadMore={setReadMore}
          />
          <Button variant='contained' sx={{ mt: '2rem' }} onClick={withdrawAll}>
            WITHDRAW
          </Button>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default WithdrawModal
