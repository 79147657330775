import { Box, styled } from '@mui/material'

export const TopMenuContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1250px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: '50px',
  [theme.breakpoints.down('lg')]: {
    padding: '0rem 1rem',
  },
}))
