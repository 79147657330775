import useLocale from '@/hooks/context/useLocale'

import CustomButton from '@/components/atoms/CustomButton'

interface DataButtonsProps {
  selected: string[]
  editMode: boolean
  handleSave: () => void
  openDeleteModal: () => void
  handleCancel: () => void
}

const DataButtons: React.FC<DataButtonsProps> = ({
  selected,
  editMode,
  handleSave,
  openDeleteModal,
  handleCancel,
}) => {
  const t = useLocale()
  return (
    <>
      {editMode ? (
        <CustomButton
          color='primary'
          size='small'
          variant='contained'
          onClick={handleCancel}
        >
          {t(
            'components.organisms.createVault.guards.modals.dataButtons.cancel.label'
          )}
        </CustomButton>
      ) : (
        <>
          <CustomButton
            color='primary'
            size='small'
            variant='contained'
            disabled={selected.length === 0}
            onClick={() => openDeleteModal()}
          >
            {t(
              'components.organisms.createVault.guards.modals.dataButtons.delete.label'
            )}
          </CustomButton>
          <CustomButton
            color='primary'
            size='small'
            variant='contained'
            onClick={handleSave}
          >
            {t(
              'components.organisms.createVault.guards.modals.dataButtons.save.label'
            )}
          </CustomButton>
        </>
      )}
    </>
  )
}

export default DataButtons
