import {
  TopMenuAction,
  TopMenuActionTypes,
  TopMenuState,
} from '@/store/topMenu/topMenu.types'

const topMenuReducer = (
  state: TopMenuState,
  action: TopMenuAction
): TopMenuState => {
  switch (action.type) {
    case TopMenuActionTypes.TOGGLE_MENU:
      return {
        ...state,
        isOpen: action.payload,
        activeSubmenuItem:
          state.isOpen && state.activeSubmenuItem !== ''
            ? ''
            : state.activeSubmenuItem,
      }
    case TopMenuActionTypes.SET_ACTIVE_SUBMENU_ITEM:
      return { ...state, activeSubmenuItem: action.payload }
    case TopMenuActionTypes.SET_ACTIVE_MENU_ITEM:
      return { ...state, activeMenuItem: action.payload }
    case TopMenuActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload }
    case TopMenuActionTypes.SET_APP_MODE:
      return { ...state, appMode: action.payload }
    default:
      return state
  }
}

export default topMenuReducer
