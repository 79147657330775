import { Box, Typography } from '@mui/material'
import { StrategyAllocationDetails } from '@solidant/spool-v2-fe-lib'
import { memo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'

import LinearProgressWithLabel from '@/components/atoms/LinearProgressWithLabel'
import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { NOT_AVILABLE } from '@/constants'
import { formatNumber } from '@/utils/formats'

interface StrategiesMobileRowProps {
  strategy: StrategyAllocationDetails
  totalAllocation: number
  lastItem: boolean
}

const StrategiesMobileRow: React.FC<StrategiesMobileRowProps> = ({
  strategy,
  totalAllocation,
  lastItem,
}) => {
  const t = useLocale()

  const { chain } = useConnectionState()

  const {
    name,
    assetGroup,
    apy,
    allocation,
    riskScore,
    errorState,
    // auditor,
    // logo,
  } = strategy

  //   const info =
  //     strategiesDescriptions[name as keyof typeof strategiesDescriptions]

  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        borderBottom={!lastItem && '1px solid rgba(0, 0, 0, 0.12)'}
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='0.5rem'
        sx={{
          opacity: !errorState || errorState === 'normal' ? 1 : 0.35,
        }}
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.strategy')}{' '}
          </Typography>
          <Box display='flex' alignItems='center'>
            <SmartVaultNameTableTypography variant='body2'>
              {name}
            </SmartVaultNameTableTypography>
            <Box display='flex' ml='0.2rem' alignItems='center'>
              {assetGroup &&
                assetGroup?.assetGroupTokens.map(({ address }, index) => (
                  <Box
                    key={index}
                    padding='2px'
                    sx={{
                      position: 'relative',
                      ml: index !== 0 ? '-12px' : '0',
                    }}
                    height={20}
                    width={20}
                  >
                    {mapAssetTokenIcon(chain, address ?? '')}
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.7dapy')}{' '}
          </Typography>
          <Typography fontSize='14px'>
            {formatNumber(Number(apy), 2)}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.allocation')}
          </Typography>
          <LinearProgressWithLabel
            noMarginRight
            sx={{ width: '40px' }}
            value={(Number(allocation) / totalAllocation) * 100}
          />
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.riskScore')}
          </Typography>
          <Typography fontSize='14px'>
            {riskScore ? `${formatNumber(riskScore, 1)}/10` : NOT_AVILABLE}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default memo(StrategiesMobileRow)
