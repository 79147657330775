import { HistoricalDeposit } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

import { MUTATE_SLEEP_DURATION } from '@/constants'
import { sleep } from '@/utils/web3'

const useDepositReceipts = (selectedVault: string) => {
  const { sdk } = useContext(AppContext)

  const { account } = useWeb3React()

  const { chain } = useConnectionState()

  const { data, error, isLoading, mutate } = useSWR(
    sdk && account && chain
      ? ['DepositReceipts', selectedVault, account, chain]
      : null,
    ([_, selectedVault, userAddress]) =>
      sdk.smartVault.getHistoricalDeposits(userAddress, selectedVault)
  )

  const updateDepositReceipts = async (previousValue?: HistoricalDeposit[]) => {
    let retries = 0

    do {
      const currentValue: HistoricalDeposit[] = await mutate()

      if (currentValue.length !== previousValue.length) {
        break
      }

      retries++
      await sleep(MUTATE_SLEEP_DURATION)
    } while (retries < 5)
  }

  return {
    depositReceipts: data,
    isLoading,
    error,
    updateDepositReceipts,
  }
}

export default useDepositReceipts
