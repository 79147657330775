import { Box, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import TooltipApyGrid from '@/components/molecules/TooltipApyGrid'
import ApyTooltipValue from '@/components/organisms/Tooltips/ApyTooltipValue'

import { ECOSYSTEM_FEE, PLATFORM_FEE } from '@/constants'

interface ApyTooltipProps {
  performanceFee: number
  managementFee: number
  depositFee: number
  baseApy: number
  adjustedApy: number
  incentivizedRewardsApy: number
}

const ApyTooltip: React.FC<ApyTooltipProps> = ({
  performanceFee,
  managementFee,
  depositFee,
  baseApy,
  adjustedApy,
  incentivizedRewardsApy,
}) => {
  const t = useLocale()

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        sx={{ background: 'rgba(0, 0, 0, 0.08)' }}
        borderRadius='4px 4px 0 0'
      >
        {/*<PauseCircleOutlineIcon
          className='md'
          sx={{ mx: (theme) => theme.spacing(1), visibility: 'hidden' }}
        />*/}
        <TooltipApyGrid
          items={[
            {
              title: (
                <Typography variant='subtitle2'>
                  {t('components.organisms.tooltips.apyTooltip.baseApy.title')}
                </Typography>
              ),
              value: <ApyTooltipValue variant='subtitle2' value={baseApy} />,
            },
          ]}
        />
      </Box>
      <Box display='flex' alignItems='center'>
        {/*<RemoveCircleOutlineIcon
          className='md'
          color='error'
          sx={{ mx: (theme) => theme.spacing(1) }}
        />*/}
        <TooltipApyGrid
          borderTop
          items={[
            {
              title: (
                <Typography variant='body2'>
                  {t(
                    'components.organisms.tooltips.apyTooltip.depositFee.title'
                  )}
                </Typography>
              ),
              value: <ApyTooltipValue value={depositFee} />,
            },
            {
              title: (
                <Typography variant='body2'>
                  {t(
                    'components.organisms.tooltips.apyTooltip.managementFee.title'
                  )}
                </Typography>
              ),
              value: <ApyTooltipValue value={managementFee} />,
            },
            {
              title: (
                <Typography variant='body2'>
                  {t(
                    'components.organisms.tooltips.apyTooltip.performanceFee.title'
                  )}
                </Typography>
              ),
              value: <ApyTooltipValue value={performanceFee} />,
            },
            {
              title: (
                <Typography variant='body2'>
                  {t('components.organisms.tooltips.apyTooltip.daoFee.title')}
                </Typography>
              ),
              value: <ApyTooltipValue value={ECOSYSTEM_FEE + PLATFORM_FEE} />,
            },
          ]}
        />
      </Box>
      <Box
        display='flex'
        alignItems='center'
        sx={{ background: 'rgba(0, 0, 0, 0.08)' }}
        borderTop='1px solid #f2f2f2'
      >
        {/*<PauseCircleOutlineIcon
          className='md'
          color='info'
          sx={{
            mx: (theme) => theme.spacing(1),
            color: 'rgba(0,0,0,0.26)',
            rotate: '90deg',
          }}
        />*/}
        <TooltipApyGrid
          items={[
            {
              title: (
                <Typography variant='subtitle2'>
                  {t('components.organisms.tooltips.apyTooltip.apy.title')}
                </Typography>
              ),
              subtitle: (
                <Typography fontSize='12px'>
                  ({t('components.organisms.tooltips.apyTooltip.apy.subtitle')})
                </Typography>
              ),
              value: (
                <ApyTooltipValue
                  variant='subtitle2'
                  value={adjustedApy - incentivizedRewardsApy}
                />
              ),
            },
          ]}
        />
      </Box>
      <Box display='flex' alignItems='center'>
        <TooltipApyGrid
          borderTop
          items={[
            {
              title: (
                <Typography variant='body2'>
                  {t(
                    'components.organisms.tooltips.apyTooltip.incentivizedRewardsApy.title'
                  )}
                </Typography>
              ),
              value: <ApyTooltipValue value={incentivizedRewardsApy} />,
            },
          ]}
        />
      </Box>
      <Box
        display='flex'
        alignItems='center'
        sx={{ background: 'rgba(0, 0, 0, 0.08)', borderRadius: '0 0 4px 4px' }}
        borderTop='1px solid #f2f2f2'
      >
        <TooltipApyGrid
          items={[
            {
              title: (
                <Typography variant='subtitle2'>
                  {t('components.organisms.tooltips.apyTooltip.totalApy.title')}
                </Typography>
              ),
              subtitle: (
                <Typography fontSize='12px'>
                  (
                  {t(
                    'components.organisms.tooltips.apyTooltip.totalApy.subtitle'
                  )}
                  )
                </Typography>
              ),
              value: (
                <ApyTooltipValue
                  variant='subtitle2'
                  //value={adjustedApy + incentivizedRewardsApy}
                  value={adjustedApy}
                />
              ),
            },
          ]}
        />
      </Box>
    </>
  )
}

export default ApyTooltip
