import { StrategiesDto } from '@/types/create-vault'

const handleSelectStrategies = (
  strategy: StrategiesDto,
  selectedStrategies: StrategiesDto[],
  setFilterByAssetGroup: (filterByAssetGroup: string) => void,
  setSelectedStrategies: (strategies: StrategiesDto[]) => void
) => {
  const isAlreadySelected = selectedStrategies.some(
    (selectedStrategy) => selectedStrategy.name === strategy.name
  )

  const updatedStrategies = isAlreadySelected
    ? selectedStrategies.filter(
        (selectedStrategy) => selectedStrategy.name !== strategy.name
      )
    : [...selectedStrategies, strategy]

  if (updatedStrategies.length) {
    setFilterByAssetGroup(strategy.assetGroup.id)
  }

  setSelectedStrategies(updatedStrategies)
}

export default handleSelectStrategies
