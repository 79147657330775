import { Box, Grid, Stack, Typography } from '@mui/material'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useDebounce from '@/hooks/utils/useDebounce'
import useDepositSwapConversion from '@/hooks/vaultList/Transactions/useDepositSwapConversion'
import usePriceFeed from '@/hooks/web3/usePriceFeed'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { WRAPPED_TOKEN_ADDRESS } from '@/constants/tokens'
import { formatUSD } from '@/utils/formats'
import { getIsNativeCurrency, getPricePerAssetAmount } from '@/utils/web3'

interface SingleAssetSwapInfoItemProps {
  isConversion: boolean
}

const SingleAssetSwapInfoItem: React.FC<SingleAssetSwapInfoItemProps> = ({
  isConversion,
}) => {
  const { chain } = useConnectionState()

  const { smartVaultData } = useVaultsPage()

  const { assetGroup } = smartVaultData

  const { singleDeposit } = useDepositState()

  const { decimals } = useTokenDetails(singleDeposit.tokenAddress)

  const {
    address,
    decimals: underlyingVaultAssetDecimals,
    symbol: underlyingVaultAssetSymbol,
  } = smartVaultData.assetGroup.assetGroupTokens[0]

  const selectedVaultAsset = assetGroup.assetGroupTokens[0].address

  const debouncedAmount = useDebounce(
    singleDeposit.amount
      ? parseUnits(singleDeposit.amount, decimals).toString()
      : ''
  )

  const { depositSwapConversion } = useDepositSwapConversion(
    // if converting eth to vault asset, use wrapped eth price
    getIsNativeCurrency(singleDeposit.tokenAddress)
      ? WRAPPED_TOKEN_ADDRESS[chain]
      : singleDeposit.tokenAddress,
    selectedVaultAsset,
    debouncedAmount
  )

  const { priceFeed } = usePriceFeed(address)

  const toAmount = isConversion
    ? depositSwapConversion
      ? formatUnits(
          depositSwapConversion.dstAmount,
          underlyingVaultAssetDecimals
        )
      : singleDeposit.amount
    : singleDeposit.amount

  return (
    <Grid
      key={address}
      display='flex'
      item
      xs={10}
      sx={{
        '&:last-child': {
          borderBottom: 'none',
          backgroundColor: '#f5f5f5',
        },
        width: '100%',
      }}
    >
      <Grid item display='flex' xs={7} sx={{ width: '100%' }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
          padding={(theme) => theme.spacing(1, 2)}
        >
          <Stack textAlign='left'>
            <Typography>{formatUSD(toAmount ? toAmount : '0.0')}</Typography>
            <Typography variant='h6' fontSize='12px' color='text.secondary'>
              ~${' '}
              {formatUSD(
                getPricePerAssetAmount(
                  toAmount,
                  priceFeed,
                  underlyingVaultAssetDecimals,
                  false
                )
              )}
            </Typography>
          </Stack>
          <Stack display='flex'>
            <Box display='flex' alignItems='center' gap='4px'>
              <Typography variant='body2' fontSize='16px' mr='0.2rem'>
                {underlyingVaultAssetSymbol}
              </Typography>
              <Box
                display='flex'
                alignItems='center'
                sx={{ transform: 'scale(1.4)' }}
              >
                {address && mapAssetTokenIcon(chain, address)}
              </Box>
            </Box>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SingleAssetSwapInfoItem
