import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Button, ClickAwayListener, Tooltip } from '@mui/material'

import useClipboard from '@/hooks/utils/useClipboard'

interface CopyToClipboardButtonProps {
  textToCopy: string
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  textToCopy,
}) => {
  const { isCopied, copyToClipboard } = useClipboard()

  return (
    <ClickAwayListener onClickAway={() => isCopied && copyToClipboard('')}>
      <Tooltip title={isCopied ? 'Copied!' : 'Copy to clipboard'}>
        <Button
          size='small'
          sx={{
            '&:focus': { border: 'none', outline: 'none' },
            height: '24px',
            width: '18px',
          }}
          variant='text'
          color='primary'
          disableRipple
          startIcon={isCopied ? <CheckCircleIcon /> : <FileCopyIcon />}
          onClick={() => copyToClipboard(textToCopy)}
        ></Button>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default CopyToClipboardButton
