import { MetaVault } from '@spool.fi/spool-v2-sdk'

import { CreatorVault } from '@/types/dashboard'

export const morphMetaVaultToCreatorVault = (
  metaVault: MetaVault
): CreatorVault => {
  return {
    name: metaVault.metaVaultName,
    address: metaVault.metaVaultId,
    totalVolumeRouted: {
      total: metaVault.metaVaultTvr,
    },
    adjustedApy: metaVault.metaVaultApy,
    assetGroup: metaVault.vaults[0].assetGroup,
    pendingDeposits: {
      totalUSD: '0',
      assets: [],
    },
    pendingWithdrawals: {
      total: '0',
    },
    deposits: {
      total: '0',
      assets: [],
    },
    strategies: [],
    userBalance: '0',
    incentivizedRewards: {
      rewards: [],
      totalApy: '0',
      totalClaimableAmountUSD: '0',
    },
    isMetavault: true,
    metavaultVaults: metaVault.vaults,
  }
}
