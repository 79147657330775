import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

const useDepositSwapConversion = (
  fromAddress: string,
  toAddress: string,
  amountInDecimals: string
) => {
  const { chain } = useConnectionState()

  const { sdk } = useContext(AppContext)

  const { data, error, isLoading } = useSWR(
    sdk && fromAddress && toAddress && amountInDecimals
      ? ['depositSwapConversion', fromAddress, toAddress, amountInDecimals]
      : null,
    ([_, fromAddress, toAddress, amountInDecimals]) => {
      if (
        !amountInDecimals ||
        amountInDecimals === '0' ||
        fromAddress === toAddress
      )
        return null
      return sdk.externalApiService.oneInchApi.getConversionData(
        fromAddress,
        toAddress,
        amountInDecimals,
        chain
      )
    }
  )

  return {
    depositSwapConversion: data,
    isLoading,
    error,
  }
}

export default useDepositSwapConversion
