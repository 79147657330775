import { formatUnits, parseUnits } from '@ethersproject/units'
import { Box, Stack } from '@mui/material'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useMultiAssetDepositRatio from '@/hooks/vaultList/Transactions/useMultiAssetDepositRatio'
import useMultiAssetSwapAndDeposit from '@/hooks/vaultList/Transactions/useMultiAssetSwapAndDeposit'
import useApproveToken from '@/hooks/web3/useApproveToken'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import ArrowDownDivider from '@/components/atoms/ArrowDownDivider'
import MultiAssetSwapList from '@/components/molecules/MultiAssetDeposit/MultiAssetSwapList'
import TransactionActionButtons from '@/components/molecules/TransactionActionButtons'
import DepositDisclaimer from '@/components/organisms/SmartVaults/CollapsibleRow/Deposit/DepositDisclaimer'
import MultiDepositSwapAmount from '@/components/organisms/SmartVaults/CollapsibleRow/Deposit/Swap/MultiAssetDepositSwapAmount'
import SwapSlippage from '@/components/organisms/SmartVaults/CollapsibleRow/Deposit/Swap/SwapSlippage'
import DepositWarning from '@/components/organisms/SmartVaults/VaultActions/Deposit/DepositWarning'
import OneInchDisclaimer from '@/components/organisms/SmartVaults/VaultActions/Deposit/OneInchDisclaimer'

import { MultiAssetDeposit } from '@/store/deposit/deposit.types'

import { API_ENDPOINTS } from '@/config/sdk'
import { gaIds } from '@/constants/googleAnalyticIDs'
import { NATIVE_TOKEN, tokenAddresses } from '@/constants/tokens'
import { getIsNativeCurrency } from '@/utils/web3'

const MultiAssetDepositSwapTransaction = () => {
  const t = useLocale()
  const { chain } = useConnectionState()

  const { smartVaultData } = useVaultsPage()
  const { multiAssetSwap } = useDepositState()

  const { address, assetGroup } = smartVaultData
  const { amount, tokenAddress } = multiAssetSwap

  const { assetGroupTokens } = assetGroup

  const assetsDecimal = Object.values(assetGroupTokens).map(
    ({ decimals }) => decimals
  )

  const { multiAssetDepositRatio, isLoading } = useMultiAssetDepositRatio(
    address ?? '',
    assetsDecimal
  )

  const initialMultiAssetDeposit = assetGroupTokens.reduce(
    (acc, { address, decimals }, index) => {
      return {
        ...acc,
        [address]: {
          amount: '',
          ratio: multiAssetDepositRatio ? multiAssetDepositRatio[index] : '0',
          decimals,
          priceFeed: '0',
        },
      } as MultiAssetDeposit
    },
    {}
  )

  const [multiAssetDeposit, setMultiAssetDeposit] = useState<MultiAssetDeposit>(
    initialMultiAssetDeposit
  )

  const { isTokenApproved, allowance, approve } = useApproveToken(
    tokenAddress,
    API_ENDPOINTS[chain].DEPOSIT_SWAP_MANAGER,
    amount
  )

  const swapAndDeposit = useMultiAssetSwapAndDeposit(multiAssetDeposit)

  const isNative = getIsNativeCurrency(tokenAddress)

  const { decimals } = useTokenDetails(tokenAddress)

  const isResetAndDepositUSDT =
    tokenAddress === tokenAddresses[chain].USDT &&
    parseUnits(amount || '0', decimals).gt(allowance) &&
    !allowance.isZero()

  const { balance } = useUserBalance(tokenAddress)

  return (
    <>
      <OneInchDisclaimer />
      <Box
        padding='1.5rem 1.5rem'
        display='flex'
        flexDirection='column'
        gap='1rem'
      >
        <MultiDepositSwapAmount
          multiAssetDeposit={multiAssetDeposit}
          setMultiAssetDeposit={setMultiAssetDeposit}
          loading={isLoading}
        />

        <Stack gap={(theme) => theme.spacing(2)}>
          <ArrowDownDivider />

          <MultiAssetSwapList
            assets={assetGroupTokens}
            multiAssetDeposit={multiAssetDeposit}
            isTokenApproved={isTokenApproved}
            multiAssetDepositRatio={multiAssetDepositRatio}
            loading={isLoading}
            setMultiAssetDeposit={setMultiAssetDeposit}
          />

          <SwapSlippage />

          {multiAssetSwap &&
            tokenAddress === NATIVE_TOKEN &&
            amount === formatUnits(balance, decimals) && (
              <DepositWarning
                text={t(
                  'pages.smartVaults.vaultDetailsPage.deposit.maxEthWarning'
                )}
              />
            )}

          <TransactionActionButtons
            id={gaIds.deposit}
            confirmLabel={
              isTokenApproved || isNative
                ? t(
                    'components.organisms.smartVaults.collapsibleRow.deposit.standard.multiDepositTransaction.transactionButton.label'
                  )
                : isResetAndDepositUSDT
                ? t(
                    'components.organisms.smartVaults.collapsibleRow.deposit.standard.multiDepositTransaction.transactionButton.resetAndApproveToken'
                  )
                : t(
                    'components.organisms.smartVaults.collapsibleRow.deposit.standard.multiDepositTransaction.transactionButton.approve'
                  )
            }
            disabled={
              !multiAssetSwap.amount ||
              parseUnits(multiAssetSwap.amount, decimals).isZero()
            }
            onConfirm={
              // if swapping from nativeCurrency, no approval needed
              isTokenApproved || isNative
                ? swapAndDeposit
                : () => approve(amount)
            }
            sx={{
              width: `100%`,
            }}
          />
          <DepositDisclaimer />
        </Stack>
      </Box>
    </>
  )
}

export default MultiAssetDepositSwapTransaction
