import getLocale from '@/locales/getLocale'

import { TransactionStatus, TransactionType } from '@/store/modal/modals.types'

const t = getLocale()

export const PATH_TO_MESSAGE: {
  [transactionStatus in Exclude<
    TransactionStatus,
    TransactionStatus.PendingSignature
  >]: Partial<{
    [transactionType in TransactionType]: string
  }>
} = {
  [TransactionStatus.Success]: {
    [TransactionType.Approve]: t(
      'components.organisms.actionModal.config.success.approve'
    ),
    [TransactionType.MultiTokenApproval]: t(
      'components.organisms.actionModal.config.success.multiTokenApproval'
    ),
    [TransactionType.Withdraw]: t(
      'components.organisms.actionModal.config.success.withdraw'
    ),
    [TransactionType.Deposit]: t(
      'components.organisms.actionModal.config.success.deposit'
    ),
    [TransactionType.ClaimWithdraw]: t(
      'components.organisms.actionModal.config.success.claimWithdraw'
    ),
    [TransactionType.SwapAndDeposit]: t(
      'components.organisms.actionModal.config.success.swapAndDeposit'
    ),
    [TransactionType.CreateVault]: t(
      'components.organisms.actionModal.config.success.createVault'
    ),
    [TransactionType.CreateMetavault]: t(
      'components.organisms.actionModal.config.success.createMetavault'
    ),
    [TransactionType.UpdateMetavaultAllocations]: t(
      'components.organisms.actionModal.config.success.updateMetavaultAllocations'
    ),
    [TransactionType.ManageMetavaultVaults]: t(
      'components.organisms.actionModal.config.success.updateMetavaultConfiguration'
    ),
    [TransactionType.Stake]: t(
      'components.organisms.actionModal.config.success.stake'
    ),
    [TransactionType.Unstake]: t(
      'components.organisms.actionModal.config.success.unstake'
    ),
    [TransactionType.ClaimToken]: t(
      'components.organisms.actionModal.config.success.claimToken'
    ),
    [TransactionType.ClaimAndStakeToken]: t(
      'components.organisms.actionModal.config.success.claimAndStakeToken'
    ),
    [TransactionType.IncentivizeToken]: t(
      'components.organisms.actionModal.config.success.incentivizeToken'
    ),
    [TransactionType.ReincentivizeToken]: t(
      'components.organisms.actionModal.config.success.reincentivizeToken'
    ),
    [TransactionType.GovernanceVote]: t(
      'components.organisms.actionModal.config.success.governanceVote'
    ),
    [TransactionType.ClaimSvts]: t(
      'components.organisms.actionModal.config.success.claimSvts'
    ),
  },
  [TransactionStatus.Failure]: {
    [TransactionType.Approve]: t(
      'components.organisms.actionModal.config.failure.approve'
    ),
    [TransactionType.MultiTokenApproval]: t(
      'components.organisms.actionModal.config.failure.multiTokenApproval'
    ),
    [TransactionType.Withdraw]: t(
      'components.organisms.actionModal.config.failure.withdraw'
    ),
    [TransactionType.Deposit]: t(
      'components.organisms.actionModal.config.failure.deposit'
    ),
    [TransactionType.ClaimWithdraw]: t(
      'components.organisms.actionModal.config.failure.claimWithdraw'
    ),
    [TransactionType.SwapAndDeposit]: t(
      'components.organisms.actionModal.config.failure.swapAndDeposit'
    ),
    [TransactionType.CreateVault]: t(
      'components.organisms.actionModal.config.failure.createVault'
    ),
    [TransactionType.CreateMetavault]: t(
      'components.organisms.actionModal.config.failure.createMetavault'
    ),
    [TransactionType.UpdateMetavaultAllocations]: t(
      'components.organisms.actionModal.config.failure.updateMetavaultAllocations'
    ),
    [TransactionType.ManageMetavaultVaults]: t(
      'components.organisms.actionModal.config.failure.updateMetavaultConfiguration'
    ),
    [TransactionType.Stake]: t(
      'components.organisms.actionModal.config.failure.stake'
    ),
    [TransactionType.Unstake]: t(
      'components.organisms.actionModal.config.failure.unstake'
    ),
    [TransactionType.ClaimToken]: t(
      'components.organisms.actionModal.config.failure.claimToken'
    ),
    [TransactionType.ClaimAndStakeToken]: t(
      'components.organisms.actionModal.config.failure.claimAndStakeToken'
    ),
    [TransactionType.IncentivizeToken]: t(
      'components.organisms.actionModal.config.failure.incentivizeToken'
    ),
    [TransactionType.ReincentivizeToken]: t(
      'components.organisms.actionModal.config.failure.reincentivizeToken'
    ),
    [TransactionType.GovernanceVote]: t(
      'components.organisms.actionModal.config.failure.governanceVote'
    ),
    [TransactionType.FutureApy]: t(
      'components.organisms.actionModal.config.failure.futureApy'
    ),
    [TransactionType.ClaimSvts]: t(
      'components.organisms.actionModal.config.failure.claimSvts'
    ),
  },
  [TransactionStatus.Processing]: {
    [TransactionType.Approve]: t(
      'components.organisms.actionModal.config.processing.approve'
    ),
    [TransactionType.MultiTokenApproval]: t(
      'components.organisms.actionModal.config.processing.multiTokenApproval'
    ),
    [TransactionType.Withdraw]: t(
      'components.organisms.actionModal.config.processing.withdraw'
    ),
    [TransactionType.Deposit]: t(
      'components.organisms.actionModal.config.processing.deposit'
    ),
    [TransactionType.ClaimWithdraw]: t(
      'components.organisms.actionModal.config.processing.claimWithdraw'
    ),
    [TransactionType.SwapAndDeposit]: t(
      'components.organisms.actionModal.config.processing.swapAndDeposit'
    ),
    [TransactionType.CreateVault]: t(
      'components.organisms.actionModal.config.processing.createVault'
    ),
    [TransactionType.CreateMetavault]: t(
      'components.organisms.actionModal.config.processing.createMetavault'
    ),
    [TransactionType.UpdateMetavaultAllocations]: t(
      'components.organisms.actionModal.config.processing.updateMetavaultAllocations'
    ),
    [TransactionType.ManageMetavaultVaults]: t(
      'components.organisms.actionModal.config.processing.updateMetavaultConfiguration'
    ),
    [TransactionType.Stake]: t(
      'components.organisms.actionModal.config.processing.stake'
    ),
    [TransactionType.Unstake]: t(
      'components.organisms.actionModal.config.processing.unstake'
    ),
    [TransactionType.ClaimToken]: t(
      'components.organisms.actionModal.config.processing.claimToken'
    ),
    [TransactionType.ClaimAndStakeToken]: t(
      'components.organisms.actionModal.config.processing.claimAndStakeToken'
    ),
    [TransactionType.IncentivizeToken]: t(
      'components.organisms.actionModal.config.processing.incentivizeToken'
    ),
    [TransactionType.ReincentivizeToken]: t(
      'components.organisms.actionModal.config.processing.reincentivizeToken'
    ),
    [TransactionType.GovernanceVote]: t(
      'components.organisms.actionModal.config.processing.governanceVote'
    ),
    [TransactionType.ClaimSvts]: t(
      'components.organisms.actionModal.config.processing.claimSvts'
    ),
  },
}
