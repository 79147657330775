import {
  SmartVaultRowAction,
  SmartVaultRowActionTypes,
  SmartVaultRowState,
} from '@/store/smartVaultRow/smartVaultRow.types'

const smartVaultRowReducer = (
  state: SmartVaultRowState,
  action: SmartVaultRowAction
): SmartVaultRowState => {
  switch (action.type) {
    case SmartVaultRowActionTypes.SET_VAULT_ROW_ACTION:
      return { ...state, vaultRowAction: action.payload }
    default:
      return state
  }
}

export default smartVaultRowReducer
