const DeBank = () => {
  return (
    <svg
      width='15'
      height='17'
      viewBox='0 0 15 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.8'
        d='M14.5354 11.7059C14.5354 14.3515 12.3532 16.5 9.66548 16.5H0.464844V13.2983H9.66548C10.5502 13.2983 11.2663 12.5821 11.2663 11.6975C11.2663 10.8128 10.5502 10.0966 9.66548 10.0966H6.42166V6.90337H9.66548C10.5502 6.90337 11.2663 6.1872 11.2663 5.30253C11.2663 4.41785 10.5502 3.70169 9.66548 3.70169H0.464844V0.5H9.66548C12.3532 0.508425 14.5354 2.65692 14.5354 5.30253C14.5354 6.49052 14.0973 7.62796 13.2969 8.50421C14.0973 9.38046 14.5354 10.5263 14.5354 11.7059Z'
        fill='currentColor'
      />
      <path
        opacity='0.12'
        d='M0.464844 13.2983H8.36795C6.69128 15.2362 4.0204 16.5 1.0125 16.5C0.82714 16.5 0.650204 16.4916 0.473269 16.4831C0.464844 16.4916 0.464844 13.2983 0.464844 13.2983ZM10.0193 10.0966H6.9609V6.90337H10.0193C10.2637 7.95656 10.2637 9.05187 10.0193 10.0966ZM8.36795 3.70169H0.464844V0.516851C0.641779 0.508425 0.82714 0.5 1.00408 0.5C4.01197 0.508425 6.68285 1.76382 8.36795 3.70169Z'
        fill='currentColor'
      />
      <path
        d='M0.464844 0.508301C4.9472 0.508301 8.57859 4.08913 8.57859 8.50409C8.57859 12.919 4.9472 16.4999 0.464844 16.4999V13.2982C3.15257 13.2982 5.33478 11.1497 5.33478 8.50409C5.33478 5.85849 3.15257 3.70999 0.464844 3.70999V0.508301Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DeBank
