import { CreatorFeeVaultSorting, SortOrder } from '@solidant/spool-v2-fe-lib'
import { memo, useMemo, useReducer } from 'react'
import { useSearchParams } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

import {
  initialState,
  RewardsTableContext,
} from '@/store/rewardsTable/rewardsTable.context'
import rewardsTableReducer from '@/store/rewardsTable/rewardsTable.reducer'
import { RewardsTableActionTypes } from '@/store/rewardsTable/rewardsTable.types'

import { Routes } from '@/constants/routes'

import { RCNode } from '@/types/index'
import { RewardsTableSort } from '@/types/rewards'

export const RewardsTableProvider = memo(({ children }: RCNode) => {
  const { chain } = useConnectionState()

  const searchParams = useSearchParams()
  const { setPathWithHistory } = useUniversalNavigation()

  const [rewardsTable, dispatch] = useReducer(rewardsTableReducer, {
    ...initialState,
    page: parseInt(searchParams[0].get('page')) || initialState.page,
    limit: parseInt(searchParams[0].get('limit')) || initialState.limit,
    owner: searchParams[0].get('owner') || initialState.owner,
    vaultAddresses: searchParams[0].get('vaultAddresses'),
    rewardsTableSort: {
      key:
        (searchParams[0].get('sortKey') as CreatorFeeVaultSorting) ||
        initialState.rewardsTableSort?.key,
      direction:
        (searchParams[0].get('sortOrder') as SortOrder) ||
        initialState.rewardsTableSort?.direction,
    },
  })

  const updateStateAndUrl = (
    actionType: RewardsTableActionTypes,
    key: string,
    value: any
  ) => {
    dispatch({ type: actionType, payload: value })
    value
      ? searchParams[0].set(key, value.toString())
      : searchParams[0].delete(key)

    if (searchParams[0].size > 0) {
      setPathWithHistory(
        `${Routes(chain).smartVaults.earnings.url}?${searchParams[0]}`
      )
    } else {
      setPathWithHistory(Routes(chain).smartVaults.earnings.url)
    }
  }

  const setPage = (page: number) => {
    updateStateAndUrl(RewardsTableActionTypes.SET_PAGE, 'page', page)
  }

  const setLimit = (limit: number) => {
    updateStateAndUrl(RewardsTableActionTypes.SET_LIMIT, 'limit', limit)
  }

  const setOwner = (owner: string) => {
    updateStateAndUrl(RewardsTableActionTypes.SET_OWNER, 'owner', owner)
  }

  const handleRewardsTableSort = (
    key: CreatorFeeVaultSorting,
    sortType: RewardsTableSort
  ) => {
    if (!sortType?.key || sortType?.key !== key) {
      dispatch({
        type: RewardsTableActionTypes.SET_REWARDS_TABLE_SORT,
        payload: key,
      })
      searchParams[0].set('sortKey', key)
    }

    if (!sortType?.direction || sortType?.key === key) {
      dispatch({
        type: RewardsTableActionTypes.SET_REWARDS_TABLE_SORT_DIRECTION,
        payload:
          sortType.direction === SortOrder.DESC
            ? SortOrder.ASC
            : SortOrder.DESC,
      })
      searchParams[0].set(
        'sortOrder',
        sortType.direction === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
      )
    }

    if (searchParams[0].size > 0) {
      setPathWithHistory(
        `${Routes(chain).smartVaults.earnings.url}?${searchParams[0]}`
      )
    } else {
      setPathWithHistory(Routes(chain).smartVaults.earnings.url)
    }
  }

  const contextValue = useMemo(() => {
    return {
      ...rewardsTable,
      setPage,
      setLimit,
      setOwner,
      handleRewardsTableSort,
    }
  }, [
    rewardsTable.page,
    rewardsTable.limit,
    rewardsTable.owner,
    rewardsTable.vaultAddresses,
    rewardsTable.rewardsTableSort,
  ])

  return (
    <RewardsTableContext.Provider value={contextValue}>
      {children}
    </RewardsTableContext.Provider>
  )
})
