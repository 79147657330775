import { Box, Typography } from '@mui/material'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import getLocale from '@/locales/getLocale'

import DashboardPieChart from '@/components/organisms/Charts/DashboardPieChart'
import AllocationTable from '@/components/organisms/CreateMetavault/Allocation/AllocationTable'

const Allocation = () => {
  const t = getLocale()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { selectedVaults, selectedVaultsAllocations } =
    useCreateMetavaultState()

  const allocationData = selectedVaults.reduce((acc, vault, index) => {
    let name = vault.name
    if (acc[name] !== undefined) {
      let count = 2
      while (acc[`${name} (${count})`] !== undefined) {
        count++
      }
      name = `${name} (${count})`
    }
    acc[name] = selectedVaultsAllocations[index]
    return acc
  }, {} as Record<string, number>)

  const sum = selectedVaultsAllocations.reduce(
    (acc, allocation) => acc + allocation,
    0
  )

  allocationData['unallocated'] = 100 - sum

  return (
    <Box display='flex' flexDirection='column' gap={6} pb={12}>
      <Typography variant='subtitle1'>
        {t('components.organisms.createMetavault.allocation.title')}
      </Typography>
      <Box
        display='flex'
        alignItems={belowPhabletBreakpoint ? 'stretch' : 'flex-start'}
        flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          px={3}
          py={5}
          border='1px solid rgba(0, 0, 0, 0.12)'
          order={belowPhabletBreakpoint ? 2 : 0}
          sx={{
            borderTopLeftRadius: belowPhabletBreakpoint ? 0 : 2,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: belowPhabletBreakpoint ? 2 : 0,
            marginTop: belowPhabletBreakpoint && '-1px',
          }}
        >
          <DashboardPieChart
            assets={allocationData}
            format='strategies'
            percentage
          />
        </Box>
        <AllocationTable />
      </Box>
    </Box>
  )
}

export default Allocation
