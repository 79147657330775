import { ChevronRight } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useModalState from '@/hooks/context/useModalState'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import useHasHPFVaultDeployerRole from '@/hooks/createVault/useHasHPFVaultDeployerRole'
import useSteps from '@/hooks/createVault/useSteps'

import CustomButton from '@/components/atoms/CustomButton'

import { STEP_SEQUENCE } from '@/store/createVault/createVault.state'
import { StepData } from '@/store/createVault/createVault.types'
import { Modals } from '@/store/modal/modals.types'

import {
  HIGH_PERFORMANCE_FEE_OPTIONS,
  MANAGEMENT_FEE_OPTIONS,
  PERFORMANCE_FEE_OPTIONS,
} from '@/constants/createVault'
import { gaIds } from '@/constants/googleAnalyticIDs'

const NextNavigation: React.FC<{
  step: StepData
  isAdvanced: boolean
  handleCreateVault: () => void
}> = ({ step, isAdvanced, handleCreateVault }) => {
  const { chain } = useConnectionState()

  const {
    currentStep,
    selectedStrategies,
    selectedGuards,
    selectedActions,
    selectedManagementFee,
    selectedPerformanceFee,
    setCurrentStep,
  } = useCreateVaultState()

  const steps = useSteps()

  const { isUnavailableProvider } = useCreateVaultState()

  const { canDeployHPFVaults } = useHasHPFVaultDeployerRole()

  const { toggleMenu } = useTopMenuState()

  const { account, connector, chainId } = useWeb3React()

  const { openModal } = useModalState()

  let nextStep = step.nextBtn.toStep
  const label = step.nextBtn.label
  const lastStep = steps.length

  const isDisabled =
    (!step.completed &&
      (currentStep !== STEP_SEQUENCE.GUARDS ||
        Object.values(selectedGuards).filter((guard) => guard.checked).length >
          0) &&
      (currentStep !== STEP_SEQUENCE.ACTIONS ||
        Object.values(selectedActions).filter((action) => action.checked)
          .length > 0)) ||
    (currentStep === lastStep &&
      (!account ||
        selectedManagementFee < MANAGEMENT_FEE_OPTIONS.min ||
        selectedManagementFee > MANAGEMENT_FEE_OPTIONS.max ||
        selectedPerformanceFee <
          (canDeployHPFVaults
            ? HIGH_PERFORMANCE_FEE_OPTIONS.min
            : PERFORMANCE_FEE_OPTIONS.min) ||
        selectedPerformanceFee >
          (canDeployHPFVaults
            ? HIGH_PERFORMANCE_FEE_OPTIONS.max
            : PERFORMANCE_FEE_OPTIONS.max))) ||
    (currentStep === STEP_SEQUENCE.ALLOCATIONS && isUnavailableProvider)

  // if not in advanced mode, skip to step 6 after step 3
  if (!isAdvanced && currentStep === STEP_SEQUENCE.ALLOCATIONS) {
    nextStep = STEP_SEQUENCE.LAUNCH
  }

  // next step navigation actions
  const handleNextNavigation = () => {
    if (currentStep === 2 && chainId !== chain) {
      connector.activate(chain)
      return
    }

    if (currentStep === lastStep) {
      if (!account) {
        toggleMenu(true)
        return
      }
      if (selectedStrategies.length > 1) {
        handleCreateVault()
        return
      }
      openModal(Modals.ONE_STRATEGY_MODAL)
      return
    } else if (currentStep === lastStep - 1) {
      setCurrentStep(lastStep)
    } else {
      setCurrentStep(nextStep)
    }
    window.scrollTo(0, 0)
  }

  return (
    <Box id={currentStep === lastStep ? gaIds.createVault : ''}>
      <CustomButton
        variant='contained'
        sx={{
          my: (theme) => theme.spacing(2),
          gap: (theme) => theme.spacing(1),
        }}
        onClick={handleNextNavigation}
        disabled={currentStep !== lastStep && isDisabled}
      >
        <ChevronRight />
        {label}
      </CustomButton>
    </Box>
  )
}

export default NextNavigation
