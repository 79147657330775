import { Box, Card, CardContent, Grid, Typography } from '@mui/material'

import SingleAssetSwapInfoItem from '@/components/organisms/SmartVaults/VaultActions/Deposit/SingleAssetSwapInfoItem'
interface SingleAssetSwapInfoProps {
  isConversion: boolean
}

const SingleAssetSwapInfo: React.FC<SingleAssetSwapInfoProps> = ({
  isConversion,
}) => {
  return (
    <Box>
      <Typography fontSize='16px' mb='0.2rem' color='#21232299'>
        Your Deposits
      </Typography>
      <Card
        variant='outlined'
        className='section-item-card'
        sx={{
          width: '100%',
        }}
      >
        <CardContent className='section-item-card-content'>
          <Grid
            container
            sx={{
              width: '100%',
            }}
            columns={2}
          >
            <SingleAssetSwapInfoItem isConversion={isConversion} />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default SingleAssetSwapInfo
