import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const LaunchDescription = () => {
  const t = useLocale()

  return (
    <>
      <Typography variant='body1'>
        {t('components.atoms.pageDescriptions.launchDescription.instruction')}
      </Typography>
    </>
  )
}

export default LaunchDescription
