import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'

import PageTransition from '@/components/atoms/PageTransition'
import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import TextTagRenderer from '@/components/atoms/TextTagRenderer'
import UnconnectedWalletDisplay from '@/components/atoms/UnconnectedWalletDisplay'
import StakingOverview from '@/components/organisms/Staking/Overview'

import { Routes } from '@/constants/routes'

import { StakingPage } from '@/types/staking'

const Staking = () => {
  const t = useLocale()
  const { chain } = useConnectionState()

  const { account } = useWeb3React()

  const paths: { [path: string]: StakingPage } = {
    [Routes(chain).staking.root.url]: 'staking',
    [Routes(chain).staking.stake.url]: 'stake',
    [Routes(chain).staking.unstake.url]: 'unstake',
    [Routes(chain).staking.claim.url]: 'claim',
    [Routes(chain).staking.claimStake.url]: 'claimStake',
  }
  const [page, setPage] = useState<StakingPage>(paths[location.pathname])

  return (
    <PageTransition>
      <Stack gap={(theme) => theme.spacing(2)}>
        <SpoolBreadcrumbs mode='injected' breadcrumb={['DAO', 'Staking']}>
          <Accordion
            sx={{
              bgcolor: 'inherit',
              boxShadow: 'none',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display='flex' alignItems='center' gap={1}>
                <InfoIcon
                  sx={{
                    width: 20,
                    height: 20,
                    color: 'icon.primary',
                  }}
                />
                <Typography variant='body1' alignSelf='center'>
                  {t('pages.staking.description1')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ my: 1 }}>
              <Box sx={{ paddingBottom: (theme) => theme.spacing(3) }}>
                <Typography
                  variant='body1'
                  sx={{ whiteSpace: 'initial' }}
                  component={Box}
                >
                  <List
                    sx={{
                      listStyleType: 'disc',
                      paddingLeft: (theme) => theme.spacing(3),
                    }}
                  >
                    <ListItem sx={{ display: 'list-item', padding: 0 }}>
                      <TextTagRenderer
                        inputString={t('pages.staking.description2')}
                      />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', padding: 0 }}>
                      <TextTagRenderer
                        inputString={t('pages.staking.description3')}
                      />
                    </ListItem>
                  </List>
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </SpoolBreadcrumbs>
        {!account ? (
          <UnconnectedWalletDisplay sectionColumnTitle='Spool Tokens' />
        ) : (
          <>
            <StakingOverview page={page} setPage={setPage} />
          </>
        )}
        {/* <StakingCommunity /> */}
      </Stack>
    </PageTransition>
  )
}

export default Staking
