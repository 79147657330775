import { useContext } from 'react'

import { TopMenuContext } from '@/store/topMenu/topMenu.context'

const useTopMenuState = () => {
  const topMenuContext = useContext(TopMenuContext)
  if (!Object.keys(TopMenuContext).length) {
    throw new Error('TopMenuContext must be used within its provider')
  }
  return topMenuContext
}

export default useTopMenuState
