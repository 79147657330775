import { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  noDecimal?: boolean
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, noDecimal, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator=' '
        valueIsNumericString
        decimalScale={noDecimal ? 0 : 2}
        fixedDecimalScale
        allowNegative={false}
        decimalSeparator='.'
        min={0}
      />
    )
  }
)

export default NumericFormatCustom
