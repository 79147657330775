import { useContext } from 'react'

import { AppContext } from '@/store/app/app.context'

const useAppState = () => {
  const appContext = useContext(AppContext)
  if (!Object.keys(AppContext).length) {
    throw new Error('AppContext must be used within its provider')
  }
  return appContext
}

export default useAppState
