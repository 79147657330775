import { List, ListItem, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const VestingParagraph = () => {
  const t = useLocale()
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ pt: 0, pb: 2, pl: 0 }}>
        <Typography variant='body1'>
          {t('components.organisms.vesting.vestingParagraph.textThankYou')}
        </Typography>
      </ListItem>
      <ListItem sx={{ pb: 2, pl: 0 }}>
        <Typography variant='body1'>
          {t('components.organisms.vesting.vestingParagraph.textTokens')}
        </Typography>
      </ListItem>
      <ListItem sx={{ pb: 0, pl: 0 }}>
        <Typography variant='body1'>
          {t('components.organisms.vesting.vestingParagraph.textVoSpool')}
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0, pl: 1.5 }}>
        <Typography variant='body1'>
          {t('components.organisms.vesting.vestingParagraph.textClaiming')}
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0, pl: 1.5 }}>
        <Typography variant='body1'>
          {t('components.organisms.vesting.vestingParagraph.textReduction')}
        </Typography>
      </ListItem>
    </List>
  )
}

export default VestingParagraph
