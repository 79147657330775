import { SortOrder } from '@solidant/spool-v2-fe-lib'

import {
  WithdrawAction,
  WithdrawActionTypes,
  WithdrawState,
} from '@/store/withdraw/withdraw.types'

const withdrawReducer = (
  state: WithdrawState,
  action: WithdrawAction
): WithdrawState => {
  switch (action.type) {
    case WithdrawActionTypes.CHANGE_WITHDRAWAL_SORT_DIRECTION:
      return {
        ...state,
        withdrawReceiptSort: {
          ...state.withdrawReceiptSort,
          direction:
            state.withdrawReceiptSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case WithdrawActionTypes.SET_WITHDRAWAL_RECEIPT_SORT:
      return {
        ...state,
        withdrawReceiptSort: action.payload,
      }
    case WithdrawActionTypes.SET_WITHDRAWAL_AMOUNT:
      return {
        ...state,
        withdrawalAmount: action.payload,
      }
  }
}

export default withdrawReducer
