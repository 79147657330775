import { Box, Typography } from '@mui/material'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

interface DashboardMetricsFieldProps {
  title: string
  value: string
  isHighlighted?: boolean
}

const DashboardMetricsField: React.FC<DashboardMetricsFieldProps> = ({
  title,
  value,
  isHighlighted = false,
}) => {
  const { belowTabletBreakpoint } = useBreakpointDetection()

  return (
    <Box
      display='flex'
      alignItems='center'
      width='100%'
      flexDirection={belowTabletBreakpoint ? 'row' : 'column'}
      textAlign='right'
    >
      <Box
        display='flex'
        flexDirection={belowTabletBreakpoint ? 'row' : 'column'}
        justifyContent={belowTabletBreakpoint ? 'space-between' : 'start'}
        width={belowTabletBreakpoint ? '100%' : 'auto'}
      >
        <Typography
          fontSize={belowTabletBreakpoint ? '16px' : '14px'}
          color='gray'
        >
          {title}
        </Typography>
        <Typography
          color={`${isHighlighted ? '#2E7D32' : ''}`}
          fontSize={belowTabletBreakpoint ? '16px' : '20px'}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

export default DashboardMetricsField
