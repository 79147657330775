import { Box, Stack, Typography } from '@mui/material'
import { StrategyListType } from '@solidant/spool-v2-fe-lib'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useStrategies from '@/hooks/strategies/useStrategies'

import PageTransition from '@/components/atoms/PageTransition'
import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import DataLoading from '@/components/molecules/DataLoading'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import StrategiesTable from '@/components/organisms/Strategies/StrategiesTable'

import { StrategiesProvider } from '@/store/strategies/strategies.provider'

import StrategiesFilters from '@/pages/Strategies/StrategiesFilter'

import { ASSET_GROUPS } from '@/constants/createVault'

const Strategies = () => {
  const t = useLocale()

  const { strategies, error, isLoading } = useStrategies()

  const { chain } = useConnectionState()

  const [strategiesList, setStrategiesList] =
    useState<StrategyListType[]>(strategies)

  return (
    <PageTransition>
      <Stack gap={(theme) => theme.spacing(2)}>
        <Box display='flex' justifyContent='space-between'>
          <SpoolBreadcrumbs>
            <Typography variant='body1' pt={1}>
              {t('pages.strategies.description')}
            </Typography>
          </SpoolBreadcrumbs>
        </Box>
        <StrategiesProvider>
          <Box sx={{ mb: 10 }}>
            <Section>
              <SectionColumn
                sx={{
                  gap: 0,
                  py: 0,
                  borderRadius: (theme) => theme.spacing(1),
                }}
              >
                <Stack>
                  {/* TODO: Filters*/}
                  {error ? (
                    <ErrorWithText errorText='Something went wrong when loading Strategies' />
                  ) : !strategies && isLoading ? (
                    <DataLoading loadingText='Loading Strategies' />
                  ) : (
                    <>
                      <StrategiesFilters assetGroups={ASSET_GROUPS[chain]} />
                      <Typography variant='overline' mt='0.5rem'>
                        {strategiesList?.length ?? '0'} strategies
                      </Typography>
                      <StrategiesTable
                        strategiesList={strategiesList}
                        setStrategiesList={setStrategiesList}
                      />
                    </>
                  )}
                </Stack>
              </SectionColumn>
            </Section>
          </Box>
        </StrategiesProvider>
      </Stack>
    </PageTransition>
  )
}

export default Strategies
