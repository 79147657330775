import { Box, Button } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useStakeOrUnstake from '@/hooks/staking/transactions/useStakeOrUnstake'
import useStakedSYLay from '@/hooks/staking/useStakedSpool'
import useUserBalance from '@/hooks/web3/useUserBalance'

import StakingTransactionInputContainer from '@/components/organisms/Staking/StakingActions/StakingTransactionInputContainer'

import { API_ENDPOINTS, IS_TESTNET } from '@/config/sdk'
import { Routes } from '@/constants/routes'
import { Token } from '@/constants/smartVault'

import { StakingPage } from '@/types/staking'

interface StakingSummaryProps {
  page: StakingPage
  setPage: (page: StakingPage) => void
}

const StakingActions: React.FC<StakingSummaryProps> = ({ page, setPage }) => {
  const t = useLocale()

  const { account } = useWeb3React()
  const { chain } = useConnectionState()

  // doing this due to being unable to dynamically get if is tenderly or mainnet on ETHEREUM_CONFIG
  const YLAY_TOKEN_ADDRESS = IS_TESTNET
    ? '0xb370f95a84d0a8d1150c42c44894c0ba457f18b6'
    : '0xaee5913ffd19dbca4fd1ef6f3925ed0414407d37'

  const { balance } = useUserBalance(YLAY_TOKEN_ADDRESS)
  const { stakedYLay: stakedSpool } = useStakedSYLay(account)

  const { setPathWithHistory } = useUniversalNavigation()
  const stakeOrUnstake = useStakeOrUnstake(page)

  const openStake = () => {
    setPathWithHistory(Routes(chain).staking.stake.url)
    setPage('stake')
  }

  const openUnstake = () => {
    setPathWithHistory(Routes(chain).staking.unstake.url)
    setPage('unstake')
  }

  const cancel = () => {
    setPathWithHistory(Routes(chain).staking.root.url)
    setPage('staking')
  }

  return (
    <>
      {page === 'stake' || page === 'unstake' ? (
        <StakingTransactionInputContainer
          inputLabel={`${t(
            'components.organisms.staking.overview.input.label'
          )} $${Token.YLAY}`}
          tokenId={YLAY_TOKEN_ADDRESS}
          page={page}
          spenderContractAddress={API_ENDPOINTS[chain].YELAY_STAKING}
          confirmLabel={page === 'stake' ? 'Stake' : 'Unstake'}
          onConfirm={(amount) => stakeOrUnstake(amount)}
          onCancel={cancel}
        />
      ) : (
        <Box display='flex' gap={(theme) => theme.spacing(2)}>
          <Button
            variant='contained'
            size='large'
            onClick={openStake}
            disabled={
              page === 'claim' ||
              page === 'claimStake' ||
              !account ||
              balance.eq(0)
            }
          >
            {t('components.organisms.staking.overview.buttons.staking.label')}
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={openUnstake}
            disabled={
              page === 'claim' ||
              page === 'claimStake' ||
              !account ||
              Number(stakedSpool) === 0
            }
          >
            {t('components.organisms.staking.overview.buttons.unstaking.label')}
          </Button>
        </Box>
      )}
    </>
  )
}

export default StakingActions
