import { Box, Breadcrumbs, Typography } from '@mui/material'
import { ReactNode } from 'react'

import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

type CommonProps = {
  page?: string
  children?: ReactNode
}

type BreadcrumbModes = 'pathname' | 'injected' | 'combined'

type BreadcrumbProps = {
  [K in BreadcrumbModes]: K extends 'injected' | 'combined'
    ? { mode: K; breadcrumb: string[] }
    : { mode?: K; breadcrumb?: never }
}

type SpoolBreadcrumbsProps = CommonProps & BreadcrumbProps[BreadcrumbModes]

// The breadcrumbs component for has three modes:
// 1. pathname: This is the default mode. It uses the pathname from the router to generate the breadcrumbs.
// 2. injected: This mode allows you to inject the breadcrumbs as a prop.
// 3. combined: This mode combines the injected breadcrumbs with the breadcrumbs from the router.

const SpoolBreadcrumbs: React.FC<SpoolBreadcrumbsProps> = ({
  mode = 'pathname',
  breadcrumb,
  children,
  ...rest
}) => {
  const { breadcrumbs } = useUniversalNavigation()
  let breadcrumbNode = null

  const breadcrumbData =
    mode === 'injected'
      ? (breadcrumb as string[])
      : mode === 'combined'
      ? [breadcrumbs[0], ...(breadcrumb as string[])]
      : breadcrumbs

  breadcrumbNode = breadcrumbData.map((b: string, i: number) => (
    <Typography key={i} variant='overline'>
      {b}
    </Typography>
  ))

  return (
    <Box width='100%'>
      <Breadcrumbs
        {...rest}
        aria-label='breadcrumb'
        separator='•'
        sx={{ width: '100%' }}
      >
        {breadcrumbNode}
      </Breadcrumbs>
      {children}
    </Box>
  )
}

export default SpoolBreadcrumbs
