import { Box, Stack, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useWithdrawState from '@/hooks/context/useWithdrawState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useWithdrawalDetails from '@/hooks/smartVaults/useWithdrawalDetails'

import TransactionInput from '@/components/molecules/TransactionInput'

import { formatUSD } from '@/utils/formats'
import { preventOverflow } from '@/utils/web3'

const WithdrawAmount: React.FC = () => {
  const t = useLocale()

  const { withdrawalAmount, setWithdrawalAmount } = useWithdrawState()

  const { account } = useWeb3React()
  const { smartVaultData } = useVaultsPage()

  const { address } = smartVaultData

  const { withdrawalDetails } = useWithdrawalDetails(account, address)

  const handleWithdrawInput = (withdrawAmount: string) => {
    setWithdrawalAmount(preventOverflow(withdrawAmount, 18))
  }

  return (
    <Box display='flex' gap={2}>
      <Stack width='100%'>
        <Stack>
          <TransactionInput
            inputLabel={t(
              'components.organisms.smartVaults.collapsibleRow.withdraw.withdrawAmount.input.label'
            )}
            value={withdrawalAmount}
            setValue={handleWithdrawInput}
            maxFiatValue={
              withdrawalDetails ? withdrawalDetails?.userBalance : '0'
            }
            assetName='USDT'
            isFiat
            showDollarSign
            showInputInfoLabel
            maxButtonWidth='65px'
            showAlert
            disabled={
              withdrawalDetails && withdrawalDetails?.userBalance === '0.0'
            }
          />
          <Box display='flex' alignItems='center' mt='0.2rem'>
            <Typography color='#21232299' fontSize='12px'>
              Available: &nbsp;
            </Typography>
            <Typography fontSize='12px'>
              $
              {withdrawalDetails
                ? formatUSD(withdrawalDetails.userBalance)
                : formatUSD('0')}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

export default WithdrawAmount
