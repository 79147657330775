import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Stack, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import ModalBox from '@/components/organisms/Modals/ModalBox'

const MaintenanceModal: React.FC = () => {
  const t = useLocale()
  return (
    <ModalBox id='maintenanceModal' disabledClose={true}>
      <ModalBoxOuterContainer
        sx={{
          width: '700px',
        }}
      >
        <Box
          display='flex'
          gap={(theme) => theme.spacing(2)}
          paddingY={(theme) => theme.spacing(1)}
        >
          <RefreshIcon className='xl' sx={{ color: 'primary.main' }} />
          <Stack gap={1}>
            <Typography variant='alertTitle'>
              {t('components.organisms.maintenanceModal.title')}
            </Typography>
            <Typography variant='body2'>
              {t('components.organisms.maintenanceModal.subtitle')}
            </Typography>
          </Stack>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default MaintenanceModal
