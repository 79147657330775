import { Box, styled } from '@mui/material'

const Bottom = styled(Box)(({ theme }) => ({
  '.part-1': {
    width: '0',
    height: '0',
    borderLeft: '10.5px solid transparent',
    borderRight: '10.5px solid transparent',
    borderBottom: `10px solid ${theme.palette.primary.main}`,
  },
  '.part-2': {
    height: '31px',
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}))

export default Bottom
