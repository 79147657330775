import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useUserClaimableNfts from '@/hooks/user/useUserClaimableNfts'

import { CHAINS } from '@/config/web3/chains'
import { formatUSD } from '@/utils/formats'
import { withdrawNotificationText } from '@/utils/helpers/withdrawNotificationText'

const useShowClaimableNft = () => {
  const { userClaimablewNFTs } = useUserClaimableNfts()

  const { account } = useWeb3React()

  const { chain } = useConnectionState()

  const [withdrawNotification, setWithdrawNotification] = useState<{
    showNotification: boolean
    multiNft: boolean
    text: string
  }>({ showNotification: false, multiNft: false, text: '' })

  useEffect(() => {
    if (!userClaimablewNFTs?.length || !account) {
      setWithdrawNotification({
        ...withdrawNotification,
        showNotification: false,
      })
      return
    }

    const localStorageKey =
      account + `-${CHAINS[chain].name}-` + '-latestClaimableWnft'

    const latestNft = userClaimablewNFTs.find(({ isLatest }) => isLatest)

    const localStoredNft = localStorage.getItem(localStorageKey)

    const showNotification = () => {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          wnftId: latestNft.wnftId,
          hasClosed: false,
        })
      )

      setWithdrawNotification({
        showNotification: true,
        multiNft: userClaimablewNFTs.length > 1,
        text: withdrawNotificationText(
          userClaimablewNFTs.length > 1,
          formatUSD(latestNft.withdrawAmount),
          latestNft.vaultName
        ),
      })
    }

    if (localStoredNft) {
      const localStoredNftObj = JSON.parse(localStoredNft)

      if (
        localStoredNftObj.wnftId === latestNft.wnftId &&
        localStoredNftObj.hasClosed
      ) {
        return
      }

      if (localStoredNftObj.wnftId !== latestNft.wnftId) {
        showNotification()
        return
      }

      if (!localStoredNftObj.hasClosed) {
        setWithdrawNotification({
          showNotification: true,
          multiNft: userClaimablewNFTs.length > 1,
          text: withdrawNotificationText(
            userClaimablewNFTs.length > 1,
            formatUSD(latestNft.withdrawAmount),
            latestNft.vaultName
          ),
        })
        return
      }
    }

    if (!localStoredNft && userClaimablewNFTs.length) {
      showNotification()
    }
  }, [userClaimablewNFTs, account, chain])

  return { withdrawNotification, setWithdrawNotification }
}

export default useShowClaimableNft
