import { Stack } from '@mui/material'
import { Suspense } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import PageTransition from '@/components/atoms/PageTransition'
import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'

import { InvestorDashboardProvider } from '@/store/investorDashboard/investorDashboard.provider'
import { TransactionHistoryProvider } from '@/store/transactionHistory/transactionHistory.provider'

import History from '@/pages/Dashboard/History'
import InvestorDashboard from '@/pages/Dashboard/InvestorDashboard'

import { Routes } from '@/constants/routes'

const Dashboard = () => {
  const { chain } = useConnectionState()

  return (
    <PageTransition>
      <Stack sx={{ gap: (theme) => theme.spacing(2) }}>
        {location.pathname === Routes(chain).dashboard.investor.url && (
          <Suspense>
            <InvestorDashboardProvider>
              <InvestorDashboard />
            </InvestorDashboardProvider>
          </Suspense>
        )}
        {location.pathname === Routes(chain).dashboard.history.url && (
          <>
            <SpoolBreadcrumbs
              breadcrumb={['Transaction History']}
              mode='injected'
            />
            <TransactionHistoryProvider>
              <History />
            </TransactionHistoryProvider>
          </>
        )}
      </Stack>
    </PageTransition>
  )
}

export default Dashboard
