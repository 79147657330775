import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Button, Stack, Typography } from '@mui/material'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import ValidityTag from '@/components/atoms/ValidityTag'
import VaultFeature from '@/components/molecules/VaultFeature'
import VaultFeatureGroup from '@/components/molecules/VaultFeatureGroup'
import VaultFeaturesChecklist from '@/components/molecules/VaultFeaturesChecklist'
import GuardsModalsContainer from '@/components/organisms/CreateVault/Guards/Modals/GuardsModalsContainer'

import { Modals } from '@/store/modal/modals.types'

import { Guard } from '@/constants/createVault'
import { GUARDS_ID } from '@/constants/guards'

interface GuardsCardProps {
  title: string
  subtitle: string | string[]
  avatar: React.ReactNode
  guards: Guard[]
  filter: string
  focused: string
  setFocused: (focused: string) => void
}

const GuardsCard: React.FC<GuardsCardProps> = ({
  title,
  subtitle,
  avatar,
  guards,
  filter,
  focused,
  setFocused,
}) => {
  const { openModal } = useModalState()

  const { selectedGuards, setGuardsChecked } = useCreateVaultState()

  const t = useLocale()

  const filteredGuards =
    filter === ''
      ? guards
      : guards.filter(
          (guard) =>
            guard.title.toLowerCase().includes(filter.toLowerCase()) ||
            guard.items.some((guardItem) =>
              guardItem.title.toLowerCase().includes(filter.toLowerCase())
            )
        )

  const handleChange = (
    id: string,
    checked: boolean,
    title: string,
    type: string
  ) => {
    setFocused(id)
    setGuardsChecked(id, checked, title, type)
  }

  const configureButton = (id: string) => {
    if (id === GUARDS_ID.SVT_NONTRANSFERABLE) {
      return null
    }

    return (
      <Box textAlign='end' padding={(theme) => theme.spacing(0, 2, 2, 2)}>
        <Button
          startIcon={<SettingsIcon />}
          variant='contained'
          size='small'
          onClick={() =>
            // TODO - 4 (unused component): Not sure what this is?
            openModal(id as Modals)
          }
        >
          {t(
            'components.organisms.createVault.guards.guardsCard.configureButton.label'
          )}
        </Button>
      </Box>
    )
  }

  const configuredInformation = (id: string) => (
    <Stack paddingX={2} paddingBottom={1}>
      {selectedGuards[id].conditions &&
        selectedGuards[id].conditions.map((condition, index) => (
          <Typography variant='caption' key={index}>
            {condition}
          </Typography>
        ))}
    </Stack>
  )

  const editButton = (id: string) => (
    <Box>
      {selectedGuards[id]?.configured && configuredInformation(id)}
      <Box
        display='flex'
        justifyContent='space-between'
        padding={(theme) => theme.spacing(0, 2, 2, 2)}
      >
        <ValidityTag label='Configured' />
        <Button
          startIcon={<EditIcon />}
          variant='contained'
          size='small'
          onClick={() =>
            // TODO - 5 (unused component): Not sure what this is?
            openModal(id as Modals)
          }
        >
          {t(
            'components.organisms.createVault.guards.guardsCard.editButton.label'
          )}
        </Button>
      </Box>
    </Box>
  )

  const HIDDEN_CARDS = [
    'Whitelisted addresses only',
    'Withdrawal time-lock',
    'Asset-holding wallets',
    'Smart Vault Token',
  ]
  const HIDDEN_SECTIONS = ['svtWhitelist', 'nftBeneficiaries']

  return (
    <VaultFeaturesChecklist title={title} avatar={avatar} subtitle={subtitle}>
      {filteredGuards.map((guard) => {
        if (HIDDEN_CARDS.includes(guard.title)) {
          return
        }

        return (
          <VaultFeatureGroup key={guard.title} title={guard.title}>
            {guard.items.map(
              ({ id, title, modalTitle, breadcrumbs, modalId, type }) => {
                const configured =
                  (selectedGuards[id]?.assetAmount &&
                    selectedGuards[id]?.assetAmount.length) ||
                  (selectedGuards[id]?.whitelist &&
                    selectedGuards[id]?.whitelist.length)

                if (HIDDEN_SECTIONS.includes(id)) {
                  return
                }

                return (
                  <VaultFeature
                    key={id}
                    id={id}
                    title={title}
                    checked={!!selectedGuards[id]?.checked}
                    handleChecked={() =>
                      handleChange(
                        id,
                        !selectedGuards[id]?.checked,
                        title,
                        type
                      )
                    }
                    focus={focused === id}
                    setFocused={setFocused}
                  >
                    <>
                      <Box>
                        {configured ? editButton(id) : configureButton(id)}
                      </Box>
                      <GuardsModalsContainer
                        modalId={modalId}
                        guardItemId={id}
                        title={modalTitle ? modalTitle : title}
                        breadcrumbs={breadcrumbs}
                      />
                    </>
                  </VaultFeature>
                )
              }
            )}
          </VaultFeatureGroup>
        )
      })}
    </VaultFeaturesChecklist>
  )
}

export default GuardsCard
