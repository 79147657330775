import { Box } from '@mui/material'
import { useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import ValidNotification from '@/components/molecules/informational/ValidNotification'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import AssetsInput from '@/components/organisms/CreateVault/Guards/Modals/AssetsModal/AssetsInput'
import AssetsTable from '@/components/organisms/CreateVault/Guards/Modals/AssetsModal/AssetsTable'
import DataButtons from '@/components/organisms/CreateVault/Guards/Modals/DataButtons'
import DeleteModal from '@/components/organisms/CreateVault/Guards/Modals/DeleteModal'

import { Requirement } from '@/store/createVault/createVault.types'

import { Token } from '@/constants/smartVault'

interface AssetsModalProps {
  id: string
}

const AssetsModal: React.FC<AssetsModalProps> = ({ id }) => {
  const { selectedGuards, setGuardsAssetAmount } = useCreateVaultState()
  const [token, setToken] = useState<Token>()
  const [requirement, setRequirement] = useState<Requirement>(
    Requirement.MINIMUM
  )
  const [value, setValue] = useState<string>('')
  const [selected, setSelected] = useState<string[]>([])
  const [editMode, setEditMode] = useState(false)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)

  const t = useLocale()

  // const handleSave = () => {
  //   setGuardsAssetAmount(id, assetAmounts)
  //   closeModal(id)
  // }

  // const handleTest = () => {
  //   setGuardsAssetAmount(id, assetAmounts)
  //   setGuardsConfigured(id, assetAmounts.length > 0)
  // }

  const handleEdit = () => {
    const row = selectedGuards[id]?.assetAmount.find(
      (i) => i.token === selected[0]
    )
    if (!row)
      throw new Error(
        t(
          'components.organisms.createVault.guards.modals.assetsModal.assetsModal.handleEdit'
        )
      )
    setEditMode(true)
    setToken(row.token)
    setRequirement(row.requirement)
    setValue(row.value)
  }

  const handleDelete = () => {
    setGuardsAssetAmount(
      id,
      selectedGuards[id]?.assetAmount.filter(
        (asset) => !selected.includes(asset.token)
      )
    )
    setSelected([])
    setDeleteModalOpened(false)
  }

  const handleCancel = () => {
    setEditMode(false)
    setToken(undefined)
    setRequirement(Requirement.MINIMUM)
    setValue('')
  }

  const deleteModalLabel = () => {
    if (selected.length === 1)
      return `${t(
        'components.organisms.createVault.guards.modals.assetsModal.assetsModal.deleteModal.selected.label'
      )}${selected[0]}?`
    return t(
      'components.organisms.createVault.guards.modals.assetsModal.assetsModal.deleteModal.all.label'
    )
  }

  // const csvImportFormat = (row: string): AssetAmount => {
  //   const array = row.split(',').map((i) => i.trim())

  //   if (isNaN(Number(array[0])))
  //     throw new Error(
  //       t(
  //         'components.organisms.createVault.guards.modals.assetsModal.assetsModal.csvImport.column1'
  //       )
  //     )
  //   if (!(array[1] in Token))
  //     throw new Error(
  //       `${t(
  //         'components.organisms.createVault.guards.modals.assetsModal.assetsModal.csvImport.column2'
  //       )} ${Object.keys(Token)}`
  //     )
  //   if (!(array[2] in Requirement))
  //     throw new Error(
  //       `${t(
  //         'components.organisms.createVault.guards.modals.assetsModal.assetsModal.csvImport.column3'
  //       )} ${Object.keys(Requirement)}`
  //     )
  //   if (isNaN(Number(array[3])))
  //     throw new Error(
  //       t(
  //         'components.organisms.createVault.guards.modals.assetsModal.assetsModal.csvImport.column4'
  //       )
  //     )
  //   return {
  //     id: Number(array[0]),
  //     token: array[1] as Token,
  //     requirement: array[2] as Requirement,
  //     value: Number(array[3]),
  //   }
  // }

  return (
    <>
      <Section>
        <SectionColumn sx={{ gap: 0 }}>
          {selectedGuards[id]?.configured ? (
            <ValidNotification />
          ) : (
            <AssetsInput
              id={id}
              token={token}
              setToken={setToken}
              requirement={requirement}
              setRequirement={setRequirement}
              value={value}
              setValue={setValue}
              editMode={editMode}
              setEditMode={setEditMode}
              selected={selected}
              setSelected={setSelected}
            />
          )}
          {selectedGuards[id]?.assetAmount &&
            selectedGuards[id]?.assetAmount.length && (
              <AssetsTable
                id={id}
                selected={selected}
                setSelected={setSelected}
                editMode={editMode}
                editConfigurationMode={!selectedGuards[id]?.configured}
              />
            )}
          <Box display='flex' gap={1}>
            {selectedGuards[id]?.assetAmount &&
              selectedGuards[id]?.assetAmount.length && (
                <DataButtons
                  selected={selected}
                  editMode={editMode}
                  handleEdit={handleEdit}
                  openDeleteModal={() => setDeleteModalOpened(true)}
                  handleCancel={handleCancel}
                />
              )}
            {/* <ImportExportCsv<AssetAmount>
              csvImportFormat={csvImportFormat}
              list={assetAmounts}
              setList={setAssetAmounts}
            /> */}
          </Box>
        </SectionColumn>
      </Section>
      {/* <BottomButtons
        handleSave={handleSave}
        handleTest={handleTest}
        closeModal={() => closeModal(id)}
        configured={selectedGuards[id]?.configured}
        handleEditConfiguration={() => setGuardsConfigured(id, false)}
        disabled={!assetAmounts.length}
      /> */}
      <DeleteModal
        label={deleteModalLabel()}
        open={deleteModalOpened}
        handleClose={() => setDeleteModalOpened(false)}
        handleDelete={handleDelete}
      />
    </>
  )
}

export default AssetsModal
