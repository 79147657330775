import { Box, Checkbox, Typography } from '@mui/material'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { currency } from '@/constants/currency'
import { MAX_METAVAULTSELECTION } from '@/constants/metavaults'
import { formatUSD } from '@/utils/formats'

interface VaultSelectorTableRowMobileProps {
  row: CreateMetaVaultVaults
  border: boolean
  addingVaults?: boolean
}

const VaultSelectorTableRowMobile: React.FC<
  VaultSelectorTableRowMobileProps
> = ({ row, border, addingVaults }) => {
  const {
    selectedVaults,
    setSelectedVaults,
    newlyAddedVaults,
    setNewlyAddedVaults,
    setAsset,
  } = useCreateMetavaultState()
  const { chain } = useConnectionState()

  const { id, name, tvr, apy, assetGroup } = row

  const onRowClick = () => {
    const currentSelection = addingVaults ? newlyAddedVaults : selectedVaults
    const vaultExists = currentSelection.some(
      (selectedVault) => selectedVault.id === id
    )

    if (vaultExists) {
      if (addingVaults) {
        setNewlyAddedVaults(newlyAddedVaults.filter((vault) => vault.id !== id))
      } else {
        setSelectedVaults(selectedVaults.filter((vault) => vault.id !== id))
      }
    } else if (
      selectedVaults.length + newlyAddedVaults.length <
      MAX_METAVAULTSELECTION
    ) {
      if (addingVaults) {
        setNewlyAddedVaults([...newlyAddedVaults, row])
      } else {
        setAsset(assetGroup.assetGroupTokens[0].address)
        setSelectedVaults([...selectedVaults, row])
      }
    }
  }

  const isSelected = useMemo(() => {
    const currentSelection = addingVaults ? newlyAddedVaults : selectedVaults
    return currentSelection.some((selectedVault) => selectedVault.id === id)
  }, [selectedVaults, newlyAddedVaults, addingVaults, id])

  const isDisabled = useMemo(
    () =>
      !isSelected &&
      selectedVaults.length + newlyAddedVaults.length >= MAX_METAVAULTSELECTION,
    [isSelected, selectedVaults, newlyAddedVaults]
  )

  return (
    <Box
      onClick={onRowClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        cursor: 'pointer',
        opacity: isDisabled ? 0.5 : 1,
        px: 2,
        py: 1.5,
        borderTop: border ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        gap={2}
      >
        <Box display='flex' alignItems='center' gap={1}>
          <Box
            display='flex'
            alignItems='center'
            sx={{ transform: 'scale(1.5)' }}
          >
            {mapAssetTokenIcon(chain, assetGroup.assetGroupTokens[0].address)}
          </Box>
          <SmartVaultNameTableTypography variant='body2'>
            {name}
          </SmartVaultNameTableTypography>
        </Box>
        <Checkbox color='primary' checked={isSelected} disableRipple />
      </Box>

      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between' sx={{ py: 1 }}>
          <Typography variant='body2'>7D APY</Typography>
          <Typography variant='body2'>{formatUSD(apy || 0)} %</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' sx={{ py: 1 }}>
          <Typography variant='body2'>TVR</Typography>
          <Typography variant='body2' textAlign='right'>
            {currency} {formatUSD(tvr.totalTvr || 0)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default VaultSelectorTableRowMobile
