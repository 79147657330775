import InfoIcon from '@mui/icons-material/Info'
import { Box, Skeleton, Tooltip, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import { useRiskMetrics } from '@/hooks/createVault/useRiskMetrics'

import TextTagRenderer from '@/components/atoms/TextTagRenderer'
import SectionColumn from '@/components/molecules/SectionColumn'
import SectionItem from '@/components/molecules/SectionItem'
import FeesTooltip from '@/components/organisms/Tooltips/FeesTooltip'

import { mapRiskModelConfig } from '@/constants/riskModels/riskModels'
import { formatNumber } from '@/utils/formats'

const Summary = () => {
  const t = useLocale()

  const { chain } = useConnectionState()

  const { selectedRiskModel } = useCreateVaultState()

  // TODO: ARB
  const riskModelConfig = mapRiskModelConfig(chain)

  const { projectedAPY, strategyRiskScore, apyAfterFees, isLoading } =
    useRiskMetrics()

  return (
    <SectionColumn
      title={t('components.organisms.createVault.launch.summary.title')}
      sx={{ paddingBottom: 0 }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'inline-grid',
          gridAutoColumns: 'minmax(0, 1fr)',
          gridAutoFlow: 'column',
          gap: (theme) => theme.spacing(2),
        }}
      >
        <SectionItem
          title={t('components.organisms.createVault.launch.summary.apy.title')}
        >
          <Typography variant='h6'>
            {isLoading ? (
              <Skeleton variant='text' animation='wave' width={150} />
            ) : (
              `${formatNumber(projectedAPY, 2)} %`
            )}
          </Typography>
        </SectionItem>
        <SectionItem
          title={t(
            'components.organisms.createVault.launch.summary.afterFeesApy.title'
          )}
        >
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h6'>
              {isLoading ? (
                <Skeleton variant='text' animation='wave' width={150} />
              ) : (
                `${formatNumber(apyAfterFees, 2)} %`
              )}
            </Typography>
            <Tooltip title={<FeesTooltip />} arrow placement='bottom'>
              <Box
                display='flex'
                alignItems='center'
                p={0.5}
                sx={{ cursor: 'pointer' }}
              >
                <InfoIcon
                  className='lg'
                  sx={{ color: 'primary.main', marginTop: '0.1rem' }}
                />
              </Box>
            </Tooltip>
          </Box>
        </SectionItem>
        <SectionItem
          title={t(
            'components.organisms.createVault.launch.summary.riskModel.title'
          )}
        >
          <Typography variant='h6'>
            {riskModelConfig[selectedRiskModel].riskModelName}
          </Typography>
        </SectionItem>
        <SectionItem
          title={t(
            'components.organisms.createVault.launch.summary.riskScore.title'
          )}
        >
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h6'>
              {isLoading ? (
                <Skeleton variant='text' animation='wave' width={150} />
              ) : (
                formatNumber(strategyRiskScore, 1)
              )}
            </Typography>
            <Tooltip
              title={
                <TextTagRenderer
                  inputString={t(
                    'components.organisms.tooltips.riskScoreTooltip.text'
                  )}
                />
              }
              arrow
              placement='bottom'
            >
              <Box
                display='flex'
                alignItems='center'
                p={0.5}
                sx={{ cursor: 'pointer' }}
              >
                <InfoIcon
                  className='lg'
                  sx={{ color: 'primary.main', marginTop: '0.1rem' }}
                />
              </Box>
            </Tooltip>
          </Box>
        </SectionItem>
      </Box>
    </SectionColumn>
  )
}

export default Summary
