import { TableCell, TableRow, Typography } from '@mui/material'
import { MetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { BigNumber } from 'ethers'
import { formatUnits, parseEther } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'

import { METAVAULT_BASE_POINTS } from '@/constants/metavaults'
import { formatUSD } from '@/utils/formats'
import {
  getDHWExecutedState,
  getFlushExecutedState,
} from '@/utils/web3/flushes'

interface MetavaultVaultsTableRowProps {
  row: MetaVaultVaults
  totalPendingDeposits: string
  totalPendingWithdrawals: string
}

const MetavaultVaultsTableRow: React.FC<MetavaultVaultsTableRowProps> = ({
  row,
  totalPendingDeposits,
  totalPendingWithdrawals,
}) => {
  const { name, assetGroup, allocation, smartVaultFlushes } = row

  const [vaultPendingDeposit, setVaultPendingDeposit] = useState('0')

  const [vaultPendingWithdrawals, setVaultPendingWithdrawals] = useState('0')

  useEffect(() => {
    const allocationPercentage = parseEther(allocation).div(
      BigNumber.from(METAVAULT_BASE_POINTS)
    )

    if (totalPendingDeposits) {
      const pendingDeposit = formatUnits(
        parseEther(totalPendingDeposits).mul(allocationPercentage),
        36
      )

      setVaultPendingDeposit(pendingDeposit)
    }

    if (totalPendingWithdrawals) {
      const pendingWithdrawal = formatUnits(
        parseEther(totalPendingWithdrawals).mul(allocationPercentage),
        36
      )

      setVaultPendingWithdrawals(pendingWithdrawal)
    }
  }, [])

  return (
    <>
      <TableRow
        sx={{
          '& > td': {
            py: '1rem',
          },
        }}
      >
        <TableCell>
          <AssetGroupTokens assetGroup={assetGroup} />
        </TableCell>
        <TableCell align='left' padding='none'>
          <SmartVaultNameTableTypography variant='body2'>
            {name}
          </SmartVaultNameTableTypography>
        </TableCell>
        {/* TODO: use useMetavaultsPage() to get pending deposits, withdrawals, flush and DHW state*/}
        <TableCell align='right'>
          <Typography variant='body2'>
            {formatUSD(vaultPendingDeposit)}{' '}
            {assetGroup.assetGroupTokens[0].symbol ?? ''}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            {' '}
            {formatUSD(vaultPendingWithdrawals)}{' '}
            {assetGroup.assetGroupTokens[0].symbol ?? ''}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            {getFlushExecutedState(smartVaultFlushes)}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            {getDHWExecutedState(smartVaultFlushes)}
          </Typography>
        </TableCell>{' '}
        <TableCell align='right'></TableCell>{' '}
      </TableRow>
      {/*TODO: add strategy dropdown*/}
      {/*{!isMetavault && (
                <CreatorVaultsTableRowExpand
                    row={row}
                    vaultClaimableNFTs={vaultClaimableNFTs}
                />
            )}*/}
    </>
  )
}

export default MetavaultVaultsTableRow
