import CancelIcon from '@mui/icons-material/Cancel'
import LoginIcon from '@mui/icons-material/Login'
import { Box, InputAdornment, TextField } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import Section from '@/components/molecules/Section'
import GuardsCard from '@/components/organisms/CreateVault/Guards/GuardsCard'

import { Guard } from '@/constants/createVault'
import { entryGuards } from '@/mockdata/create-vault.json'

const Guards = () => {
  const [search, setSearch] = useState('')
  const [focused, setFocused] = useState('')
  const { selectedGuards, setStepCompletedStatus } = useCreateVaultState()

  const t = useLocale()

  const handleClearSearch = () => {
    setSearch('')
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }

  useEffect(() => {
    // const isCompleted =
    //   Object.values(selectedGuards).filter(
    //     (guard) => guard.configured && guard.checked
    //   ).length > 0 &&
    //   !Object.values(selectedGuards).some(
    //     (guard) => guard.checked && !guard.configured
    //   )
    setStepCompletedStatus(4, true)
  }, [selectedGuards])

  return (
    <Section>
      <Box padding={2} width='100%'>
        <TextField
          value={search}
          onChange={handleInput}
          label={t('components.organisms.createVault.guards.search.label')}
          size='small'
          fullWidth
          variant='outlined'
          InputProps={{
            endAdornment: search !== '' && (
              <InputAdornment
                position='end'
                sx={{ cursor: 'pointer' }}
                onClick={handleClearSearch}
              >
                <CancelIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box
          display='inline-grid'
          gridAutoColumns='minmax(0, 1fr)'
          gridAutoFlow='column'
          paddingTop={(theme) => theme.spacing(2)}
          gap={(theme) => theme.spacing(4)}
          width='100%'
        >
          <GuardsCard
            title={t(
              'components.organisms.createVault.guards.entryGuard.title'
            )}
            subtitle={[
              t(
                'components.organisms.createVault.guards.entryGuard.subtitle.subtitleMain'
              ),
              t(
                'components.organisms.createVault.guards.entryGuard.subtitle.subtitle1stCondition'
              ),
              t(
                'components.organisms.createVault.guards.entryGuard.subtitle.subtitle2ndCondition'
              ),
            ]}
            avatar={<LoginIcon sx={{ color: 'icon.primary' }} />}
            guards={entryGuards as Guard[]}
            filter={search}
            focused={focused}
            setFocused={setFocused}
          />
          {/* <GuardsCard
            title={t('components.organisms.createVault.guards.exitGuard.title')}
            subtitle={t(
              'components.organisms.createVault.guards.exitGuard.subtitle'
            )}
            avatar={<LogoutIcon sx={{ color: 'icon.primary' }} />}
            guards={exitGuards as Guard[]}
            filter={search}
            focused={focused}
            setFocused={setFocused}
          /> */}
        </Box>
      </Box>
    </Section>
  )
}

export default Guards
