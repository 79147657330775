import { Stack } from '@mui/material'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import PageTransition from '@/components/atoms/PageTransition'
import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import VestingParagraph from '@/components/organisms/Vesting/VestingParagraph'
import VestingTokens from '@/components/organisms/Vesting/VestingTokens'

import { Routes } from '@/constants/routes'
import { VestingPage } from '@/constants/vesting'

const Vesting = () => {
  const { chain } = useConnectionState()

  const paths: { [path: string]: VestingPage } = {
    [Routes(chain).vesting.root.url]: VestingPage.VESTING,
    [Routes(chain).vesting.claim.url]: VestingPage.CLAIM,
  }
  const [page, setPage] = useState<VestingPage>(paths[location.pathname])

  return (
    <PageTransition>
      <Stack gap={(theme) => theme.spacing(4)}>
        <SpoolBreadcrumbs page='vesting'>
          <VestingParagraph />
        </SpoolBreadcrumbs>
        <VestingTokens page={page} setPage={setPage} />
      </Stack>
    </PageTransition>
  )
}

export default Vesting
