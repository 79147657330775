const Github = () => {
  return (
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.875 7.12501V7.75001C16.8737 8.80751 16.4898 9.82883 15.7942 10.6254C15.0986 11.4219 14.1383 11.9399 13.0906 12.0836C13.5183 12.6308 13.7504 13.3055 13.75 14V17.125C13.75 17.2908 13.6842 17.4497 13.5669 17.567C13.4497 17.6842 13.2908 17.75 13.125 17.75H8.125C7.95924 17.75 7.80027 17.6842 7.68306 17.567C7.56585 17.4497 7.5 17.2908 7.5 17.125V15.875H5.625C4.7962 15.875 4.00134 15.5458 3.41529 14.9597C2.82924 14.3737 2.5 13.5788 2.5 12.75C2.5 12.2527 2.30246 11.7758 1.95083 11.4242C1.59919 11.0726 1.12228 10.875 0.625 10.875C0.45924 10.875 0.300269 10.8092 0.183058 10.692C0.065848 10.5747 0 10.4158 0 10.25C0 10.0842 0.065848 9.92528 0.183058 9.80807C0.300269 9.69086 0.45924 9.62501 0.625 9.62501C1.03538 9.62501 1.44174 9.70584 1.82089 9.86289C2.20003 10.0199 2.54453 10.2501 2.83471 10.5403C3.12489 10.8305 3.35508 11.175 3.51212 11.5541C3.66917 11.9333 3.75 12.3396 3.75 12.75C3.75 13.2473 3.94754 13.7242 4.29917 14.0758C4.65081 14.4275 5.12772 14.625 5.625 14.625H7.5V14C7.4996 13.3055 7.73173 12.6308 8.15938 12.0836C7.11169 11.9399 6.15139 11.4219 5.4558 10.6254C4.76021 9.82883 4.37632 8.80751 4.375 7.75001V7.12501C4.38278 6.34827 4.58963 5.58651 4.97578 4.91251C4.78474 4.29626 4.72346 3.64711 4.7958 3.00599C4.86814 2.36487 5.07254 1.7457 5.39609 1.18751C5.45096 1.09248 5.52988 1.01357 5.62492 0.958709C5.71996 0.903853 5.82777 0.874986 5.9375 0.87501C6.66548 0.873486 7.38372 1.04225 8.03485 1.36781C8.68598 1.69337 9.25193 2.16671 9.6875 2.75001H11.5625C11.9981 2.16671 12.564 1.69337 13.2151 1.36781C13.8663 1.04225 14.5845 0.873486 15.3125 0.87501C15.4222 0.874986 15.53 0.903853 15.6251 0.958709C15.7201 1.01357 15.799 1.09248 15.8539 1.18751C16.1775 1.74569 16.3819 2.3649 16.4541 3.00604C16.5263 3.64719 16.4648 4.29634 16.2734 4.91251C16.6603 5.58624 16.8675 6.34813 16.875 7.12501Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Github
