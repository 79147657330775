import { IncentivizeVault, SortOrder } from '@solidant/spool-v2-fe-lib'
import { memo, useMemo, useReducer } from 'react'

import {
  IncentivizeVaultContext,
  initialState,
} from '@/store/incentiviseVault/incentiviseVault.context'
import incentivizeVaultReducer from '@/store/incentiviseVault/incentiviseVault.reducer'
import { IncentivizeVaultActionTypes } from '@/store/incentiviseVault/incentiviseVault.types'

import { IncentivesPage } from '@/constants/incentives'

import { IncentivesToken } from '@/types/incentives'
import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const IncentivizeVaultProvider = memo(({ children }: RCNode) => {
  const [incentivizeVault, dispatch] = useReducer(
    incentivizeVaultReducer,
    initialState
  )

  const setTablePage = (tablePage: number) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_TABLE_PAGE,
      payload: tablePage,
    })
  }
  const setTableRowPerPage = (tableRowPerPage: number) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_TABLE_ROW_PER_PAGE,
      payload: tableRowPerPage,
    })
  }
  const setTableSortType = (tableSortType: SortType<IncentivizeVault>) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_TABLE_SORT_TYPE,
      payload: tableSortType,
    })
  }

  const setOpenId = (openId: string | undefined) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_OPEN_ID,
      payload: openId,
    })
  }

  const setPage = (page: IncentivesPage | undefined) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_PAGE,
      payload: page,
    })
  }

  const setTargetToken = (checkedToken: string | undefined) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_TARGET_TOKEN,
      payload: checkedToken,
    })
  }

  const setTokenAmount = (tokenAmount: string) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_TOKEN_AMOUNT,
      payload: tokenAmount,
    })
  }

  const setDays = (days: string) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_DAYS,
      payload: days,
    })
  }

  const setIsCustomToken = (isCustomToken: boolean) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_IS_CUSTOM_TOKEN,
      payload: isCustomToken,
    })
  }

  const setTokenTableSortType = (
    tokenTableSortType: SortType<IncentivesToken>
  ) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_TOKEN_TABLE_SORT_TYPE,
      payload: tokenTableSortType,
    })
  }

  const handleSortClick = (
    key: keyof IncentivizeVault,
    sortType: SortType<IncentivizeVault>
  ) => {
    let direction = SortOrder.DESC
    if (!sortType?.direction || sortType?.key === key) {
      direction =
        sortType?.direction === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
    }
    setTableSortType({ key, direction })
  }

  const handleTokenTableSortClick = (
    key: keyof IncentivesToken,
    sortType: SortType<IncentivesToken>
  ) => {
    let direction = SortOrder.DESC
    if (!sortType?.direction || sortType?.key === key) {
      direction =
        sortType?.direction === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
    }
    setTokenTableSortType({ key, direction })
  }

  const setCustomTokenAddress = (customTokenAddress: string) => {
    dispatch({
      type: IncentivizeVaultActionTypes.SET_CUSTOM_TOKEN_ADDRESS,
      payload: customTokenAddress,
    })
  }

  const contextValue = useMemo(() => {
    return {
      ...incentivizeVault,
      setTablePage,
      setTableRowPerPage,
      setTableSortType,
      handleSortClick,
      setOpenId,
      setPage,
      setTargetToken,
      setTokenAmount,
      setDays,
      setIsCustomToken,
      setTokenTableSortType,
      handleTokenTableSortClick,
      setCustomTokenAddress,
    }
  }, [
    incentivizeVault.tablePage,
    incentivizeVault.tableRowsPerPage,
    incentivizeVault.tableSortType,
    incentivizeVault.openId,
    incentivizeVault.page,
    incentivizeVault.targetToken,
    incentivizeVault.tokenAmount,
    incentivizeVault.days,
    incentivizeVault.isCustomToken,
    incentivizeVault.tokenTableSortType,
    incentivizeVault.customTokenAddress,
  ])

  return (
    <IncentivizeVaultContext.Provider value={contextValue}>
      {children}
    </IncentivizeVaultContext.Provider>
  )
})
