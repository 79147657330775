import { Box, Step, StepLabel, Stepper } from '@mui/material'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useCreateVault from '@/hooks/createVault/useCreateVault'
import useSteps from '@/hooks/createVault/useSteps'

import AdvancedNavigation from '@/components/organisms/CreateVault/Actions/AdvancedNavigation'
import BackNavigation from '@/components/organisms/CreateVault/Actions/BackNavigation'
import NextNavigation from '@/components/organisms/CreateVault/Actions/NextNavigation'
import CreateVaultRenderStep from '@/components/organisms/CreateVault/CreateVaultRenderStep'

import { STEP_SEQUENCE } from '@/store/createVault/createVault.state'

import { FEATURES } from '@/config/sdk'

const CreateVaultLayout: React.FC = () => {
  const { currentStep, getStepData } = useCreateVaultState()
  const steps = useSteps()
  const { chain } = useConnectionState()

  const [isAdvanced, setIsAdvanced] = useState(false)
  const step = getStepData(currentStep)

  let filteredSteps = null

  if (!isAdvanced && FEATURES(chain).CREATE_VAULT_ADVANCED) {
    filteredSteps = steps.filter(
      (_, index) =>
        index !== STEP_SEQUENCE.GUARDS - 1 &&
        index !== STEP_SEQUENCE.ACTIONS - 1
    )
  } else {
    filteredSteps = steps.filter(
      (_, index) => index !== STEP_SEQUENCE.ACTIONS - 1
    )
  }

  const handleCreateVault = useCreateVault()

  return (
    <Box>
      <Stepper
        activeStep={currentStep - 1}
        alternativeLabel
        sx={{ my: (theme) => theme.spacing(2) }}
      >
        {filteredSteps.map((step, index) => (
          <Step
            key={step.name}
            completed={step.completed && index < currentStep}
          >
            <StepLabel>{step.name}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <CreateVaultRenderStep
        totalSteps={steps.length}
        step={currentStep}
        type='body'
      />
      <Box
        width='100%'
        display='flex'
        justifyContent={currentStep !== 1 ? 'space-between' : 'end'}
      >
        <BackNavigation step={step} isAdvanced={isAdvanced} />

        <AdvancedNavigation
          isAdvanced={isAdvanced}
          setIsAdvanced={setIsAdvanced}
        />

        <NextNavigation
          step={step}
          isAdvanced={isAdvanced}
          handleCreateVault={handleCreateVault}
        />
      </Box>
    </Box>
  )
}

export default CreateVaultLayout
