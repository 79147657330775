import { useContext } from 'react'

import { ModalManager } from '@/store/modal/modals.context'

const useModalState = () => {
  const modalContext = useContext(ModalManager)
  if (!Object.keys(ModalManager).length) {
    throw new Error('ModalContext must be used within its provider')
  }
  return modalContext
}

export default useModalState
