import { Box, styled } from '@mui/material'

const Middle = styled(Box)(({ theme }) => ({
  width: '2px',
  height: '53px',
  backgroundColor: theme.palette.primary.main,
  margintTop: -'2px',
}))

export default Middle
