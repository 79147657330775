import { useWeb3React } from '@web3-react/core'

import useAppState from '@/hooks/context/useAppState'
import useModalState from '@/hooks/context/useModalState'
import usePendingWithdrawals from '@/hooks/smartVaults/usePendingWithdrawals'
import useWithdrawalDetails from '@/hooks/smartVaults/useWithdrawalDetails'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { WITHDRAW_FLUSH } from '@/config/flushes.config'
import { IS_STAGING, IS_TESTNET } from '@/config/sdk'
import { sleep } from '@/utils/web3'

const useWithdrawAll = (withdrawFast: boolean, address: string) => {
  const { openModal, closeModal, setModalType } = useModalState()

  const { account, provider } = useWeb3React()

  const { sdk } = useAppState()

  const { mutate } = useWithdrawalDetails(account, address)

  const { pendingWithdrawals, updatePendingReceipts } = usePendingWithdrawals(
    address,
    account
  )

  const withdrawAll = async () => {
    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.Withdraw,
        },
      })

      const userNfts = await sdk.smartVault.getUserWithdrawableNftIds(
        account,
        address
      )

      const userSVTsOwned = await sdk.smartVault.getUserSVTBalance(
        address.toLowerCase(),
        account.toLowerCase(),
        userNfts.nftId
      )

      const blockNumber = await provider.getBlockNumber()

      const tx = withdrawFast
        ? await sdk.smartVault.withdrawFast(
            address,
            {
              nftId: userNfts.nftId,
              nftAmounts: userNfts.shares,
            },
            userSVTsOwned,
            account.toLowerCase(),
            blockNumber,
            provider.getSigner(),
            IS_TESTNET && !IS_STAGING
          )
        : await sdk.smartVault.withdraw(
            address,
            userSVTsOwned,
            {
              nftId: userNfts.nftId,
              shares: userNfts.shares,
            },
            account,
            // flush permenantly set to false
            WITHDRAW_FLUSH
          )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.Withdraw,
        },
      })

      await tx.wait()

      await sleep(5000)

      await updatePendingReceipts(pendingWithdrawals)

      closeModal(Modals.WITHDRAW_MODAL)

      if (withdrawFast) {
        setModalType({
          actionModalType: {
            transactionStatus: TransactionStatus.Success,
            transactionType: TransactionType.FastWithdraw,
            txHash: tx.hash,
          },
        })
        return
      } else {
        setModalType({
          actionModalType: {
            transactionStatus: TransactionStatus.Success,
            transactionType: TransactionType.Withdraw,
          },
        })
      }
    } catch (error) {
      console.warn('error', error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.Withdraw,
        },
      })
    } finally {
      mutate()
    }
  }

  return withdrawAll
}

export default useWithdrawAll
