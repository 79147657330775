import { HeadCell } from '@/types/table'

export const strategiesHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Strategies',
  },
  {
    id: 'apy',
    numeric: true,
    label: '7D APY',
    align: 'right',
  },
  {
    id: 'balanceUSD',
    numeric: true,
    label: 'Total Value Routed',
    align: 'right',
  },
  /*{
    id: 'auditor',
    numeric: false,
    label: 'Audited by',
    align: 'right',
  },*/
]

export const strategyVaultsHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Smart vault',
  },
  {
    id: 'adjustedApy',
    numeric: true,
    label: 'Total APY after fees',
    align: 'right',
  },
  {
    id: 'totalVolumeRouted',
    numeric: true,
    label: 'TVR',
    align: 'right',
  },
]
