import { Box, Typography } from '@mui/material'
import { IncentivizeRewardToken } from '@solidant/spool-v2-fe-lib'
import dayjs, { unix } from 'dayjs'
import { memo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useReincentivize from '@/hooks/incentives/transactions/useReincentivize'

import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { formatUSD } from '@/utils/formats'
import { countDecimals } from '@/utils/helpers/countDecimals'

import { CreatorVault } from '@/types/dashboard'

interface CreatorVaultsMobileRowExpandProps {
  incentivizeReward: IncentivizeRewardToken
  row: CreatorVault
}

const CreatorVaultsMobileRowExpand: React.FC<
  CreatorVaultsMobileRowExpandProps
> = ({ incentivizeReward, row }) => {
  const t = useLocale()

  const { chain } = useConnectionState()

  const { updateIncentivizedRewards } = useCreatorDashboard()

  const { totalAmount, asset, remainingAmount, endTime, startTime } =
    incentivizeReward

  const currentIncentivizedDays = unix(Number(endTime)).diff(dayjs(), 'day') + 1
  const totalIncentivizedDays =
    unix(Number(endTime)).diff(unix(Number(startTime)), 'day') + 1

  const endOfIncentiveProgress =
    100 -
    ((dayjs().unix() - Number(startTime)) * 100) /
      (Number(endTime) - Number(startTime))

  const incentiveFinished = endOfIncentiveProgress < 0

  const reIncentivize = useReincentivize(
    row?.address ?? '',
    asset.address,
    countDecimals(incentivizeReward.totalAmount.toString()) >
      incentivizeReward.asset.decimals
      ? parseFloat(incentivizeReward.totalAmount.toString()).toFixed(
          incentivizeReward.asset.decimals
        )
      : incentivizeReward.totalAmount.toString(),
    totalIncentivizedDays.toString(),
    incentivizeReward,
    () =>
      updateIncentivizedRewards(
        row?.address ?? '',
        false,
        row.incentivizedRewards,
        asset.address
      )
  )

  const reincentivizeClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    reIncentivize()
  }

  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='1rem'
        mb='1rem'
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.rewards.incentiveToken'
            )}
          </Typography>
          <Typography fontSize='14px'>
            <Box display='flex' alignItems='center' gap='4px'>
              <Typography variant='body2'>{asset.symbol}</Typography>
              <Box sx={{ transform: 'scale(1.2)' }} display='flex'>
                {mapAssetTokenIcon(chain, asset.address ?? '')}
              </Box>
            </Box>
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.rewards.amountLeft'
            )}
          </Typography>
          <Box
            fontSize='14px'
            display='flex'
            alignItems='center'
            justifyContent='right'
            gap='4px'
          >
            {formatUSD(remainingAmount)}/{formatUSD(totalAmount.toString())}{' '}
            <Typography variant='body2'>{asset.symbol}</Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.rewards.countOfDays'
            )}
          </Typography>
          <Typography fontSize='14px'>
            {
              // if currentIncentivizedDays is negative, show 0
              endOfIncentiveProgress < 0 ? 0 : currentIncentivizedDays
            }
            /{totalIncentivizedDays} Days
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.rewards.status'
            )}
          </Typography>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Box
              display='flex'
              alignItems='center'
              padding='0.5rem'
              height='2rem'
              borderRadius='4px'
              bgcolor={incentiveFinished ? '#F5F5F5' : '#E8F5E9'}
              color={incentiveFinished ? '#757575' : '#2E7D32'}
            >
              <Typography fontSize='30px'>•</Typography>
              <Typography fontSize='14px'>
                {incentiveFinished ? 'Finished' : 'Active'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          fontSize='14px'
          color='#006BA6'
          sx={{ textTransform: 'none', padding: '0', cursor: 'pointer' }}
          onClick={(event) => reincentivizeClick(event)}
        >
          {t(
            'components.organisms.dashboard.creator.creatorVaultsTable.row.options'
          )}
        </Box>
      </Box>
    </>
  )
}

export default memo(CreatorVaultsMobileRowExpand)
