import { Paper, styled } from '@mui/material'

const ModalBoxOuterContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: 'none',
  borderRadius: '4px',
  padding: theme.spacing(5, 3),
  boxShadow: theme.shadows[6],
}))

export default ModalBoxOuterContainer
