import { memo, useMemo, useReducer } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

import { initialState, TopMenuContext } from '@/store/topMenu/topMenu.context'
import topMenuReducer from '@/store/topMenu/topMenu.reducer'
import { TopMenuActionTypes } from '@/store/topMenu/topMenu.types'

import { AppMode, TOP_MENU_ITEMS } from '@/constants/topMenu'

import { RCNode } from '@/types/index'

export const TopMenuProvider = memo(({ children }: RCNode) => {
  const { pathname } = useUniversalNavigation()
  const { chain } = useConnectionState()

  const t = useLocale()

  const getInitalAppMode = () => {
    return pathname.includes('/manager') ? AppMode.B2B : AppMode.B2C
  }

  const getInitialActiveSubmenuItem = () => {
    const mode = getInitalAppMode()
    for (const menuItem of TOP_MENU_ITEMS(chain)[mode]) {
      if (menuItem.submenuSections) {
        for (const section of menuItem.submenuSections) {
          for (const subItem of section) {
            if (pathname.startsWith(subItem.url)) {
              return subItem.title
            }
          }
        }
      }
    }
    return ''
  }

  // Determine the initially active item based on the current pathname
  const getInitialActiveMenuItem = () => {
    if (pathname === '/')
      return t('components.organisms.topMenu.b2c.menuItems.vaults.title')

    const mode = getInitalAppMode()

    for (const menuItem of TOP_MENU_ITEMS(chain)[mode]) {
      if (pathname.startsWith(menuItem.url)) {
        return menuItem.title
      }
      if (menuItem.submenuSections) {
        for (const section of menuItem.submenuSections) {
          for (const subItem of section) {
            if (pathname.startsWith(subItem.url)) {
              return menuItem.title
            }
          }
        }
      }
      if (menuItem.tabs) {
        for (const tab of menuItem.tabs) {
          if (pathname.startsWith(tab.url)) {
            return menuItem.title
          }
        }
      }
    }
  }

  const getInitialActiveTab = () => {
    const mode = getInitalAppMode()
    for (const menuItem of TOP_MENU_ITEMS(chain)[mode]) {
      if (menuItem.tabs) {
        for (const tab of menuItem.tabs) {
          if (pathname.startsWith(tab.url)) {
            return tab.title
          }
        }
      }
    }
    return ''
  }

  // Define local state and associated dispatch function using the reducer
  const [topmenu, dispatch] = useReducer(topMenuReducer, {
    ...initialState,
    activeSubmenuItem: getInitialActiveSubmenuItem(),
    activeMenuItem: getInitialActiveMenuItem(),
    activeTab: getInitialActiveTab(),
    appMode: getInitalAppMode(),
  })

  // Action dispatchers for the top menu state
  const toggleMenu = (open: boolean) =>
    dispatch({ type: TopMenuActionTypes.TOGGLE_MENU, payload: open })
  const setActiveSubmenuItem = (activeSubmenuItem: string) =>
    dispatch({
      type: TopMenuActionTypes.SET_ACTIVE_SUBMENU_ITEM,
      payload: activeSubmenuItem,
    })
  const setActiveMenuItem = (activeMenuItem: string) =>
    dispatch({
      type: TopMenuActionTypes.SET_ACTIVE_MENU_ITEM,
      payload: activeMenuItem,
    })
  const setActiveTab = (activeTab: string) =>
    dispatch({
      type: TopMenuActionTypes.SET_ACTIVE_TAB,
      payload: activeTab,
    })
  const setAppMode = (appMode: AppMode) =>
    dispatch({ type: TopMenuActionTypes.SET_APP_MODE, payload: appMode })

  const contextValue = useMemo(
    () => ({
      ...topmenu,
      toggleMenu,
      setActiveSubmenuItem,
      setActiveMenuItem,
      setActiveTab,
      setAppMode,
    }),
    [
      topmenu.isOpen,
      topmenu.activeSubmenuItem,
      topmenu.activeMenuItem,
      topmenu.activeTab,
      topmenu.appMode,
    ]
  )

  return (
    <TopMenuContext.Provider value={contextValue}>
      {children}
    </TopMenuContext.Provider>
  )
})
