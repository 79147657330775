import {
  Box,
  Container,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material'
import { ResponsiveLine, Serie } from '@nivo/line'
import { PropsWithChildren } from 'react'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { formatUSD } from '@/utils/formats'
import {
  formatXAxisValue,
  getDashboardLineChartMarginLeft,
  getMaxValue,
  getXTickerValues,
  getYTickValues,
  TimeRangeOptions,
} from '@/utils/stakedChart.biaxial'

interface StakedBiAxialChartProps extends PropsWithChildren {
  data: Serie[]
  xTickLines: number
  handleChange?: (event: SelectChangeEvent) => void
  timeRange?: TimeRangeOptions
  timeRangeSX?: SxProps<Theme>
}

const DashboardLineChart: React.FC<StakedBiAxialChartProps> = ({
  data,
  xTickLines,
  // handleChange,
  // timeRange,
  // timeRangeSX,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const maxValue = getMaxValue(data)
  const yTickValues = getYTickValues(maxValue)
  const xTickValues = getXTickerValues(data, xTickLines, true)
  const height = 250

  const lastYTickValue = yTickValues[yTickValues.length - 1]
  const lastYTickValueLength = lastYTickValue
    ? lastYTickValue.toString().length
    : 0

  return (
    <>
      {maxValue === 0 || xTickValues.length === 0 ? (
        <Container fixed>Error occurred while rendering graph!</Container>
      ) : (
        <>
          <Box
            component='div'
            sx={{
              height: height,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
              }}
            >
              <ResponsiveLine
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fontSize: 14,
                        fill: '#21232299',
                      },
                    },
                  },
                }}
                data={data}
                enableGridX={false}
                defs={[
                  {
                    id: 'gradient',
                    type: 'linearGradient',
                    colors: [
                      { offset: 0, color: data[0]['color'] },
                      { offset: 100, color: '#ffffff' },
                    ],
                  },
                ]}
                fill={[
                  {
                    match: '*',
                    id: 'gradient',
                  },
                ]}
                xScale={{ type: 'point' }}
                yFormat=' >-.2f'
                colors={data.map((d) => d['color'])}
                curve='linear'
                enableArea={true}
                axisLeft={{
                  legendPosition: 'middle',
                  legendOffset: -60,
                  tickSize: 0,
                  tickValues: yTickValues.filter((x) => x !== yTickValues[0]),
                  format: (value: number) => `$ ${formatUSD(value, 4, 0)}`,
                }}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: maxValue,
                }}
                axisBottom={{
                  format: (value) => {
                    return formatXAxisValue(
                      value,
                      getXTickerValues(
                        data,
                        belowPhabletBreakpoint ? 2 : 5,
                        true
                      )
                    )
                  },

                  tickSize: 0,
                  tickPadding: 10,
                }}
                lineWidth={1}
                gridYValues={yTickValues}
                gridXValues={xTickValues}
                margin={{
                  top: 10,
                  right: 0,
                  bottom: 30,
                  left: getDashboardLineChartMarginLeft(lastYTickValueLength),
                }}
                enablePoints={false}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default DashboardLineChart
