import { Backdrop, Box, styled } from '@mui/material'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useModalState from '@/hooks/context/useModalState'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useShowClaimableNft from '@/hooks/notification/useShowClaimableNft'

import ModalsContainer from '@/components/molecules/ModalsContainer'
import WithdrawalNotification from '@/components/molecules/WithdrawalNotification'
import CookieBanner from '@/components/organisms/Dialogs/CookieBanner'
import Submenu from '@/components/organisms/Submenu/Submenu'
import MenuTabs from '@/components/organisms/TopMenu/MenuTabs'
import TopMenu from '@/components/organisms/TopMenu/TopMenu'
import MobileWalletDrawer from '@/components/organisms/WalletDrawer/MobileWalletDrawer'
import WalletDrawer from '@/components/organisms/WalletDrawer/WalletDrawer'

import { Modals } from '@/store/modal/modals.types'

import { IS_PUBLIC_TESTNET } from '@/config/sdk'
import { CHAIN_URL, Routes } from '@/constants/routes'
import { SUBMENU_ITEMS } from '@/constants/subMenu'
import { AppMode } from '@/constants/topMenu'

const MainLayout = styled(Box)(() => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
}))

const Layout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()

  const { withdrawNotification, setWithdrawNotification } =
    useShowClaimableNft()

  const { chain } = useConnectionState()

  const { belowPhabletBreakpoint, belowTabletBreakpoint } =
    useBreakpointDetection()

  const { openModal } = useModalState()
  const { isOpen, toggleMenu, setAppMode, activeTab, activeMenuItem } =
    useTopMenuState()

  const [disableBottomMenu, setDisableBottomMenu] = useState<boolean>(false)
  const { pathname } = useUniversalNavigation()

  useEffect(() => {
    if (
      import.meta.env.VITE_MAINTENANCE_MODE &&
      import.meta.env.VITE_MAINTENANCE_MODE.toLowerCase() === 'true'
    ) {
      openModal(Modals.MAINTENANCE_MODAL)
    }
  }, [])

  useEffect(() => {
    let hideWelcomeModal = false
    try {
      hideWelcomeModal = localStorage.getItem('hideWelcomeModal') === 'true'
    } catch (e) {
      // console.warning('local storage not supported')
    }
    if (!hideWelcomeModal) {
      openModal(Modals.WELCOME_MODAL)
    }
  }, [])

  useEffect(() => {
    pathname.includes('/manager')
      ? setAppMode(AppMode.B2B)
      : setAppMode(AppMode.B2C)
  }, [pathname])

  useEffect(() => {
    if (IS_PUBLIC_TESTNET && !pathname.includes('sep')) {
      navigate(CHAIN_URL[chain] + pathname)
      return
    }

    if (
      !IS_PUBLIC_TESTNET &&
      !pathname.includes('eth') &&
      !pathname.includes('arb')
    ) {
      navigate(CHAIN_URL[chain] + pathname)
      return
    }
  }, [])

  const showSubmenu = useMemo(
    () => !!SUBMENU_ITEMS(chain)[activeMenuItem],
    [activeMenuItem]
  )

  useEffect(() => {
    setDisableBottomMenu(
      pathname.includes(Routes(chain).createMetavault.root.url)
    )
  }, [pathname])

  return (
    <MainLayout>
      {!belowPhabletBreakpoint && <WalletDrawer />}
      {belowPhabletBreakpoint && <MobileWalletDrawer />}
      <Box component='main' width='100%'>
        <Box
          component='nav'
          sx={{
            backgroundColor: 'white',
            zIndex: 1000,
            position: 'sticky',
            top: 0,
            borderBottom: '1.5px solid rgba(33, 35, 34, 0.12)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          width='100%'
        >
          <Box display='flex' flexDirection='column' alignItems='center'>
            <TopMenu />
          </Box>
          {activeTab && !belowTabletBreakpoint && <Submenu />}
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <Box width='100%' display='flex' justifyContent='center'>
            {children}
          </Box>
        </Box>
        {belowTabletBreakpoint && !disableBottomMenu && (
          <>
            {showSubmenu && (
              <Box
                component='nav'
                sx={{
                  backgroundColor: 'white',
                  zIndex: 1000,
                  position: 'fixed',
                  bottom: 73,
                  display: 'flex',
                  justifyContent: 'center',
                  borderTop: '1.5px solid rgba(33, 35, 34, 0.12)',
                  minHeight: '48px',
                }}
                width='100%'
              >
                <Submenu />
              </Box>
            )}
            <Box
              component='nav'
              sx={{
                backgroundColor: 'white',
                zIndex: 1000,
                position: 'fixed',
                bottom: 0,
                borderTop: '1.5px solid rgba(33, 35, 34, 0.12)',
                display: 'flex',
                justifyContent: 'center',
              }}
              width='100%'
            >
              <MenuTabs />
            </Box>
          </>
        )}
      </Box>
      {withdrawNotification.showNotification && (
        <WithdrawalNotification
          descriptionText={withdrawNotification.text}
          withdrawNotification={withdrawNotification}
          setWithdrawNotification={setWithdrawNotification}
        />
      )}
      <Backdrop
        open={isOpen}
        onClick={() => toggleMenu(false)}
        sx={{ backgroundColor: 'transparent', zIndex: 10 }}
      />
      <ModalsContainer />
      <CookieBanner />
    </MainLayout>
  )
}

export default Layout
