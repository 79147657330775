import { UserClaimableNFTs } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

import { MUTATE_SLEEP_DURATION } from '@/constants'
import { sleep } from '@/utils/web3'

const useUserClaimableNfts = () => {
  const { sdk } = useContext(AppContext)
  const { account, chainId } = useWeb3React()
  const { chain } = useConnectionState()

  const { data, error, mutate } = useSWR(
    sdk && account ? ['userClaimablewNFTs', account, chain, chainId] : null,
    async () => await sdk.smartVault.getAllUserClaimablewNFTs(account)
  )

  const updateUserClaimableNfts = async (
    previousValue?: UserClaimableNFTs[]
  ) => {
    if (!previousValue) {
      await mutate()
      return
    }

    let retries = 0

    do {
      const currentValue: UserClaimableNFTs[] = await mutate()

      if (currentValue.length !== previousValue.length) {
        break
      }

      retries++
      await sleep(MUTATE_SLEEP_DURATION)
    } while (retries < 5)
  }

  if (error) {
    console.error(error)
  }

  return {
    userClaimablewNFTs: data,
    isLoading: !error && !data,
    updateUserClaimableNfts,
    error,
  }
}

export default useUserClaimableNfts
