import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, IconButton, Typography } from '@mui/material'
import { InvestorSmartVault } from '@solidant/spool-v2-fe-lib'
import { memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useInvestorDashboardState from '@/hooks/context/useInvestorDashboardState'
import useUserClaimableNfts from '@/hooks/user/useUserClaimableNfts'

import getLocale from '@/locales/getLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import InvestorVaultOptions from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorVaultOptions'
import InvestorVaultsMobileRowExpand from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorVaultsMobileRowExpand'
import InvestorVaultsMobileRowWithdrawal from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorVaultsMobileRowWithdrawal'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { currency } from '@/constants/currency'
import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'

interface InvestorVaultsMobileRowProps {
  row: InvestorSmartVault
  optimisticallyBurnDNfts: (
    vaultAddress: string,
    dNftIds: string[]
  ) => Promise<void>
}

const InvestorVaultsMobileRow: React.FC<InvestorVaultsMobileRowProps> = ({
  row,
  optimisticallyBurnDNfts,
}) => {
  const t = getLocale()

  const { chain } = useConnectionState()

  const {
    address,
    name,
    assetGroup,
    balance,
    totalClaimableRewards,
    pendingDeposits,
    pendingWithdrawals,
    wNfts,
  } = row

  const { userClaimablewNFTs } = useUserClaimableNfts()

  const vaultClaimableNFTs = useMemo(() => {
    if (!userClaimablewNFTs) return []

    return userClaimablewNFTs.filter(({ vaultId }) => vaultId === address)
  }, [userClaimablewNFTs])

  const { selectedVaults, setSelectedVaults } = useInvestorDashboardState()

  const isSelected = useMemo(
    () => !!selectedVaults.find((vault) => vault.address === address),
    [selectedVaults, row]
  )

  const navigate = useNavigate()

  const onRowClick = () => {
    navigate(`${Routes(chain).smartVaults.root.url}/${address}`)
  }

  const onCheckboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const updatedVaults = isSelected
      ? selectedVaults.filter((vault) => vault.address !== address)
      : [...selectedVaults, row]

    setSelectedVaults(updatedVaults)
  }

  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='0.5rem'
        onClick={onRowClick}
        mb='1rem'
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex'>
            <Box>
              <AssetGroupTokens assetGroup={assetGroup} />
            </Box>
            <Box display='flex' justifyContent='space-between' pl='0.5rem'>
              <NameCellContent>
                <SmartVaultNameTableTypography variant='body2'>
                  {name}
                </SmartVaultNameTableTypography>
              </NameCellContent>
            </Box>
          </Box>
          <Box>
            <InvestorVaultOptions
              row={row}
              optimisticallyBurnDNfts={optimisticallyBurnDNfts}
            />
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' mt='1rem'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.7dapy')}
          </Typography>
          <Typography variant='subtitle2'>
            {currency} {formatUSD(+balance.total + +pendingDeposits.totalUSD)}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' mt='1rem' mb='1rem'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('pages.strategies.strategyDetailsPage.mobileTitles.tvr')}
          </Typography>
          <Typography variant='subtitle2'>
            {currency} {formatUSD(totalClaimableRewards)}
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          flexDirection='row-reverse'
          onClick={(event: any) => onCheckboxClick(event)}
        >
          <Box display='flex' alignItems='center'>
            <Typography fontSize='14px'>Show details</Typography>
            <IconButton sx={{ padding: '0px' }} size='small'>
              {isSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>
        <InvestorVaultsMobileRowExpand row={row} />
        {(!!wNfts?.length || !!pendingWithdrawals.total) && (
          <InvestorVaultsMobileRowWithdrawal
            vaultClaimableNFTs={vaultClaimableNFTs}
            row={row}
          />
        )}
      </Box>
    </>
  )
}

export default memo(InvestorVaultsMobileRow)
