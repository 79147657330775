import { SortOrder } from '@solidant/spool-v2-fe-lib'

import { CreateMetavaultState } from '@/store/createMetavault/createMetavault.types'

export const initialState: CreateMetavaultState = {
  steps: [
    {
      name: 'Basic Info',
      completed: false,
      id: 'basicInfo',
    },
    {
      name: 'Vaults',
      completed: false,
      id: 'vaults',
    },
    {
      name: 'Allocation',
      completed: false,
      id: 'allocation',
    },
    {
      name: 'Review',
      completed: false,
      id: 'review',
    },
  ],
  currentStep: 1,
  asset: '',
  selectedVaults: [],
  vaultsSort: {
    key: 'tvr',
    direction: SortOrder.DESC,
  },
  selectedVaultsAllocations: [],
  vaultName: '',
  vaultSymbol: '',
  searchString: '',
  metavaultId: '',
  newlyAddedVaults: [],
}
