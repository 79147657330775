import { Box, Typography } from '@mui/material'
import { StrategyVault } from '@solidant/spool-v2-fe-lib'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { currency } from '@/constants/currency'
import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'

interface StrategyVaultsTableRowProps {
  row: StrategyVault
}

const StrategyVaultsMobileRow: React.FC<StrategyVaultsTableRowProps> = ({
  row,
}) => {
  const { setIsVaultsRowClicked } = useUniversalNavigation()

  const { chain } = useConnectionState()

  const {
    address,
    name,
    assetGroup,
    totalVolumeRouted,
    incentivizedRewards,
    adjustedApy,
    baseApy,
  } = row

  const navigate = useNavigate()

  const onRowClick = () => {
    setIsVaultsRowClicked(true)
    navigate(`${Routes(chain).smartVaults.root.url}/${address}`)
  }

  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='0.5rem'
        onClick={onRowClick}
        mb='1rem'
      >
        <Box display='flex' alignItems='center' mb='1rem'>
          <Box>
            <AssetGroupTokens assetGroup={assetGroup} />
          </Box>
          <Box ml='0.5rem'>
            <NameCellContent>
              {/*{mapIcons(SupportedNetworks.ETHEREUM)}*/}
              <SmartVaultNameTableTypography variant='body2' fontSize='16PX'>
                {name}
              </SmartVaultNameTableTypography>
            </NameCellContent>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' mt='1.5rem'>
          <Box display='flex' flexDirection='column'>
            <Typography
              fontSize='24px'
              variant='body2'
              fontWeight='400'
              mb='0.2rem'
            >
              {formatUSD(adjustedApy)} %
            </Typography>
            <Typography variant='caption' color='rgba(33, 35, 34, 0.7)'>
              {`Base ${formatUSD(baseApy)}%`}
              {incentivizedRewards?.rewards
                .filter((reward) => +reward.apy > 0.01)
                .map((reward, index) => (
                  <span key={index}>
                    {' + '}
                    {reward.asset.symbol} {formatUSD(reward.apy)}%{' '}
                  </span>
                ))}
            </Typography>
            <Typography variant='caption' color='rgba(33, 35, 34, 0.7)'>
              After fees
            </Typography>
          </Box>
        </Box>
        <Box display='flex' mt='1rem'>
          <Typography variant='body2' fontSize='12px'>
            {currency} {formatUSD(totalVolumeRouted, 2)}
          </Typography>
          &nbsp;
          <Typography variant='caption' color='rgba(33, 35, 34, 0.7)'>
            TVR
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default memo(StrategyVaultsMobileRow)
