import { lazy, Suspense, useEffect, useState } from 'react'

import LoadSentry from '@/components/atoms/Sentry'

const Hotjar = lazy(() => import('@/components/atoms/Hotjar'))
const GoogleAnalytics = lazy(() => import('@/components/atoms/GoogleAnalytics'))

const Tracking: React.FC = () => {
  const [consentGiven, setConsentGiven] = useState(false)

  useEffect(() => {
    const userConsentLocalStorage = localStorage.getItem('userConsent')
    if (userConsentLocalStorage) {
      const { consent } = JSON.parse(userConsentLocalStorage)
      if (consent) {
        setConsentGiven(true)
      }
    }

    window.addEventListener('consentGiven', () => setConsentGiven(true))

    // Clean-up function
    return () => {
      window.removeEventListener('consentGiven', () => setConsentGiven(true))
    }
  }, [])

  return (
    <>
      {consentGiven && (
        <Suspense fallback={null}>
          <Hotjar appLoaded={consentGiven} />
          <GoogleAnalytics appLoaded={consentGiven} />
        </Suspense>
      )}
      <LoadSentry appLoaded={consentGiven} />
    </>
  )
}

export default Tracking
