import { createContext } from 'react'

import { ModalContext, ModalState } from '@/store/modal/modals.types'

export const initialState = {
  basic: { isOpen: false },
  connectWallet: { isOpen: false },
  actionModal: { isOpen: false, isMinimized: false },
  warningModal: { isOpen: false },
  welcomeModal: { isOpen: false },
  oneStrategyModal: { isOpen: false },
  receiptsModal: { isOpen: false },
  incentivizeVaultModal: { isOpen: false },
  withdrawModal: { isOpen: false },
  metavaultModal: { isOpen: false },
  metavaultAddVaultsModal: { isOpen: false },
  confirmationModal: { isOpen: false },
}

export const initialStateContext: ModalContext & {
  modals: Record<string, ModalState>
} = {
  modals: { ...initialState },
  openModal: (): void => undefined,
  closeModal: (): void => undefined,
  setModalType: (): void => undefined,
  minimizeModal: (): void => undefined,
}

export const ModalManager = createContext(initialStateContext)
ModalManager.displayName = 'ModalManager'
