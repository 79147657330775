import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const GuardsDescription = () => {
  const t = useLocale()
  return (
    <Typography variant='subtitle1'>
      {t('components.atoms.pageDescriptions.guardsDescription.description')}
    </Typography>
  )
}

export default GuardsDescription
