import { createContext } from 'react'

import { initialState } from '@/store/investorDashboard/investorDashboard.state'
import { InvestorDashboardContextProps } from '@/store/investorDashboard/investorDashboard.types'

export const InvestorDashboardContext =
  createContext<InvestorDashboardContextProps>({
    ...initialState,
    setInvestorVaultsTableLimit: () => undefined,
    setInvestorVaultsTablePage: () => undefined,
    handleInvestorSmartVaultSort: () => undefined,
    setSelectedVaults: () => undefined,
    setVaultsStrategiesTableLimit: () => undefined,
    setVaultsStrategiesTablePage: () => undefined,
    setBalanceChartValues: () => undefined,
    handleInvestorStrategySort: () => undefined,
  })
