const MetamaskLogo = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='40'
        height='40'
        rx='4.44444'
        fill='#E4751F'
        fillOpacity='0.1'
      />
      <g clipPath='url(#clip0_970_7306)'>
        <path
          d='M32.0048 7.70833L21.5996 15.4364L23.5238 10.877L32.0048 7.70833Z'
          fill='#E2761B'
          stroke='#E2761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.98438 7.70833L18.3059 15.5096L16.4759 10.877L7.98438 7.70833Z'
          fill='#E4761B'
          stroke='#E4761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.2615 25.6221L25.4902 29.8678L31.4196 31.4992L33.1242 25.7162L28.2615 25.6221Z'
          fill='#E4761B'
          stroke='#E4761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.88623 25.7162L8.58035 31.4992L14.5098 29.8678L11.7385 25.6221L6.88623 25.7162Z'
          fill='#E4761B'
          stroke='#E4761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.1752 18.4482L12.5229 20.9475L18.4105 21.209L18.2014 14.8822L14.1752 18.4482Z'
          fill='#E4761B'
          stroke='#E4761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.8145 18.4482L21.736 14.809L21.6001 21.209L27.4772 20.9476L25.8145 18.4482Z'
          fill='#E4761B'
          stroke='#E4761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5098 29.8678L18.0444 28.1423L14.9908 25.758L14.5098 29.8678Z'
          fill='#E4761B'
          stroke='#E4761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.9448 28.1423L25.4899 29.8678L24.9984 25.758L21.9448 28.1423Z'
          fill='#E4761B'
          stroke='#E4761B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.4899 29.8678L21.9448 28.1423L22.2272 30.4534L22.1958 31.426L25.4899 29.8678Z'
          fill='#D7C1B3'
          stroke='#D7C1B3'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5098 29.8678L17.8039 31.426L17.783 30.4534L18.0444 28.1423L14.5098 29.8678Z'
          fill='#D7C1B3'
          stroke='#D7C1B3'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8562 24.2312L14.9072 23.3632L16.9883 22.4116L17.8562 24.2312Z'
          fill='#233447'
          stroke='#233447'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.1333 24.2312L23.0013 22.4116L25.0928 23.3632L22.1333 24.2312Z'
          fill='#233447'
          stroke='#233447'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5095 29.8678L15.0115 25.6221L11.7383 25.7162L14.5095 29.8678Z'
          fill='#CD6116'
          stroke='#CD6116'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.9878 25.6221L25.4898 29.8678L28.261 25.7162L24.9878 25.6221Z'
          fill='#CD6116'
          stroke='#CD6116'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.4767 20.9475L21.5996 21.209L22.1434 24.2312L23.0114 22.4116L25.1029 23.3632L27.4767 20.9475Z'
          fill='#CD6116'
          stroke='#CD6116'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.9068 23.3632L16.9983 22.4116L17.8558 24.2312L18.41 21.209L12.5225 20.9475L14.9068 23.3632Z'
          fill='#CD6116'
          stroke='#CD6116'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5229 20.9475L14.9909 25.758L14.9073 23.3632L12.5229 20.9475Z'
          fill='#E4751F'
          stroke='#E4751F'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.1031 23.3632L24.9985 25.758L27.477 20.9475L25.1031 23.3632Z'
          fill='#E4751F'
          stroke='#E4751F'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.4107 21.209L17.8564 24.2312L18.5466 27.7972L18.7035 23.1018L18.4107 21.209Z'
          fill='#E4751F'
          stroke='#E4751F'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.6002 21.209L21.3179 23.0913L21.4434 27.7972L22.144 24.2312L21.6002 21.209Z'
          fill='#E4751F'
          stroke='#E4751F'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.1435 24.2312L21.4429 27.7972L21.9448 28.1423L24.9984 25.758L25.103 23.3632L22.1435 24.2312Z'
          fill='#F6851B'
          stroke='#F6851B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.9072 23.3632L14.9909 25.758L18.0445 28.1423L18.5464 27.7972L17.8562 24.2312L14.9072 23.3632Z'
          fill='#F6851B'
          stroke='#F6851B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.196 31.426L22.2274 30.4534L21.966 30.2234H18.0235L17.783 30.4534L17.8039 31.426L14.5098 29.8678L15.6601 30.809L17.9921 32.4299H21.9973L24.3398 30.809L25.4902 29.8678L22.196 31.426Z'
          fill='#C0AD9E'
          stroke='#C0AD9E'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.9448 28.1423L21.4428 27.7972H18.5461L18.0442 28.1423L17.7827 30.4534L18.0232 30.2234H21.9657L22.2272 30.4534L21.9448 28.1423Z'
          fill='#161616'
          stroke='#161616'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M32.4442 15.9384L33.3331 11.6717L32.005 7.70833L21.9448 15.175L25.8141 18.4482L31.2834 20.0482L32.4965 18.6364L31.9736 18.26L32.8102 17.4966L32.1618 16.9946L32.9984 16.3567L32.4442 15.9384Z'
          fill='#763D16'
          stroke='#763D16'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.6665 11.6717L7.55539 15.9384L6.99069 16.3567L7.82729 16.9946L7.18938 17.4966L8.02598 18.26L7.50311 18.6364L8.70572 20.0482L14.175 18.4482L18.0443 15.175L7.98415 7.70833L6.6665 11.6717Z'
          fill='#763D16'
          stroke='#763D16'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.2835 20.0482L25.8142 18.4482L27.477 20.9475L24.9985 25.758L28.2613 25.7162H33.124L31.2835 20.0482Z'
          fill='#F6851B'
          stroke='#F6851B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.1751 18.4482L8.70584 20.0482L6.88623 25.7162H11.7385L14.9908 25.758L12.5228 20.9475L14.1751 18.4482Z'
          fill='#F6851B'
          stroke='#F6851B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5998 21.209L21.9449 15.175L23.5344 10.877H16.4756L18.0442 15.175L18.4102 21.209L18.5357 23.1123L18.5462 27.7972H21.4429L21.4638 23.1123L21.5998 21.209Z'
          fill='#F6851B'
          stroke='#F6851B'
          strokeWidth='0.555556'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_970_7306'>
          <rect
            width='26.6667'
            height='26.6667'
            fill='white'
            transform='translate(6.6665 6.66667)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MetamaskLogo
