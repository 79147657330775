import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
interface UnsupportedMobilePage {
  feature: string
}

const UnsupportedMobilePage: React.FC<UnsupportedMobilePage> = ({
  feature,
}) => {
  const t = useLocale()

  return (
    <Box
      sx={{ height: '80vh' }}
      px='0.8rem'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Box
        display='flex'
        flexDirection='column'
        gap='0.5rem'
        alignItems='center'
      >
        <InfoOutlinedIcon color='disabled' />
        <Box display='flex'>
          <Typography textAlign='center'>
            {feature} {t('general.mobileNotSupported.notSupported')}
          </Typography>
        </Box>
        <Box>
          <Typography textAlign='center'>
            {t('general.mobileNotSupported.experienceInDesktop')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default UnsupportedMobilePage
