import { SelectChangeEvent, Stack } from '@mui/material'
import { useEffect } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import { tokenDetails } from '@/components/atoms/Tokens'
import AssetsSelect from '@/components/molecules/AssetsSelect'
import TransactionInput from '@/components/molecules/TransactionInput'

import { preventOverflow } from '@/utils/web3'

interface SingleAssetDepositInputProps {
  setIsConversion: React.Dispatch<React.SetStateAction<boolean>>
}

const SingleAssetDepositInput: React.FC<SingleAssetDepositInputProps> = ({
  setIsConversion,
}) => {
  const t = useLocale()
  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { chain } = useConnectionState()

  const { singleDeposit, setSingleDepositAmount } = useDepositState()

  const { smartVaultData } = useVaultsPage()
  const assetGroup = smartVaultData.assetGroup
  const { address } = assetGroup.assetGroupTokens[0]
  const selectedVaultAsset = assetGroup.assetGroupTokens[0].address

  const { decimals } = useTokenDetails(singleDeposit.tokenAddress)

  const handleChangeAsset = (event: SelectChangeEvent<unknown>) => {
    const selectedAsset = event.target.value as string

    setSingleDepositAmount({
      amount: preventOverflow(
        singleDeposit.amount,
        tokenDetails[chain][selectedAsset].decimals
      ),
      tokenAddress: selectedAsset,
    })

    if (selectedAsset !== address) {
      setIsConversion(true)
      return
    }

    setIsConversion(false)
  }

  const handleDepositInput = (depositAmount: string) => {
    setSingleDepositAmount({
      ...singleDeposit,
      amount: preventOverflow(depositAmount, decimals),
    })
  }

  useEffect(() => {
    setSingleDepositAmount({
      amount: '',
      tokenAddress: selectedVaultAsset,
    })
  }, [smartVaultData])

  useEffect(() => {
    if (singleDeposit.tokenAddress !== address) {
      setIsConversion(true)
      return
    }

    setIsConversion(false)
  }, [singleDeposit])

  return (
    <Stack width='100%' gap={(theme) => theme.spacing(2)}>
      <AssetsSelect
        selectedTokenAddress={singleDeposit.tokenAddress}
        handleChangeSelectedToken={handleChangeAsset}
        nativeToken={address}
      />
      <Stack gap={(theme) => theme.spacing(2)}>
        <TransactionInput
          inputLabel={t(
            'components.organisms.smartVaults.collapsibleRow.deposit.standard.singleDepositAmount.transactionInput.label'
          )}
          tokenId={singleDeposit.tokenAddress}
          value={singleDeposit.amount}
          setValue={handleDepositInput}
          showInputInfoLabel={!belowTabletBreakpoint}
          maxButtonWidth='65px'
        />
      </Stack>
    </Stack>
  )
}

export default SingleAssetDepositInput
