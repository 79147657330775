import { HeadCell } from '@/types/table'

export const incentivesHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    align: 'left',
  },
  {
    id: 'apy',
    numeric: true,
    label: 'Total APY',
    tableHelperBottom: 'after fees',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'value',
    numeric: true,
    label: 'Value',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'remainingIncentives',
    numeric: true,
    label: 'Remaining incentives',
    align: 'right',
    notSortable: true,
  },
]

export const tokensHeadCells: HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    label: 'Token',
    align: 'left',
  },
  {
    id: 'tokenName',
    numeric: true,
    label: '',
    align: 'left',
    notSortable: true,
  },
  {
    id: 'remainingTokens',
    numeric: true,
    label: 'Remaining Tokens',
    align: 'left',
    tableHelperBottom: 'to be Emitted',
  },
  {
    id: 'timeLeft',
    numeric: true,
    label: 'Time Left',
    align: 'left',
    tableHelperBottom: 'Until Completion',
  },
  {
    id: 'contract',
    numeric: true,
    label: 'Contract Address',
    align: 'left',
    notSortable: true,
  },
]

export enum IncentivesPage {
  DETAILS = 'details',
  ADD = 'add',
  DETAILS_TOKEN = 'detailsToken',
  REINCENTIVIZE = 'reincentivize',
  EXTEND_DURATION = 'extendRewards',
  INCREASE_AMOUNT = 'increaseAmount',
}

export const tokens = [
  {
    symbol: 'DAI',
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
  },
  {
    symbol: 'USDC',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  },
  {
    symbol: 'USDT',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  },
  {
    symbol: 'WETH',
    address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  },
  {
    symbol: 'SPOOL',
    address: '0x40803cea2b2a32bda1be61d3604af6a814e70976',
  },
]

export const EMPTY_ROW_COUNT = 5
