import WarningIcon from '@mui/icons-material/Warning'
import { Box, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useStrategies from '@/hooks/strategies/useStrategies'

import { MainContainer } from '@/components/atoms/MainContainer'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import BackButton from '@/components/molecules/BackButton'
import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'

import { currency } from '@/constants/currency'
import { BaseRoutesPaths, BaseRoutesPathsWithChain } from '@/constants/routes'
import { strategiesDescriptions } from '@/mockdata/create-vault.json'
import { formatUSD } from '@/utils/formats'

const StrategyTitle = () => {
  const { chain } = useConnectionState()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const navigate = useNavigate()
  const { strategyId } = useParams()

  const t = useLocale()

  const handleClick = () => {
    navigate(BaseRoutesPathsWithChain(chain, BaseRoutesPaths.STRATEGIES))
  }

  const { isLoading, strategies } = useStrategies()

  const currentStrategy = strategies?.find(
    (strategy) => strategy?.address === strategyId
  )

  const info =
    strategiesDescriptions[
      currentStrategy?.name as keyof typeof strategiesDescriptions
    ]

  return (
    <Box
      width='100%'
      display='flex'
      justifyContent='center'
      paddingTop='1rem'
      mb={belowPhabletBreakpoint ? '' : '1rem'}
      alignItems='start'
    >
      <MainContainer mb={belowPhabletBreakpoint ? '' : '1rem'}>
        <>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems={belowPhabletBreakpoint ? 'start' : 'center'}
            flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
          >
            <BackButton handleClick={handleClick} />
            {isLoading ? (
              <DataLoading loadingText='Loading Strategy' />
            ) : currentStrategy && !!Object.values(currentStrategy).length ? (
              <Box
                display='flex'
                alignItems='center'
                gap='1rem'
                ml={belowPhabletBreakpoint ? '0.2rem' : ''}
                mt={belowPhabletBreakpoint ? '1rem' : ''}
              >
                {currentStrategy.assetGroup &&
                  currentStrategy.assetGroup?.assetGroupTokens.map(
                    ({ address }, index) => (
                      <Fragment key={index}>
                        <Box
                          display='flex'
                          sx={{
                            position: 'relative',
                            ml: index !== 0 ? '-12px' : '0',
                            transform: 'scale(2)',
                          }}
                        >
                          {mapAssetTokenIcon(chain, address ?? '')}
                        </Box>
                      </Fragment>
                    )
                  )}
                <Box display='flex' flexDirection='column'>
                  <Typography variant='body1' fontSize='24px'>
                    {currentStrategy.name}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                my='2rem'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <WarningIcon
                  sx={{ height: '90px', width: '90px' }}
                  color='primary'
                />
                <DataMissing text='Strategy not found' />
              </Box>
            )}
            <Box visibility='hidden' width='52px' />
          </Box>
          {currentStrategy && !!Object.values(currentStrategy).length && (
            <>
              <Box
                display='flex'
                justifyContent={belowPhabletBreakpoint ? 'start' : 'center'}
                alignItems='center'
                gap={4}
                marginY='1rem'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  gap={0.5}
                >
                  <Typography
                    variant='caption'
                    textAlign={belowPhabletBreakpoint ? 'start' : 'center'}
                  >
                    {t(
                      'pages.strategies.strategyDetailsPage.header.metrics.apy'
                    )}
                  </Typography>
                  <Typography
                    variant='h6'
                    textAlign={belowPhabletBreakpoint ? 'start' : 'center'}
                  >
                    {formatUSD(currentStrategy.apy)}%
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  gap={0.5}
                >
                  <Typography
                    variant='caption'
                    textAlign={belowPhabletBreakpoint ? 'start' : 'center'}
                  >
                    {t(
                      'pages.strategies.strategyDetailsPage.header.metrics.tvr'
                    )}
                  </Typography>
                  <Typography
                    variant='h6'
                    textAlign={belowPhabletBreakpoint ? 'start' : 'center'}
                  >
                    {currency} {formatUSD(currentStrategy.balanceUSD)}
                  </Typography>
                </Box>
                {currentStrategy.auditor && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    gap={0.5}
                  >
                    <Typography variant='caption' textAlign='center'>
                      {t(
                        'pages.strategies.strategyDetailsPage.header.metrics.auditor'
                      )}
                    </Typography>
                    <Box display='flex' alignContent='center'>
                      <svg width='30' height='30'>
                        <image
                          xlinkHref={currentStrategy.logo}
                          width='30'
                          height='30'
                        />
                      </svg>
                      <Typography variant='h6' textAlign='center'>
                        {currentStrategy.auditor}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              {info && (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignContent='center'
                >
                  <Typography
                    p={1}
                    textAlign='center'
                    variant='subtitle2'
                    maxWidth='600px'
                    color='text.secondary'
                  >
                    {info}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </>
      </MainContainer>
    </Box>
  )
}

export default StrategyTitle
