const LinkedIn = () => {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 2.5C4.5 3.6 3.8 4.5 2.5 4.5C1.3 4.5 0.5 3.6 0.5 2.6C0.5 1.5 1.3 0.5 2.5 0.5C3.7 0.5 4.5 1.4 4.5 2.5ZM0.5 18.5H4.5V5.5H0.5V18.5ZM14.1 5.7C12 5.7 10.8 6.9 10.3 7.7H10.2L10 6H6.4C6.4 7.1 6.5 8.4 6.5 9.9V18.5H10.5V11.4C10.5 11 10.5 10.7 10.6 10.4C10.9 9.7 11.4 8.8 12.5 8.8C13.9 8.8 14.5 10 14.5 11.6V18.5H18.5V11.1C18.5 7.4 16.6 5.7 14.1 5.7Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LinkedIn
