import useSWR from 'swr'

import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useInFocus from '@/hooks/utils/useInFocus'

import { NATIVE_TOKEN, WRAPPED_TOKEN_ADDRESS } from '@/constants/tokens'

const usePriceFeed = (token: string) => {
  const { sdk } = useAppState()
  const { chain } = useConnectionState()

  const { isInFocus } = useInFocus()

  const { data, error } = useSWR(
    chain && token ? ['priceFeed', token] : null,
    () =>
      sdk.priceFeed.getTokenPrice(
        token === NATIVE_TOKEN ? WRAPPED_TOKEN_ADDRESS[chain] : token
      ),
    {
      isPaused: () => isInFocus,
      refreshInterval: 30000,
      dedupingInterval: 10000,
      focusThrottleInterval: 5000,
      refreshWhenHidden: false,
    }
  )

  return {
    priceFeed: data,
    isLoading: !error && !data,
    error,
  }
}

export default usePriceFeed
