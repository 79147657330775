import { VaultsListAction } from '@/constants/smartVault'

export interface SmartVaultRowState {
  vaultRowAction: VaultsListAction
}

export interface SmartVaultRowContextProps extends SmartVaultRowState {
  setVaultRowAction: (vaultRowAction: VaultsListAction) => void
}

export enum SmartVaultRowActionTypes {
  SET_VAULT_ROW_ACTION = 'SET_VAULT_ROW_ACTION',
}

export type SmartVaultRowAction = {
  type: SmartVaultRowActionTypes.SET_VAULT_ROW_ACTION
  payload: VaultsListAction
}
