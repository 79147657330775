import { formatEther } from '@ethersproject/units'
import { Box, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useUserBalance from '@/hooks/web3/useUserBalance'

import SectionItem from '@/components/molecules/SectionItem'

import { IS_TESTNET } from '@/config/sdk'
import { Token } from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'
//import {useEffect} from "react";

const SpoolBalance = () => {
  const t = useLocale()

  const YLAY_TOKEN_ADDRESS = IS_TESTNET
    ? '0xb370f95a84d0a8d1150c42c44894c0ba457f18b6'
    : '0xaee5913ffd19dbca4fd1ef6f3925ed0414407d37'

  const { balance /*, updateUserBalance, isLoading, isValidating*/ } =
    useUserBalance(YLAY_TOKEN_ADDRESS)

  /*useEffect(() => {
    console.log('Is loading:', isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log('Is validating:', isValidating);
  }, [isValidating]);*/

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.overview.sectionItems.tokensInWallet.title'
      )}
    >
      <Box display='flex' alignItems='baseline'>
        <Typography variant='h6'>{formatUSD(formatEther(balance))}</Typography>
        <Typography variant='body1'>&nbsp;{Token.YLAY}</Typography>
      </Box>
      {/* <AmountTextUSD amount={formatEther(balance) ?? '0'} /> */}
    </SectionItem>
  )
}

export default SpoolBalance
