import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Tooltip, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const SwapSlippage = () => {
  const t = useLocale()

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Typography color='text.secondary' mr='0.2rem'>
          Slippage
        </Typography>
        <Box display='flex' alignItems='center'>
          <Tooltip title={t('pages.smartVaults.vaultDetailsPage.slipageInfo')}>
            <InfoOutlinedIcon color='disabled' fontSize='small' />
          </Tooltip>
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        <Typography>1%</Typography>
      </Box>
    </Box>
  )
}

export default SwapSlippage
