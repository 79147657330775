import { HistoricalWithdawal, SortOrder } from '@solidant/spool-v2-fe-lib'

import { SortType } from '@/types/table'

export interface WithdrawState {
  withdrawReceiptSort: SortType<HistoricalWithdawal>
  withdrawalAmount: string
}

export interface WithdrawContextProps extends WithdrawState {
  handleSortClick: (
    key: keyof HistoricalWithdawal,
    withdrawReceiptSort: SortType<HistoricalWithdawal>
  ) => void
  setWithdrawalAmount: (withdrawalAmount: string) => void
}

export enum WithdrawActionTypes {
  SET_WITHDRAWAL_RECEIPT_SORT = 'SET_WITHDRAW_RECEIPT_SORT',
  CHANGE_WITHDRAWAL_SORT_DIRECTION = 'CHANGE_WITHDRAW_SORT_DIRECTION',
  SET_WITHDRAWAL_AMOUNT = 'SET_WITHDRAWAL_AMOUNT',
}

export type WithdrawAction =
  | {
      type: WithdrawActionTypes.SET_WITHDRAWAL_RECEIPT_SORT
      payload: {
        key: keyof HistoricalWithdawal
        direction: SortOrder
      }
    }
  | {
      type: WithdrawActionTypes.CHANGE_WITHDRAWAL_SORT_DIRECTION
    }
  | {
      type: WithdrawActionTypes.SET_WITHDRAWAL_AMOUNT
      payload: string
    }
