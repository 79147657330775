import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

interface NavigationSectionProps {
  title: string
  description: string
  toSectionText: string
  toSection: string
  logo: JSX.Element
  openInNewTab?: boolean
}

const NavigationSection: React.FC<NavigationSectionProps> = ({
  title,
  description,
  toSectionText,
  toSection,
  logo,
  openInNewTab = false,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  return (
    <Box
      border='1px solid rgba(0, 0, 0, 0.12)'
      display={belowPhabletBreakpoint ? 'flex' : 'grid'}
      flexDirection={belowPhabletBreakpoint ? 'column-reverse' : 'row'}
      justifyContent={belowPhabletBreakpoint ? '' : 'end'}
      position='relative'
      gridTemplateColumns='2.5fr 1fr'
      padding='1rem'
      pl='1.5rem'
      py='2rem'
      alignItems={belowPhabletBreakpoint ? 'start' : 'center'}
      borderRadius='4px'
      overflow='hidden'
      sx={{
        backgroundImage:
          'linear-gradient(90deg, rgba(255,255,255,1) 40%, rgba(242,248,251,1) 100%)',
      }}
    >
      <Box
        display={belowPhabletBreakpoint ? 'flex' : 'grid'}
        flexDirection='column'
        gridTemplateRows='20px 70px 30px'
        gap='0.5rem'
      >
        <Typography fontWeight={500}>{title}</Typography>
        <Typography fontSize='14px' color='text.secondary'>
          {description}
        </Typography>
        <Box
          mt={belowPhabletBreakpoint ? '1rem' : ''}
          component={Link}
          color='primary'
          to={toSection}
          display='flex'
          sx={{
            '&:hover': {
              color: 'primary',
            },
          }}
          target={openInNewTab ? '_blank' : '_self'}
        >
          <Typography fontSize='14px' color='primary'>
            {toSectionText}
          </Typography>
          {openInNewTab ? (
            <ArrowOutwardIcon fontSize='small' color='primary' />
          ) : (
            <ArrowForwardIcon fontSize='small' color='primary' />
          )}
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='flex-end'
        marginRight='1rem'
        position='relative'
        overflow='hidden'
        zIndex='4'
        mb={belowPhabletBreakpoint ? '1rem' : ''}
        sx={{
          width: `${belowPhabletBreakpoint ? '24px' : '100%'}`,
        }}
      >
        {logo}
      </Box>
    </Box>
  )
}

export default NavigationSection
