import { HistoricalWithdawal } from '@solidant/spool-v2-fe-lib'
import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

import { MUTATE_SLEEP_DURATION } from '@/constants'
import { sleep } from '@/utils/web3'

const usePendingWithdrawals = (vaultId: string, userAddress?: string) => {
  const { sdk } = useContext(AppContext)

  const { data, error, mutate } = useSWR(
    sdk && userAddress && vaultId
      ? ['smartVaultHistoricalWithdrawals', vaultId, userAddress]
      : null,
    async () => await sdk.smartVault.getPendingWithdrawals(userAddress, vaultId)
  )

  const updatePendingReceipts = async (
    previousValue?: HistoricalWithdawal[]
  ) => {
    if (!previousValue) {
      return mutate()
    }

    let retries = 0

    do {
      const currentValue = await mutate()

      if (currentValue?.length !== previousValue?.length) {
        break
      }

      retries++
      await sleep(MUTATE_SLEEP_DURATION)
    } while (retries < 5)
  }

  if (error) {
    console.error(error)
  }

  return {
    pendingWithdrawals: data,
    isLoading: !error && !data,
    updatePendingReceipts,
    error,
  }
}

export default usePendingWithdrawals
