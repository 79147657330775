import { useMemo } from 'react'

import { eip6963Connection } from '@/connection/connections'
import { EIP6963_PROVIDER_MANAGER } from '@/connection/providers/eip6963/eip6963.provider'
import { connections } from '@/connection/utils'
import { ConnectionType } from '@/constants/web3'

import { Connection } from '@/types/connectors'

function useEIP6963Connections() {
  const eip6963Injectors = EIP6963_PROVIDER_MANAGER.list

  return useMemo(() => {
    const eip6963Connections = eip6963Injectors.flatMap((injector) => {
      return eip6963Connection.wrap(injector.info) ?? []
    })

    // Displays ui to activate window.ethereum for edge-case where we detect window.ethereum !== one of the eip6963 providers
    const showDeprecatedMessage = false
    // eip6963Connections.length > 0 &&
    // shouldUseDeprecatedInjector(eip6963Injectors);

    return { eip6963Connections, showDeprecatedMessage }
  }, [eip6963Injectors])
}

function mergeConnections(
  connections: Connection[],
  eip6963Connections: Connection[]
) {
  const hasEip6963Connections = eip6963Connections.length > 0
  const displayedConnections = connections.filter((c) => c.shouldDisplay())

  if (!hasEip6963Connections) return displayedConnections

  const allConnections = [
    ...eip6963Connections,
    ...displayedConnections.filter((c) => c.type !== ConnectionType.INJECTED),
  ]
  // By default, injected options should appear second in the list (below Uniswap wallet)
  // allConnections.splice(1, 0, ...eip6963Connections);

  return allConnections
}

// return () => useMemo due to useOrderedConnections not being a react function
export function useOrderedConnections() {
  const { eip6963Connections, showDeprecatedMessage } = useEIP6963Connections()
  const orderedConnections = useMemo(() => {
    const allConnections = mergeConnections(connections, eip6963Connections)
    return allConnections
  }, [eip6963Connections])

  return { orderedConnections, showDeprecatedMessage }
}
