import { Box, Tab, TabProps, Tabs } from '@mui/material'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useWindowScroll from '@/hooks/utils/useWindowScroll'

import { gaIds } from '@/constants/googleAnalyticIDs'
import { SUBMENU_ITEMS } from '@/constants/subMenu'

const LinkTab: React.ComponentType<TabProps & { to: string }> =
  Tab as React.ComponentType<TabProps & { to: string }>

const Submenu = () => {
  const { activeMenuItem, activeTab } = useTopMenuState()
  const { chain } = useConnectionState()

  const { windowScrollY } = useWindowScroll()

  const { belowTabletBreakpoint, belowMobileBreakpoint } =
    useBreakpointDetection()

  const showSubmenu = useMemo(
    () => !!SUBMENU_ITEMS(chain)[activeMenuItem],
    [activeMenuItem]
  )

  return (
    <>
      {/*TODO: change for TOP_MENU_ITEMS?*/}
      {showSubmenu && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{
            borderTop: belowTabletBreakpoint
              ? 'none'
              : '1.5px solid rgba(33, 35, 34, 0.12)',
            opacity: `${windowScrollY !== 0 ? '0.8' : '1'}`,
          }}
        >
          <Box display='flex' sx={{ width: '100%', maxWidth: '1250px' }}>
            <Tabs
              value={activeTab}
              sx={{
                width: '100%',
                '.MuiTabs-flexContainer': {
                  justifyContent: `${
                    belowTabletBreakpoint ? 'space-evenly' : ''
                  } `,
                },
                height: belowTabletBreakpoint ? '72px' : 'auto',
              }}
              indicatorColor='primary'
            >
              {SUBMENU_ITEMS(chain)[activeMenuItem].map(
                ({ title, url }, index) => (
                  <LinkTab
                    id={gaIds.submenuNav}
                    sx={{
                      textTransform: 'none',
                      opacity: '1',
                      '.MuiTab-iconWrapper': {
                        marginLeft: '0',
                        color: 'gray',
                      },
                      '&.MuiButtonBase-root': {
                        padding: '12 16px',
                      },
                      fontSize: belowMobileBreakpoint ? '14px' : '16px',
                      maxWidth: belowTabletBreakpoint ? '120px' : '360px',
                    }}
                    component={Link}
                    to={url}
                    key={index}
                    value={title}
                    label={title}
                  />
                )
              )}
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Submenu
