import { Box, Typography } from '@mui/material'

interface DataMissingProps {
  text: string
  icon?: boolean
}

const DataMissing: React.FC<DataMissingProps> = ({ text }) => (
  <Box display='flex' justifyContent='center'>
    <Typography
      variant='subtitle1'
      align='center'
      //sx={{ maxWidth: '230px', wordWrap: 'break-word' }}
    >
      {text}
    </Typography>
  </Box>
)

export default DataMissing
