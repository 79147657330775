import { useCallback, useReducer } from 'react'

import { initialState, ModalManager } from '@/store/modal/modals.context'
import { modalsReducer } from '@/store/modal/modals.reducer'
import {
  Modals,
  ModalsActionTypes,
  ModalType,
} from '@/store/modal/modals.types'

import { RCNode } from '@/types/index'

// Modal Context Provider
export const ModalProvider = ({ children }: RCNode): JSX.Element => {
  const [modals, dispatch] = useReducer(modalsReducer, initialState)

  const openModal = useCallback((id: Modals) => {
    dispatch({
      type: ModalsActionTypes.OPEN_MODAL,
      payload: id,
    })
  }, [])

  const closeModal = useCallback((id: Modals | string) => {
    dispatch({
      type: ModalsActionTypes.CLOSE_MODAL,
      payload: id,
    })
  }, [])

  const setModalType = useCallback((modalType: ModalType) => {
    dispatch({
      type: ModalsActionTypes.SET_MODAL_TYPE,
      payload: modalType,
    })
  }, [])

  const minimizeModal = useCallback((id: Modals) => {
    dispatch({
      type: ModalsActionTypes.MINIMIZE_MODAL,
      payload: id,
    })
  }, [])

  return (
    <ModalManager.Provider
      value={{ openModal, closeModal, setModalType, minimizeModal, modals }}
    >
      {children}
    </ModalManager.Provider>
  )
}
