import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import tenderly from '@/config/tenderly/arbitrum.contracts.json'
import { tokenAddresses } from '@/constants/tokens'

export const ARBITRUM_TENDERLY_CONFIG = {
  RPC_PROVIDER:
    'https://virtual.arbitrum.rpc.tenderly.co/57f27ded-b464-4a7d-a897-16c59c64a9f2',
  CORE_SUBGRAPH:
    'https://arb.graph.dev.spool.fi/subgraphs/name/spool/core-v2-arbitrum/',
  API_URL: 'https://jobs.dev.spool.fi/graphql',
  FAST_WITHDRAW_API_URL: 'https://fastwithdraw.dev.spool.fi/',
  STAKING_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spoolfi-staking/api',
  ANALYTICS: 'https://analytics.spool.fi',
  SNAPSHOT_SUBGRAPH: 'https://hub.snapshot.org/graphql',
  SNAPSHOT_HUB: 'https://hub.snapshot.org',
  SNAPSHOT_SPACE: 'egov.spool.eth',
  REWARDS_URL: 'https://rewards.dev.spool.fi/arbitrum_t',
  PRICE_FEED: 'https://pricefeed.v2.spool.fi/',
  SMART_VAULT_MANAGER: tenderly.SmartVaultManager.proxy,
  DEPOSIT_SWAP_MANAGER: tenderly.DepositSwap.proxy,
  DEPOSIT_MANAGER: tenderly.DepositManager.proxy,
  SMART_VAULT_FACTORY: tenderly.SmartVaultFactory,
  SMART_VAULT_FACTORY_HPF: tenderly.SmartVaultFactoryHpf,
  REWARD_MANAGER: tenderly.RewardManager.proxy,
  YELAY_STAKING: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
  SYLAY: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
  SPOOL_LENS: tenderly.SpoolLens.proxy,
  YELAY_TOKEN: tokenAddresses[SupportedNetworks.ARBITRUM]['SPOOL'],
  LINEAR_ALLOCATION_PROVIDER: tenderly.LinearAllocationProvider.proxy,
  ONE_INCH_ROUTER: '0x111111125421ca6dc452d289314280a0f8842a65',
  SWAPPER: tenderly.Swapper.proxy,
  ALLOW_LIST_GUARD: tenderly.AllowlistGuard,
  IGUARD_MANAGER: tenderly.GuardManager.proxy,
  API_CHAIN_NAME: 'arbTenderly',
  STRATEGY_REGISTRY: tenderly.StrategyRegistry.proxy,
  SPOOL_ACCESS_CONTROL: tenderly.SpoolAccessControl.proxy,
  META_VAULT_FACTORY: tenderly.MetaVaultFactory,
}
