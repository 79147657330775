import { useWeb3React } from '@web3-react/core'
import dayjs from 'dayjs'

import useAppState from '@/hooks/context/useAppState'
import useModalState from '@/hooks/context/useModalState'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import {
  CLAIM_INCENTIVIZED_REWARDS_TIME,
  REWARDS_SYNC_DURING_CLAIM,
} from '@/constants/smartVault'

const useClaimIncentivizedRewards = (vaultAddress: string) => {
  const { openModal, setModalType } = useModalState()
  const { sdk } = useAppState()
  const { account } = useWeb3React()
  const { nextRewardsSyncTime, setRewardsClaimedVault } =
    useSmartVaultsTableState()

  const claimIncentivizedRewards = async () => {
    try {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.ClaimToken,
        },
      })
      openModal(Modals.ACTION_MODAL)

      const tx = await sdk.smartVault.claimRewards(vaultAddress, account)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.ClaimToken,
        },
      })
      await tx.wait()

      const currTime = dayjs().unix()

      localStorage.setItem(
        CLAIM_INCENTIVIZED_REWARDS_TIME + vaultAddress,
        currTime.toString()
      )

      localStorage.setItem(
        REWARDS_SYNC_DURING_CLAIM + vaultAddress,
        nextRewardsSyncTime.toString()
      )

      setRewardsClaimedVault(vaultAddress)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.ClaimToken,
        },
      })
    } catch (error) {
      console.error(error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.ClaimToken,
        },
      })
    }
  }

  return claimIncentivizedRewards
}

export default useClaimIncentivizedRewards
