const t = getLocale()
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import getLocale from '@/locales/getLocale'

import { Routes } from '@/constants/routes'

export type SubmenuItem =
  | {
      title: string
      newTab?: boolean
      url: string
      mainnetOnly?: boolean
    }
  | undefined

export const SUBMENU_ITEMS: (
  chain: SupportedNetworks
) => Record<string, SubmenuItem[]> = (chain: SupportedNetworks) => {
  return {
    [t('components.organisms.topMenu.b2c.menuItems.myPortfolio.title')]: [
      {
        title: t(
          'components.organisms.topMenu.b2c.menuItems.myPortfolio.subMenu.depositDashboard.title'
        ),
        url: Routes(chain).dashboard.investor.url,
      },
      {
        title: t(
          'components.organisms.topMenu.b2c.menuItems.myPortfolio.subMenu.transactionHistory.title'
        ),
        url: Routes(chain).dashboard.history.url,
      },
    ],
  }
}
