import { Box, Typography } from '@mui/material'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import getLocale from '@/locales/getLocale'

import { mapTransactionStatus } from '@/components/atoms/ActionModalStatus'

import { ActionModalType, TransactionStatus } from '@/store/modal/modals.types'

import { PATH_TO_MESSAGE } from '@/constants/actionModal'

interface ModalTextProps {
  modalType: ActionModalType
  textId?: string
  isMinimized?: boolean
}

const ModalText: React.FC<ModalTextProps> = ({
  modalType,
  textId,
  isMinimized,
}) => {
  const t = getLocale()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { transactionStatus, transactionType } = modalType

  const { icon } = mapTransactionStatus(transactionStatus)
  const message =
    transactionStatus === TransactionStatus.PendingSignature
      ? t('components.organisms.actionModal.config.pendingSignature.title')
      : PATH_TO_MESSAGE[transactionStatus][transactionType]

  return (
    <Box
      display='flex'
      alignContent='center'
      flexDirection={isMinimized ? 'row' : 'column'}
      gap={1}
    >
      <Box
        alignSelf='center'
        sx={{
          width: isMinimized ? '24px' : '48px',
          height: isMinimized ? '24px' : '48px',
          mx: isMinimized ? 0.5 : 0,
          my: isMinimized ? 1 : 0,
        }}
      >
        {icon}
      </Box>
      <Typography
        alignSelf='center'
        textAlign='center'
        id={textId}
        variant='body1'
        noWrap={belowPhabletBreakpoint ? false : true}
      >
        {message}
      </Typography>
    </Box>
  )
}

export default ModalText
