import { Stack } from '@mui/material'
import { useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import ValidNotification from '@/components/molecules/informational/ValidNotification'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import BottomButtons from '@/components/organisms/CreateVault/Guards/Modals/BottomButtons'
import SVTTable from '@/components/organisms/CreateVault/Guards/Modals/SVTModal/SVTTable'

interface SVTModalProps {
  id: string
  isTimePeriod?: boolean
}

const SVTModal: React.FC<SVTModalProps> = ({ id, isTimePeriod }) => {
  const { selectedGuards, setGuardsTime, setGuardsBool, setGuardsConfigured } =
    useCreateVaultState()
  const { closeModal } = useModalState()
  const [isChecked, setIsChecked] = useState(selectedGuards[id]?.bool || false)
  const [timePeriod, setTimePeriod] = useState(selectedGuards[id]?.time || 0)

  const t = useLocale()

  const handleSave = () => {
    if (isTimePeriod) {
      setGuardsTime(id, Number(timePeriod))
    } else {
      setGuardsBool(id, isChecked)
    }
    closeModal(id)
  }

  const handleTest = () => {
    if (isTimePeriod) {
      setGuardsTime(id, Number(timePeriod))
      setGuardsConfigured(id, true)
    } else {
      setGuardsBool(id, isChecked)
      setGuardsConfigured(id, isChecked ? true : undefined)
    }
  }

  return (
    <>
      <Section>
        <Stack display='flex' width='100%' padding={2} gap={2}>
          {selectedGuards[id]?.configured && <ValidNotification />}
          <SectionColumn
            title={t(
              'components.organisms.createVault.guards.modals.SVTModal.settings.title'
            )}
            noPadding
          >
            <SVTTable
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              timePeriod={timePeriod}
              setTimePeriod={setTimePeriod}
              disabled={selectedGuards[id]?.configured}
              isTimePeriod={isTimePeriod}
              editConfigurationMode={!selectedGuards[id]?.configured}
            />
          </SectionColumn>
        </Stack>
      </Section>
      <BottomButtons
        handleSave={handleSave}
        handleTest={handleTest}
        closeModal={() => closeModal(id)}
        configured={selectedGuards[id]?.configured}
        handleEditConfiguration={() => setGuardsConfigured(id, false)}
        disabled={isTimePeriod && timePeriod <= 0}
      />
    </>
  )
}

export default SVTModal
