import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import ARBITRUM_ASSETS from '@/constants/assets/arbitrumAssets.json'
import ETHEREUM_ASSETS from '@/constants/assets/ethereumAssets.json'
import SEPOLIA_ASSETS from '@/constants/assets/sepoliaAssets.json'

import { AssetsConstant } from '@/types/create-vault'

// ARB
const assetConfig: { [chainId: number]: AssetsConstant } = {
  [SupportedNetworks.ETHEREUM]: ETHEREUM_ASSETS as AssetsConstant,
  [SupportedNetworks.ARBITRUM]: ARBITRUM_ASSETS as AssetsConstant,
  [SupportedNetworks.SEPOLIA]: SEPOLIA_ASSETS as AssetsConstant,
}

export const mapAssetConfig = (chainId: SupportedNetworks | undefined) => {
  if (chainId) return assetConfig[chainId]
}
