import { GuardParamType, GuardRequestType } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { constants } from 'ethers'

import useAppState from '@/hooks/context/useAppState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useModalState from '@/hooks/context/useModalState'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { SC_PERCENTAGE_MULTIPLIER } from '@/constants'
import { GUARDS_ID } from '@/constants/guards'

const useCreateVault = () => {
  const { openModal, setModalType } = useModalState()

  const { account } = useWeb3React()

  const { sdkExternal } = useAppState()

  const {
    vaultName,
    svtSymbol,
    selectedRiskModel,
    selectedStrategies,
    selectedAllocationProvider,
    selectedAssetGroupId,
    selectedRiskScore,
    selectedManagementFee,
    selectedPerformanceFee,
    selectedGuards,
    resetAllSelectedFields,
    validateVaultName,
    validateSvtSymbol,
  } = useCreateVaultState()

  const handleCreateVault = async () => {
    const strategies = selectedStrategies.map((strategy) => strategy.address)

    const vaultNameValidation = validateVaultName(vaultName)
    const svtSymbolValidation = validateSvtSymbol(svtSymbol)

    if (!vaultNameValidation || !svtSymbolValidation) return

    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.CreateVault,
        },
      })

      //const vaultGuards = sdk.guards.newGuardBuilder()

      const vaultGuards = sdkExternal.mutations.guards.getNewGuardBuilder()

      // if (selectedGuards[GUARDS_ID.ASSET_LIMIT]?.assetAmount) {
      //   selectedGuards[GUARDS_ID.ASSET_LIMIT].assetAmount.map(
      //     ({ token, requirement, value }) => {
      //       vaultGuards.addAssetGuard(
      //         GuardRequestType.Deposit,
      //         token,
      //         GuardParamType.Executor,
      //         GUARDS_ASSET_LIMIT_OPERATOR[requirement],
      //         parseUnits(value, tokenDetails[token].decimals)
      //       )
      //     }
      //   )
      // }

      if (selectedGuards[GUARDS_ID.NFT_DEPOSITORS]) {
        selectedGuards[GUARDS_ID.NFT_DEPOSITORS].whitelist.map(
          ({ address }) => {
            vaultGuards.addAssetGuard(
              GuardRequestType.Deposit,
              address,
              GuardParamType.Executor,
              '>=',
              '1'
            )
          }
        )
      }

      if (selectedGuards[GUARDS_ID.NFT_BENEFICIARIES]) {
        selectedGuards[GUARDS_ID.NFT_BENEFICIARIES].whitelist.map(
          ({ address }) => {
            vaultGuards.addAssetGuard(
              GuardRequestType.Deposit,
              address,
              GuardParamType.Receiver,
              '>=',
              '1'
            )
          }
        )
      }

      if (
        selectedGuards[GUARDS_ID.SVT_NONTRANSFERABLE] &&
        selectedGuards[GUARDS_ID.SVT_NONTRANSFERABLE].checked
      ) {
        vaultGuards.addLockActionGuard(GuardRequestType.TransferSVTs)
      }

      // If it's a 1 strat vault, we dont need riskTolerance, riskProvider, allocationProvider
      const oneStrategyVault = strategies.length === 1

      const isHPFVaultDeployer =
        await sdkExternal.views.userInfo.hasHPFVaultDeployerRole(account || '')

      const guardParams = vaultGuards.buildGuardParameters()

      const tx = await sdkExternal.mutations.smartVaults.deploySmartVault(
        {
          smartVaultName: vaultName,
          svtSymbol: svtSymbol ? svtSymbol : vaultName,
          baseURI: '',
          assetGroupId: selectedAssetGroupId,
          strategies: strategies,
          strategyAllocation: oneStrategyVault ? 10000 : 0,
          riskTolerance: oneStrategyVault ? 0 : selectedRiskScore,
          riskProvider: oneStrategyVault
            ? constants.AddressZero
            : selectedRiskModel,
          allocationProvider: oneStrategyVault
            ? constants.AddressZero
            : selectedAllocationProvider,
          actions: [],
          actionRequestTypes: [],
          guards: guardParams[0],
          guardRequestTypes: guardParams[1],
          managementFeePct: selectedManagementFee * SC_PERCENTAGE_MULTIPLIER,
          depositFeePct: 0,
          performanceFeePct: selectedPerformanceFee * SC_PERCENTAGE_MULTIPLIER,
          allowRedeemFor: true,
        },
        isHPFVaultDeployer && selectedPerformanceFee > 20
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.CreateVault,
        },
      })

      await tx.wait()

      resetAllSelectedFields()
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.CreateVault,
        },
      })
    } catch (err) {
      console.error(err)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.CreateVault,
        },
      })
    }
  }

  return handleCreateVault
}

export default useCreateVault
