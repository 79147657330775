import { useMediaQuery, useTheme } from '@mui/material'

const useBreakpointDetection = () => {
  const theme = useTheme()

  const belowMobileBreakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const belowPhabletBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))
  const belowTabletBreakpoint = useMediaQuery(theme.breakpoints.down('md'))
  const belowLaptopBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))
  const belowDesktopBreakpoint = useMediaQuery(theme.breakpoints.down('xxl'))

  return {
    belowMobileBreakpoint,
    belowPhabletBreakpoint,
    belowTabletBreakpoint,
    belowLaptopBreakpoint,
    belowDesktopBreakpoint,
  }
}

export default useBreakpointDetection
