import { useContext } from 'react'
import useSWR from 'swr'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'

import { AppContext } from '@/store/app/app.context'

const useStrategiesAllocation = () => {
  const { sdk } = useContext(AppContext)

  const { selectedStrategies, selectedAllocationProvider, selectedRiskModel } =
    useCreateVaultState()

  const strategies = selectedStrategies.map(({ address }) => address)

  const { data, error, isLoading } = useSWR(
    sdk
      ? [
          'strategiesAllocation',
          selectedStrategies,
          selectedRiskModel,
          selectedAllocationProvider,
        ]
      : null,
    async () => {
      return await sdk.riskModels.getStrategyAllocation(
        strategies,
        selectedRiskModel,
        selectedAllocationProvider
      )
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    strategiesAllocation: data,
    isLoading,
    error,
  }
}

export default useStrategiesAllocation
