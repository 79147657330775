import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'

interface SpoolSelectProps extends SelectProps {
  label: string
  value: string | number
  handleChange: (event: SelectChangeEvent<unknown>) => void
  children: React.ReactNode
}

const SpoolSelect: React.FC<SpoolSelectProps> = ({
  label,
  value,
  handleChange,
  children,
  ...rest
}) => {
  return (
    <FormControl fullWidth size='small'>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={handleChange}
        {...rest}
        MenuProps={{
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              marginTop: '8px',
            },
          },
        }}
        sx={{
          '& .MuiSelect-select > div': {
            overflow: 'hidden',
          },
          '& .MuiSelect-select p': {
            fontSize: 16,
            fontWeight: 500,
          },
          '& .MuiSelect-select .allocation-provider-icon': {
            marginRight: '0.5rem',
          },
          '& .MuiSelect-select .allocation-provider-type': {
            fontSize: 14,
            fontWeight: 500,
          },
          '& .MuiSelect-select .allocation-provider-description': {
            fontSize: 12,
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SpoolSelect
