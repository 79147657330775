import { createContext } from 'react'

import { initialState } from '@/store/strategies/strategies.state'
import { StrategiesContextProps } from '@/store/strategies/strategies.types'

export const StrategiesContext = createContext<StrategiesContextProps>({
  ...initialState,
  handleSortClick: () => undefined,
  setStrategiesPage: () => undefined,
  setStrategiesLimit: () => undefined,
  handleSortClickVaults: () => undefined,
  filterStrategy: () => undefined,
  setFilterByAssetGroup: () => undefined,
  setFilterBySearchTerm: () => undefined,
})
