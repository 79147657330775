import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { Asset } from '@solidant/spool-v2-fe-lib'

import MultiAssetSwapListItem from '@/components/molecules/MultiAssetDeposit/MultiAssetSwapListItem'

import { MultiAssetDeposit } from '@/store/deposit/deposit.types'

interface MultiAssetSwapListProps {
  assets: Asset[]
  multiAssetDeposit: MultiAssetDeposit
  isTokenApproved: boolean
  multiAssetDepositRatio: string[]
  loading: boolean
  setMultiAssetDeposit: React.Dispatch<React.SetStateAction<MultiAssetDeposit>>
}

const MultiAssetSwapList: React.FC<MultiAssetSwapListProps> = ({
  assets,
  multiAssetDeposit,
  isTokenApproved,
  multiAssetDepositRatio,
  loading,
  setMultiAssetDeposit,
}) => {
  return (
    <Box>
      <Typography fontSize='16px' mb='0.2rem' color='#21232299'>
        Your Deposits
      </Typography>
      <Card variant='outlined' className='section-item-card'>
        <CardContent className='section-item-card-content'>
          <Grid container columns={2}>
            {assets.map((asset, index) => (
              <MultiAssetSwapListItem
                key={asset.address}
                asset={asset}
                allocation={
                  multiAssetDepositRatio ? multiAssetDepositRatio[index] : '0'
                }
                multiAssetDeposit={multiAssetDeposit}
                isTokenApproved={isTokenApproved}
                setMultiAssetDeposit={setMultiAssetDeposit}
                loading={loading}
              />
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default MultiAssetSwapList
