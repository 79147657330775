import useAppState from '@/hooks/context/useAppState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useModalState from '@/hooks/context/useModalState'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

const useUpdateMetavaultVaultsAllocations = () => {
  const { openModal, closeModal, setModalType } = useModalState()
  const { sdkExternal } = useAppState()

  const { selectedVaultsAllocations, metavaultId } = useCreateMetavaultState()
  const { vaults, updateMetavaultVaults } = useCreatorDashboard()

  const handleUpdateAllocations = async () => {
    const allocations = selectedVaultsAllocations.map((allocation) =>
      Math.round(allocation * 100)
    )

    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.UpdateMetavaultAllocations,
        },
      })

      const tx = await sdkExternal.mutations.metaVaults.setMetaVaultAllocations(
        metavaultId,
        allocations
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.UpdateMetavaultAllocations,
        },
      })

      await tx.wait()

      const prevMetaVaultVaults = vaults.find(
        ({ address }) => address === metavaultId
      )

      await updateMetavaultVaults(prevMetaVaultVaults)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.UpdateMetavaultAllocations,
        },
      })
      closeModal(Modals.METAVAULT_MODAL)
    } catch (err) {
      console.error(err)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.UpdateMetavaultAllocations,
        },
      })
    }
  }

  return handleUpdateAllocations
}

export default useUpdateMetavaultVaultsAllocations
