import {
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'

import SpoolSelect from '@/components/atoms/SpoolSelect'
import AssetMenuItem from '@/components/molecules/AssetMenuItem'
import AssetMenuRenderItem from '@/components/molecules/AssetMenuRenderItem'

import { tokenAddresses } from '@/constants/tokens'

interface AssetsSelectProps {
  selectedTokenAddress: string
  handleChangeSelectedToken: (event: SelectChangeEvent<unknown>) => void
  nativeToken?: string
}

const AssetsSelect: React.FC<AssetsSelectProps> = ({
  selectedTokenAddress,
  handleChangeSelectedToken,
  nativeToken,
}) => {
  const { chain } = useConnectionState()

  const t = useLocale()

  const tokens = useMemo(() => tokenAddresses[chain], [chain])

  return (
    <FormControl fullWidth>
      <SpoolSelect
        value={selectedTokenAddress ?? ''}
        label={t(
          'components.organisms.smartVaults.collapsibleRow.deposit.standard.singleDepositAmount.availableAssets.label'
        )}
        handleChange={handleChangeSelectedToken}
        renderValue={(value) => {
          return <AssetMenuRenderItem assetId={value as string} />
        }}
      >
        <MenuItem
          disabled
          color='text.secondary'
          sx={{
            '&.Mui-disabled': {
              opacity: '1',
            },
          }}
        >
          <Typography
            ml='0.5rem'
            py='0.1rem'
            fontSize='14px'
            color='text.secondary'
            fontWeight={500}
          >
            In your wallet
          </Typography>
        </MenuItem>
        {Object.values(tokens)
          .filter((tokenAddress) => {
            if (chain === SupportedNetworks.SEPOLIA) {
              return nativeToken === tokenAddress
            }
            return true
          })
          .filter((address) => address !== tokens.SPOOL)
          .map((address) => (
            <MenuItem
              key={address}
              value={address}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px 16px',
              }}
            >
              <AssetMenuItem assetId={address} />
            </MenuItem>
          ))}
      </SpoolSelect>
    </FormControl>
  )
}

export default AssetsSelect
