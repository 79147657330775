import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import RiskModelTable from '@/components/organisms/CreateVault/RiskModel/RiskModelTable'

import riskModelData from '@/mockdata/create-vault.json'

const RiskModel = () => {
  return (
    <Section>
      <SectionColumn sx={{ gap: 0 }}>
        {riskModelData && (
          <>
            <RiskModelTable />
          </>
        )}
      </SectionColumn>
    </Section>
  )
}

export default RiskModel
