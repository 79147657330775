import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, Tooltip } from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import Oneinch from '@/assets/icons/chainIcons/1inch'
import Arbitrum from '@/assets/icons/chainIcons/Arbitrum'
import Ethereum from '@/assets/icons/chainIcons/Ethereum'

const icon: { [key: string]: JSX.Element } = {
  [SupportedNetworks.ARBITRUM]: <Arbitrum />,
  [SupportedNetworks.ETHEREUM]: <Ethereum />,
  [SupportedNetworks.SEPOLIA]: <Ethereum />,
  oneinch: <Oneinch />,
}

export const mapIcons = (key: string | number) => {
  if (!icon[key]) {
    // Just in case there is an unknown token
    return (
      <Tooltip title='Undetected token' arrow placement='left'>
        <IconButton sx={{ padding: '1px' }} disableRipple>
          <WarningIcon className='lg' sx={{ color: 'primary.main' }} />
        </IconButton>
      </Tooltip>
    )
  }
  return icon[key]
}
