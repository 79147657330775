import { useContext } from 'react'

import { SmartVaultsTableContext } from '@/store/smartVaultsTable/smartVaultsTable.context'

const useSmartVaultsTableState = () => {
  const smartVaultsTableContext = useContext(SmartVaultsTableContext)
  if (!Object.keys(smartVaultsTableContext).length) {
    throw new Error('SmartVaultsTableContext must be used within its provider')
  }
  return smartVaultsTableContext
}

export default useSmartVaultsTableState
