import WarningIcon from '@mui/icons-material/Warning'
import { Box, TableBody, TableCell, TableRow } from '@mui/material'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useMetavaultsPage from '@/hooks/smartVaults/useMetavaultPage'

import DataMissing from '@/components/molecules/DataMissing'
import TableWrapper from '@/components/molecules/TableWrapper'
import MetavaultVaultsMobileRow from '@/components/organisms/Metavaults/MetavaultVaultsMobileRow'
import MetavaultVaultsTableRow from '@/components/organisms/Metavaults/MetavaultVaultsTableRow'

import { creatorMetavaultVaultsHeadCells } from '@/constants/dashboard'

const MetavaultVaultsTable = () => {
  const { belowTabletBreakpoint } = useBreakpointDetection()
  const { metavaultData } = useMetavaultsPage()

  const {
    vaults,
    totalPendingDepositAssetAmount,
    totalPendingWithdrawalAssetAmount,
  } = metavaultData

  return !belowTabletBreakpoint ? (
    <>
      {vaults.length ? (
        <Box
          border='1px solid rgba(0, 0, 0, 0.12)'
          borderRadius='4px'
          overflow='hidden'
          sx={{ mt: 1 }}
        >
          <TableWrapper
            tokens
            header={creatorMetavaultVaultsHeadCells}
            headerClass='default-table-head'
          >
            <TableBody>
              {vaults
                //.sort((a, b) => sort(a, b, creatorVaultsTableSort))
                .map((metavaultVault) => (
                  <MetavaultVaultsTableRow
                    key={metavaultVault.id}
                    row={metavaultVault}
                    totalPendingDeposits={totalPendingDepositAssetAmount}
                    totalPendingWithdrawals={totalPendingWithdrawalAssetAmount}
                  />
                ))}
            </TableBody>
          </TableWrapper>
        </Box>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <Box
                my='2rem'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <WarningIcon
                  sx={{ height: '90px', width: '90px' }}
                  color='primary'
                />
                <DataMissing text='Creator Vaults not found...' />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </>
  ) : (
    <Box pt={2}>
      {vaults.map((vault) => (
        <MetavaultVaultsMobileRow
          key={vault.id}
          row={vault}
          totalPendingDeposits={totalPendingDepositAssetAmount}
          totalPendingWithdrawals={totalPendingWithdrawalAssetAmount}
        />
      ))}
    </Box>
  )
}

export default MetavaultVaultsTable
