import { Box, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

interface DetailsRowProps extends PropsWithChildren {
  name: string
  singleCell?: boolean
  strong?: boolean
}

const DetailsRow: React.FC<DetailsRowProps> = ({
  name,
  singleCell = false,
  children,
  strong = false,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  return (
    <Box
      display='flex'
      sx={{
        ':not(:last-child)': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)' },
      }}
    >
      {!singleCell && (
        <Typography
          variant='body2'
          sx={{
            width: belowPhabletBreakpoint ? '170px' : '220px',
            minWidth: belowPhabletBreakpoint ? '170px' : '220px',
            padding: (theme) => `6px ${theme.spacing(2)}`,
            alignSelf: 'center',
            fontWeight: strong && '500',
            backgroundColor: strong && 'rgba(235, 235, 235, 1)',
          }}
        >
          {name}
        </Typography>
      )}

      <Box
        display='flex'
        alignItems='center'
        padding={(theme) => `6px ${theme.spacing(2)}`}
        flexGrow={1}
        sx={{
          backgroundColor: '#fff',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
export default DetailsRow
