import { Stack, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import RiskAllocationTable from '@/components/organisms/CreateVault/Allocation/RiskAllocationTable'
import BasicFees from '@/components/organisms/CreateVault/Launch/BasicFees'
import GuardsActions from '@/components/organisms/CreateVault/Launch/GuardsActions'
import ManagementFee from '@/components/organisms/CreateVault/Launch/ManagementFee/ManagementFee'
import NameConfiguration from '@/components/organisms/CreateVault/Launch/NameConfiguration/NameConfiguration'
import PerformanceFee from '@/components/organisms/CreateVault/Launch/PerformanceFee/PerformanceFee'
import Summary from '@/components/organisms/CreateVault/Launch/Summary'

const Launch = () => {
  const t = useLocale()
  return (
    <Stack gap={2}>
      <Section>
        <Stack width='100%'>
          <NameConfiguration />
          <PerformanceFee />
          <ManagementFee />
        </Stack>
      </Section>
      <Section>
        <Stack width='100%'>
          <Summary />
          <BasicFees />
          <GuardsActions />
          <RiskAllocationTable />
          <SectionColumn sx={{ gap: 0 }}>
            <Typography
              color='rgba(0, 0, 0, 0.6)'
              fontSize='16px'
              lineHeight='175%'
              letterSpacing='0.15px'
            >
              {t('components.organisms.createVault.launch.launch.description')}
            </Typography>
          </SectionColumn>
        </Stack>
      </Section>
    </Stack>
  )
}

export default Launch
