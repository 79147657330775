import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { SocialsMenuIcons } from '@/constants/topMenu'

const SocialsMenuItem = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{ px: (theme) => theme.spacing(2.5) }}
    >
      <Box
        mt='1rem'
        pb='0.5rem'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        {SocialsMenuIcons.map(({ icon, url }) => (
          <Box
            component={Link}
            to={url}
            target='_blank'
            key={url}
            display='flex'
            sx={{
              color: 'icon.primary',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            {icon}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default SocialsMenuItem
