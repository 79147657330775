import { Box, Typography } from '@mui/material'
import { unix } from 'dayjs'

import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import ColumnlessGridRow from '@/components/molecules/ColumnlessGridRow'
import DetailsRow from '@/components/molecules/DetailsRow'
import SectionColumn from '@/components/molecules/SectionColumn'
import { StyledGridItem } from '@/components/molecules/StyledGridItem'

const ReallocationDetails = () => {
  const t = useLocale()

  const { smartVaultData } = useVaultsPage()

  const { lastRebalanceTime, rebalanceCount } = smartVaultData

  return (
    <SectionColumn
      sx={{
        padding: '0',
      }}
      noVerticalGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.reallocation'
      )}
    >
      <StyledGridItem>
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.lastReallocation.label'
          )}
        >
          {lastRebalanceTime ? (
            <>
              <Box display='flex' flexDirection='column'>
                <Typography variant='body2'>
                  {unix(lastRebalanceTime).format('DD MMM YYYY')}
                </Typography>
                <Typography variant='caption' color='text.secondary'>
                  {unix(lastRebalanceTime).format('HH:mm [UTC]Z')}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography variant='caption' color='text.secondary'>
              {t(
                'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.lastReallocation.missing'
              )}
            </Typography>
          )}
        </DetailsRow>
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.reallocationCount.label'
          )}
        >
          <Typography variant='body2'>{rebalanceCount ?? 0}</Typography>
        </DetailsRow>
        <ColumnlessGridRow
          text={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.reallocationNote.label'
          )}
          isLink={false}
        />
      </StyledGridItem>
    </SectionColumn>
  )
}

export default ReallocationDetails
