import { RouteDefinition } from '@/constants/routes'

type KeyValue = { [key: string]: string[] }

type RouteGroup = {
  [key: string]: RouteDefinition | RouteGroup
}

export const mapRoutesToBreadcrumbs = (
  routes: RouteGroup,
  parentBreadcrumbs: string[] = []
): KeyValue => {
  let breadcrumbsMap: KeyValue = {}

  for (const key in routes) {
    const route = routes[key]

    if (
      Object.prototype.hasOwnProperty.call(route, 'url') &&
      Object.prototype.hasOwnProperty.call(route, 'breadcrumbs')
    ) {
      const routeDefinition = route as RouteDefinition
      breadcrumbsMap[routeDefinition.url] = [
        ...parentBreadcrumbs,
        ...(routeDefinition.breadcrumbs || []),
      ]
    } else if (typeof route === 'object') {
      const nestedRoutes = route as RouteGroup
      const childBreadcrumbsMap = mapRoutesToBreadcrumbs(
        nestedRoutes,
        parentBreadcrumbs
      )
      breadcrumbsMap = { ...breadcrumbsMap, ...childBreadcrumbsMap }
    }
  }

  return breadcrumbsMap
}

export const mapRoutePatterns = (routes: KeyValue) => {
  return Object.entries(routes)
    .map(([route, breadcrumbs]) => {
      const pattern = route.replace(/:\w+/g, '([^\\/]+)')
      const regex = new RegExp(`^${pattern}$`)
      const specificity = route.split('/').length
      return { regex, breadcrumbs, specificity }
    })
    .sort((a, b) => b.specificity - a.specificity)
}

export const getBreadcrumbs = (routes: any, url: string) => {
  // Replace dynamic values like ':vaultId' with a regex pattern
  for (const { regex, breadcrumbs } of routes) {
    if (regex.test(url)) {
      return breadcrumbs
    }
  }
  return null
}
