import { Box, Typography } from '@mui/material'
import { ReactElement } from 'react'

interface VaultPageTitleDetailsProps {
  title: string
  value: ReactElement
  textAlign?: 'left' | 'right' | 'center'
}

const VaultPageTitleDetails: React.FC<VaultPageTitleDetailsProps> = ({
  title,
  value,
  textAlign = 'left',
}) => {
  return (
    <Box display='flex' flexDirection='column' textAlign={textAlign}>
      <Typography fontSize='12px' color='#212322'>
        {title}
      </Typography>
      {value}
    </Box>
  )
}

export default VaultPageTitleDetails
