import { useWeb3React } from '@web3-react/core'
import useSWR from 'swr'

import useNativeCurrency from '@/hooks/web3/useNativeCurrency'

import erc20ABI from '@/abis/erc20ABI'
import { NATIVE_TOKEN } from '@/constants/tokens'
import { fetcher } from '@/utils/web3'

const useTokenDetails = (tokenAddress: string | undefined) => {
  const { provider } = useWeb3React()

  const { nativeCurrency } = useNativeCurrency()

  const { data: symbol, error: symbolError } = useSWR(
    [tokenAddress ?? '', 'symbol'],
    (args: any) => {
      if (tokenAddress === NATIVE_TOKEN) {
        return nativeCurrency?.symbol
      }
      return fetcher(provider, erc20ABI)(args)
    }
  )

  const { data: decimals, error: decimalsError } = useSWR(
    [tokenAddress ?? '', 'decimals'],
    (args: any) => {
      if (tokenAddress === NATIVE_TOKEN) {
        return nativeCurrency?.decimals
      }
      return fetcher(provider, erc20ABI)(args)
    }
  )

  return {
    symbol,
    tokenAddress,
    decimals: decimals?.toString(),
    isLoading: !symbol && !decimals && !symbolError && !decimalsError,
  }
}

export default useTokenDetails
