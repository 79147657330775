import { Box, Button, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import SectionItem from '@/components/molecules/SectionItem'
import TransactionInputContainer from '@/components/molecules/TransactionInputContainer'

import { Routes } from '@/constants/routes'
import { Token } from '@/constants/smartVault'
import { VestingPage } from '@/constants/vesting'
import vestingData from '@/mockdata/vesting.json'
import { formatNumber } from '@/utils/formats'

interface VestingTokensProps {
  page: VestingPage
  setPage: (page: VestingPage) => void
}

const VestingTokens: React.FC<VestingTokensProps> = ({ page, setPage }) => {
  const t = useLocale()
  const { setPathWithHistory } = useUniversalNavigation()
  const { chain } = useConnectionState()

  const openClaim = () => {
    setPathWithHistory(Routes(chain).vesting.claim.url)
    setPage(VestingPage.CLAIM)
  }

  const claim = () => {
    // TODO - 12 (unused component): implement when backend integration
    alert('Not implemented yet')
  }

  const cancel = () => {
    setPathWithHistory(Routes(chain).vesting.root.url)
    setPage(VestingPage.VESTING)
  }

  return (
    <Section>
      <SectionColumn
        title={t(
          'components.organisms.vesting.vestingTokens.locked.sectionTitle'
        )}
        sx={{ paddingRight: (theme) => theme.spacing(1) }}
      >
        <SectionItem
          title={t(
            'components.organisms.vesting.vestingTokens.locked.sectionItems.totalVested.title'
          )}
        >
          <Box display='flex' alignItems='baseline'>
            <Typography variant='h6'>
              {formatNumber(vestingData.totalVested, 2)} %
            </Typography>
            <Typography variant='body1'>
              &nbsp;
              {t(
                'components.organisms.vesting.vestingTokens.locked.sectionItems.totalVested.content'
              )}
            </Typography>
          </Box>
        </SectionItem>

        <SectionItem
          title={t(
            'components.organisms.vesting.vestingTokens.locked.sectionItems.remaining.title'
          )}
          subtitle={t(
            'components.organisms.vesting.vestingTokens.locked.sectionItems.remaining.subtitle'
          )}
        >
          <Box display='flex' alignItems='baseline'>
            <Typography variant='h6'>
              {formatNumber(vestingData.remainingToBeVested, 2)}
            </Typography>
            <Typography variant='body1'>&nbsp;${Token.YLAY}</Typography>
          </Box>
        </SectionItem>
      </SectionColumn>

      <SectionColumn
        title={t(
          'components.organisms.vesting.vestingTokens.unlocked.sectionTitle'
        )}
        sx={{ paddingLeft: (theme) => theme.spacing(1) }}
      >
        <SectionItem
          title={t(
            'components.organisms.vesting.vestingTokens.unlocked.sectionItems.claimableTokensApy.title'
          )}
        >
          <Box display='flex' alignItems='baseline'>
            <Typography variant='h6'>
              {formatNumber(vestingData.claimable, 2)} %
            </Typography>
            <Typography variant='body1'>
              &nbsp;
              {t(
                'components.organisms.vesting.vestingTokens.unlocked.sectionItems.claimableTokensApy.content'
              )}
            </Typography>
          </Box>
        </SectionItem>

        <SectionItem
          title={t(
            'components.organisms.vesting.vestingTokens.unlocked.sectionItems.claimableTokensSpool.title'
          )}
        >
          <Box display='flex' alignItems='baseline'>
            <Typography variant='h6'>
              {formatNumber(vestingData.claimed, 2)}
            </Typography>
            <Typography variant='body1'>&nbsp;${Token.YLAY}</Typography>
          </Box>
        </SectionItem>

        {page === VestingPage.CLAIM ? (
          <TransactionInputContainer
            inputLabel={`${t(
              'components.organisms.vesting.vestingTokens.unlocked.input.label'
            )} $${Token.YLAY}`}
            confirmLabel={t(
              'components.organisms.vesting.vestingTokens.unlocked.input.confirmLabel'
            )}
            // maxValue={vestingData.claimable}
            onConfirm={claim}
            onCancel={cancel}
            tokenId=''
          />
        ) : (
          <Button
            variant='contained'
            size='large'
            onClick={openClaim}
            disabled={vestingData.claimable <= 0}
            sx={{ width: 'fit-content' }}
          >
            {t(
              'components.organisms.vesting.vestingTokens.unlocked.button.label'
            )}
          </Button>
        )}
      </SectionColumn>
    </Section>
  )
}

export default VestingTokens
