const Ethereum = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
        fill='#627EEA'
      />
      <path
        d='M8.24933 1.9989V6.43411L11.9978 8.10934L8.24933 1.9989Z'
        fill='white'
        fillOpacity='0.602'
      />
      <path
        d='M8.24935 1.9989L4.50085 8.10934L8.24935 6.43411V1.9989Z'
        fill='white'
      />
      <path
        d='M8.24933 10.9831V13.9967L12 8.80701L8.24933 10.9831Z'
        fill='white'
        fillOpacity='0.602'
      />
      <path
        d='M8.24935 13.9967V10.9831L4.50085 8.80701L8.24935 13.9967Z'
        fill='white'
      />
      <path
        d='M8.24933 10.2854L11.9978 8.10938L8.24933 6.43414V10.2854Z'
        fill='white'
        fillOpacity='0.2'
      />
      <path
        d='M4.50085 8.10938L8.24935 10.2854V6.43414L4.50085 8.10938Z'
        fill='white'
        fillOpacity='0.602'
      />
    </svg>
  )
}

export default Ethereum
