import InfoIcon from '@mui/icons-material/Info'
import { Box, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'

import TextTagRenderer from '@/components/atoms/TextTagRenderer'

interface TableCellInfoTooltipProps {
  infoTitle?: string
  infoText?: string
}

const TableCellInfoTooltip: React.FC<TableCellInfoTooltipProps> = ({
  infoTitle,
  infoText,
}) => {
  return (
    <Tooltip
      title={
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={1}
          sx={{ color: 'black' }}
        >
          <Typography variant='caption' fontSize='14px'>
            <TextTagRenderer inputString={infoTitle} />
          </Typography>
          {infoText && (
            <Typography variant='caption' fontSize='14px'>
              <TextTagRenderer inputString={infoText} />
            </Typography>
          )}
        </Box>
      }
      arrow
      placement='bottom'
    >
      <InfoIcon className='sm' sx={{ color: 'primary.main' }} />
    </Tooltip>
  )
}

export default TableCellInfoTooltip
