import { Paper, styled } from '@mui/material'

const ReceiptModalBoxOuterContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: 'none',
  borderRadius: '4px',
  padding: theme.spacing(3, 0),
  boxShadow: theme.shadows[6],
}))

export default ReceiptModalBoxOuterContainer
