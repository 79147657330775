import { createContext } from 'react'

import { initialState } from '@/store/creatorVault/creatorDashboard.state'
import { CreatorDashboardContextProps } from '@/store/creatorVault/creatorDashboard.types'

export const CreatorDashboardContext =
  createContext<CreatorDashboardContextProps>({
    ...initialState,
    setCreatorVaultsTableLimit: () => undefined,
    setCreatorVaultsTablePage: () => undefined,
    handleSortClick: () => undefined,
    setSelectedVaults: () => undefined,
    setVaultsStrategiesTableLimit: () => undefined,
    setVaultsStrategiesTablePage: () => undefined,
    setTvrChartValues: () => undefined,
    setFeesChartValues: () => undefined,
    handleStrategiesSortClick: () => undefined,
    setWithdrawEarningsVault: () => undefined,
  })
