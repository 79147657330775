import { SortOrder } from '@solidant/spool-v2-fe-lib'

import {
  CreatorDashboardAction,
  CreatorDashboardActionTypes,
  CreatorDashboardState,
} from '@/store/creatorVault/creatorDashboard.types'

const creatorDashboardReducer = (
  state: CreatorDashboardState,
  action: CreatorDashboardAction
): CreatorDashboardState => {
  switch (action.type) {
    case CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_PAGE:
      return { ...state, creatorVaultsTablePage: action.payload }
    case CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_LIMIT:
      return { ...state, creatorVaultsTableLimit: action.payload }
    case CreatorDashboardActionTypes.CHANGE_CREATOR_VAULTS_SORT_DIRECTION:
      return {
        ...state,
        creatorVaultsTableSort: {
          ...state.creatorVaultsTableSort,
          direction:
            state.creatorVaultsTableSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_SORT:
      return {
        ...state,
        creatorVaultsTableSort: action.payload,
      }
    case CreatorDashboardActionTypes.SET_SELECTED_VAULTS:
      return { ...state, selectedVaults: action.payload }
    case CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_PAGE:
      return { ...state, creatorVaultsStrategiesPage: action.payload }
    case CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_LIMIT:
      return { ...state, creatorVaultsStrategiesLimit: action.payload }
    case CreatorDashboardActionTypes.CHANGE_VAULTS_STRATEGIES_SORT_DIRECTION:
      return {
        ...state,
        creatorVaultsStrategiesSort: {
          ...state.creatorVaultsStrategiesSort,
          direction:
            state.creatorVaultsStrategiesSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_SORT:
      return {
        ...state,
        creatorVaultsStrategiesSort: action.payload,
      }
    case CreatorDashboardActionTypes.SET_TVR_CHARTS_VALUE:
      return {
        ...state,
        tvrChartValues: action.payload,
      }
    case CreatorDashboardActionTypes.SET_FEES_CHART_VALUES:
      return {
        ...state,
        feesChartValues: action.payload,
      }
    case CreatorDashboardActionTypes.SET_WITHDRAW_EARNINGS_VAULT:
      return {
        ...state,
        withdrawEarningsVault: action.payload,
      }
  }
}

export default creatorDashboardReducer
