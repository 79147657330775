import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import { Box, Button, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

const ButtonMinimize: React.FC = () => {
  const { minimizeModal } = useModalState()

  const t = useLocale()

  return (
    <Button
      variant='outlined'
      size='small'
      color='primary'
      onClick={() => minimizeModal(Modals.ACTION_MODAL)}
    >
      <Box display='flex' gap={1}>
        <CloseFullscreenIcon className='lg' sx={{ alignSelf: 'center' }} />
        <Typography variant='button' sx={{ alignSelf: 'center' }}>
          {t('components.organisms.actionModal.button.minimize')}
        </Typography>
      </Box>
    </Button>
  )
}

export default ButtonMinimize
