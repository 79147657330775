import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { Component, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: React.ErrorInfo | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ errorInfo })
  }

  handleClose = () => {
    this.setState({ /*hasError: false,*/ error: null, errorInfo: null })
    window.location.reload()
  }

  private fontFamily = [
    '"Poppins"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(',')

  render() {
    if (this.state.hasError) {
      return (
        <Modal
          open={this.state.hasError}
          aria-labelledby='error-modal-title'
          aria-describedby='error-modal-description'
          disableEnforceFocus
          sx={{
            backgroundColor: '#fff',
          }}
          slotProps={{ backdrop: { sx: { backgroundColor: '#fdf9f9f' } } }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '400px',
              whiteSpace: 'pre-wrap',
              bgcolor: 'background.paper',
              boxShadow: 24,
              border: 0,
              outline: 0,
              borderRadius: 1,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <ErrorOutlineIcon color='error' fontSize='large' />
            <Typography
              fontFamily={this.fontFamily}
              variant='h6'
              color='text.primary'
            >
              Sorry, unexpected error
            </Typography>
            <Typography
              fontFamily={this.fontFamily}
              variant='body1'
              textAlign='center'
              color='text.primary'
            >
              We are looking into it. Please try to refresh the page in the
              meantime.
            </Typography>
            <Button
              variant='contained'
              sx={{
                fontFamily: this.fontFamily,
                backgroundColor: '#006BA6',
                '&:hover': { backgroundColor: '#004a74' },
              }}
              fullWidth
              onClick={this.handleClose}
            >
              Refresh page
            </Button>
          </Box>
        </Modal>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
