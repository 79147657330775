import { Suspense, useMemo } from 'react'
import { Navigate, Route, Routes as Router } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'

import Redirect from '@/components/atoms/Redirect'
import TopPageLoading from '@/components/atoms/TopPageLoading'

import PageNotFound from '@/pages/General/PageNotFound'

import {
  BaseRoutesPaths,
  BaseRoutesPathsWithChain,
  Routes,
} from '@/constants/routes'
import getRouteConfigurations from '@/routes/getRoutesConfig'

const AppRoutes = () => {
  const routeConfigurations = getRouteConfigurations()
  const { chain } = useConnectionState()

  return useMemo(
    () => (
      <Router>
        <Route
          path={Routes(chain).home.root.url}
          index
          element={<Navigate to={Routes(chain).smartVaults.root.url} />}
        />
        {routeConfigurations.map(({ path, element }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <Suspense fallback={<TopPageLoading />}>{element}</Suspense>
            }
          />
        ))}
        {/*Route here because users will always need to select RiskModels first */}
        <Route
          path={`/${BaseRoutesPathsWithChain(
            chain,
            BaseRoutesPaths.CREATE_VAULT
          )}/:step?`}
          element={
            <Navigate
              replace
              to={Routes(chain).createVault.createVaultRiskModel.url}
            />
          }
        />
        <Route
          path='spool/*'
          element={<Navigate to={Routes(chain).smartVaults.root.url} />}
        />
        <Route
          path='app/*'
          element={<Navigate to={Routes(chain).smartVaults.root.url} />}
        />
        <Route
          path='platform-summary/*'
          element={<Redirect url='https://v1.app.spool.fi/platform-summary' />}
        />
        <Route
          path='egov/*'
          element={<Redirect url='https://v1.app.spool.fi/egov' />}
        />
        <Route path='*' element={<PageNotFound />} />
      </Router>
    ),
    [chain]
  )
}

export default AppRoutes
