import { Fragment, memo, useMemo, useState } from 'react'

import { parseTextTags } from '@/utils/textParser'

const TextTagRenderer: React.FC<{ inputString: string }> = ({
  inputString,
}) => {
  const [error, setError] = useState<string | null>(null)

  const parsedElements = useMemo(() => {
    try {
      const result = parseTextTags(inputString)
      setError(null)
      return result
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
        return []
      }
    }
  }, [inputString])

  const renderedElements = useMemo(
    () =>
      parsedElements.map((element, index) => {
        switch (element.type) {
          case 'text':
            return <Fragment key={index}>{element.content}</Fragment>
          case 'link':
            return (
              <a
                key={index}
                href={element.url}
                target='_blank'
                rel='noreferrer'
              >
                {element.content}
              </a>
            )
          case 'strong':
            return <strong key={index}>{element.content}</strong>
          case 'break':
            return <br key={index} />
          default:
            return null
        }
      }),
    [inputString]
  )

  if (error) {
    return <div className='error'>{error}</div>
  }

  return <>{renderedElements}</>
}

export default memo(TextTagRenderer)
