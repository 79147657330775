import ActionsDescription from '@/components/atoms/PageDescriptions/ActionsDescription'
import AllocationDescription from '@/components/atoms/PageDescriptions/AllocationDescription'
import GuardsDescription from '@/components/atoms/PageDescriptions/GuardsDescription'
import LaunchDescription from '@/components/atoms/PageDescriptions/LaunchDescription'
import RiskModelDescription from '@/components/atoms/PageDescriptions/RiskModelDescription'
import StrategiesDescription from '@/components/atoms/PageDescriptions/StrategiesDescription'
import Actions from '@/components/organisms/CreateVault/Actions/Actions'
import Allocation from '@/components/organisms/CreateVault/Allocation/Allocation'
import Guards from '@/components/organisms/CreateVault/Guards/Guards'
import Launch from '@/components/organisms/CreateVault/Launch/Launch'
import RiskModel from '@/components/organisms/CreateVault/RiskModel/RiskModel'
import Strategies from '@/components/organisms/CreateVault/Strategies/Strategies'

type stepsMap = {
  [key: number]: {
    body: JSX.Element
    description: JSX.Element
  }
}

const CreateVaultRenderStep = ({
  step,
  totalSteps,
  type,
}: {
  step: number
  totalSteps?: number
  type: 'body' | 'description'
}) => {
  const renderStepsMap: stepsMap = {
    1: {
      body: <RiskModel />,
      description: <RiskModelDescription />,
    },
    2: {
      body: <Strategies />,
      description: <StrategiesDescription />,
    },
    3: {
      body: <Allocation />,
      description: <AllocationDescription />,
    },
    4: {
      body: totalSteps === 4 ? <Launch /> : <Guards />,
      description:
        totalSteps === 4 ? <LaunchDescription /> : <GuardsDescription />,
    },
    5: {
      body: <Actions />,
      description: <ActionsDescription />,
    },
    6: {
      body: <Launch />,
      description: <LaunchDescription />,
    },
  }

  return renderStepsMap[step][type]
}

export default CreateVaultRenderStep
