import {
  SmartVaultsTableAction,
  SmartVaultsTableActionTypes,
  SmartVaultsTableState,
} from '@/store/smartVaultsTable/smartVaultsTable.types'

const smartVaultsTableReducer = (
  state: SmartVaultsTableState,
  action: SmartVaultsTableAction
): SmartVaultsTableState => {
  switch (action.type) {
    case SmartVaultsTableActionTypes.SET_PAGE:
      return { ...state, page: action.payload, selectedVault: undefined }
    case SmartVaultsTableActionTypes.SET_LIMIT:
      return { ...state, limit: action.payload, selectedVault: undefined }
    case SmartVaultsTableActionTypes.SET_MARKET:
      return { ...state, marketId: action.payload, selectedVault: undefined }
    case SmartVaultsTableActionTypes.SET_ASSET_GROUP:
      return {
        ...state,
        assetGroupId: action.payload,
        selectedVault: undefined,
      }
    case SmartVaultsTableActionTypes.SET_RISK_MODEL_PROVIDER:
      return {
        ...state,
        riskModelProviderId: action.payload,
        selectedVault: undefined,
      }
    case SmartVaultsTableActionTypes.SET_SEARCH:
      return { ...state, search: action.payload, selectedVault: undefined }
    case SmartVaultsTableActionTypes.SET_SMART_VAULTS_TABLE_SORT:
      return {
        ...state,
        smartVaultsTableSort: {
          ...state.smartVaultsTableSort,
          key: action.payload,
        },
        selectedVault: undefined,
      }
    case SmartVaultsTableActionTypes.SET_SMART_VAULTS_TABLE_SORT_DIRECTION:
      return {
        ...state,
        smartVaultsTableSort: {
          ...state.smartVaultsTableSort,
          direction: action.payload,
        },
        selectedVault: undefined,
      }
    case SmartVaultsTableActionTypes.SET_SELECTED_VAULT:
      return { ...state, selectedVault: action.payload }
    case SmartVaultsTableActionTypes.SET_REWARDS_CLAIMED_VAULT:
      return { ...state, rewardsClaimedVault: action.payload }
    case SmartVaultsTableActionTypes.SET_NEXT_REWARDS_SYNC_TIME:
      return { ...state, nextRewardsSyncTime: action.payload }
    case SmartVaultsTableActionTypes.SET_SELECTED_VAULT_ID:
      return { ...state, selectedVaultId: action.payload }
    default:
      return state
  }
}

export default smartVaultsTableReducer
