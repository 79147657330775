import { HeadCell } from '@/types/table'

export const transactionHistoryHeadCells: HeadCell[] = [
  {
    id: 'timestamp',
    numeric: false,
    label: 'Date',
    align: 'left',
  },
  {
    id: 'smartVaultName',
    numeric: false,
    label: 'Smart Vault',
    align: 'left',
  },
  {
    id: 'transactionType',
    numeric: false,
    label: 'Type',
    align: 'left',
  },
  {
    id: 'amount',
    numeric: true,
    label: 'Amount',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'usdBalance',
    numeric: true,
    label: 'Your Balance',
    align: 'right',
  },
]
