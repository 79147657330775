import { memo, useEffect } from 'react'

const LoadSentry: React.FC<{ appLoaded: boolean }> = ({ appLoaded }) => {
  useEffect(() => {
    if (appLoaded) {
      import('@sentry/react').then((Sentry) => {
        Sentry.init({
          dsn: 'https://ac3035a66b6a48b59efce883bcb8d1c7@o4505170224218112.ingest.sentry.io/4505170279530496',
          integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
          // Performance Monitoring
          tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
      })
    }
  }, [appLoaded])

  return null
}

export default memo(LoadSentry)
