import { useContext } from 'react'

import { InvestorDashboardContext } from '@/store/investorDashboard/investorDashboard.context'

const useInvestorDashboardState = () => {
  const investorDashboardContext = useContext(InvestorDashboardContext)
  if (!Object.keys(investorDashboardContext).length) {
    throw new Error('InvestorDashboardContext must be used within its provider')
  }
  return investorDashboardContext
}

export default useInvestorDashboardState
