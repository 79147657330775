import { Box, TableBody } from '@mui/material'
import { RiskScores } from '@solidant/spool-v2-fe-lib'
import { useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useStrategiesAllocation from '@/hooks/createVault/useStrategiesAllocation'

import DataLoading from '@/components/molecules/DataLoading'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import SectionColumn from '@/components/molecules/SectionColumn'
import TableStatus from '@/components/molecules/TableStatus'
import TableWrapper from '@/components/molecules/TableWrapper'
import RiskAllocationTableFooter from '@/components/organisms/CreateVault/Allocation/RiskAllocationTableFooter'
import RiskAllocationTableRow from '@/components/organisms/CreateVault/Allocation/RiskAllocationTableRow'

import { riskAllocationStrategiesHeadCells } from '@/constants/createVault'

const RiskAllocationTable: React.FC = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { selectedStrategies, selectedRiskScore } = useCreateVaultState()

  const { strategiesAllocation, isLoading, error } = useStrategiesAllocation()
  const { isUnavailableProvider } = useCreateVaultState()

  const t = useLocale()

  const totalBalanceUSD = selectedStrategies.reduce((total, item) => {
    return total + parseFloat(item.balanceUSD)
  }, 0)

  return (
    <SectionColumn
      title={t(
        'components.organisms.createVault.allocation.riskAllocationTable.title'
      )}
    >
      <Box
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
      >
        <TableWrapper
          header={riskAllocationStrategiesHeadCells}
          headerClass='default-table-head'
          pagination={
            selectedStrategies.length > 9 && {
              rowsPerPageOptions: [5, 10, 25],
              page: page,
              rowsPerPage: rowsPerPage,
              count: selectedStrategies.length,
              setPage: setPage,
              setRowsPerPage: setRowsPerPage,
            }
          }
        >
          <TableBody>
            {isUnavailableProvider ? (
              <TableStatus colSpan={5}>
                <ErrorWithText errorText='Allocation provider unavailable' />
              </TableStatus>
            ) : !error ? (
              !isLoading ? (
                selectedStrategies
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((allocationData, index) => (
                    <RiskAllocationTableRow
                      key={index}
                      row={allocationData}
                      strategyAllocation={
                        strategiesAllocation[selectedRiskScore as RiskScores]
                      }
                    />
                  ))
              ) : (
                <TableStatus colSpan={5}>
                  <DataLoading loadingText='Loading Allocations' />
                </TableStatus>
              )
            ) : (
              <TableStatus colSpan={5}>
                <ErrorWithText errorText='Error loading allocations' />
              </TableStatus>
            )}
          </TableBody>
          {/* Not ideal to use footer prop, imagine having to calculate the data in the future to pass in. We would have think of how to reconstruct a FooterCell Type object. -Heng*/}
          <RiskAllocationTableFooter totalVal={totalBalanceUSD} />
        </TableWrapper>
      </Box>
    </SectionColumn>
  )
}

export default RiskAllocationTable
