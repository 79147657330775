import { Box, Button, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useDebounce from '@/hooks/utils/useDebounce'

import IncrementDecrementContainer from '@/components/atoms/IncrementDecrementContainer'
import CustomSlider from '@/components/molecules/CustomSlider/CustomSlider'

import { mapRiskToRange } from '@/utils/sdkHelper'

const RISK_RANGE = {
  min: -10,
  max: 10,
}

const ButtonContainer = styled(Box)(() => ({
  paddingTop: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  gap: '0.5rem',
}))

const RiskConfiguration: React.FC = () => {
  const t = useLocale()
  const { selectedRiskScore, setSelectedRiskScore } = useCreateVaultState()

  const [disabled, setDisabled] = useState<boolean>(false)

  const [value, setValue] = useState(selectedRiskScore)

  const debouncedValue = useDebounce(value.toString())

  useEffect(() => {
    setSelectedRiskScore(Number(debouncedValue))
  }, [debouncedValue])

  const handleBeforeChange = () => setDisabled(true)

  const handleAfterChange = () => {
    setDisabled(false)
  }

  const handleFeeIncrement = () => {
    if (value === RISK_RANGE.max) {
      return
    }
    setValue(value + 1)
  }

  const handleFeeDecrement = () => {
    if (value === RISK_RANGE.min) {
      return
    }
    setValue(value - 1)
  }

  return (
    <Box
      paddingTop='3rem'
      paddingBottom='2rem'
      display='flex'
      bgcolor='#FFFFFF'
      border='1px solid rgba(0, 0, 0, 0.12)'
      borderRadius='4px'
      justifyContent='center'
      paddingX='1rem'
    >
      <Box display='flex'>
        <ButtonContainer>
          <Button
            onClick={() => setValue(RISK_RANGE.min)}
            disabled={value === RISK_RANGE.min || disabled}
            variant='contained'
            sx={{ height: '53px', width: '90px' }}
          >
            {t(
              'components.organisms.createVault.allocation.riskConfiguration.minButton.buttonText'
            )}
          </Button>
          <Typography textAlign='center' fontSize='12px'>
            {t(
              'components.organisms.createVault.allocation.riskConfiguration.minButton.label'
            )}
          </Typography>
        </ButtonContainer>
        <CustomSlider
          range={RISK_RANGE}
          rangeLabel={{
            max: 10,
            min: 0,
          }}
          value={value}
          onChange={setValue}
          onBeforeChange={handleBeforeChange}
          onAfterChange={handleAfterChange}
        />
        <ButtonContainer>
          <Button
            onClick={() => setValue(RISK_RANGE.max)}
            disabled={value === RISK_RANGE.max || disabled}
            variant='contained'
            sx={{ height: '53px', width: '90px' }}
          >
            {t(
              'components.organisms.createVault.allocation.riskConfiguration.maxButton.buttonText'
            )}
          </Button>
          <Typography textAlign='center' fontSize='12px'>
            {t(
              'components.organisms.createVault.allocation.riskConfiguration.maxButton.label'
            )}
          </Typography>
        </ButtonContainer>
      </Box>
      <Box display='flex' paddingLeft='1.5rem'>
        <IncrementDecrementContainer
          onClick={handleFeeDecrement}
          sx={{
            borderRadius: '4px 0px 0px 4px',
            borderColor: 'primary.main',
            '&:hover': {
              borderColor: 'primary.main',
            },
          }}
        >
          -
        </IncrementDecrementContainer>
        <Box marginTop='1rem' height='53px' px='0.5rem'>
          <Typography
            textAlign='center'
            fontSize='12px'
            color='rgba(0, 0, 0, 0.6)'
            fontWeight={400}
            px='1.5rem'
          >
            {t(
              'components.organisms.createVault.allocation.riskConfiguration.incrementDecrementContainer.label'
            )}
          </Typography>
          <Typography textAlign='center' fontSize='16px' fontWeight={500}>
            {mapRiskToRange(value)}
          </Typography>
          <hr
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.42)',
              height: '1px',
              border: 'none',
            }}
          />
        </Box>
        <IncrementDecrementContainer
          onClick={handleFeeIncrement}
          sx={{
            color: 'primary.main',
            borderRadius: '0px 4px 4px 0px',
            borderColor: 'primary.main',
            '&:hover': {
              borderColor: 'primary.main',
            },
          }}
        >
          +
        </IncrementDecrementContainer>
      </Box>
    </Box>
  )
}

export default RiskConfiguration
