import useConnectionState from '@/hooks/context/useConnectionState'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import StrategiesFilters from '@/components/organisms/CreateVault/Strategies/Filters'
import StrategiesTable from '@/components/organisms/CreateVault/Strategies/StrategiesTable'

import { ASSET_GROUPS } from '@/constants/createVault'

const Strategies = () => {
  const { chain } = useConnectionState()

  return (
    <Section>
      <SectionColumn sx={{ gap: 0 }}>
        <StrategiesFilters assetGroups={ASSET_GROUPS[chain]} />
        <StrategiesTable />
      </SectionColumn>
    </Section>
  )
}

export default Strategies
