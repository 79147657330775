import { useWeb3React } from '@web3-react/core'

import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useModalState from '@/hooks/context/useModalState'
import useInvestorDashboard from '@/hooks/dashboard/useInvestorDashboard'
import useUserClaimableNfts from '@/hooks/user/useUserClaimableNfts'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { sleep } from '@/utils/web3'

const useMultiNftClaim = () => {
  const { openModal, setModalType } = useModalState()
  const { chain } = useConnectionState()

  const { userClaimablewNFTs, updateUserClaimableNfts } = useUserClaimableNfts()

  const { account, chainId, connector } = useWeb3React()

  const { sdk } = useAppState()

  const { optimisticallyBurnWNFTs } = useInvestorDashboard()

  const claimMultipleNft = async (
    wnfts: {
      nftId: string
      shares: string
    }[],
    vaultAddress: string
  ) => {
    try {
      if (chainId !== chain) {
        connector.activate(chain)
        return
      }

      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.ClaimWithdraw,
        },
      })

      await sdk.smartVault.claim(
        vaultAddress,
        {
          wnftId: wnfts.map(({ nftId }) => nftId),
          shares: wnfts.map(({ shares }) => shares),
        },
        account
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.ClaimWithdraw,
        },
      })

      await sleep(5000)

      await optimisticallyBurnWNFTs(vaultAddress)

      await updateUserClaimableNfts(userClaimablewNFTs)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.ClaimWithdraw,
        },
      })
    } catch (error) {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.ClaimWithdraw,
        },
      })
    }
  }

  return claimMultipleNft
}

export default useMultiNftClaim
