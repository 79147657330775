import { alpha, Box, Typography } from '@mui/material'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

interface DashboardPieChartLegendProps {
  allocationData: Record<string, number>
  sum: number
  formatLabel: (key: string) => string
  gridTemplateColumns: string
}

const DashboardPieChartLegend: React.FC<DashboardPieChartLegendProps> = ({
  allocationData,
  sum,
  formatLabel,
  gridTemplateColumns,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignSelf='center'
      gap={1}
      maxWidth='400px'
    >
      {Object.entries(allocationData).map(([key, allocation], index) => {
        return (
          <Box
            key={index}
            display='grid'
            gap={3}
            gridTemplateColumns={gridTemplateColumns}
          >
            <Box display='flex' gap={0.5}>
              <Box
                alignSelf='center'
                sx={{
                  mr: '0.2rem',
                  transform: 'scale(2.5)',
                  color: alpha(
                    '#039BE5',
                    1 - (index / Object.keys(allocationData).length) * 0.9
                  ),
                }}
              >
                •
              </Box>
              <Typography variant='body2'>{formatLabel(key)}</Typography>
            </Box>
            <Typography variant='body2'>
              {formatUSD((+allocation / sum) * 100)}%
            </Typography>
            <Typography variant='body2' textAlign='right'>
              {currency}
              {formatUSD(allocation)}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export default DashboardPieChartLegend
