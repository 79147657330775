import { Box, TableBody, TablePagination } from '@mui/material'
import {
  SmartVaultBase,
  SmartVaultSorting,
  SortOrder,
} from '@solidant/spool-v2-fe-lib'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import TableWrapper from '@/components/molecules/TableWrapper'
import MobileVaultRow from '@/components/organisms/SmartVaults/MobileVaultRow'
import SmartVaultTableRow from '@/components/organisms/SmartVaults/Row'

import { headCells } from '@/constants/smartVault'

interface SmartVaultTableProps {
  smartVaults: SmartVaultBase[]
  totalVaults: number
  totalTvr: string
  totalUserBalance: string
}

export const HIDDEN_VAULTS: string[] = [
  '0x49531aacc1d18502bf09a84fc1769d894e4e5aa0',
]

const Table: React.FC<SmartVaultTableProps> = ({
  smartVaults,
  totalVaults,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const {
    page,
    setPage,
    limit,
    setLimit,
    smartVaultsTableSort,
    setAssetGroupId,
    setSearch,
    handleSmartVaultsTableSort,
  } = useSmartVaultsTableState()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('page')) {
      setPage(parseInt(searchParams.get('page')))
    }
    if (searchParams.get('limit')) {
      setLimit(parseInt(searchParams.get('limit')))
    }
    if (searchParams.get('assetGroup')) {
      setAssetGroupId(searchParams.get('assetGroup'))
    }
    if (searchParams.get('search')) {
      setSearch(searchParams.get('search'))
    }
    if (searchParams.get('sortKey') || searchParams.get('sortOrder')) {
      handleSmartVaultsTableSort(
        (searchParams.get('sortKey') as SmartVaultSorting) || 'tvr',
        {
          key: (searchParams.get('sortKey') as SmartVaultSorting) || 'tvr',
          direction:
            (searchParams.get('sortOrder') as SortOrder) || SortOrder.DESC,
        }
      )
    }
  }, [])

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage)
  }

  return (
    <>
      {!belowPhabletBreakpoint && (
        <Box
          width='100%'
          borderTop='none'
          border='1px solid rgba(0, 0, 0, 0.12)'
          borderRadius=' 0 0 4px 4px'
        >
          <TableWrapper
            header={headCells}
            headerClass='default-table-head'
            pagination={{
              rowsPerPageOptions: [10, 20, 30],
              page: page,
              rowsPerPage: limit,
              count: totalVaults,
              setPage: setPage,
              setRowsPerPage: setLimit,
            }}
            sortType={smartVaultsTableSort}
            handleSortClick={handleSmartVaultsTableSort}
            tokens
            headerSX={{ py: '0.5rem' }}
          >
            <TableBody
              component={Box}
              borderBottom='1px solid rgba(0, 0, 0, 0.12)'
            >
              {smartVaults
                ?.filter(({ address }) => !HIDDEN_VAULTS.includes(address))
                .map((smartVaultBase: SmartVaultBase) => (
                  <SmartVaultTableRow
                    key={smartVaultBase.address}
                    smartVaultBase={smartVaultBase}
                  />
                ))}
            </TableBody>
          </TableWrapper>
        </Box>
      )}
      {belowPhabletBreakpoint &&
        smartVaults
          ?.filter(({ address }) => !HIDDEN_VAULTS.includes(address))
          .map((smartVaultBase: SmartVaultBase) => (
            <MobileVaultRow
              key={smartVaultBase.address}
              smartVaultBase={smartVaultBase}
            />
          ))}
      {belowPhabletBreakpoint && (
        <TablePagination
          component='div'
          count={totalVaults}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  )
}

export default Table
