import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import getLocale from '@/locales/getLocale'

import DeBank from '@/assets/icons/socials/DeBank'
import Discord from '@/assets/icons/socials/Discord'
import Github from '@/assets/icons/socials/Github'
import LinkedIn from '@/assets/icons/socials/LinkedIn'
import Telegram from '@/assets/icons/socials/Telegram'
import Twitter from '@/assets/icons/socials/Twitter'

import { Routes } from '@/constants/routes'
import { SubmenuItem } from '@/constants/subMenu'

const t = getLocale()

export enum AppMode {
  B2B = 'B2B',
  B2C = 'B2C',
}

export type MenuItem = {
  title: string
  url?: string
  submenuSections?: SubmenuItem[][]
  tabs?: { title: string; url: string }[]
  social?: boolean
  version?: boolean
  more?: boolean
}[]

export const TOP_MENU_ITEMS: (
  chainId: SupportedNetworks
) => Record<AppMode, MenuItem> = (chainId: SupportedNetworks) => {
  return {
    [AppMode.B2B]: [
      {
        title: t(
          'components.organisms.topMenu.b2b.menuItems.vaultsManagement.title'
        ),
        url: Routes(chainId).manager.creator.url,
        tabs: [
          {
            title: t(
              'components.organisms.topMenu.b2b.menuItems.vaultsManagement.subMenu.myVaults.title'
            ),
            url: Routes(chainId).manager.creator.url,
          },
        ],
      },
      {
        title: t('components.organisms.topMenu.b2b.menuItems.more.title'),
        version: true,
        more: true,
        submenuSections: [
          [
            {
              title: t(
                'components.organisms.topMenu.b2b.menuItems.more.subMenu.exploreVaults.title'
              ),
              newTab: true,
              url: Routes(chainId).smartVaults.root.url,
            },
            {
              title: t(
                'components.organisms.topMenu.b2b.menuItems.more.subMenu.exploreStrategies.title'
              ),
              newTab: true,
              url: Routes(chainId).strategies.root.url,
            },
          ],
          [
            {
              title: t(
                'components.organisms.topMenu.b2b.menuItems.more.subMenu.documentation.title'
              ),
              newTab: true,
              url: 'https://docs.spool.fi/',
            },
            {
              title: t(
                'components.organisms.topMenu.b2b.menuItems.more.subMenu.homepage.title'
              ),
              newTab: true,
              url: 'https://spool.fi/',
            },
          ],
        ],
      },
    ],
    [AppMode.B2C]: [
      {
        title: t('components.organisms.topMenu.b2c.menuItems.vaults.title'),
        url: Routes(chainId).smartVaults.root.url,
      },
      {
        title: t(
          'components.organisms.topMenu.b2c.menuItems.myPortfolio.title'
        ),
        url: Routes(chainId).dashboard.investor.url,
        tabs: [
          {
            title: t(
              'components.organisms.topMenu.b2c.menuItems.myPortfolio.subMenu.depositDashboard.title'
            ),
            url: Routes(chainId).dashboard.investor.url,
          },
          {
            title: t(
              'components.organisms.topMenu.b2c.menuItems.myPortfolio.subMenu.transactionHistory.title'
            ),
            url: Routes(chainId).dashboard.history.url,
          },
        ],
      },
      {
        title: t('components.organisms.topMenu.b2c.menuItems.dao.title'),
        url: Routes(chainId).staking.root.url,
        submenuSections: [
          [
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.dao.subMenu.staking.title'
              ),
              url: 'https://portal.yelay.io/staking',
              newTab: true,
              mainnetOnly: true,
            },
            /*{
              title: t(
                'components.organisms.topMenu.b2c.menuItems.dao.subMenu.emissionsVoting.title'
              ),
              url: Routes(chainId).governance.voting.url,
              mainnetOnly: true,
            },
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.dao.subMenu.governance.title'
              ),
              newTab: true,
              url: 'https://forum.yelay.io/',
            },*/
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.dao.subMenu.claim.title'
              ),
              newTab: true,
              url: 'https://claim.yelay.io/',
            },
          ],
        ],
      },
      {
        title: t('components.organisms.topMenu.b2c.menuItems.more.title'),
        social: true,
        version: true,
        more: true,
        submenuSections: [
          [
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.more.subMenu.createVault.title'
              ),
              newTab: true,
              url: Routes(chainId).createVault.root.url,
            },
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.more.subMenu.vaultsManagement.title'
              ),
              newTab: true,
              url: Routes(chainId).manager.creator.url,
            },
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.more.subMenu.strategies.title'
              ),
              newTab: true,
              url: Routes(chainId).strategies.root.url,
            },
          ],
          [
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.more.subMenu.documentation.title'
              ),
              newTab: true,
              url: 'https://docs.yelay.io/',
            },
            {
              title: t(
                'components.organisms.topMenu.b2c.menuItems.more.subMenu.homepage.title'
              ),
              newTab: true,
              url: 'https://yelay.io/',
            },
          ],
        ],
      },
    ],
  }
}

export const SocialsMenuIcons = [
  { icon: <Twitter />, url: 'https://twitter.com/YieldLayer' },
  { icon: <Discord />, url: 'https://discord.gg/728EBJvf' },
  { icon: <Telegram />, url: 'https://t.me/YelayChat' },
  {
    icon: <LinkedIn />,
    url: 'https://www.linkedin.com/company/yelay/mycompany',
  },
  { icon: <DeBank />, url: 'https://debank.com/official/Yelay' },
  { icon: <Github />, url: 'https://github.com/YieldLayer' },
]

export const mainnetOnlyRoutes = (chain: SupportedNetworks) => [
  Routes(chain).staking.root.url,
  Routes(chain).governance.voting.url,
]
