import { parseEther } from '@ethersproject/units'
import { Alert, Box } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { constants } from 'ethers/lib/ethers'
import { useEffect, useState } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useStakedSYLay from '@/hooks/staking/useStakedSpool'
import useApproveToken from '@/hooks/web3/useApproveToken'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import TransactionActionButtons from '@/components/molecules/TransactionActionButtons'
import StakingTransactionInput from '@/components/organisms/Staking/StakingActions/StakingTransactionInput'

import { preventOverflow } from '@/utils/web3'

interface StakingTransactionInputContainerProps {
  inputLabel: string
  confirmLabel: string
  tokenId: string
  page?: string
  spenderContractAddress?: string
  isCancelContained?: boolean
  onConfirm: (amount: string) => void
  onCancel: () => void
}

const StakingTransactionInputContainer: React.FC<
  StakingTransactionInputContainerProps
> = ({
  inputLabel,
  confirmLabel,
  tokenId,
  spenderContractAddress,
  page,
  onConfirm,
  onCancel,
}) => {
  const t = useLocale()

  const { decimals } = useTokenDetails(tokenId)

  const [value, setValue] = useState('')
  const [balance, setBalance] = useState(BigNumber.from('0'))

  const { isTokenApproved, approve } = useApproveToken(
    tokenId,
    spenderContractAddress ?? constants.AddressZero,
    value
  )

  const { account } = useWeb3React()

  const { stakedYLay } = useStakedSYLay(account)

  const { balance: userBalance } = useUserBalance(tokenId)

  const setInputValue = (value: string) => {
    setValue(preventOverflow(value, decimals))
  }

  const confirm = () => {
    onConfirm(value)
    setValue('')
  }

  const cancel = () => {
    onCancel()
    setValue('')
  }

  useEffect(() => {
    setBalance(page === 'unstake' ? parseEther(stakedYLay ?? '0') : userBalance)
  }, [page, stakedYLay, userBalance])

  return (
    <Box position='relative' width='100%'>
      <Box display='flex' gap={(theme) => theme.spacing(2)}>
        <StakingTransactionInput
          inputLabel={inputLabel}
          tokenId={tokenId}
          value={value}
          page={page}
          setValue={setInputValue}
        />
        <TransactionActionButtons
          confirmLabel={
            spenderContractAddress && !isTokenApproved
              ? 'Approve'
              : confirmLabel
          }
          disabled={!value || parseEther(value).gt(balance)}
          onConfirm={() =>
            spenderContractAddress && !isTokenApproved
              ? approve(value)
              : confirm()
          }
          onCancel={cancel}
        />
      </Box>
      {value && parseEther(value).gt(balance) && (
        <Alert variant='outlined' severity='error' elevation={6}>
          {t('components.molecules.transactionInputContainer.alert.message')}
        </Alert>
      )}
    </Box>
  )
}

export default StakingTransactionInputContainer
