import { Box, TableCell, TableRow, Typography } from '@mui/material'

interface GuardsActionsTableRowProps {
  conditions: string[]
}

const GuardsActionsTableRow: React.FC<GuardsActionsTableRowProps> = ({
  conditions,
}) => (
  <TableRow sx={{ backgroundColor: '#fff' }}>
    <TableCell>
      <Box>
        {conditions &&
          conditions.map((condition, index) => (
            <Box key={index}>
              <Typography
                variant='body2'
                sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
              >
                {condition}
              </Typography>
            </Box>
          ))}
      </Box>
    </TableCell>
  </TableRow>
)

export default GuardsActionsTableRow
