import { useContext } from 'react'

import { CreatorDashboardContext } from '@/store/creatorVault/creatorDashboard.context'

const useCreatorDashboardState = () => {
  const creatorDashboardContext = useContext(CreatorDashboardContext)
  if (!Object.keys(creatorDashboardContext).length) {
    throw new Error('CreatorDashboardContext must be used within its provider')
  }
  return creatorDashboardContext
}

export default useCreatorDashboardState
