import { SortOrder } from '@solidant/spool-v2-fe-lib'

import {
  StrategiesAction,
  StrategiesActionTypes,
  StrategiesState,
} from '@/store/strategies/strategies.types'

const strategiesReducer = (
  state: StrategiesState,
  action: StrategiesAction
): StrategiesState => {
  switch (action.type) {
    case StrategiesActionTypes.SET_STRATEGIES_PAGE:
      return { ...state, strategiesPage: action.payload }
    case StrategiesActionTypes.SET_STRATEGIES_LIMIT:
      return { ...state, strategiesLimit: action.payload }
    case StrategiesActionTypes.CHANGE_STRATEGIES_SORT_DIRECTION:
      return {
        ...state,
        strategiesSort: {
          ...state.strategiesSort,
          direction:
            state.strategiesSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case StrategiesActionTypes.SET_STRATEGIES_SORT:
      return { ...state, strategiesSort: action.payload }
    case StrategiesActionTypes.SET_STRATEGY_VAULTS_SORT:
      return { ...state, strategyVaultsSort: action.payload }
    case StrategiesActionTypes.SET_FILTER_BY_ASSET_GROUP:
      return { ...state, filterByAssetGroup: action.payload }
    case StrategiesActionTypes.SET_FILTER_BY_SEARCH_TERM:
      return { ...state, filterBySearchTerm: action.payload }
    case StrategiesActionTypes.CHANGE_STRATEGY_VAULTS_SORT_DIRECTION:
      return {
        ...state,
        strategyVaultsSort: {
          ...state.strategyVaultsSort,
          direction:
            state.strategyVaultsSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
  }
}

export default strategiesReducer
