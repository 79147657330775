import { parseUnits } from '@ethersproject/units'
import dayjs from 'dayjs'

import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useIncentivizeVaultState from '@/hooks/context/useIncentivizeVault'
import useModalState from '@/hooks/context/useModalState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { MUTATE_SLEEP_DURATION } from '@/constants'
import { IncentivesPage } from '@/constants/incentives'
import { Routes } from '@/constants/routes'
import { sleep } from '@/utils/web3'

const useAddIncentive = (
  id: string,
  days: string,
  tokenAddress: string,
  amount: string,
  updateIncentivizeVaults: () => void
) => {
  const { chain } = useConnectionState()

  const { openModal, setModalType } = useModalState()

  const { setPathWithHistory } = useUniversalNavigation()

  const { sdk } = useAppState()

  const { setTargetToken, setTokenAmount, setDays, setPage } =
    useIncentivizeVaultState()

  const { decimals } = useTokenDetails(tokenAddress)

  const cancel = () => {
    setTargetToken(undefined)
    setTokenAmount('')
    setDays('')
    setPathWithHistory(Routes(chain).incentives.details.url, { vaultId: id })
    setPage(IncentivesPage.DETAILS)
  }

  const addIncentive = async () => {
    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.IncentivizeToken,
        },
      })

      const endTime = dayjs().add(parseInt(days), 'day').unix()
      const tx = await sdk.smartVault.addIncentiveToken(
        id,
        tokenAddress,
        endTime,
        parseUnits(amount, decimals).toString()
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.IncentivizeToken,
        },
      })

      await tx.wait()

      // delay so that subgraph gets updated
      await sleep(MUTATE_SLEEP_DURATION)

      await updateIncentivizeVaults()

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.IncentivizeToken,
        },
      })

      cancel()
    } catch (error) {
      //setError(error);
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.IncentivizeToken,
        },
      })
    }
  }

  return addIncentive
}

export default useAddIncentive
