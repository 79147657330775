import { parseUnits } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import { constants } from 'ethers'

import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useModalState from '@/hooks/context/useModalState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useDebounce from '@/hooks/utils/useDebounce'
import useDepositReceipts from '@/hooks/vaultList/Transactions/useDepositReceipts'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { SWAP_AND_DEPOSIT_FLUSH } from '@/config/flushes.config'
import { WRAPPED_TOKEN_ADDRESS } from '@/constants/tokens'
import { getIsNativeCurrency, sleep } from '@/utils/web3'

const useSwapAndDeposit = () => {
  const { chain } = useConnectionState()

  const { openModal, setModalType } = useModalState()

  const { sdk } = useAppState()
  // ARB
  const { account } = useWeb3React()

  const { smartVaultData } = useVaultsPage()

  const { singleDeposit, setSingleDepositAmount } = useDepositState()

  const { address, assetGroup } = smartVaultData

  const { depositReceipts, updateDepositReceipts } = useDepositReceipts(address)

  const { decimals } = useTokenDetails(singleDeposit.tokenAddress)

  const selectedVaultAsset = assetGroup.assetGroupTokens[0].address

  const isVaultAssetWETH = selectedVaultAsset === WRAPPED_TOKEN_ADDRESS[chain]

  const debouncedAmount = useDebounce(
    singleDeposit.amount
      ? parseUnits(singleDeposit.amount, decimals).toString()
      : ''
  )

  const swapAndDeposit = async () => {
    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.SwapAndDeposit,
        },
      })
      const tx = await sdk.smartVault.singleAssetSwapAndDeposit(
        getIsNativeCurrency(singleDeposit.tokenAddress)
          ? WRAPPED_TOKEN_ADDRESS[chain]
          : singleDeposit.tokenAddress,
        selectedVaultAsset,
        debouncedAmount,
        // 1st condition: if is nativeCurrency, then go into nested condition, else use the token address
        // nested condition:  if the selectedVaultAsset is weth, then we just do wrapping and depositing,
        // hence, we use empty array
        getIsNativeCurrency(singleDeposit.tokenAddress)
          ? isVaultAssetWETH
            ? []
            : [WRAPPED_TOKEN_ADDRESS[chain]]
          : [singleDeposit.tokenAddress],
        // if is nativeCurrency and the selectedVaultAsset is WETH, we wrap and deposit
        // hence an empty array
        getIsNativeCurrency(singleDeposit.tokenAddress)
          ? isVaultAssetWETH
            ? []
            : ['0']
          : [parseUnits(singleDeposit.amount, decimals).toString()],
        // if is nativeCurrency and the selectedVaultAsset is WETH, we wrap and deposit
        // we don't need swapData for the tx, hence an empty array
        !(getIsNativeCurrency(singleDeposit.tokenAddress) && isVaultAssetWETH),
        address,
        account,
        // flush permenantly set to false
        SWAP_AND_DEPOSIT_FLUSH,
        constants.AddressZero,
        // if we are using nativeCurrency to deposit then we specify the { value: ethValue },
        // else it is empty
        getIsNativeCurrency(singleDeposit.tokenAddress)
          ? parseUnits(singleDeposit.amount, decimals).toString()
          : undefined,
        chain
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.SwapAndDeposit,
        },
      })

      await tx.wait()

      await sleep(5000)

      await updateDepositReceipts(depositReceipts)

      setSingleDepositAmount({
        ...singleDeposit,
        amount: '',
      })

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.SwapAndDeposit,
          txHash: tx.hash,
        },
      })
    } catch (error) {
      console.error(error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.SwapAndDeposit,
        },
      })
    }
  }

  return swapAndDeposit
}

export default useSwapAndDeposit
