import { TimeConversions } from '@solidant/spool-v2-fe-lib'

export const LIMIT = 1000
export const SC_PERCENTAGE_MULTIPLIER = 100
export const MUTATE_SLEEP_DURATION = 3000
export const NOT_AVILABLE = 'N/A'

// DAO params
export const ECOSYSTEM_FEE = 8
export const PLATFORM_FEE = 2
export const DEFAULT_MANAGEMENT_FEE = 0
export const DEFAULT_PERFORMANCE_FEE = 0
export const DAO_FEES = ECOSYSTEM_FEE + PLATFORM_FEE

// UI
export const MAX_RESOLUTION_WIDTH = 1512
export const DEFAULT_ROW_HEIGHT = '53px'
export const DEFAULT_INPUT_AMOUNT = '0.00'
export const DEFAULT_DECIMAL = '18'
export const DEFAULT_DISPLAY_DECIMAL = 6
export const DEFAULT_GOVERNANCE_PLACEHOLDER = 'voSPOOL Amount'

// Smart vault
export const MAX_VAULT_NAME_LENGTH = 50

// Incentivize Rewards
export const REWARD_RATE_ADDITIONAL_BIG_NUMBERS = 6

// TIME
export const DAYS_PER_YEAR = 365
export const SECONDS_TO_MILISECONDS_MULTIPLIER = 1000
export const ELEVEN_MINUTES_IN_SECONDS = TimeConversions.SECONDS_PER_MINUTE * 11
