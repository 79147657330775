import { parseEther } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import useSWR from 'swr'

import useNativeCurrency from '@/hooks/web3/useNativeCurrency'

import erc20ABI from '@/abis/erc20ABI'
import { MUTATE_SLEEP_DURATION } from '@/constants'
import { NATIVE_TOKEN } from '@/constants/tokens'
import { fetcher, sleep } from '@/utils/web3'

const TWENTY_SECONDS_MS = 20000

const useUserBalance = (tokenAddress: string | undefined) => {
  const { account, provider } = useWeb3React()

  const { nativeCurrency } = useNativeCurrency()

  const {
    data: balance,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<BigNumber>(
    [tokenAddress ?? '', 'balanceOf', account],
    (args: any) => {
      if (tokenAddress === NATIVE_TOKEN) {
        return nativeCurrency?.balance
      }
      return fetcher(provider, erc20ABI)(args)
    },
    {
      fallbackData: parseEther('0'),
      refreshInterval: TWENTY_SECONDS_MS,
      dedupingInterval: TWENTY_SECONDS_MS,
    }
  )

  const updateUserBalance = async () => {
    let retries = 0
    do {
      const currentValue = await mutate()
      if (!currentValue.eq(balance)) {
        break
      }
      retries++
      await sleep(MUTATE_SLEEP_DURATION)
    } while (retries < 10)
  }

  return { balance, updateUserBalance, isLoading, isValidating }
}

export default useUserBalance
