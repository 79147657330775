import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import '@/css/App.scss'

import Tracking from '@/components/atoms/Tracking'

import { NavigationProvider } from '@/store/navigation/navigation.provider'
import { RewardsTableProvider } from '@/store/rewardsTable/rewardsTable.provider'
import { SmartVaultRowProvider } from '@/store/smartVaultRow/smartVaultRow.provider'
import { SmartVaultsTableProvider } from '@/store/smartVaultsTable/smartVaultsTable.provider'
import { TopMenuProvider } from '@/store/topMenu/topMenu.provider'

import Layout from '@/layouts/Layout'
import AppRoutes from '@/routes/AppRoutes'
import RemoveTrailingSlash from '@/routes/RemoveTrailingSlash'

const App = () => {
  return (
    <Router>
      <RemoveTrailingSlash />
      <Suspense fallback={<div>Loading...</div>}></Suspense>
      <Tracking />
      <NavigationProvider>
        <TopMenuProvider>
          <SmartVaultsTableProvider>
            <SmartVaultRowProvider>
              <RewardsTableProvider>
                <Layout>
                  <AppRoutes />
                </Layout>
              </RewardsTableProvider>
            </SmartVaultRowProvider>
          </SmartVaultsTableProvider>
        </TopMenuProvider>
      </NavigationProvider>
    </Router>
  )
}

export default App
