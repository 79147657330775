import { Box, styled } from '@mui/material'

const SliderOuterBorder = styled(Box)(() => ({
  position: 'absolute',
  width: 'calc(100% - 10px)',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  left: '5px',
  height: '53px',
  borderRadius: '4px',
}))

export default SliderOuterBorder
