import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
} from '@ethersproject/units'
import { BigNumber } from 'ethers/lib/ethers'

import { MultiAssetDeposit } from '@/store/deposit/deposit.types'

import { INPUT_FIXED_DECIMALS } from '@/constants/smartVault'
import {
  fixStringDecimals,
  getAssetAmountFromPrice,
  getPricePerAssetAmount,
  preventOverflow,
} from '@/utils/web3'

export const getTotalAssetAmountToUSD = (
  multiDepositAmounts: MultiAssetDeposit
) =>
  Object.values(multiDepositAmounts).reduce(
    (acc, { amount, decimals, priceFeed }) => {
      const tokenToUSD = getPricePerAssetAmount(
        amount ? parseUnits(amount, decimals).toString() : '0',
        priceFeed,
        decimals
      )

      return acc.add(parseEther(tokenToUSD))
    },
    BigNumber.from('0')
  )

export const getLatestMultiDepositAmount = (
  multiAssetDeposit: MultiAssetDeposit,
  depositAmount: string,
  allocation: string[],
  address: string,
  maintainAllocation = true
) =>
  Object.keys(multiAssetDeposit).reduce((acc, curr) => {
    if (address !== curr && maintainAllocation) {
      // return acc
      return {
        ...acc,
        [curr]: {
          ...acc[curr],
          // get ratio for each input
          amount: depositAmount
            ? (+formatUnits(
                parseEther(depositAmount)
                  .mul(parseEther('1'))
                  .div(parseEther(allocation[multiAssetDeposit[address].index]))
                  .mul(parseEther(allocation[acc[curr].index])),
                36
              ))
                .toFixed(
                  parseEther(depositAmount).isZero() ? 2 : INPUT_FIXED_DECIMALS
                )
                .toString()
            : '0.00',
        },
      }
    }

    if (address === curr) {
      return {
        ...acc,
        [curr]: {
          ...acc[curr],
          amount: preventOverflow(
            depositAmount,
            multiAssetDeposit[curr].decimals
          ),
        },
      }
    }
    return acc
  }, multiAssetDeposit)

export const getRatioToAssetAmount = (
  depositAmount: string,
  ratio: string,
  priceFeed: string,
  decimals: number
) => {
  const amountInUSD = fixStringDecimals(
    formatEther(
      parseEther(depositAmount ?? '0')
        .mul(parseEther(ratio))
        .div(parseEther('1'))
    )
  )

  //convert USD amount for each input to token amount
  const amountFromUSD = getAssetAmountFromPrice(
    amountInUSD,
    priceFeed,
    decimals
  )

  return (+amountFromUSD).toFixed(INPUT_FIXED_DECIMALS).toString()
}

export const setMultiDepositInput = (
  latestMultiDepositAmount: MultiAssetDeposit,
  setMultiAssetDeposit: (
    value: React.SetStateAction<MultiAssetDeposit>
  ) => void,
  setMultiAssetUsdDepositAmount: (multiAssetUsdDepositAmount: string) => void
) => {
  // if maintainAssetAllocation is true, then set values based on the ratio for the other inputs.
  setMultiAssetDeposit(latestMultiDepositAmount)

  const totalAssetAmountToUSD = getTotalAssetAmountToUSD(
    latestMultiDepositAmount
  )

  setMultiAssetUsdDepositAmount(
    fixStringDecimals(
      formatEther(totalAssetAmountToUSD),
      totalAssetAmountToUSD.isZero() ? 2 : INPUT_FIXED_DECIMALS
    )
  )
}
