import { formatUnits } from '@ethersproject/units'
import { Box, Typography } from '@mui/material'
import { memo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import { mapNativeCurrencyIcons } from '@/components/atoms/NativeCurrencyIcon'
import { SpoolSelectCoinIcon } from '@/components/atoms/SpoolSelectCoinIcon'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import ChevronRight from '@/assets/icons/general/ChevronRight'

import { formatUSD } from '@/utils/formats'

interface AssetMenuItemProps {
  assetId: string
}

const AssetMenuItem: React.FC<AssetMenuItemProps> = ({ assetId }) => {
  const { balance } = useUserBalance(assetId)

  const { decimals, symbol } = useTokenDetails(assetId)

  const { chain } = useConnectionState()

  const isNative = assetId === 'native'

  const tokenBalance =
    balance && !balance.isZero() && decimals
      ? formatUnits(balance, decimals)
      : 0.0

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
    >
      <Box display='flex'>
        <SpoolSelectCoinIcon disableRipple>
          {!isNative
            ? mapAssetTokenIcon(chain, assetId)
            : mapNativeCurrencyIcons(chain)}
        </SpoolSelectCoinIcon>
        <Typography variant='body2' sx={{ marginTop: 'auto' }}>
          {balance && symbol ? `${symbol}` : ''}
        </Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <Typography
          variant='caption'
          sx={{
            color: 'text.secondary',
            marginTop: 'auto',
            overflow: 'hidden',
            marginLeft: 'auto',
            marginRight: '0.5rem',
          }}
        >
          {formatUSD(tokenBalance, 2)}
        </Typography>
        <ChevronRight />
      </Box>
    </Box>
  )
}

export default memo(AssetMenuItem)
