import { Box } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { isAddress } from 'web3-validator'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useSmartVaults from '@/hooks/smartVaults/useSmartVaults'

import NavigationSection from '@/components/molecules/NavigationSection'
import SectionColumn from '@/components/molecules/SectionColumn'
import Filters from '@/components/organisms/SmartVaults/Filters'
import SmartVaultDataLoading from '@/components/organisms/SmartVaults/SmartVaultDataLoading'
import SmartVaultErrorWithText from '@/components/organisms/SmartVaults/SmartVaultErrorWithText'
import Table, { HIDDEN_VAULTS } from '@/components/organisms/SmartVaults/Table'

import { Routes } from '@/constants/routes'

const VaultsOverview = () => {
  const { chain } = useConnectionState()

  const { belowTabletBreakpoint } = useBreakpointDetection()

  const {
    page,
    limit,
    assetGroupId,
    riskModelProviderId,
    smartVaultsTableSort,
    search,
  } = useSmartVaultsTableState()
  const { account } = useWeb3React()
  const {
    smartVaults,
    totalVaults,
    totalTvr,
    totalClaimableRewards,
    totalUserBalance,
    error,
  } = useSmartVaults(
    page,
    limit,
    assetGroupId,
    riskModelProviderId,
    smartVaultsTableSort,
    search,
    account
  )
  const t = useLocale()

  return (
    <>
      <SectionColumn
        sx={{ gap: 0, mt: '-9rem', bgcolor: 'white' }}
        noPadding
        noGap
      >
        <Filters />
        {error ? (
          <SmartVaultErrorWithText errorText='Something went wrong when loading Smart Vaults' />
        ) : smartVaults ? (
          !smartVaults.length ? (
            <SmartVaultErrorWithText errorText='No vaults found' />
          ) : (
            <Table
              smartVaults={smartVaults}
              totalVaults={
                isAddress(search) &&
                smartVaults &&
                smartVaults?.every(
                  ({ address }) => address === search.toLowerCase()
                )
                  ? 1
                  : smartVaults?.filter(({ address }) =>
                      HIDDEN_VAULTS.includes(address)
                    ).length
                  ? totalVaults - 1
                  : totalVaults
              }
              totalTvr={totalTvr}
              totalClaimableRewards={totalClaimableRewards}
              totalUserBalance={totalUserBalance}
            />
          )
        ) : (
          <SmartVaultDataLoading loadingText='Loading Smart Vaults' />
        )}
      </SectionColumn>
      <Box
        display={`${belowTabletBreakpoint ? 'flex' : 'grid'}`}
        flexDirection={`${belowTabletBreakpoint ? 'column' : 'row'}`}
        gridTemplateColumns='1fr 1fr'
        gap='1rem'
        mb={`${belowTabletBreakpoint ? '6rem' : '2rem'}`}
      >
        <NavigationSection
          title={t(
            'pages.smartVaults.vaultsOverview.stakeSpoolNavigation.title'
          )}
          description={t(
            'pages.smartVaults.vaultsOverview.stakeSpoolNavigation.description'
          )}
          toSectionText={t(
            'pages.smartVaults.vaultsOverview.stakeSpoolNavigation.toSectionText'
          )}
          toSection={Routes(chain).staking.root.url}
          logo={<></>}
        />
        <NavigationSection
          title={t(
            'pages.smartVaults.vaultsOverview.createVaultNavigation.title'
          )}
          description={t(
            'pages.smartVaults.vaultsOverview.createVaultNavigation.description'
          )}
          toSectionText={t(
            'pages.smartVaults.vaultsOverview.createVaultNavigation.toSectionText'
          )}
          toSection={Routes(chain).createVault.root.url}
          logo={<></>}
          openInNewTab={true}
        />
      </Box>
    </>
  )
}

export default VaultsOverview
