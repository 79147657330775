import { HistoricalDeposit, SortOrder } from '@solidant/spool-v2-fe-lib'
import { BigNumber } from 'ethers'

import { SortType } from '@/types/table'

export type Deposit = {
  tokenAddress: string
  amount: string
}

export type MultiAssetDeposit = {
  [key: string]: {
    amount: string
    ratio: string
    index: number
    decimals: number
    priceFeed: string
  }
}

export type MultiAssetPriceFeeds = {
  [key: string]: {
    priceFeed: string
  }
}

export type MultiAssetTokenBalances = {
  [key: string]: { amount: string; balance: BigNumber; decimals: number }
}

export interface DepositState {
  singleDeposit: Deposit
  multiAssetUsdDepositAmount: string
  depositReceiptPage: number
  depositReceiptLimit: number
  depositReceiptSort: SortType<HistoricalDeposit>
  multiAssetSwap: Deposit
}

export interface DepositContextProps extends DepositState {
  setSingleDepositAmount: (singleDeposit: Deposit) => void
  setMultiAssetUsdDepositAmount: (multiAssetUsdDepositAmount: string) => void
  handleSortClick: (
    key: keyof HistoricalDeposit,
    depositReceiptSort: SortType<HistoricalDeposit>
  ) => void
  setDepositReceiptPage: (depositReceiptPage: number) => void
  setDepositReceiptLimit: (depositReceiptLimit: number) => void
  setMultiAssetSwap: (multiAssetSwap: Deposit) => void
}

export enum DepositActionTypes {
  SET_SINGLE_DEPOSIT_AMOUNT = 'SET_SINGLE_DEPOSIT_AMOUNT',
  SET_MULTI_ASSET_USD_DEPOSIT_AMOUNT = 'SET_MULTI_ASSET_USD_DEPOSIT_AMOUNT',
  SET_DEPOSIT_RECEIPT_SORT = 'SET_DEPOSIT_RECEIPT_SORT',
  CHANGE_DEPOSIT_SORT_DIRECTION = 'CHANGE_DEPOSIT_SORT_DIRECTION',
  SET_DEPOSIT_RECEIPT_PAGE = 'SET_DEPOSIT_RECEIPT_PAGE',
  SET_DEPOSIT_RECEIPT_LIMIT = 'SET_DEPOSIT_RECEIPT_LIMIT',
  SET_MULTI_ASSET_SWAP_DEPOSIT = 'SET_MULTI_ASSET_SWAP_DEPOSIT',
}

export type DepositAction =
  | {
      type: DepositActionTypes.SET_SINGLE_DEPOSIT_AMOUNT
      payload: Deposit
    }
  | {
      type: DepositActionTypes.SET_DEPOSIT_RECEIPT_SORT
      payload: {
        key: keyof HistoricalDeposit
        direction: SortOrder
      }
    }
  | {
      type: DepositActionTypes.CHANGE_DEPOSIT_SORT_DIRECTION
    }
  | {
      type: DepositActionTypes.SET_DEPOSIT_RECEIPT_PAGE
      payload: number
    }
  | {
      type: DepositActionTypes.SET_DEPOSIT_RECEIPT_LIMIT
      payload: number
    }
  | {
      type: DepositActionTypes.SET_MULTI_ASSET_USD_DEPOSIT_AMOUNT
      payload: string
    }
  | {
      type: DepositActionTypes.SET_MULTI_ASSET_SWAP_DEPOSIT
      payload: Deposit
    }
