import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Checkbox, Chip, Typography } from '@mui/material'
import { ApiTransactionType } from '@solidant/spool-v2-fe-lib'

import useLocale from '@/hooks/context/useLocale'
import useTransactionHistoryState from '@/hooks/context/useTransactionHistoryState'

import NameCellTypography from '@/components/atoms/NameCell'
import SpoolMultiSelect from '@/components/atoms/SpoolMultiSelect'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'
import { StyledMenuItem } from '@/components/organisms/TransactionHistory/TransactionHistoryFilters/TransactionHistoryFilters'

import { transactionType } from '@/constants/dashboard'
import { gaIds } from '@/constants/googleAnalyticIDs'

const TransactionTypesFilters = () => {
  const { filteredTransactionTypes, setTransactionTypes } =
    useTransactionHistoryState()
  const t = useLocale()

  const handleFilteredTransactionTypeDelete = (
    e: React.MouseEvent,
    value: string
  ) => {
    e.preventDefault()
    const newFilteredTransactionTypes = filteredTransactionTypes.filter(
      (transactionType) => transactionType !== value
    )
    setTransactionTypes(
      newFilteredTransactionTypes.length ? newFilteredTransactionTypes : ['all']
    )
  }

  return (
    <SpoolMultiSelect
      label={t(
        'components.organisms.transactionHistory.filters.transactionType.label'
      )}
      multiple
      value={
        filteredTransactionTypes?.length
          ? filteredTransactionTypes
          : ['All Transaction Types']
      }
      handleChange={(event) => {
        const {
          target: { value },
        } = event
        setTransactionTypes(
          // On autofill, we get a stringified value.
          typeof value === 'string' ? value.split(',') : (value as string[])
        )
      }}
      renderValue={(selected) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.2rem' }}>
            {(selected as string[]).map((value) => {
              if (value === 'All Transaction Types') {
                return (
                  <Typography key='all' variant='body2'>
                    All Transaction Types
                  </Typography>
                )
              }

              return (
                <Chip
                  sx={{
                    height: '30px',
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                  key={value}
                  label={
                    <Typography
                      key='all'
                      variant='body2'
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                      }}
                    >
                      {transactionType[value as ApiTransactionType]}
                    </Typography>
                  }
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event: any) => event.stopPropagation()}
                    />
                  }
                  onDelete={(event) =>
                    handleFilteredTransactionTypeDelete(event, value)
                  }
                />
              )
            })}
          </Box>
        )
      }}
    >
      <StyledMenuItem value='all'>
        <Typography variant='body2'>
          {t(
            'components.organisms.transactionHistory.filters.transactionType.all'
          )}
        </Typography>
      </StyledMenuItem>
      {Object.keys(transactionType).map((transactionTypeKey, index) => (
        <StyledMenuItem
          id={
            gaIds.filter.main +
            '-' +
            gaIds.filter.page.transactionHistory +
            '-' +
            gaIds.filter.transactionType
          }
          key={transactionTypeKey + index}
          value={transactionTypeKey}
        >
          <Checkbox
            sx={{
              '& .MuiCheckbox-root': {
                padding: 'none',
              },
            }}
            checked={filteredTransactionTypes?.indexOf(transactionTypeKey) > -1}
          />
          <Box
            display='flex'
            width='100%'
            alignItems='center'
            justifyContent='space-between'
          >
            <NameCellContent>
              <NameCellTypography variant='body2'>
                {transactionType[transactionTypeKey as ApiTransactionType]}
              </NameCellTypography>
            </NameCellContent>
          </Box>
        </StyledMenuItem>
      ))}
    </SpoolMultiSelect>
  )
}

export default TransactionTypesFilters
