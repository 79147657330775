import useAppState from '@/hooks/context/useAppState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useModalState from '@/hooks/context/useModalState'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

const useManageMetavaultVaults = () => {
  const { openModal, closeModal, setModalType } = useModalState()

  const { sdkExternal } = useAppState()

  const { selectedVaults, selectedVaultsAllocations, metavaultId } =
    useCreateMetavaultState()

  const { updateMetavaultVaults, vaults } = useCreatorDashboard()

  const handleManageVaults = async () => {
    const vaultAddresses = selectedVaults.map((vault) => vault.id)

    const allocations = selectedVaultsAllocations.map((allocation) =>
      Math.round(allocation * 100)
    )

    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.ManageMetavaultVaults,
        },
      })

      const tx =
        await sdkExternal.mutations.metaVaults.manageMetaVaultSmartVaults(
          metavaultId,
          vaultAddresses,
          allocations
        )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.ManageMetavaultVaults,
        },
      })

      await tx.wait()

      const prevMetaVaultVaults = vaults.find(
        ({ address }) => address === metavaultId
      )

      await updateMetavaultVaults(prevMetaVaultVaults)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.ManageMetavaultVaults,
        },
      })
      closeModal(Modals.METAVAULT_MODAL)
    } catch (err) {
      console.error(err)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.ManageMetavaultVaults,
        },
      })
    }
  }

  return handleManageVaults
}

export default useManageMetavaultVaults
