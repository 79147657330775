import { formatEther, formatUnits, parseUnits } from '@ethersproject/units'
import { BigNumber } from 'ethers/lib/ethers'
import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

const useMultiAssetDepositRatio = (
  vaultAddress: string,
  assetDecimals: number[]
) => {
  const { sdk } = useContext(AppContext)

  const { data, error } = useSWR(
    sdk && vaultAddress && assetDecimals
      ? ['depositSwapConversionAmount', vaultAddress, assetDecimals]
      : null,
    async ([_, vaultAddress, assetDecimals]) => {
      const depositRatio = await sdk.smartVault.getDepositRatio(vaultAddress)

      const largestDecimalIndex = Math.max(...assetDecimals)

      // format ratios so that they have the same units
      const formattedRatios = depositRatio.map((ratio, index) =>
        parseUnits(
          formatUnits(ratio, assetDecimals[index]),
          largestDecimalIndex
        )
      )

      // get total of ratio
      const total = formattedRatios.reduce(
        (acc, curr) => acc.add(curr),
        BigNumber.from('0')
      )

      // array of ratio values in decimals
      const ratio = formattedRatios.map((ratio) =>
        formatEther(ratio.mul(parseUnits('1')).div(total))
      )

      return ratio
    }
  )

  return {
    multiAssetDepositRatio: data,
    isLoading: !error && !data,
    error,
  }
}

export default useMultiAssetDepositRatio
