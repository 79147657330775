import { TableCell, TableFooter, TableRow, Typography } from '@mui/material'

import { formatUSD } from '@/utils/formats'

const RiskAllocationTableFooter: React.FC<{ totalVal: number }> = ({
  totalVal,
}) => (
  <TableFooter>
    <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)' }}>
      <TableCell
        key='strategies'
        sx={{
          width: 'auto',
          textAlign: 'left',
          fontWeight: 500,
          color: 'black',
        }}
      >
        <Typography variant='tableHeader'>Total</Typography>
      </TableCell>
      <TableCell />
      <TableCell
        sx={{
          width: 'auto',
          textAlign: 'right',
          fontWeight: 500,
          color: 'black',
        }}
      >
        <Typography variant='tableHeader'>${formatUSD(totalVal)}</Typography>
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  </TableFooter>
)

export default RiskAllocationTableFooter
