import { Box, styled } from '@mui/material'

const Top = styled(Box)(({ theme }) => ({
  '.part-1': {
    width: 'inherit',
    height: '6px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '1px',
    borderBottomRightRadius: '1px',
    backgroundColor: theme.palette.primary.main,
  },
  '.part-2': {
    width: '0',
    height: '0',
    borderLeft: '10.5px solid transparent',
    borderRight: '10.5px solid transparent',
    borderTop: `10px solid ${theme.palette.primary.main}`,
  },
}))

export default Top
