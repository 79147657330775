import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { constants } from 'ethers'
import { isAddress } from 'ethers/lib/utils'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'

import { AppContext } from '@/store/app/app.context'

import { RISK_PROVIDERS } from '@/config/sdk'

import { SmartVaultData } from '@/types/smart-vault'

const useVaultsPage = () => {
  const { chain } = useConnectionState()

  const { sdk } = useContext(AppContext)
  const { account } = useWeb3React()

  const { selectedVault } = useSmartVaultsTableState()

  const { vaultId } = useParams()

  const { data, error } = useSWR(
    sdk && vaultId
      ? ['smartVault', selectedVault, vaultId, account, chain]
      : null,
    async () => {
      let vaultBaseDetails = selectedVault

      if (!isAddress(vaultId)) {
        return {} as SmartVaultData
      }

      if (!selectedVault) {
        const vaultBase = await sdk.smartVault.getVaults({
          offset: 0,
          limit: 1,
          vaultAddresses: [vaultId],
          userAddress: account ?? '',
          sortBy: 'tvr',
          sortOrder: SortOrder.DESC,
        })

        if (!vaultBase.vaults.length) {
          return {} as SmartVaultData
        }

        vaultBaseDetails = vaultBase.vaults[0]
      }

      const historicalTvr = await sdk.dashboard.getCreatorTvrHistory(
        account ?? constants.AddressZero,
        [vaultId],
        1
      )

      //TODO
      const vaultDetails = await sdk.smartVault.getVaultDetails(
        vaultId,
        vaultBaseDetails.riskModel?.address
          ? vaultBaseDetails.riskModel?.address
          : RISK_PROVIDERS[chain],
        account ?? undefined
      )

      return {
        ...vaultBaseDetails,
        ...vaultDetails,
        historicalTvr,
      } as SmartVaultData
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    smartVaultData: data,
    isLoading: !error && !data,
    error,
  }
}

export default useVaultsPage
