import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

//wallets that uses the genericInjectedConnectors class
export enum GenericInjectedConnectorWallets {
  BITKEEP = 'bitkeep',
  NABOX = 'nabox',
  OKX = 'okx',
}

// Supported web3 wallets
export enum ConnectionType {
  EIP_6963_INJECTED = 'EIP_6963_INJECTED',
  INJECTED = 'INJECTED',
  COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  NETWORK = 'NETWORK',
  GNOSIS_SAFE = 'GNOSIS_SAFE',
  LEDGER_IFRAME = 'LEDGER_IFRAME',
  NABOX = 'NABOX',
  BITKEEP = 'BITKEEP',
  OKX = 'OKX',
}

export enum TokenAddress {
  SPOOL = '0x40803cea2b2a32bda1be61d3604af6a814e70976',
}

export const ZeroAddress = '0x0000000000000000000000000000000000000000'

export const CHAIN_EXPLORER = {
  [SupportedNetworks.ETHEREUM]: {
    url: 'https://etherscan.io/',
    name: 'Etherscan',
  },
  [SupportedNetworks.ARBITRUM]: {
    url: 'https://arbiscan.io/',
    name: 'Arbiscan',
  },
  [SupportedNetworks.SEPOLIA]: {
    url: 'https://sepolia.etherscan.io/',
    name: 'Sepolia',
  },
}

export const CHAIN_EXPLORER_NAME = {
  [SupportedNetworks.ETHEREUM]: 'Etherscan',
  [SupportedNetworks.ARBITRUM]: 'Arbiscan',
  [SupportedNetworks.SEPOLIA]: 'Sepolia',
}
