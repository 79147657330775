import { Box, Tab, Tabs } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useMemo, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import usePendingWithdrawals from '@/hooks/smartVaults/usePendingWithdrawals'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import { BorderedBox } from '@/components/atoms/BorderedBox'
import ConditionWarning from '@/components/organisms/SmartVaults/VaultActions/ConditionWarning'
import Deposit from '@/components/organisms/SmartVaults/VaultActions/Deposit/Deposit'
import WithdrawLayout from '@/components/organisms/SmartVaults/VaultActions/Withdraw/WithdrawLayout'
import WithdrawOrClaim from '@/components/organisms/SmartVaults/VaultActions/Withdraw/WithdrawOrClaim'
import WithdrawReceipts from '@/components/organisms/SmartVaults/VaultActions/Withdraw/WithdrawReceipts'

export enum VaultActionType {
  DEPOSIT = 'Deposit',
  WITHDRAW = 'Withdraw',
  WITHDRAW_OR_CLAIM = 'WITHDRAW_OR_CLAIM',
}

const VAULT_DETAILS_PAGE_ACTIONS = [
  VaultActionType.DEPOSIT,
  VaultActionType.WITHDRAW,
]

export enum UNFULFILLED_CONDITION {
  WRONG_NETWORK = 'WRONG_NETWORK',
  GUARD_CONDITIONS_UNFULFILLED = 'GUARD_CONDITIONS_UNFULFILLED',
  EXTERNAL_PARTNER = 'EXTERNAL_PARTNER',
  NOT_CONNECTED = 'NOT_CONNECTED',
  STRATEGY_BLOCKED = 'STRATEGY_BLOCKED',
}

const VaultActions = () => {
  const { chain } = useConnectionState()

  const [action, setAction] = useState(VaultActionType.DEPOSIT)
  const [unfulfilledCondition, setUnfulfilledCondition] =
    useState<UNFULFILLED_CONDITION | null>(null)

  const { chainId, account } = useWeb3React()

  const { smartVaultData } = useVaultsPage()

  const { pendingWithdrawals, isLoading } = usePendingWithdrawals(
    smartVaultData.address,
    account
  )

  const { guardConditions, vaultDetails, strategiesAllocation } = smartVaultData

  const handleChange = (_: any, value: VaultActionType) => {
    if (value === VaultActionType.WITHDRAW) {
      if (!Number(smartVaultData.incentivizedRewards.totalClaimableAmountUSD)) {
        setAction(VaultActionType.WITHDRAW)
        return
      }
      setAction(VaultActionType.WITHDRAW_OR_CLAIM)
      return
    }

    setAction(value)
  }

  const errorState = useMemo(
    () =>
      strategiesAllocation?.strategies.some(
        (strategy) => strategy.errorState && strategy.errorState !== 'normal'
      ),
    [strategiesAllocation]
  )

  useEffect(() => {
    if (
      action === VaultActionType.DEPOSIT &&
      (vaultDetails?.vaultSource === 'drip' ||
        vaultDetails?.vaultSource === 'nuklai')
    ) {
      setUnfulfilledCondition(UNFULFILLED_CONDITION.EXTERNAL_PARTNER)
      return
    }
    //ARB
    if (chainId !== chain) {
      setUnfulfilledCondition(UNFULFILLED_CONDITION.WRONG_NETWORK)
      return
    }
    if (!account) {
      setUnfulfilledCondition(UNFULFILLED_CONDITION.NOT_CONNECTED)
      return
    }
    if (action === VaultActionType.DEPOSIT && errorState) {
      setUnfulfilledCondition(UNFULFILLED_CONDITION.STRATEGY_BLOCKED)
      return
    }

    if (
      action === VaultActionType.DEPOSIT &&
      guardConditions.conditions &&
      guardConditions.conditions.length &&
      guardConditions?.conditions.some(
        (guardCondition: any) => !guardCondition.isFulfilled
      )
    ) {
      setUnfulfilledCondition(
        UNFULFILLED_CONDITION.GUARD_CONDITIONS_UNFULFILLED
      )
      return
    }

    setUnfulfilledCondition(null)
  }, [chainId, guardConditions, action, account, smartVaultData, errorState])

  return (
    <Box display='flex' flexDirection='column' gap='1rem' position='sticky'>
      <Box
        display='flex'
        flexDirection='column'
        position='sticky'
        top='100px'
        marginTop='2rem'
        gap='1rem'
      >
        <BorderedBox>
          <Tabs
            value={
              action === VaultActionType.WITHDRAW_OR_CLAIM
                ? VaultActionType.WITHDRAW
                : action
            }
            onChange={handleChange}
            centered
            indicatorColor='primary'
            variant='fullWidth'
            sx={{
              width: '100%',
              borderBottom: '1px solid rgba(65, 61, 61, 0.12)',
            }}
          >
            {VAULT_DETAILS_PAGE_ACTIONS.map((action, index) => (
              <Tab
                key={index}
                label={action}
                value={action}
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  '&:focus': {
                    outline: 'none',
                  },
                  '&.Mui-selected': {
                    outline: 'none',
                  },
                }}
              />
            ))}
          </Tabs>
          {unfulfilledCondition ? (
            <ConditionWarning unfulfilledCondition={unfulfilledCondition} />
          ) : action === VaultActionType.DEPOSIT ? (
            <Deposit />
          ) : action === VaultActionType.WITHDRAW ? (
            <WithdrawLayout setAction={setAction} />
          ) : (
            <WithdrawOrClaim setAction={setAction} />
          )}
        </BorderedBox>
        {!isLoading && !!pendingWithdrawals.length && <WithdrawReceipts />}
      </Box>
    </Box>
  )
}

export default VaultActions
