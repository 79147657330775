import { useContext } from 'react'

import { CreateVaultContext } from '@/store/createVault/createVault.context'

const useCreateVaultState = () => {
  const createVaultContext = useContext(CreateVaultContext)
  if (!Object.keys(createVaultContext).length) {
    throw new Error('CreateVaultContext must be used within its provider')
  }
  return createVaultContext
}

export default useCreateVaultState
