import { Box } from '@mui/material'

interface TooltipGridProps {
  title?: string
  items: {
    title: React.ReactNode
    subtitle?: React.ReactNode
    value: React.ReactNode
  }[]
  borderTop?: boolean
  borderLeft?: boolean
}

const TooltipGrid: React.FC<TooltipGridProps> = ({
  items,
  borderTop = false,
  borderLeft = false,
}) => {
  return (
    <Box width='100%'>
      {items.map(({ title: itemTitle, subtitle, value }, index) => (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          key={`${itemTitle}_${index}`}
          sx={{
            borderTop: borderTop && index > 0 && '1px solid #f2f2f2',
            borderLeft: borderLeft && '1px solid #f2f2f2',
          }}
        >
          <Box
            display='flex'
            alignItems='center'
            sx={{
              ml: (theme) => theme.spacing(1),
              my: (theme) => theme.spacing(0.5),
            }}
          >
            {itemTitle}&nbsp;{subtitle}
          </Box>
          <Box sx={{ mr: (theme) => theme.spacing(1) }}>{value}</Box>
        </Box>
      ))}
    </Box>
  )
}

export default TooltipGrid
