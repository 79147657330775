import {
  AssetGroupType,
  SmartVaultBase,
  SmartVaultSorting,
  SortOrder,
} from '@solidant/spool-v2-fe-lib'

import { SmartVaultData } from '@/types/smart-vault'
import { SmartVaultTableSort } from '@/types/table'

export type SelectedVault = {
  address: string
  assetGroup: AssetGroupType
}

export interface SmartVaultsTableState {
  page: number
  limit: number
  marketId?: string
  assetGroupId?: string
  riskModelProviderId?: string
  search?: string
  smartVaultsTableSort?: SmartVaultTableSort
  selectedVault?: SmartVaultBase | SmartVaultData
  selectedVaultId?: string
  rewardsClaimedVault: string
  nextRewardsSyncTime: number
}

export interface SmartVaultsTableContextProps extends SmartVaultsTableState {
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  setMarketId: (marketId?: string) => void
  setAssetGroupId: (assetGroupId?: string) => void
  setRiskModelProviderId: (riskModelProviderId?: string) => void
  setSearch: (search: string) => void
  handleSmartVaultsTableSort: (
    key: SmartVaultSorting,
    sortType: SmartVaultTableSort
  ) => void
  setSelectedVault: (vault: SmartVaultBase | SmartVaultData) => void
  setSelectedVaultId: (vaultId: string) => void
  setRewardsClaimedVault: (rewardsClaimedVault: string) => void
  setNextRewardsSyncTime: (nextRewardsSynceTime: number) => void
}

export enum SmartVaultsTableActionTypes {
  SET_PAGE = 'SET_PAGE',
  SET_LIMIT = 'SET_LIMIT',
  SET_MARKET = 'SET_MARKET',
  SET_ASSET_GROUP = 'SET_ASSET_GROUP',
  SET_RISK_MODEL_PROVIDER = 'SET_RISK_MODEL_PROVIDER',
  SET_SEARCH = 'SET_SEARCH',
  SET_SMART_VAULTS_TABLE_SORT = 'SET_SMART_VAULTS_TABLE_SORT',
  SET_SMART_VAULTS_TABLE_SORT_DIRECTION = 'SET_SMART_VAULTS_TABLE_SORT_DIRECTION',
  SET_SELECTED_VAULT = 'SET_SELECTED_VAULT',
  SET_REWARDS_CLAIMED_VAULT = 'SET_REWARDS_CLAIMED_VAULT',
  SET_NEXT_REWARDS_SYNC_TIME = 'SET_NEXT_REWARDS_SYNC_TIME',
  SET_SELECTED_VAULT_ID = 'SET_SELECTED_VAULT_ID',
}

export type SmartVaultsTableAction =
  | {
      type: SmartVaultsTableActionTypes.SET_PAGE
      payload: number
    }
  | {
      type: SmartVaultsTableActionTypes.SET_LIMIT
      payload: number
    }
  | {
      type: SmartVaultsTableActionTypes.SET_MARKET
      payload: string
    }
  | {
      type: SmartVaultsTableActionTypes.SET_ASSET_GROUP
      payload: string
    }
  | {
      type: SmartVaultsTableActionTypes.SET_RISK_MODEL_PROVIDER
      payload: string
    }
  | {
      type: SmartVaultsTableActionTypes.SET_SEARCH
      payload: string
    }
  | {
      type: SmartVaultsTableActionTypes.SET_SMART_VAULTS_TABLE_SORT
      payload: SmartVaultSorting
    }
  | {
      type: SmartVaultsTableActionTypes.SET_SMART_VAULTS_TABLE_SORT_DIRECTION
      payload: SortOrder
    }
  | {
      type: SmartVaultsTableActionTypes.SET_SELECTED_VAULT
      payload: SmartVaultBase
    }
  | {
      type: SmartVaultsTableActionTypes.SET_REWARDS_CLAIMED_VAULT
      payload: string
    }
  | {
      type: SmartVaultsTableActionTypes.SET_NEXT_REWARDS_SYNC_TIME
      payload: number
    }
  | {
      type: SmartVaultsTableActionTypes.SET_SELECTED_VAULT_ID
      payload: string
    }
