import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { InvestorSmartVault } from '@solidant/spool-v2-fe-lib'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useClaimSvts from '@/hooks/dashboard/useClaimSvts'
import useImportDNfts from '@/hooks/dashboard/useImportDNfts'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useMultiNftClaim from '@/hooks/vaultList/Transactions/useMultiNftClaim'

import { Routes } from '@/constants/routes'

interface InvestorVaultOptionsProps {
  row: InvestorSmartVault
  optimisticallyBurnDNfts: (
    vaultAddress: string,
    dNftIds: string[]
  ) => Promise<void>
}

const InvestorVaultOptions: React.FC<InvestorVaultOptionsProps> = ({
  row,
  optimisticallyBurnDNfts,
}) => {
  const { address, dNfts, wNfts } = row
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { chain } = useConnectionState()

  const navigate = useNavigate()
  const { setIsVaultsRowClicked } = useUniversalNavigation()

  const { importDNftsOnClick } = useImportDNfts()
  const { claimSvtsOnClick } = useClaimSvts()
  const claimMultipleNft = useMultiNftClaim()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }
  const t = useLocale()

  return (
    <>
      <IconButton
        aria-controls={open ? 'investor-vaults-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          padding: belowPhabletBreakpoint ? '0px' : '0.5rem',
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id='investor-vaults-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box py={2}>
          <MenuItem
            onClick={(event) => {
              handleClose(event)
              setIsVaultsRowClicked(true)
              navigate(`${Routes(chain).smartVaults.root.url}/${address}`)
            }}
          >
            Deposit/Withdraw
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              handleClose(event)
              importDNftsOnClick(address, dNfts)
            }}
            disabled={dNfts.every((dNft) => dNft.isBurned === true)}
          >
            {t(
              'components.organisms.dashboard.investor.investorAllocations.vaultsAllocation.buttons.import.label'
            )}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              handleClose(event)
              claimSvtsOnClick(row, optimisticallyBurnDNfts)
            }}
            disabled={dNfts.every(
              (dNft) =>
                dNft.isBurned === true || (!dNft.isBurned && dNft.isPendingDHW)
            )}
          >
            {t(
              'components.organisms.dashboard.investor.investorAllocations.vaultsAllocation.buttons.convert.label'
            )}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              handleClose(event)
              claimMultipleNft(wNfts, address)
            }}
            disabled={!wNfts?.length}
          >
            {t(
              'components.organisms.dashboard.investor.investorAllocations.vaultsAllocation.buttons.claimAllwNFTs.label'
            )}
          </MenuItem>
        </Box>
      </Menu>
    </>
  )
}

export default InvestorVaultOptions
