import { SortOrder, TransactionHistorySorting } from '@solidant/spool-v2-fe-lib'
import { memo, useMemo, useReducer } from 'react'
import { useSearchParams } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

import {
  initialState,
  TransactionHistoryContext,
} from '@/store/transactionHistory/transactionHistory.context'
import transactionHistoryReducer from '@/store/transactionHistory/transactionHistory.reducer'
import { TransactionHistoryActionTypes } from '@/store/transactionHistory/transactionHistory.types'

import { Routes } from '@/constants/routes'

import { RCNode } from '@/types/index'
import { TransactionHistoryTableSort } from '@/types/table'

export const TransactionHistoryProvider = memo(({ children }: RCNode) => {
  const { chain } = useConnectionState()

  const searchParams = useSearchParams()
  const { setPathWithHistory } = useUniversalNavigation()

  const [transactionHistory, dispatch] = useReducer(transactionHistoryReducer, {
    ...initialState,
    page: parseInt(searchParams[0].get('page')) || initialState.page,
    limit: parseInt(searchParams[0].get('limit')) || initialState.limit,
    assetGroupId:
      searchParams[0].get('assetGroup') || initialState.assetGroupId,
    search: searchParams[0].get('search') || initialState.search,
    filteredTransactionTypes:
      searchParams[0].get('transactionTypes')?.split(',') ||
      initialState.filteredTransactionTypes,
    transactionHistorySort: {
      key:
        (searchParams[0].get('sortKey') as TransactionHistorySorting) ||
        initialState.transactionHistorySort?.key,
      direction:
        (searchParams[0].get('sortOrder') as SortOrder) ||
        initialState.transactionHistorySort?.direction,
    },
  })

  const updateStateAndUrl = (
    actionType: TransactionHistoryActionTypes,
    key: string,
    value: any
  ) => {
    dispatch({ type: actionType, payload: value })
    value
      ? searchParams[0].set(key, value.toString())
      : searchParams[0].delete(key)

    if (searchParams[0].size > 0) {
      setPathWithHistory(
        `${Routes(chain).dashboard.history.url}?${searchParams[0]}`
      )
    } else {
      setPathWithHistory(Routes(chain).dashboard.history.url)
    }
  }

  const setPage = (page: number) => {
    updateStateAndUrl(TransactionHistoryActionTypes.SET_PAGE, 'page', page)
  }

  const setLimit = (limit: number) => {
    updateStateAndUrl(TransactionHistoryActionTypes.SET_LIMIT, 'limit', limit)
  }

  const setMarketId = (marketId: string) => {
    updateStateAndUrl(
      TransactionHistoryActionTypes.SET_MARKET,
      'market',
      marketId
    )
  }

  const setAssetGroupId = (assetGroupId: string) => {
    updateStateAndUrl(
      TransactionHistoryActionTypes.SET_ASSET_GROUP,
      'assetGroup',
      assetGroupId
    )
  }

  const setFilteredVaults = (filteredVaults: string[]) => {
    let vaults: string[] = []

    if (filteredVaults.length === 0 || filteredVaults.includes('all')) {
      dispatch({
        type: TransactionHistoryActionTypes.SET_FILTERED_VAULTS,
        payload: undefined,
      })
      return
    }
    if (filteredVaults.length > 0) {
      vaults = filteredVaults.filter((vault) => vault !== 'All Smart Vaults')
    }

    dispatch({
      type: TransactionHistoryActionTypes.SET_FILTERED_VAULTS,
      payload: vaults,
    })
  }

  const setTransactionTypes = (filteredTransactionTypes: string[]) => {
    let transactionTypes: string[] = []
    if (
      filteredTransactionTypes.length === 0 ||
      filteredTransactionTypes.includes('all')
    ) {
      updateStateAndUrl(
        TransactionHistoryActionTypes.SET_TRANSACTION_TYPES,
        'transactionTypes',
        undefined
      )
      return
    }
    if (filteredTransactionTypes.length > 0) {
      transactionTypes = filteredTransactionTypes.filter(
        (transactionType) => transactionType !== 'All Transaction Types'
      )
    }

    updateStateAndUrl(
      TransactionHistoryActionTypes.SET_TRANSACTION_TYPES,
      'transactionTypes',
      transactionTypes
    )
  }

  const setSearch = (search: string) => {
    updateStateAndUrl(
      TransactionHistoryActionTypes.SET_SEARCH,
      'search',
      search
    )
  }

  const handleTransactionHistoryTableSort = (
    key: TransactionHistorySorting,
    sortType: TransactionHistoryTableSort
  ) => {
    if (!sortType?.key || sortType?.key !== key) {
      dispatch({
        type: TransactionHistoryActionTypes.SET_TRANSACTION_HISTORY_TABLE_SORT,
        payload: key,
      })
      searchParams[0].set('sortKey', key)
    }

    if (!sortType?.direction || sortType?.key === key) {
      dispatch({
        type: TransactionHistoryActionTypes.SET_TRANSACTION_HISTORY_TABLE_SORT_DIRECTION,
        payload:
          sortType.direction === SortOrder.DESC
            ? SortOrder.ASC
            : SortOrder.DESC,
      })
      searchParams[0].set(
        'sortOrder',
        sortType.direction === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
      )
    }

    if (searchParams[0].size > 0) {
      setPathWithHistory(
        `${Routes(chain).dashboard.history.url}?${searchParams[0]}`
      )
    } else {
      setPathWithHistory(Routes(chain).dashboard.history.url)
    }
  }

  const contextValue = useMemo(() => {
    return {
      ...transactionHistory,
      setPage,
      setLimit,
      setAssetGroupId,
      setFilteredVaults,
      setTransactionTypes,
      setSearch,
      setMarketId,
      handleTransactionHistoryTableSort,
    }
  }, [
    transactionHistory.page,
    transactionHistory.limit,
    transactionHistory.marketId,
    transactionHistory.assetGroupId,
    transactionHistory.filteredVaults,
    transactionHistory.filteredTransactionTypes,
    transactionHistory.search,
    transactionHistory.transactionHistorySort,
  ])

  return (
    <TransactionHistoryContext.Provider value={contextValue}>
      {children}
    </TransactionHistoryContext.Provider>
  )
})
