import { Box } from '@mui/material'
import { AssetGroupType } from '@solidant/spool-v2-fe-lib'
import { Fragment } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

interface AssetGroupTokensProps {
  assetGroup: AssetGroupType
}

const AssetGroupTokens: React.FC<AssetGroupTokensProps> = ({ assetGroup }) => {
  const { chain } = useConnectionState()

  return (
    <Box display='flex' justifyContent='center'>
      {assetGroup &&
        assetGroup?.assetGroupTokens.map(({ address }, index) => (
          <Fragment key={index}>
            <Box
              display='flex'
              sx={{
                position: 'relative',
                ml: index !== 0 ? '-3px' : '0',
                pt: '1px',
                transform: 'scale(1.4)',
                transformOrigin: 'center',
              }}
            >
              {mapAssetTokenIcon(chain, address ?? '')}
            </Box>
          </Fragment>
        ))}
    </Box>
  )
}

export default AssetGroupTokens
