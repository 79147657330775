import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { createContext } from 'react'

import {
  WithdrawContextProps,
  WithdrawState,
} from '@/store/withdraw/withdraw.types'

export const initialState: WithdrawState = {
  withdrawReceiptSort: {
    key: 'createdOn',
    direction: SortOrder.DESC,
  },
  withdrawalAmount: '',
}

export const WithdrawContext = createContext<WithdrawContextProps>({
  ...initialState,
  handleSortClick: () => undefined,
  setWithdrawalAmount: () => undefined,
})
