import { SortOrder } from '@solidant/spool-v2-fe-lib'

import { Token } from '@/constants/smartVault'

import {
  StrategiesDto,
  StrategySortKey,
  StrategySortType,
} from '@/types/create-vault'
import { SortType } from '@/types/table'

export enum Role {
  DEPOSITOR = 'DEPOSITOR',
  BENEFICIARIES = 'BENEFICIARIES',
  NO_ROLE = 'NO_ROLE',
}

export enum Requirement {
  MINIMUM = 'MINIMUM',
  MAXIMUM = 'MAXIMUM',
}

export interface Whitelist {
  id?: number
  nftAmount: string
  address: string
  role: Role
}

export interface AssetAmount {
  id?: number
  token: Token
  requirement: Requirement
  value: string
}

export interface Guards {
  [guardItemId: string]: {
    title: string
    type: string
    whitelist?: Whitelist[]
    assetAmount?: AssetAmount[]
    bool?: boolean
    time?: number
    conditions?: string[]
    checked?: boolean
    configured?: boolean
  }
}

export interface ActionData {
  percent: number
  period?: number
}

export interface Actions {
  [itemId: string]: {
    title: string
    type: string
    actionData?: ActionData
    conditions?: string[]
    checked?: boolean
    configured?: boolean
  }
}

export type Button = {
  label: string
  toStep: number | null
}

export type StepData = {
  name: string
  nextBtn?: Button
  advBtn?: Button
  prevBtn?: Button
  urlSubpath?: string
  completed: boolean
}
export interface CreateVaultState {
  steps: StepData[]
  currentStep: number
  isUnavailableProvider: boolean
  selectedMarket: string
  selectedRiskModel: string
  vaultName: string
  svtSymbol: string
  isSvtSymbolValid: boolean
  isVaultNameValid: boolean
  selectedStrategies: StrategiesDto[]
  selectedAssetGroupId: string
  selectedAllocationProvider: string
  selectedRiskScore: number
  selectedPerformanceFee: number
  selectedManagementFee: number
  filterByAssetGroup: string
  filterBySearchTerm: string
  strategySort: StrategySortType
  selectedGuards: Guards
  selectedActions: Actions
}

export interface CreateVaultContextProps extends CreateVaultState {
  setCurrentStep: (currentStep: number) => void
  setSelectedRiskModel: (riskModel: string) => void
  setVaultName: (vaultName: string) => void
  setSvtSymbol: (svtSymbol: string) => void
  setIsSvtSymbolValid: (isSvtSymbolValid: boolean) => void
  setIsVaultNameValid: (isVaultNameValid: boolean) => void
  setSelectedMarket: (selectedMarket: string) => void
  setSelectedStrategies: (strategies: StrategiesDto[]) => void
  setSelectedAssetGroupId: (assetGroupId: string) => void
  setSelectedAllocationProvider: (allocationProvider: string) => void
  setSelectedRiskScore: (riskScore: number) => void
  setSelectedPerformanceFee: (performanceFee: number) => void
  setSelectedManagementFee: (managementFee: number) => void
  setStepCompletedStatus: (stepNumber: number, status: boolean) => void
  setFilterByAssetGroup: (filterByAssetGroup: string) => void
  setFilterBySearchTerm: (filterSearchTerm: string) => void
  getStepData: (step: number) => StepData
  validateVaultName: (vault: string) => boolean
  validateSvtSymbol: (svtSymbol: string) => boolean
  filterStrategy: (data: StrategiesDto) => void
  handleSortClick: (
    key: StrategySortKey,
    strategySort: SortType<StrategiesDto>
  ) => void
  setSelectedGuards: (selectedGuards: Guards) => void
  setGuardsWhitelist: (
    id: string,
    whiteList: Whitelist[],
    removeCondition?: boolean,
    isNFTWhitelist?: boolean
  ) => void
  setGuardsAssetAmount: (id: string, assetAmount: AssetAmount[]) => void
  setGuardsBool: (id: string, bool: boolean) => void
  setGuardsTime: (id: string, time: number) => void
  setGuardsConfigured: (id: string, configured?: boolean) => void
  setGuardsChecked: (
    id: string,
    checked: boolean,
    title: string,
    type: string
  ) => void
  setActionsChecked: (
    id: string,
    checked: boolean,
    title: string,
    type: string
  ) => void
  setActionsConfigured: (id: string, configured: boolean) => void
  setActionsData: (id: string, actionData: ActionData) => void
  resetAllSelectedFields: () => void
}

export enum CreateVaultActionTypes {
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_SELECTED_RISK_MODEL = 'SET_SELECTED_RISK_MODEL',
  SET_SELECTED_MARKET = 'SET_SELECTED_MARKET',
  SET_SELECTED_STRATEGIES = 'SET_SELECTED_STRATEGIES',
  SET_SELECTED_ASSET_GROUP_ID = 'SET_SELECTED_ASSET_GROUP_ID',
  SET_SELECTED_PERFORMANCE_FEE = 'SET_SELECTED_PERFORMANCE_FEE',
  SET_SELECTED_MANAGEMENT_FEE = 'SET_SELECTED_MANAGEMENT_FEE',
  SET_STEP_COMPLETED = 'SET_STEP_COMPLETED',
  SET_VAULT_NAME = 'SET_VAULT_NAME',
  SET_SVT_SYMBOL = 'SET_SVT_SYMBOL',
  SET_IS_SVT_SYMBOL_VALID = 'SET_IS_SVT_SYMBOL_VALID',
  SET_IS_VAULT_NAME_VALID = 'SET_IS_VAULT_NAME_VALID',
  SET_SELECTED_ALLOCATION_PROVIDER = 'SET_SELECTED_ALLOCATION_PROVIDER',
  SET_SELECTED_RISK_SCORE = 'SET_SELECTED_RISK_SCORE',
  SET_FILTER_BY_ASSET_GROUP = 'SET_FILTER_BY_ASSET_GROUP',
  SET_FILTER_BY_SEARCH_TERM = 'SET_FILTER_BY_SEARCH_TERM',
  SET_STRATEGY_SORT = 'SET_STRATEGY_SORT',
  CHANGE_STRATEGY_SORT_DIRECTION = 'CHANGE_STRATEGY_SORT_DIRECTION',
  SET_GUARDS_WHITELIST = 'SET_GUARDS_WHITELIST',
  SET_SELECTED_GUARDS = 'SET_SELECTED_GUARDS',
  SET_GUARDS_ASSET_AMOUNT = 'SET_GUARDS_ASSET_AMOUNT',
  SET_GUARDS_BOOL = 'SET_GUARDS_BOOL',
  SET_GUARDS_TIME = 'SET_GUARDS_TIME',
  SET_GUARDS_CONFIGURED = 'SET_GUARDS_CONFIGURED',
  SET_GUARDS_CHECKED = 'SET_GUARDS_CHECKED',
  SET_ACTIONS_CHECKED = 'SET_ACTIONS_CHECKED',
  SET_ACTIONS_CONFIGURED = 'SET_ACTIONS_CONFIGURED',
  SET_ACTIONS_DATA = 'SET_ACTIONS_DATA',
  RESET_SELECTED_GUARDS = 'RESET_SELECTED_GUARDS',
}

export type CreateVaultAction =
  | {
      type: CreateVaultActionTypes.SET_CURRENT_STEP
      payload: number
    }
  | {
      type: CreateVaultActionTypes.SET_STEP_COMPLETED
      payload: {
        stepNumber: number
        status: boolean
      }
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_RISK_MODEL
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_VAULT_NAME
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_SVT_SYMBOL
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_IS_SVT_SYMBOL_VALID
      payload: boolean
    }
  | {
      type: CreateVaultActionTypes.SET_IS_VAULT_NAME_VALID
      payload: boolean
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_MARKET
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_STRATEGIES
      payload: StrategiesDto[]
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_PERFORMANCE_FEE
      payload: number
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_MANAGEMENT_FEE
      payload: number
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_ALLOCATION_PROVIDER
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_RISK_SCORE
      payload: number
    }
  | {
      type: CreateVaultActionTypes.SET_FILTER_BY_ASSET_GROUP
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_FILTER_BY_SEARCH_TERM
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_ASSET_GROUP_ID
      payload: string
    }
  | {
      type: CreateVaultActionTypes.SET_STRATEGY_SORT
      payload: {
        key: StrategySortKey
        direction: SortOrder
      }
    }
  | {
      type: CreateVaultActionTypes.CHANGE_STRATEGY_SORT_DIRECTION
    }
  | {
      type: CreateVaultActionTypes.SET_SELECTED_GUARDS
      payload: Guards
    }
  | {
      type: CreateVaultActionTypes.SET_GUARDS_WHITELIST
      id: string
      payload: { whitelist: Whitelist[]; conditions: string[] }
    }
  | {
      type: CreateVaultActionTypes.SET_GUARDS_ASSET_AMOUNT
      id: string
      payload: { assetAmount: AssetAmount[]; conditions: string[] }
    }
  | {
      type: CreateVaultActionTypes.SET_GUARDS_BOOL
      id: string
      payload: { bool: boolean; conditions: string[] }
    }
  | {
      type: CreateVaultActionTypes.SET_GUARDS_TIME
      id: string
      payload: { time: number; conditions: string[] }
    }
  | {
      type: CreateVaultActionTypes.SET_GUARDS_CONFIGURED
      id: string
      payload: boolean
    }
  | {
      type: CreateVaultActionTypes.SET_GUARDS_CHECKED
      id: string
      payload: { checked: boolean; title: string; type: string }
    }
  | {
      type: CreateVaultActionTypes.SET_ACTIONS_CHECKED
      id: string
      payload: { checked: boolean; title: string; type: string }
    }
  | {
      type: CreateVaultActionTypes.SET_ACTIONS_CONFIGURED
      id: string
      payload: boolean
    }
  | {
      type: CreateVaultActionTypes.SET_ACTIONS_DATA
      id: string
      payload: { actionData: ActionData; conditions: string[] }
    }
  | {
      type: CreateVaultActionTypes.RESET_SELECTED_GUARDS
    }
