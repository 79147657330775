export const isExternalURL = (url: string) => {
  try {
    const parsedURL = new URL(url, window.location.href)

    return parsedURL.host !== window.location.host
  } catch (e) {
    // Invalid URL passed
    console.warn('Invalid URL:', e, url)

    return false
  }
}

const statelessNavigation =
  (replaceState: boolean) =>
  (path: string, params?: { [key: string]: string }) => {
    let url = path

    if (params) {
      const keys = Object.keys(params)
      const values = Object.values(params)

      url = path.replace(/:(\w+)/g, (matchedSubstring, key) => {
        const index = keys.indexOf(key)
        if (index > -1) {
          return values[index]
        }
        return matchedSubstring
      })
    }

    if (replaceState) {
      history.replaceState({}, '', url)
    } else {
      history.pushState({}, '', url)
    }
  }

export const navigateReplace = statelessNavigation(true)

export const navigatePush = statelessNavigation(false)

// const shouldShow = true;  // example condition
// const props = shouldShow ? { show: 'yes' } : {};

// <MyComponent {...props} />
