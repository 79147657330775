import { Datum } from '@nivo/line'
import {
  DashboardStrategy,
  InvestorSmartVault,
  SortOrder,
} from '@solidant/spool-v2-fe-lib'
import { memo, useCallback, useMemo, useReducer } from 'react'

import { InvestorDashboardContext } from '@/store/investorDashboard/investorDashboard.context'
import investorDashboardReducer from '@/store/investorDashboard/investorDashboard.reducer'
import { initialState } from '@/store/investorDashboard/investorDashboard.state'
import { InvestorDashboardActionTypes } from '@/store/investorDashboard/investorDashboard.types'

import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const InvestorDashboardProvider = memo(({ children }: RCNode) => {
  const [investorVault, dispatch] = useReducer(
    investorDashboardReducer,
    initialState
  )

  const setInvestorVaultsTablePage = (investorSmartVaultPage: number) => {
    dispatch({
      type: InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_PAGE,
      payload: investorSmartVaultPage,
    })
  }

  const setInvestorVaultsTableLimit = (investorSmartVaultLimit: number) => {
    dispatch({
      type: InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_LIMIT,
      payload: investorSmartVaultLimit,
    })
  }

  const handleInvestorSmartVaultSort = useCallback(
    (key: keyof InvestorSmartVault, sortType: SortType<InvestorSmartVault>) => {
      if (!sortType?.key || sortType?.key !== key) {
        dispatch({
          type: InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_SORT,
          payload: {
            key,
            direction: SortOrder.DESC,
          },
        })
        return
      }

      dispatch({
        type: InvestorDashboardActionTypes.CHANGE_INVESTOR_SMART_VAULT_SORT_DIRECTION,
      })
    },
    []
  )

  const setSelectedVaults = (selectedVaults: InvestorSmartVault[]) => {
    dispatch({
      type: InvestorDashboardActionTypes.SET_SELECTED_VAULTS,
      payload: selectedVaults,
    })
  }

  const setVaultsStrategiesTablePage = (setInvestorStategyPage: number) => {
    dispatch({
      type: InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_PAGE,
      payload: setInvestorStategyPage,
    })
  }

  const setVaultsStrategiesTableLimit = (investorStrategyLimit: number) => {
    dispatch({
      type: InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_LIMIT,
      payload: investorStrategyLimit,
    })
  }

  const setBalanceChartValues = (balanceChartValues: Datum[]) => {
    dispatch({
      type: InvestorDashboardActionTypes.SET_BALANCE_CHARTS_VALUE,
      payload: balanceChartValues,
    })
  }

  const handleInvestorStrategySort = useCallback(
    (key: keyof DashboardStrategy, sortType: SortType<DashboardStrategy>) => {
      if (!sortType?.key || sortType?.key !== key) {
        dispatch({
          type: InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_SORT,
          payload: {
            key,
            direction: SortOrder.DESC,
          },
        })
        return
      }

      dispatch({
        type: InvestorDashboardActionTypes.CHANGE_INVESTOR_STRATEGY_SORT_DIRECTION,
      })
    },
    []
  )

  const contextValue = useMemo(() => {
    return {
      ...investorVault,
      setInvestorVaultsTableLimit,
      setInvestorVaultsTablePage,
      handleInvestorSmartVaultSort,
      setSelectedVaults,
      setVaultsStrategiesTableLimit,
      setVaultsStrategiesTablePage,
      handleInvestorStrategySort,
      setBalanceChartValues,
    }
  }, [
    investorVault.investorSmartVaultLimit,
    investorVault.investorSmartVaultPage,
    investorVault.investorSmartVaultSort,
    investorVault.selectedVaults,
    investorVault.investorStrategiesPage,
    investorVault.investorStrategiesLimit,
    investorVault.investorStrategiesSort,
    investorVault.balanceChartValues,
  ])

  return (
    <InvestorDashboardContext.Provider value={contextValue}>
      {children}
    </InvestorDashboardContext.Provider>
  )
})
