import { Box, IconButton, MenuItem, styled, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useTransactionHistoryState from '@/hooks/context/useTransactionHistoryState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useAssetGroups from '@/hooks/smartVaults/useAssetGroups'

import SpoolSelect from '@/components/atoms/SpoolSelect'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import TransactionTypesFilters from '@/components/organisms/TransactionHistory/TransactionHistoryFilters/TransactionTypesFilters'
import VaultNameFilters from '@/components/organisms/TransactionHistory/TransactionHistoryFilters/VaultNameFilters'

import { gaIds } from '@/constants/googleAnalyticIDs'

export const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  height: 20,
  width: 20,
  marginRight: '4px',
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyItems: 'start',
  alignItems: 'center',
  height: '44px',
  padding: theme.spacing(1.5, 2),
}))

const TransactionHistoryFilters = () => {
  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { chain } = useConnectionState()

  const { assetGroupId, setAssetGroupId } = useTransactionHistoryState()
  const t = useLocale()
  const { assetGroups } = useAssetGroups()

  return (
    <Box
      display={belowTabletBreakpoint ? 'grid' : 'flex'}
      //display='grid'
      // gridTemplateColumns='minmax(0, 0.15fr) minmax(0, 0.40fr) minmax(0, 0.25fr) minmax(0, 0.20fr) '
      gap={(theme) => theme.spacing(2)}
    >
      <VaultNameFilters />
      <TransactionTypesFilters />
      <SpoolSelect
        label={t(
          'components.organisms.transactionHistory.filters.assetGroup.label'
        )}
        value={assetGroupId ? assetGroupId : 'all'}
        handleChange={(event) =>
          setAssetGroupId(
            event.target.value !== 'all'
              ? (event.target.value as string)
              : undefined
          )
        }
      >
        <StyledMenuItem value='all'>
          <Box display='flex'>
            <Typography variant='body2'>
              {t(
                'components.organisms.transactionHistory.filters.assetGroup.all'
              )}
            </Typography>
          </Box>
        </StyledMenuItem>

        {assetGroups &&
          assetGroups?.map(({ id, assetGroupTokens }) => (
            <StyledMenuItem
              id={
                gaIds.filter.main +
                '-' +
                gaIds.filter.page.transactionHistory +
                '-' +
                gaIds.filter.assetGroup
              }
              key={id}
              value={id}
            >
              <Box display='flex'>
                {assetGroupTokens.map(({ symbol, address }, index) => (
                  <Box key={index} display='flex' marginRight='6px'>
                    <StyledIconButton disableRipple>
                      {mapAssetTokenIcon(chain, address ?? '')}
                    </StyledIconButton>
                    <Typography variant='body2'>{symbol}</Typography>
                  </Box>
                ))}
              </Box>
            </StyledMenuItem>
          ))}
      </SpoolSelect>
    </Box>
  )
}

export default TransactionHistoryFilters
