import { Box } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useMetavaultsPage from '@/hooks/smartVaults/useMetavaultPage'

import DataLoading from '@/components/molecules/DataLoading'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import SectionColumn from '@/components/molecules/SectionColumn'
import MetavaultMetrics from '@/components/organisms/Metavaults/MetavaultMetrics'
import MetavaultSelector from '@/components/organisms/Metavaults/MetavaultSelector'
import MetavaultVaultsTable from '@/components/organisms/Metavaults/MetavaultVaultsTable'

import PageNotFound from '@/pages/General/PageNotFound'

const MetavaultPage = () => {
  const t = useLocale()

  const {
    metavaultData,
    isLoading: isLoadingMetavaultsPage,
    error,
  } = useMetavaultsPage()
  const { isLoading: isLoadingCreatorDashboard } = useCreatorDashboard()

  const isLoading = isLoadingMetavaultsPage || isLoadingCreatorDashboard

  return (
    <>
      {!isLoading ? (
        error ? (
          <Box mt='1rem'>
            <ErrorWithText errorText='Error loading metavault' />
          </Box>
        ) : !Object.values(metavaultData).length ? (
          <Box mt='1rem'>
            <PageNotFound height='90vh' />
          </Box>
        ) : (
          <>
            <Box
              display='flex'
              flexDirection='column'
              gap='1rem'
              pt={2}
              /*mb={belowTabletBreakpoint ? '4rem' : '0rem'}*/
            >
              <MetavaultSelector />
              <MetavaultMetrics />
              <SectionColumn
                title={t(
                  'components.organisms.dashboard.creator.metavaults.metavaultVaultsTable.title'
                )}
                sx={{ px: 0, pb: 0 }}
              >
                <MetavaultVaultsTable />
              </SectionColumn>
            </Box>
          </>
        )
      ) : (
        <Box mt='1rem'>
          <DataLoading
            loadingText={t(
              'pages.metavaults.vaultDetailsPage.loadingVaultData'
            )}
          />
        </Box>
      )}
    </>
  )
}

export default MetavaultPage
