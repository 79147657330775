import { Box, Typography } from '@mui/material'
import { unix } from 'dayjs'

import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import DetailsRow from '@/components/molecules/DetailsRow'
import SectionColumn from '@/components/molecules/SectionColumn'
import { StyledGridItem } from '@/components/molecules/StyledGridItem'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

const IncentivizationDetails = () => {
  const t = useLocale()

  const { smartVaultData } = useVaultsPage()

  const { incentivizedRewards, lastIncentivizationTime } = smartVaultData

  return (
    <SectionColumn
      sx={{
        padding: '0',
      }}
      noVerticalGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.incentivization'
      )}
    >
      <StyledGridItem>
        {!!Number(incentivizedRewards?.totalApy) && (
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.incentivizedRewards.label'
            )}
          >
            <Box display='flex' flexDirection='column'>
              <Typography variant='body2'>
                {formatUSD(incentivizedRewards?.totalApy ?? '0', 2)}%{' '}
                {t(
                  'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.incentivizedRewards.content'
                )}
              </Typography>
              {incentivizedRewards &&
                incentivizedRewards.rewards
                  .filter((reward) => +reward.apy > 0.01)
                  .map(({ apy, asset }, index) => (
                    <Typography
                      variant='caption'
                      color='text.secondary'
                      key={index}
                    >
                      {currency}
                      {asset.symbol} {formatUSD(apy)} %
                    </Typography>
                  ))}
            </Box>
          </DetailsRow>
        )}
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.lastIncentivization.label'
          )}
        >
          <Box display='flex' flexDirection='column'>
            {lastIncentivizationTime ? (
              <>
                <Typography variant='body2'>
                  {unix(Number(lastIncentivizationTime)).format('DD MMM YYYY')}
                </Typography>
                <Typography variant='caption' color='text.secondary'>
                  {unix(Number(lastIncentivizationTime)).format('HH:mm [UTC]Z')}
                </Typography>
              </>
            ) : (
              <Typography variant='caption' color='text.secondary'>
                {t(
                  'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.lastIncentivization.missing'
                )}
              </Typography>
            )}
          </Box>
        </DetailsRow>
      </StyledGridItem>
    </SectionColumn>
  )
}

export default IncentivizationDetails
