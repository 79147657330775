import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import { DisclaimerBox } from '@/components/atoms/DisclaimerBox'

import Oneinch from '@/assets/icons/chainIcons/1inch'

const OneInchDisclaimer = () => {
  const t = useLocale()

  return (
    <DisclaimerBox
      display='flex'
      alignItems='center'
      justifyContent='center'
      padding='6px'
    >
      <Typography textAlign='center' fontSize='12px'>
        {t('pages.smartVaults.vaultDetailsPage.deposit.disclaimer')}
      </Typography>
      <Oneinch />
    </DisclaimerBox>
  )
}

export default OneInchDisclaimer
