import InfoIcon from '@mui/icons-material/Info'
import { AlertTitle, Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import DialogContainer from '@/components/organisms/Dialogs/DialogContainer'

const CookieBanner = () => {
  const t = useLocale()
  const [isOpen, setIsOpen] = useState(false)
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  useEffect(() => {
    const userConsentLocalStorage = localStorage.getItem('userConsent')
    if (userConsentLocalStorage) {
      const { consent, date } = JSON.parse(userConsentLocalStorage)
      const consentDate = new Date(date)
      const oneYearAgo = new Date()
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

      if (!consent && consentDate <= oneYearAgo) {
        setIsOpen(true)
      }
    } else {
      setIsOpen(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem(
      'userConsent',
      JSON.stringify({ consent: true, date: new Date().toISOString() })
    )
    setIsOpen(false)
    window.dispatchEvent(new CustomEvent('consentGiven'))
  }

  const handleDecline = () => {
    localStorage.setItem(
      'userConsent',
      JSON.stringify({ consent: false, date: new Date().toISOString() })
    )
    setIsOpen(false)
  }

  return (
    isOpen && (
      <DialogContainer isOpen={isOpen} zIndex={10000} bottom='20px'>
        <Box
          display='flex'
          flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
          gap={(theme) => theme.spacing(2)}
          alignItems='start'
        >
          {!belowPhabletBreakpoint && (
            <InfoIcon className='xl' color='primary' />
          )}
          <Box>
            <AlertTitle sx={{ pl: '4px' }}>
              {t('components.organisms.dialogs.cookieBanner.alertTitle')}
            </AlertTitle>
            <Typography variant='body2' sx={{ pl: '4px' }}>
              {t('components.organisms.dialogs.cookieBanner.alertText')}
            </Typography>
          </Box>
          <Box display='flex' gap={(theme) => theme.spacing(1)}>
            <Button variant='outlined' size='small' onClick={handleDecline}>
              {t('components.organisms.dialogs.cookieBanner.buttons.decline')}
            </Button>
            <Button variant='contained' size='small' onClick={handleAccept}>
              {t('components.organisms.dialogs.cookieBanner.buttons.allow')}
            </Button>
          </Box>
        </Box>
      </DialogContainer>
    )
  )
}

export default CookieBanner
