import type { Actions } from '@web3-react/types'
import { Connector } from '@web3-react/types'
import invariant from 'tiny-invariant'

import { isLedgerDappBrowserProvider } from '@/connection/connectors/LedgerIFrameConnector/helpers'
import { SpoolIFrameEthereumProvider } from '@/connection/connectors/LedgerIFrameConnector/SpoolIFrameEthereumProvider'

type SpoolIFrameEthereumProviderOptions = ConstructorParameters<
  typeof SpoolIFrameEthereumProvider
>[0]

export class NoConnectorError extends Error {
  public constructor() {
    super('Selected wallet not installed')
    this.name = NoConnectorError.name
    Object.setPrototypeOf(this, NoConnectorError.prototype)
  }
}

export class NotLedgerApp extends Error {
  public constructor() {
    super('The app is loaded outside Ledger Live')
    this.name = NotLedgerApp.name
    Object.setPrototypeOf(this, NotLedgerApp.prototype)
  }
}

export class NoAccountsError extends Error {
  public constructor() {
    super('No accounts detected on wallet')
    this.name = NoAccountsError.name
    Object.setPrototypeOf(this, NoAccountsError.prototype)
  }
}

export class UndetectedChainIdError extends Error {
  public constructor() {
    super('ChainID unable to be detected')
    this.name = UndetectedChainIdError.name
    Object.setPrototypeOf(this, UndetectedChainIdError.prototype)
  }
}

const parseChainId = (chainId: string) => {
  return Number.parseInt(chainId, 16)
}

/**
 * @param onError - Handler to report errors thrown from eventListeners.
 */
export interface LedgerIFrameConnectorConstructorArgs {
  actions: Actions
  options?: SpoolIFrameEthereumProviderOptions
  onError?: (error: Error) => void
  provider?: SpoolIFrameEthereumProvider
}

export class LedgerIFrameConnector extends Connector {
  /** {@inheritdoc Connector.provider} */
  public declare provider?: SpoolIFrameEthereumProvider
  private eagerConnection?: Promise<void>

  constructor({ actions, onError }: LedgerIFrameConnectorConstructorArgs) {
    super(actions, onError)
  }

  public isLedgerApp(): boolean {
    return isLedgerDappBrowserProvider()
  }

  public async getProviderInstance(): Promise<SpoolIFrameEthereumProvider> {
    if (this.provider) return this.provider

    return new SpoolIFrameEthereumProvider()
  }

  public async getChainId(): Promise<number | string> {
    invariant(this.provider, 'Provider is not defined')
    return this.provider.send('eth_chainId')
  }

  private async isomorphicInitialize(): Promise<void> {
    if (this.eagerConnection) return

    this.provider = await this.getProviderInstance()

    this.provider?.on('networkChanged', (networkId: string): void => {
      this.actions.update({
        chainId: parseChainId(networkId),
      })
    })

    this.provider?.on('chainChanged', (chainId: string): void => {
      this.actions.update({ chainId: parseChainId(chainId) })
    })

    this.provider?.on('accountsChanged', (accounts: string[]): void => {
      if (accounts.length === 0) {
        // handle this edge case by disconnecting
        this.actions.resetState()
      } else {
        this.actions.update({ accounts })
      }
    })
  }

  /** {@inheritdoc Connector.connectEagerly} */
  public async connectEagerly(): Promise<void> {
    const cancelActivation = this.actions.startActivation()

    try {
      await this.isomorphicInitialize()
      if (!this.provider) {
        return cancelActivation()
      }

      const accounts = await this.provider.enable()
      const chainId = (await this.getChainId()).toString()
      this.actions.update({ chainId: parseChainId(chainId), accounts })
    } catch (error) {
      cancelActivation()
      console.debug('Could not connect eagerly', error)
      this.actions.resetState()
    }
  }

  /**
   * Initiates a connection.
   *
   */
  public async activate(): Promise<void> {
    // if (!this.isLedgerApp()) throw new NotLedgerApp()
    const cancelActivation = this.actions.startActivation()

    return this.isomorphicInitialize()
      .then(async () => {
        if (!this.provider) throw new NotLedgerApp()

        const accounts = await this.provider.enable()
        const chainId = (await this.getChainId()).toString()

        await this.actions.update({
          chainId: parseChainId(chainId),
          accounts,
        })
      })
      .catch((error) => {
        cancelActivation?.()
        throw error
      })
  }
}
