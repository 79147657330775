import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'

import { Routes } from '@/constants/routes'

interface PageNotFoundProps {
  height?: string
}

const PageNotFound: React.FC<PageNotFoundProps> = ({ height = '93vh' }) => {
  const { chain } = useConnectionState()

  const t = useLocale()
  const navigate = useNavigate()

  return (
    <Box
      position='absolute'
      height={height}
      display='flex'
      flexDirection='column'
      maxWidth='1250px'
      width='100%'
      alignItems='start'
      justifyContent='center'
      pb='5rem'
    >
      <Typography fontSize='48px'>{t('general.pageNotFound.title')}</Typography>
      <Typography color='text.secondary'>
        {t('general.pageNotFound.subtitle')}
      </Typography>
      <Box display='flex' gap='1rem' pt='2rem'>
        <Button
          color='primary'
          startIcon={<ArrowBackIcon />}
          variant='outlined'
          onClick={() => history.back()}
        >
          {t('general.pageNotFound.cta.back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate(Routes(chain).smartVaults.root.url)}
        >
          {t('general.pageNotFound.cta.home')}
        </Button>
      </Box>
    </Box>
  )
}

export default PageNotFound
