import { Typography } from '@mui/material'
import { StrategyAllocationDetails } from '@solidant/spool-v2-fe-lib'
import { useEffect, useMemo, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import ColumnlessGridRow from '@/components/molecules/ColumnlessGridRow'
import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import DetailsRow from '@/components/molecules/DetailsRow'
import SectionColumn from '@/components/molecules/SectionColumn'
import { StyledGridItem } from '@/components/molecules/StyledGridItem'
import StrategiesTable from '@/components/organisms/SmartVaults/CollapsibleRow/Strategies/StrategiesTable'
import StrategyNotification from '@/components/organisms/WarningBanner/StrategyNotification'

import { ALLOCATION_PROVIDERS } from '@/config/sdk'
import { mapRiskToRange } from '@/utils/sdkHelper'

import { SmartVaultData } from '@/types/smart-vault'

interface StrategiesAllocationProps {
  smartVault: SmartVaultData
  strategies: StrategyAllocationDetails[] | undefined
  totalAllocated: number
}

const StrategiesAllocation: React.FC<StrategiesAllocationProps> = ({
  smartVault,
  strategies,
  totalAllocated,
}) => {
  const t = useLocale()
  const { chain } = useConnectionState()
  const { smartVaultData } = useVaultsPage()
  const [loadingError, setLoadingError] = useState(false)

  const { riskTolerance, allocationProvider, vaultTheme } = smartVaultData

  const errorState = useMemo(
    () =>
      smartVault?.strategiesAllocation?.strategies.some(
        (strategy) => strategy.errorState && strategy.errorState !== 'normal'
      ),
    [smartVault]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (strategies === undefined) {
        setLoadingError(true)
      }
    }, 10000)

    return () => clearTimeout(timer)
  }, [strategies])

  const allocationProviders = useMemo(
    () => ALLOCATION_PROVIDERS[chain],
    [chain]
  )

  return (
    <SectionColumn
      noGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.strategiesAllocation.sectionTitle'
      )}
      sx={{ flexGrow: 7, flexBasis: 0, gap: (theme) => theme.spacing(0.5) }}
      noPadding
    >
      {strategies === undefined && !loadingError ? (
        <DataLoading loadingText='Loading Strategies' />
      ) : strategies?.length > 0 ? (
        <>
          {errorState && <StrategyNotification vault={smartVault} />}
          <StrategiesTable
            strategies={strategies}
            totalAllocation={totalAllocated}
          />
        </>
      ) : loadingError ? (
        <DataMissing text='Error loading strategies.' />
      ) : (
        <DataMissing text='Strategies Not Found' />
      )}
      <StyledGridItem marginTop='1.5rem'>
        {!!vaultTheme && (
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.productType.label'
            )}
          >
            <Typography variant='body2' sx={{ width: '100%' }}>
              {vaultTheme}
            </Typography>
          </DetailsRow>
        )}
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.allocationProvider.label'
          )}
        >
          <Typography variant='body2' sx={{ width: '100%' }}>
            {allocationProvider && allocationProviders
              ? allocationProviders[allocationProvider].name
              : 'Not applicable to single strategy vaults'}
          </Typography>
        </DetailsRow>
        <>
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.riskTolerance.label'
            )}
          >
            <Typography variant='body2' sx={{ width: '100%' }}>
              {riskTolerance !== null
                ? `${mapRiskToRange(riskTolerance)}/10`
                : 'Not applicable to single strategy vaults'}
            </Typography>
          </DetailsRow>
          <ColumnlessGridRow
            text={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.howAppetiteAffectsFees.label'
            )}
            link='https://docs.spool.fi/guides/setting-a-risk-appetite'
          />
        </>
      </StyledGridItem>
    </SectionColumn>
  )
}

export default StrategiesAllocation
