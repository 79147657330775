import { useContext } from 'react'

import { DepositContext } from '@/store/deposit/deposit.context'

const useDepositState = () => {
  const depositContext = useContext(DepositContext)
  if (!Object.keys(depositContext).length) {
    throw new Error('DepositContext must be used within its provider')
  }
  return depositContext
}

export default useDepositState
