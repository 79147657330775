import { useWeb3React } from '@web3-react/core'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { CHAINS } from '@/config/web3/chains'

const useNativeCurrency = () => {
  // ARB
  const { provider, account } = useWeb3React()

  const { chain } = useConnectionState()

  const { data, error } = useSWR(
    provider && account && chain
      ? ['nativeCurrency', provider, account, chain]
      : null,
    async ([_, provider, account, chain]) => {
      const balance = await provider.getBalance(account)

      const nativeCurrencyInfo = CHAINS[chain].nativeCurrency

      return {
        ...nativeCurrencyInfo,
        balance: balance,
      }
    },
    {
      refreshInterval: 5000,
      dedupingInterval: 5000,
    }
  )

  return {
    nativeCurrency: data,
    isLoading: !data && !error,
    error,
  }
}

export default useNativeCurrency
