import { ReactNode, useMemo, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useSpoolLocation from '@/hooks/context/useSpoolLocation'

import { NavigationContext } from '@/store/navigation/navigation.context'

import { Routes } from '@/constants/routes'
import {
  getBreadcrumbs,
  mapRoutePatterns,
  mapRoutesToBreadcrumbs,
} from '@/utils/breadcrumbs'
import { navigateReplace } from '@/utils/navigation'

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { chain } = useConnectionState()

  const { pathname } = useSpoolLocation()

  const [isVaultsRowClicked, setIsVaultsRowClicked] = useState<boolean>(false)

  const urlChangeEvent = new Event('urlchange')

  // Map routes to breadcrumbs as key value pairs
  const mappedBreadcrumbs = useMemo(
    () => mapRoutesToBreadcrumbs(Routes(chain)),
    [Routes, chain]
  )

  // Map routes-breadcrumps pairs to regex patterns
  const routesRegex = useMemo(
    () => mapRoutePatterns(mappedBreadcrumbs),
    [mappedBreadcrumbs]
  )

  // Get breadcrumbs from regex patterns
  const breadcrumbs = getBreadcrumbs(routesRegex, pathname)

  // useEffect(() => {
  //    console.log('pathname', pathname)
  // }, [pathname])

  const setPathWithHistory = (
    path: string,
    params?: { [key: string]: string }
  ) => {
    if (params) {
      navigateReplace(path, params)
    } else {
      navigateReplace(path)
    }

    dispatchEvent(urlChangeEvent)
  }

  const contextValue = {
    pathname: pathname,
    breadcrumbs: breadcrumbs || ['No bradcrumbs'],
    isVaultsRowClicked,
    setIsVaultsRowClicked,
    setPathWithHistory,
  }

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  )
}
