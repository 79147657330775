import { Grid, styled, Typography } from '@mui/material'
import { Fragment } from 'react'

interface TooltipGridProps {
  title?: string
  items: { title: React.ReactNode; value: React.ReactNode }[]
}

const GridItemWithBorder = styled(Grid)(() => ({
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
}))

const TooltipGrid: React.FC<TooltipGridProps> = ({ title, items }) => {
  return (
    <Grid container>
      {title && (
        <Grid item xs={12} py={0.75}>
          <Typography variant='body2' fontWeight='500'>
            {title}
          </Typography>
        </Grid>
      )}
      {items.map(({ title: itemTitle, value }, index) => (
        <Fragment key={`${itemTitle}_${index}`}>
          {index > 0 || title ? (
            <>
              <GridItemWithBorder item xs={6} display='table'>
                {itemTitle}
              </GridItemWithBorder>
              <GridItemWithBorder item xs={6} display='table'>
                {value}
              </GridItemWithBorder>
            </>
          ) : (
            <>
              <Grid item xs={6} display='table'>
                {itemTitle}
              </Grid>
              <Grid item xs={6} display='table'>
                {value}
              </Grid>
            </>
          )}
        </Fragment>
      ))}
    </Grid>
  )
}

export default TooltipGrid
