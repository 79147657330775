import { SortOrder } from '@solidant/spool-v2-fe-lib'

import { StrategiesState } from '@/store/strategies/strategies.types'

export const initialState: StrategiesState = {
  strategiesPage: 0,
  strategiesLimit: 10,
  strategiesSort: {
    key: 'name',
    direction: SortOrder.DESC,
  },
  strategyVaultsSort: {
    key: 'totalVolumeRouted',
    direction: SortOrder.DESC,
  },
  filterByAssetGroup: '0',
  filterBySearchTerm: '',
}
