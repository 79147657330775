import { Button, styled } from '@mui/material'

const IncrementDecrementContainer = styled(Button)(({ theme }) => ({
  padding: '1rem',
  marginTop: '1rem',
  display: 'flex',
  border: '1px solid rgba(42, 162, 202, 0.5)',
  color: theme.palette.primary.main,
  width: '50px',
  height: '53px',
}))

export default IncrementDecrementContainer
