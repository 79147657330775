import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { createContext } from 'react'

import {
  SmartVaultsTableContextProps,
  SmartVaultsTableState,
} from '@/store/smartVaultsTable/smartVaultsTable.types'

export const initialState: SmartVaultsTableState = {
  page: 0,
  limit: 10,
  search: '',
  rewardsClaimedVault: '',
  nextRewardsSyncTime: 0,
  smartVaultsTableSort: {
    key: 'tvr',
    direction: SortOrder.DESC,
  },
  selectedVaultId: '',
}

export const SmartVaultsTableContext =
  createContext<SmartVaultsTableContextProps>({
    ...initialState,
    setPage: () => undefined,
    setLimit: () => undefined,
    setMarketId: () => undefined,
    setAssetGroupId: () => undefined,
    setRiskModelProviderId: () => undefined,
    setSearch: () => undefined,
    handleSmartVaultsTableSort: () => undefined,
    setSelectedVault: () => undefined,
    setSelectedVaultId: () => undefined,
    setRewardsClaimedVault: () => undefined,
    setNextRewardsSyncTime: () => undefined,
  })
