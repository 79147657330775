import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import SectionColumn from '@/components/molecules/SectionColumn'
import ManagementFeeConfiguration from '@/components/organisms/CreateVault/Launch/ManagementFee/ManagementFeeConfiguration'

const ManagementFee: React.FC = () => {
  const t = useLocale()
  return (
    <SectionColumn sx={{ gap: 0 }}>
      <Accordion
        sx={{
          bgcolor: 'inherit',
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='overline'>
            {t('components.organisms.createVault.launch.managementFee.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={2}>
            <Typography variant='subtitle1'>
              {t(
                'components.organisms.createVault.launch.managementFee.instruction'
              )}
              <br />
              {t(
                'components.organisms.createVault.launch.managementFee.description'
              )}
            </Typography>
            <ManagementFeeConfiguration />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </SectionColumn>
  )
}

export default ManagementFee
