import { Box, styled } from '@mui/material'

const SliderThumbContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  //   width: '21px',
  cursor: 'pointer',
  marginTop: '-1rem',
  outline: 'none',
}))

export default SliderThumbContainer
