import { isAddress } from '@ethersproject/address'
import { parseFixed } from '@ethersproject/bignumber'
import { Asset, SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { Resolution } from '@unstoppabledomains/resolution'
import { UnsLocation } from '@unstoppabledomains/resolution/build/types/publicTypes'
import { BigNumber, BigNumberish, Contract } from 'ethers/lib/ethers'
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
} from 'ethers/lib/utils'
import { NavigateFunction } from 'react-router-dom'

import { tokenDetails } from '@/components/atoms/Tokens'

import { CHAIN_URL } from '@/constants/routes'
import { NATIVE_TOKEN } from '@/constants/tokens'

//function to get Unstoppable Domains domain if it exists
export const reverseUrl = async (address: string) => {
  const resolution = new Resolution()

  const resolvedDomain = await resolution.reverse(address, {
    location: UnsLocation.Layer2,
  })

  return resolvedDomain
}

export const preventOverflow = (value: string, decimalPlaces = 18) => {
  if (!value.includes('.')) return value

  const parts = value.split('.')

  if (decimalPlaces === 0 && !parts[1]) return parts[0]

  const fraction = parts[1].slice(0, decimalPlaces)

  return `${parts[0]}.${fraction}`
}

export const fetcher =
  (web3Provider: any, abi: any) =>
  (...args: any) => {
    const [arg1, arg2, ...params] = args[0]

    if (isAddress(arg1)) {
      const address = arg1
      const method = arg2

      const contract = new Contract(address, abi, web3Provider.getSigner())

      return contract[method](...params)
    }

    const method = arg1

    return web3Provider[method](arg2, ...params)
  }

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const calculateMargin = (value: BigNumber) =>
  value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000))

export const toBigNumber = (
  value: string,
  decimalPlaces: BigNumberish = 18
) => {
  if (!value) {
    return BigNumber.from(0)
  }

  if (!value.includes('.')) {
    return parseFixed(value, decimalPlaces)
  }

  const parts = value.split('.')
  const fraction = parts[1].slice(0, Number(decimalPlaces))

  return parseFixed(`${parts[0]}.${fraction}`, decimalPlaces)
}

export const getPricePerAssetAmount = (
  conversionAmount: string,
  priceFeed: string,
  decimals: BigNumberish,
  isWei = true
) => {
  if (!+conversionAmount || !priceFeed) return '0'

  return formatUnits(
    toBigNumber(priceFeed, decimals)
      .mul(
        isWei
          ? BigNumber.from(conversionAmount)
          : parseUnits(conversionAmount, decimals)
      )
      .div(parseUnits('1', decimals)),
    decimals
  )
}

export const getAssetAmountFromPrice = (
  conversionAmount: string,
  priceFeed: string,
  decimals: BigNumberish
) => {
  if (!conversionAmount || !priceFeed) return '0'

  const amountToConvert = conversionAmount.includes('.')
    ? parseUnits(conversionAmount, decimals)
    : BigNumber.from(conversionAmount)

  return formatUnits(
    amountToConvert
      .mul(parseUnits('1', decimals))
      .div(toBigNumber(priceFeed, decimals)),
    decimals
  )
}

export const getPriceFee = (
  gasLimit: string | BigNumber,
  gasPrice: BigNumber,
  nativeCurrencyPrice: string
) => {
  const gasLimitInBN =
    typeof gasLimit === 'string' ? BigNumber.from(gasLimit) : gasLimit

  const gasFeesInWei = gasLimitInBN.mul(gasPrice)

  const gasFeesInEth = formatEther(gasFeesInWei)

  const gasFeesInUSD = formatEther(
    toBigNumber(nativeCurrencyPrice).mul(gasFeesInWei).div(parseEther('1'))
  )

  return { gasFeesInEth, gasFeesInUSD }
}

export const fixStringDecimals = (value: string, decimalPoints = 4) => {
  return (+value).toFixed(decimalPoints).toString()
}

export const getIsNativeCurrency = (tokenId: string) => {
  return tokenId === NATIVE_TOKEN
}

export const isLSDStrategy = (
  assets: Asset[],
  chain: SupportedNetworks
): boolean => {
  const foundLSD = assets.find(
    (asset) => tokenDetails[chain][asset.address]?.lsd === true
  )
  return Boolean(foundLSD)
}

export const swapUrlChain = (
  pathname: string,
  key: SupportedNetworks,
  navigate: NavigateFunction
) => {
  const firstPath = pathname.split('/')[1]

  if (firstPath.includes('eth') || firstPath.includes('arb')) {
    navigate(pathname.replace(firstPath, CHAIN_URL[key].replace('/', '')))
  }
}
