import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Link, Typography } from '@mui/material'

import DetailsRow from '@/components/molecules/DetailsRow'

interface ColumnlessGridRow {
  text: string
  isLink?: boolean
  link?: string
}

const ColumnlessGridRow: React.FC<ColumnlessGridRow> = ({
  isLink = true,
  text,
  link,
}) => {
  return (
    <DetailsRow name={text} singleCell>
      <Box
        display='flex'
        width='100%'
        alignItems='center'
        justifyContent='space-between'
      >
        {isLink ? (
          <Link
            sx={{
              textDecoration: 'none',
              '&:hover': { color: 'primary.main' },
            }}
            href={link}
            target='_blank'
            color='#000'
          >
            <Typography
              variant='body2'
              sx={{
                cursor: 'pointer',
              }}
            >
              {text}
            </Typography>
          </Link>
        ) : (
          <Typography fontSize='12px' color='#212322'>
            {text}
          </Typography>
        )}
        {isLink && (
          <Link
            href={link}
            target='_blank'
            color='#000'
            sx={{ height: '18px' }}
          >
            <OpenInNewIcon color='primary' sx={{ fontSize: '18px' }} />
          </Link>
        )}
      </Box>
    </DetailsRow>
  )
}

export default ColumnlessGridRow
