import { Box } from '@mui/material'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useLocale from '@/hooks/context/useLocale'
import useDeposit from '@/hooks/vaultList/Transactions/useDeposit'
import useSwapAndDeposit from '@/hooks/vaultList/Transactions/useSwapAndDeposit'
import useApproveToken from '@/hooks/web3/useApproveToken'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import TransactionActionButtons from '@/components/molecules/TransactionActionButtons'
import DepositDisclaimer from '@/components/organisms/SmartVaults/CollapsibleRow/Deposit/DepositDisclaimer'
import SwapSlippage from '@/components/organisms/SmartVaults/CollapsibleRow/Deposit/Swap/SwapSlippage'
import ConversionInfo from '@/components/organisms/SmartVaults/VaultActions/Deposit/ConversionInfo'
import DepositWarning from '@/components/organisms/SmartVaults/VaultActions/Deposit/DepositWarning'
import OneInchDisclaimer from '@/components/organisms/SmartVaults/VaultActions/Deposit/OneInchDisclaimer'
import SingleAssetDepositInput from '@/components/organisms/SmartVaults/VaultActions/Deposit/SingleAssetDepositInput'
import SingleAssetSwapInfo from '@/components/organisms/SmartVaults/VaultActions/Deposit/SingleAssetSwapInfo'

import { API_ENDPOINTS, IS_PUBLIC_TESTNET } from '@/config/sdk'
import { gaIds } from '@/constants/googleAnalyticIDs'
import { NATIVE_TOKEN, tokenAddresses } from '@/constants/tokens'
import { getIsNativeCurrency } from '@/utils/web3'
const SingleAssetDeposit = () => {
  const t = useLocale()

  const [isConversion, setIsConversion] = useState<boolean>(false)

  const { chain } = useConnectionState()

  const { singleDeposit } = useDepositState()

  const { tokenAddress, amount } = singleDeposit

  const { isTokenApproved, allowance, approve } = useApproveToken(
    singleDeposit.tokenAddress,
    isConversion
      ? API_ENDPOINTS[chain].DEPOSIT_SWAP_MANAGER
      : API_ENDPOINTS[chain].SMART_VAULT_MANAGER,
    singleDeposit.amount
  )

  const deposit = useDeposit()

  const swapAndDeposit = useSwapAndDeposit()

  const { decimals } = useTokenDetails(singleDeposit.tokenAddress)

  const { balance } = useUserBalance(tokenAddress)

  return (
    <Box>
      {!IS_PUBLIC_TESTNET && <OneInchDisclaimer />}
      <Box padding='1.5rem 1.5rem'>
        <Box display='flex' flexDirection='column' gap='1rem'>
          <SingleAssetDepositInput setIsConversion={setIsConversion} />
          <SingleAssetSwapInfo isConversion={isConversion} />
          {isConversion && singleDeposit && !!+singleDeposit.amount && (
            <ConversionInfo />
          )}
          {isConversion && <SwapSlippage />}
          {singleDeposit &&
            tokenAddress === NATIVE_TOKEN &&
            amount === formatUnits(balance, decimals) && (
              <DepositWarning
                text={t(
                  'pages.smartVaults.vaultDetailsPage.deposit.maxEthWarning'
                )}
              />
            )}
          <TransactionActionButtons
            id={gaIds.deposit}
            confirmLabel={
              !isTokenApproved &&
              singleDeposit &&
              singleDeposit.tokenAddress !== NATIVE_TOKEN
                ? singleDeposit.tokenAddress === tokenAddresses[chain].USDT &&
                  !allowance.isZero() &&
                  parseUnits(singleDeposit.amount || '0', decimals).gt(
                    allowance
                  )
                  ? t(
                      'components.organisms.smartVaults.collapsibleRow.deposit.standard.singleDepositAmount.transactionButton.resetAndApproveToken'
                    )
                  : t(
                      'components.organisms.smartVaults.collapsibleRow.deposit.standard.singleDepositAmount.transactionButton.approveToken'
                    )
                : t(
                    'components.organisms.smartVaults.collapsibleRow.deposit.standard.singleDepositAmount.transactionButton.label'
                  )
            }
            disabled={
              !singleDeposit.amount ||
              parseUnits(singleDeposit.amount, decimals).isZero()
            }
            onConfirm={
              // if swapping from nativeCurrency, no approval needed
              isTokenApproved || getIsNativeCurrency(singleDeposit.tokenAddress)
                ? isConversion
                  ? swapAndDeposit
                  : deposit
                : () => approve(singleDeposit.amount)
            }
            sx={{ mb: '1rem' }}
          />
        </Box>
        <DepositDisclaimer />
      </Box>
    </Box>
  )
}

export default SingleAssetDeposit
