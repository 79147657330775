import { parseEther, parseUnits } from '@ethersproject/units'
import { BigNumber } from 'ethers/lib/ethers'

// get share per dollar in 18 decimal places
export const getSharePerDollar = (
  shares: string[],
  totalUserDollarValue: BigNumber
) => {
  const totalShares = shares.reduce(
    (acc, share) => BigNumber.from(acc).add(BigNumber.from(share)),
    BigNumber.from('0')
  )

  const sharePerDollar = totalShares
    .mul(parseUnits('1', 36))
    .div(totalUserDollarValue)

  //returns sharePerDollar in 18 decimals
  return sharePerDollar
}

// get USD value converted to SVT
export const getUsdToSvt = (
  usdValue: string,
  userBalanceInSvt: string,
  userBalanceInUsd: string
) => {
  // tvr returned from API is in USD, and tvr is SVTs. So dividing tvr by USD will return
  // the amount of SVT per USD
  return parseEther(usdValue)
    .mul(parseEther(userBalanceInSvt))
    .div(parseEther(userBalanceInUsd))
    .div(parseEther('1'))
}

// get shares for dNFT based on SVTs
export const getRequiredNFTShares = (
  svtsToBurn: BigNumber,
  nftSVTs: BigNumber,
  nftShares: string
) => {
  const requiredShares = svtsToBurn.mul(nftShares).div(nftSVTs)

  return requiredShares
}
