interface SymbolLineProps {
  height: number
  width: number
  fill: string
  opacity?: number
  borderWidth?: number
  borderColor?: string
}

const LegendLine: React.FC<SymbolLineProps> = ({
  height,
  width,
  fill,
  opacity = 1,
  borderWidth = 0,
  borderColor = 'transparent',
}) => (
  <svg width={width} height={height}>
    <rect
      width={width}
      height={height}
      fill={fill}
      opacity={opacity}
      strokeWidth={borderWidth}
      stroke={borderColor}
      style={{
        pointerEvents: 'none',
      }}
    />
  </svg>
)

export default LegendLine
