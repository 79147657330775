import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, Typography } from '@mui/material'

interface DepositWarningProps {
  text: string
}

const DepositWarning: React.FC<DepositWarningProps> = ({ text }) => {
  return (
    <>
      <Box borderRadius='0.2rem' bgcolor='#fdd8ba'>
        <Box padding='1rem' display='flex' gap='1rem'>
          <>
            <WarningAmberIcon sx={{ color: '#ED6C02' }} />
            <Box>
              <Typography color='#00000099' fontSize='14px'>
                {text}
              </Typography>
            </Box>{' '}
          </>
        </Box>
      </Box>
    </>
  )
}

export default DepositWarning
