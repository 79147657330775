import WarningIcon from '@mui/icons-material/Warning'
import { Box, TableBody, TableCell, TableRow } from '@mui/material'

import useInvestorDashboardState from '@/hooks/context/useInvestorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useInvestorDashboard from '@/hooks/dashboard/useInvestorDashboard'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import DataMissing from '@/components/molecules/DataMissing'
import SectionColumn from '@/components/molecules/SectionColumn'
import TableWrapper from '@/components/molecules/TableWrapper'
import InvestorVaultsMobileRow from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorVaultsMobileRow'
import InvestorVaultTableRow from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorVaultTableRow'

import { investorDashboardHeadCells } from '@/constants/dashboard'
import { sort } from '@/utils/table'

const InvestorVaultTable = () => {
  const t = useLocale()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const {
    investorSmartVaultLimit,
    investorSmartVaultPage,
    investorSmartVaultSort,
    handleInvestorSmartVaultSort,
    setInvestorVaultsTableLimit,
    setInvestorVaultsTablePage,
  } = useInvestorDashboardState()

  const { vaults, optimisticallyBurnDNfts } = useInvestorDashboard()

  return !belowPhabletBreakpoint ? (
    <SectionColumn
      title={t(
        'components.organisms.dashboard.investor.investorSmartVaults.sectionTitle'
      )}
      sx={{ px: 0, gap: 1 }}
    >
      <Box
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        mb={(theme) => theme.spacing(1)}
      >
        <TableWrapper
          tokens
          header={investorDashboardHeadCells}
          headerClass='default-table-head'
          pagination={
            vaults?.length > 9 && {
              rowsPerPageOptions: [10, 20, 30],
              page: investorSmartVaultPage,
              rowsPerPage: investorSmartVaultLimit,
              count: vaults?.length,
              setPage: setInvestorVaultsTablePage,
              setRowsPerPage: setInvestorVaultsTableLimit,
            }
          }
          handleSortClick={handleInvestorSmartVaultSort}
          sortType={investorSmartVaultSort}
        >
          <Box
            component='thead'
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              pt: 4,
              pr: 2,
            }}
          ></Box>
          {vaults?.length ? (
            <TableBody>
              {vaults
                .sort((a, b) => sort(a, b, investorSmartVaultSort))
                .slice(
                  investorSmartVaultPage * investorSmartVaultLimit,
                  (investorSmartVaultPage + 1) * investorSmartVaultLimit
                )
                .map((investorVault) => (
                  <InvestorVaultTableRow
                    key={investorVault.address}
                    row={investorVault}
                    optimisticallyBurnDNfts={optimisticallyBurnDNfts}
                  />
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    my='2rem'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <WarningIcon
                      sx={{ height: '90px', width: '90px' }}
                      color='primary'
                    />
                    <DataMissing text='Investor Vaults not found...' />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </TableWrapper>
      </Box>
    </SectionColumn>
  ) : (
    vaults
      .sort((a, b) => sort(a, b, investorSmartVaultSort))
      .slice(
        investorSmartVaultPage * investorSmartVaultLimit,
        (investorSmartVaultPage + 1) * investorSmartVaultLimit
      )
      .map((investorVault) => (
        <InvestorVaultsMobileRow
          key={investorVault.address}
          row={investorVault}
          optimisticallyBurnDNfts={optimisticallyBurnDNfts}
        />
      ))
  )
}

export default InvestorVaultTable
