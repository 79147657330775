const USDT = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='25' cy='25' r='18' fill='white' />
      <path
        d='M27.2385 26.9345V26.9413C32.3534 26.7117 36.1719 25.8204 36.1719 24.7535C36.1719 23.6866 32.3568 22.7953 27.2385 22.5657V26.05C27.0899 26.0635 26.2931 26.131 24.6996 26.131C23.3727 26.131 22.4105 26.0736 22.0762 26.05V22.5623C16.9512 22.7885 13.1225 23.6832 13.1225 24.7501C13.1225 25.8204 16.9478 26.7117 22.0762 26.9379V26.9345C22.4071 26.9514 23.3423 26.9885 24.6759 26.9885C26.3438 26.9885 27.0933 26.9446 27.2385 26.9345Z'
        fill='#559558'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46 25C46 36.598 36.598 46 25 46C13.402 46 4 36.598 4 25C4 13.402 13.402 4 25 4C36.598 4 46 13.402 46 25ZM27.2385 19.0848V22.2045L27.2418 22.2078C33.0354 22.4746 37.3907 23.6258 37.3907 25C37.3907 26.3774 33.0354 27.5254 27.2418 27.7921V37.7924H22.0796V27.7921C16.2793 27.522 11.9139 26.3741 11.9139 24.9966C11.9139 23.6191 16.2759 22.4712 22.0796 22.2045V19.0848H14.9389V14.3278H34.3791V19.0848H27.2385Z'
        fill='#559558'
      />
    </svg>
  )
}

export default USDT
