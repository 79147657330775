import { createContext } from 'react'

import { initialState } from '@/store/createMetavault/createMetavault.state'
import { CreateMetavaultContextProps } from '@/store/createMetavault/createMetavault.types'

export const CreateMetavaultContext =
  createContext<CreateMetavaultContextProps>({
    ...initialState,
    setCurrentStep: () => undefined,
    setStepCompletedStatus: () => undefined,
    setAsset: () => undefined,
    setSelectedVaults: () => undefined,
    handleSortClick: () => undefined,
    setSelectedVaultsAllocations: () => undefined,
    setVaultName: () => undefined,
    setVaultSymbol: () => undefined,
    resetAll: () => undefined,
    setSearchingString: () => undefined,
    setMetavaultId: () => undefined,
    setNewlyAddedVaults: () => undefined,
  })
