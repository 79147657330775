import { MetaVault } from '@spool.fi/spool-v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { isAddress } from 'ethers/lib/utils'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

const useMetavaultsPage = () => {
  const { chain } = useConnectionState()

  const { sdkExternal } = useContext(AppContext)
  const { account } = useWeb3React()

  const { metavaultId } = useParams()

  const { data, error } = useSWR(
    sdkExternal && metavaultId
      ? ['selectedMetavault', metavaultId, account, chain]
      : null,
    async () => {
      if (!isAddress(metavaultId)) {
        return {} as MetaVault
      }

      const metavaults = await sdkExternal.views.metaVault.getUserMetaVaults(
        account?.toLowerCase() || '0x0',
        true
      )

      const selectedMetavault = metavaults.find(
        (metavault) => metavault.metaVaultId === metavaultId
      )

      return {
        ...selectedMetavault,
      } as MetaVault
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    metavaultData: data,
    isLoading: !error && !data,
    error,
  }
}

export default useMetavaultsPage
