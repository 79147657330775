const WalletConnectLogo = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='40'
        height='40'
        rx='4.44444'
        fill='#4B95F8'
        fillOpacity='0.12'
      />
      <g clipPath='url(#clip0_970_7357)'>
        <path
          d='M19.9998 33.3333C27.3636 33.3333 33.3332 27.3638 33.3332 20C33.3332 12.6362 27.3636 6.66667 19.9998 6.66667C12.636 6.66667 6.6665 12.6362 6.6665 20C6.6665 27.3638 12.636 33.3333 19.9998 33.3333Z'
          fill='url(#paint0_radial_970_7357)'
        />
        <path
          d='M15.0389 16.9648C17.7212 14.3451 22.0754 14.3451 24.7576 16.9648L25.0806 17.2826C25.216 17.4128 25.216 17.6263 25.0806 17.7565L23.9764 18.8346C23.9087 18.9023 23.7993 18.9023 23.7316 18.8346L23.2889 18.4023C21.4139 16.5742 18.3826 16.5742 16.5076 18.4023L16.0337 18.8659C15.966 18.9336 15.8566 18.9336 15.7889 18.8659L14.6847 17.7878C14.5493 17.6576 14.5493 17.444 14.6847 17.3138L15.0389 16.9648ZM27.0441 19.194L28.0285 20.1523C28.1639 20.2826 28.1639 20.4961 28.0285 20.6263L23.5962 24.9544C23.4608 25.0846 23.242 25.0846 23.1118 24.9544L19.966 21.8815C19.9347 21.8503 19.8774 21.8503 19.8462 21.8815L16.7004 24.9544C16.5649 25.0846 16.3462 25.0846 16.216 24.9544L11.7681 20.6263C11.6326 20.4961 11.6326 20.2826 11.7681 20.1523L12.7524 19.194C12.8879 19.0638 13.1066 19.0638 13.2368 19.194L16.3826 22.2669C16.4139 22.2982 16.4712 22.2982 16.5024 22.2669L19.6483 19.194C19.7837 19.0638 20.0024 19.0638 20.1326 19.194L23.2785 22.2669C23.3097 22.2982 23.367 22.2982 23.3983 22.2669L26.5441 19.194C26.6899 19.0638 26.9087 19.0638 27.0441 19.194Z'
          fill='white'
        />
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_970_7357'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(6.66659 20.0004) scale(26.6667)'
        >
          <stop stopColor='#5D9DF6' />
          <stop offset='1' stopColor='#006FFF' />
        </radialGradient>
        <clipPath id='clip0_970_7357'>
          <rect
            width='26.6667'
            height='26.6667'
            fill='white'
            transform='translate(6.6665 6.66667)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WalletConnectLogo
