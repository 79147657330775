const Arbitrum = () => {
  return (
    <svg
      width='16'
      height='20'
      viewBox='0 0 20 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.317 10.6047L13.9248 7.87674L18.2582 14.6263L18.2602 15.9215L18.2461 7.00822C18.2358 6.79034 18.1201 6.59101 17.9356 6.47348L10.1337 1.98575C9.95125 1.89607 9.71979 1.89702 9.53765 1.98854C9.51305 2.0009 9.48989 2.01428 9.46781 2.02889L9.44063 2.04601L1.86769 6.43448L1.83828 6.4478C1.8005 6.46519 1.76232 6.48727 1.72645 6.51295C1.5829 6.61594 1.48751 6.76819 1.45667 6.93899C1.45205 6.96487 1.44865 6.99123 1.44702 7.0178L1.45891 14.2813L5.49532 8.02505C6.0035 7.19546 7.11076 6.92825 8.1386 6.94279L9.34498 6.97466L2.23701 18.3738L3.07489 18.8562L10.2681 6.98621L13.4474 6.97466L6.27281 19.1442L9.26263 20.8639L9.61985 21.0693C9.77095 21.1307 9.94908 21.1338 10.1015 21.0788L18.0129 16.494L16.5004 17.3705L12.317 10.6047ZM12.9304 19.4393L9.91063 14.6998L11.754 11.5718L15.7199 17.8228L12.9304 19.4393Z'
        fill='#2D374B'
      />
      <path
        d='M9.91052 14.6998L12.9303 19.4393L15.7199 17.8227L11.754 11.5718L9.91052 14.6998Z'
        fill='#28A0F0'
      />
      <path
        d='M18.2603 15.9215L18.2582 14.6262L13.9248 7.87671L12.317 10.6047L16.5004 17.3705L18.013 16.494C18.1613 16.3735 18.2511 16.1967 18.2605 16.0059L18.2603 15.9215Z'
        fill='#28A0F0'
      />
      <path
        d='M0.100898 17.1429L2.23688 18.3737L9.34484 6.97456L8.13846 6.9427C7.11063 6.92816 6.00344 7.19536 5.49519 8.02495L1.45878 14.2812L0.10083 16.3676V17.1429H0.100898Z'
        fill='white'
      />
      <path
        d='M13.4473 6.97461L10.268 6.98616L3.07483 18.8562L5.58902 20.3038L6.27275 19.1442L13.4473 6.97461Z'
        fill='white'
      />
      <path
        d='M19.6 6.95823C19.5735 6.29332 19.2135 5.68459 18.6495 5.33023L10.7454 0.784821C10.1876 0.503896 9.49069 0.503557 8.93197 0.784617C8.86593 0.817907 1.24537 5.23756 1.24537 5.23756C1.13993 5.2881 1.03836 5.34837 0.942774 5.41671C0.439352 5.77753 0.132475 6.33816 0.100952 6.95415V16.3677L1.4589 14.2813L1.44701 7.01781C1.44864 6.99125 1.45197 6.96516 1.45666 6.93934C1.4873 6.76841 1.58275 6.61596 1.72644 6.51296C1.76231 6.48728 9.51297 2.00091 9.53764 1.98855C9.71985 1.89704 9.95131 1.89609 10.1337 1.98576L17.9355 6.47349C18.1201 6.59102 18.2358 6.79035 18.2461 7.00823V16.0059C18.2366 16.1967 18.1612 16.3735 18.0129 16.494L16.5003 17.3705L15.7199 17.8228L12.9303 19.4394L10.1014 21.0788C9.949 21.1339 9.77087 21.1308 9.61977 21.0693L6.27273 19.1442L5.589 20.3038L8.59689 22.0356C8.69636 22.0921 8.78502 22.1423 8.85771 22.1832C8.97035 22.2464 9.04706 22.2885 9.07416 22.3017C9.28796 22.4055 9.59552 22.4659 9.87271 22.4659C10.1269 22.4659 10.3746 22.4193 10.6092 22.3274L18.8259 17.5688C19.2976 17.2034 19.575 16.6524 19.6 16.0554V6.95823Z'
        fill='#96BEDC'
      />
    </svg>
  )
}

export default Arbitrum
