import { CreatorFeeVaultSorting, SortOrder } from '@solidant/spool-v2-fe-lib'

import { RewardsTableSort } from '@/types/rewards'

export interface RewardsTableState {
  page: number
  limit: number
  owner: string
  vaultAddresses?: string
  rewardsTableSort?: RewardsTableSort
}

export interface RewardsTableContextProps extends RewardsTableState {
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  setOwner: (owner: string) => void
  handleRewardsTableSort: (
    key: CreatorFeeVaultSorting,
    sortType: RewardsTableSort
  ) => void
}

export enum RewardsTableActionTypes {
  SET_PAGE = 'SET_PAGE',
  SET_LIMIT = 'SET_LIMIT',
  SET_OWNER = 'SET_OWNER',
  SET_VAULT_ADDRESSES = 'SET_VAULT_ADDRESSES',
  SET_REWARDS_TABLE_SORT = 'SET_REWARDS_TABLE_SORT',
  SET_REWARDS_TABLE_SORT_DIRECTION = 'SET_REWARDS_TABLE_SORT_DIRECTION',
}

export type RewardsTableAction =
  | {
      type: RewardsTableActionTypes.SET_PAGE
      payload: number
    }
  | {
      type: RewardsTableActionTypes.SET_LIMIT
      payload: number
    }
  | {
      type: RewardsTableActionTypes.SET_OWNER
      payload: string
    }
  | {
      type: RewardsTableActionTypes.SET_VAULT_ADDRESSES
      payload: string[]
    }
  | {
      type: RewardsTableActionTypes.SET_REWARDS_TABLE_SORT
      payload: CreatorFeeVaultSorting
    }
  | {
      type: RewardsTableActionTypes.SET_REWARDS_TABLE_SORT_DIRECTION
      payload: SortOrder
    }
