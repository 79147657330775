import { parseUnits } from '@ethersproject/units'
import { ethers } from 'ethers'
import { BigNumber } from 'ethers/lib/ethers'

import erc20ABI from '@/abis/erc20ABI'
import { tokenAddresses } from '@/constants/tokens'
import { sleep } from '@/utils/web3'

export const approveToken = async (
  tokenAddress: string,
  decimals: number,
  spender: string,
  approveAmount: string,
  provider: ethers.providers.Web3Provider
): Promise<any> => {
  if (!tokenAddress)
    throw new Error('useApproveToken: tokenAddress not specified')
  if (!spender) throw new Error('useApproveToken: spender not specified')

  const erc20Contract = new ethers.Contract(
    tokenAddress,
    erc20ABI,
    provider.getSigner()
  )

  const estimatedGas = await erc20Contract.estimateGas
    .approve(spender, parseUnits(approveAmount, decimals))
    .catch(() => {
      // general fallback for tokens who restrict approval amounts

      return erc20Contract.estimateGas
        .approve(spender, parseUnits(approveAmount, decimals))
        .catch(() => BigNumber.from('50000'))
    })

  const approve = await erc20Contract.approve(
    spender,
    parseUnits(approveAmount, decimals),
    {
      gasLimit: estimatedGas,
    }
  )

  return approve
}

export const getTokenApprovalArray = (
  tokenAddress: string,
  decimals: number,
  spender: string,
  approveAmount: string,
  provider: ethers.providers.Web3Provider,
  allowance: BigNumber
) => {
  if (tokenAddress === tokenAddresses.USDT && !allowance.isZero()) {
    return [
      approveToken(tokenAddress, decimals, spender, '0', provider),
      (() => {
        //sleep so that approve(0) always pops first
        sleep(10000)
        return approveToken(
          tokenAddress,
          decimals,
          spender,
          approveAmount,
          provider
        )
      })(),
    ]
  }
  return [
    approveToken(tokenAddress, decimals, spender, approveAmount, provider),
  ]
}
