import { SortOrder, SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import getLocale from '@/locales/getLocale'

import {
  CreateVaultState,
  StepData,
} from '@/store/createVault/createVault.types'

import { CURRENT_NETWORK, FEATURES, IS_PUBLIC_TESTNET } from '@/config/sdk'
import { DEFAULT_MANAGEMENT_FEE, DEFAULT_PERFORMANCE_FEE } from '@/constants'
import { DEFAULT_ALLOCATION_PROVIDER } from '@/constants/smartVault'

const t = getLocale()

export const STEP_SEQUENCE = {
  RISK_MODELS: 1,
  STRATEGIES: 2,
  ALLOCATIONS: 3,
  GUARDS: 4,
  ACTIONS: 5,
  LAUNCH: 6,
}

const GUARDS_ACTIONS: StepData[] = [
  {
    name: t('store.createVault.state.guardsActions.guards.name'),
    nextBtn: {
      label: t('store.createVault.state.guardsActions.guards.nextBtn.label'),
      toStep: STEP_SEQUENCE.LAUNCH,
    },
    advBtn: {
      label: t('store.createVault.state.guardsActions.guards.advBtn.label'),
      toStep: STEP_SEQUENCE.ACTIONS,
    },
    prevBtn: {
      label: t('store.createVault.state.guardsActions.guards.prevBtn.label'),
      toStep: STEP_SEQUENCE.ALLOCATIONS,
    },
    completed: false,
  },
  {
    name: t('store.createVault.state.guardsActions.actions.name'),
    nextBtn: {
      label: t('store.createVault.state.guardsActions.actions.nextBtn.label'),
      toStep: STEP_SEQUENCE.LAUNCH,
    },
    advBtn: {
      label: t('store.createVault.state.guardsActions.actions.advBtn.label'),
      toStep: STEP_SEQUENCE.GUARDS,
    },
    prevBtn: {
      label: t('store.createVault.state.guardsActions.actions.prevBtn.label'),
      toStep: STEP_SEQUENCE.ALLOCATIONS,
    },
    completed: false,
  },
]

export const initialState: CreateVaultState = {
  steps: [
    {
      name: t('store.createVault.state.initialState.steps.riskModel.name'),
      nextBtn: {
        label: t(
          'store.createVault.state.initialState.steps.riskModel.nextBtn.label'
        ),
        toStep: STEP_SEQUENCE.STRATEGIES,
      },
      completed: false,
    },
    {
      name: t('store.createVault.state.initialState.steps.strategies.name'),
      nextBtn: {
        label: t(
          'store.createVault.state.initialState.steps.strategies.nextBtn.label'
        ),
        toStep: STEP_SEQUENCE.ALLOCATIONS,
      },
      prevBtn: {
        label: t(
          'store.createVault.state.initialState.steps.strategies.prevBtn.label'
        ),
        toStep: STEP_SEQUENCE.RISK_MODELS,
      },
      completed: false,
    },

    {
      name: t('store.createVault.state.initialState.steps.allocation.name'),
      nextBtn: {
        label: t(
          'store.createVault.state.initialState.steps.allocation.nextBtn.label'
        ),
        toStep: STEP_SEQUENCE.LAUNCH,
      },

      prevBtn: {
        label: t(
          'store.createVault.state.initialState.steps.allocation.prevBtn.label'
        ),
        toStep: STEP_SEQUENCE.STRATEGIES,
      },
      completed: true,
    },
    ...(FEATURES(
      IS_PUBLIC_TESTNET ? SupportedNetworks.SEPOLIA : SupportedNetworks.ETHEREUM
    ).CREATE_VAULT_ADVANCED
      ? GUARDS_ACTIONS.map((item) => ({
          name: item.name,
          nextBtn: { ...item.nextBtn },
          advBtn: { ...item.advBtn },
          prevBtn: { ...item.prevBtn },
          completed: item.completed,
        }))
      : []),
    {
      name: t('store.createVault.state.initialState.steps.launch.name'),
      nextBtn: {
        label: t(
          'store.createVault.state.initialState.steps.launch.nextBtn.label'
        ),
        toStep: null,
      },
      prevBtn: {
        label: t(
          'store.createVault.state.initialState.steps.launch.prevBtn.label'
        ),
        toStep: 5,
      },
      completed: true,
    },
  ],
  isUnavailableProvider: true,
  currentStep: 1,
  selectedRiskModel: '',
  selectedStrategies: [],
  selectedAssetGroupId: '',
  selectedAllocationProvider: DEFAULT_ALLOCATION_PROVIDER[CURRENT_NETWORK],
  selectedRiskScore: 0,
  selectedManagementFee: DEFAULT_MANAGEMENT_FEE,
  selectedPerformanceFee: DEFAULT_PERFORMANCE_FEE,
  vaultName: '',
  svtSymbol: '',
  isVaultNameValid: true,
  isSvtSymbolValid: true,
  filterByAssetGroup: '0',
  filterBySearchTerm: '',
  strategySort: {
    key: 'name',
    direction: SortOrder.DESC,
  },
  selectedGuards: {},
  selectedActions: {},
  selectedMarket: '',
}
