import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography } from '@mui/material'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import SearchField from '@/components/organisms/CreateMetavault/Vaults/SearchField'
import VaultSelectorTable from '@/components/organisms/CreateMetavault/Vaults/VaultSelectorTable'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

import { MAX_METAVAULTSELECTION } from '@/constants/metavaults'

const MetavaultAddVaultsModal = () => {
  const t = useLocale()
  const { closeModal } = useModalState()
  const {
    selectedVaults,
    setSelectedVaults,
    newlyAddedVaults,
    setNewlyAddedVaults,
    selectedVaultsAllocations,
    setSelectedVaultsAllocations,
  } = useCreateMetavaultState()

  const handleClose = () => {
    closeModal(Modals.METAVAULT_ADD_VAULTS_MODAL)
    setNewlyAddedVaults([])
  }

  const handleAdd = () => {
    closeModal(Modals.METAVAULT_ADD_VAULTS_MODAL)
    const newVaults = newlyAddedVaults.filter(
      (newVault) =>
        !selectedVaults.some(
          (selectedVault) => selectedVault.id === newVault.id
        )
    )
    setSelectedVaults([...selectedVaults, ...newVaults])
    setSelectedVaultsAllocations([
      ...selectedVaultsAllocations,
      ...newlyAddedVaults.map(() => 0),
    ])
    setNewlyAddedVaults([])
  }

  return (
    <ModalBox id='metavaultAddVaultsModal'>
      <ModalBoxOuterContainer
        sx={{ width: '90%', maxWidth: '480', padding: '0rem' }}
      >
        <Box
          display='flex'
          paddingY='1rem'
          px='1rem'
          alignItems='center'
          justifyContent='space-between'
          borderBottom='1px solid rgba(0, 0, 0, 0.12)'
        >
          <Typography fontWeight={500}>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.row.actionMetavaultsAddVaults'
            )}
          </Typography>
          <Box display='flex' onClick={handleClose}>
            <CloseIcon
              sx={{
                color: 'text.secondary',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' sx={{ p: 3 }} gap={1}>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='cstartenter'
            sx={{ width: '100%' }}
          >
            <Typography variant='subtitle1'>
              {t(
                'components.organisms.createMetavault.vaults.vaultSelector.label'
              )}{' '}
              ({selectedVaults?.length + newlyAddedVaults.length}/
              {MAX_METAVAULTSELECTION})
            </Typography>
            <SearchField />
          </Box>
          <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <VaultSelectorTable addingVaults />
          </Box>
          <Box display='flex' justifyContent='end' sx={{ width: '100%' }}>
            <Button
              variant='contained'
              sx={{ px: 4 }}
              onClick={handleAdd}
              disabled={!newlyAddedVaults.length}
            >
              ADD
            </Button>
          </Box>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default MetavaultAddVaultsModal
