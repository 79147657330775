import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Link,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import ReceiptModalBoxOuterContainer from '@/components/atoms/ReceiptModalBoxContainer'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

import { CHAIN_EXPLORER } from '@/constants/web3'
import { ellipsisText, formatUSD } from '@/utils/formats'

const WITHDRAW_STEPS = [
  {
    label: 'Withdrawal initiated',
  },
  {
    label: 'Withdrawal processing (24-48 hours)',
  },
  {
    label:
      'Withdrawal ready to claim via “Claim Withdrawal” in “Depositor Dashboard”',
  },
]

const ACTIVE_STEP = 1

const ReceiptsModal = () => {
  const { chain } = useConnectionState()

  const t = useLocale()

  const { modals, closeModal } = useModalState()

  const { account } = useWeb3React()

  const receipt = modals['receiptsModal']?.modalType?.receiptsModalType?.receipt

  if (!receipt) return null

  const explorerURL = CHAIN_EXPLORER[chain].url + 'tx/'

  return (
    <ModalBox id='receiptsModal'>
      <ReceiptModalBoxOuterContainer sx={{ width: '480px' }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb='1rem'
          mx='1rem'
        >
          <Box display='flex' alignItems='center'>
            <AccessTimeIcon
              sx={{ color: 'text.secondary', marginRight: '0.2rem' }}
              className='lg'
            />
            <Typography fontSize='16px' fontWeight={500}>
              {t(
                'pages.smartVaults.vaultDetailsPage.receipts.pendingWithdrawals'
              )}
            </Typography>
          </Box>
          <Box display='flex' onClick={() => closeModal(Modals.RECEIPTS_MODAL)}>
            <CloseIcon
              className='lg'
              sx={{ cursor: 'pointer', color: 'text.secondary' }}
            />
          </Box>
        </Box>
        <Box mb='1rem' mx='1rem'>
          <Box display='flex' justifyContent='space-between' mb='0.4rem'>
            <Typography fontSize='14px' color='text.secondary'>
              {t('pages.smartVaults.vaultDetailsPage.receipts.destination')}
            </Typography>
            <Typography fontSize='14px'>
              {ellipsisText(account ?? '0', 6, 6)}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Typography fontSize='14px' color='text.secondary'>
              {t('pages.smartVaults.vaultDetailsPage.receipts.amount')}
            </Typography>
            <Box display='flex' flexDirection='column' justifyContent='right'>
              {receipt.withdrawnAssets.map(({ amount, asset }, index) => {
                return (
                  <Box
                    key={index}
                    display='flex'
                    justifyContent='right'
                    alignItems='center'
                    gap='0.2rem'
                  >
                    <Typography fontSize='14px'>
                      {formatUSD(amount)} {asset.symbol}
                    </Typography>
                    <Box display='flex'>
                      {mapAssetTokenIcon(chain, asset.address ?? '')}
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
        <Box
          borderTop='1px solid rgba(0, 0, 0, 0.12)'
          borderBottom='1px solid rgba(0, 0, 0, 0.12)'
        >
          <Box mx='1rem' mb='1rem' minWidth='440px'>
            <Box minHeight='2rem' ml='0.8rem' borderLeft='2px solid #2E7D32' />
            {/* <Stepper activeStep={ACTIVE_STEP} orientation='vertical'>
              {WITHDRAW_STEPS.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper> */}
            <Stepper
              connector={
                <StepConnector
                  sx={{
                    mt: '-0.7rem',
                    minHeight: '2rem',
                    ml: '0.8rem',
                    '&.Mui-active': {
                      borderLeft: '2px solid #2E7D32',
                    },
                    '&.Mui-disabled': {
                      mt: '-0.8rem',
                      minHeight: '2.5rem',
                      borderLeft: '2px solid #00000061',
                    },
                    '& .MuiStepConnector-lineVertical': {
                      border: 0,
                    },
                  }}
                />
              }
              activeStep={ACTIVE_STEP}
              orientation='vertical'
            >
              {WITHDRAW_STEPS.map((step, index) => {
                return (
                  <Step
                    key={step.label}
                    sx={{
                      mt: `${index > ACTIVE_STEP ? '-1.05rem' : '-1.15rem'} `,
                      ml: '0.4rem',
                    }}
                  >
                    <StepLabel
                      sx={{
                        '&.MuiStepLabel-root': {
                          padding: '0',
                          fontWeight: 400,
                        },
                        '& .Mui-completed': {
                          '& .MuiStepIcon-root': {
                            color: '#2E7D32',
                          },
                          '&.MuiStepLabel-label': {
                            fontWeight: 400,
                          },
                        },
                        '& .Mui-active': {
                          '& .MuiStepIcon-root': {
                            color: '#2E7D32',
                          },
                          '&.MuiStepLabel-label': {
                            fontWeight: 400,
                            color: '#2E7D32',
                          },
                        },
                      }}
                      icon={
                        <Typography
                          color={index > ACTIVE_STEP ? '#00000061' : '#2E7D32'}
                          fontSize='24px'
                        >
                          ●
                        </Typography>
                      }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        </Box>
        <Box mt='1rem' mx='1rem'>
          <Box display='flex' justifyContent='space-between'>
            <Typography fontSize='14px' color='text.secondary'>
              {t('pages.smartVaults.vaultDetailsPage.receipts.transaction')}
            </Typography>
            <Link
              href={explorerURL + receipt.txHash}
              target='_blank'
              rel='noopener noreferrer'
              fontSize='14px'
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  color: '#006BA6',
                },
              }}
            >
              {t('pages.smartVaults.vaultDetailsPage.receipts.etherscan')}
              {CHAIN_EXPLORER[chain].name}
            </Link>
          </Box>
        </Box>
      </ReceiptModalBoxOuterContainer>
    </ModalBox>
  )
}

export default ReceiptsModal
