const Verified = () => {
  return (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.73268 14L4.46602 11.8667L2.06602 11.3333L2.29935 8.86667L0.666016 7L2.29935 5.13333L2.06602 2.66667L4.46602 2.13333L5.73268 0L7.99935 0.966667L10.266 0L11.5327 2.13333L13.9327 2.66667L13.6993 5.13333L15.3327 7L13.6993 8.86667L13.9327 11.3333L11.5327 11.8667L10.266 14L7.99935 13.0333L5.73268 14ZM7.29935 9.36667L11.066 5.6L10.1327 4.63333L7.29935 7.46667L5.86602 6.06667L4.93268 7L7.29935 9.36667Z'
        fill='#03A9F4'
      />
    </svg>
  )
}

export default Verified
