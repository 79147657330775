import { TableCell, TableRow } from '@mui/material'

interface TableStatusProps {
  colSpan?: number
  children: React.ReactNode
}

const TableStatus: React.FC<TableStatusProps> = ({ children, colSpan = 3 }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ textAlign: 'center' }}>
        {children}
      </TableCell>
    </TableRow>
  )
}

export default TableStatus
