const Linear = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.59 3L18 4.41L14.96 7.83L13.55 9.42L8 15.5L7.5 16L6 14.53L6.5 14L12.38 7.73L13.74 6.21L16.59 3Z'
        fill='black'
        fillOpacity='0.54'
      />
      <path d='M13 20H11V18H13V20Z' fill='black' fillOpacity='0.54' />
      <path d='M5 20H3V18H5V20Z' fill='black' fillOpacity='0.54' />
      <path d='M9 20H7V18H9V20Z' fill='black' fillOpacity='0.54' />
      <path d='M17 20H15V18H17V20Z' fill='black' fillOpacity='0.54' />
      <path d='M21 20H19V18H21V20Z' fill='black' fillOpacity='0.54' />
    </svg>
  )
}

export default Linear
