import { Box } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import DashboardMetricsField from '@/components/molecules/DashboardMetricsField'
import SectionColumn from '@/components/molecules/SectionColumn'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

const CreatorMetrics = () => {
  const t = useLocale()

  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { metrics, vaults } = useCreatorDashboard()

  const { totalFeesEarned, totalTvr } = metrics
  const { management, performance } = totalFeesEarned

  return (
    <Box display='flex' gap={2}>
      <SectionColumn
        noVerticalGap
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
          background: '#ffffff',
          flex: 1,
          paddingX: '1.5rem',
          padding: belowTabletBreakpoint ? '1.5rem' : '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: belowTabletBreakpoint ? 'column' : 'row',
            gap: belowTabletBreakpoint ? '1rem' : '',
            textAlign: 'right',
          }}
        >
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.creatorMetrics.vaultsCurrentTvr'
            )}
            value={`${currency} ${formatUSD(totalTvr)}`}
          />
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.creatorMetrics.performanceFees'
            )}
            value={`${+performance > 0.099 ? '+' : ''} ${currency} ${formatUSD(
              performance
            )}`}
            isHighlighted={+performance > 0.099}
          />

          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.creatorMetrics.managementFees'
            )}
            value={`${+management > 0.099 ? '+' : ''} ${currency} ${formatUSD(
              management
            )}`}
            isHighlighted={+management > 0.099}
          />
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.creatorMetrics.vaults'
            )}
            value={`${
              vaults.filter((vault) => !vault.isMetavault).length ?? 0
            }`}
          />
        </Box>
      </SectionColumn>
    </Box>
  )
}

export default CreatorMetrics
