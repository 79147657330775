import { Button, ButtonProps } from '@mui/material'

interface CustomButtonProps extends ButtonProps {
  color?: 'primary' | 'secondary'
  children: React.ReactNode
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  color = 'primary',
  ...rest
}) => {
  return (
    <Button color={color} {...rest}>
      {children}
    </Button>
  )
}

export default CustomButton
