import CheckIcon from '@mui/icons-material/Check'
import { Button } from '@mui/material'

interface ValidityTagProps {
  label?: string
}

const ValidityTag: React.FC<ValidityTagProps> = ({ label }) => (
  <Button
    startIcon={<CheckIcon />}
    variant='outlined'
    size='small'
    color='success'
    sx={{ height: 'fit-content', width: 'fit-content', pointerEvents: 'none' }}
    disableRipple
  >
    {label}
  </Button>
)

export default ValidityTag
