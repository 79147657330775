import {
  IconButton,
  MenuItem,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'

import SpoolSelect from '@/components/atoms/SpoolSelect'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { gaIds } from '@/constants/googleAnalyticIDs'

import { AssetGroup } from '@/types/smart-vault'

interface StrategiesFiltersProps {
  assetGroups: AssetGroup[]
}

const StyledIconButton = styled(IconButton)(() => ({
  padding: '2px',
  height: 20,
  width: 20,
  marginRight: '4px',
}))

const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  justifyItems: 'start',
  alignItems: 'center',
  height: '44px',
}))

const StrategiesFilters: React.FC<StrategiesFiltersProps> = ({
  assetGroups,
}) => {
  const { chain } = useConnectionState()

  //const t = useLocale()
  const {
    filterByAssetGroup,
    filterBySearchTerm,
    //selectedMarket,
    //setSelectedMarket,
    setFilterByAssetGroup,
    setFilterBySearchTerm,
  } = useCreateVaultState()

  /*const handleChangeMarket = (event: SelectChangeEvent<unknown>) => {
    setSelectedMarket(event.target.value as string)
  }*/

  const handleChangeAssetGroup = (event: SelectChangeEvent<unknown>) => {
    setFilterByAssetGroup(event.target.value as string)
  }

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilterBySearchTerm(event.target.value as string)
  }

  return (
    <Box display='flex' gap={(theme) => theme.spacing(2)}>
      {/*<SpoolSelect
        label={t(
          'components.organisms.createVault.strategies.filters.market.label'
        )}
        value={selectedMarket ?? SupportedNetworks.ETHEREUM}
        handleChange={handleChangeMarket}
        sx={{ width: '550px' }}
      >
        {markets.map(({ id, label }) => (
          <StyledMenuItem key={id} value={id}>
            {id !== 'all' && (
              <StyledIconButton disableRipple>{mapIcons(id)}</StyledIconButton>
            )}
            <Typography variant='body2'>{label}</Typography>
          </StyledMenuItem>
        ))}
      </SpoolSelect>*/}
      <SpoolSelect
        label='Asset group'
        value={filterByAssetGroup}
        handleChange={handleChangeAssetGroup}
      >
        {assetGroups?.map(({ id, assets }) => {
          return (
            <StyledMenuItem
              id={
                gaIds.filter.main +
                '-' +
                gaIds.filter.page.createVaultStrategies +
                '-' +
                gaIds.filter.assetGroup
              }
              key={id}
              value={id.toString()}
            >
              <Box display='flex'>
                {assets.map(({ id, label, address }) => {
                  return (
                    <Box key={label} display='flex' marginRight='6px'>
                      {label !== 'All Assets' && (
                        <StyledIconButton disableRipple>
                          {mapAssetTokenIcon(chain, address)}
                        </StyledIconButton>
                      )}
                      <Typography variant='body2'>{id}</Typography>
                    </Box>
                  )
                })}
              </Box>
            </StyledMenuItem>
          )
        })}
      </SpoolSelect>
      <TextField
        id={
          gaIds.filter.main +
          '-' +
          gaIds.filter.page.createVaultStrategies +
          '-' +
          gaIds.filter.search
        }
        variant='outlined'
        fullWidth
        sx={{ backgroundColor: 'white' }}
        label='Search'
        size='small'
        onChange={handleSearchTermChange}
        value={filterBySearchTerm}
      />
    </Box>
  )
}

export default StrategiesFilters
