import { parseUnits } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'

import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useModalState from '@/hooks/context/useModalState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useDepositReceipts from '@/hooks/vaultList/Transactions/useDepositReceipts'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import { MultiAssetDeposit } from '@/store/deposit/deposit.types'
import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { DEFAULT_DECIMAL } from '@/constants'
import { WRAPPED_TOKEN_ADDRESS } from '@/constants/tokens'
import { getIsNativeCurrency, sleep } from '@/utils/web3'

const useMultiAssetSwapAndDeposit = (multiAssetDeposit: MultiAssetDeposit) => {
  const { chain } = useConnectionState()

  const { openModal, setModalType } = useModalState()

  const { sdk } = useAppState()

  const { smartVaultData } = useVaultsPage()

  const { multiAssetSwap, setMultiAssetSwap } = useDepositState()
  const { amount, tokenAddress } = multiAssetSwap

  const { decimals } = useTokenDetails(tokenAddress)

  const { address } = smartVaultData

  const { depositReceipts, updateDepositReceipts } = useDepositReceipts(address)

  // ARB
  const { account } = useWeb3React()

  const deposit = async () => {
    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.Deposit,
        },
      })

      const inTokens = [
        getIsNativeCurrency(tokenAddress)
          ? WRAPPED_TOKEN_ADDRESS[chain]
          : tokenAddress,
      ]
      const inAmounts = [parseUnits(amount, decimals)]
      const inDecimals = [decimals]
      const outTokens = Object.keys(multiAssetDeposit).map((key) => key)
      const outRatio = Object.values(multiAssetDeposit).map(({ ratio }) =>
        parseUnits(ratio)
      )
      const outRatioDecimals = Object.values(multiAssetDeposit).map(() =>
        BigNumber.from(DEFAULT_DECIMAL)
      )
      const userAddress = account
      const smartVault = address
      const ethValue = getIsNativeCurrency(tokenAddress)
        ? parseUnits(amount, decimals).toString()
        : undefined

      const tx = await sdk.smartVault.swapAndDeposit(
        inTokens,
        inAmounts,
        inDecimals,
        outTokens,
        outRatio,
        outRatioDecimals,
        userAddress,
        smartVault,
        ethValue
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.Deposit,
        },
      })

      await tx.wait()

      // delay so that subgraph gets updated
      await sleep(5000)

      await updateDepositReceipts(depositReceipts)

      setMultiAssetSwap({ ...multiAssetSwap, amount: '' })

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.Deposit,
          txHash: tx.hash,
        },
      })
    } catch (error) {
      //setError(error);
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.Deposit,
        },
      })
      console.error(error)
    }
  }

  return deposit
}

export default useMultiAssetSwapAndDeposit
