import { Box, Checkbox, TableBody, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import TableWrapper from '@/components/molecules/TableWrapper'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { Requirement } from '@/store/createVault/createVault.types'

import { assetAmountHeadCells } from '@/constants/createVault'
import { tokenSymbols } from '@/constants/tokens'
import { formatNumber } from '@/utils/formats'

interface AssetsTableProps {
  id: string
  selected: string[]
  setSelected: (selected: string[]) => void
  editMode: boolean
  editConfigurationMode: boolean
}

const AssetsTable: React.FC<AssetsTableProps> = ({
  id,
  selected,
  setSelected,
  editMode,
  editConfigurationMode,
}) => {
  const { selectedGuards } = useCreateVaultState()
  const { chain } = useConnectionState()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const t = useLocale()

  const handleSelected = (tokenId: string) => {
    if (selected.includes(tokenId)) {
      setSelected(selected.filter((tokenRow) => tokenRow !== tokenId))
    } else {
      setSelected([...selected, tokenId])
    }
  }

  const onSelectAllCheckboxChange = () => {
    if (!selected.length) {
      setSelected(selectedGuards[id]?.assetAmount.map((item) => item.token))
    } else {
      setSelected([])
    }
  }

  return (
    <Box width='100%' border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='4px'>
      <TableWrapper
        header={assetAmountHeadCells}
        headerClass='default-table-head'
        pagination={{
          rowsPerPageOptions: [5, 10, 25],
          page: page,
          rowsPerPage: rowsPerPage,
          count: selectedGuards[id]?.assetAmount.length,
          setPage: setPage,
          setRowsPerPage: setRowsPerPage,
        }}
        onCheckedChange={
          editConfigurationMode && !editMode
            ? onSelectAllCheckboxChange
            : undefined
        }
        withCheckbox={editConfigurationMode}
      >
        <TableBody>
          {selectedGuards[id]?.assetAmount
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map(({ token, requirement, value }, index) => {
              return (
                <TableRow
                  key={token}
                  sx={{
                    backgroundColor: selected.includes(token)
                      ? 'highlight.background'
                      : '#fff',
                    outline: selected.includes(token)
                      ? (theme) => `1px solid ${theme.palette.highlight.border}`
                      : undefined,
                    outlineOffset: selected.includes(token)
                      ? '-1px'
                      : undefined,
                    opacity: editMode && !selected.includes(token) ? 0.35 : 1,
                  }}
                  onClick={() => !editMode && handleSelected(token)}
                >
                  {editConfigurationMode && (
                    <TableCell
                      padding='none'
                      sx={{ minWidth: '0px', width: '40px' }}
                    >
                      <Checkbox
                        color='primary'
                        checked={selected.includes(token)}
                        sx={{ paddingRight: '2px' }}
                        disableRipple
                      />
                    </TableCell>
                  )}
                  <TableCell width='48px'>{index + 1}</TableCell>
                  <TableCell>
                    <NameCellContent>
                      {mapAssetTokenIcon(chain, token)}
                      <SmartVaultNameTableTypography variant='body2'>
                        {tokenSymbols[token]}
                      </SmartVaultNameTableTypography>
                    </NameCellContent>
                  </TableCell>
                  <TableCell>
                    {requirement === Requirement.MINIMUM
                      ? t(
                          'components.organisms.createVault.guards.modals.assetsModal.assetsTable.min'
                        )
                      : t(
                          'components.organisms.createVault.guards.modals.assetsModal.assetsTable.max'
                        )}
                  </TableCell>
                  <TableCell align='right'>{formatNumber(value, 2)}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </TableWrapper>
    </Box>
  )
}

export default AssetsTable
