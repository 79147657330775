import { Box, Card, CardContent, SxProps, Theme } from '@mui/material'
import { PropsWithChildren } from 'react'

interface SectionProps extends PropsWithChildren {
  contentBoxClassName?: string
  sx?: SxProps<Theme>
}

const Section: React.FC<SectionProps> = ({
  children,
  contentBoxClassName,
  sx,
}) => (
  <Card variant='outlined' className='section-card' sx={sx}>
    <CardContent className='section-card-content'>
      <Box
        className={
          contentBoxClassName ? contentBoxClassName : 'section-content'
        }
      >
        {children}
      </Box>
    </CardContent>
  </Card>
)

export default Section
