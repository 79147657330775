import { Box, Typography, TypographyTypeMap } from '@mui/material'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'

interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number
  textVariant?: TypographyTypeMap['props']['variant']
  textColor?: TypographyTypeMap['props']['color']
  noMarginRight?: boolean
}

const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = ({
  value,
  textVariant,
  textColor,
  noMarginRight,
  ...rest
}) => {
  return (
    <Box display='flex' alignItems='center'>
      <Typography
        variant={textVariant ? textVariant : 'body2'}
        color={textColor ? textColor : 'text.primary'}
        sx={{ marginRight: '4px', whiteSpace: 'nowrap' }}
      >
        {`${value.toFixed(1)} %`}
      </Typography>
      <Box width='100%' mr={noMarginRight ? 0 : 1}>
        <LinearProgress variant='determinate' value={value} {...rest} />
      </Box>
    </Box>
  )
}
export default LinearProgressWithLabel
