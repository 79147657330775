import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { parseEther, parseUnits } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

import useModalState from '@/hooks/context/useModalState'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import erc20ABI from '@/abis/erc20ABI'
import { MUTATE_SLEEP_DURATION } from '@/constants'
import { countDecimals } from '@/utils/helpers/countDecimals'
import { fetcher, sleep } from '@/utils/web3'
import { getTokenApprovalArray } from '@/utils/web3/approve'

import useTokenDetails from './useTokenDetails'

const useApproveToken = (
  tokenAddress: string | undefined,
  spender: string | undefined,
  amount: string
) => {
  const { account, provider } = useWeb3React()

  const { openModal, setModalType } = useModalState()

  const { decimals } = useTokenDetails(tokenAddress)

  const [isTokenApproved, setIsApproved] = useState(false)

  const { data: allowance, mutate } = useSWR<BigNumber>(
    provider && account && tokenAddress && spender
      ? [tokenAddress, 'allowance', account, spender]
      : null,
    {
      fetcher: fetcher(provider, erc20ABI),
      fallbackData: parseEther('0'),
      dedupingInterval: undefined,
      refreshInterval: undefined,
    }
  )

  useEffect(() => {
    if (!allowance) return

    let checkedAmount = amount

    if (countDecimals(amount) > decimals) {
      checkedAmount = parseFloat(amount).toFixed(decimals)
    }

    if (allowance.gte(parseUnits(checkedAmount || '0', decimals))) {
      setIsApproved(true)
      return
    }

    setIsApproved(false)
  }, [allowance, amount, decimals])

  const approve = async (approveAmount: string) => {
    try {
      if (!tokenAddress)
        throw new Error('useApproveToken: tokenAddress not specified')
      if (!spender) throw new Error('useApproveToken: spender not specified')

      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.Approve,
        },
      })

      await Promise.all(
        getTokenApprovalArray(
          tokenAddress,
          decimals,
          spender,
          approveAmount,
          provider,
          allowance
        )
      ).then((txs) =>
        Promise.all(
          txs.map(async (tx) => {
            setModalType({
              actionModalType: {
                transactionStatus: TransactionStatus.Processing,
                transactionType: TransactionType.Approve,
              },
            })
            await tx.wait()
          })
        )
      )

      sleep(MUTATE_SLEEP_DURATION)

      await mutate()

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.Approve,
        },
      })
    } catch (error) {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.Approve,
        },
      })
    }
  }

  return { isTokenApproved, allowance, approve, mutate }
}

export default useApproveToken
