import { TableBody } from '@mui/material'
import Box from '@mui/material/Box'
import { SortOrder, StrategyAllocationDetails } from '@solidant/spool-v2-fe-lib'
import { Fragment, useEffect, useState } from 'react'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import TableWrapper from '@/components/molecules/TableWrapper'
import StrategiesMobileRow from '@/components/organisms/SmartVaults/CollapsibleRow/Strategies/StrategiesMobileRow'
import StrategiesTableRow from '@/components/organisms/SmartVaults/CollapsibleRow/Strategies/StrategiesTableRow'

import { strategiesHeadCells } from '@/constants/smartVault'
import { sort } from '@/utils/table'

import { StrategiesDto } from '@/types/create-vault'
import { SortType } from '@/types/table'

interface StrategiesTableProps {
  strategies?: StrategyAllocationDetails[]
  totalAllocation: number
}

const StrategiesTable: React.FC<StrategiesTableProps> = ({
  strategies,
  totalAllocation,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const [sortType, setSortType] = useState<SortType<StrategiesDto>>()
  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleSortClick = (
    key: keyof StrategiesDto,
    sortType: SortType<StrategiesDto>
  ) => {
    let direction = SortOrder.DESC
    if (!sortType?.direction || sortType?.key === key) {
      direction =
        sortType?.direction === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
    }
    setSortType({ key, direction })
  }

  useEffect(() => handleSortClick('allocation', sortType), [])

  if (!strategies) {
    return <DataLoading loadingText='Loading Strategies' />
  }

  if (strategies.length === 0) {
    return <DataMissing text='No Strategies' />
  }

  return !belowPhabletBreakpoint ? (
    <Box width='100%' border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='4px'>
      <TableWrapper
        header={strategiesHeadCells}
        headerClass='default-table-head'
        // pagination={{
        //   rowsPerPageOptions: [5, 10, 25],
        //   page: page,
        //   rowsPerPage: rowsPerPage,
        //   count: strategies.length,
        //   setPage: setPage,
        //   setRowsPerPage: setRowsPerPage,
        // }}
        handleSortClick={handleSortClick}
        sortType={sortType}
      >
        <TableBody>
          {strategies
            .sort((a, b) => sort(a, b, sortType))
            // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((strategy: StrategyAllocationDetails) => (
              <Fragment key={strategy.address}>
                <StrategiesTableRow
                  strategy={strategy}
                  totalAllocation={totalAllocation}
                />
              </Fragment>
            ))}
        </TableBody>
      </TableWrapper>
    </Box>
  ) : (
    <Box border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='4px'>
      {strategies
        .sort((a, b) => sort(a, b, sortType))
        // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((strategy: StrategyAllocationDetails, index) => (
          <Fragment key={strategy.address}>
            <StrategiesMobileRow
              strategy={strategy}
              totalAllocation={totalAllocation}
              lastItem={index === strategies?.length - 1}
            />
          </Fragment>
        ))}
    </Box>
  )
}

export default StrategiesTable
