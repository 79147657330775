const WETH = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='18'
      height='18'
      viewBox='0 0 64 64'
      version='1.1'
    >
      <g id='weth' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <path
            d='M17.9418294,63.4754098 C34.028366,63.4754098 34.028366,63.2359045 36.7559485,62.4262295 C40.3204714,61.3681111 26.6919355,51.9344262 17.9418294,51.9344262 C9.19172322,51.9344262 2.09836066,54.5179634 2.09836066,57.704918 C2.09836066,60.8918727 9.19207533,63.4754098 17.9418294,63.4754098 Z'
            id='Oval'
            fill='#000000'
            fillRule='nonzero'
          />
          <path
            d='M54.4695265,14.4045497 L55.2094533,15.201912 L54.5626151,16.0764854 C33.5828112,44.442745 21.4971019,57.2862594 16.3692574,55.5636688 C13.2458812,54.5144368 10.1135781,51.3589554 6.9812012,46.7769489 C5.88018295,45.1663916 4.84628784,43.4704467 3.90596281,41.782752 C3.27979002,40.6588976 2.7497946,39.6338951 2.4746083,39.0565716 C2.1235755,38.3201268 1.9753707,37.8664378 1.85779077,37.270622 C1.8506164,37.2341076 1.8506164,37.2341076 1.84379952,37.1990163 C1.79472341,36.9460725 1.77774483,36.8661371 1.74106076,36.7278774 C1.10976885,34.3485843 0.786885246,31.8835507 0.786885246,29.3770492 C0.786885246,13.5871376 13.5871376,0.786885246 29.3770492,0.786885246 C34.3238335,0.786885246 39.0908721,2.04585501 43.3155836,4.4091623 L43.4246568,4.47740987 C46.8836145,6.88557505 50.5628018,10.194573 54.4695265,14.4045497 Z M41.9790397,6.66704826 C38.1570602,4.54139658 33.8506929,3.40983607 29.3770492,3.40983607 C15.0357534,3.40983607 3.40983607,15.0357534 3.40983607,29.3770492 C3.40983607,31.6559737 3.70310496,33.8949157 4.27629076,36.0552112 C4.33297049,36.268833 4.35860349,36.3895136 4.41873305,36.6994281 C4.42504356,36.7319102 4.42504356,36.7319102 4.43111224,36.7627957 C4.50699765,37.1473317 4.58705811,37.3924153 4.84233651,37.9279733 C5.09337484,38.4546359 5.59786134,39.4303045 6.19726672,40.5061168 C7.10103806,42.128205 8.09420606,43.7573433 9.14653165,45.2966736 C11.9765832,49.4364418 14.7832688,52.2638963 17.2045131,53.0772619 C20.4337999,54.1620724 32.5907528,41.2930687 51.8030153,15.3953329 C48.3150112,11.7136557 45.0383975,8.80419237 41.9790397,6.66704826 Z'
            id='Oval'
            fill='#000000'
            fillRule='nonzero'
          />
          <path
            d='M41.9790397,6.66704826 C38.1570602,4.54139658 33.8506929,3.40983607 29.3770492,3.40983607 C15.0357534,3.40983607 3.40983607,15.0357534 3.40983607,29.3770492 C3.40983607,31.6559737 3.70310496,33.8949157 4.27629076,36.0552112 C4.33297049,36.268833 4.35860349,36.3895136 4.41873305,36.6994281 C4.42504356,36.7319102 4.42504356,36.7319102 4.43111224,36.7627957 C4.50699765,37.1473317 4.58705811,37.3924153 4.84233651,37.9279733 C5.09337484,38.4546359 5.59786134,39.4303045 6.19726672,40.5061168 C7.10103806,42.128205 8.09420606,43.7573433 9.14653165,45.2966736 C11.9765832,49.4364418 14.7832688,52.2638963 17.2045131,53.0772619 C20.4337999,54.1620724 32.5907528,41.2930687 51.8030153,15.3953329 C48.3150112,11.7136557 45.0383975,8.80419237 41.9790397,6.66704826 Z'
            id='Path'
            fill='#EC1C79'
            fillRule='nonzero'
          />
          <path
            d='M34.6229508,63.2131148 C18.8330393,63.2131148 6.03278689,50.4128624 6.03278689,34.6229508 C6.03278689,18.8330393 18.8330393,6.03278689 34.6229508,6.03278689 C50.4128624,6.03278689 63.2131148,18.8330393 63.2131148,34.6229508 C63.2131148,50.4128624 50.4128624,63.2131148 34.6229508,63.2131148 Z M34.6229508,60.5901639 C48.9642466,60.5901639 60.5901639,48.9642466 60.5901639,34.6229508 C60.5901639,20.281655 48.9642466,8.6557377 34.6229508,8.6557377 C20.281655,8.6557377 8.6557377,20.281655 8.6557377,34.6229508 C8.6557377,48.9642466 20.281655,60.5901639 34.6229508,60.5901639 Z'
            id='Oval'
            fill='#000000'
            fillRule='nonzero'
          />
          <path
            d='M34.6229508,60.5901639 C48.9642466,60.5901639 60.5901639,48.9642466 60.5901639,34.6229508 C60.5901639,20.281655 48.9642466,8.6557377 34.6229508,8.6557377 C20.281655,8.6557377 8.6557377,20.281655 8.6557377,34.6229508 C8.6557377,48.9642466 20.281655,60.5901639 34.6229508,60.5901639 Z'
            id='Path'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
          <path
            d='M15.1984262,39.9422951 L11.7613115,29.5868852 L14.772459,29.5868852 L16.564459,35.5357377 L18.5474098,29.5575082 L21.0003934,29.5575082 L22.9833443,35.5357377 L24.7753443,29.5868852 L27.7277377,29.5868852 L24.290623,39.9422951 L21.8082623,39.9422951 L19.7371803,34.0228197 L17.6807869,39.9422951 L15.1984262,39.9422951 Z M28.8587541,39.8688525 L28.8587541,29.5868852 L37.1283934,29.5868852 L37.1283934,32.0104918 L31.6789508,32.0104918 L31.6789508,33.5674754 L36.6142951,33.5674754 L36.6142951,35.8148197 L31.6789508,35.8148197 L31.6789508,37.4452459 L37.2018361,37.4452459 L37.2018361,39.8688525 L28.8587541,39.8688525 Z M41.1677377,39.8688525 L41.1677377,32.0839344 L38.0831475,32.0839344 L38.0831475,29.5868852 L47.1019016,29.5868852 L47.1019016,32.0839344 L44.0173115,32.0839344 L44.0173115,39.8688525 L41.1677377,39.8688525 Z M48.3504262,39.8688525 L48.3504262,29.5868852 L51.2,29.5868852 L51.2,33.4352787 L54.8574426,33.4352787 L54.8574426,29.5868852 L57.7070164,29.5868852 L57.7070164,39.8688525 L54.8574426,39.8688525 L54.8574426,35.9617049 L51.2,35.9617049 L51.2,39.8688525 L48.3504262,39.8688525 Z'
            id='WETH'
            fill='#000000'
          />
          <path
            d='M2.49065019,26.4941094 C1.76506866,26.1313187 1.47096864,25.2490186 1.8337594,24.5234371 C2.19655017,23.7978555 3.07885024,23.5037555 3.80443177,23.8665463 L8.00115308,25.9649069 C8.72673462,26.3276977 9.02083464,27.2099978 8.65804387,27.9355793 C8.29525311,28.6611608 7.41295304,28.9552609 6.68737151,28.5924701 L2.49065019,26.4941094 Z'
            id='Line'
            fill='#000000'
            fillRule='nonzero'
          />
          <path
            d='M2.0074456,33.4527176 C1.27087892,33.1127834 0.949344723,32.2401071 1.28927887,31.5035404 C1.62921302,30.7669737 2.50188937,30.4454395 3.23845604,30.7853737 L7.43517736,32.7222097 C8.17174403,33.0621438 8.49327823,33.9348202 8.15334408,34.6713869 C7.81340993,35.4079535 6.94073359,35.7294877 6.20416691,35.3895536 L2.0074456,33.4527176 Z'
            id='Line'
            fill='#000000'
            fillRule='nonzero'
          />
        </g>
      </g>
    </svg>
  )
}

export default WETH
