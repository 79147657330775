import { Box, styled } from '@mui/material'

const SliderBox = styled(Box)(() => ({
  height: 'inherit',
  marginRight: '1px',
  borderRadius: '3px',
  background:
    'linear-gradient(90deg, #23E594 0%, #23B3E5 51.49%, #F35064 100%)',
  opacity: 0.3,
}))

export default SliderBox
