import { InvestorSmartVault } from '@solidant/spool-v2-fe-lib'
import { useContext } from 'react'

import useModalState from '@/hooks/context/useModalState'

import { AppContext } from '@/store/app/app.context'
import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

const useClaimSvts = () => {
  const { sdk } = useContext(AppContext)
  const { openModal, setModalType } = useModalState()

  const claimSvtsOnClick = async (
    row: InvestorSmartVault,
    optimisticallyBurnDNfts: (
      vaultAddress: string,
      dNftIds: string[]
    ) => Promise<void>
  ) => {
    const { dNfts, address } = row

    const filteredDNfts = dNfts.reduce(
      (acc, dNft) => {
        if (!dNft.isBurned && !dNft.isPendingDHW) {
          acc.ids.push(dNft.nftId)
          acc.shares.push(dNft.shares)
        }
        return acc
      },
      { ids: [], shares: [] }
    )
    try {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.ClaimSvts,
        },
      })
      openModal(Modals.ACTION_MODAL)

      const tx = await sdk.smartVault.claimSvts(address, filteredDNfts)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.ClaimSvts,
        },
      })
      await tx.wait()
      await optimisticallyBurnDNfts(address, filteredDNfts.ids)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.ClaimSvts,
        },
      })
    } catch (error) {
      console.error(error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.ClaimSvts,
        },
      })
    }
  }

  return { claimSvtsOnClick }
}

export default useClaimSvts
