import { Typography } from '@mui/material'
import { GuardConditions, GuardType } from '@solidant/spool-v2-fe-lib'

import {
  ACTION_CONDITION_MAP,
  GUARDTYPE_CONDITION_MAP,
  TARGET_CONDITION_MAP,
} from '@/constants/guards'

interface ConditionConstructorTableRowProps {
  guardConditions: GuardConditions
}

const ConditionConstructor: React.FC<ConditionConstructorTableRowProps> = ({
  guardConditions,
}) => {
  const { guardType } = guardConditions

  let condition = ''

  if (
    guardType === GuardType.NftGuard ||
    guardType === GuardType.WhitelistGuard
  ) {
    condition =
      ACTION_CONDITION_MAP[guardConditions.action] +
      TARGET_CONDITION_MAP[guardConditions.target] +
      GUARDTYPE_CONDITION_MAP[guardType]
  }

  return (
    <>
      <Typography variant='body2'>{condition}</Typography>
      <Typography variant='body2'>
        {guardType === GuardType.NftGuard ? guardConditions.nftAddress : ''}
      </Typography>
    </>
  )
}

export default ConditionConstructor
