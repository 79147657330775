import { useContext } from 'react'

import { ConnectionContext } from '@/store/connection/connection.context'

const useConnectionState = () => {
  const connectionContext = useContext(ConnectionContext)
  if (!Object.keys(ConnectionContext).length) {
    throw new Error('ModalContext must be used within its provider')
  }
  return connectionContext
}

export default useConnectionState
