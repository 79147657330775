import { parseEther } from '@ethersproject/units'
import { Box, SelectChangeEvent, Skeleton, Stack } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useLocale from '@/hooks/context/useLocale'

import { tokenDetails } from '@/components/atoms/Tokens'
import AssetsSelect from '@/components/molecules/AssetsSelect'
import TransactionInput from '@/components/molecules/TransactionInput'

import { MultiAssetDeposit } from '@/store/deposit/deposit.types'

import { DEFAULT_INPUT_AMOUNT } from '@/constants'
import { preventOverflow } from '@/utils/web3'
import { getRatioToAssetAmount } from '@/utils/web3/multiDeposit'

interface MultiAssetDepositSwapAmountProps {
  multiAssetDeposit: MultiAssetDeposit
  loading: boolean
  setMultiAssetDeposit: React.Dispatch<React.SetStateAction<MultiAssetDeposit>>
}

const MultiAssetDepositSwapAmount: React.FC<
  MultiAssetDepositSwapAmountProps
> = ({ multiAssetDeposit, loading, setMultiAssetDeposit }) => {
  const { chain } = useConnectionState()

  const t = useLocale()

  const { multiAssetSwap, setMultiAssetSwap } = useDepositState()
  const { amount, tokenAddress } = multiAssetSwap

  const handleDepositInput = (depositAmount: string) => {
    setMultiAssetSwap({
      ...multiAssetSwap,
      amount: preventOverflow(
        depositAmount,
        tokenDetails[chain][tokenAddress].decimals
      ),
    })

    const newMultiDepositAmountValues = Object.keys(multiAssetDeposit).reduce(
      (acc, curr) => {
        try {
          const parsedAmount = parseEther(
            depositAmount !== '' ? depositAmount : '0'
          )
          if (!depositAmount || parsedAmount.isZero()) {
            return {
              ...acc,
              [curr]: {
                ...acc[curr],
                amount: DEFAULT_INPUT_AMOUNT,
              },
            }
          }
        } catch (error) {
          console.error('Error parsing ether:', error)
          return acc
        }

        const ratioToAmount = getRatioToAssetAmount(
          depositAmount,
          acc[curr].ratio,
          acc[curr].priceFeed,
          acc[curr].decimals
        )

        // fix decimal values to 6, reasoning is that USDC/USDT has a decimal value of 6
        return {
          ...acc,
          [curr]: {
            ...acc[curr],
            amount: ratioToAmount,
          },
        }
      },
      multiAssetDeposit
    )

    setMultiAssetDeposit(newMultiDepositAmountValues)
  }

  const handleChangeAsset = (event: SelectChangeEvent<unknown>) => {
    const selectedAssetAddress = event.target.value as string

    setMultiAssetSwap({
      amount: preventOverflow(
        amount || '0',
        tokenDetails[chain][selectedAssetAddress].decimals
      ),
      tokenAddress: selectedAssetAddress,
    })
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='flex-start'
      gap={(theme) => theme.spacing(2)}
    >
      <Stack width='100%' gap={(theme) => theme.spacing(2)}>
        {loading ? (
          <Skeleton variant='rounded' animation='wave' height={40} />
        ) : (
          <AssetsSelect
            selectedTokenAddress={tokenAddress}
            handleChangeSelectedToken={handleChangeAsset}
          />
        )}

        {loading ? (
          <Stack gap='3px'>
            <Skeleton variant='rounded' animation='wave' height={40} />
            <Skeleton variant='rounded' animation='wave' height={20} />
          </Stack>
        ) : (
          <TransactionInput
            inputLabel={t(
              'components.organisms.smartVaults.collapsibleRow.deposit.standard.multiDepositAmount.depositAmount.title'
            )}
            value={amount}
            setValue={handleDepositInput}
            showAlert
            showInputInfoLabel
            tokenId={tokenAddress}
          />
        )}
      </Stack>
    </Box>
  )
}

export default MultiAssetDepositSwapAmount
