import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import SectionColumn from '@/components/molecules/SectionColumn'
import PerformanceFeeConfiguration from '@/components/organisms/CreateVault/Launch/PerformanceFee/PerformanceFeeConfiguration'

const PerformanceFee: React.FC = () => {
  const t = useLocale()
  return (
    <SectionColumn
      title={t('components.organisms.createVault.launch.performanceFee.title')}
      sx={{ paddingBottom: 0, gap: 0 }}
    >
      <Typography variant='subtitle1'>
        {t(
          'components.organisms.createVault.launch.performanceFee.instruction'
        )}
        <br />
        {t(
          'components.organisms.createVault.launch.performanceFee.description'
        )}
      </Typography>
      <PerformanceFeeConfiguration />
    </SectionColumn>
  )
}

export default PerformanceFee
