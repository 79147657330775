import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import CustomButton from '@/components/atoms/CustomButton'
import ValidNotification from '@/components/molecules/informational/ValidNotification'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import DataButtons from '@/components/organisms/CreateVault/Guards/Modals/DataButtons'
import DeleteModal from '@/components/organisms/CreateVault/Guards/Modals/DeleteModal'
import AddressInput from '@/components/organisms/CreateVault/Guards/Modals/WhitelistModal/AddressInput'
import WhitelistTable from '@/components/organisms/CreateVault/Guards/Modals/WhitelistModal/WhitelistTable'

import { Guards, Role } from '@/store/createVault/createVault.types'

import {
  nftHeadCells,
  svtHeadCells,
  whitelistHeadCells,
} from '@/constants/createVault'

interface WhitelistModalProps {
  id: string
  role: Role
  setInitialConditions: React.Dispatch<React.SetStateAction<Guards>>
  nft?: boolean
  isAddressInput?: boolean
}

const WhitelistModal: React.FC<WhitelistModalProps> = ({
  id,
  role,
  nft,
  isAddressInput = true,
  setInitialConditions,
}) => {
  const { selectedGuards, setGuardsWhitelist } = useCreateVaultState()
  const { closeModal } = useModalState()
  const [conditionExists, setConditionExists] = useState<boolean>(false)
  const [address, setAddress] = useState('')
  const [nftAmount, setNftAmount] = useState('')
  const [selected, setSelected] = useState<string[]>([])
  const [editMode, setEditMode] = useState(false)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)

  useEffect(() => {
    if (selectedGuards) {
      if (
        selectedGuards[id]?.whitelist &&
        selectedGuards[id]?.whitelist.length
      ) {
        setConditionExists(true)
      }
      setInitialConditions(selectedGuards)
      return
    }
    setInitialConditions({})

    return () => {
      setInitialConditions({})
    }
  }, [])

  const t = useLocale()

  const handleDelete = () => {
    const filteredWhitelist = selectedGuards[id]?.whitelist.filter(
      (address) => !selected.includes(address.address)
    )

    if (id === 'nftDepositors') {
      setGuardsWhitelist(id, filteredWhitelist, !filteredWhitelist.length, true)
    } else {
      setGuardsWhitelist(id, filteredWhitelist, !filteredWhitelist.length)
    }

    setSelected([])
    setDeleteModalOpened(false)
  }

  const handleCancel = () => {
    setEditMode(false)
    setAddress('')
    setNftAmount('')
  }

  const handleSelected = (address: string) => {
    if (selected.includes(address)) {
      setSelected((prevSelected) => prevSelected.filter((i) => i !== address))
    } else {
      setSelected([...selected, address])
    }
  }

  const onSelectAllCheckboxChange = () => {
    if (!selected.length) {
      setSelected(selectedGuards[id]?.whitelist.map((item) => item.address))
    } else {
      setSelected([])
    }
  }

  const deleteModalLabel = () => {
    return `${t(
      'components.organisms.createVault.guards.modals.whiteListModal.deleteModal.selected.labelStart'
    )}${selected.join(',')}${t(
      'components.organisms.createVault.guards.modals.whiteListModal.deleteModal.selected.labelEnd'
    )}`
  }

  return (
    <>
      <Section>
        <SectionColumn sx={{ gap: 0 }}>
          {selectedGuards[id]?.configured ? (
            <ValidNotification />
          ) : (
            <AddressInput
              id={id}
              isAddressInput={isAddressInput}
              inputLabel={
                nft
                  ? t(
                      'components.organisms.createVault.guards.modals.whiteListModal.addressInput.inputLabel.uniqueID'
                    )
                  : t(
                      'components.organisms.createVault.guards.modals.whiteListModal.addressInput.inputLabel.walletAddress'
                    )
              }
              role={role}
              address={address}
              setAddress={setAddress}
              nftAmount={nftAmount}
              setNftAmount={setNftAmount}
              selected={selected}
              setSelected={setSelected}
              editMode={editMode}
              setEditMode={setEditMode}
              nft
            />
          )}
          {selectedGuards[id]?.whitelist &&
            selectedGuards[id]?.whitelist?.length > 0 && (
              <WhitelistTable
                headCells={
                  nft
                    ? nftHeadCells
                    : role === Role.NO_ROLE
                    ? svtHeadCells
                    : whitelistHeadCells
                }
                whitelist={selectedGuards[id]?.whitelist || []}
                selected={selected}
                handleSelected={handleSelected}
                onSelectAllCheckboxChange={onSelectAllCheckboxChange}
                editMode={editMode}
                editConfigurationMode={!selectedGuards[id]?.configured}
                nft={nft}
              />
            )}
          <Box display='flex' gap={1}>
            {selectedGuards[id]?.whitelist &&
            selectedGuards[id]?.whitelist?.length > 0 ? (
              <DataButtons
                selected={selected}
                editMode={editMode}
                handleSave={() => closeModal(id)}
                openDeleteModal={() => setDeleteModalOpened(true)}
                handleCancel={handleCancel}
              />
            ) : (
              conditionExists && (
                <CustomButton
                  color='primary'
                  size='small'
                  variant='contained'
                  onClick={() => closeModal(id)}
                >
                  {t(
                    'components.organisms.createVault.guards.modals.dataButtons.save.label'
                  )}
                </CustomButton>
              )
            )}
            {/* <ImportExportCsv<Whitelist>
              csvImportFormat={csvImportFormat}
              list={whitelist}
              setList={setWhitelist}
            /> */}
          </Box>
        </SectionColumn>
      </Section>
      {/* <BottomButtons
        handleSave={handleSave}
        handleTest={handleTest}
        closeModal={() => closeModal(id)}
        configured={selectedGuards[id]?.configured}
        handleEditConfiguration={() => setGuardsConfigured(id, false)}
        disabled={!whitelist.length}
      /> */}
      <DeleteModal
        label={deleteModalLabel()}
        open={deleteModalOpened}
        handleClose={() => setDeleteModalOpened(false)}
        handleDelete={handleDelete}
      />
    </>
  )
}

export default WhitelistModal
