import { Box, Checkbox, TableBody, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'

import useLocale from '@/hooks/context/useLocale'

import TableWrapper from '@/components/molecules/TableWrapper'

import { Role, Whitelist } from '@/store/createVault/createVault.types'

import { HeadCell } from '@/types/table'

interface WhitelistTableProps {
  headCells: HeadCell[]
  whitelist: Whitelist[]
  selected: string[]
  handleSelected: (id: string) => void
  onSelectAllCheckboxChange: () => void
  editMode: boolean
  editConfigurationMode: boolean
  nft?: boolean
}

const WhitelistTable: React.FC<WhitelistTableProps> = ({
  headCells,
  whitelist,
  selected,
  handleSelected,
  onSelectAllCheckboxChange,
  editMode,
  editConfigurationMode,
  // nft,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const t = useLocale()

  return (
    <Box width='100%' border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='4px'>
      <TableWrapper
        header={headCells}
        headerClass='default-table-head'
        pagination={{
          rowsPerPageOptions: [5, 10, 25],
          page: page,
          rowsPerPage: rowsPerPage,
          count: whitelist.length,
          setPage: setPage,
          setRowsPerPage: setRowsPerPage,
        }}
        onCheckedChange={
          editConfigurationMode && !editMode
            ? onSelectAllCheckboxChange
            : undefined
        }
        withCheckbox={editConfigurationMode}
      >
        <TableBody>
          {whitelist
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map(({ address, role }, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: selected.includes(address)
                    ? 'highlight.background'
                    : '#fff',
                  outline: selected.includes(address)
                    ? (theme) => `1px solid ${theme.palette.highlight.border}`
                    : undefined,
                  outlineOffset: selected.includes(address)
                    ? '-1px'
                    : undefined,
                  opacity: editMode && !selected.includes(address) ? 0.35 : 1,
                }}
                onClick={() => !editMode && handleSelected(address)}
              >
                {editConfigurationMode && (
                  <TableCell
                    padding='none'
                    sx={{ minWidth: '0px', width: '40px' }}
                  >
                    <Checkbox
                      color='primary'
                      checked={selected.includes(address)}
                      sx={{ paddingRight: '2px' }}
                      disableRipple
                    />
                  </TableCell>
                )}
                <TableCell width='auto'>{index + 1}</TableCell>
                <TableCell>{address}</TableCell>
                {role !== Role.NO_ROLE && (
                  <TableCell>
                    {role === Role.DEPOSITOR
                      ? t(
                          'components.organisms.createVault.guards.modals.whiteListModal.whiteListTable.role.depositor.label'
                        )
                      : t(
                          'components.organisms.createVault.guards.modals.whiteListModal.whiteListTable.role.beneficiary.label'
                        )}
                  </TableCell>
                )}
                {/* {nft && <TableCell>{nftAmount}</TableCell>} */}
              </TableRow>
            ))}
        </TableBody>
      </TableWrapper>
    </Box>
  )
}

export default WhitelistTable
