import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, Button, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import TextTagRenderer from '@/components/atoms/TextTagRenderer'
import { UNFULFILLED_CONDITION } from '@/components/organisms/SmartVaults/VaultActions/VaultActions'

import { ellipsisText } from '@/utils/formats'

interface ConditionWarningProps {
  unfulfilledCondition: UNFULFILLED_CONDITION
}

const ConditionWarning: React.FC<ConditionWarningProps> = ({
  unfulfilledCondition,
}) => {
  const t = useLocale()

  const { smartVaultData } = useVaultsPage()

  const { guardConditions } = smartVaultData

  const { toggleMenu } = useTopMenuState()

  const depositConditionTitleBuilder = (): string => {
    if (unfulfilledCondition === UNFULFILLED_CONDITION.EXTERNAL_PARTNER)
      return 'External Partner'
    if (unfulfilledCondition === UNFULFILLED_CONDITION.STRATEGY_BLOCKED)
      return 'Issue with strategy'
    return 'Wrong Network'
  }

  const depositConditionTextBuilder = (): string => {
    if (unfulfilledCondition === UNFULFILLED_CONDITION.WRONG_NETWORK)
      return t('pages.smartVaults.vaultDetailsPage.wrongNetworkCondition')

    if (unfulfilledCondition === UNFULFILLED_CONDITION.EXTERNAL_PARTNER)
      return t('pages.smartVaults.vaultDetailsPage.externalPartnerCondition')

    if (unfulfilledCondition === UNFULFILLED_CONDITION.STRATEGY_BLOCKED)
      return t('pages.smartVaults.vaultDetailsPage.strategyBlocked')

    if (
      unfulfilledCondition ===
        UNFULFILLED_CONDITION.GUARD_CONDITIONS_UNFULFILLED &&
      guardConditions
    ) {
      return (
        t('pages.smartVaults.vaultDetailsPage.deposit.guardCondition') +
        guardConditions.conditions
          .map(({ nftAddress }) => ellipsisText(nftAddress, 4, 4))
          .join(', ') +
        t('pages.smartVaults.vaultDetailsPage.deposit.pleaseTryDifferentVault')
      )
    }
  }

  return (
    <>
      {unfulfilledCondition === UNFULFILLED_CONDITION.NOT_CONNECTED ? (
        <Box margin='1.5rem 1.5rem'>
          <Button
            variant='contained'
            size='small'
            fullWidth
            sx={{ justifySelf: 'center', height: '42px', whiteSpace: 'nowrap' }}
            onClick={() => toggleMenu(true)}
          >
            {t(
              'components.organisms.sideMenu.walletConnectionMenu.buttons.connect.label'
            )}
          </Button>
        </Box>
      ) : (
        <Box margin='1.5rem 1.5rem' borderRadius='0.2rem' bgcolor='#fdd8ba'>
          <Box padding='1rem' display='flex' gap='1rem'>
            <>
              <WarningAmberIcon sx={{ color: '#ED6C02' }} />
              <Box>
                <Typography color='#00000099' fontWeight={500}>
                  {depositConditionTitleBuilder()}
                </Typography>
                <Typography
                  marginTop='0.5rem'
                  color='#00000099'
                  fontSize='14px'
                >
                  <TextTagRenderer
                    inputString={depositConditionTextBuilder()}
                  />
                </Typography>
              </Box>{' '}
            </>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ConditionWarning
