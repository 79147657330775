import { Modal } from '@mui/material'

import useModalState from '@/hooks/context/useModalState'

import BasicModalBackdrop from '@/components/molecules/BasicModalBackdrop'

type BasicModalProps = {
  id: string
  backdropColor?: string
  disabledClose?: boolean
  hideBackdrop?: boolean
  children: React.ReactNode
}

const BasicModal: React.FC<BasicModalProps> = ({
  id,
  backdropColor,
  hideBackdrop,
  disabledClose,
  children,
}) => {
  const { modals, closeModal } = useModalState()

  return (
    <>
      {modals[id]?.isOpen && (
        <Modal
          open={modals[id]?.isOpen || false}
          onClose={() => {
            if (!disabledClose) return closeModal(id)
          }}
          disableEnforceFocus
          slots={{
            backdrop: BasicModalBackdrop,
          }}
          hideBackdrop={hideBackdrop}
          slotProps={{
            backdrop: {
              open: modals[id]?.isOpen || false,
              color: backdropColor,
              onClick: () => {
                if (!disabledClose) return closeModal(id)
              },
            },
          }}
        >
          <>{children}</>
        </Modal>
      )}
    </>
  )
}

export default BasicModal
