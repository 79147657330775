import { Box, Checkbox, TableCell, TableRow, Typography } from '@mui/material'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { currency } from '@/constants/currency'
import { MAX_METAVAULTSELECTION } from '@/constants/metavaults'
import { formatUSD } from '@/utils/formats'

interface VaultSelectorTableRowProps {
  row: CreateMetaVaultVaults
  addingVaults?: boolean
}

const VaultSelectorTableRow: React.FC<VaultSelectorTableRowProps> = ({
  row,
  addingVaults,
}) => {
  const {
    selectedVaults,
    setSelectedVaults,
    newlyAddedVaults,
    setNewlyAddedVaults,
    setAsset,
  } = useCreateMetavaultState()

  const { id, name, tvr, apy, assetGroup } = row

  const { chain } = useConnectionState()

  const onRowClick = () => {
    const currentSelection = addingVaults ? newlyAddedVaults : selectedVaults
    const vaultExists = currentSelection.some(
      (selectedVault) => selectedVault.id === id
    )

    if (vaultExists) {
      if (addingVaults) {
        setNewlyAddedVaults(newlyAddedVaults.filter((vault) => vault.id !== id))
      } else {
        setSelectedVaults(selectedVaults.filter((vault) => vault.id !== id))
      }
    } else if (
      selectedVaults.length + newlyAddedVaults.length <
      MAX_METAVAULTSELECTION
    ) {
      if (addingVaults) {
        setNewlyAddedVaults([...newlyAddedVaults, row])
      } else {
        setAsset(assetGroup.assetGroupTokens[0].address)
        setSelectedVaults([...selectedVaults, row])
      }
    }
  }

  const isSelected = useMemo(() => {
    const currentSelection = addingVaults ? newlyAddedVaults : selectedVaults
    return currentSelection.some((selectedVault) => selectedVault.id === id)
  }, [selectedVaults, newlyAddedVaults, addingVaults, id])

  const isDisabled = useMemo(
    () =>
      !isSelected &&
      selectedVaults.length + newlyAddedVaults.length >= MAX_METAVAULTSELECTION,
    [isSelected, selectedVaults, newlyAddedVaults]
  )

  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer', opacity: isDisabled ? 0.5 : 1 }}
        role='checkbox'
        onClick={onRowClick}
        hover
      >
        <TableCell>
          <Box
            display='flex'
            justifyContent='start'
            alignItems='center'
            gap={1}
          >
            <Checkbox
              color='primary'
              checked={isSelected}
              sx={{ pr: 2, py: 1 }}
              disableRipple
            />
            {mapAssetTokenIcon(chain, assetGroup.assetGroupTokens[0].address)}
            <SmartVaultNameTableTypography variant='body2'>
              {name}
            </SmartVaultNameTableTypography>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>{formatUSD(apy || 0)} %</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            {currency} {formatUSD(tvr.totalTvr || 0)}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  )
}

export default VaultSelectorTableRow
