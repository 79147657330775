const ChevronRight = () => {
  return (
    <svg
      width='7'
      height='10'
      viewBox='0 0 7 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.78016 0.5L0.722656 1.5575L4.15766 5L0.722656 8.4425L1.78016 9.5L6.28016 5L1.78016 0.5Z'
        fill='#212322'
      />
    </svg>
  )
}

export default ChevronRight
