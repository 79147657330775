import { Backdrop } from '@mui/material'

type BasicModalBackdropProps = {
  open: boolean
  color?: string
  onClick?: () => void
}

const BasicModalBackdrop: React.FC<BasicModalBackdropProps> = ({
  open,
  color,
  onClick,
}) => {
  return (
    <Backdrop
      sx={{
        bgcolor: color,
      }}
      open={open}
      onClick={onClick}
    />
  )
}

export default BasicModalBackdrop
