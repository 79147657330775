import { createContext } from 'react'

import { NavigationProps } from '@/store/navigation/navigation.types'

export const initialState: {
  pathname: string
  breadcrumbs: string[]
  isVaultsRowClicked: boolean
} = {
  pathname: '/',
  breadcrumbs: [''],
  isVaultsRowClicked: false,
}

export const NavigationContext = createContext<NavigationProps>({
  ...initialState,
  setPathWithHistory: () => undefined,
  setIsVaultsRowClicked: () => undefined,
})
