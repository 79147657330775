import { InvestorSmartVault } from '@solidant/spool-v2-fe-lib'

import { CreatorVault } from '@/types/dashboard'

export const calculateDashboardStrategyAllocation = (
  smartVaults: InvestorSmartVault[] | CreatorVault[]
) => {
  const allocation = smartVaults.reduce((acc, vault) => {
    let curr = acc

    vault.strategies.forEach(({ allocation, name }) => {
      if (!Number(allocation.allocationAmountInUSD)) return

      if (acc[allocation.allocationAmountInUSD]) {
        curr = {
          ...curr,
          [name]: curr[name] + Number(allocation.allocationAmountInUSD),
        }
        return
      }
      curr = { ...curr, [name]: Number(allocation.allocationAmountInUSD) }
    })

    return curr
  }, {} as Record<string, number>)

  // Convert the object to an array, sort it and convert it back to an object
  return Object.fromEntries(
    Object.entries(allocation).sort(([, a], [, b]) => b - a)
  )
}
