import { Box, styled } from '@mui/material'

const Range = styled(Box)(() => ({
  width: 'calc(100% - 12px)',
  left: '50%',
  transform: 'translate(-50%, 0)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid rgb(56 45 45 / 26%)',
  paddingBottom: '0.7rem',
  position: 'absolute',
  top: '-2.8rem',
  fontSize: '12px',
  lineHeight: '19.2px',
  '&::before, &::after': {
    content: `''`,
    width: '9px',
    height: '9px',
    backgroundColor: 'black',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '-4.5px',
  },
  '&::after': {
    right: 0,
  },
  'span:nth-of-type(2):not(:last-child)': {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}))

export default Range
