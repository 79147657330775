import { Box, TableCell, TableRow, Typography } from '@mui/material'
import { StrategyListType } from '@solidant/spool-v2-fe-lib'
import { Link, useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { currency } from '@/constants/currency'
import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'

interface StrategiesTableRowProps {
  row: StrategyListType
}

const StrategiesTableRow: React.FC<StrategiesTableRowProps> = ({ row }) => {
  const { chain } = useConnectionState()

  const { address, name, assetGroup, balanceUSD, apy } = row

  const navigate = useNavigate()

  const onRowClick = () => {
    navigate(`${Routes(chain).strategies.root.url}/${address}`)
  }

  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer', backgroundColor: 'white' }}
        role='checkbox'
        onClick={onRowClick}
        hover
        component={Link}
        to={`${Routes(chain).strategies.root.url}/${address}`}
      >
        <TableCell sx={{ py: 2 }}>
          <AssetGroupTokens assetGroup={assetGroup} />
        </TableCell>
        <TableCell sx={{ pl: '0' }}>
          <Box display='flex' justifyContent='space-between'>
            <NameCellContent>
              <SmartVaultNameTableTypography variant='body2'>
                {name}
              </SmartVaultNameTableTypography>
            </NameCellContent>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>{formatUSD(apy)} %</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            {currency} {formatUSD(balanceUSD)}
          </Typography>
        </TableCell>
        <TableCell padding='none' />
        {/*<TableCell align='right'>
          <Typography variant='body2'>{auditor}</Typography>
        </TableCell>*/}
      </TableRow>
    </>
  )
}

export default StrategiesTableRow
