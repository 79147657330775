import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Checkbox, Chip, Typography } from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import useLocale from '@/hooks/context/useLocale'
import useTransactionHistoryState from '@/hooks/context/useTransactionHistoryState'
import useHistoricalVaultList from '@/hooks/transactionHistory/useHistoricalVaultList'

import { mapIcons } from '@/components/atoms/CryptoIcons'
import NameCellTypography from '@/components/atoms/NameCell'
import SpoolMultiSelect from '@/components/atoms/SpoolMultiSelect'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'
import { StyledMenuItem } from '@/components/organisms/TransactionHistory/TransactionHistoryFilters/TransactionHistoryFilters'

import { gaIds } from '@/constants/googleAnalyticIDs'

const VaultNameFilters = () => {
  const { filteredVaults, setFilteredVaults } = useTransactionHistoryState()
  const { historicalVaultList } = useHistoricalVaultList()

  const t = useLocale()

  const handleFilteredVaultDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault()
    const newFilteredVaults = filteredVaults.filter(
      (filteredVault) => filteredVault !== value
    )
    setFilteredVaults(newFilteredVaults.length ? newFilteredVaults : ['all'])
  }

  return (
    <SpoolMultiSelect
      label={t(
        'components.organisms.transactionHistory.filters.smartVaults.label'
      )}
      multiple
      value={filteredVaults?.length ? filteredVaults : ['All Smart Vaults']}
      handleChange={(event) => {
        const {
          target: { value },
        } = event
        setFilteredVaults(
          // On autofill, we get a stringified value.
          typeof value === 'string' ? value.split(',') : (value as string[])
        )
      }}
      renderValue={(selected) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.2rem' }}>
            {(selected as string[]).map((value) => {
              if (value === 'All Smart Vaults') {
                return (
                  <Typography key='all' variant='body2'>
                    All Smart Vaults
                  </Typography>
                )
              }

              return (
                <Chip
                  sx={{
                    height: '30px',
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                  key={value}
                  label={
                    <Typography
                      key='all'
                      variant='body2'
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                      }}
                    >
                      {historicalVaultList[value]}
                    </Typography>
                  }
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event: any) => event.stopPropagation()}
                    />
                  }
                  onDelete={(event) => handleFilteredVaultDelete(event, value)}
                />
              )
            })}
          </Box>
        )
      }}
    >
      <StyledMenuItem value='all'>
        <Typography variant='body2'>
          {t('components.organisms.transactionHistory.filters.smartVaults.all')}
        </Typography>
      </StyledMenuItem>
      {Object.keys(historicalVaultList).map((vaultAddress, index) => (
        <StyledMenuItem
          id={
            gaIds.filter.main +
            '-' +
            gaIds.filter.page.transactionHistory +
            '-' +
            gaIds.filter.vaults
          }
          key={vaultAddress + index}
          value={vaultAddress}
        >
          <Checkbox
            sx={{
              '& .MuiCheckbox-root': {
                padding: 'none',
              },
            }}
            checked={filteredVaults?.indexOf(vaultAddress) > -1}
          />
          <Box
            display='flex'
            width='100%'
            alignItems='center'
            justifyContent='space-between'
          >
            <NameCellContent>
              <Box display='flex' alignContent='center'>
                {/* ARB */}
                {mapIcons(SupportedNetworks.ETHEREUM)}
              </Box>
              <NameCellTypography variant='body2'>
                {historicalVaultList[vaultAddress]}
              </NameCellTypography>
            </NameCellContent>
            {/* <Box display='flex'>
              {assetGroup &&
                assetGroup?.assetGroupTokens.map(({ address }, index) => (
                  <Fragment key={index}>
                    {mapAssetTokenIcon(chain,address ?? '')}
                  </Fragment>
                ))}
            </Box> */}
          </Box>
        </StyledMenuItem>
      ))}
    </SpoolMultiSelect>
  )
}

export default VaultNameFilters
