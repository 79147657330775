import {
  ConnectionAction,
  ConnectionActionTypes,
  ConnectionState,
} from '@/store/connection/connection.types'

const connectionReducer = (
  state: ConnectionState,
  action: ConnectionAction
): ConnectionState => {
  switch (action.type) {
    case ConnectionActionTypes.SET_CHAIN:
      return { ...state, chain: action.payload }
    case ConnectionActionTypes.TARGET_WALLET:
      return { ...state, activatingWallet: action.payload }
    default:
      return state
  }
}

export default connectionReducer
