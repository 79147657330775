export const mapRiskToRange = (risk: number, reverse = false) => {
  const oldRange = [-10, 10]
  const newRange = [0, 10]

  const inMin = reverse ? newRange[0] : oldRange[0]
  const inMax = reverse ? newRange[1] : oldRange[1]

  const outMin = reverse ? oldRange[0] : newRange[0]
  const outMax = reverse ? oldRange[1] : newRange[1]

  return ((risk - inMin) / (inMax - inMin)) * (outMax - outMin) + outMin
}
