import { Box, Stack, Typography } from '@mui/material'
import { Asset } from '@solidant/spool-v2-fe-lib'

import useConnectionState from '@/hooks/context/useConnectionState'
import usePriceFeed from '@/hooks/web3/usePriceFeed'

import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { formatUSD } from '@/utils/formats'
import { getAssetAmountFromPrice } from '@/utils/web3'

interface MultiAssetSwapListTokenProps {
  asset: Asset
  amountInUsd: string
}

const MultiAssetSwapListToken: React.FC<MultiAssetSwapListTokenProps> = ({
  asset,
  amountInUsd,
}) => {
  const { address, decimals, symbol } = asset

  const { priceFeed } = usePriceFeed(address)
  const { chain } = useConnectionState()

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      padding={(theme) => theme.spacing(1, 2)}
    >
      <Stack textAlign='left'>
        <Typography>
          {(+getAssetAmountFromPrice(amountInUsd, priceFeed, decimals)).toFixed(
            2
          )}
        </Typography>
        <Typography variant='h6' fontSize='12px' color='text.secondary'>
          ~$ {formatUSD(amountInUsd, 2)}
        </Typography>
      </Stack>
      <Stack display='flex'>
        <Box display='flex' alignItems='center' gap='4px'>
          <Typography variant='body2' fontSize='16px' mr='0.2rem'>
            {symbol}
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            sx={{ transform: 'scale(1.4)' }}
          >
            {asset.address && mapAssetTokenIcon(chain, asset.address)}
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export default MultiAssetSwapListToken
