import { Box } from '@mui/material'

import SliderBox from '@/components/molecules/CustomSlider/SliderBox'

interface SliderTrackProps {
  width: number
  numMarks: number
}

const SliderTrack: React.FC<SliderTrackProps> = ({ width, numMarks }) => {
  return (
    <Box
      width={width}
      sx={{
        display: 'flex',
        height: '43px',
        position: 'absolute',
        background: 'none',
        border: 'none',
        top: '5px',
        left: '0.64rem',
        cursor: 'pointer',
      }}
    >
      {[...new Array(numMarks)].map((_, index) => (
        <SliderBox
          key={index}
          width={width / numMarks}
          sx={{
            backgroundPositionX: -index * (width / numMarks),
            backgroundSize: `${width}px 43px`,
          }}
        />
      ))}
    </Box>
  )
}

export default SliderTrack
