import WarningIcon from '@mui/icons-material/Warning'
import { Box, Stack, TablePagination } from '@mui/material'
import { UserTransactionHistory } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'

import useTransactionHistoryState from '@/hooks/context/useTransactionHistoryState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useHistoricalVaultList from '@/hooks/transactionHistory/useHistoricalVaultList'
import useUserTransactionHistory from '@/hooks/transactionHistory/useUserTransactionHistory'

import PageTransition from '@/components/atoms/PageTransition'
import UnconnectedWalletDisplay from '@/components/atoms/UnconnectedWalletDisplay'
import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import TransactionHistoryFilters from '@/components/organisms/TransactionHistory/TransactionHistoryFilters/TransactionHistoryFilters'
import TransactionHistoryTable from '@/components/organisms/TransactionHistory/TransactionHistoryTable'
import TransactionHistoryMobileRow from '@/components/organisms/TransactionHistory/TransactionHistoryTable/TransactionHistoryMobileRow'

const History = () => {
  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { page, limit, setPage } = useTransactionHistoryState()

  const {
    transactionHistory,
    totalTransactions,
    isLoading: transactionHistoryLoading,
    error: transactionHistoryError,
  } = useUserTransactionHistory()
  const { account } = useWeb3React()

  const { isLoading: historicalVaultLoading, error: historicalVaultError } =
    useHistoricalVaultList()

  const isError = useMemo(
    () => !!transactionHistoryError || !!historicalVaultError,
    [transactionHistoryError, historicalVaultError]
  )

  const isLoading = useMemo(
    () => transactionHistoryLoading || historicalVaultLoading,
    [historicalVaultLoading, transactionHistoryLoading]
  )

  if (!account) {
    return <UnconnectedWalletDisplay />
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  return (
    <PageTransition>
      <Stack gap={(theme) => theme.spacing(2)} mb='5rem'>
        <Section>
          <SectionColumn sx={{ gap: 0 }}>
            {isLoading ? (
              <DataLoading loadingText='Loading Transaction History' />
            ) : isError ? (
              <Box
                my='2rem'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <WarningIcon
                  sx={{ height: '90px', width: '90px' }}
                  color='primary'
                />
                <DataMissing text='An Error Occurred...' />
              </Box>
            ) : (
              <>
                <TransactionHistoryFilters />
                {!belowTabletBreakpoint ? (
                  <TransactionHistoryTable
                    totalTransactions={totalTransactions}
                    transactionHistory={transactionHistory}
                  />
                ) : (
                  transactionHistory?.map(
                    (transactionHistory: UserTransactionHistory, index) => (
                      <TransactionHistoryMobileRow
                        key={transactionHistory.vault.name + index}
                        transactionHistory={transactionHistory}
                      />
                    )
                  )
                )}
              </>
            )}
            {belowTabletBreakpoint && (
              <TablePagination
                rowsPerPageOptions={[]}
                component='div'
                count={totalTransactions}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
              />
            )}
          </SectionColumn>
        </Section>
      </Stack>
    </PageTransition>
  )
}

export default History
