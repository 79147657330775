import { Box } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import CustomButton from '@/components/atoms/CustomButton'

interface BottomButtonsProps {
  handleSave: () => void
  handleTest: () => void
  closeModal: () => void
  configured: boolean
  disabled?: boolean
  handleEditConfiguration: () => void
}

const BottomButtons: React.FC<BottomButtonsProps> = ({
  handleSave,
  handleTest,
  closeModal,
  configured,
  handleEditConfiguration,
  disabled,
}) => {
  const t = useLocale()
  return (
    <>
      <Box display='flex' justifyContent='center' gap={3} paddingY={3}>
        <CustomButton
          color='primary'
          size='large'
          variant='contained'
          onClick={handleSave}
          disabled={disabled}
        >
          {t(
            'components.organisms.createVault.guards.modals.bottomButtons.save.label'
          )}
        </CustomButton>
        <CustomButton
          color='primary'
          size='large'
          variant='outlined'
          onClick={configured ? handleEditConfiguration : handleTest}
          disabled={disabled}
        >
          {configured ? 'Edit' : 'Test'}
        </CustomButton>
      </Box>
      <CustomButton
        color='primary'
        size='large'
        variant='text'
        onClick={closeModal}
        sx={{ width: 'fit-content', alignSelf: 'end' }}
      >
        {t(
          'components.organisms.createVault.guards.modals.bottomButtons.back.label'
        )}
      </CustomButton>
    </>
  )
}

export default BottomButtons
