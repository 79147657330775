import { GuardActions, GuardType } from '@solidant/spool-v2-fe-lib'

export enum GUARDS_ID {
  WHITELIST_DEPOSITORS = 'whitelistDepositors',
  WHITELIST_DEPOSITOR_BENEFICIARIES = 'whitelistDepositorBeneficiaries',
  ASSET_LIMIT = 'assetLimits',
  NFT_DEPOSITORS = 'nftDepositors',
  NFT_BENEFICIARIES = 'nftBeneficiaries',
  SVT_NONTRANSFERABLE = 'svtNonTransferableAddress',
  WHITELIST_SVT = 'svtWhitelist',
  WHITELIST_WITHDRAWAL_BENEFICIARIES = 'whitelistWithdrawalBeneficiaries',
  ASSET_HOLDING_PERIOD = 'assetHoldingPeriod',
  SVT_HOLDING_PERIOD = 'svtHoldingPeriod',
}

export enum GUARDS_ASSET_LIMIT_OPERATOR {
  'MINIMUM' = '<=',
  'MAXIMUM' = '>=',
}

export const ACTION_CONDITION_MAP = {
  [GuardActions.Deposit]: 'Deposit permission is restricted to the ',
  [GuardActions.Withdraw]: 'Withdraw permission is restricted to the ',
  [GuardActions.TransferNft]: '',
  [GuardActions.BurnNft]: '',
  [GuardActions.TransferSvts]: '',
}

export const TARGET_CONDITION_MAP = {
  DEPOSITORS: 'users ',
  BENEFICIARIES: 'beneficiaries ',
}

export const GUARDTYPE_CONDITION_MAP = {
  //TODO: add strings for other guards
  [GuardType.NftGuard]: 'that own an item from this NFT Collection:',
  [GuardType.AssetGuard]: '',
  [GuardType.SvtGuard]: '',
  [GuardType.WhitelistGuard]: '',
}
