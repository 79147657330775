import { Box } from '@mui/material'
import { useMemo } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import StyledActionButton from '@/components/atoms/StyledActionButton'
import { VaultActionType } from '@/components/organisms/SmartVaults/VaultActions/VaultActions'
import ClaimLayout from '@/components/organisms/SmartVaults/VaultActions/Withdraw/ClaimLayout'

interface WithdrawOrClaimProps {
  setAction: React.Dispatch<React.SetStateAction<VaultActionType>>
}

const WithdrawOrClaim: React.FC<WithdrawOrClaimProps> = ({ setAction }) => {
  const t = useLocale()

  const { smartVaultData } = useVaultsPage()

  const hasRewards = useMemo(
    () => !!Number(smartVaultData.incentivizedRewards.totalClaimableAmountUSD),
    [smartVaultData]
  )

  return (
    <Box
      padding='1.5rem 1.5rem'
      display='flex'
      flexDirection='column'
      gap='1rem'
    >
      <StyledActionButton
        variant='contained'
        size='large'
        fullWidth
        onClick={() => setAction(VaultActionType.WITHDRAW)}
      >
        {t(
          'components.organisms.smartVaults.collapsibleRow.vaultActionButtons.actionButtons.withdraw.label'
        )}
      </StyledActionButton>
      {hasRewards && <ClaimLayout />}
    </Box>
  )
}

export default WithdrawOrClaim
