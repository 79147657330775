import InfoIcon from '@mui/icons-material/Info'
import Star from '@mui/icons-material/Star'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { SmartVaultBase } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import dayjs, { unix } from 'dayjs'
import { memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'

import getLocale from '@/locales/getLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import TextTagRenderer from '@/components/atoms/TextTagRenderer'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import Verified from '@/assets/icons/general/Verified'

import { currency } from '@/constants/currency'
import { gaIds } from '@/constants/googleAnalyticIDs'
import { Routes } from '@/constants/routes'
import {
  CLAIM_INCENTIVIZED_REWARDS_TIME,
  REWARDS_SYNC_DURING_CLAIM,
} from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

interface SmartVaultTableRowProps {
  smartVaultBase: SmartVaultBase
}

const SmartVaultTableRow: React.FC<SmartVaultTableRowProps> = ({
  smartVaultBase,
}) => {
  const { chain } = useConnectionState()

  const {
    address,
    name,
    assetGroup,
    adjustedApy,
    owner,
    incentivizedRewards,
    totalVolumeRouted,
    baseApy,
    vaultDetails,
  } = smartVaultBase
  const { account } = useWeb3React()

  const navigate = useNavigate()

  const t = getLocale()

  // check if sync time during claim has passed the current time, if it has then remove the keys
  useEffect(() => {
    const syncTimeAtTimeOfClaim = localStorage.getItem(
      REWARDS_SYNC_DURING_CLAIM + address
    )

    if (
      syncTimeAtTimeOfClaim &&
      dayjs().isAfter(unix(+syncTimeAtTimeOfClaim))
    ) {
      localStorage.removeItem(CLAIM_INCENTIVIZED_REWARDS_TIME + address)
      localStorage.removeItem(REWARDS_SYNC_DURING_CLAIM + address)
    }
  }, [])

  //const showRiskModel = !!riskScore

  return (
    <>
      <Box
        mb='1rem'
        padding='1rem'
        onClick={() => {
          navigate(
            `${Routes(chain).smartVaults.root.url}/${smartVaultBase.address}`
          )
          return
        }}
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius=' 0 0 4px 4px'
        tabIndex={-1}
        id={gaIds.smartVaultRow.main + '-' + gaIds.smartVaultRow.home}
      >
        <Box display='flex' alignItems='center' mb='1rem'>
          <Box>
            <AssetGroupTokens assetGroup={assetGroup} />
          </Box>
          <Box ml='0.5rem'>
            <NameCellContent>
              {/*{mapIcons(SupportedNetworks.ETHEREUM)}*/}
              <SmartVaultNameTableTypography variant='body2'>
                {name}
              </SmartVaultNameTableTypography>
              {owner === account && (
                <IconButton sx={{ padding: '4px' }} disableRipple>
                  <Star color='primary' className='lg' />
                </IconButton>
              )}
            </NameCellContent>
            {vaultDetails?.createdBy && (
              <Box display='flex' gap={0.5} alignItems='center'>
                <Typography variant='caption' color='gray'>
                  {`By ${vaultDetails?.createdBy}`}
                </Typography>
                <Tooltip placement='right' title='Official Partner'>
                  <IconButton sx={{ padding: '0' }}>
                    <Verified />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
        <Box display='flex' alignItems='center'>
          {vaultDetails?.vaultSource === 'drip' ? (
            <Box>
              <Typography
                fontSize='24px'
                variant='body2'
                fontWeight='400'
                mb='0.2rem'
              >
                {`${vaultDetails?.farmRewards}`}
              </Typography>
              <Typography
                variant='caption'
                color='rgba(33, 35, 34, 0.7)'
                component={Box}
                display='flex'
                gap={0.5}
              >
                <span>
                  {t('pages.smartVaults.partners.drip.pointsSubheader')}
                </span>
                <Tooltip
                  onClick={(event) => event.stopPropagation()}
                  title={
                    <Box
                      sx={{ color: 'black' }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <TextTagRenderer
                        inputString={t(
                          'pages.smartVaults.partners.drip.tooltip'
                        )}
                      />
                    </Box>
                  }
                  sx={{ alignSelf: 'center' }}
                >
                  <InfoIcon
                    className='sm'
                    sx={{ alignSelf: 'center', color: 'primary.main' }}
                  />
                </Tooltip>
                {incentivizedRewards?.rewards
                  .filter((reward) => +reward.apy > 0.01)
                  .map((reward, index) => (
                    <span key={index}>
                      {' + '}
                      {reward.asset.symbol} {formatUSD(reward.apy)}%{' '}
                    </span>
                  ))}
              </Typography>
            </Box>
          ) : (
            <Box display='flex' flexDirection='column'>
              <Typography
                fontSize='24px'
                variant='body2'
                fontWeight='400'
                mb='0.2rem'
              >
                {formatUSD(adjustedApy)} %
              </Typography>
              <Typography variant='caption' color='rgba(33, 35, 34, 0.7)'>
                {`Base ${formatUSD(baseApy)}%`}
                {incentivizedRewards?.rewards
                  .filter((reward) => +reward.apy > 0.01)
                  .map((reward, index) => (
                    <span key={index}>
                      {' + '}
                      {reward.asset.symbol} {formatUSD(reward.apy)}%{' '}
                    </span>
                  ))}
              </Typography>
              <Typography variant='caption' color='rgba(33, 35, 34, 0.7)'>
                After fees
              </Typography>
            </Box>
          )}
        </Box>
        <Box display='flex' mt='1rem'>
          <Typography variant='body2' fontSize='12px'>
            {currency} {formatUSD(totalVolumeRouted.total, 2)}
          </Typography>
          &nbsp;
          <Typography variant='caption' color='rgba(33, 35, 34, 0.7)'>
            TVR
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default memo(SmartVaultTableRow)
