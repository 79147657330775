import { createContext } from 'react'

import {
  RewardsTableContextProps,
  RewardsTableState,
} from '@/store/rewardsTable/rewardsTable.types'

export const initialState: RewardsTableState = {
  page: 0,
  limit: 10,
  owner: '',
}

export const RewardsTableContext = createContext<RewardsTableContextProps>({
  ...initialState,
  setPage: () => undefined,
  setLimit: () => undefined,
  setOwner: () => undefined,
  handleRewardsTableSort: () => undefined,
})
