import { Box, Typography } from '@mui/material'
import { parseUnits } from 'ethers/lib/utils'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useDepositState from '@/hooks/context/useDepositState'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useDebounce from '@/hooks/utils/useDebounce'
import useDepositSwapConversion from '@/hooks/vaultList/Transactions/useDepositSwapConversion'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import Oneinch from '@/assets/icons/chainIcons/1inch'

import { WRAPPED_TOKEN_ADDRESS } from '@/constants/tokens'
import { parseConversionText } from '@/utils/conversionText'
import { getIsNativeCurrency } from '@/utils/web3'

const ConversionInfo = () => {
  const { smartVaultData } = useVaultsPage()

  const { assetGroup } = smartVaultData

  const { singleDeposit } = useDepositState()

  const { chain } = useConnectionState()

  const { decimals, symbol } = useTokenDetails(singleDeposit.tokenAddress)

  const { address } = smartVaultData.assetGroup.assetGroupTokens[0]
  const {
    symbol: vaultUnderlyingTokenSymbol,
    decimals: vaultUnderlyingTokenDecimals,
  } = useTokenDetails(address)

  const selectedVaultAsset = assetGroup.assetGroupTokens[0].address

  const debouncedAmount = useDebounce(
    singleDeposit.amount
      ? parseUnits(singleDeposit.amount, decimals).toString()
      : ''
  )

  // ARB
  const { depositSwapConversion } = useDepositSwapConversion(
    // if converting eth to vault asset, use wrapped eth price
    getIsNativeCurrency(singleDeposit.tokenAddress)
      ? WRAPPED_TOKEN_ADDRESS[chain]
      : singleDeposit.tokenAddress,
    selectedVaultAsset,
    debouncedAmount
  )

  const isWrap = useMemo(
    () => symbol === 'ETH' && vaultUnderlyingTokenSymbol === 'WETH',
    [symbol, vaultUnderlyingTokenSymbol]
  )

  return (
    <Box display='flex' gap={(theme) => theme.spacing(2)}>
      <Box width='100%'>
        {!isWrap && (
          <Box display='flex' alignItems='center' gap='0.5rem'>
            <Oneinch />
            <Typography color='#21232299'>Swapped via 1inch</Typography>
          </Box>
        )}
        <Box
          width='100%'
          padding='1rem'
          borderRadius='0.2rem'
          sx={{
            backgroundColor: '#f5f5f5',
          }}
        >
          <Typography fontSize='14px'>
            {parseConversionText(
              singleDeposit,
              symbol,
              depositSwapConversion,
              vaultUnderlyingTokenDecimals,
              vaultUnderlyingTokenSymbol,
              isWrap
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ConversionInfo
