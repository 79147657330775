import { Box, Typography } from '@mui/material'
import { ReactNode, useEffect, useRef, useState } from 'react'
import ReactSlider from 'react-slider'

import Range from '@/components/molecules/CustomSlider/Range'
import SliderOuterBorder from '@/components/molecules/CustomSlider/SliderOuterBorder'
import SliderTrack from '@/components/molecules/CustomSlider/SliderTrack'
import SliderThumb from '@/components/molecules/SliderThumb/SliderThumb'

type Props = {
  range?: {
    max: number
    min: number
  }
  rangeLabel?: {
    max: number
    min: number
  }
  rangeSuffix?: string | number | ReactNode
  value: number
  onChange: (value: number) => any
  onBeforeChange?: () => void
  onAfterChange?: () => void
  lineMultiple?: number
  step?: number
}

const CustomSlider: React.FC<Props> = ({
  range = { max: 20, min: 0 },
  rangeLabel,
  rangeSuffix = '',
  value,
  onChange,
  onBeforeChange,
  onAfterChange,
  lineMultiple = 1,
  step = 1,
}) => {
  const [lines, setLines] = useState(0)
  const sliderRef = useRef<HTMLDivElement>(null)

  const { max, min } = range

  useEffect(() => {
    if (!sliderRef.current || lines || !range) return

    const numOfLines = (max - min) * lineMultiple

    setLines(Math.floor(numOfLines))
  }, [sliderRef, range, lineMultiple, lines])

  return (
    <Box paddingTop='1rem' height='100px' width='633px'>
      <Box position='relative' ref={sliderRef}>
        <Range>
          <Typography>
            {rangeLabel?.min ?? min}
            {rangeSuffix}
          </Typography>

          <Typography>
            {rangeLabel?.max ?? max}
            {rangeSuffix}
          </Typography>
        </Range>
        <SliderOuterBorder />
        <ReactSlider
          className='horizontal-slider custom-horizontal-slider'
          min={min}
          value={value}
          step={step}
          max={max}
          onChange={onChange}
          onBeforeChange={onBeforeChange}
          onAfterChange={onAfterChange}
          renderTrack={(props) => (
            <SliderTrack {...props} width={615} numMarks={52} />
          )}
          renderThumb={(props) => (
            <SliderThumb key='slider-thumb' props={props} />
          )}
        />
      </Box>
    </Box>
  )
}

export default CustomSlider
