import type { AddEthereumChainParameter } from '@web3-react/types'

export enum SupportedNetworks {
  MAINNET = 1,
  //   GOERLI = 5,
  ARBITRUM_ONE = 42161,
  //   ARBITRUM_RINKEBY = 421611,
}

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

interface BasicChainInformation {
  urls: string[]
  name: string
  providerName: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency?: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls?: AddEthereumChainParameter['blockExplorerUrls']
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

export const CHAINS: {
  [chainId: number]: ExtendedChainInformation
} = {
  1: {
    urls: [
      process.env.infuraKey
        ? `https://mainnet.infura.io/v3/${process.env.infuraKey}`
        : '',
      process.env.alchemyKey
        ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}`
        : '',
      'https://cloudflare-eth.com',
    ].filter((url) => url !== ''),
    name: 'Ethereum',
    nativeCurrency: ETH,
    providerName: 'mainnet',
  },
  // Arbitrum
  42161: {
    urls: [
      process.env.infuraKey
        ? `https://arbitrum-mainnet.infura.io/v3/${process.env.infuraKey}`
        : '',
      'https://arb1.arbitrum.io/rpc',
    ].filter((url) => url !== ''),
    name: 'Arbitrum',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
    providerName: 'arbitrum',
  },
  // Sepolia
  11155111: {
    urls: ['https://sepolia.gateway.tenderly.co/51xvd3NjqSCwdOXr63AGCL'].filter(
      (url) => url !== ''
    ),
    name: 'Sepolia',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
    providerName: 'sepolia',
  },
}

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs
  }

  return accumulator
}, {})
