import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box } from '@mui/material'
import { useState } from 'react'

import useLocale from '@/hooks/context/useLocale'

const WithdrawDisclaimer = () => {
  const t = useLocale()
  const [isClosed, setIsClosed] = useState<boolean>(false)

  return (
    <>
      {!isClosed && (
        <Box
          display='grid'
          gridTemplateColumns='0.4fr 3.5fr 0.5fr'
          bgcolor='#d4e6f0'
          padding='0.5rem'
          py='1rem'
          mb='1.5rem'
          borderRadius='4px'
        >
          <InfoOutlinedIcon sx={{ color: '#0288D1' }} fontSize='small' />
          <Box fontSize='14px'>
            {t('pages.smartVaults.vaultDetailsPage.withdrawDisclaimer')}
          </Box>
          <Box display='flex' justifyContent='center'>
            <CloseOutlinedIcon
              sx={{ color: 'black', cursor: 'pointer' }}
              fontSize='small'
              onClick={() => setIsClosed((prev) => !prev)}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default WithdrawDisclaimer
