import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

interface DialogContainerProps extends PropsWithChildren {
  isOpen?: boolean
  bottom?: string
  zIndex?: number
  padding?: number
}

const DialogContainer: React.FC<DialogContainerProps> = ({
  isOpen,
  bottom = '60px',
  zIndex = 9999,
  padding = 2,
  children,
}) => {
  const { belowTabletBreakpoint, belowPhabletBreakpoint } =
    useBreakpointDetection()

  return (
    <>
      {isOpen && (
        <Box
          display='inline-flex'
          boxShadow={6}
          sx={
            belowTabletBreakpoint
              ? {
                  position: 'fixed',
                  bottom: '-10px',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '1rem',
                  zIndex: '10000',
                  backgroundColor: 'background.paper',
                  minWidth: belowPhabletBreakpoint ? '280px' : '500px',
                }
              : {
                  maxWidth: '850px',
                  zIndex: zIndex,
                  position: 'fixed',
                  bottom: bottom,
                  right: '93px',
                  padding: padding,
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                }
          }
        >
          {children}
        </Box>
      )}
    </>
  )
}

export default DialogContainer
