import { useEffect, useState } from 'react'

const useInFocus = () => {
  const [isInFocus, setIsInFocus] = useState(document.hidden)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsInFocus(document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return { isInFocus }
}

export default useInFocus
