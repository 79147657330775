import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

const HIDDEN_STRATEGIES = ['0xafc182fcfbda42f639ccd4518aeccce5ea777440']

const useStrategies = () => {
  const { chain } = useConnectionState()

  const { sdk } = useContext(AppContext)

  const { data, error } = useSWR(
    sdk ? ['strategies', chain] : null,
    async () => {
      return (await sdk.strategies.getStrategies()).filter(
        ({ address }) => !HIDDEN_STRATEGIES.includes(address)
      )
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    strategies: data,
    totalStrategies: data?.length ?? 0,
    isLoading: !error && !data,
    error,
  }
}

export default useStrategies
