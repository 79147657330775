import CloseIcon from '@mui/icons-material/Close'
import { Box, Drawer, Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'

import useTopMenuState from '@/hooks/context/useTopMenuState'

import ConnectWalletModalOptions from '@/components/organisms/ConnectWalletModal/ConnectWalletModalOptions'

const MobileWalletDrawer = () => {
  const theme = useTheme()
  const { isOpen, toggleMenu } = useTopMenuState()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        toggleMenu(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [toggleMenu])

  return (
    <Drawer variant='persistent' anchor='bottom' open={isOpen}>
      <Box display='flex' flexGrow={1}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            overflow: 'hidden',
            px: (theme) => theme.spacing(2),
            py: (theme) => theme.spacing(2),
            width: '100%',
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            pb={(theme) => theme.spacing(1.5)}
          >
            <Typography
              variant='alertTitle'
              component={Box}
              sx={{ px: (theme) => theme.spacing(1.5) }}
            >
              Connect wallet
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              sx={{ pr: (theme) => theme.spacing(1.5) }}
              onClick={() => toggleMenu(false)}
            >
              <CloseIcon />
            </Box>
          </Box>
          <ConnectWalletModalOptions />
        </Box>
      </Box>
    </Drawer>
  )
}

export default MobileWalletDrawer
