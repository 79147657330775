import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import CustomButton from '@/components/atoms/CustomButton'
import SpoolSelect from '@/components/atoms/SpoolSelect'
import { SpoolSelectCoinIcon } from '@/components/atoms/SpoolSelectCoinIcon'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { Requirement } from '@/store/createVault/createVault.types'

import { Token } from '@/constants/smartVault'
import { tokenAddresses, tokenSymbols } from '@/constants/tokens'
import { preventOverflow } from '@/utils/web3'

interface AssetsInputProps {
  id: string
  token?: Token
  setToken: (token?: Token) => void
  requirement: Requirement
  setRequirement: (requirement: Requirement) => void
  value: string
  setValue: (value: string) => void
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  selected: string[]
  setSelected: (selected: string[]) => void
}

const AssetsInput: React.FC<AssetsInputProps> = ({
  id,
  token,
  setToken,
  requirement,
  setRequirement,
  value,
  setValue,
  editMode,
  setEditMode,
  selected,
  setSelected,
}) => {
  const t = useLocale()
  const { chain } = useConnectionState()
  const { selectedGuards, setGuardsAssetAmount } = useCreateVaultState()

  const handleAdd = () => {
    if (token && value !== '') {
      setGuardsAssetAmount(id, [
        ...(selectedGuards[id]?.assetAmount || []),
        {
          token,
          requirement,
          value,
        },
      ])
      setToken(undefined)
      setRequirement(Requirement.MINIMUM)
      setValue('')
    }
  }

  const handleUpdate = () => {
    if (token && value !== '') {
      setGuardsAssetAmount(
        id,
        selectedGuards[id]?.assetAmount.map((asset) =>
          asset.token === selected[0]
            ? { id: asset.id, token, requirement, value }
            : asset
        )
      )
      setEditMode(false)
      setToken(undefined)
      setRequirement(Requirement.MINIMUM)
      setValue('')
      setSelected([])
    }
  }

  const { decimals } = useTokenDetails(token)

  const setInputValue = (value: string) => {
    if (
      value !== '.' &&
      +value < Number.MAX_SAFE_INTEGER &&
      value.match('^[0-9]*[.]?[0-9]*$')
    ) {
      setValue(preventOverflow(value, decimals))
    }
  }

  return (
    <Box display='flex' gap={(theme) => theme.spacing(1.5)}>
      <Box width='300px'>
        <SpoolSelect
          label={t(
            'components.organisms.createVault.guards.modals.assetsModal.assetsInput.select.label'
          )}
          value={token || ''}
          handleChange={(event) => setToken(event.target.value as Token)}
        >
          {Object.values(tokenAddresses[chain])
            .filter((address) => {
              const assetWithRequirementAdded = !!(
                selectedGuards[id]?.assetAmount || []
              ).filter(
                (assetAmount) =>
                  assetAmount.token === address &&
                  assetAmount.requirement === requirement
              ).length
              return address !== 'native' && !assetWithRequirementAdded
            })
            .map((address) => {
              if (address === tokenAddresses[chain].SPOOL) return

              return (
                <MenuItem key={address} value={address}>
                  <SpoolSelectCoinIcon disableRipple>
                    {mapAssetTokenIcon(chain, address)}
                  </SpoolSelectCoinIcon>
                  {tokenSymbols[address]}
                </MenuItem>
              )
            })}
        </SpoolSelect>
      </Box>
      <RadioGroup
        row
        value={requirement}
        onChange={(event) =>
          setRequirement(
            (event.target as HTMLInputElement).value as Requirement
          )
        }
        sx={{ display: 'contents' }}
      >
        <FormControlLabel
          value={Requirement.MINIMUM}
          control={<Radio />}
          label={t(
            'components.organisms.createVault.guards.modals.assetsModal.assetsInput.formControl.min.label'
          )}
          sx={{ marginLeft: '10px' }}
        />
        <FormControlLabel
          value={Requirement.MAXIMUM}
          control={<Radio />}
          label={t(
            'components.organisms.createVault.guards.modals.assetsModal.assetsInput.formControl.max.label'
          )}
          sx={{ marginRight: '20px' }}
        />
      </RadioGroup>
      <TextField
        size='small'
        disabled={!token}
        label={
          !token
            ? t(
                'components.organisms.createVault.guards.modals.assetsModal.assetsInput.textField.noTokenSelectedLabel'
              )
            : t(
                'components.organisms.createVault.guards.modals.assetsModal.assetsInput.textField.label'
              )
        }
        onChange={(event) => setInputValue(event.target.value)}
        value={value}
        sx={{ flexGrow: 1 }}
      />
      <CustomButton
        color='primary'
        disabled={!token || value === ''}
        size='large'
        variant='contained'
        onClick={editMode ? handleUpdate : handleAdd}
      >
        {editMode
          ? t(
              'components.organisms.createVault.guards.modals.assetsModal.assetsInput.customButton.update.label'
            )
          : t(
              'components.organisms.createVault.guards.modals.assetsModal.assetsInput.customButton.add.label'
            )}
      </CustomButton>
    </Box>
  )
}

export default AssetsInput
