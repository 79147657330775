import {
  RewardsTableAction,
  RewardsTableActionTypes,
  RewardsTableState,
} from '@/store/rewardsTable/rewardsTable.types'

const rewardsTableReducer = (
  state: RewardsTableState,
  action: RewardsTableAction
): RewardsTableState => {
  switch (action.type) {
    case RewardsTableActionTypes.SET_PAGE:
      return { ...state, page: action.payload }
    case RewardsTableActionTypes.SET_LIMIT:
      return { ...state, limit: action.payload }
    case RewardsTableActionTypes.SET_OWNER:
      return { ...state, owner: action.payload }
    case RewardsTableActionTypes.SET_REWARDS_TABLE_SORT:
      return {
        ...state,
        rewardsTableSort: {
          ...state.rewardsTableSort,
          key: action.payload,
        },
      }
    case RewardsTableActionTypes.SET_REWARDS_TABLE_SORT_DIRECTION:
      return {
        ...state,
        rewardsTableSort: {
          ...state.rewardsTableSort,
          direction: action.payload,
        },
      }
    default:
      return state
  }
}

export default rewardsTableReducer
