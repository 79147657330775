import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useAccumulatedSYlay from '@/hooks/staking/useAccumulatedSYLay'

import SectionItem from '@/components/molecules/SectionItem'

import { Token } from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

const AccumulatedSYlay = () => {
  const t = useLocale()

  const { account } = useWeb3React()

  const { accumulatedSYlay, isLoading: isLoadingAccumulated } =
    useAccumulatedSYlay(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.overview.sectionItems.accumulatedVospool.title'
      )}
      sx={{ width: '100%' }}
    >
      <Box display='flex' alignItems='baseline'>
        <Typography variant='h6'>
          {!isLoadingAccumulated ? formatUSD(accumulatedSYlay) : '0'}
        </Typography>
        <Typography variant='body1'>&nbsp;{Token.sYLAY}</Typography>
      </Box>
    </SectionItem>
  )
}

export default AccumulatedSYlay
