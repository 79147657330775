import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import ARBITRUM_RISK_MODELS from '@/constants/riskModels/arbitrumRiskModels.json'
import ETHEREUM_RISK_MODELS from '@/constants/riskModels/ethereumRiskModels.json'
import SEPOLIA_RISK_MODELS from '@/constants/riskModels/sepoliaRiskModels.json'

import { RiskModelsConstant } from '@/types/create-vault'

const riskModelConfig: { [chainId: number]: RiskModelsConstant } = {
  [SupportedNetworks.ETHEREUM]: ETHEREUM_RISK_MODELS as RiskModelsConstant,
  [SupportedNetworks.ARBITRUM]: ARBITRUM_RISK_MODELS as RiskModelsConstant,
  [SupportedNetworks.SEPOLIA]: SEPOLIA_RISK_MODELS as RiskModelsConstant,
}

export const mapRiskModelConfig = (chainId: SupportedNetworks | undefined) => {
  if (chainId) return riskModelConfig[chainId]
}
