import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import {
  ALLOCATION_PROVIDERS_ARBITRUM,
  ALLOCATION_PROVIDERS_ARBITRUM_TENDERLY,
  ALLOCATION_PROVIDERS_ETHEREUM,
  ALLOCATION_PROVIDERS_ETHEREUM_TENDERLY,
  ALLOCATION_PROVIDERS_SEPOLIA,
} from '@/components/atoms/AllocationProviders'

import { ARBITRUM_CONFIG } from '@/config/configs/arb.config'
import { ARBITRUM_STAGING_CONFIG } from '@/config/configs/arbStaging.config'
import { ARBITRUM_TENDERLY_CONFIG } from '@/config/configs/arbTenderly.config'
import { ETHEREUM_CONFIG, ETHEREUM_FEATURES } from '@/config/configs/eth.config'
import { ETHEREUM_STAGING_CONFIG } from '@/config/configs/ethStaging.config'
import {
  ETHEREUM_TENDERLY_CONFIG,
  ETHEREUM_TENDERLY_FEATURES,
  ETHEREUM_TENDERLY_FLUSHES,
} from '@/config/configs/ethTenderly.config'
import { SEPOLIA_CONFIG } from '@/config/configs/sepolia.config'

export const IS_TESTNET =
  !import.meta.env.VITE_IS_TESTNET ||
  (import.meta.env.VITE_IS_TESTNET &&
    import.meta.env.VITE_IS_TESTNET.toLowerCase() === 'true')

export const IS_STAGING =
  import.meta.env.VITE_IS_STAGING &&
  import.meta.env.VITE_IS_STAGING.toLowerCase() === 'true'

export const IS_PUBLIC_TESTNET =
  import.meta.env.VITE_IS_PUBLIC_TESTNET &&
  import.meta.env.VITE_IS_PUBLIC_TESTNET.toLowerCase() === 'true'

export const CURRENT_NETWORK: SupportedNetworks =
  (import.meta.env.VITE_CURRENT_NETWORK as SupportedNetworks) ||
  SupportedNetworks.ETHEREUM

export const API_ENDPOINTS = IS_TESTNET
  ? IS_PUBLIC_TESTNET
    ? { [SupportedNetworks.SEPOLIA]: SEPOLIA_CONFIG }
    : IS_STAGING
    ? {
        [SupportedNetworks.ETHEREUM]: ETHEREUM_STAGING_CONFIG,
        [SupportedNetworks.ARBITRUM]: ARBITRUM_STAGING_CONFIG,
      }
    : {
        [SupportedNetworks.ETHEREUM]: ETHEREUM_TENDERLY_CONFIG,
        [SupportedNetworks.ARBITRUM]: ARBITRUM_TENDERLY_CONFIG,
      }
  : {
      [SupportedNetworks.ETHEREUM]: ETHEREUM_CONFIG,
      [SupportedNetworks.ARBITRUM]: ARBITRUM_CONFIG,
    }

export const FLUSHES = IS_TESTNET
  ? IS_STAGING
    ? {
        [SupportedNetworks.ETHEREUM]: ETHEREUM_TENDERLY_FLUSHES,
        [SupportedNetworks.ARBITRUM]: ETHEREUM_TENDERLY_FLUSHES,
      }
    : {
        [SupportedNetworks.ETHEREUM]: ETHEREUM_TENDERLY_FLUSHES,
        [SupportedNetworks.ARBITRUM]: ETHEREUM_TENDERLY_FLUSHES,
      }
  : {
      [SupportedNetworks.ETHEREUM]: ETHEREUM_TENDERLY_FLUSHES,
      [SupportedNetworks.ARBITRUM]: ETHEREUM_TENDERLY_FLUSHES,
    }

export const CONFIG_FEATURES = IS_TESTNET
  ? IS_PUBLIC_TESTNET
    ? { [SupportedNetworks.SEPOLIA]: ETHEREUM_FEATURES }
    : IS_STAGING
    ? {
        [SupportedNetworks.ETHEREUM]: ETHEREUM_FEATURES,
        [SupportedNetworks.ARBITRUM]: ETHEREUM_FEATURES,
      }
    : {
        [SupportedNetworks.ETHEREUM]: ETHEREUM_TENDERLY_FEATURES,
        [SupportedNetworks.ARBITRUM]: ETHEREUM_TENDERLY_FEATURES,
      }
  : {
      [SupportedNetworks.ETHEREUM]: ETHEREUM_FEATURES,
      [SupportedNetworks.ARBITRUM]: ETHEREUM_FEATURES,
    }

export const ALLOCATION_PROVIDERS = IS_TESTNET
  ? IS_PUBLIC_TESTNET
    ? { [SupportedNetworks.SEPOLIA]: ALLOCATION_PROVIDERS_SEPOLIA }
    : IS_STAGING
    ? {
        [SupportedNetworks.ETHEREUM]: ALLOCATION_PROVIDERS_ETHEREUM,
        [SupportedNetworks.ARBITRUM]: ALLOCATION_PROVIDERS_ARBITRUM,
      }
    : {
        [SupportedNetworks.ETHEREUM]: ALLOCATION_PROVIDERS_ETHEREUM_TENDERLY,
        [SupportedNetworks.ARBITRUM]: ALLOCATION_PROVIDERS_ARBITRUM_TENDERLY,
      }
  : {
      [SupportedNetworks.ETHEREUM]: ALLOCATION_PROVIDERS_ETHEREUM,
      [SupportedNetworks.ARBITRUM]: ALLOCATION_PROVIDERS_ARBITRUM,
    }

export const RISK_PROVIDERS = IS_TESTNET
  ? IS_PUBLIC_TESTNET
    ? {
        [SupportedNetworks.SEPOLIA]:
          '0x2f75193ebef14541266696cd87dd84ff90c02b5c',
      }
    : IS_STAGING
    ? {
        [SupportedNetworks.ETHEREUM]:
          '0xc216ad6280f4fa92a5159ef383a1206d432481c8',
        [SupportedNetworks.ARBITRUM]:
          '0xedee7b1c9a240c4085a5fcac8f4fa750e3779695',
      }
    : {
        [SupportedNetworks.ETHEREUM]:
          '0x2f75193ebef14541266696cd87dd84ff90c02b5c',
        [SupportedNetworks.ARBITRUM]:
          '0x2f75193ebef14541266696cd87dd84ff90c02b5c',
      }
  : {
      [SupportedNetworks.ETHEREUM]:
        '0xc216ad6280f4fa92a5159ef383a1206d432481c8',
      [SupportedNetworks.ARBITRUM]:
        '0xedee7b1c9a240c4085a5fcac8f4fa750e3779695',
    }

export const FEATURES = (chain: SupportedNetworks) => CONFIG_FEATURES[chain]
