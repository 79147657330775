import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'
import useClaimIncentivizedRewards from '@/hooks/vaultList/Transactions/useClaimIncentivizedRewards'

import StyledActionButton from '@/components/atoms/StyledActionButton'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { formatUSD } from '@/utils/formats'

const ClaimLayout = () => {
  const { chain } = useConnectionState()

  const t = useLocale()

  const { smartVaultData } = useVaultsPage()

  const { incentivizedRewards, address } = smartVaultData

  const hasRewards = useMemo(
    () => !!Number(smartVaultData.incentivizedRewards.totalClaimableAmountUSD),
    [smartVaultData]
  )

  const claimIncentivizedRewards = useClaimIncentivizedRewards(address)

  return (
    <Box>
      {hasRewards && (
        <Box padding='1rem' border='1px solid #2123221F' borderRadius='4px'>
          <Typography textAlign='center' marginBottom='1.5rem'>
            {t(
              'components.organisms.smartVaults.collapsibleRow.vaultActionButtons.actionButtons.incentives.title'
            )}
          </Typography>
          <Box>
            {incentivizedRewards.rewards
              .filter(({ claimableAmountUSD }) => !!Number(claimableAmountUSD))
              .map(({ asset, claimableAmount, claimableAmountUSD }) => (
                <Box
                  key={asset.address}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  mb='0.5rem'
                >
                  <Box>
                    <Typography>{formatUSD(claimableAmount)}</Typography>
                    <Typography fontSize='12px' color='text.secondary'>
                      ~${formatUSD(claimableAmountUSD)}
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Typography>{asset.symbol}</Typography>
                    <Box
                      ml='0.2rem'
                      display='flex'
                      alignItems='center'
                      sx={{ transform: 'scale(1.3)' }}
                    >
                      {mapAssetTokenIcon(chain, asset.address)}
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
          <StyledActionButton
            variant='outlined'
            size='large'
            fullWidth
            sx={{
              bgcolor: 'white',
              mt: '1rem',
              '&:hover': {
                background: 'white',
              },
            }}
            onClick={() => claimIncentivizedRewards()}
          >
            {t(
              'components.organisms.smartVaults.collapsibleRow.vaultActionButtons.actionButtons.incentives.cta'
            )}
          </StyledActionButton>
        </Box>
      )}
    </Box>
  )
}

export default ClaimLayout
