import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'

import CustomButton from '@/components/atoms/CustomButton'

import { STEP_SEQUENCE } from '@/store/createVault/createVault.state'

import { FEATURES } from '@/config/sdk'

const AdvancedNavigation: React.FC<{
  isAdvanced: boolean
  setIsAdvanced: (isAdvanced: boolean) => void
}> = ({ isAdvanced, setIsAdvanced }) => {
  const { chain } = useConnectionState()

  const { currentStep, getStepData, setCurrentStep } = useCreateVaultState()
  const { isUnavailableProvider } = useCreateVaultState()

  const showBtn =
    currentStep >= STEP_SEQUENCE.ALLOCATIONS &&
    currentStep !== STEP_SEQUENCE.GUARDS &&
    currentStep === STEP_SEQUENCE.ALLOCATIONS &&
    !isUnavailableProvider

  const handleAdvancedNavigation = () => {
    if (!isAdvanced && currentStep >= STEP_SEQUENCE.ALLOCATIONS) {
      setCurrentStep(STEP_SEQUENCE.GUARDS)
      setIsAdvanced(true)
    } else if (isAdvanced && currentStep === STEP_SEQUENCE.ALLOCATIONS) {
      setCurrentStep(STEP_SEQUENCE.GUARDS)
    } else if (isAdvanced && currentStep === STEP_SEQUENCE.GUARDS) {
      setCurrentStep(STEP_SEQUENCE.ACTIONS)
    } else if (isAdvanced && currentStep === STEP_SEQUENCE.ACTIONS) {
      setCurrentStep(STEP_SEQUENCE.GUARDS)
    } else if (isAdvanced && currentStep === STEP_SEQUENCE.LAUNCH) {
      setCurrentStep(STEP_SEQUENCE.GUARDS)
    }
    window.scrollTo(0, 0)
  }

  const getButtonLabel = () => {
    if (
      (!isAdvanced && currentStep >= STEP_SEQUENCE.ALLOCATIONS) ||
      (isAdvanced && currentStep <= STEP_SEQUENCE.ALLOCATIONS)
    )
      return 'Set Up Conditions'
    if (isAdvanced && currentStep === STEP_SEQUENCE.GUARDS)
      return getStepData(STEP_SEQUENCE.GUARDS).advBtn.label
    if (isAdvanced && currentStep === STEP_SEQUENCE.ACTIONS)
      return getStepData(STEP_SEQUENCE.ACTIONS).advBtn.label
    if (isAdvanced && currentStep === STEP_SEQUENCE.LAUNCH)
      return getStepData(STEP_SEQUENCE.ACTIONS).advBtn.label
    return 'Back'
  }

  return (
    showBtn &&
    FEATURES(chain).CREATE_VAULT_ADVANCED && (
      <CustomButton
        variant='outlined'
        sx={{
          my: (theme) => theme.spacing(2),
          gap: (theme) => theme.spacing(1),
        }}
        onClick={handleAdvancedNavigation}
      >
        <ExitToAppIcon />
        {getButtonLabel()}
      </CustomButton>
    )
  )
}

export default AdvancedNavigation
