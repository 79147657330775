import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'

import TextTagRenderer from '@/components/atoms/TextTagRenderer'

import { WithdrawMode } from '@/constants/smartVault'

interface WithdrawOptions {
  mode: WithdrawMode
  setMode: React.Dispatch<React.SetStateAction<WithdrawMode>>
  readMore: boolean
  setReadMore: React.Dispatch<React.SetStateAction<boolean>>
}

const WithdrawOptions: React.FC<WithdrawOptions> = ({
  setMode,
  readMore,
  setReadMore,
}) => {
  const t = useLocale()
  const { chain } = useConnectionState()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode(event.target.value as WithdrawMode)
  }

  const WITHDRAW_MODE = [
    {
      mode: WithdrawMode.STANDARD,
      title: t('pages.smartVaults.vaultDetailsPage.slowWithdraw.title'),
      description: t(
        'pages.smartVaults.vaultDetailsPage.slowWithdraw.description'
      ),
      additionalDescription: {
        description1: t(
          'pages.smartVaults.vaultDetailsPage.slowWithdraw.additionalDescription.first'
        ),
        description2: t(
          'pages.smartVaults.vaultDetailsPage.slowWithdraw.additionalDescription.second'
        ),
        description3: t(
          'pages.smartVaults.vaultDetailsPage.slowWithdraw.additionalDescription.third'
        ),
      },
      isRecommended: chain === SupportedNetworks.ETHEREUM,
    },
    {
      mode: WithdrawMode.FAST,
      title: t('pages.smartVaults.vaultDetailsPage.fastWithdraw.title'),
      description: t(
        'pages.smartVaults.vaultDetailsPage.fastWithdraw.description'
      ),
      isRecommended: chain !== SupportedNetworks.ETHEREUM,
    },
  ]

  return (
    <RadioGroup
      sx={{ marginTop: '1rem', gap: '0.5  rem' }}
      defaultValue={
        chain === SupportedNetworks.ETHEREUM
          ? WithdrawMode.STANDARD
          : WithdrawMode.FAST
      }
      onChange={handleChange}
    >
      {(chain !== SupportedNetworks.ETHEREUM
        ? WITHDRAW_MODE.reverse()
        : WITHDRAW_MODE
      ).map(
        (
          { mode, title, description, isRecommended, additionalDescription },
          index
        ) => {
          // if (IS_PUBLIC_TESTNET && mode === WithdrawMode.FAST) return

          return (
            <FormControlLabel
              sx={{ display: 'flex', alignItems: 'start' }}
              key={index}
              value={mode}
              control={<Radio />}
              label={
                <Box marginTop='0.5rem'>
                  <Box display='flex' alignItems='center'>
                    <Typography fontSize='14px'>{title}</Typography>
                    {isRecommended && (
                      <Typography
                        ml='0.5rem'
                        borderRadius='5px'
                        px='0.5rem'
                        fontSize='12px'
                        bgcolor='#C8E6C9'
                      >
                        Recommended
                      </Typography>
                    )}
                  </Box>
                  <Box display='flex'>
                    <Typography color='text.secondary' fontSize='12px'>
                      {description}
                    </Typography>
                    &nbsp;
                    <Typography
                      color='text.secondary'
                      fontSize='12px'
                      sx={{ textDecoration: 'underline' }}
                      onClick={() => setReadMore((value) => !value)}
                    >
                      {additionalDescription && !readMore && 'Read more'}
                    </Typography>
                  </Box>
                  {additionalDescription && (
                    <Box
                      sx={{
                        height: '115px',
                        transition: 'max-height 0.25s ease-in',
                        maxHeight: `${readMore ? '115px' : '0px'}`,
                        overflow: 'hidden',
                      }}
                    >
                      <List
                        sx={{
                          listStyleType: 'disc',
                          paddingLeft: (theme) => theme.spacing(3),
                        }}
                      >
                        {Object.values(additionalDescription).map(
                          (description, index) => (
                            <ListItem
                              key={index}
                              sx={{
                                display: 'list-item',
                                padding: 0,
                                fontSize: '12px',
                                color: 'text.secondary',
                              }}
                            >
                              <TextTagRenderer inputString={description} />
                            </ListItem>
                          )
                        )}
                      </List>
                      <Typography
                        color='text.secondary'
                        fontSize='12px'
                        sx={{ textDecoration: 'underline' }}
                        my='0.5rem'
                        ml='1.5rem'
                        onClick={() => setReadMore((value) => !value)}
                      >
                        Read less
                      </Typography>
                    </Box>
                  )}
                </Box>
              }
            />
          )
        }
      )}
    </RadioGroup>
  )
}

export default WithdrawOptions
