import { Box, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

interface VaultFeatureGroupProps extends PropsWithChildren {
  title: string
}

const VaultFeatureGroup: React.FC<VaultFeatureGroupProps> = ({
  title,
  children,
}) => (
  <Box
    sx={{
      '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
    }}
  >
    <Typography
      padding={(theme): string => theme.spacing(2.5, 2, 0.5, 2)}
      variant='h6'
    >
      {title}
    </Typography>
    {children}
  </Box>
)

export default VaultFeatureGroup
