import { Box } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useMetavaultsPage from '@/hooks/smartVaults/useMetavaultPage'

//import useMetavaultsPage from "@/hooks/smartVaults/useMetavaultPage";
import DashboardMetricsField from '@/components/molecules/DashboardMetricsField'
import SectionColumn from '@/components/molecules/SectionColumn'

import { formatUSD } from '@/utils/formats'
import {
  getMVVaultsDHWExecutedState,
  getMVVaultsFlushExecutedState,
} from '@/utils/web3/flushes'

const MetavaultMetrics = () => {
  const t = useLocale()

  const { belowTabletBreakpoint } = useBreakpointDetection()

  const { metavaultData } = useMetavaultsPage()

  const {
    totalPendingDepositAssetAmount,
    totalPendingWithdrawalAssetAmount,
    vaults,
  } = metavaultData

  return (
    <Box display='flex' gap={2}>
      <SectionColumn
        noVerticalGap
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
          background: '#ffffff',
          flex: 1,
          paddingX: '1.5rem',
          padding: belowTabletBreakpoint ? '1.5rem' : '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: belowTabletBreakpoint ? 'column' : 'row',
            gap: belowTabletBreakpoint ? '1rem' : '',
            textAlign: 'right',
          }}
        >
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.metavaults.metavaultMetrics.pendingDeposits'
            )}
            //TODO: use useMetavaultsPage() to get pending deposits
            value={`${formatUSD(totalPendingDepositAssetAmount)} ${
              vaults[0] && vaults[0].assetGroup.assetGroupTokens[0].symbol
            }`}
          />
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.metavaults.metavaultMetrics.pendingWithdrawals'
            )}
            //TODO: use useMetavaultsPage() to get pending withdrawals
            value={`${formatUSD(totalPendingWithdrawalAssetAmount)} ${
              vaults[0] && vaults[0].assetGroup.assetGroupTokens[0].symbol
            }`}
          />

          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.metavaults.metavaultMetrics.flushState'
            )}
            //TODO: use useMetavaultsPage() to get flush state
            value={`${getMVVaultsFlushExecutedState(
              vaults.map(({ smartVaultFlushes }) => smartVaultFlushes)
            )}`}
          />
          <DashboardMetricsField
            title={t(
              'components.organisms.dashboard.creator.metavaults.metavaultMetrics.reallocationState'
            )}
            //TODO: use useMetavaultsPage() to get reallocation state
            value={`${getMVVaultsDHWExecutedState(
              vaults.map(({ smartVaultFlushes }) => smartVaultFlushes)
            )}`}
          />
        </Box>
      </SectionColumn>
    </Box>
  )
}

export default MetavaultMetrics
