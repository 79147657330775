import { Box, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import DetailsRowWithLink from '@/components/atoms/DetailsRowWithLink'
import DetailsRow from '@/components/molecules/DetailsRow'
import SectionColumn from '@/components/molecules/SectionColumn'
import { StyledGridItem } from '@/components/molecules/StyledGridItem'

import Verified from '@/assets/icons/general/Verified'

import { CHAIN_EXPLORER } from '@/constants/web3'
import { ellipsisText } from '@/utils/formats'

const VaultOwnerDetails = () => {
  const t = useLocale()
  const { chain } = useConnectionState()

  const { smartVaultData } = useVaultsPage()
  const { address, owner, twitter, website, createdBy } = smartVaultData
  // ARB
  const explorerURL = CHAIN_EXPLORER[chain].url + 'address/'

  return (
    <SectionColumn
      sx={{
        padding: '0',
      }}
      noVerticalGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.vaultOwner'
      )}
    >
      <StyledGridItem>
        {createdBy && (
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.managedBy.label'
            )}
          >
            <Box display='flex' gap='0.3rem' alignItems='center'>
              <Typography fontSize='14px'>{createdBy}</Typography>
              <Verified />
            </Box>
          </DetailsRow>
        )}
        <DetailsRowWithLink
          link={explorerURL + owner}
          text={ellipsisText(owner, 5, 5)}
          rowTitle={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedLinksGrid.ownerAddress.label'
          )}
        />
        {twitter && (
          <DetailsRowWithLink
            link={twitter}
            text='Link'
            rowTitle={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.twitterAnnouncement.label'
            )}
          />
        )}
        {website && (
          <DetailsRowWithLink
            link={website}
            text='Link'
            rowTitle={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.ownerWebsite.label'
            )}
          />
        )}
        <DetailsRowWithLink
          link={explorerURL + address}
          text={ellipsisText(address, 5, 5)}
          rowTitle={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedSmartVaultGrid.vaultAddress.label'
          )}
        />
      </StyledGridItem>
    </SectionColumn>
  )
}

export default VaultOwnerDetails
