import { OneInchQuoteRes } from '@solidant/spool-v2-fe-lib'
import { formatUnits } from 'ethers/lib/utils'

import { Deposit } from '@/store/deposit/deposit.types'

import { formatUSD } from '@/utils/formats'

export const parseConversionText = (
  singleDeposit: Deposit,
  depositTokenSymbol: string,
  swappedToken: OneInchQuoteRes,
  underlyingTokenDecimals: number,
  underlyingTokenSymbol: string,
  isWrap: boolean
) => {
  const action = isWrap ? 'Wrap ' : 'Swap '

  const depositTokenAmount = `${
    singleDeposit && singleDeposit.amount ? singleDeposit.amount : '0.0'
  }`

  const depositTokenString = `${formatUSD(
    depositTokenAmount,
    2
  )} ${depositTokenSymbol} `

  const underlyingTokenString = `${
    isWrap
      ? formatUSD(depositTokenAmount, 2)
      : swappedToken && swappedToken.dstAmount
      ? formatUSD(
          formatUnits(swappedToken.dstAmount, underlyingTokenDecimals),
          2
        )
      : '0.0'
  } ${underlyingTokenSymbol}`

  return action + depositTokenString + 'for ' + underlyingTokenString
}
