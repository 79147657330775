import { useContext } from 'react'

import { NavigationContext } from '@/store/navigation/navigation.context'

const useUniversalNavigation = () => {
  const context = useContext(NavigationContext)

  if (!context) {
    throw new Error(
      'useUniversalNavigation must be used within a NavigationProvider'
    )
  }

  return context
}

export default useUniversalNavigation
