import { IncentivizeVault } from '@solidant/spool-v2-fe-lib'

import { IncentivesPage } from '@/constants/incentives'

import { IncentivesToken } from '@/types/incentives'
import { SortType } from '@/types/table'

export interface IncentiviseVaultState {
  tablePage: number
  tableRowsPerPage: number
  tableSortType: SortType<IncentivizeVault>
  openId: string | undefined
  page: IncentivesPage | undefined
  targetToken: string | undefined
  tokenAmount: string
  days: string
  isCustomToken: boolean
  tokenTableSortType: SortType<IncentivesToken>
  customTokenAddress: string
}

export interface IncentiviseVaultContextProps extends IncentiviseVaultState {
  setTablePage: (page: number) => void
  setTableRowPerPage: (page: number) => void
  setTableSortType: (tableSortType: SortType<IncentivizeVault>) => void
  handleSortClick: (
    key: keyof IncentivizeVault,
    depositReceiptSort: SortType<IncentivizeVault>
  ) => void
  setOpenId: (openId: string | undefined) => void
  setPage: (page: IncentivesPage | undefined) => void
  setTargetToken: (targetToken: string | undefined) => void
  setTokenAmount: (tokenAmount: string) => void
  setDays: (days: string) => void
  setIsCustomToken: (isCustomToken: boolean) => void
  setTokenTableSortType: (tokenTableSortType: SortType<IncentivesToken>) => void
  handleTokenTableSortClick: (
    key: keyof IncentivesToken,
    sortType: SortType<IncentivesToken>
  ) => void
  setCustomTokenAddress: (customTokenAddress: string) => void
}

export enum IncentivizeVaultActionTypes {
  SET_TABLE_PAGE,
  SET_TABLE_ROW_PER_PAGE,
  SET_TABLE_SORT_TYPE,
  SET_OPEN_ID,
  SET_PAGE,
  SET_TARGET_TOKEN,
  SET_TOKEN_AMOUNT,
  SET_DAYS,
  SET_IS_CUSTOM_TOKEN,
  SET_TOKEN_TABLE_SORT_TYPE,
  SET_CUSTOM_TOKEN_ADDRESS,
}

export type IncentivizeVaultAction =
  | {
      type: IncentivizeVaultActionTypes.SET_TABLE_PAGE
      payload: number
    }
  | {
      type: IncentivizeVaultActionTypes.SET_TABLE_ROW_PER_PAGE
      payload: number
    }
  | {
      type: IncentivizeVaultActionTypes.SET_TABLE_SORT_TYPE
      payload: SortType<IncentivizeVault>
    }
  | {
      type: IncentivizeVaultActionTypes.SET_OPEN_ID
      payload: string | undefined
    }
  | {
      type: IncentivizeVaultActionTypes.SET_PAGE
      payload: IncentivesPage | undefined
    }
  | {
      type: IncentivizeVaultActionTypes.SET_TARGET_TOKEN
      payload: string | undefined
    }
  | {
      type: IncentivizeVaultActionTypes.SET_TOKEN_AMOUNT
      payload: string
    }
  | {
      type: IncentivizeVaultActionTypes.SET_DAYS
      payload: string
    }
  | {
      type: IncentivizeVaultActionTypes.SET_IS_CUSTOM_TOKEN
      payload: boolean
    }
  | {
      type: IncentivizeVaultActionTypes.SET_TOKEN_TABLE_SORT_TYPE
      payload: SortType<IncentivesToken>
    }
  | {
      type: IncentivizeVaultActionTypes.SET_CUSTOM_TOKEN_ADDRESS
      payload: string
    }
