import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { TableCell, TableRow } from '@mui/material'
import Box from '@mui/material/Box'
import { GuardConditions, GuardType } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { memo } from 'react'

import ConditionConstructor from '@/components/organisms/SmartVaults/CollapsibleRow/DepositConditions/ConditionConstructor'

interface GuardConditionsTableRowProps {
  guardConditions: GuardConditions
}

const GuardConditionsTableRow: React.FC<GuardConditionsTableRowProps> = ({
  guardConditions,
}) => {
  const { guardType } = guardConditions

  const { account } = useWeb3React()

  const isFulfillableGuard =
    guardType === GuardType.NftGuard ||
    guardType === GuardType.AssetGuard ||
    guardType === GuardType.WhitelistGuard

  return (
    <TableRow sx={{ backgroundColor: '#fff' }}>
      <TableCell
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box display='flex' gap={2}>
          •
          <Box
            whiteSpace='normal'
            display='grid'
            alignItems='left'
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            <ConditionConstructor guardConditions={guardConditions} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align='center'>
        {account ? (
          isFulfillableGuard &&
          'isFulfilled' in guardConditions &&
          guardConditions?.isFulfilled ? (
            <CheckCircleIcon color='primary' />
          ) : (
            <CancelIcon color='primary' />
          )
        ) : (
          '-'
        )}
      </TableCell>
    </TableRow>
  )
}

export default memo(GuardConditionsTableRow)
