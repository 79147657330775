import { formatUnits } from '@ethersproject/units'
import { Box, Typography } from '@mui/material'
import { memo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import usePriceFeed from '@/hooks/web3/usePriceFeed'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import { mapIcons } from '@/components/atoms/CryptoIcons'
import { SpoolSelectCoinIcon } from '@/components/atoms/SpoolSelectCoinIcon'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { formatUSD } from '@/utils/formats'

interface AssetMenuRenderItemProps {
  assetId: string
}

const AssetMenuRenderItem: React.FC<AssetMenuRenderItemProps> = ({
  assetId,
}) => {
  const { chain } = useConnectionState()

  const { priceFeed } = usePriceFeed(assetId)

  const { balance } = useUserBalance(assetId)

  const { decimals, symbol } = useTokenDetails(assetId)

  const isNative = assetId === 'native'

  const tokenBalance =
    balance && !balance.isZero() && decimals
      ? formatUnits(balance, decimals)
      : 0.0

  const usdBalance =
    tokenBalance && priceFeed && !isNaN(Number(priceFeed))
      ? Number(tokenBalance) * Number(priceFeed)
      : 0.0

  return (
    <Box
      display='flex'
      width='100%'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box display='flex' alignItems='center'>
        <SpoolSelectCoinIcon disableRipple>
          <Box sx={{ transform: 1.5 }}>
            {!isNative ? mapAssetTokenIcon(chain, assetId) : mapIcons(chain)}
          </Box>
        </SpoolSelectCoinIcon>
        <Typography
          variant='body2'
          sx={{ marginTop: 'auto', marginLeft: '2px' }}
        >
          {balance && symbol ? `$${symbol}` : ''}
        </Typography>
        <Typography
          variant='caption'
          sx={{
            color: 'text.secondary',
            marginTop: 'auto',
            overflow: 'hidden',
          }}
        >
          ~${formatUSD(usdBalance, 2)}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant='caption'
          sx={{
            color: 'text.secondary',
            marginTop: 'auto',
            overflow: 'hidden',
          }}
        >
          {formatUSD(formatUnits(balance, decimals), 2)}
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(AssetMenuRenderItem)
