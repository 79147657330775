const ZeroDataPlaceholder = () => {
  return (
    <svg
      width='121'
      height='104'
      viewBox='0 0 121 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.5'
        y='1'
        width='118'
        height='26'
        rx='7'
        stroke='#212322'
        strokeOpacity='0.12'
        strokeWidth='2'
        strokeDasharray='6 6'
      />
      <circle cx='16.5' cy='14' r='8' fill='#E0E0E0' />
      <rect x='92.5' y='10' width='20' height='8' rx='4' fill='#E0E0E0' />
      <rect
        x='1.5'
        y='35'
        width='118'
        height='30'
        rx='7'
        stroke='#212322'
        strokeOpacity='0.12'
        strokeWidth='2'
        strokeDasharray='6 6'
      />
      <circle cx='16.5' cy='50' r='8' fill='#E0E0E0' />
      <rect x='92.5' y='46' width='20' height='8' rx='4' fill='#E0E0E0' />
      <rect
        x='1.5'
        y='73'
        width='118'
        height='30'
        rx='7'
        stroke='#212322'
        strokeOpacity='0.12'
        strokeWidth='2'
        strokeDasharray='6 6'
      />
      <circle cx='16.5' cy='88' r='8' fill='#E0E0E0' />
      <rect x='92.5' y='84' width='20' height='8' rx='4' fill='#E0E0E0' />
    </svg>
  )
}

export default ZeroDataPlaceholder
