import { Box, TableBody, TableCell, TableRow, TextField } from '@mui/material'
import Switch from '@mui/material/Switch'

import useLocale from '@/hooks/context/useLocale'

import TableWrapper from '@/components/molecules/TableWrapper'

import { SVTHeadCells } from '@/constants/createVault'

interface SVTTableProps {
  isChecked: boolean
  setIsChecked: (isChecked: boolean) => void
  timePeriod: number
  setTimePeriod: (timePeriod: number) => void
  disabled: boolean
  isTimePeriod?: boolean
  editConfigurationMode: boolean
}

const SVTTable: React.FC<SVTTableProps> = ({
  isChecked,
  setIsChecked,
  timePeriod,
  setTimePeriod,
  disabled,
  isTimePeriod,
  editConfigurationMode,
}) => {
  const t = useLocale()
  return (
    <Box width='100%' border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='4px'>
      <TableWrapper header={SVTHeadCells} headerClass='default-table-head'>
        <TableBody>
          <TableRow
            sx={{
              backgroundColor: '#fff',
              padding: (theme) => theme.spacing(2),
            }}
          >
            <TableCell>1</TableCell>
            <TableCell>
              {isTimePeriod
                ? t(
                    'components.organisms.createVault.guards.modals.SVTModal.svtTable.holdingPeriod'
                  )
                : t(
                    'components.organisms.createVault.guards.modals.SVTModal.svtTable.smartVaultToken'
                  )}
            </TableCell>
            <TableCell align='center'>
              {isTimePeriod ? (
                <TextField
                  size='small'
                  variant='outlined'
                  label='In days'
                  onChange={(event) =>
                    Number(event.target.value) &&
                    setTimePeriod(Number(event.target.value))
                  }
                  value={timePeriod}
                  sx={{
                    width: '180px',
                    '& .MuiInputBase-root': {
                      fontWeight: 500,
                      '& .Mui-disabled': {
                        color: 'text.primary',
                        WebkitTextFillColor: 'unset',
                      },
                    },
                  }}
                  disabled={!editConfigurationMode}
                />
              ) : (
                <Switch
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  disabled={disabled}
                  sx={{
                    '&.MuiSwitch-root': {
                      '.Mui-disabled': {
                        color: 'text.primary',
                      },
                      '.MuiSwitch-track': {
                        backgroundColor: disabled ? 'text.primary' : undefined,
                        opacity: disabled ? 0.5 : undefined,
                      },
                    },
                  }}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </TableWrapper>
    </Box>
  )
}

export default SVTTable
