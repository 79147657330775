import { useEffect, useRef, useState } from 'react'
import { Location, useLocation } from 'react-router-dom'
// This hook is used to track the current path from history or react router and does not cause rerenders

const useSpoolLocation = (): Location => {
  const location = useLocation()
  const prevPathRef = useRef<string>(location.pathname)

  if (!location) {
    new Error(
      'The useSpoolLocation must be inside the React Router component tree.'
    )
  }

  const [path, setPath] = useState(location.pathname)

  // Tracking path when changed by history
  useEffect(() => {
    const handleUrlChange = () => {
      const newPath = window.location.pathname

      if (newPath !== prevPathRef.current) {
        setPath(newPath)

        prevPathRef.current = newPath
      }
    }

    window.addEventListener('urlchange', handleUrlChange)

    return () => {
      window.removeEventListener('urlchange', handleUrlChange)
    }
  }, [])

  // Tracking path when changed by react router
  useEffect(() => {
    const newPath = window.location.pathname
    if (newPath !== prevPathRef.current) {
      setPath(newPath)

      prevPathRef.current = newPath
    }
  }, [location])

  return {
    ...location,
    pathname: path,
  }
}

export default useSpoolLocation
