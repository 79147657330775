const DAI = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='25' cy='25' r='18' fill='white' />
      <path
        d='M33.7536 26.4827H17.9512C17.7916 26.4827 17.7129 26.4827 17.6742 26.4434C17.6365 26.4052 17.6365 26.3298 17.6365 26.1813V23.8452C17.6365 23.6609 17.6894 23.5957 17.886 23.5957H33.7669C33.9379 23.5957 34.0163 23.6609 34.0163 23.8187C34.0815 24.6314 34.0815 25.4479 34.0163 26.2597C34.0041 26.4827 33.9237 26.4827 33.7536 26.4827Z'
        fill='#F5AC37'
      />
      <path
        d='M33.1252 20.6822C33.1479 20.7408 33.1479 20.806 33.1252 20.8656H33.1885C33.162 20.944 33.0307 20.9705 33.0307 20.9705H17.885C17.6355 20.9705 17.6355 20.9175 17.6355 20.721V16.0621C17.6355 15.891 17.662 15.8126 17.8586 15.8126H24.9064C25.6568 15.806 26.4052 15.8844 27.1376 16.0489C28.5891 16.3928 29.9396 17.077 31.0755 18.0438C31.3023 18.2129 31.5045 18.4123 31.6793 18.6344C32.0498 19.002 32.3749 19.4112 32.6508 19.8554C32.8341 20.1152 32.9919 20.3921 33.1252 20.6822Z'
        fill='#F5AC37'
      />
      <path
        d='M32.4542 29.2128C32.6773 29.1883 32.9022 29.1883 33.1252 29.2128V29.2147C33.2282 29.2667 33.1365 29.4652 33.1365 29.4652C32.3295 31.0452 31.05 32.3323 29.4746 33.1516H29.383C29.0796 33.3321 28.7583 33.4824 28.4257 33.5977C27.5109 33.9464 26.5517 34.1581 25.5765 34.228C25.2618 34.2951 24.9405 34.3178 24.6192 34.2932H17.8992C17.6365 34.2932 17.6365 34.2403 17.6365 34.0305V29.4756C17.6365 29.2128 17.7026 29.2128 17.8992 29.2128H32.4542Z'
        fill='#F5AC37'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46 25C46 13.4028 36.599 4 25 4C13.4028 4 4 13.4028 4 25C4 36.5981 13.4028 46 25 46C36.599 46 46 36.599 46 25ZM14.999 36.6548L14.9952 36.6576V36.6709H24.1041C25.1351 36.685 26.1624 36.6028 27.1754 36.4214C28.2423 36.2125 29.2818 35.8837 30.2732 35.4376C30.703 35.2385 31.1154 34.9979 31.5315 34.7552C31.6368 34.6937 31.7423 34.6321 31.8485 34.571C32.338 34.2223 32.8067 33.8443 33.2528 33.4427C34.4737 32.3389 35.4187 30.9621 36.0094 29.4264C36.0491 29.2544 36.2173 29.1439 36.3902 29.1769H38.8709C39.0684 29.1769 39.1336 29.1108 39.1336 28.8878V27.2992C39.1468 27.1471 39.1468 26.993 39.1336 26.8409C39.1336 26.8011 39.1438 26.7615 39.154 26.7219C39.1743 26.6429 39.1946 26.5642 39.1336 26.4855H37.0612C36.8107 26.4855 36.8107 26.4591 36.8107 26.2361C36.8826 25.4366 36.8826 24.6343 36.8107 23.8348C36.7975 23.5985 36.8504 23.5985 37.0338 23.5985H38.8453C39.0561 23.5985 39.1345 23.5456 39.1345 23.3358V21.1831C39.1213 20.8939 39.1213 20.8939 38.8198 20.8939H36.4705C36.2872 20.9251 36.1114 20.8013 36.0784 20.618C35.809 19.9139 35.466 19.2402 35.053 18.6098C34.6372 17.988 34.1685 17.404 33.6487 16.8644C32.9579 16.1745 32.1821 15.5754 31.3391 15.0793C30.0681 14.3412 28.678 13.8243 27.2312 13.5569C26.529 13.4274 25.8193 13.349 25.1049 13.3206H15.2617C14.999 13.3206 14.999 13.3735 14.999 13.5833V20.6577C14.999 20.9072 14.946 20.9072 14.7495 20.9072H11.9277C11.7179 20.9072 11.7179 20.9459 11.7179 21.0905V23.4001C11.7179 23.6099 11.7831 23.6099 11.9409 23.6099H14.7892C14.999 23.6099 14.999 23.6486 14.999 23.8064V26.2739C14.999 26.4969 14.9328 26.4969 14.7627 26.4969H11.7179V28.9908C11.7179 29.2006 11.7831 29.2006 11.9409 29.2006H14.7892C14.999 29.2006 14.999 29.2261 14.999 29.3971V36.6548Z'
        fill='#F5AC37'
      />
    </svg>
  )
}

export default DAI
