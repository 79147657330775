import { formatUnits, parseEther, parseUnits } from '@ethersproject/units'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { isAddress } from 'web3-validator'

import useLocale from '@/hooks/context/useLocale'
import usePriceFeed from '@/hooks/web3/usePriceFeed'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import Usd from '@/assets/icons/chainIcons/Usd'

import { DEFAULT_INPUT_AMOUNT } from '@/constants'
import { NATIVE_TOKEN, WRAPPED_TOKEN_ADDRESS } from '@/constants/tokens'
import { formatNumber, formatUSD } from '@/utils/formats'
import { getIsNativeCurrency, getPricePerAssetAmount } from '@/utils/web3'

interface TransactionInputProps {
  inputLabel: string
  tokenId?: string
  value: string
  setValue: (value: any) => void
  assetName?: string
  showAlert?: boolean
  showDollarSign?: boolean
  showInputInfoLabel?: boolean
  allocation?: number
  maxButtonWidth?: string
  isFiat?: boolean
  maxFiatValue?: string
  disabled?: boolean
  showMax?: boolean
  sx?: SxProps<Theme>
}

const TransactionInput: React.FC<TransactionInputProps> = ({
  inputLabel,
  tokenId,
  value,
  setValue,
  showInputInfoLabel,
  allocation,
  showDollarSign,
  maxButtonWidth,
  isFiat,
  maxFiatValue,
  disabled,
  showMax = true,
  sx,
}) => {
  const t = useLocale()

  const { account, chainId } = useWeb3React()

  // to get token related data if there is tokenId or if tokenId is a valid address
  const isToken = tokenId && (isAddress(tokenId) || tokenId === NATIVE_TOKEN)

  const balance = maxFiatValue
    ? parseEther(maxFiatValue)
    : useUserBalance(isToken ? tokenId : '').balance

  const { decimals } = useTokenDetails(isToken ? tokenId : '')

  // check if tokenId is a valid address, else check if it's the native currency then use the
  // price of the wrapped native currency
  const { priceFeed } = usePriceFeed(
    isToken
      ? tokenId
      : getIsNativeCurrency(tokenId)
      ? WRAPPED_TOKEN_ADDRESS[chainId]
      : ''
  )
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (!account) return

    if (
      value !== '.' &&
      +value < Number.MAX_SAFE_INTEGER &&
      value.match('^[0-9]*[.]?[0-9]*$')
    ) {
      setValue(value)
    }
  }

  return (
    <Stack flexGrow={1}>
      <Box
        display='flex'
        flexGrow='1'
        gap={(theme) => theme.spacing(1)}
        sx={{
          ...sx,
        }}
      >
        <TextField
          size='small'
          label={inputLabel}
          onChange={handleChange}
          value={value}
          placeholder={DEFAULT_INPUT_AMOUNT}
          error={
            !!value &&
            parseUnits(value, maxFiatValue ? '18' : decimals).gt(balance)
          }
          disabled={!account}
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-root': {
              backgroundColor: '#fff',
              fontWeight: 500,
            },
          }}
          InputProps={{
            startAdornment: showDollarSign && (
              <InputAdornment position='start'>
                <Usd />
              </InputAdornment>
            ),
            endAdornment: !isFiat && showInputInfoLabel && (
              <Typography
                variant='caption'
                sx={{
                  color: 'text.secondary',
                }}
              >
                $
                {maxFiatValue
                  ? maxFiatValue
                  : formatUSD(
                      getPricePerAssetAmount(
                        value ? parseUnits(value, decimals).toString() : '0',
                        priceFeed,
                        decimals
                      ),
                      2
                    )}
              </Typography>
            ),
            // endAdornment: showPercentSign && (
            //   <InputAdornment position='end'>%</InputAdornment>
            // ),
          }}
          // InputProps={{
          //   inputComponent:
          //     NumericFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
          //   error: parseInt(value) > parseInt(maxValue) && showAlert,
          //   startAdornment: showDollarSign && (
        />
        {showMax && (
          <Button
            variant='outlined'
            disabled={!account || disabled}
            onClick={() => {
              const formattedBalance = formatUnits(
                balance,
                maxFiatValue ? '18' : decimals
              )
              const [whole, decimal] = formattedBalance.split('.')
              let truncated
              if (decimal) {
                truncated = `${whole}.${decimal.slice(0, 2)}`
              } else {
                truncated = whole
              }
              setValue(truncated)
            }}
            sx={{ width: maxButtonWidth || '48px' }}
          >
            Max
          </Button>
        )}
      </Box>
      {showInputInfoLabel && (
        <Box
          padding='3px 0'
          display='flex'
          flexGrow={1}
          justifyContent='space-between'
        >
          <Box display='flex' flexDirection='column' justifyContent='center'>
            {!!value &&
              parseUnits(value, maxFiatValue ? '18' : decimals).gt(balance) && (
                <Box display='flex' alignItems='center' gap='0.2rem'>
                  <InfoOutlinedIcon
                    fontSize='small'
                    sx={{ color: 'error.main' }}
                  />
                  <Typography fontSize='12px' color='error'>
                    {t(
                      'components.molecules.transactionInputContainer.alert.message'
                    )}
                  </Typography>
                </Box>
              )}
          </Box>
          {!isNaN(allocation) && (
            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
              Allocation:{' '}
              {allocation ? `${formatNumber(allocation, 1)} %` : '...Loading'}
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  )
}

export default TransactionInput
