import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { memo, useEffect, useMemo, useReducer, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { CreateVaultContext } from '@/store/createVault/createVault.context'
import createVaultReducer from '@/store/createVault/createVault.reducer'
import { initialState } from '@/store/createVault/createVault.state'
import {
  ActionData,
  AssetAmount,
  CreateVaultActionTypes,
  Guards,
  Whitelist,
} from '@/store/createVault/createVault.types'
import { Modals } from '@/store/modal/modals.types'

import { ALLOCATION_PROVIDERS, CURRENT_NETWORK } from '@/config/sdk'
import { DEFAULT_MANAGEMENT_FEE, MAX_VAULT_NAME_LENGTH } from '@/constants'
import { DEFAULT_ALLOCATION_PROVIDER } from '@/constants/smartVault'
import { tokenSymbols } from '@/constants/tokens'
import { formatNumber } from '@/utils/formats'
import isProfane from '@/utils/helpers/isProfane'

import { StrategiesDto, StrategySortKey } from '@/types/create-vault'
import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const CreateVaultProvider = memo(({ children }: RCNode) => {
  const { chain } = useConnectionState()

  const [createVault, dispatch] = useReducer(createVaultReducer, initialState)

  const [isUnavailableProvider, setIsUnavaliableProvider] =
    useState<boolean>(true)

  const { openModal, setModalType } = useModalState()

  const t = useLocale()

  useEffect(() => {
    if (createVault.selectedAllocationProvider) {
      setIsUnavaliableProvider(
        ALLOCATION_PROVIDERS[chain][createVault.selectedAllocationProvider]
          ? !ALLOCATION_PROVIDERS[chain][createVault.selectedAllocationProvider]
              .isReady
          : false
      )
    }
  }, [createVault.selectedAllocationProvider, chain])

  const setCurrentStep = (currentStep: number) => {
    dispatch({
      type: CreateVaultActionTypes.SET_CURRENT_STEP,
      payload: currentStep,
    })
  }

  const setStepCompletedStatus = (stepNumber: number, status: boolean): void =>
    dispatch({
      type: CreateVaultActionTypes.SET_STEP_COMPLETED,
      payload: { stepNumber, status },
    })

  // 2 dispatches because we want to clear the selected strategies whenver there is a change in RiskModel.
  const setSelectedRiskModel = (selectedRiskModel: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_RISK_MODEL,
      payload: selectedRiskModel,
    })
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_STRATEGIES,
      payload: [],
    })
  }

  const setVaultName = (vaultName: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_VAULT_NAME,
      payload: vaultName,
    })
  }

  const setSvtSymbol = (svtSymbol: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SVT_SYMBOL,
      payload: svtSymbol,
    })
  }

  const setIsSvtSymbolValid = (isSvtSymbolValid: boolean) => {
    dispatch({
      type: CreateVaultActionTypes.SET_IS_SVT_SYMBOL_VALID,
      payload: isSvtSymbolValid,
    })
  }

  const setIsVaultNameValid = (isVaultNameValid: boolean) => {
    dispatch({
      type: CreateVaultActionTypes.SET_IS_VAULT_NAME_VALID,
      payload: isVaultNameValid,
    })
  }

  const setSelectedMarket = (selectedMarket: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_MARKET,
      payload: selectedMarket,
    })
  }

  const setSelectedStrategies = (selectedStrategies: StrategiesDto[]) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_STRATEGIES,
      payload: selectedStrategies,
    })
  }

  const setSelectedAssetGroupId = (selectedAssetGroupId: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_ASSET_GROUP_ID,
      payload: selectedAssetGroupId,
    })
  }

  const setSelectedPerformanceFee = (performanceFee: number) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_PERFORMANCE_FEE,
      payload: performanceFee,
    })
  }

  const setSelectedManagementFee = (managementFee: number) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_MANAGEMENT_FEE,
      payload: managementFee,
    })
  }

  const setSelectedAllocationProvider = (allocationProvider: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_ALLOCATION_PROVIDER,
      payload: allocationProvider,
    })
  }

  const setSelectedRiskScore = (riskScore: number) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_RISK_SCORE,
      payload: riskScore,
    })
  }

  const setFilterByAssetGroup = (filterByAssetGroup: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_FILTER_BY_ASSET_GROUP,
      payload: filterByAssetGroup,
    })
  }

  const setFilterBySearchTerm = (filterBySearchTerm: string) => {
    dispatch({
      type: CreateVaultActionTypes.SET_FILTER_BY_SEARCH_TERM,
      payload: filterBySearchTerm,
    })
  }

  const getStepData = (step: number) => {
    if (step === 0) return createVault.steps[0]
    return createVault.steps[step - 1]
  }

  const validateVaultName = (vaultName: string): boolean => {
    vaultName = vaultName.trim()
    if (isProfane(vaultName)) {
      openModal(Modals.WARNING_MODAL)
      setModalType({
        warningModalType: {
          message: t('store.createVault.provider.isProfane'),
        },
      })
      setIsVaultNameValid(false)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      return false
    }

    if (vaultName.length > MAX_VAULT_NAME_LENGTH) {
      openModal(Modals.WARNING_MODAL)
      setModalType({
        warningModalType: {
          message: t('store.createVault.provider.length'),
        },
      })
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setIsVaultNameValid(false)
      return false
    }
    setIsVaultNameValid(true)
    return true
  }

  const validateSvtSymbol = (svtSymbol: string): boolean => {
    svtSymbol = svtSymbol.trim()
    if (isProfane(svtSymbol)) {
      openModal(Modals.WARNING_MODAL)
      setModalType({
        warningModalType: {
          message: t('store.createVault.provider.isProfane'),
        },
      })
      setIsSvtSymbolValid(false)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      return false
    }

    if (svtSymbol.length > MAX_VAULT_NAME_LENGTH) {
      openModal(Modals.WARNING_MODAL)
      setModalType({
        warningModalType: {
          message: t('store.createVault.provider.length'),
        },
      })
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setIsSvtSymbolValid(false)
      return false
    }
    setIsSvtSymbolValid(true)
    return true
  }

  const filterStrategy = (data: StrategiesDto) => {
    const strategyNameFilter = createVault.filterBySearchTerm
      ? data.name
          .toUpperCase()
          .includes(createVault.filterBySearchTerm.toUpperCase())
      : true

    const strategyAddressFilter = createVault.filterBySearchTerm
      ? data.address
          .toUpperCase()
          .includes(createVault.filterBySearchTerm.toUpperCase())
      : true

    const searchResults = strategyNameFilter || strategyAddressFilter

    if (!createVault.filterBySearchTerm) {
      createVault.filterByAssetGroup === data.assetGroup.id
    }

    if (createVault.filterByAssetGroup === '0') {
      return searchResults
    }

    return (
      createVault.filterByAssetGroup === data.assetGroup.id && searchResults
    )
  }

  const handleSortClick = (
    key: StrategySortKey,
    sortType: SortType<StrategiesDto>
  ) => {
    if (!sortType?.key || sortType?.key !== key) {
      dispatch({
        type: CreateVaultActionTypes.SET_STRATEGY_SORT,
        payload: {
          key,
          direction: SortOrder.DESC,
        },
      })
      return
    }

    dispatch({
      type: CreateVaultActionTypes.CHANGE_STRATEGY_SORT_DIRECTION,
    })
  }

  const setGuardsWhitelist = (
    id: string,
    whitelist: Whitelist[],
    removeCondition = false,
    isNFTWhitelist = false
  ) => {
    dispatch({
      type: CreateVaultActionTypes.SET_GUARDS_WHITELIST,
      id,
      payload: {
        whitelist,
        conditions: removeCondition
          ? []
          : [
              `${
                isNFTWhitelist
                  ? t(
                      'store.createVault.provider.setGuardsWhitelist.nftCondition'
                    )
                  : t(
                      'store.createVault.provider.setGuardsWhitelist.conditions'
                    )
              } ${whitelist.map(({ address }) => address).join(', ')}`,
            ],
      },
    })
  }

  const setGuardsAssetAmount = (id: string, assetAmount: AssetAmount[]) => {
    dispatch({
      type: CreateVaultActionTypes.SET_GUARDS_ASSET_AMOUNT,
      id,
      payload: {
        assetAmount,
        conditions: assetAmount.map(
          (asset) =>
            `${tokenSymbols[asset.token]} ${t(
              'store.createVault.provider.setGuardsAssetAmount.conditions'
            )} ${asset.requirement.toLowerCase()} ${formatNumber(
              asset.value,
              2
            )}`
        ),
      },
    })
  }

  const setGuardsBool = (id: string, bool: boolean) => {
    dispatch({
      type: CreateVaultActionTypes.SET_GUARDS_BOOL,
      id,
      payload: {
        bool,
        conditions: [t('store.createVault.provider.setGuardsBool.conditions')],
      },
    })
  }

  const setGuardsTime = (id: string, time: number) => {
    dispatch({
      type: CreateVaultActionTypes.SET_GUARDS_TIME,
      id,
      payload: {
        time,
        conditions: [
          `${t(
            'store.createVault.provider.setGuardsTime.conditions.period'
          )} ${formatNumber(time)} ${t(
            'store.createVault.provider.setGuardsTime.conditions.days'
          )}`,
        ],
      },
    })
  }

  const setGuardsConfigured = (id: string, configured?: boolean) => {
    dispatch({
      type: CreateVaultActionTypes.SET_GUARDS_CONFIGURED,
      id,
      payload: configured,
    })
  }

  const setGuardsChecked = (
    id: string,
    checked: boolean,
    title: string,
    type: string
  ) => {
    dispatch({
      type: CreateVaultActionTypes.SET_GUARDS_CHECKED,
      id,
      payload: { checked, title, type },
    })
  }

  const setSelectedGuards = (selectedGuards: Guards) => {
    dispatch({
      type: CreateVaultActionTypes.SET_SELECTED_GUARDS,
      payload: selectedGuards,
    })
  }

  const setActionsChecked = (
    id: string,
    checked: boolean,
    title: string,
    type: string
  ) => {
    dispatch({
      type: CreateVaultActionTypes.SET_ACTIONS_CHECKED,
      id,
      payload: { checked, title, type },
    })
  }

  const setActionsConfigured = (id: string, configured: boolean) => {
    dispatch({
      type: CreateVaultActionTypes.SET_ACTIONS_CONFIGURED,
      id,
      payload: configured,
    })
  }

  const setActionsData = (id: string, actionData: ActionData) => {
    const conditions = [
      `${t(
        'store.createVault.provider.setActionsData.condition'
      )} ${formatNumber(actionData.percent)}%`,
    ]
    if (actionData.period) {
      conditions.push(
        `${t('store.createVault.provider.setActionsData.period')} ${
          actionData.period
        } ${t('store.createVault.provider.setActionsData.days')}`
      )
    }
    dispatch({
      type: CreateVaultActionTypes.SET_ACTIONS_DATA,
      id,
      payload: { actionData, conditions },
    })
  }

  const resetSelectedGuards = () => {
    dispatch({
      type: CreateVaultActionTypes.RESET_SELECTED_GUARDS,
    })
  }

  const resetAllSelectedFields = () => {
    setCurrentStep(1)
    setSelectedRiskModel('')
    setVaultName('')
    setSvtSymbol('')
    setIsSvtSymbolValid(true)
    setIsVaultNameValid(true)
    setSelectedMarket('1')
    setFilterByAssetGroup('0')
    setSelectedStrategies([])
    setSelectedManagementFee(10)
    setSelectedRiskScore(0)
    setSelectedAllocationProvider(DEFAULT_ALLOCATION_PROVIDER[CURRENT_NETWORK])
    setSelectedManagementFee(DEFAULT_MANAGEMENT_FEE)
    resetSelectedGuards()
  }

  const contextValue = useMemo(() => {
    return {
      ...createVault,
      isUnavailableProvider,
      setCurrentStep,
      setSelectedRiskModel,
      setVaultName,
      setSvtSymbol,
      setIsVaultNameValid,
      setIsSvtSymbolValid,
      setSelectedMarket,
      setSelectedStrategies,
      setSelectedAssetGroupId,
      setSelectedPerformanceFee,
      setSelectedManagementFee,
      setSelectedAllocationProvider,
      setSelectedRiskScore,
      setStepCompletedStatus,
      setFilterByAssetGroup,
      setFilterBySearchTerm,
      getStepData,
      validateVaultName,
      validateSvtSymbol,
      filterStrategy,
      handleSortClick,
      setGuardsWhitelist,
      setGuardsAssetAmount,
      setGuardsBool,
      setGuardsTime,
      setGuardsConfigured,
      setGuardsChecked,
      setSelectedGuards,
      setActionsChecked,
      setActionsConfigured,
      setActionsData,
      resetAllSelectedFields,
    }
  }, [
    isUnavailableProvider,
    createVault.steps,
    createVault.currentStep,
    createVault.vaultName,
    createVault.svtSymbol,
    createVault.isSvtSymbolValid,
    createVault.isVaultNameValid,
    createVault.selectedMarket,
    createVault.selectedAllocationProvider,
    createVault.selectedRiskModel,
    createVault.selectedStrategies,
    createVault.selectedAssetGroupId,
    createVault.selectedManagementFee,
    createVault.selectedPerformanceFee,
    createVault.selectedRiskScore,
    createVault.filterByAssetGroup,
    createVault.filterBySearchTerm,
    createVault.strategySort,
    createVault.selectedGuards,
    createVault.selectedActions,
  ])

  return (
    <CreateVaultContext.Provider value={contextValue}>
      {children}
    </CreateVaultContext.Provider>
  )
})
