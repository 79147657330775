import { Box, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import DetailsRow from '@/components/molecules/DetailsRow'
import SectionColumn from '@/components/molecules/SectionColumn'
import { StyledGridItem } from '@/components/molecules/StyledGridItem'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

const GeneralDetails = () => {
  const { smartVaultData } = useVaultsPage()

  const { totalVolumeRouted } = smartVaultData

  const t = useLocale()

  return (
    <SectionColumn
      sx={{
        padding: '0',
      }}
      noVerticalGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.totalVolumeRouted'
      )}
    >
      <StyledGridItem>
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.volumeRouted.label'
          )}
        >
          <Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body2'>
                {currency} {formatUSD(totalVolumeRouted?.total ?? '0')}
              </Typography>
              {totalVolumeRouted &&
                totalVolumeRouted.assets.map(({ amount, asset }, index) => (
                  <Typography
                    variant='caption'
                    color='text.secondary'
                    key={index}
                  >
                    {formatUSD(amount)} {currency}
                    {asset.symbol}
                  </Typography>
                ))}
            </Box>
          </Box>
        </DetailsRow>
        {/* <ColumnlessGridRow
          text={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.links.tvr'
          )}
          link='https://docs.spool.fi/glossary/spool-glossary#total-value-routed-tvr'
        />*/}
      </StyledGridItem>
    </SectionColumn>
  )
}

export default GeneralDetails
