import { Button, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

const ButtonConfirm: React.FC = () => {
  const { modals, closeModal } = useModalState()

  const t = useLocale()

  /*const modalType = modals['actionModal'].modalType?.actionModalType
  const color =
    modalType?.transactionStatus === TransactionStatus.Success
      ? 'success'
      : 'error'*/
  const minimized = modals['actionModal'].isMinimized

  return (
    <Button
      variant={minimized ? 'text' : 'contained'}
      color='primary'
      onClick={() => closeModal(Modals.ACTION_MODAL)}
      sx={minimized ? { p: 0, px: 1, mx: 0, my: 1 } : {}}
    >
      <Typography variant='button' sx={{ alignSelf: 'center' }}>
        {t('components.organisms.actionModal.button.label')}
      </Typography>
    </Button>
  )
}

export default ButtonConfirm
