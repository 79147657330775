import { Box, Typography } from '@mui/material'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import SectionColumn from '@/components/molecules/SectionColumn'
import SectionItem from '@/components/molecules/SectionItem'

import { ECOSYSTEM_FEE, PLATFORM_FEE } from '@/constants'
import { formatNumber } from '@/utils/formats'

const BasicFees = () => {
  const t = useLocale()
  const { selectedPerformanceFee } = useCreateVaultState()

  return (
    <SectionColumn title='Basic Fees' sx={{ paddingBottom: 0 }}>
      <Box
        width='100%'
        display='inline-grid'
        gridAutoColumns='minmax(0, 1fr)'
        gridAutoFlow='column'
        gap={2}
      >
        <SectionItem
          title={t(
            'components.organisms.createVault.launch.basicFees.creatorPerformance.title'
          )}
        >
          <Typography variant='h6'>
            {formatNumber(selectedPerformanceFee, 2)} %
          </Typography>
        </SectionItem>
        <SectionItem
          title={t(
            'components.organisms.createVault.launch.basicFees.daoPerformance.title'
          )}
        >
          <Typography variant='h6'>
            {formatNumber(ECOSYSTEM_FEE + PLATFORM_FEE, 2)} %
          </Typography>
        </SectionItem>
      </Box>
    </SectionColumn>
  )
}

export default BasicFees
