import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { Button } from '@mui/material'

import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

const ButtonMaximize: React.FC = () => {
  const { minimizeModal } = useModalState()

  return (
    <Button
      variant='text'
      size='small'
      color='primary'
      sx={{
        px: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:focus-within': {
          outline: 'none',
        },
      }}
      onClick={() => minimizeModal(Modals.ACTION_MODAL)}
    >
      <UnfoldMoreIcon
        color='primary'
        sx={{ alignSelf: 'center', transform: 'rotate(-45deg)' }}
      />
    </Button>
  )
}

export default ButtonMaximize
