import RotateRightIcon from '@mui/icons-material/RotateRight'
import { Box, Stack, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import CustomButton from '@/components/atoms/CustomButton'
import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import ModalBox from '@/components/organisms/Modals/ModalBox'

const DetectSmallScreenModal: React.FC = () => {
  const { closeModal } = useModalState()

  const t = useLocale()
  return (
    <ModalBox id='detectSmallScreenModal' disabledClose={true}>
      <ModalBoxOuterContainer
        sx={{
          width: '308px',
        }}
      >
        <Box
          display='flex'
          gap={(theme) => theme.spacing(2)}
          paddingY={(theme) => theme.spacing(1)}
          justifyContent='space-between'
        >
          <RotateRightIcon className='xl' sx={{ color: 'primary.main' }} />
          <Stack gap={2}>
            <Typography variant='alertTitle'>
              {t('components.organisms.detectSmallScreenModal.title')}
            </Typography>
            <Typography variant='body2'>
              {t('components.organisms.detectSmallScreenModal.doesNotProvide')}
            </Typography>
            <Typography variant='body2'>
              {t('components.organisms.detectSmallScreenModal.encouragement')}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ ml: 4.5, pt: 1 }}>
          <CustomButton
            onClick={() => closeModal('detectSmallScreenModal')}
            variant='contained'
            size='small'
            fullWidth
            disableElevation
          >
            Continue Anyway
          </CustomButton>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default DetectSmallScreenModal
