type TextElement = {
  type: 'text'
  content: string
}

type LinkElement = {
  type: 'link'
  content: string
  url: string
}

type StrongElement = {
  type: 'strong'
  content: string
}

type BreakElement = {
  type: 'break'
}

enum TextTags {
  LINK = 'LINK',
  STRONG = 'STRONG',
  BREAK = 'BREAK',
}

type ParsedElement = TextElement | LinkElement | StrongElement | BreakElement

export const parseTextTags = (inputString: string): ParsedElement[] => {
  const elements: ParsedElement[] = []
  let currentText = ''
  let tagContent = ''
  let linkUrl = ''
  let insideStrongTag = false

  const pushCurrentText = () => {
    if (currentText) {
      elements.push({ type: 'text', content: currentText })
      currentText = ''
    }
  }

  let i = 0
  while (i < inputString.length) {
    if (inputString[i] === '[') {
      pushCurrentText()

      const tagStart = i + 1
      const tagEnd = inputString.indexOf(']', tagStart)

      if (tagEnd !== -1) {
        const tagString = inputString.slice(tagStart, tagEnd)
        const isClosingTag = tagString.startsWith('/')

        if (isClosingTag) {
          const tagName = tagString.slice(1)
          if (tagName === TextTags.LINK) {
            elements.push({ type: 'link', content: tagContent, url: linkUrl })
            tagContent = ''
            linkUrl = ''
          } else if (tagName === TextTags.STRONG) {
            elements.push({ type: 'strong', content: tagContent })
            tagContent = ''
            insideStrongTag = false
          }
        } else if (tagString === TextTags.BREAK) {
          elements.push({ type: 'break' })
        } else if (tagString.startsWith(TextTags.LINK + '#')) {
          linkUrl = tagString.slice(5)
        } else if (tagString === TextTags.STRONG) {
          insideStrongTag = true
        }

        i = tagEnd + 1
        continue
      }
    }

    if (linkUrl || insideStrongTag) {
      tagContent += inputString[i]
    } else {
      currentText += inputString[i]
    }

    i++
  }

  pushCurrentText()

  if (tagContent) {
    elements.push({ type: 'text', content: tagContent })
  }

  return elements
}
