import { parseEther, parseUnits } from '@ethersproject/units'
import {
  IncentivizeRewardToken,
  TimeConversions,
} from '@solidant/spool-v2-fe-lib'
import dayjs, { unix } from 'dayjs'
import { BigNumber } from 'ethers/lib/ethers'

import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useIncentivizeVaultState from '@/hooks/context/useIncentivizeVault'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useApproveToken from '@/hooks/web3/useApproveToken'
import useTokenDetails from '@/hooks/web3/useTokenDetails'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { API_ENDPOINTS } from '@/config/sdk'
import { MUTATE_SLEEP_DURATION } from '@/constants'
import { IncentivesPage } from '@/constants/incentives'
import { calculateNewEndTime, getNewRewardRate } from '@/utils/incentives'
import { sleep } from '@/utils/web3'

const useReincentivize = (
  id: string,
  address: string,
  amount: string,
  days: string,
  incentivizedToken: IncentivizeRewardToken,
  updateIncentivizedVaults: () => void
) => {
  const { chain } = useConnectionState()

  const { openModal, closeModal, setModalType } = useModalState()
  const { sdk } = useAppState()
  const t = useLocale()

  const { decimals } = useTokenDetails(address)

  const { page, setTargetToken, setTokenAmount, setDays } =
    useIncentivizeVaultState()

  const cancel = () => {
    setTargetToken(undefined)
    setTokenAmount('')
    setDays('')
  }

  const { isTokenApproved, approve } = useApproveToken(
    address,
    API_ENDPOINTS[chain].REWARD_MANAGER,
    amount
  )

  const updateModal = (
    type: TransactionStatus | Modals.WARNING_MODAL,
    message?: string
  ) => {
    closeModal(Modals.ACTION_MODAL)

    if (type === Modals.WARNING_MODAL) {
      openModal(Modals.WARNING_MODAL)
      setModalType({
        warningModalType: {
          message: t(message || ''),
        },
      })
    } else {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: type,
          transactionType: TransactionType.ReincentivizeToken,
        },
      })
    }
  }

  const reincentivize = async () => {
    try {
      const tokenEndTime = incentivizedToken?.endTime.toString()

      const totalDuration = unix(+incentivizedToken?.endTime.toString()).diff(
        unix(+incentivizedToken?.startTime.toString()),
        'seconds'
      )

      const timeRemaining = unix(+tokenEndTime).diff(dayjs(), 'seconds')

      const previousRate = parseEther(incentivizedToken?.totalAmount.toString())
        .mul(TimeConversions.SECONDS_PER_DAY)
        .div(BigNumber.from(totalDuration))

      const newRewardRate = getNewRewardRate(
        amount,
        days,
        incentivizedToken,
        page === IncentivesPage.EXTEND_DURATION
      )

      const newEndTime = calculateNewEndTime(tokenEndTime, days)

      updateModal(TransactionStatus.PendingSignature)

      //NOTE: this might not be an issue if we can predetermine the amount needed to be added when the user inputs the days(same as V1)
      if (newRewardRate.lt(previousRate) && timeRemaining > 0) {
        updateModal(
          Modals.WARNING_MODAL,
          'store.incentivizedRewards.reincentivize.warning.lowRate'
        )
        return
      }

      if (!isTokenApproved) {
        await approve(amount)
        setModalType({
          actionModalType: {
            transactionStatus: TransactionStatus.Processing,
            transactionType: TransactionType.Approve,
          },
        })
        //wait for approval to go through, so that extend incentive works.
        await sleep(5000)
      }

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.ReincentivizeToken,
        },
      })

      const tx = await sdk.smartVault.extendIncentiveToken(
        id,
        address,
        parseUnits(amount, decimals).toString(),
        newEndTime
      )
      updateModal(TransactionStatus.Processing)

      await tx.wait()

      // delay so that subgraph gets updated
      await sleep(MUTATE_SLEEP_DURATION)

      await updateIncentivizedVaults()

      updateModal(TransactionStatus.Success)

      cancel()
    } catch (error) {
      console.error(error)
      updateModal(TransactionStatus.Failure)
    }
  }

  return reincentivize
}

export default useReincentivize
