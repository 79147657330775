import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { StrategyAllocation } from '@solidant/spool-v2-fe-lib'
import { Fragment } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import LinearProgressWithLabel from '@/components/atoms/LinearProgressWithLabel'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { strategiesDescriptions } from '@/mockdata/create-vault.json'
import { formatNumber, formatUSD } from '@/utils/formats'

import { StrategiesDto } from '@/types/create-vault'

interface RiskAllocationTableRowProps {
  row: StrategiesDto
  strategyAllocation: StrategyAllocation
}

const RiskAllocationTableRow: React.FC<RiskAllocationTableRowProps> = ({
  row: { name, assetGroup, apy, riskScore, notice, address, balanceUSD },
  strategyAllocation,
}) => {
  const { chain } = useConnectionState()

  const { description, warning } = notice
  const info =
    strategiesDescriptions[name as keyof typeof strategiesDescriptions]

  return (
    <TableRow key={name} sx={{ backgroundColor: '#fff' }}>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            whiteSpace='normal'
            maxWidth='250px'
            display='flex'
            alignItems='center'
            gap={0.25}
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            {/*{mapIcons(chainId)}*/}
            <Typography variant='body2'>{name}</Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            {assetGroup?.assetGroupTokens.map(({ address }) => (
              <Fragment key={address}>
                {mapAssetTokenIcon(chain, address)}
              </Fragment>
            ))}
            <Box display='flex' alignItems='center'>
              <Tooltip title={info} arrow placement='left'>
                <Box
                  display='flex'
                  alignItems='center'
                  sx={{ cursor: 'pointer' }}
                >
                  <InfoIcon className='md' sx={{ color: 'primary.main' }} />
                </Box>
              </Tooltip>

              {warning && (
                <Tooltip title={description} arrow placement='left'>
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{ cursor: 'pointer' }}
                  >
                    <WarningIcon
                      className='md'
                      sx={{ color: 'primary.main', marginTop: '0.1rem' }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <Typography variant='body2'>
          {formatNumber(parseFloat(apy), 2)} %
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <Typography variant='body2'>$ {formatUSD(balanceUSD)}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <LinearProgressWithLabel
          value={strategyAllocation[address] / 100}
          noMarginRight
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Typography variant='body2'>{formatNumber(riskScore, 1)}/10</Typography>
      </TableCell>
    </TableRow>
  )
}

export default RiskAllocationTableRow
