import useLocale from '@/hooks/context/useLocale'

import TooltipGrid from '@/components/molecules/TooltipGrid'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

interface YourBalanceToolTipProps {
  pendingDeposits: string
  pendingWithdrawals: string
}

const YourBalanceToolTip: React.FC<YourBalanceToolTipProps> = ({
  pendingDeposits,
  pendingWithdrawals,
}) => {
  const t = useLocale()

  return (
    <TooltipGrid
      title={t('components.organisms.tooltips.yourBalanceTooltip.title')}
      items={[
        {
          title: t(
            'components.organisms.tooltips.balanceTooltip.deposits.title'
          ),
          value: `${currency} ${formatUSD(pendingDeposits)}`,
        },
        {
          title: t(
            'components.organisms.tooltips.balanceTooltip.withdrawals.title'
          ),
          value: `${currency} ${formatUSD(pendingWithdrawals)}`,
        },
      ]}
    />
  )
}

export default YourBalanceToolTip
