export const gaIds = {
  walletConnectionButtons: 'connect-wallet-button',
  topNav: 'top-navigation',
  submenuNav: 'submenu-navigation',
  filter: {
    main: 'filter',
    page: {
      smartVaults: 'smart-vault',
      transactionHistory: 'transaction',
      createVaultStrategies: 'create-vault-strategies',
    },
    assetGroup: 'asset-group',
    search: 'search',
    vaults: 'vaults',
    transactionType: 'transaction-type',
  },
  smartVaultRow: {
    main: 'smart-vault-row',
    home: 'home',
    investorDashboard: 'investor-dashboard',
  },
  deposit: 'deposit',
  actionModal: 'action-modal',
  createVault: 'create-vault',
}
