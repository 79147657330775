import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

import { CreatorVault } from '@/types/dashboard'

interface CreatorVaultsTableRowOptionsProps {
  row: CreatorVault
}

const CreatorVaultsTableRowOptions: React.FC<
  CreatorVaultsTableRowOptionsProps
> = ({ row }) => {
  const { modals, openModal, setModalType } = useModalState()
  const actionModalType = modals['actionModal']?.modalType

  const { setWithdrawEarningsVault } = useCreatorDashboardState()

  const {
    setSelectedVaults,
    setSelectedVaultsAllocations,
    setAsset,
    resetAll,
    setMetavaultId,
  } = useCreateMetavaultState()

  const { chain } = useConnectionState()

  const { connector, chainId } = useWeb3React()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }

  const { address, metavaultVaults, assetGroup, isMetavault, userBalance } = row

  const setMetavaultData = () => {
    resetAll()
    setSelectedVaults(metavaultVaults)
    setSelectedVaultsAllocations(
      metavaultVaults.map((vault) => +vault.allocation / 100)
    )
    setAsset(assetGroup.assetGroupTokens[0].address)
    setMetavaultId(address)
  }

  const t = useLocale()

  return (
    <>
      <IconButton
        aria-controls={open ? 'investor-vaults-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id='investor-vaults-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box py={2}>
          <MenuItem
            onClick={(event) => {
              const modalType = isMetavault
                ? { metavaultModalType: { metavault: row } }
                : { incentivizeVaultModalType: { vault: row } }
              const modal = isMetavault
                ? Modals.METAVAULT_MODAL
                : Modals.INCENTIVIZE_VAULT_MODAL
              if (isMetavault) {
                setMetavaultData()
              }
              setModalType(modalType)
              openModal(modal)
              handleClose(event)
            }}
          >
            {isMetavault
              ? t(
                  'components.organisms.dashboard.creator.creatorVaultsTable.menuOption.editMetavault'
                )
              : t(
                  'components.organisms.dashboard.creator.creatorVaultsTable.menuOption.incentivizeVault'
                )}
          </MenuItem>
          {!isMetavault && (
            <MenuItem
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()

                if (!+userBalance) {
                  setModalType({
                    warningModalType: {
                      message: t(
                        'components.organisms.dashboard.creator.creatorVaultsTable.row.warning.noEarnings'
                      ),
                    },
                    ...actionModalType,
                  })
                  openModal(Modals.WARNING_MODAL)
                  return
                }

                if (chainId !== chain) {
                  connector.activate(chain)
                  return
                }
                setWithdrawEarningsVault(row)
                setModalType({
                  warningModalType: {
                    message: t(
                      'components.organisms.dashboard.creator.creatorVaultsTable.row.warning.claimEarnings'
                    ),
                    isActionRequired: true,
                    action: () => openModal(Modals.WITHDRAW_MODAL),
                  },
                  ...actionModalType,
                })
                openModal(Modals.WARNING_MODAL)
              }}
            >
              {t(
                'components.organisms.dashboard.creator.creatorVaultsTable.row.action'
              )}
            </MenuItem>
          )}
        </Box>
      </Menu>
    </>
  )
}

export default CreatorVaultsTableRowOptions
