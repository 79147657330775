import { isAddress } from 'ethers/lib/utils'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

import { SmartVaultTableSortType } from '@/types/smart-vault'

const useSmartVaults = (
  page: number,
  limit: number,
  assetGroupId: string,
  riskModelProviderId: string,
  smartVaultsTableSort: SmartVaultTableSortType,
  search: string,
  userAddress?: string
) => {
  const { sdk } = useContext(AppContext)

  const { chain } = useConnectionState()

  const { data, error, mutate } = useSWR(
    sdk
      ? [
          chain,
          'smartVaults',
          page,
          limit,
          assetGroupId,
          riskModelProviderId,
          smartVaultsTableSort,
          search,
          userAddress,
        ]
      : null,
    async () => {
      return await sdk.smartVault.getVaults({
        offset: page * limit,
        limit,
        assetGroupId,
        riskModelProviderId,
        vaultAddresses: isAddress(search) ? [search] : undefined,
        search: isAddress(search) ? '' : search,
        userAddress,
        sortBy: smartVaultsTableSort.key,
        sortOrder: smartVaultsTableSort?.direction,
      })
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    smartVaults: data?.vaults,
    totalVaults: data?.totalCount,
    totalUnfilteredVaults: data?.totalUnfilteredCount,
    isLoading: !error && !data,
    totalTvr: data?.totalTvr,
    totalUserBalance: data?.totalUserBalance,
    mutate,
    error,
  }
}

export default useSmartVaults
