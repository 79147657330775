import { Box } from '@mui/material'

import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import GuardConditions from '@/components/organisms/SmartVaults/CollapsibleRow/DepositConditions/GuardConditions'
import StrategiesAllocation from '@/components/organisms/SmartVaults/CollapsibleRow/Strategies/StrategiesAllocation'
import FeesDetails from '@/components/organisms/SmartVaults/VaultDetails/ApyDetails'
import GeneralDetails from '@/components/organisms/SmartVaults/VaultDetails/GeneralDetails'
import HistoricalTvrChart from '@/components/organisms/SmartVaults/VaultDetails/HistoricalTvrChart'
import IncentivizationDetails from '@/components/organisms/SmartVaults/VaultDetails/IncentivizationDetails'
import NoteDetails from '@/components/organisms/SmartVaults/VaultDetails/NoteDetails'
import ReallocationDetails from '@/components/organisms/SmartVaults/VaultDetails/ReallocationDetails'
import RiskDetails from '@/components/organisms/SmartVaults/VaultDetails/RiskDetails'
import VaultOwnerDetails from '@/components/organisms/SmartVaults/VaultDetails/VaultOwnerDetails'

const VaultDetails = () => {
  const { smartVaultData } = useVaultsPage()
  const { strategiesAllocation, rebalanceCount } = smartVaultData

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <HistoricalTvrChart />
      <GeneralDetails />
      <IncentivizationDetails />
      <FeesDetails />
      <RiskDetails />
      {smartVaultData.guardConditions?.conditions &&
        smartVaultData.guardConditions.conditions.length > 0 &&
        smartVaultData.guardConditions.guardsLoaded && (
          <GuardConditions
            guardConditions={smartVaultData.guardConditions.conditions}
          ></GuardConditions>
        )}
      <StrategiesAllocation
        smartVault={smartVaultData}
        strategies={strategiesAllocation?.strategies}
        totalAllocated={
          strategiesAllocation ? Number(strategiesAllocation.total) : 0
        }
      />
      {!!Number(rebalanceCount) && <ReallocationDetails />}
      <VaultOwnerDetails />
      <NoteDetails />
    </Box>
  )
}

export default VaultDetails
