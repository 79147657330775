import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { memo, useEffect, useMemo, useReducer } from 'react'

import {
  ConnectionContext,
  initialState,
} from '@/store/connection/connection.context'
import connectionReducer from '@/store/connection/connection.reducer'
import { ConnectionActionTypes } from '@/store/connection/connection.types'

import { eip6963Connection } from '@/connection/connections'
import { EIP6963 } from '@/connection/connectors/eip6963Connector/eip6963Connector'
import { setRecentConnectionMeta } from '@/connection/utils'
import { ConnectionType } from '@/constants/web3'

import { RCNode } from '@/types/index'

export const ConnnectionProvider = memo(({ children }: RCNode) => {
  const [connection, dispatch] = useReducer(connectionReducer, initialState)

  const { account, connector } = useWeb3React()

  useEffect(() => {
    if (account) {
      if (connector instanceof EIP6963) {
        const { rdns } = eip6963Connection.getProviderInfo()
        setRecentConnectionMeta({
          type: ConnectionType.EIP_6963_INJECTED,
          address: account,
          rdns,
        })
        return
      }
      setRecentConnectionMeta(undefined)
    }
  }, [account])

  const setChain = (chain: SupportedNetworks) => {
    dispatch({ type: ConnectionActionTypes.SET_CHAIN, payload: chain })
  }

  const setActivatingWallet = (wallet?: string) => {
    dispatch({ type: ConnectionActionTypes.TARGET_WALLET, payload: wallet })
  }

  const contextValue = useMemo(() => {
    return {
      ...connection,
      setChain,
      setActivatingWallet,
    }
  }, [connection.chain, connection.activatingWallet])

  return (
    <ConnectionContext.Provider value={contextValue}>
      {children}
    </ConnectionContext.Provider>
  )
})
