import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { createContext } from 'react'

import {
  DepositContextProps,
  DepositState,
} from '@/store/deposit/deposit.types'

import { tokenAddresses } from '@/constants/tokens'

export const initialState: DepositState = {
  depositReceiptLimit: 10,
  depositReceiptPage: 0,
  singleDeposit: {
    tokenAddress: tokenAddresses.USDT,
    amount: '',
  },
  multiAssetUsdDepositAmount: '',
  depositReceiptSort: {
    key: 'createdOn',
    direction: SortOrder.DESC,
  },
  multiAssetSwap: {
    tokenAddress: tokenAddresses.USDT,
    amount: '',
  },
}

export const DepositContext = createContext<DepositContextProps>({
  ...initialState,
  setSingleDepositAmount: () => undefined,
  setMultiAssetUsdDepositAmount: () => undefined,
  setMultiAssetSwap: () => undefined,
  handleSortClick: () => undefined,
  setDepositReceiptPage: () => undefined,
  setDepositReceiptLimit: () => undefined,
})
