import { Typography } from '@mui/material'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import TooltipGrid from '@/components/molecules/TooltipGrid'

import { ECOSYSTEM_FEE, PLATFORM_FEE } from '@/constants'
import { formatNumber } from '@/utils/formats'

const FeesTooltip = () => {
  const t = useLocale()

  const { selectedPerformanceFee, selectedManagementFee } =
    useCreateVaultState()

  return (
    <TooltipGrid
      title={t('components.organisms.tooltips.feesTooltip.title')}
      items={[
        {
          title: t('components.organisms.tooltips.feesTooltip.performanceFee'),
          value: (
            <Typography variant='caption'>
              {formatNumber(selectedPerformanceFee, 2)} %
            </Typography>
          ),
        },
        {
          title: t('components.organisms.tooltips.feesTooltip.managementFee'),
          value: (
            <Typography variant='caption'>
              {formatNumber(selectedManagementFee, 2)} %
            </Typography>
          ),
        },
        {
          title: t('components.organisms.tooltips.feesTooltip.daoFee'),
          value: (
            <Typography variant='caption'>
              {formatNumber(ECOSYSTEM_FEE + PLATFORM_FEE, 2)} %
            </Typography>
          ),
        },
      ]}
    />
  )
}

export default FeesTooltip
