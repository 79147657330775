import { Navigate, useLocation } from 'react-router-dom'

const RemoveTrailingSlash: React.FC = ({ ...rest }) => {
  const location = useLocation()

  const { pathname, search } = location
  // If the last character of the url is '/'
  if (pathname.match('/.*/$')) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: pathname.replace(/\/+$/, ''),
          search: search,
        }}
      />
    )
  } else return null
}

export default RemoveTrailingSlash
