import { useContext } from 'react'

import { IncentivizeVaultContext } from '@/store/incentiviseVault/incentiviseVault.context'

const useIncentivizeVaultState = () => {
  const incentivizeVaultContext = useContext(IncentivizeVaultContext)
  if (!Object.keys(incentivizeVaultContext).length) {
    throw new Error('IncentivizeVaultContext must be used within its provider')
  }
  return incentivizeVaultContext
}

export default useIncentivizeVaultState
