import { AddressZero } from '@ethersproject/constants'

export const formatAccount = (address: string, maxLength: number) => {
  if (address.length <= maxLength) {
    return address
  }

  return `${(address || AddressZero).slice(0, maxLength - 4)}...${(
    address || AddressZero
  ).slice(-4)}`
}

export const formatNumber = (
  val: any,
  decimal = 0,
  short?: boolean
): string => {
  // Convert `val` to number if possible
  val = typeof val === 'number' ? val : Number(val)

  // Check if `val` is not a number
  if (isNaN(val)) {
    console.warn(`Value is not a number: ${val}. Defaulting to 0.`)
    val = 0 // Setting default value to 0
  }

  // Check if `decimal` is not a whole number or is negative
  if (!Number.isInteger(decimal) || decimal < 0) {
    throw new Error('Decimal count is not a non-negative integer')
  }

  let newVal: string | number = val.toFixed(decimal)

  if (short) {
    // Check if number falls into a certain range and format it
    // The +0.1 compensates if `toFixed()` rounds up a number that's exactly on the boundary
    if (val >= 1e3 && val < 1e6 + 0.1) {
      newVal = +(val / 1e3).toFixed(decimal) + ' K'
    } else if (val >= 1e6 && val < 1e9 + 0.1) {
      newVal = +(val / 1e6).toFixed(decimal) + ' M'
    } else if (val >= 1e9 && val < 1e12 + 0.1) {
      newVal = +(val / 1e9).toFixed(decimal) + ' B'
    }
  }

  // Add thousands separator. This could fail if newVal is not a string.
  try {
    return newVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  } catch (e) {
    throw new Error('Error adding thousands separator')
  }
}

export const formatUSD = (
  value: number | string,
  maxDecimals = 2,
  minDecimals = 2,
  logger = console.warn
) => {
  let returnValue = value
  if ((!value && value !== 0) || isNaN(+value)) {
    logger && logger('Value is not defined or not a number: ', value)
    returnValue = 0
  }

  // Check if minDecimals and maxDecimals are non-negative integers
  if (!Number.isInteger(minDecimals) || minDecimals < 0) {
    throw new Error('minDecimals is not a non-negative integer')
  }
  if (!Number.isInteger(maxDecimals) || maxDecimals < 0) {
    throw new Error('maxDecimals is not a non-negative integer')
  }

  // Ensure minDecimals is not greater than maxDecimals
  if (minDecimals > maxDecimals) {
    throw new Error('minDecimals should not be greater than maxDecimals')
  }

  return (+returnValue).toLocaleString('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  })
}

export const getUnixDateTimeString = (unixTimestamp: number | string) => {
  const date = new Date(Number(unixTimestamp) * 1000)

  const UTCOffSet = (date.getTimezoneOffset() / 60) * -1

  const UTCOffSetInHours = UTCOffSet > 0 ? `+${UTCOffSet}` : UTCOffSet

  const UTCTimeZone = 'UTC' + UTCOffSetInHours

  const time = date.toLocaleTimeString()

  const dateOfDeposit = `${date.getDate()} ${date
    .toLocaleString('default', { month: 'short' })
    .toUpperCase()} ${date.getFullYear()}`

  return {
    dateOfDeposit,
    time,
    UTCTimeZone,
  }
}

export const formatUSDWithLowerLimit = (
  value: number | string,
  maxDecimals = 2,
  minDecimals = 2,
  logger = console.warn
) => {
  if (Number(value) < 0.01 && Number(value) > 0) {
    return '< $ 0.01'
  }
  return `$ ${formatUSD(value, maxDecimals, minDecimals, logger)}`
}

export const formatETHWithLowerLimit = (value: number | string) => {
  if (Number(value) < 0.00001 && Number(value) > 0) {
    return '< 0.00001'
  }
  return `${value}`
}

export const formatPercentages = (value: number | string, decimals = 2) => {
  if (!value && value !== 0) {
    console.error('formatPercentages: Value is not defined')
    return (0).toFixed(decimals)
  }

  if (isNaN(+value) || !isFinite(+value)) {
    console.error('formatPercentages: Value is not a number')
    return (0).toFixed(decimals)
  }

  return (+value).toFixed(decimals)
}

export const ellipsisText = (
  text: string,
  startTextPosition = 3,
  endTextPosition = 3
) => {
  return (
    text.substring(0, startTextPosition) +
    '...' +
    text.substring(text.length - endTextPosition, text.length)
  )
}
