const USDE = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 94 93'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M46.8538 0.847386H46.8556C72 0.847386 92.3835 21.2309 92.3835 46.3753V46.3771C92.3835 71.5215 72 91.905 46.8556 91.905H46.8538C21.7095 91.905 1.3259 71.5215 1.3259 46.3771V46.3753C1.3259 21.2309 21.7095 0.847386 46.8538 0.847386Z'
        fill='black'
        stroke='black'
        strokeWidth='1.67719'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.4164 8.22217C21.5186 11.6696 7.99902 27.416 7.99902 46.3201C7.99902 65.2241 21.5186 80.9705 39.4164 84.418V80.7817C23.5 77.3888 11.5622 63.2485 11.5622 46.3201C11.5622 29.3916 23.5 15.2514 39.4164 11.8585V8.22217ZM54.2468 11.8727V8.23505C72.1115 11.7087 85.5976 27.4394 85.5976 46.3201C85.5976 65.2007 72.1115 80.9315 54.2468 84.4051V80.7674C70.1299 77.3494 82.0344 63.2249 82.0344 46.3201C82.0344 29.4152 70.1299 15.2908 54.2468 11.8727Z'
        fill='white'
      />
      <path
        d='M52.3261 45.0239C55.2864 45.5881 57.5826 46.6762 59.2146 48.2882C60.8466 49.8599 61.6626 51.8748 61.6626 54.3331V58.2019C61.6626 61.1438 60.5999 63.5215 58.4745 65.335C56.3491 67.1082 53.5596 67.9948 50.1058 67.9948H48.9103V74.1607H44.8113V67.9948H43.5588C41.2816 67.9948 39.2701 67.491 37.5242 66.4835C35.7783 65.4357 34.412 63.985 33.4252 62.1312C32.4764 60.2371 32.002 58.0609 32.002 55.6026H36.1009C36.1009 58.1012 36.7841 60.1363 38.1504 61.708C39.5547 63.2394 41.3955 64.0051 43.6727 64.0051H49.9919C52.2312 64.0051 54.034 63.4812 55.4003 62.4334C56.7666 61.3453 57.4498 59.9348 57.4498 58.2019V54.3331C57.4498 52.9629 56.9374 51.7942 55.9127 50.8271C54.9259 49.8599 53.5596 49.2352 51.8137 48.9531L41.5662 47.1396C38.6818 46.6157 36.4425 45.5276 34.8485 43.8753C33.2544 42.223 32.4574 40.1476 32.4574 37.649V34.3847C32.4574 31.4428 33.4821 29.1054 35.5316 27.3725C37.6191 25.5994 40.3707 24.7128 43.7865 24.7128H44.8113V18.5469H48.9103V24.7128H50.2196C53.4457 24.7128 56.0455 25.7606 58.0191 27.8561C59.9927 29.9114 60.9795 32.6317 60.9795 36.0169H56.8805C56.8805 33.8004 56.2732 32.0272 55.0587 30.6973C53.8442 29.3674 52.2312 28.7024 50.2196 28.7024H43.7865C41.6232 28.7024 39.8963 29.2263 38.6059 30.2741C37.3154 31.2816 36.6702 32.6518 36.6702 34.3847V37.649C36.6702 39.0595 37.1447 40.2483 38.0935 41.2155C39.0803 42.1827 40.4276 42.8275 42.1355 43.1499L52.3261 45.0239Z'
        fill='white'
      />
    </svg>
  )
}

export default USDE
