import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

const useHistoricalVaultList = () => {
  const { sdk } = useContext(AppContext)
  const { account } = useWeb3React()

  const { data, error } = useSWR(
    sdk && account ? ['historicalVaultList', account] : null,
    async () => await sdk.dashboard.getHistoricalVaultList(account)
  )

  if (error) {
    console.error(error)
  }

  return {
    historicalVaultList: data,
    isLoading: !error && !data,
    error,
  }
}

export default useHistoricalVaultList
