import { createContext } from 'react'

import {
  ConnectionContextProps,
  ConnectionState,
} from '@/store/connection/connection.types'

import { IS_PUBLIC_TESTNET } from '@/config/sdk'

export const initialState: ConnectionState = {
  chain: IS_PUBLIC_TESTNET ? 11155111 : 1, //default chain
  activatingWallet: undefined,
}

export const ConnectionContext = createContext<ConnectionContextProps>({
  ...initialState,
  setChain: () => undefined,
  setActivatingWallet: () => undefined,
})
