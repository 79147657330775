import { Box, Button, CircularProgress, Grid } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'

import { gaIds } from '@/constants/googleAnalyticIDs'

import { Connection } from '@/types/connectors'

type ConnectWalletButtonProps = {
  connection: Connection
  onClick: () => void
}

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  connection,
  onClick,
}) => {
  const { activatingWallet } = useConnectionState()

  const isLoading = activatingWallet === connection.getProviderInfo().name

  const walletIcon =
    typeof connection.getProviderInfo().icon === 'object' ? (
      connection.getProviderInfo().icon
    ) : (
      <img
        width='40px'
        height='40px'
        alt={connection.getProviderInfo().name}
        src={connection.getProviderInfo().icon as string}
      />
    )

  const opacity = isLoading ? 0.14 : 1

  return (
    <Grid item xs={6}>
      <Button
        id={gaIds.walletConnectionButtons}
        className={`${
          !isLoading && Boolean(activatingWallet) ? 'disabled' : ''
        }`}
        sx={{
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          width: '100%',
          typography: 'body1',
          textTransform: 'none',
          color: 'text.primary',
          // bgColor: 'text.secondary',
        }}
        variant='text'
        onClick={onClick}
        disabled={!!activatingWallet}
        startIcon={walletIcon}
      >
        <Box display='flex' gap={1} width='100%'>
          <Box
            sx={{ opacity: opacity, transition: 'all .25s eas-in-out' }}
            width='inherit'
            display='flex'
            justifyContent='start'
            alignItems='center'
          >
            {connection.getProviderInfo().name}
          </Box>
          {isLoading ? (
            <CircularProgress
              size={32}
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          ) : null}
        </Box>
      </Button>
    </Grid>
  )
}

export default ConnectWalletButton
