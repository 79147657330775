import { FormControl, MenuItem } from '@mui/material'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useIncentivizeVaultState from '@/hooks/context/useIncentivizeVault'
import useLocale from '@/hooks/context/useLocale'

import SpoolSelect from '@/components/atoms/SpoolSelect'
import AssetMenuItem from '@/components/molecules/AssetMenuItem'

import { tokenAddresses } from '@/constants/tokens'

interface AddTableRowDropdownProps {
  excludedTokens: string[]
}

const AddTableRowDropdown: React.FC<AddTableRowDropdownProps> = ({
  excludedTokens,
}) => {
  const { chain } = useConnectionState()

  const {
    targetToken,
    setTargetToken,
    setIsCustomToken,
    setCustomTokenAddress,
  } = useIncentivizeVaultState()

  const t = useLocale()

  const tokens = useMemo(() => tokenAddresses[chain], [chain])

  return (
    <FormControl fullWidth>
      <SpoolSelect
        value={targetToken ?? ''}
        label='Select Token'
        handleChange={(event) => {
          const { value } = event.target

          setTargetToken(value as string)

          if (value === 'custom') {
            setIsCustomToken(true)
            return
          }

          setCustomTokenAddress('')
          setIsCustomToken(false)
        }}
      >
        {Object.values(tokens)
          .filter(
            (address) => address !== tokens.SPOOL && address !== tokens.ETH
          )
          .map((address) => {
            if (excludedTokens.includes(address)) return
            return (
              <MenuItem
                key={address}
                value={address}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: (theme) => theme.spacing(1.5, 2),
                }}
              >
                <AssetMenuItem assetId={address} />
              </MenuItem>
            )
          })}
        <MenuItem
          value='custom'
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: (theme) => theme.spacing(1.5, 2),
          }}
        >
          {t('components.organisms.incentives.addTableRow.custom')}
        </MenuItem>
      </SpoolSelect>
    </FormControl>
  )
}

export default AddTableRowDropdown
