import ErrorIcon from '@mui/icons-material/Error'
import { Box, Modal, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import CustomButton from '@/components/atoms/CustomButton'
import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'

interface DeleteModalProps {
  label: string
  open: boolean
  handleClose: () => void
  handleDelete: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  label,
  open,
  handleClose,
  handleDelete,
}) => {
  const t = useLocale()
  return (
    <Modal open={open} onClose={handleClose} disableEnforceFocus>
      <ModalBoxOuterContainer
        sx={{
          bgcolor: 'background.paper',
          width: '880px',
          borderRadius: '4px',
        }}
      >
        <Box display='flex' padding={2} justifyContent='space-between'>
          <Box display='flex' gap={1.5} alignItems='center' maxWidth='570px'>
            <ErrorIcon className='lg' sx={{ color: 'primary.main' }} />
            <Typography
              variant='alertTitle'
              maxWidth='515px'
              sx={{ wordBreak: 'break-word' }}
            >
              {label}
            </Typography>
          </Box>
          <Box display='flex' gap={1}>
            <CustomButton
              color='primary'
              size='large'
              variant='outlined'
              onClick={handleClose}
            >
              {t(
                'components.organisms.createVault.guards.modals.deleteModal.cancel.label'
              )}
            </CustomButton>
            <CustomButton
              color='primary'
              size='large'
              variant='contained'
              onClick={handleDelete}
            >
              {t(
                'components.organisms.createVault.guards.modals.deleteModal.delete.label'
              )}
            </CustomButton>
          </Box>
        </Box>
      </ModalBoxOuterContainer>
    </Modal>
  )
}

export default DeleteModal
