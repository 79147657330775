import { Box, Typography } from '@mui/material'
import { UserTransactionHistory } from '@solidant/spool-v2-fe-lib'
import { formatEther } from 'ethers/lib/utils'
import { Fragment, memo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'

import { mapAssetTokenIcon, tokenDetails } from '@/components/atoms/Tokens'

import { transactionType } from '@/constants/dashboard'
import { formatUSD, getUnixDateTimeString } from '@/utils/formats'

interface TransactionHistoryMobileRowProps {
  transactionHistory: UserTransactionHistory
}

const TransactionHistoryMobileRow: React.FC<
  TransactionHistoryMobileRowProps
> = ({ transactionHistory }) => {
  const t = useLocale()

  const { assetAddresses, vault, timestamp, amount, type, balance } =
    transactionHistory

  const { chain } = useConnectionState()

  const { name: vaultName } = vault

  const { total } = balance

  const formattedTime = getUnixDateTimeString(timestamp)

  const { dateOfDeposit } = formattedTime

  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius=' 0 0 4px 4px'
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='0.5rem'
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('components.organisms.transactionHistory.mobileTitles.date')}
          </Typography>
          <Typography fontSize='14px'>{dateOfDeposit}</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.transactionHistory.mobileTitles.smartVault'
            )}
          </Typography>
          <Typography fontSize='14px'>{vaultName}</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('components.organisms.transactionHistory.mobileTitles.type')}
          </Typography>
          <Typography fontSize='14px'>{transactionType[type]}</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('components.organisms.transactionHistory.mobileTitles.amount')}
          </Typography>
          <Typography fontSize='14px'>
            {amount &&
              amount?.map(({ amount, assetAddress }, index) => (
                <Typography
                  key={index}
                  sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                    fontSize: '14px',
                  }}
                >
                  {assetAddress === 'SVT'
                    ? formatUSD(formatEther(amount))
                    : formatUSD(amount)}{' '}
                  {tokenDetails[chain][assetAddress].symbol}
                </Typography>
              ))}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('components.organisms.transactionHistory.mobileTitles.asset')}
          </Typography>
          <Box display='flex' ml='0.2rem' alignItems='center'>
            {assetAddresses &&
              assetAddresses?.map((address, index) => (
                <Fragment key={index}>
                  {mapAssetTokenIcon(chain, address ?? '')}
                </Fragment>
              ))}
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.transactionHistory.mobileTitles.yourBalance'
            )}
          </Typography>
          <Typography fontSize='14px'>$ {formatUSD(total)}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default memo(TransactionHistoryMobileRow)
