import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import { UserTransactionHistory } from '@solidant/spool-v2-fe-lib'

import useLocale from '@/hooks/context/useLocale'
import useTransactionHistoryState from '@/hooks/context/useTransactionHistoryState'

import DataMissing from '@/components/molecules/DataMissing'
import TableWrapper from '@/components/molecules/TableWrapper'
import TransactionHistoryTableRow from '@/components/organisms/TransactionHistory/TransactionHistoryTable/TransactionHistoryTableRow'

import { transactionHistoryHeadCells } from '@/constants/transactionHistory'

interface TransactionHistoryTableProps {
  transactionHistory: UserTransactionHistory[]
  totalTransactions: number
}

const TransactionHistoryTable: React.FC<TransactionHistoryTableProps> = ({
  transactionHistory,
  totalTransactions,
}) => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    transactionHistorySort,
    handleTransactionHistoryTableSort,
  } = useTransactionHistoryState()

  const t = useLocale()

  return (
    <Box width='100%' border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='4px'>
      <TableWrapper
        header={transactionHistoryHeadCells}
        headerClass='default-table-head'
        pagination={{
          rowsPerPageOptions: [10, 20, 30],
          page: page,
          rowsPerPage: limit,
          count: totalTransactions,
          setPage: setPage,
          setRowsPerPage: setLimit,
        }}
        sortType={transactionHistorySort}
        handleSortClick={handleTransactionHistoryTableSort}
      >
        {transactionHistory && transactionHistory?.length ? (
          <TableBody>
            {transactionHistory?.map(
              (transactionHistory: UserTransactionHistory, index) => (
                <TransactionHistoryTableRow
                  key={transactionHistory.vault.name + index}
                  transactionHistory={transactionHistory}
                />
              )
            )}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  my='2rem'
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <DataMissing
                    text={t('components.organisms.transactionHistory.zeroData')}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </TableWrapper>
    </Box>
  )
}

export default TransactionHistoryTable
