import { Datum } from '@nivo/line'
import { DashboardStrategy, SortOrder } from '@solidant/spool-v2-fe-lib'

import { CreatorVault } from '@/types/dashboard'
import { SortType } from '@/types/table'

export type FeesChartValues = {
  performanceFeesChartValues: Datum[]
  managementFeesChartValues: Datum[]
}
export interface CreatorDashboardState {
  creatorVaultsTablePage: number
  creatorVaultsTableLimit: number
  creatorVaultsTableSort: SortType<CreatorVault>
  creatorVaultsStrategiesPage: number
  creatorVaultsStrategiesLimit: number
  creatorVaultsStrategiesSort: SortType<
    DashboardStrategy & { amountInUSDPercentage: string }
  >
  selectedVaults: CreatorVault[]
  tvrChartValues: Datum[]
  feesChartValues: FeesChartValues
  withdrawEarningsVault: CreatorVault
}

export interface CreatorDashboardContextProps extends CreatorDashboardState {
  handleSortClick: (
    key: keyof CreatorVault,
    creatorDashboardReceiptSort: SortType<CreatorVault>
  ) => void
  setCreatorVaultsTablePage: (creatorVaultsPage: number) => void
  setCreatorVaultsTableLimit: (creatorVaultsLimit: number) => void
  setSelectedVaults: (selectedVaults: CreatorVault[]) => void
  handleStrategiesSortClick: (
    key: keyof DashboardStrategy,
    creatorDashboardSort: SortType<DashboardStrategy>
  ) => void
  setVaultsStrategiesTablePage: (creatorVaultsPage: number) => void
  setVaultsStrategiesTableLimit: (creatorVaultsLimit: number) => void
  setTvrChartValues: (tvrChartValues: Datum[]) => void
  setFeesChartValues: (feesChartValues: FeesChartValues) => void
  setWithdrawEarningsVault: (
    withdrawEarningsVault: CreatorVault | undefined
  ) => void
}

export enum CreatorDashboardActionTypes {
  SET_CREATOR_VAULTS_TABLE_SORT = 'SET_CREATOR_VAULTS_TABLE_SORT',
  CHANGE_CREATOR_VAULTS_SORT_DIRECTION = 'CHANGE_CREATOR_VAULTS_SORT_DIRECTION',
  SET_CREATOR_VAULTS_TABLE_PAGE = 'SET_CREATOR_VAULTS_TABLE_PAGE',
  SET_CREATOR_VAULTS_TABLE_LIMIT = 'SET_CREATOR_VAULTS_TABLE_LIMIT',
  SET_SELECTED_VAULTS = 'SET_SELECTED_VAULTS',
  SET_VAULTS_STRATEGIES_TABLE_SORT = 'SET_VAULTS_STRATEGIES_TABLE_SORT',
  CHANGE_VAULTS_STRATEGIES_SORT_DIRECTION = 'CHANGE_VAULTS_STRATEGIES_SORT_DIRECTION',
  SET_VAULTS_STRATEGIES_TABLE_PAGE = 'SET_VAULTS_STRATEGIES_TABLE_PAGE',
  SET_VAULTS_STRATEGIES_TABLE_LIMIT = 'SET_VAULTS_STRATEGIES_TABLE_LIMIT',
  SET_TVR_CHARTS_VALUE = 'SET_TVR_CHARTS_VALUE',
  SET_FEES_CHART_VALUES = 'SET_FEES_CHART_VALUES',
  SET_WITHDRAW_EARNINGS_VAULT = 'SET_WITHDRAW_EARNINGS_VAULT',
}

export type CreatorDashboardAction =
  | {
      type: CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_SORT
      payload: {
        key: keyof CreatorVault
        direction: SortOrder
      }
    }
  | {
      type: CreatorDashboardActionTypes.CHANGE_CREATOR_VAULTS_SORT_DIRECTION
    }
  | {
      type: CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_PAGE
      payload: number
    }
  | {
      type: CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_LIMIT
      payload: number
    }
  | {
      type: CreatorDashboardActionTypes.SET_SELECTED_VAULTS
      payload: CreatorVault[]
    }
  | {
      type: CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_SORT
      payload: {
        key: keyof DashboardStrategy
        direction: SortOrder
      }
    }
  | {
      type: CreatorDashboardActionTypes.CHANGE_VAULTS_STRATEGIES_SORT_DIRECTION
    }
  | {
      type: CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_PAGE
      payload: number
    }
  | {
      type: CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_LIMIT
      payload: number
    }
  | {
      type: CreatorDashboardActionTypes.SET_TVR_CHARTS_VALUE
      payload: Datum[]
    }
  | {
      type: CreatorDashboardActionTypes.SET_FEES_CHART_VALUES
      payload: FeesChartValues
    }
  | {
      type: CreatorDashboardActionTypes.SET_WITHDRAW_EARNINGS_VAULT
      payload: CreatorVault
    }
