import { Datum } from '@nivo/line'
import {
  DashboardStrategy,
  InvestorSmartVault,
  SortOrder,
} from '@solidant/spool-v2-fe-lib'

import { SortType } from '@/types/table'
export interface InvestorDashboardState {
  investorSmartVaultPage: number
  investorSmartVaultLimit: number
  investorSmartVaultSort: SortType<InvestorSmartVault>
  investorStrategiesPage: number
  investorStrategiesLimit: number
  investorStrategiesSort: SortType<
    DashboardStrategy & { amountInUSDPercentage: string }
  >
  selectedVaults: InvestorSmartVault[]
  balanceChartValues: Datum[]
}
export interface InvestorDashboardContextProps extends InvestorDashboardState {
  handleInvestorSmartVaultSort: (
    key: keyof InvestorSmartVault,
    investorSmartVaultSort: SortType<InvestorSmartVault>
  ) => void
  setInvestorVaultsTablePage: (investorVaultsPage: number) => void
  setInvestorVaultsTableLimit: (investorVaultsLimit: number) => void
  setSelectedVaults: (selectedVaults: InvestorSmartVault[]) => void
  handleInvestorStrategySort: (
    key: keyof DashboardStrategy,
    investorStrategySort: SortType<DashboardStrategy>
  ) => void
  setVaultsStrategiesTablePage: (investorVaultsPage: number) => void
  setVaultsStrategiesTableLimit: (investorVaultsLimit: number) => void
  setBalanceChartValues: (balanceChartValues: Datum[]) => void
}

export enum InvestorDashboardActionTypes {
  SET_INVESTOR_SMART_VAULT_SORT = 'SET_INVESTOR_SMART_VAULT_SORT',
  CHANGE_INVESTOR_SMART_VAULT_SORT_DIRECTION = 'CHANGE_INVESTOR_SMART_VAULT_SORT_DIRECTION',
  SET_INVESTOR_SMART_VAULT_PAGE = 'SET_INVESTOR_SMART_VAULT_PAGE',
  SET_INVESTOR_SMART_VAULT_LIMIT = 'SET_INVESTOR_SMART_VAULT_LIMIT',
  SET_SELECTED_VAULTS = 'SET_SELECTED_VAULTS',
  SET_OPEN_VAULT = 'SET_OPEN_VAULT',
  SET_INVESTOR_STRATEGY_SORT = 'SET_INVESTOR_STRATEGY_SORT',
  CHANGE_INVESTOR_STRATEGY_SORT_DIRECTION = 'CHANGE_INVESTOR_STRATEGY_SORT_DIRECTION',
  SET_INVESTOR_STRATEGY_PAGE = 'SET_INVESTOR_STRATEGY_PAGE',
  SET_INVESTOR_STRATEGY_LIMIT = 'SET_INVESTOR_STRATEGY_LIMIT',
  SET_BALANCE_CHARTS_VALUE = 'SET_BALANCE_CHARTS_VALUE',
}

export type InvestorDashboardAction =
  | {
      type: InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_SORT
      payload: {
        key: keyof InvestorSmartVault
        direction: SortOrder
      }
    }
  | {
      type: InvestorDashboardActionTypes.CHANGE_INVESTOR_SMART_VAULT_SORT_DIRECTION
    }
  | {
      type: InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_PAGE
      payload: number
    }
  | {
      type: InvestorDashboardActionTypes.SET_INVESTOR_SMART_VAULT_LIMIT
      payload: number
    }
  | {
      type: InvestorDashboardActionTypes.SET_SELECTED_VAULTS
      payload: InvestorSmartVault[]
    }
  | {
      type: InvestorDashboardActionTypes.SET_OPEN_VAULT
      payload: InvestorSmartVault
    }
  | {
      type: InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_SORT
      payload: {
        key: keyof DashboardStrategy
        direction: SortOrder
      }
    }
  | {
      type: InvestorDashboardActionTypes.CHANGE_INVESTOR_STRATEGY_SORT_DIRECTION
    }
  | {
      type: InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_PAGE
      payload: number
    }
  | {
      type: InvestorDashboardActionTypes.SET_INVESTOR_STRATEGY_LIMIT
      payload: number
    }
  | {
      type: InvestorDashboardActionTypes.SET_BALANCE_CHARTS_VALUE
      payload: Datum[]
    }
