import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import getLocale from '@/locales/getLocale'

import { align, HeadCell } from '@/types/table'

const t = getLocale()

export const currency = '$'

export const riskModelHeadCells: readonly HeadCell[] = [
  {
    id: 'model',
    numeric: false,
    label: t('constants.createVault.riskModelHeadCells.model.label'),
    notSortable: true,
    width: '265px',
    align: 'left',
  },
  {
    id: 'support',
    numeric: true,
    label: t('constants.createVault.riskModelHeadCells.support.label'),
    notSortable: true,
    width: '190px',
    align: 'left',
  },
  {
    id: 'risk-factors',
    numeric: false,
    label: t('constants.createVault.riskModelHeadCells.riskFactors.label'),
    notSortable: true,
    width: '350px',
    align: 'left',
  },
  {
    id: 'description',
    numeric: false,
    label: t('constants.createVault.riskModelHeadCells.description.label'),
    notSortable: true,
    width: '350px',
    align: 'left',
  },
]

export const strategiesHeadCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: t('constants.createVault.name.label'),
    width: '265px',
    align: 'left',
  },
  {
    id: 'apy',
    numeric: true,
    label: t('constants.createVault.apy.label'),
    width: '190px',
    align: 'right',
  },
  {
    id: 'riskScore',
    numeric: false,
    label: t('constants.createVault.riskScore.label'),
    width: '100px',
    align: 'left',
    infoTitle: t('components.organisms.tooltips.riskScoreTooltip.text'),
  },
  {
    id: 'assetGroup',
    numeric: false,
    label: t('constants.createVault.strategiesHeadCells.assetGroupId.label'),
    width: '500px',
    align: 'left',
  },
  {
    id: 'notice',
    numeric: false,
    label: t('constants.createVault.strategiesHeadCells.info.label'),
    notSortable: true,
    width: '350px',
    align: 'center',
  },
]

export const riskAllocationStrategiesHeadCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: t(
      'constants.createVault.riskAllocationStrategiesHeadCells.strategies.label'
    ),
    width: '300px',
    align: 'left',
    notSortable: true,
  },
  {
    id: 'apy',
    numeric: true,
    label: t('constants.createVault.histApy.label'),
    width: '150px',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'total-value',
    numeric: false,
    label: t(
      'constants.createVault.riskAllocationStrategiesHeadCells.totalValue.label'
    ),
    width: '150px',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'allocation',
    numeric: false,
    label: t(
      'constants.createVault.riskAllocationStrategiesHeadCells.allocation.label'
    ),
    align: 'right',
    width: '200px',
    notSortable: true,
  },
  {
    id: 'riskScore',
    numeric: false,
    label: t('constants.createVault.riskScore.label'),
    width: '100px',
    align: 'center',
    notSortable: true,
    infoTitle: t('components.organisms.tooltips.riskScoreTooltip.text'),
  },
]

export const guardsActionsHeadCells: readonly HeadCell[] = [
  {
    id: 'conditions',
    numeric: false,
    label: t('constants.createVault.guardsActionsHeadCells.type.label'),
    align: 'left',
    notSortable: true,
  },
]

export interface GuardItem {
  id: string
  title: string
  type: string
  breadcrumbs: string[]
  modalId: GuardModal
  modalTitle?: string
}

export interface Guard {
  title: string
  items: GuardItem[]
}

export interface ActionItemForm {
  nameFee: string
  max?: number
  labelFee: string
  namePeriod: string
  labelPeriod: string
}

export interface ActionItem {
  id: string
  title: string
  type: string
  description: string
  form?: ActionItemForm
}

export interface Action {
  title: string
  items: ActionItem[]
}

export const whitelistHeadCells: {
  id: string
  numeric: boolean
  label: string
  width?: string
}[] = [
  {
    id: 'id',
    numeric: false,
    label: t('constants.createVault.id.label'),
    width: '40px',
  },
  {
    id: 'address',
    numeric: false,
    label: t('constants.createVault.address.label'),
  },
  {
    id: 'role',
    numeric: false,
    label: t('constants.createVault.role.label'),
  },
]

export const svtHeadCells: {
  id: string
  numeric: boolean
  label: string
  width?: string
}[] = [
  {
    id: 'id',
    numeric: false,
    label: t('constants.createVault.id.label'),
    width: '40px',
  },
  {
    id: 'name',
    numeric: false,
    label: t('constants.createVault.name.label'),
    width: '240px',
  },
  {
    id: 'address',
    numeric: false,
    label: t('constants.createVault.address.label'),
  },
]

export const nftHeadCells: {
  id: string
  numeric: boolean
  label: string
  width?: string
}[] = [
  {
    id: 'id',
    numeric: false,
    label: t('constants.createVault.id.label'),
    width: '40px',
  },
  {
    id: 'nft',
    numeric: false,
    label: t('constants.createVault.nftHeadCells.nft.label'),
  },
  {
    id: 'role',
    numeric: false,
    label: t('constants.createVault.role.label'),
  },
  // {
  //   id: 'nftAmounts',
  //   numeric: false,
  //   label: t('constants.createVault.nftHeadCells.nft.minimumAmount'),
  // },
]

export const SVTHeadCells: {
  id: string
  numeric: boolean
  label: string
  width?: string
  align?: align
}[] = [
  {
    id: 'id',
    numeric: false,
    label: t('constants.createVault.id.label'),
    width: '88px',
  },
  {
    id: 'value',
    numeric: false,
    label: t('constants.createVault.value.label'),
    align: 'center',
    width: '256px',
  },
]

export const assetAmountHeadCells: {
  id: string
  numeric: boolean
  label: string
  align?: align
}[] = [
  {
    id: 'id',
    numeric: false,
    label: t('constants.createVault.id.label'),
  },
  {
    id: 'asset',
    numeric: false,
    label: t('constants.createVault.assetAmountHeadCells.asset.label'),
  },
  {
    id: 'requirement',
    numeric: false,
    label: t('constants.createVault.assetAmountHeadCells.requirement.label'),
  },
  {
    id: 'value',
    numeric: true,
    label: t('constants.createVault.value.label'),
    align: 'right',
  },
]

export enum GuardModal {
  WHITELIST_DEPOSITORS = 'WHITELIST_DEPOSITORS',
  WHITELIST_BENEFICIARIES = 'WHITELIST_BENEFICIARIES',
  WHITELIST_SVT = 'WHITELIST_SVT',
  NFT_DEPOSITORS = 'NFT_DEPOSITORS',
  NFT_BENEFICIARIES = 'NFT_BENEFICIARIES',
  ASSETS = 'ASSETS',
  SVT_NONTRANSFERABLE = 'SVT_NONTRANSFERABLE',
  HOLDING_PERIOD = 'HOLDING_PERIOD',
}

export const MANAGEMENT_FEE_OPTIONS = {
  min: 0,
  max: 5,
  step: 0.01,
}

export const PERFORMANCE_FEE_OPTIONS = {
  min: 0,
  max: 20,
  step: 0.05,
}
export const HIGH_PERFORMANCE_FEE_OPTIONS = {
  min: 0,
  max: 90,
  step: 0.05,
}

export const ASSET_GROUPS = {
  [SupportedNetworks.ETHEREUM]: [
    {
      id: '0',
      assets: [
        {
          id: 'All Assets',
          label: 'All Assets',
        },
      ],
    },
    {
      id: '1',
      assets: [
        {
          id: '$WETH',
          label: 'WETH',
          address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        },
      ],
    },
    {
      id: '2',
      assets: [
        {
          id: '$USDC',
          label: 'USDC',
          address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        },
      ],
    },
    {
      id: '3',
      assets: [
        {
          id: '$USDT',
          label: 'USDT',
          address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        },
      ],
    },
    {
      id: '4',
      assets: [
        {
          id: '$DAI',
          label: 'DAI',
          address: '0x6b175474e89094c44da98b954eedeac495271d0f',
        },
      ],
    },
    {
      id: '5',
      assets: [
        {
          id: '$DAI',
          label: 'DAI',
          address: '0x6b175474e89094c44da98b954eedeac495271d0f',
        },
        {
          id: '$USDC',
          label: 'USDC',
          address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        },
        {
          id: '$USDT',
          label: 'USDT',
          address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        },
      ],
    },
    {
      id: '6',
      assets: [
        {
          id: '$USDe',
          label: 'USDe',
          address: '0x4c9edd5852cd905f086c759e8383e09bff1e68b3',
        },
      ],
    },
    {
      id: '7',
      assets: [
        {
          id: '$PYUSD',
          label: 'PYUSD',
          address: '0x6c3ea9036406852006290770bedfcaba0e23a0e8',
        },
      ],
    },
    {
      id: '8',
      assets: [
        {
          id: '$WBTC',
          label: 'WBTC',
          address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        },
      ],
    },
  ],
  [SupportedNetworks.ARBITRUM]: [
    {
      id: '0',
      assets: [
        {
          id: 'All Assets',
          label: 'All Assets',
        },
      ],
    },
    {
      id: '1',
      assets: [
        {
          id: '$DAI',
          label: 'DAI',
          address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        },
      ],
    },
    {
      id: '2',
      assets: [
        {
          id: '$USDC',
          label: 'USDC',
          address: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        },
      ],
    },
    {
      id: '3',
      assets: [
        {
          id: '$USDT',
          label: 'USDT',
          address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
        },
      ],
    },
    {
      id: '4',
      assets: [
        {
          id: '$WETH',
          label: 'WETH',
          address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        },
      ],
    },
    {
      id: '5',
      assets: [
        {
          id: '$WETH',
          label: 'WETH',
          address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        },
        {
          id: '$USDC',
          label: 'USDC',
          address: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        },
      ],
    },
  ],
  [SupportedNetworks.SEPOLIA]: [
    {
      id: '0',
      assets: [
        {
          id: 'All Assets',
          label: 'All Assets',
        },
      ],
    },
    {
      id: '1',
      assets: [
        {
          id: '$WETH',
          label: 'WETH',
          address: '0x88b25a14dae46c33ae3eef2b45a5ec758d9a5ec6',
        },
      ],
    },
    {
      id: '2',
      assets: [
        {
          id: '$USDC',
          label: 'USDC',
          address: '0xa6b92fcd4ee124353c8a6acf1edb574f46f3f8df',
        },
      ],
    },
    {
      id: '3',
      assets: [
        {
          id: '$USDT',
          label: 'USDT',
          address: '0x72d6a42377e7511ff69aab65fb3c075512fcbae5',
        },
      ],
    },
    {
      id: '4',
      assets: [
        {
          id: '$DAI',
          label: 'DAI',
          address: '0x2a3a3872c242c35093a8fc48dac838c4b2d24a03',
        },
      ],
    },
  ],
}
