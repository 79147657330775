const Oneinch = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 706.8 665.2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill='#FFFFFF'
        d='M168.2,366.5L186.9,226L24.7,113.4l148.2,50.2l35-53.7l127.8-79.3l281.7,155.2l14.6,236.8L506.5,597l-99.2,15.2
	l51.3-93.9v-90.4l-37.3-70.6l-37.9-25.1l-58.3,60.1v63.6l-45.5,42.6l-57.8,7L196.2,520l-42-13.4l-17.5-63l31.5-44.3V366.5z'
      />
      <path
        fill='#94A6C3'
        d='M427.2,112.2c-30.9-6.4-64.7-4.7-64.7-4.7s-11.1,51.3-79.9,64.8C283.1,172.3,373.5,203.2,427.2,112.2z'
      />
      <path
        fill='#94A6C3'
        d='M455.2,577.7c37.3-29.2,65.3-69.4,78.2-114.9c0.6-1.7,5.8-4.7,9.3-7c5.8-3.5,11.7-6.4,12.8-11.1
	c2.3-13.4,3.5-27.4,3.5-41.4c0-5.2-5.3-10.5-10.5-15.7c-4.1-3.5-8.2-7.6-8.2-10.5c-5.8-53.1-30.3-102.7-69.4-138.8l-4.1,4.1
	c37.9,35.6,62.4,84,67.7,135.3c0.6,4.7,5.2,9.3,9.9,14c4.1,3.5,8.8,8.8,8.8,11.1c0,13.4-1.2,26.8-3.5,40.2c-0.6,2.3-5.8,4.7-9.9,7
	c-5.8,2.9-11.1,5.8-12.2,10.5c-14,49.6-46.1,92.8-88.7,120.8c7.6-16.3,31.5-69.4,44.3-96.3l-2.3-86.3l-74.1-71.7l-42,5.8l-46.1,74.7
	c0,0,21.6,27.4-8.8,59.5c-29.7,31.5-53.1,38.5-53.1,38.5l-21.6-11.7c6.4-8.2,19.3-20.4,29.2-28.6c16.9-14,33.8-15.2,33.8-30.3
	C298.9,403.3,265,412,265,412l-12.3,11.7l-5.2,43.2L221.9,499l-2.9-0.6l-42-9.3c0,0,25.7-13.4,29.8-28.6c4.1-14.6-8.2-63-8.8-65.9
	c0.6,0.6,12.3,10.5,17.5,26.8c9.3-25.7,21.6-50.2,25.1-52.5c3.5-2.3,50.7-27.4,50.7-27.4l-15.7,41.4l11.7-6.4l28-68.8
	c0,0,27.4-13.4,47.8-13.4c36.7-0.6,91-45.5,66.5-126c7,2.9,128.3,63.6,149.3,182.6C594.6,442.4,542.7,528.1,455.2,577.7z'
      />
      <path
        fill='#1B314F'
        d='M316.4,125c13.4-15.8,8.2-39.1,8.2-39.1l-39.1,57.8C284.9,143.7,299.4,144.3,316.4,125z'
      />
      <path
        fill='#1B314F'
        d='M185.1,440.6l4.7-23.3c0,0-19.3,33.8-21,38.5c-1.8,5.3,1.2,14.6,8.7,14c7.6-0.6,16.9-11.7,16.9-19.8
	C194.4,439.5,185.1,440.6,185.1,440.6z'
      />
      <path
        fill='#1B314F'
        d='M531.6,69.6c0,0,29.2,1.2,59.5,4.7c-68.3-53.7-133-69.4-185.5-69.4c-72.3,0-121.3,29.8-124.3,31.5l22.8-36.2
	c0,0-91-8.8-123.1,87.5c-8.2-20.4-15.7-50.2-15.7-50.2S118,79,140.2,147.8C85.9,128,8.4,100.5,5.4,100c-4.1-0.6-5.3,1.2-5.3,1.2
	s-1.2,1.7,2.3,4.7C8.9,111,131.4,201.5,158.3,219c-5.8,21-5.8,30.9,0,40.8c8.2,13.4,8.7,20.4,7.6,30.3c-1.2,9.9-11.7,95.7-14,106.2
	c-2.3,10.5-26.8,47.8-25.7,58.9c1.2,11.1,16.3,58.3,29.8,63.6c9.9,3.5,34.4,11.1,50.7,11.1c5.8,0,11.1-1.2,13.4-3.5
	c9.9-8.7,12.8-10.5,19.8-10.5c0.6,0,1.2,0,1.7,0c2.9,0,6.4,0.6,10.5,0.6c9.3,0,21.6-1.8,30.3-9.9c12.8-12.8,35-30.3,42-38.5
	c8.8-11.1,13.4-26.2,11.1-41.4c-1.8-14,5.8-26.3,14.6-38.5c11.1-14.6,31.5-40.8,31.5-40.8C421.9,377.6,447,423.7,447,475
	c0,91-79.3,164.5-177.3,164.5c-15.2,0-29.7-1.7-44.3-5.2c44.9,15.7,82.8,21,113.8,21c65.9,0,100.9-23.9,100.9-23.9
	s-12.2,15.8-32.1,33.8c0.6,0,0.6,0,0.6,0c109.1-15.2,162.2-105,162.2-105s-4.1,29.2-9.3,49C706.6,500.1,682.1,363.6,681.5,359
	c1.2,1.7,15.8,19.2,23.3,28.6C728.2,147.2,531.6,69.6,531.6,69.6z M308.2,453.5c-2.3,2.9-12.2,11.7-19.2,18.1
	c-7,6.4-14.6,12.8-20.4,18.7c-2.3,2.3-7,3.5-14,3.5c-2.3,0-4.7,0-6.4,0c-1.2,0-2.3,0-3.5,0s-1.8,0-2.3,0c-1.8,0-3.5,0-5.3,0
	c8.8-11.7,34.4-38.5,43.2-44.3c10.5-7,15.8-14,9.3-26.2c-6.4-12.3-23.3-9.3-23.3-9.3s9.9-4.1,18.7-4.1c-11.1-2.9-25.1,0-31.5,6.4
	c-7,6.4-5.8,29.2-8.7,43.7c-2.9,15.2-12.8,22.8-28,36.8c-8.2,7.6-14,9.9-18.7,9.9c-9.9-1.7-21.6-4.7-29.8-7.6
	c-5.8-7.6-14.6-32.7-16.9-43.2c1.7-5.8,8.7-18.1,12.2-25.1c7-13.4,11.1-21,12.3-28c2.3-9.9,9.9-71.2,12.8-96.8
	c7.6,9.9,18.1,26.3,15.7,36.8c16.9-23.9,4.7-47.3-1.2-56.6c-5.2-9.3-12.2-28-6.4-47.8c5.8-19.8,26.8-74.7,26.8-74.7s7,12.3,16.9,9.9
	c9.9-2.3,89.8-122.5,89.8-122.5s21.6,47.2-1.2,81.7c-23.3,34.4-46.1,40.8-46.1,40.8s32.1,5.8,61.8-15.8
	c12.2,28.6,23.9,58.3,24.5,62.4c-1.8,4.1-25.1,60.1-27.4,63.6c-1.2,1.2-9.3,3.5-15.2,4.7c-9.9,2.9-15.7,4.7-18.1,6.4
	c-4.1,3.5-22.8,54.8-31.5,79.9c-10.5,2.9-21,8.8-28.6,20.4c4.1-2.9,16.9-4.7,26.3-5.8c8.2-0.6,33.2,12.8,39.7,37.9
	c0,0.6,0,0.6,0,1.2C315.8,437.7,312.9,446.5,308.2,453.5z M253.4,460.5c5.3-7.6,4.7-20.4,5.3-24.5c0.6-4.1,1.7-11.7,6.4-12.8
	c4.7-1.2,15.8,0.6,15.8,8.7c0,7.6-8.2,9.3-14,14.6C262.7,450.5,254.5,459.3,253.4,460.5z M486.1,349c5.8-29.7,6.4-55.4,4.7-76.4
	c22.7,30.3,36.7,67.1,40.8,105c0.6,4.7,5.2,9.3,9.9,14c4.1,3.5,8.8,8.2,8.8,11.1c0,13.4-1.2,26.8-3.5,40.3c-0.6,1.7-5.8,4.7-9.9,7
	c-5.8,2.9-11.1,5.8-12.2,10.5c-12.8,44.9-40.3,84.6-77,112.6C501.9,516.5,528.1,423.1,486.1,349z M449.4,578.3
	c37.9-29.2,67.1-70,79.9-116.1c0.6-1.7,5.8-4.7,9.3-7c5.8-2.9,11.7-6.4,12.8-11.1c2.3-13.4,3.5-27.4,3.5-41.4
	c0-5.2-5.3-10.5-10.5-15.7c-2.9-3.5-7.6-7.6-7.6-10.5c-4.7-42.6-21.6-82.8-47.8-116.1c-11.7-70-58.3-91.6-59.5-92.2
	c1.2,1.8,31.5,45.5,10.5,96.8c-21.6,51.9-77,43.8-81.7,44.3c-4.7,0-22.7,23.3-45.5,66.5c-2.9-1.2-15.2-4.1-29.2-1.7
	c10.5-29.2,26.3-70.6,29.2-74.1c1.2-1.2,9.9-3.5,15.8-5.2c11.1-2.9,16.3-4.7,18.1-7c1.2-1.8,7-15.2,12.8-29.2
	c5.3,0,18.7-1.2,19.8-1.8c1.2-1.2,12.3-29.7,12.3-33.2c0-2.9-22.8-59.5-31.5-81.1c4.1-4.7,8.2-10.5,12.2-16.9
	C491.9,138.5,585.3,240,585.3,363C585.3,457.5,529.9,539.8,449.4,578.3z'
      />
      <path
        fill='#1B314F'
        d='M294.2,263.3c11.1-12.8,5.3-36.7-15.2-40.8c5.3-12.2,12.8-36.7,12.8-36.7s-59.5,93.3-64.7,95.1
	c-5.3,1.8-10.5-18.7-10.5-18.7c-11.1,42.6,18.7,48.4,22.2,35C255.1,293,283.1,275.5,294.2,263.3z'
      />
      <path
        fill='#FFD923'
        d='M243.4,286l30.3-51.9c0,0,17.5,8.8,8.7,22.8C271.4,273.2,243.4,286,243.4,286z'
      />
      <path
        fill='#D82122'
        d='M618.5,526.4c-8.8,11.7-18.7,23.9-30.3,35.6c75.2-144.7,3.5-277.1,0.6-282.3c5.3,5.3,10.5,11.1,15.2,16.3
	C661.7,360.1,668.7,456.4,618.5,526.4z'
      />
      <path
        fill='#D82122'
        d='M688.5,340.3c-26.3-70.6-63.6-130.7-145.8-184.9c-79.3-52.5-164.5-48.4-169.2-47.8c-0.6,0-1.2,0-1.2,0
	c2.9-1.2,5.8-1.8,8.7-2.3c18.1-5.8,41.4-10.5,64.8-13.4C507.7,83,570.1,104,612.7,149c0.6,0.6,0.6,0.6,1.2,1.2
	C662.3,201.5,687.4,265.6,688.5,340.3z'
      />
      <path
        fill='#D82122'
        d='M524,51.5c-86.9-16.3-142.9-8.2-183.2,7c-1.2-4.7-5.3-14-8.8-21.6C319.9,51.5,307,69,298.9,80.1
	c-22.2,15.2-31.5,29.8-31.5,29.8c12.8-43.8,50.2-76.4,95.7-84.6c12.8-2.3,26.8-3.5,42-3.5C445.3,22.4,485.5,32.3,524,51.5z'
      />
      <path
        fill='#D82122'
        d='M202.6,163.5c-68.2-2.3-45.5-81.7-44.3-86.3C158.3,77.8,162.9,139.6,202.6,163.5z'
      />
      <path
        fill='#D82122'
        d='M269.7,20.6c-52.5,31.5-42,106.7-42,106.7C177.5,51,265.6,22.4,269.7,20.6z'
      />
      <path
        fill='#FFFFFF'
        d='M183.4,184.5c3.5,2.9,7,8.2,2.9,15.8c-2.3,4.1-5.8,3.5-11.1,1.2c-7-3.5-49-28-86.9-53.1
	c43.2,15.2,86.9,31.5,93.9,35C182.2,183.4,182.8,184,183.4,184.5z'
      />
    </svg>
  )
}

export default Oneinch
