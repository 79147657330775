import { Typography } from '@mui/material'

import version from '../../../../version.json'
const Version = () => {
  return (
    <Typography fontSize='12px' color='#21232261' mt='0.5rem' pl='1.2rem'>
      {' '}
      {`Version: ${version.version}`}
    </Typography>
  )
}

export default Version
