import { Warning } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

interface SmartVaultErrorWithTextProps {
  errorText: string
}

const SmartVaultErrorWithText: React.FC<SmartVaultErrorWithTextProps> = ({
  errorText,
}) => (
  <Box
    maxWidth='100%'
    display='flex'
    justifyContent='center'
    sx={{ p: 1, py: 4 }}
    border='1px solid rgba(0, 0, 0, 0.12)'
  >
    <Box display='flex' flexDirection='column' alignItems='center' gap='1.5rem'>
      <Warning
        style={{ width: '50', height: '50' }}
        color='error'
        className='hook-loading'
      />
      <Typography>{errorText}...</Typography>
    </Box>
  </Box>
)

export default SmartVaultErrorWithText
