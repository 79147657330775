import { Fade } from '@mui/material'

const PageTransition: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  return (
    <Fade in={true} timeout={300} mountOnEnter unmountOnExit>
      {children}
    </Fade>
  )
}

export default PageTransition
