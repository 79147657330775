import { Box } from '@mui/material'

import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import MultiAssetDepositSwapTransaction from '@/components/organisms/SmartVaults/CollapsibleRow/Deposit/Swap/MultiAssetDepositSwapTransaction'
import SingleAssetDeposit from '@/components/organisms/SmartVaults/VaultActions/Deposit/SingleAssetDeposit'

export enum UNFULFILLED_DEPOSIT_CONDITION {
  WRONG_NETWORK = 'WRONG_NETWORK',
  GUARD_CONDITIONS_UNFULFILLED = 'GUARD_CONDITIONS_UNFULFILLED',
}

const Deposit = () => {
  const { smartVaultData } = useVaultsPage()

  const { assetGroup } = smartVaultData

  const { assetGroupTokens } = assetGroup

  return (
    <Box>
      {assetGroupTokens.length === 1 ? (
        <SingleAssetDeposit />
      ) : (
        <MultiAssetDepositSwapTransaction />
      )}
    </Box>
  )
}

export default Deposit
