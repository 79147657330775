import { Box, TableBody } from '@mui/material'

import TableWrapper from '@/components/molecules/TableWrapper'
import GuardsActionsTableRow from '@/components/organisms/CreateVault/Launch/GuardsActions/GuardsActionsTableRow'

import { guardsActionsHeadCells } from '@/constants/createVault'

import { GuardsActionsData } from '@/types/create-vault'

interface GuardsActionsTableProps {
  data: GuardsActionsData[]
}

const GuardsActionsTable: React.FC<GuardsActionsTableProps> = ({ data }) => {
  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10)

  const guardsConditionExists = data.filter((data) => data.conditions).length

  if (!guardsConditionExists) return

  return (
    <Box
      width='100%'
      border='1px solid rgba(0, 0, 0, 0.12)'
      borderRadius='4px'
      height='fit-content'
    >
      <TableWrapper
        header={guardsActionsHeadCells}
        headerClass='default-table-head'
        // pagination={{
        //   rowsPerPageOptions: [5, 10, 25],
        //   page: page,
        //   rowsPerPage: rowsPerPage,
        //   count: data.length,
        //   setPage: setPage,
        //   setRowsPerPage: setRowsPerPage,
        // }}
      >
        <TableBody>
          {data
            // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map(({ conditions }, index) => {
              if (!conditions.length) return
              return (
                <GuardsActionsTableRow key={index} conditions={conditions} />
              )
            })}
        </TableBody>
      </TableWrapper>
    </Box>
  )
}

export default GuardsActionsTable
