const FixedAllocation = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 2.47L9 9L4.62 4.62L0 8V5.52L4.83 2L9.21 6.38L18 0V2.47ZM18 10H13.3L9.13 13.34L3 7.41L0 9.54V12L2.8 10L9 16L14 12H18V10Z'
        fill='black'
        fillOpacity='0.54'
      />
    </svg>
  )
}

export default FixedAllocation
