import { Box, Fade, LinearProgress } from '@mui/material'

const TopPageLoading: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      <Fade in={true} unmountOnExit>
        <LinearProgress />
      </Fade>
    </Box>
  )
}

export default TopPageLoading
