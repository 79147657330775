import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress'
import { useWeb3React } from '@web3-react/core'
import { ChangeEvent, useEffect, useState, useTransition } from 'react'
import { useParams } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useAssetGroups from '@/hooks/smartVaults/useAssetGroups'
import useSmartVaults from '@/hooks/smartVaults/useSmartVaults'
import useDebounce from '@/hooks/utils/useDebounce'

import SpoolSelect from '@/components/atoms/SpoolSelect'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { gaIds } from '@/constants/googleAnalyticIDs'

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  height: 20,
  width: 20,
  marginRight: '4px',
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyItems: 'start',
  alignItems: 'center',
  height: '44px',
  padding: theme.spacing(1.5, 2),
}))

const Filters = () => {
  const { vaultId } = useParams()

  const { chain } = useConnectionState()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const {
    //marketId,
    //setMarketId,
    assetGroupId,
    setAssetGroupId,
    riskModelProviderId,
    //setRiskModelProviderId,
    search,
    setSearch,
    setPage,
    page,
    limit,
    smartVaultsTableSort,
  } = useSmartVaultsTableState()
  const { account } = useWeb3React()
  const t = useLocale()
  //const { riskModels } = useRiskModels()
  const { assetGroups } = useAssetGroups()

  const [keywords, setKeywords] = useState(search)
  const [isPending, startTransition] = useTransition()
  const [initialLoad, setInitialLoad] = useState(true)

  const debouncedKeywords = useDebounce(keywords)

  const { isLoading } = useSmartVaults(
    page,
    limit,
    assetGroupId,
    riskModelProviderId,
    smartVaultsTableSort,
    search,
    account
  )

  useEffect(() => {
    if (vaultId && initialLoad) {
      setKeywords(vaultId)
      setInitialLoad(false)
    }
  }, [vaultId, isLoading])

  useEffect(() => {
    startTransition(() => {
      setSearch(debouncedKeywords)

      if (page > 0) {
        setPage(0)
      }
    })
  }, [debouncedKeywords])

  return (
    <Box
      display='flex'
      padding={(theme) => theme.spacing(2)}
      py={(theme) => theme.spacing(3)}
      border='1px solid rgba(0, 0, 0, 0.12)'
      borderRadius='4px 4px 0 0'
      borderBottom={!belowPhabletBreakpoint && 'none'}
      mb={belowPhabletBreakpoint && '1rem'}
    >
      <Box
        display='flex'
        width='100%'
        flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
        borderRadius='4px 4px 0 0'
        gap={(theme) => theme.spacing(2)}
      >
        <SpoolSelect
          label=''
          value={assetGroupId ? assetGroupId : 'all'}
          handleChange={(event) =>
            setAssetGroupId(
              event.target.value !== 'all'
                ? (event.target.value as string)
                : undefined
            )
          }
        >
          <StyledMenuItem value='all'>
            <Box display='flex'>
              <Typography variant='body2'>
                {t('components.organisms.smartVaults.filters.assetGroup.all')}
              </Typography>
            </Box>
          </StyledMenuItem>

          {assetGroups &&
            assetGroups?.map(({ id, assetGroupTokens }) => {
              if (id === '0') return

              return (
                <StyledMenuItem
                  id={
                    gaIds.filter.main +
                    '-' +
                    gaIds.filter.page.smartVaults +
                    '-' +
                    gaIds.filter.assetGroup
                  }
                  key={id}
                  value={id}
                >
                  <Box display='flex'>
                    {assetGroupTokens.map(({ symbol, address }, index) => (
                      <Box key={index} display='flex' marginRight='6px'>
                        <StyledIconButton disableRipple>
                          {mapAssetTokenIcon(chain, address ?? '')}
                        </StyledIconButton>
                        <Typography variant='body2'>{symbol}</Typography>
                      </Box>
                    ))}
                  </Box>
                </StyledMenuItem>
              )
            })}
        </SpoolSelect>
        <TextField
          id={
            gaIds.filter.main +
            '-' +
            gaIds.filter.page.smartVaults +
            '-' +
            gaIds.filter.search
          }
          variant='outlined'
          fullWidth
          label={t('components.organisms.smartVaults.filters.textField.label')}
          size='small'
          value={keywords}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setKeywords(event.target.value)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {isPending && (
                  <CircularProgress
                    variant='indeterminate'
                    disableShrink
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      animationDuration: '550ms',
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                      },
                    }}
                    size={20}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  )
}

export default Filters
