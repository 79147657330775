import { TableBody } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useRiskModelAndStrategies from '@/hooks/createVault/useRiskModelAndStrategies'

import DataLoading from '@/components/molecules/DataLoading'
import TableWrapper from '@/components/molecules/TableWrapper'
import StrategiesTableRow from '@/components/organisms/CreateVault/Strategies/StrategiesTableRow'
import { HIDDEN_STRATEGIES } from '@/components/organisms/Strategies/StrategiesTable'

import { strategiesHeadCells } from '@/constants/createVault'
import { mapRiskModelConfig } from '@/constants/riskModels/riskModels'
import { sort } from '@/utils/table'

import { StrategiesDto } from '@/types/create-vault'

const StrategiesTable: React.FC = () => {
  const { chain } = useConnectionState()

  const { riskModelsAndStrategies, isLoading } = useRiskModelAndStrategies()
  const {
    selectedRiskModel,
    filterByAssetGroup,
    filterBySearchTerm,
    strategySort,
    filterStrategy,
    handleSortClick,
  } = useCreateVaultState()
  const [availableStrategies, setAvailableStrategies] = useState<
    StrategiesDto[]
  >([])
  const [filteredStrategies, setfilteredStrategies] = useState<StrategiesDto[]>(
    []
  )

  // previously used for pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const t = useLocale()

  useEffect(() => {
    const riskModelConfig = mapRiskModelConfig(chain)

    setAvailableStrategies(
      riskModelsAndStrategies
        .filter(
          (riskModelAndStrategies) =>
            riskModelAndStrategies.riskModelId === selectedRiskModel
        )[0]
        .strategies.map((strategy) => {
          return {
            ...strategy,
            notice: {
              description: 'Data to be added',
            },
            riskModelName: riskModelConfig[selectedRiskModel].riskModelName,
          }
        })
        .filter(
          ({ address }) => !HIDDEN_STRATEGIES.includes(address)
        ) as StrategiesDto[]
    )
  }, [selectedRiskModel])

  useEffect(() => {
    if (!availableStrategies) return

    setPage(0)

    const filtered = availableStrategies.filter((strategy) =>
      filterStrategy(strategy)
    )

    setfilteredStrategies(filtered)
  }, [availableStrategies, filterBySearchTerm, filterByAssetGroup])

  if (isLoading) return <DataLoading loadingText='Loading Strategies' />

  if (!isLoading && availableStrategies.length === 0)
    return (
      <Box
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
      >
        {t('store.createVault.noStrategies')}
      </Box>
    )

  return (
    availableStrategies.length > 0 &&
    !isLoading && (
      <Box
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
      >
        <TableWrapper
          header={strategiesHeadCells}
          headerClass='create-vault-table-head'
          withCheckbox
          pagination={{
            rowsPerPageOptions: [10, 20, 30],
            page: page,
            rowsPerPage: rowsPerPage,
            count: filteredStrategies.length,
            setPage: setPage,
            setRowsPerPage: setRowsPerPage,
          }}
          handleSortClick={handleSortClick}
          sortType={strategySort}
        >
          <TableBody>
            {filteredStrategies
              .sort((a, b) => sort(a, b, strategySort))
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .filter((strategy) => {
                return !HIDDEN_STRATEGIES.includes(strategy.address)
              })
              .map((strategiesData: StrategiesDto) => (
                <StrategiesTableRow
                  key={strategiesData.address}
                  strategiesData={strategiesData}
                />
              ))}
          </TableBody>
        </TableWrapper>
      </Box>
    )
  )
}

export default StrategiesTable
