import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

const useMetaVaultDeployer = () => {
  const { sdkExternal } = useContext(AppContext)

  const { account } = useWeb3React()

  const { data, error } = useSWR(
    sdkExternal && account ? ['metaVaultDeployer', account] : null,
    async () => {
      return await sdkExternal.views.metaVault.hasMetaVaultDeployerRole(account)
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    isWhitelisted: data,
    isLoading: !data && !error,
    error,
  }
}

export default useMetaVaultDeployer
