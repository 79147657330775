import { SortOrder } from '@solidant/spool-v2-fe-lib'

import { InvestorDashboardState } from '@/store/investorDashboard/investorDashboard.types'

export const initialState: InvestorDashboardState = {
  investorSmartVaultLimit: 10,
  investorSmartVaultPage: 0,
  investorSmartVaultSort: {
    key: 'balance',
    direction: SortOrder.DESC,
  },
  selectedVaults: [],
  investorStrategiesLimit: 10,
  investorStrategiesPage: 0,
  investorStrategiesSort: {
    key: 'amountInUSDPercentage',
    direction: SortOrder.DESC,
  },
  balanceChartValues: [],
}
