import { SmartVaultFlush } from '@/types/smart-vault'

export const getFlushExecutedState = (
  flush: SmartVaultFlush | undefined
): string => {
  if (flush && !flush.isExecuted) {
    return 'In Progress'
  }

  return 'Completed'
}

export const getDHWExecutedState = (
  flush: SmartVaultFlush | undefined
): string => {
  if (flush) {
    if (
      !flush.isExecuted ||
      flush.strategyDHWs?.some((strategyDHW) => !strategyDHW.isExecuted)
    )
      return 'In Progress'
  }

  return 'Completed'
}

export const getMVVaultsFlushExecutedState = (
  flush: (SmartVaultFlush | undefined)[]
): string => {
  if (flush && flush.length) {
    const allFlushesUndefined = flush.every((flush) => !flush)

    if (allFlushesUndefined) {
      return 'Completed'
    }

    // SmartVaultFlush can be undefined
    const flushState = flush.some((flush) => flush && !flush.isExecuted)

    if (flushState) {
      return 'In Progress'
    }
  }

  return 'Completed'
}

export const getMVVaultsDHWExecutedState = (
  flush: (SmartVaultFlush | undefined)[]
): string => {
  if (flush && flush.length) {
    const allFlushesUndefined = flush.every((flush) => !flush)

    if (allFlushesUndefined) {
      return 'Completed'
    }

    // SmartVaultFlush can be undefined
    const dhwState = flush.some((flush) => {
      if (flush) {
        return (
          !flush.isExecuted ||
          flush.strategyDHWs?.some((strategyDHW) => !strategyDHW.isExecuted)
        )
      }
    })

    if (dhwState) {
      return 'In Progress'
    }
  }

  return 'Completed'
}
