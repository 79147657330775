import {
  TransactionHistoryAction,
  TransactionHistoryActionTypes,
  TransactionHistoryState,
} from '@/store/transactionHistory/transactionHistory.types'

const transactionHistoryReducer = (
  state: TransactionHistoryState,
  action: TransactionHistoryAction
): TransactionHistoryState => {
  switch (action.type) {
    case TransactionHistoryActionTypes.SET_PAGE:
      return { ...state, page: action.payload }
    case TransactionHistoryActionTypes.SET_LIMIT:
      return { ...state, limit: action.payload }
    case TransactionHistoryActionTypes.SET_MARKET:
      return { ...state, marketId: action.payload }
    case TransactionHistoryActionTypes.SET_ASSET_GROUP:
      return {
        ...state,
        assetGroupId: action.payload,
      }
    case TransactionHistoryActionTypes.SET_FILTERED_VAULTS:
      return { ...state, filteredVaults: action.payload }
    case TransactionHistoryActionTypes.SET_TRANSACTION_TYPES:
      return {
        ...state,
        filteredTransactionTypes: action.payload,
      }
    case TransactionHistoryActionTypes.SET_SEARCH:
      return { ...state, search: action.payload }
    case TransactionHistoryActionTypes.SET_TRANSACTION_HISTORY_TABLE_SORT:
      return {
        ...state,
        transactionHistorySort: {
          ...state.transactionHistorySort,
          key: action.payload,
        },
      }
    case TransactionHistoryActionTypes.SET_TRANSACTION_HISTORY_TABLE_SORT_DIRECTION:
      return {
        ...state,
        transactionHistorySort: {
          ...state.transactionHistorySort,
          direction: action.payload,
        },
      }
    default:
      return state
  }
}

export default transactionHistoryReducer
