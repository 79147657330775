import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Button, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

import { CHAIN_EXPLORER, CHAIN_EXPLORER_NAME } from '@/constants/web3'

const DepositSuccess = () => {
  const t = useLocale()
  const { closeModal } = useModalState()
  const { chain } = useConnectionState()

  const { modals } = useModalState()

  const modalType = modals['actionModal'].modalType?.actionModalType

  const explorerURL = CHAIN_EXPLORER[chain].url + 'tx/'

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={2}
      px={2}
      alignContent='center'
      sx={{ maxWidth: '400px' }}
    >
      <CheckCircleOutlineIcon
        sx={{
          alignSelf: 'center',
          width: '48px',
          height: '48px',
          color: '#2E7D32',
        }}
      />
      <Typography variant='h5' textAlign='center'>
        {t('components.organisms.modals.depositSuccessModal.title')}
      </Typography>
      <Box textAlign='center' sx={{ lineHeight: 'normal' }}>
        <Typography variant='body2'>
          {t('components.organisms.modals.depositSuccessModal.description1')}
        </Typography>
        <Typography variant='body2' sx={{ color: 'success.main' }}>
          {t(
            'components.organisms.modals.depositSuccessModal.descriptionGreen'
          )}
          .
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={1.5} pt={1}>
        <Button
          fullWidth
          variant='outlined'
          href={explorerURL + modalType.txHash}
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('components.organisms.modals.depositSuccessModal.buttonEtherscan')}
          {CHAIN_EXPLORER_NAME[chain]}
        </Button>
        <Button
          fullWidth
          variant='contained'
          onClick={() => closeModal(Modals.ACTION_MODAL)}
        >
          {t('components.organisms.modals.depositSuccessModal.buttonDone')}
        </Button>
      </Box>
    </Box>
  )
}

export default DepositSuccess
