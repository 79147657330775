import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Link, Typography } from '@mui/material'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import CopyToClipboardButton from '@/components/atoms/CopyToClipboard'
import DetailsRow from '@/components/molecules/DetailsRow'

interface DetailsRowWithLinkProps {
  link: string
  text: string
  rowTitle: string
  isButtonCopy?: boolean
}

const DetailsRowWithLink: React.FC<DetailsRowWithLinkProps> = ({
  link,
  text,
  isButtonCopy = false,
  rowTitle,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  return (
    <DetailsRow name={rowTitle}>
      <Box
        display='flex'
        alignItems='center'
        width='100%'
        justifyContent={belowPhabletBreakpoint ? '' : 'space-between'}
        flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
        sx={{ wordWrap: 'break-word' }}
      >
        <Typography variant='body2' width='100%'>
          <Link
            color='inherit'
            href={link}
            sx={{
              '&:hover': { color: 'primary.main' },
              textDecoration: 'none',
              mr: 0.5,
            }}
            target='_blank'
            rel='noopener noreferrer'
          >
            {text}
          </Link>
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          width='100%'
          justifyContent='end'
        >
          {isButtonCopy ? (
            <CopyToClipboardButton textToCopy={link} />
          ) : (
            <Link
              sx={{ height: '18px' }}
              href={link}
              width='24px'
              height='24px'
              target='_blank'
              color='#000'
            >
              <OpenInNewIcon color='primary' sx={{ fontSize: '18px' }} />
            </Link>
          )}
        </Box>
      </Box>
    </DetailsRow>
  )
}

export default DetailsRowWithLink
