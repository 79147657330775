import { PauseCircle } from '@mui/icons-material'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, List, ListItem, Typography } from '@mui/material'
import {
  DashboardStrategy,
  InvestorSmartVault,
  StrategyAllocationDetails,
} from '@solidant/spool-v2-fe-lib'

import useLocale from '@/hooks/context/useLocale'

import SectionColumn from '@/components/molecules/SectionColumn'

import { SmartVaultData } from '@/types/smart-vault'

interface StrategyNotificationProps {
  vault: InvestorSmartVault | SmartVaultData
}
interface RenderStrategySectionProps {
  strategies: (StrategyAllocationDetails | DashboardStrategy)[]
  title: string
  icon: JSX.Element
  instructions: string
}

const extractStrategies = (vault: InvestorSmartVault | SmartVaultData) => {
  if ('strategies' in vault) {
    return vault.strategies
  } else if ('strategiesAllocation' in vault && vault.strategiesAllocation) {
    return vault.strategiesAllocation.strategies
  }
  return []
}

const StrategyNotification: React.FC<StrategyNotificationProps> = ({
  vault,
}) => {
  const t = useLocale()

  const strategies = extractStrategies(vault)
  const pausedStrategies = strategies.filter(
    (strategy) => strategy.errorState === 'paused'
  )
  const exploitedStrategies = strategies.filter(
    (strategy) => strategy.errorState === 'exploited'
  )

  const renderStrategySection = ({
    strategies,
    title,
    icon,
    instructions,
  }: RenderStrategySectionProps) => {
    if (strategies.length > 0) {
      return (
        <SectionColumn
          sx={{
            background: 'white',
            borderRadius: (theme) => theme.spacing(1),
            maxWidth: '1140px',
          }}
        >
          <Box display='flex' gap={1}>
            {icon}
            <Box>
              <Typography color='primary.dark' variant='subtitle2'>
                {vault.name + ' ' + t(title)}
              </Typography>
              <Typography
                sx={{ pt: 1 }}
                color='primary.dark'
                variant='body2'
                component={Box}
              >
                <List sx={{ listStyleType: 'disc', pl: 3 }}>
                  {strategies.map((strategy) => {
                    return (
                      <ListItem
                        key={strategy.address}
                        sx={{ display: 'list-item', p: 0 }}
                      >
                        {strategy.name}
                      </ListItem>
                    )
                  })}
                </List>
              </Typography>
              <Typography
                sx={{ pt: 1, whiteSpace: 'initial' }}
                color='primary.dark'
                variant='body2'
              >
                {t(instructions)}
              </Typography>
            </Box>
          </Box>
        </SectionColumn>
      )
    }
    return null
  }

  return (
    <>
      {renderStrategySection({
        strategies: pausedStrategies,
        title:
          'components.organisms.dashboard.investor.investorStrategies.notifications.paused.title',
        icon: <PauseCircle color='primary' />,
        instructions:
          'components.organisms.dashboard.investor.investorStrategies.notifications.paused.instructions',
      })}
      {renderStrategySection({
        strategies: exploitedStrategies,
        title:
          'components.organisms.dashboard.investor.investorStrategies.notifications.hacked.title',
        icon: <WarningIcon color='primary' />,
        instructions:
          'components.organisms.dashboard.investor.investorStrategies.notifications.hacked.instructions',
      })}
    </>
  )
}

export default StrategyNotification
