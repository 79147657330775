import { Box, Skeleton, Stack, Typography } from '@mui/material'

const MultiAssetSwapListTokenSkeleton = () => (
  <Box
    display='flex'
    justifyContent='space-between'
    alignItems='center'
    width='100%'
    padding={(theme) => theme.spacing(1, 2)}
  >
    <Stack>
      <Box display='flex' alignItems='center' gap='4px'>
        <Skeleton variant='circular' animation='wave' width={20} height={20} />
        <Typography variant='body2'>
          <Skeleton variant='text' animation='wave' width={80} />
        </Typography>
      </Box>
      <Typography
        variant='caption'
        sx={{ marginLeft: '20px', color: 'text.secondary' }}
      >
        <Skeleton variant='text' animation='wave' width={40} />
      </Typography>
    </Stack>
    <Stack textAlign='right'>
      <Typography variant='h6'>
        <Skeleton variant='text' animation='wave' width={70} />
      </Typography>

      <Typography variant='caption' sx={{ color: 'text.secondary' }}>
        <Skeleton variant='text' animation='wave' width={70} />
      </Typography>
    </Stack>
  </Box>
)

export default MultiAssetSwapListTokenSkeleton
