// Almost the same as StakedBiAxialChartTheme.tsx, check with Spool if this is the new default

const fontFamily = [
  '"Poppins"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
].join(',')

export const StakedBiAxialChartDashboardTheme = {
  textColor: '#333333',
  fontSize: 11,
  fontFamily: fontFamily,
  axis: {
    legend: {
      text: {
        fontSize: 12,
        fill: 'rgba(0,0,0,0.35)',
      },
    },
    ticks: {
      line: {
        stroke: '#777777',
        strokeWidth: 1,
      },
      text: {
        fontSize: 14,
        fontWeight: 500,
        fill: '#212322',
      },
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 12,
        fill: 'rgba(0,0,0,0.87)',
      },
    },
    text: {
      fontSize: 12,
      fill: 'rgba(0,0,0,0.87)',
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: 'rgba(0,0,0,0.87)',
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: '#333333',
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    link: {
      stroke: '#000000',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    outline: {
      stroke: '#000000',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    symbol: {
      fill: '#000000',
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
  },
}
