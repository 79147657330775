import { Box, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { mapIcons } from '@/components/atoms/CryptoIcons'

import { CHAINS } from '@/config/web3/chains'

const ChainDetails = () => {
  const { chain } = useConnectionState()
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  return (
    <Box
      display='flex'
      alignItems='center'
      padding={belowPhabletBreakpoint ? '0.5 0rem' : '0.5rem'}
      borderRadius='0.2rem'
    >
      <Typography fontSize='14px' color='rgba(33, 35, 34, 0.6)'>
        Chain:&nbsp;
      </Typography>
      <Typography fontSize='14px' marginRight='0.2rem'>
        {CHAINS[chain].name}
      </Typography>
      {mapIcons(chain)}
    </Box>
  )
}

export default ChainDetails
