import { Box, SelectChangeEvent } from '@mui/material'
import { Datum } from '@nivo/line'
import { useEffect, useState } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import SectionColumn from '@/components/molecules/SectionColumn'
import StakedAreaChart from '@/components/organisms/Charts/StakedAreaChart'

import { getHistoricalDatum } from '@/utils/accumulateHistoricalData'
import { TimeRangeOptions } from '@/utils/stakedChart.biaxial'

const HistoricalTvrChart = () => {
  const t = useLocale()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { smartVaultData } = useVaultsPage()

  const { address, historicalTvr } = smartVaultData

  const [tvrChartValues, setTvrChartValues] = useState<Datum[]>()
  const [timeRangeBalance, setTimeRangeBalance] =
    useState<TimeRangeOptions>('6M')

  const handleChangeBalance = (event: SelectChangeEvent) => {
    setTimeRangeBalance(event.target.value as TimeRangeOptions)
  }

  useEffect(() => {
    if (smartVaultData) {
      if (historicalTvr[address]) {
        const datum = getHistoricalDatum([historicalTvr[address]], 'tvr')
        setTvrChartValues(datum)
      }
    }
  }, [smartVaultData])

  return (
    <>
      {tvrChartValues?.length > 1 && (
        <SectionColumn
          sx={{
            padding: '0',
          }}
          title={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.historicalTvr'
          )}
          noVerticalGap
        >
          <Box
            border='1px solid rgba(0, 0, 0, 0.12)'
            borderRadius='4px'
            padding='1.5rem 0.5rem'
          >
            <StakedAreaChart
              data={[
                { id: 'Total TVR', color: '#006BA6', data: tvrChartValues },
              ]}
              handleChange={handleChangeBalance}
              xTickLines={
                tvrChartValues?.length < 5
                  ? tvrChartValues?.length
                  : belowPhabletBreakpoint
                  ? 2
                  : 5
              }
              timeRangeSX={{
                marginRight: '3rem',
                justifyContent: 'right',
              }}
              timeRange={timeRangeBalance}
            />
          </Box>
        </SectionColumn>
      )}
    </>
  )
}

export default HistoricalTvrChart
