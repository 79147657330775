const Spool = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33.7905 4.2308C33.8365 4.65381 33.7659 5.08206 33.5877 5.46208C33.4095 5.8421 33.1314 6.15705 32.7883 6.36762L13.9743 17.8868C13.0759 18.436 12.4164 19.3477 12.141 20.4215C11.8656 21.4953 11.9969 22.6432 12.5058 23.6126L15.7721 29.8338L16.1686 29.5926C16.1236 29.1701 16.1948 28.7427 16.3735 28.3636C16.5521 27.9845 16.8303 27.6706 17.1733 27.461L35.9993 15.9522C36.4442 15.6803 36.835 15.3165 37.1495 14.8815C37.464 14.4465 37.696 13.9489 37.8321 13.4171C37.9683 12.8854 38.006 12.3298 37.9431 11.7824C37.8801 11.2349 37.7178 10.7061 37.4654 10.2263L34.1967 4L33.7905 4.2308Z'
        fill='#006BA6'
      />
      <path
        d='M33.8314 20.4074C33.8764 20.8299 33.8052 21.2573 33.6265 21.6364C33.4479 22.0155 33.1697 22.3294 32.8267 22.539L14.0007 34.053C13.1036 34.6022 12.4451 35.513 12.1697 36.5856C11.8944 37.6581 12.0248 38.8047 12.5322 39.7736L15.8033 46L16.1974 45.7588C16.1515 45.3358 16.222 44.9076 16.4003 44.5275C16.5785 44.1475 16.8565 43.8326 17.1997 43.622L36.0257 32.1132C36.9241 31.564 37.5836 30.6523 37.859 29.5785C38.1344 28.5047 38.0031 27.3568 37.4942 26.3874L34.2279 20.1662L33.8314 20.4074Z'
        fill='#006BA6'
      />
    </svg>
  )
}

export default Spool
