import {
  alpha,
  Box,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { BigNumber } from 'ethers'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import getLocale from '@/locales/getLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

interface AllocationTableRowProps {
  row: CreateMetaVaultVaults
  index: number
  condensed?: boolean
}

const AllocationTableRow: React.FC<AllocationTableRowProps> = ({
  row,
  index,
  condensed,
}) => {
  const t = getLocale()

  const { selectedVaultsAllocations, setSelectedVaultsAllocations } =
    useCreateMetavaultState()

  const { chain } = useConnectionState()

  const { name, tvr, apy, assetGroup } = row

  const [inputValue, setInputValue] = useState(selectedVaultsAllocations[index])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (
      value !== '.' &&
      +value < Number.MAX_SAFE_INTEGER &&
      value.match('^[0-9]*[.]?[0-9]*$')
    ) {
      setInputValue(+value)
      const updatedAllocations = [...selectedVaultsAllocations]
      updatedAllocations[index] = +value
      setSelectedVaultsAllocations(updatedAllocations)
    }
  }

  const isError = BigNumber.from(inputValue ?? 0).gt(BigNumber.from(100))

  return (
    <>
      <TableRow>
        {!condensed && (
          <TableCell align='right' sx={{ p: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  background: alpha(
                    '#039BE5',
                    1 - (index / selectedVaultsAllocations.length) * 0.9
                  ),
                }}
              />
            </Box>
          </TableCell>
        )}
        <TableCell sx={{ pl: !condensed ? 0 : 2 }} colSpan={condensed ? 2 : 1}>
          <Box
            display='flex'
            justifyContent='start'
            alignItems='center'
            gap={1}
          >
            {mapAssetTokenIcon(chain, assetGroup.assetGroupTokens[0].address)}
            <SmartVaultNameTableTypography>
              {name}
            </SmartVaultNameTableTypography>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>{formatUSD(apy || 0)} %</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            {currency} {formatUSD(tvr.totalTvr || 0)}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <TextField
            size={condensed ? 'small' : 'medium'}
            value={inputValue}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            }}
            sx={{ width: '100px', textWrap: 'wrap' }}
            error={isError}
          />
        </TableCell>
      </TableRow>
      {isError && (
        <TableRow sx={{ borderTop: '2px solid white' }}>
          <TableCell sx={{ py: 0, px: 2 }} align='right' colSpan={5}>
            <Typography variant='body2' textAlign='right' color='error'>
              {t('components.organisms.createMetavault.allocation.errors.row')}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default AllocationTableRow
