import { Connector } from '@web3-react/types'

import {
  coinbaseWalletConnection,
  deprecatedInjectedConnection,
  eip6963Connection,
  gnosisSafeConnection,
  ledgerIFrameConnection,
  networkConnection,
  walletConnectConnection,
} from '@/connection/connections'
import { ConnectionType } from '@/constants/web3'

import { RecentConnectionMeta } from '@/types/connectors'

export const connections = [
  gnosisSafeConnection,
  // deprecatedInjectedConnection,
  walletConnectConnection,
  ledgerIFrameConnection,
  coinbaseWalletConnection,
  eip6963Connection,
  networkConnection,
]

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = connections.find(
      (connection) => connection.connector === c
    )
    if (!connection) {
      throw Error('unsupported connector')
    }
    return connection
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return deprecatedInjectedConnection
      case ConnectionType.COINBASE_WALLET:
        return coinbaseWalletConnection
      case ConnectionType.WALLET_CONNECT_V2:
        return walletConnectConnection
      case ConnectionType.NETWORK:
        return networkConnection
      case ConnectionType.GNOSIS_SAFE:
        return gnosisSafeConnection
      case ConnectionType.EIP_6963_INJECTED:
        return eip6963Connection
    }
  }
}

export const connectionMetaKey = 'connection_meta'

const isRecentConnectionMeta = (value: any): value is RecentConnectionMeta => {
  const test: RecentConnectionMeta = { type: value.type } // reconstruct literal to ensure all required fields are present
  return Boolean(test.type && ConnectionType[test.type])
}

export const setRecentConnectionMeta = (
  meta: RecentConnectionMeta | undefined
) => {
  if (!meta) return localStorage.removeItem(connectionMetaKey)

  localStorage.setItem(connectionMetaKey, JSON.stringify(meta))
}

export const getRecentConnectionMeta = (): RecentConnectionMeta | undefined => {
  const value = localStorage.getItem(connectionMetaKey)
  if (!value) return

  try {
    const json = JSON.parse(value)
    if (isRecentConnectionMeta(json)) return json
  } catch (e) {
    console.warn(e)
  }
  // If meta is invalid or there is an error, clear it from local storage.
  setRecentConnectionMeta(undefined)
  return
}
