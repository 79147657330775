import { Box, Typography } from '@mui/material'
import { ReactElement } from 'react'

import { formatNumber } from '@/utils/formats'

interface ApyTooltipRowProps {
  value: number
  icon?: ReactElement
  variant?: 'body2' | 'subtitle2'
}

const ApyTooltipValue: React.FC<ApyTooltipRowProps> = ({
  value,
  icon,
  variant = 'body2',
}) => {
  return (
    <Box display='table-cell' sx={{ verticalAlign: 'middle' }} gap={0.5}>
      <Box display='flex' gap={0.5}>
        <Box display='flex' alignItems='center' sx={{ color: 'icon.primary' }}>
          {icon}
        </Box>
        <Typography variant={variant}>{formatNumber(value, 2)} %</Typography>
      </Box>
    </Box>
  )
}

export default ApyTooltipValue
