import {
  Box,
  Container,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material'
import { Serie } from '@nivo/line'
import { PropsWithChildren } from 'react'

import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import LegendChart from '@/components/organisms/Charts/Legend'
import NormalChart from '@/components/organisms/Charts/NormalChart'

import {
  getMaxValue,
  getXTickerValues,
  getYTickValues,
  TimeRangeOptions,
} from '@/utils/stakedChart.biaxial'

interface StakedBiAxialChartProps extends PropsWithChildren {
  data: Serie[]
  xTickLines: number
  handleChange: (event: SelectChangeEvent) => void
  timeRange?: TimeRangeOptions
  timeRangeSX?: SxProps<Theme>
}

const StakedAreaChart: React.FC<StakedBiAxialChartProps> = ({
  data,
  xTickLines,
  // handleChange,
  // timeRange,
  // timeRangeSX,
}) => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const maxValue = getMaxValue(data)
  const yTickValues = getYTickValues(maxValue)
  const xTickValues = getXTickerValues(data, xTickLines)
  const height = belowPhabletBreakpoint ? 200 : 300

  return (
    <>
      {maxValue === 0 || xTickValues.length === 0 ? (
        <Container fixed>Error occurred while rendering graph!</Container>
      ) : (
        <>
          {/* <TimeRange
            timeRange={timeRange}
            handleChange={handleChange}
            sx={
              timeRangeSX
                ? timeRangeSX
                : { marginTop: (theme) => theme.spacing(2), alignSelf: 'end' }
            }
          /> */}
          <Box
            component='div'
            sx={{
              height: height,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 10,
                width: '100%',
              }}
            >
              <NormalChart
                data={data}
                colors={data.map((d) => d['color'])}
                maxValue={maxValue}
                yTickValues={yTickValues}
                xTickValues={xTickValues}
              />
            </Box>
          </Box>
          <Box mt='0.5rem'>
            <LegendChart data={data} />
          </Box>
        </>
      )}
    </>
  )
}

export default StakedAreaChart
