import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

const StrategiesDescription = () => {
  const t = useLocale()
  return (
    <Typography variant='subtitle1'>
      {t('components.atoms.pageDescriptions.strategiesDescription.instruction')}
    </Typography>
  )
}

export default StrategiesDescription
