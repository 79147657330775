import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Typography } from '@mui/material'

interface BackButtonProps {
  handleClick: () => void
}

const BackButton: React.FC<BackButtonProps> = ({ handleClick }) => {
  return (
    <Box
      alignSelf='flex-start'
      display='flex'
      alignItems='center'
      gap='0.2rem'
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <ArrowBackIcon sx={{ width: '14px', height: '14px' }} />
      <Typography fontSize={14}>Back</Typography>
    </Box>
  )
}

export default BackButton
