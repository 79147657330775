import { Box, Collapse, Typography } from '@mui/material'
import { InvestorSmartVault } from '@solidant/spool-v2-fe-lib'
import { formatEther } from 'ethers/lib/utils'
import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useInvestorDashboardState from '@/hooks/context/useInvestorDashboardState'
import useLocale from '@/hooks/context/useLocale'

import { mapAssetTokenIcon, tokenDetails } from '@/components/atoms/Tokens'

import { formatUSD } from '@/utils/formats'

interface InvestorVaultsMobileRowExpandProps {
  row: InvestorSmartVault
}

const InvestorVaultsMobileRowExpand: React.FC<
  InvestorVaultsMobileRowExpandProps
> = ({ row }) => {
  const { chain } = useConnectionState()

  const { address, balance, rewards } = row

  const { selectedVaults } = useInvestorDashboardState()

  const isSelected = useMemo(
    () => !!selectedVaults.find((vault) => vault.address === address),
    [selectedVaults, row]
  )

  const t = useLocale()

  return (
    <Collapse in={isSelected} timeout='auto' unmountOnExit>
      <Box sx={{ py: 2 }} display='flex' flexDirection='column' gap={2}>
        <Box display='flex' gap={1} flexDirection='column'>
          <Typography variant='body2' color='gray'>
            {t(
              'components.organisms.dashboard.investor.investorSmartVaults.rowExpand.underlyingAssets'
            )}
          </Typography>
          {balance.assets.map(({ amount, asset }, index) => (
            <Box key={index} display='flex' gap={0.5}>
              {mapAssetTokenIcon(chain, asset.address)}
              <Typography variant='body2' color='rgba(33, 35, 34, 1)'>
                {formatUSD(amount)} {asset.symbol}
              </Typography>
            </Box>
          ))}
        </Box>
        {!!rewards.length && (
          <Box display='flex' gap={1} flexDirection='column'>
            {rewards.some(({ tokenAmount }) => +tokenAmount > 0) && (
              <Typography variant='body2' color='gray'>
                {t(
                  'components.organisms.dashboard.investor.investorSmartVaults.rowExpand.incentives'
                )}
              </Typography>
            )}
            {rewards.map(({ address, tokenAmount }, index) => {
              if (+tokenAmount > 0) {
                return (
                  <Box key={index} display='flex' gap={0.5}>
                    {mapAssetTokenIcon(chain, address)}
                    <Typography variant='body2' color='rgba(33, 35, 34, 1)'>
                      {formatUSD(formatEther(tokenAmount ?? '0'))}{' '}
                      {tokenDetails[chain][address]?.symbol ?? 'UNKNOWN'}
                    </Typography>
                  </Box>
                )
              }
            })}
          </Box>
        )}
      </Box>
    </Collapse>
  )
}

export default InvestorVaultsMobileRowExpand
