import { Box, Button, SxProps, Theme } from '@mui/material'

interface TransactionActionButtonsProps {
  confirmLabel: string
  disabled: boolean
  onConfirm: () => void
  sx?: SxProps<Theme>
  id?: string
  onCancel?: () => void
}

const TransactionActionButtons: React.FC<TransactionActionButtonsProps> = ({
  confirmLabel,
  disabled,
  onConfirm,
  sx,
  id,
  onCancel,
}) => {
  return (
    <Box
      display='flex'
      gap={(theme) => theme.spacing(1)}
      sx={{
        ...sx,
      }}
      id={id}
    >
      <Button
        variant='contained'
        size='large'
        onClick={onConfirm}
        disabled={disabled}
        sx={{
          flexGrow: 1,
        }}
      >
        {confirmLabel}
      </Button>
      {onCancel && (
        <Button
          variant='outlined'
          size='large'
          onClick={onCancel}
          sx={{
            flexGrow: 1,
          }}
        >
          CANCEL
        </Button>
      )}
    </Box>
  )
}

export default TransactionActionButtons
