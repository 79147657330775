import { SelectChangeEvent, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import SpoolSelect from '@/components/atoms/SpoolSelect'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import {
  StyledIconButton,
  StyledMenuItem,
} from '@/components/organisms/TransactionHistory/TransactionHistoryFilters/TransactionHistoryFilters'

import { Routes } from '@/constants/routes'

import { CreatorVault } from '@/types/dashboard'

const MetavaultSelector = () => {
  const { belowTabletBreakpoint } = useBreakpointDetection()
  const { chain } = useConnectionState()
  const { creatorDashboard } = useCreatorDashboard()
  const navigate = useNavigate()
  const { metavaultId } = useParams()

  const [selectedMetavault, setSelectedMetavault] = useState<string>('')

  const metavaults = creatorDashboard?.vaults?.vaults.filter(
    (vault) => vault.isMetavault === true
  )

  useEffect(() => {
    if (creatorDashboard?.vaults?.vaults?.length > 0) {
      const metavault = metavaults?.find(
        (vault) => vault.address === metavaultId
      )
      setSelectedMetavault(metavault?.address || '')
    }
  }, [metavaults])

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string
    setSelectedMetavault(value)
    navigate(Routes(chain).metavaults.root.url + `/${value}`)
  }

  return (
    <Box
      sx={{ width: '100%', maxWidth: belowTabletBreakpoint ? '100%' : '300px' }}
    >
      <SpoolSelect
        label='Select Metavault'
        value={selectedMetavault}
        handleChange={handleChange}
      >
        {metavaults?.map((vault: CreatorVault) => (
          <StyledMenuItem key={vault.address} value={vault.address}>
            <Box display='flex' alignItems='center' gap='0.5rem'>
              {vault.assetGroup.assetGroupTokens.map((token) => (
                <StyledIconButton disableRipple key={token.address}>
                  {mapAssetTokenIcon(chain, token.address)}
                </StyledIconButton>
              ))}
              <Typography variant='body2'>{vault.name}</Typography>
            </Box>
          </StyledMenuItem>
        ))}
      </SpoolSelect>
    </Box>
  )
}

export default MetavaultSelector
