import { Add } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useManageMetavaultVaults from '@/hooks/metavaults/useManageMetevaultVaults'
import useUpdateMetavaultVaultsAllocations from '@/hooks/metavaults/useUpdateMetavaultVaultsAllocations'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import AllocationTable from '@/components/organisms/CreateMetavault/Allocation/AllocationTable'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

import { MAX_METAVAULTSELECTION } from '@/constants/metavaults'

const MetavaultModal = () => {
  const t = useLocale()
  const { modals, openModal, setModalType, closeModal } = useModalState()
  const { selectedVaults, selectedVaultsAllocations } =
    useCreateMetavaultState()
  const updateMetavaultVaultsAllocations = useUpdateMetavaultVaultsAllocations()
  const manageMetavaultVaults = useManageMetavaultVaults()
  const { belowMobileBreakpoint } = useBreakpointDetection()

  // Initial states
  const [initialSelectedVaults, setInitialSelectedVaults] =
    useState(selectedVaults)
  const [
    initialSelectedVaultsAllocations,
    setInitialSelectedVaultsAllocations,
  ] = useState(selectedVaultsAllocations)

  useEffect(() => {
    if (modals['metavaultModal']?.isOpen) {
      setInitialSelectedVaults(selectedVaults)
      setInitialSelectedVaultsAllocations(selectedVaultsAllocations)
    }
  }, [modals['metavaultModal']?.isOpen])

  const handleOpenConfirmationModal = () => {
    if (selectedVaultsAllocations.some((allocation) => allocation === 0)) {
      openModal(Modals.CONFIRMATION_MODAL)
      setModalType({
        confirmationModalType: {
          message: t('components.organisms.confirmationModal.allocationZero'),
          action: () => {
            openModal(Modals.CONFIRMATION_MODAL)
            setModalType({
              confirmationModalType: {
                message: t('components.organisms.confirmationModal.waitDHW'),
                action: () => {
                  if (haveAllocationsChanged && !haveVaultsChanged) {
                    updateMetavaultVaultsAllocations()
                  } else {
                    manageMetavaultVaults()
                  }
                },
              },
            })
          },
        },
      })
    } else {
      openModal(Modals.CONFIRMATION_MODAL)
      setModalType({
        confirmationModalType: {
          message: t('components.organisms.confirmationModal.waitDHW'),
          action: () => {
            if (haveAllocationsChanged && !haveVaultsChanged) {
              updateMetavaultVaultsAllocations()
            } else {
              manageMetavaultVaults()
            }
          },
        },
      })
    }
  }

  const handleOpenAddVaultsModal = () => {
    openModal(Modals.METAVAULT_ADD_VAULTS_MODAL)
    setModalType({
      metavaultAddVaultsModalType: {
        metavaultId:
          modals['metavaultAddVaultsModal']?.modalType?.metavaultModalType
            ?.metavault.address,
        assetId:
          modals['metavaultAddVaultsModal']?.modalType?.metavaultModalType
            ?.metavault.metavaultVaults[0].assetGroup.assetGroupTokens[0]
            .address,
      },
    })
  }

  const haveAllocationsChanged = useMemo(() => {
    return (
      JSON.stringify(selectedVaultsAllocations) !==
      JSON.stringify(initialSelectedVaultsAllocations)
    )
  }, [selectedVaultsAllocations, initialSelectedVaultsAllocations])

  const haveVaultsChanged = useMemo(() => {
    return (
      JSON.stringify(selectedVaults) !== JSON.stringify(initialSelectedVaults)
    )
  }, [selectedVaults, initialSelectedVaults])

  const deletingVaultWhenTVRIsZero = useMemo(() => {
    const metavaultTVR =
      +modals['metavaultModal']?.modalType?.metavaultModalType?.metavault
        ?.totalVolumeRouted || 0

    return selectedVaultsAllocations
      .slice(0, initialSelectedVaultsAllocations.length)
      .some((allocation, _index) => {
        return allocation === 0 && metavaultTVR === 0
      })
  }, [selectedVaultsAllocations, initialSelectedVaultsAllocations])

  const isDisabled = useMemo(() => {
    return (
      selectedVaultsAllocations.reduce((acc, cur) => acc + cur, 0) !== 100 ||
      (!haveAllocationsChanged && !haveVaultsChanged) ||
      deletingVaultWhenTVRIsZero
    )
  }, [selectedVaultsAllocations, haveAllocationsChanged, haveVaultsChanged])

  return (
    <ModalBox id='metavaultModal'>
      <ModalBoxOuterContainer
        sx={{ width: '90%', maxWidth: '600px', padding: '0rem' }}
      >
        <Box
          display='flex'
          paddingY='1rem'
          px='1rem'
          alignItems='center'
          justifyContent='space-between'
          borderBottom='1px solid rgba(0, 0, 0, 0.12)'
        >
          <Typography fontWeight={500}>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.row.actionMetavaults'
            )}{' '}
            {
              modals['metavaultModal']?.modalType?.metavaultModalType?.metavault
                ?.name
            }
          </Typography>
          <Box
            display='flex'
            onClick={() => closeModal(Modals.METAVAULT_MODAL)}
          >
            <CloseIcon
              sx={{
                color: 'text.secondary',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' sx={{ p: 3 }} gap={1}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Typography
              variant={belowMobileBreakpoint ? 'caption' : 'subtitle1'}
            >
              {t(
                'components.organisms.createMetavault.vaults.vaultSelector.label'
              )}{' '}
              ({selectedVaults?.length}/{MAX_METAVAULTSELECTION})
            </Typography>
            <Button
              startIcon={<Add />}
              onClick={handleOpenAddVaultsModal}
              sx={{ fontSize: belowMobileBreakpoint ? '10px' : '14px' }}
            >
              Add Vault
            </Button>
          </Box>
          <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <AllocationTable manage />
          </Box>
          <Box display='flex' justifyContent='end' sx={{ width: '100%' }}>
            <Button
              variant='contained'
              sx={{ px: 4 }}
              onClick={handleOpenConfirmationModal}
              disabled={isDisabled}
            >
              SAVE
            </Button>
          </Box>
          {deletingVaultWhenTVRIsZero && (
            <Box display='flex' width='100%' justifyContent='end'>
              <Typography variant='caption' color='error'>
                {t(
                  'components.organisms.createMetavault.allocation.errors.deleteVaultWhenTVRIsZero'
                )}
              </Typography>
            </Box>
          )}
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default MetavaultModal
