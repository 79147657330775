import { createContext } from 'react'

import {
  TopMenuContextProps,
  TopMenuState,
} from '@/store/topMenu/topMenu.types'

import { AppMode } from '@/constants/topMenu'

export const initialState: TopMenuState = {
  isOpen: false,
  activeSubmenuItem: '',
  activeMenuItem: '',
  activeTab: '',
  appMode: AppMode.B2C,
}

export const TopMenuContext = createContext<TopMenuContextProps>({
  ...initialState,
  toggleMenu: () => undefined,
  setActiveSubmenuItem: () => undefined,
  setActiveMenuItem: () => undefined,
  setActiveTab: () => undefined,
  setAppMode: () => undefined,
})
