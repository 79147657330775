import { Box, Typography } from '@mui/material'
import { MetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { BigNumber } from 'ethers'
import { formatUnits, parseEther } from 'ethers/lib/utils'
import { memo, useEffect, useState } from 'react'

import useLocale from '@/hooks/context/useLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { METAVAULT_BASE_POINTS } from '@/constants/metavaults'
import { formatUSD } from '@/utils/formats'
import {
  getDHWExecutedState,
  getFlushExecutedState,
} from '@/utils/web3/flushes'

interface MetavaultVaultsMobileRowProps {
  row: MetaVaultVaults
  totalPendingDeposits: string
  totalPendingWithdrawals: string
}

const MetavaultVaultsMobileRow: React.FC<MetavaultVaultsMobileRowProps> = ({
  row,
  totalPendingDeposits,
  totalPendingWithdrawals,
}) => {
  const t = useLocale()

  const { name, assetGroup, allocation, smartVaultFlushes } = row

  const [vaultPendingDeposit, setVaultPendingDeposit] = useState('0')

  const [vaultPendingWithdrawals, setVaultPendingWithdrawals] = useState('0')

  useEffect(() => {
    const allocationPercentage = parseEther(allocation).div(
      BigNumber.from(METAVAULT_BASE_POINTS)
    )

    if (totalPendingDeposits) {
      const pendingDeposit = formatUnits(
        parseEther(totalPendingDeposits).mul(allocationPercentage),
        36
      )

      setVaultPendingDeposit(pendingDeposit)
    }

    if (totalPendingWithdrawals) {
      const pendingWithdrawal = formatUnits(
        parseEther(totalPendingWithdrawals).mul(allocationPercentage),
        36
      )

      setVaultPendingWithdrawals(pendingWithdrawal)
    }
  }, [])
  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='1rem'
        //onClick={onRowClick}
        mb='1rem'
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex'>
            <Box>
              <AssetGroupTokens assetGroup={assetGroup} />
            </Box>
            <Box display='flex' justifyContent='space-between' pl='0.5rem'>
              <NameCellContent>
                <SmartVaultNameTableTypography fontSize='16px'>
                  {name}
                </SmartVaultNameTableTypography>
              </NameCellContent>
            </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' fontSize='14px'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.metavaults.metavaultVaultsTable.mobileTitles.pendingDeposits'
            )}
          </Typography>
          <Typography fontSize='14px'>
            {formatUSD(vaultPendingDeposit)}{' '}
            {assetGroup.assetGroupTokens[0].symbol ?? ''}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.metavaults.metavaultVaultsTable.mobileTitles.pendingWithdrawals'
            )}
          </Typography>
          <Typography fontSize='14px'>
            {' '}
            {formatUSD(vaultPendingWithdrawals)}{' '}
            {assetGroup.assetGroupTokens[0].symbol ?? ''}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.metavaults.metavaultVaultsTable.mobileTitles.flushState'
            )}
          </Typography>
          <Typography fontSize='14px'>
            {getFlushExecutedState(smartVaultFlushes)}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.metavaults.metavaultVaultsTable.mobileTitles.dhwState'
            )}
          </Typography>
          <Typography fontSize='14px'>
            {getDHWExecutedState(smartVaultFlushes)}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default memo(MetavaultVaultsMobileRow)
