import { SortOrder } from '@solidant/spool-v2-fe-lib'

import { CreatorDashboardState } from '@/store/creatorVault/creatorDashboard.types'

export const initialState: CreatorDashboardState = {
  creatorVaultsTableLimit: 10,
  creatorVaultsTablePage: 0,
  creatorVaultsTableSort: {
    key: 'name',
    direction: SortOrder.DESC,
  },
  selectedVaults: [],
  creatorVaultsStrategiesLimit: 10,
  creatorVaultsStrategiesPage: 0,
  creatorVaultsStrategiesSort: {
    key: 'amountInUSDPercentage',
    direction: SortOrder.DESC,
  },
  tvrChartValues: [],
  feesChartValues: {
    performanceFeesChartValues: [],
    managementFeesChartValues: [],
  },
  withdrawEarningsVault: undefined,
}
