import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

import BasicModal from '@/components/molecules/BasicModal'

interface ModalBoxProps extends PropsWithChildren {
  id: string
  disabledClose?: boolean
  backdropColor?: string
  hideBackdrop?: boolean
}

const ModalBox: React.FC<ModalBoxProps> = ({
  id,
  disabledClose,
  backdropColor,
  hideBackdrop,
  children,
}) => {
  const modalRoot = document.getElementById('modal-root') as HTMLElement

  return (
    <>
      {createPortal(
        <BasicModal
          disabledClose={disabledClose}
          backdropColor={backdropColor}
          hideBackdrop={hideBackdrop}
          id={id}
        >
          {children}
        </BasicModal>,
        modalRoot
      )}
    </>
  )
}

export default ModalBox
