import { Box } from '@mui/material'

import Bottom from '@/components/molecules/SliderThumb/Bottom'
import Middle from '@/components/molecules/SliderThumb/Middle'
import SliderThumbContainer from '@/components/molecules/SliderThumb/SliderThumbContainer'
import Top from '@/components/molecules/SliderThumb/Top'

type SliderThumbProps = {
  props: any
}

const SliderThumb: React.FC<SliderThumbProps> = ({ props }) => {
  return (
    <SliderThumbContainer {...props}>
      <Top className='top' key='top'>
        <Box key='top-part-1' className='part-1'></Box>
        <Box key='top-part-2' className='part-2'></Box>
      </Top>
      <Middle key='middle' />
      <Bottom key='bottom'>
        <Box key='bottom-part-1' className='part-1'></Box>
        <Box key='bottom-part-2' className='part-2'></Box>
      </Bottom>
    </SliderThumbContainer>
  )
}

export default SliderThumb
