import { Connector } from '@web3-react/types'
import { useEffect } from 'react'

import {
  eip6963Connection,
  gnosisSafeConnection,
  ledgerIFrameConnection,
  networkConnection,
  walletConnectConnection,
} from '@/connection/connections'
import { isIframe } from '@/connection/connectors/LedgerIFrameConnector/helpers'
import { getConnection, getRecentConnectionMeta } from '@/connection/utils'

const connect = async (connector: Connector) => {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly()
    } else {
      await connector.activate()
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`)
  }
}

const eagerlyConnectEIP6963 = () => {
  class FailedToConnect extends Error {}

  let connectionSuccess = false

  // Get the persisted wallet type from the last session.
  const recentConnectionMeta = getRecentConnectionMeta()
  if (recentConnectionMeta?.type && !recentConnectionMeta.disconnected) {
    const selectedConnection = getConnection(recentConnectionMeta.type)

    // All EIP6963 wallets share one Connection object, `eip6963Connection`
    // To activate the same EIP6963 wallet as the last session, we need to `select` the rdns of the recent connection
    if (recentConnectionMeta.rdns)
      eip6963Connection.selectRdns(recentConnectionMeta.rdns)

    if (selectedConnection) {
      connect(selectedConnection.connector)
        .then(() => {
          connectionSuccess = false
          if (!connectionSuccess) throw new FailedToConnect()
        })
        .catch((error) => {
          // Clear the persisted wallet type if it failed to connect.
          // Log it if it threw an unknown error.
          if (!(error instanceof FailedToConnect)) {
            console.error(error)
          }
        })
        .finally(() => {
          connectionSuccess = true
        })
    }

    return connectionSuccess
  }
}

const useEagerlyConnect = () => {
  useEffect(() => {
    // if (isLedgerDappBrowserProvider()) {
    // }
    if (isIframe()) {
      connect(ledgerIFrameConnection.connector)
      connect(gnosisSafeConnection.connector)
    }

    if (eagerlyConnectEIP6963()) {
      return
    }

    connect(walletConnectConnection.connector)
    connect(networkConnection.connector)
  }, [])
}

export default useEagerlyConnect
