import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useLocale from '@/hooks/context/useLocale'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import DataLoading from '@/components/molecules/DataLoading'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import VaultActions from '@/components/organisms/SmartVaults/VaultActions/VaultActions'
import VaultDetails from '@/components/organisms/SmartVaults/VaultDetails/VaultDetails'

import PageNotFound from '@/pages/General/PageNotFound'

const VaultsPage = () => {
  const { belowTabletBreakpoint, belowLaptopBreakpoint } =
    useBreakpointDetection()

  const t = useLocale()

  const { isLoading, error, smartVaultData } = useVaultsPage()

  const { vaultId } = useParams()

  const { setSelectedVaultId } = useSmartVaultsTableState()

  useEffect(() => {
    if (vaultId) {
      setSelectedVaultId(vaultId)
    }
    return () => {
      setSelectedVaultId(undefined)
    }
  }, [])

  const responsiveColumnSize = () => {
    if (belowTabletBreakpoint) {
      return '55% 40%'
    } else if (belowLaptopBreakpoint) {
      return '60% 37.5%'
    } else {
      return '65% 32.5%'
    }
  }

  return (
    <>
      {!isLoading ? (
        error ? (
          <Box mt='1rem'>
            <ErrorWithText errorText='Error loading vault' />
          </Box>
        ) : !Object.values(smartVaultData).length ? (
          <Box mt='1rem'>
            <PageNotFound height='90vh' />
          </Box>
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            gap='1rem'
            mb={belowTabletBreakpoint ? '4rem' : '0rem'}
          >
            <Box
              display={belowTabletBreakpoint ? 'flex' : 'grid'}
              flexDirection='column-reverse'
              gap='2rem'
              gridTemplateColumns={responsiveColumnSize()}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='left'
                gap='1rem'
              >
                <VaultDetails />
              </Box>
              <VaultActions />
            </Box>
          </Box>
        )
      ) : (
        <Box mt='1rem'>
          <DataLoading
            loadingText={t(
              'pages.smartVaults.vaultDetailsPage.loadingVaultData'
            )}
          />
        </Box>
      )}
    </>
  )
}

export default VaultsPage
