import { SortOrder } from '@solidant/spool-v2-fe-lib'

import {
  DepositAction,
  DepositActionTypes,
  DepositState,
} from '@/store/deposit/deposit.types'

const depositReducer = (
  state: DepositState,
  action: DepositAction
): DepositState => {
  switch (action.type) {
    case DepositActionTypes.SET_DEPOSIT_RECEIPT_PAGE:
      return { ...state, depositReceiptPage: action.payload }
    case DepositActionTypes.SET_DEPOSIT_RECEIPT_LIMIT:
      return { ...state, depositReceiptLimit: action.payload }
    case DepositActionTypes.SET_SINGLE_DEPOSIT_AMOUNT:
      return { ...state, singleDeposit: action.payload }
    case DepositActionTypes.SET_MULTI_ASSET_USD_DEPOSIT_AMOUNT:
      return { ...state, multiAssetUsdDepositAmount: action.payload }
    case DepositActionTypes.CHANGE_DEPOSIT_SORT_DIRECTION:
      return {
        ...state,
        depositReceiptSort: {
          ...state.depositReceiptSort,
          direction:
            state.depositReceiptSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case DepositActionTypes.SET_DEPOSIT_RECEIPT_SORT:
      return {
        ...state,
        depositReceiptSort: action.payload,
      }
    case DepositActionTypes.SET_MULTI_ASSET_SWAP_DEPOSIT:
      return { ...state, multiAssetSwap: action.payload }
  }
}

export default depositReducer
