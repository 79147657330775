import { useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import getLocale from '@/locales/getLocale'

import { STEP_SEQUENCE } from '@/store/createVault/createVault.state'
import { StepData } from '@/store/createVault/createVault.types'

import { FEATURES } from '@/config/sdk'
import { Routes } from '@/constants/routes'

const useSteps = () => {
  const { chain } = useConnectionState()

  const t = getLocale()

  const GUARDS_ACTIONS: StepData[] = useMemo(
    () => [
      {
        name: t('store.createVault.state.guardsActions.guards.name'),
        nextBtn: {
          label: t(
            'store.createVault.state.guardsActions.guards.nextBtn.label'
          ),
          toStep: STEP_SEQUENCE.LAUNCH,
        },
        advBtn: {
          label: t('store.createVault.state.guardsActions.guards.advBtn.label'),
          toStep: STEP_SEQUENCE.ACTIONS,
        },
        prevBtn: {
          label: t(
            'store.createVault.state.guardsActions.guards.prevBtn.label'
          ),
          toStep: STEP_SEQUENCE.ALLOCATIONS,
        },
        urlSubpath: Routes(chain).createVault.createVaultGuards.url,
        completed: false,
      },
      {
        name: t('store.createVault.state.guardsActions.actions.name'),
        nextBtn: {
          label: t(
            'store.createVault.state.guardsActions.actions.nextBtn.label'
          ),
          toStep: STEP_SEQUENCE.LAUNCH,
        },
        advBtn: {
          label: t(
            'store.createVault.state.guardsActions.actions.advBtn.label'
          ),
          toStep: STEP_SEQUENCE.GUARDS,
        },
        prevBtn: {
          label: t(
            'store.createVault.state.guardsActions.actions.prevBtn.label'
          ),
          toStep: STEP_SEQUENCE.ALLOCATIONS,
        },
        urlSubpath: Routes(chain).createVault.createVaultActions.url,
        completed: false,
      },
    ],
    [chain]
  )

  const steps = useMemo(
    () => [
      {
        name: t('store.createVault.state.initialState.steps.riskModel.name'),
        nextBtn: {
          label: t(
            'store.createVault.state.initialState.steps.riskModel.nextBtn.label'
          ),
          toStep: STEP_SEQUENCE.STRATEGIES,
        },
        urlSubpath: Routes(chain).createVault.createVaultRiskModel.url,
        completed: false,
      },
      {
        name: t('store.createVault.state.initialState.steps.strategies.name'),
        nextBtn: {
          label: t(
            'store.createVault.state.initialState.steps.strategies.nextBtn.label'
          ),
          toStep: STEP_SEQUENCE.ALLOCATIONS,
        },
        prevBtn: {
          label: t(
            'store.createVault.state.initialState.steps.strategies.prevBtn.label'
          ),
          toStep: STEP_SEQUENCE.RISK_MODELS,
        },
        urlSubpath: Routes(chain).createVault.createVaultStrategies.url,
        completed: false,
      },

      {
        name: t('store.createVault.state.initialState.steps.allocation.name'),
        nextBtn: {
          label: t(
            'store.createVault.state.initialState.steps.allocation.nextBtn.label'
          ),
          toStep: STEP_SEQUENCE.LAUNCH,
        },

        prevBtn: {
          label: t(
            'store.createVault.state.initialState.steps.allocation.prevBtn.label'
          ),
          toStep: STEP_SEQUENCE.STRATEGIES,
        },
        urlSubpath: Routes(chain).createVault.createVaultAllocation.url,
        completed: true,
      },
      ...(FEATURES(chain).CREATE_VAULT_ADVANCED
        ? GUARDS_ACTIONS.map((item) => ({
            name: item.name,
            nextBtn: { ...item.nextBtn },
            advBtn: { ...item.advBtn },
            prevBtn: { ...item.prevBtn },
            urlSubpath: item.urlSubpath,
            completed: item.completed,
          }))
        : []),
      {
        name: t('store.createVault.state.initialState.steps.launch.name'),
        nextBtn: {
          label: t(
            'store.createVault.state.initialState.steps.launch.nextBtn.label'
          ),
          toStep: null,
        },
        prevBtn: {
          label: t(
            'store.createVault.state.initialState.steps.launch.prevBtn.label'
          ),
          toStep: 5,
        },
        urlSubpath: Routes(chain).createVault.createVaultLaunch.url,
        completed: true,
      },
    ],
    [chain]
  )

  return steps
}

export default useSteps
