import {
  SortOrder,
  Strategy,
  StrategyListType,
  StrategyVault,
} from '@solidant/spool-v2-fe-lib'

import { SortType } from '@/types/table'

export interface StrategiesState {
  strategiesSort: SortType<Strategy>
  strategiesPage: number
  strategiesLimit: number
  strategyVaultsSort: SortType<StrategyVault>
  filterByAssetGroup: string
  filterBySearchTerm: string
}

export interface StrategiesContextProps extends StrategiesState {
  handleSortClick: (
    key: keyof Strategy,
    strategiesSort: SortType<Strategy>
  ) => void
  setStrategiesPage: (strategiesPage: number) => void
  setStrategiesLimit: (strategiesLimit: number) => void
  setFilterByAssetGroup: (filterByAssetGroup: string) => void
  setFilterBySearchTerm: (filterSearchTerm: string) => void
  handleSortClickVaults: (
    key: keyof StrategyVault,
    smartVaultsSort: SortType<StrategyVault>
  ) => void
  filterStrategy: (data: StrategyListType) => void
}

export enum StrategiesActionTypes {
  SET_STRATEGIES_SORT = 'SET_STRATEGIES_SORT',
  CHANGE_STRATEGIES_SORT_DIRECTION = 'CHANGE_STRATEGIES_SORT_DIRECTION',
  SET_STRATEGIES_PAGE = 'SET_STRATEGIES_PAGE',
  SET_STRATEGIES_LIMIT = 'SET_STRATEGIES_LIMIT',
  SET_STRATEGY_VAULTS_SORT = 'SET_STRATEGY_VAULTS_SORT',
  CHANGE_STRATEGY_VAULTS_SORT_DIRECTION = 'CHANGE_STRATEGY_VAULTS_SORT_DIRECTION',
  SET_FILTER_BY_ASSET_GROUP = 'SET_FILTER_BY_ASSET_GROUP',
  SET_FILTER_BY_SEARCH_TERM = 'SET_FILTER_BY_SEARCH_TERM',
}

export type StrategiesAction =
  | {
      type: StrategiesActionTypes.SET_STRATEGIES_SORT
      payload: {
        key: keyof Strategy
        direction: SortOrder
      }
    }
  | {
      type: StrategiesActionTypes.CHANGE_STRATEGIES_SORT_DIRECTION
    }
  | {
      type: StrategiesActionTypes.SET_STRATEGIES_PAGE
      payload: number
    }
  | {
      type: StrategiesActionTypes.SET_STRATEGIES_LIMIT
      payload: number
    }
  | {
      type: StrategiesActionTypes.SET_STRATEGY_VAULTS_SORT
      payload: {
        key: keyof StrategyVault
        direction: SortOrder
      }
    }
  | {
      type: StrategiesActionTypes.CHANGE_STRATEGY_VAULTS_SORT_DIRECTION
    }
  | {
      type: StrategiesActionTypes.SET_FILTER_BY_ASSET_GROUP
      payload: string
    }
  | {
      type: StrategiesActionTypes.SET_FILTER_BY_SEARCH_TERM
      payload: string
    }
