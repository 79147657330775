/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
import { arrayify } from '@ethersproject/bytes'
import type { Actions } from '@web3-react/types'
import { Connector } from '@web3-react/types'
import { ethers, TypedDataDomain } from 'ethers'
import { EventEmitter } from 'events'

interface RequestArguments {
  method: string
  params?: unknown[] | Record<string, unknown>
}

interface EIP1193Provider extends EventEmitter {
  connect(params?: any): Promise<void>
  disconnect(): Promise<void>
  request(args: RequestArguments): Promise<unknown>
}

type SafeInfo = {
  safeAddress: string
  chainId: number
  threshold: number
  owners: string[]
  isReadOnly: boolean
}

type Opts = {
  allowedDomains?: RegExp[]
  debug?: boolean
}
declare enum Methods {
  sendTransactions = 'sendTransactions',
  rpcCall = 'rpcCall',
  getChainInfo = 'getChainInfo',
  getSafeInfo = 'getSafeInfo',
  getTxBySafeTxHash = 'getTxBySafeTxHash',
  getSafeBalances = 'getSafeBalances',
  signMessage = 'signMessage',
  signTypedMessage = 'signTypedMessage',
  getEnvironmentInfo = 'getEnvironmentInfo',
  getOffChainSignature = 'getOffChainSignature',
  requestAddressBook = 'requestAddressBook',
  wallet_getPermissions = 'wallet_getPermissions',
  wallet_requestPermissions = 'wallet_requestPermissions',
}
type SendTransactionsResponse = {
  safeTxHash: string
}
declare enum RPC_AUTHENTICATION {
  API_KEY_PATH = 'API_KEY_PATH',
  NO_AUTHENTICATION = 'NO_AUTHENTICATION',
  UNKNOWN = 'UNKNOWN',
}
declare type RpcUri = {
  authentication: RPC_AUTHENTICATION
  value: string
}
declare type BlockExplorerUriTemplate = {
  address: string
  txHash: string
  api: string
}
declare type NativeCurrency = {
  name: string
  symbol: string
  decimals: number
  logoUri: string
}
declare type Theme = {
  textColor: string
  backgroundColor: string
}
declare enum GAS_PRICE_TYPE {
  ORACLE = 'ORACLE',
  FIXED = 'FIXED',
  UNKNOWN = 'UNKNOWN',
}
declare type GasPriceOracle = {
  type: GAS_PRICE_TYPE.ORACLE
  uri: string
  gasParameter: string
  gweiFactor: string
}
declare type GasPriceFixed = {
  type: GAS_PRICE_TYPE.FIXED
  weiValue: string
}
declare type GasPriceUnknown = {
  type: GAS_PRICE_TYPE.UNKNOWN
}
declare enum FEATURES {
  ERC721 = 'ERC721',
  SAFE_APPS = 'SAFE_APPS',
  CONTRACT_INTERACTION = 'CONTRACT_INTERACTION',
  DOMAIN_LOOKUP = 'DOMAIN_LOOKUP',
  SPENDING_LIMIT = 'SPENDING_LIMIT',
  EIP1559 = 'EIP1559',
  SAFE_TX_GAS_OPTIONAL = 'SAFE_TX_GAS_OPTIONAL',
  TX_SIMULATION = 'TX_SIMULATION',
  EIP1271 = 'EIP1271',
}
declare type GasPrice = (GasPriceOracle | GasPriceFixed | GasPriceUnknown)[]
declare type _ChainInfo = {
  transactionService: string
  chainId: string
  chainName: string
  shortName: string
  l2: boolean
  description: string
  rpcUri: RpcUri
  safeAppsRpcUri: RpcUri
  publicRpcUri: RpcUri
  blockExplorerUriTemplate: BlockExplorerUriTemplate
  nativeCurrency: NativeCurrency
  theme: Theme
  ensRegistryAddress?: string
  gasPrice: GasPrice
  disabledWallets: string[]
  features: FEATURES[]
}
type ChainInfo = Pick<
  _ChainInfo,
  | 'chainName'
  | 'chainId'
  | 'shortName'
  | 'nativeCurrency'
  | 'blockExplorerUriTemplate'
>

type GatewayTransactionDetails = TransactionDetails
declare enum TransactionStatus {
  AWAITING_CONFIRMATIONS = 'AWAITING_CONFIRMATIONS',
  AWAITING_EXECUTION = 'AWAITING_EXECUTION',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
declare enum TransactionInfoType {
  TRANSFER = 'Transfer',
  SETTINGS_CHANGE = 'SettingsChange',
  CUSTOM = 'Custom',
  CREATION = 'Creation',
}
declare type AddressEx = {
  value: string
  name?: string
  logoUri?: string
}
declare enum TransferDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  UNKNOWN = 'UNKNOWN',
}
declare enum TransactionTokenType {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  NATIVE_COIN = 'NATIVE_COIN',
}
declare type Erc20Transfer = {
  type: TransactionTokenType.ERC20
  tokenAddress: string
  tokenName?: string
  tokenSymbol?: string
  logoUri?: string
  decimals?: number
  value: string
}
declare type Erc721Transfer = {
  type: TransactionTokenType.ERC721
  tokenAddress: string
  tokenId: string
  tokenName?: string
  tokenSymbol?: string
  logoUri?: string
}
declare type NativeCoinTransfer = {
  type: TransactionTokenType.NATIVE_COIN
  value: string
}
declare type TransferInfo = Erc20Transfer | Erc721Transfer | NativeCoinTransfer
declare type Transfer = {
  type: TransactionInfoType.TRANSFER
  sender: AddressEx
  recipient: AddressEx
  direction: TransferDirection
  transferInfo: TransferInfo
}
declare enum Operation {
  CALL = 0,
  DELEGATE = 1,
}
declare type InternalTransaction = {
  operation: Operation
  to: string
  value?: string
  data: string | null
  dataDecoded?: DataDecoded
}
declare type ValueDecodedType = InternalTransaction[]
declare type ParamValue = string | ParamValue[]
declare type Parameter = {
  name: string
  type: string
  value: ParamValue
  valueDecoded?: ValueDecodedType
}
declare type DataDecoded = {
  method: string
  parameters?: Parameter[]
}
declare enum SettingsInfoType {
  SET_FALLBACK_HANDLER = 'SET_FALLBACK_HANDLER',
  ADD_OWNER = 'ADD_OWNER',
  REMOVE_OWNER = 'REMOVE_OWNER',
  SWAP_OWNER = 'SWAP_OWNER',
  CHANGE_THRESHOLD = 'CHANGE_THRESHOLD',
  CHANGE_IMPLEMENTATION = 'CHANGE_IMPLEMENTATION',
  ENABLE_MODULE = 'ENABLE_MODULE',
  DISABLE_MODULE = 'DISABLE_MODULE',
  SET_GUARD = 'SET_GUARD',
  DELETE_GUARD = 'DELETE_GUARD',
}
declare type SetFallbackHandler = {
  type: SettingsInfoType.SET_FALLBACK_HANDLER
  handler: AddressEx
}
declare type AddOwner = {
  type: SettingsInfoType.ADD_OWNER
  owner: AddressEx
  threshold: number
}
declare type RemoveOwner = {
  type: SettingsInfoType.REMOVE_OWNER
  owner: AddressEx
  threshold: number
}
declare type SwapOwner = {
  type: SettingsInfoType.SWAP_OWNER
  oldOwner: AddressEx
  newOwner: AddressEx
}
declare type ChangeThreshold = {
  type: SettingsInfoType.CHANGE_THRESHOLD
  threshold: number
}
declare type ChangeImplementation = {
  type: SettingsInfoType.CHANGE_IMPLEMENTATION
  implementation: AddressEx
}
declare type EnableModule = {
  type: SettingsInfoType.ENABLE_MODULE
  module: AddressEx
}
declare type DisableModule = {
  type: SettingsInfoType.DISABLE_MODULE
  module: AddressEx
}
declare type SetGuard = {
  type: SettingsInfoType.SET_GUARD
  guard: AddressEx
}
declare type DeleteGuard = {
  type: SettingsInfoType.DELETE_GUARD
}
declare type SettingsInfo =
  | SetFallbackHandler
  | AddOwner
  | RemoveOwner
  | SwapOwner
  | ChangeThreshold
  | ChangeImplementation
  | EnableModule
  | DisableModule
  | SetGuard
  | DeleteGuard
declare type SettingsChange = {
  type: TransactionInfoType.SETTINGS_CHANGE
  dataDecoded: DataDecoded
  settingsInfo?: SettingsInfo
}
declare type Custom = {
  type: TransactionInfoType.CUSTOM
  to: AddressEx
  dataSize: string
  value: string
  methodName?: string
  actionCount?: number
  isCancellation: boolean
}
declare type MultiSend = {
  type: TransactionInfoType.CUSTOM
  to: AddressEx
  dataSize: string
  value: string
  methodName: 'multiSend'
  actionCount: number
  isCancellation: boolean
}
declare type Cancellation = Custom & {
  isCancellation: true
}
declare type Creation = {
  type: TransactionInfoType.CREATION
  creator: AddressEx
  transactionHash: string
  implementation?: AddressEx
  factory?: AddressEx
}
declare type TransactionInfo =
  | Transfer
  | SettingsChange
  | Custom
  | MultiSend
  | Cancellation
  | Creation
declare type TransactionData = {
  hexData?: string
  dataDecoded?: DataDecoded
  to: AddressEx
  value?: string
  operation: Operation
  addressInfoIndex?: {
    [key: string]: AddressEx
  }
  trustedDelegateCallTarget: boolean
}
declare enum DetailedExecutionInfoType {
  MULTISIG = 'MULTISIG',
  MODULE = 'MODULE',
}
declare type ModuleExecutionDetails = {
  type: DetailedExecutionInfoType.MODULE
  address: AddressEx
}
declare type MultisigConfirmation = {
  signer: AddressEx
  signature?: string
  submittedAt: number
}
declare enum TokenType {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  NATIVE_TOKEN = 'NATIVE_TOKEN',
}
declare type TokenInfo = {
  type: TokenType
  address: string
  decimals: number
  symbol: string
  name: string
  logoUri: string
}
declare type MultisigExecutionDetails = {
  type: DetailedExecutionInfoType.MULTISIG
  submittedAt: number
  nonce: number
  safeTxGas: string
  baseGas: string
  gasPrice: string
  gasToken: string
  refundReceiver: AddressEx
  safeTxHash: string
  executor?: AddressEx
  signers: AddressEx[]
  confirmationsRequired: number
  confirmations: MultisigConfirmation[]
  rejectors?: AddressEx[]
  gasTokenInfo?: TokenInfo
  trusted: boolean
}
declare type DetailedExecutionInfo =
  | ModuleExecutionDetails
  | MultisigExecutionDetails
declare type SafeAppInfo = {
  name: string
  url: string
  logoUri: string
}
declare type TransactionDetails = {
  safeAddress: string
  txId: string
  executedAt?: number
  txStatus: TransactionStatus
  txInfo: TransactionInfo
  txData?: TransactionData
  detailedExecutionInfo?: DetailedExecutionInfo
  txHash?: string
  safeAppInfo?: SafeAppInfo
}
declare type SafeBalanceResponse = {
  fiatTotal: string
  items: Array<{
    tokenInfo: TokenInfo
    balance: string
    fiatBalance: string
    fiatConversion: string
  }>
}
type SafeBalances = SafeBalanceResponse
type OffChainSignMessageResponse = {
  messageHash: string
}
type SignMessageResponse =
  | SendTransactionsResponse
  | OffChainSignMessageResponse
type EnvironmentInfo = {
  origin: string
}
type AddressBookItem = {
  address: string
  chainId: string
  name: string
}
type Permission = {
  parentCapability: string
  invoker: string
  date?: number
  caveats?: PermissionCaveat[]
}
type PermissionCaveat = {
  type: string
  value?: unknown
  name?: string
}
interface MethodToResponse {
  [Methods.sendTransactions]: SendTransactionsResponse
  [Methods.rpcCall]: unknown
  [Methods.getSafeInfo]: SafeInfo
  [Methods.getChainInfo]: ChainInfo
  [Methods.getTxBySafeTxHash]: GatewayTransactionDetails
  [Methods.getSafeBalances]: SafeBalances[]
  [Methods.signMessage]: SignMessageResponse
  [Methods.signTypedMessage]: SignMessageResponse
  [Methods.getEnvironmentInfo]: EnvironmentInfo
  [Methods.getOffChainSignature]: string
  [Methods.requestAddressBook]: AddressBookItem[]
  [Methods.wallet_getPermissions]: Permission[]
  [Methods.wallet_requestPermissions]: Permission[]
}
type RequestId = string
type SuccessResponse<T = MethodToResponse[Methods]> = {
  id: RequestId
  data: T
  version?: string
  success: true
}
interface Communicator {
  send<M extends Methods, P = unknown, R = unknown>(
    method: M | string,
    params: P
  ): Promise<SuccessResponse<R>>
}
// type Bytes = ArrayLike<number>
// type BytesLike = Bytes | string
// type BigNumberish = any // BigNumber | Bytes | bigint | string | number;
// interface TypedDataDomain {
//   name?: string
//   version?: string
//   chainId?: BigNumberish
//   verifyingContract?: string
//   salt?: BytesLike
// }
interface TypedDataTypes {
  name: string
  type: string
}
type TypedMessageTypes = {
  [key: string]: TypedDataTypes[]
}
type EIP712TypedData = {
  domain: TypedDataDomain
  types: TypedMessageTypes
  message: Record<string, any>
}
type BaseTransaction = {
  to: string
  value: string
  data: string
}
interface SendTransactionRequestParams {
  safeTxGas?: number
}
interface SendTransactionsParams {
  txs: BaseTransaction[]
  params?: SendTransactionRequestParams
}

type GetTxBySafeTxHashParams = {
  safeTxHash: string
}
type SignMessageParams = {
  message: string
}
const isObjectEIP712TypedData = (obj?: unknown): obj is EIP712TypedData => {
  return (
    typeof obj === 'object' &&
    obj != null &&
    'domain' in obj &&
    'types' in obj &&
    'message' in obj
  )
}
type SignTypedMessageParams = {
  typedData: EIP712TypedData
}
class TXs {
  private readonly communicator: Communicator

  constructor(communicator: Communicator) {
    this.communicator = communicator
  }

  async getBySafeTxHash(
    safeTxHash: string
  ): Promise<GatewayTransactionDetails> {
    if (!safeTxHash) {
      throw new Error('Invalid safeTxHash')
    }

    const response = await this.communicator.send<
      Methods.getTxBySafeTxHash,
      GetTxBySafeTxHashParams,
      GatewayTransactionDetails
    >(Methods.getTxBySafeTxHash, { safeTxHash })

    return response.data
  }

  async signMessage(message: string): Promise<SignMessageResponse> {
    const messagePayload = {
      message,
    }

    const response = await this.communicator.send<
      Methods.signMessage,
      SignMessageParams,
      SignMessageResponse
    >(Methods.signMessage, messagePayload)

    return response.data
  }

  async signTypedMessage(
    typedData: EIP712TypedData
  ): Promise<SignMessageResponse> {
    if (!isObjectEIP712TypedData(typedData)) {
      throw new Error('Invalid typed data')
    }

    const response = await this.communicator.send<
      Methods.signTypedMessage,
      SignTypedMessageParams,
      SignMessageResponse
    >(Methods.signTypedMessage, { typedData })

    return response.data
  }

  async send({
    txs,
    params,
  }: SendTransactionsParams): Promise<SendTransactionsResponse> {
    if (!txs || !txs.length) {
      throw new Error('No transactions were passed')
    }

    const messagePayload = {
      txs,
      params,
    }

    const response = await this.communicator.send<
      Methods.sendTransactions,
      SendTransactionsParams,
      SendTransactionsResponse
    >(Methods.sendTransactions, messagePayload)

    return response.data
  }
}

interface TransactionConfig {
  from?: string | number
  to?: string
  value?: number | string
  gas?: number | string
  gasPrice?: number | string
  data?: string
  nonce?: number
}
type BlockNumberArg = number | 'earliest' | 'latest' | 'pending'
interface PastLogsOptions {
  fromBlock?: BlockNumberArg
  toBlock?: BlockNumberArg
  address?: string
  topics?: Array<string | string[] | null>
}
interface Log {
  address: string
  data: string
  topics: string[]
  logIndex: number
  transactionIndex: number
  transactionHash: string
  blockHash: string
  blockNumber: number
}
interface BlockHeader {
  number: number
  hash: string
  parentHash: string
  nonce: string
  sha3Uncles: string
  logsBloom: string
  transactionRoot: string
  stateRoot: string
  receiptRoot: string
  miner: string
  extraData: string
  gasLimit: number
  gasUsed: number
  timestamp: number | string
}
interface BlockTransactionBase extends BlockHeader {
  size: number
  difficulty: number
  totalDifficulty: number
  uncles: string[]
}
interface BlockTransactionObject extends BlockTransactionBase {
  transactions: Web3TransactionObject[]
}
interface BlockTransactionString extends BlockTransactionBase {
  transactions: string[]
}
interface Web3TransactionReceiptObject {
  transactionHash: string
  transactionIndex: number
  blockHash: string
  blockNumber: number
  from: string
  to: string | null
  cumulativeGasUsed: number
  gasUsed: number
  contractAddress: string
  logs: Log[]
  logsBloom: string
  status: number | undefined
}
interface SafeSettings {
  offChainSigning?: boolean
}

const RPC_CALLS = {
  eth_call: 'eth_call',
  eth_gasPrice: 'eth_gasPrice',
  eth_getLogs: 'eth_getLogs',
  eth_getBalance: 'eth_getBalance',
  eth_getCode: 'eth_getCode',
  eth_getBlockByHash: 'eth_getBlockByHash',
  eth_getBlockByNumber: 'eth_getBlockByNumber',
  eth_getStorageAt: 'eth_getStorageAt',
  eth_getTransactionByHash: 'eth_getTransactionByHash',
  eth_getTransactionReceipt: 'eth_getTransactionReceipt',
  eth_getTransactionCount: 'eth_getTransactionCount',
  eth_estimateGas: 'eth_estimateGas',
  safe_setSettings: 'safe_setSettings',
} as const

type Formatter = (arg: any) => any

const inputFormatters: Record<string, Formatter> = {
  defaultBlockParam: (arg = 'latest') => arg,
  returnFullTxObjectParam: (arg = false): boolean => arg,
  blockNumberToHex: (arg: BlockNumberArg): string =>
    Number.isInteger(arg) ? `0x${arg.toString(16)}` : (arg as string),
}
type RpcCallNames = keyof typeof RPC_CALLS
type BuildRequestArgs = {
  call: RpcCallNames
  formatters?: (Formatter | null)[]
}
type RPCPayload<P = unknown[]> = {
  call: RpcCallNames
  params: P | unknown[]
}
class Eth {
  public call
  public getBalance
  public getCode
  public getStorageAt
  public getPastLogs
  public getBlockByHash
  public getBlockByNumber
  public getTransactionByHash
  public getTransactionReceipt
  public getTransactionCount
  public getGasPrice
  public getEstimateGas
  public setSafeSettings

  private readonly communicator: Communicator

  constructor(communicator: Communicator) {
    this.communicator = communicator
    this.call = this.buildRequest<[TransactionConfig, string?], string>({
      call: RPC_CALLS.eth_call,
      formatters: [null, inputFormatters.defaultBlockParam],
    })
    this.getBalance = this.buildRequest<[string, string?], string>({
      call: RPC_CALLS.eth_getBalance,
      formatters: [null, inputFormatters.defaultBlockParam],
    })
    this.getCode = this.buildRequest<[string, string?], string>({
      call: RPC_CALLS.eth_getCode,
      formatters: [null, inputFormatters.defaultBlockParam],
    })
    this.getStorageAt = this.buildRequest<[string, number, string?], string>({
      call: RPC_CALLS.eth_getStorageAt,
      formatters: [
        null,
        inputFormatters.blockNumberToHex,
        inputFormatters.defaultBlockParam,
      ],
    })
    this.getPastLogs = this.buildRequest<[PastLogsOptions], Log[]>({
      call: RPC_CALLS.eth_getLogs,
    })
    this.getBlockByHash = this.buildRequest<
      [string, boolean?],
      BlockTransactionString | BlockTransactionObject
    >({
      call: RPC_CALLS.eth_getBlockByHash,
      formatters: [null, inputFormatters.returnFullTxObjectParam],
    })
    this.getBlockByNumber = this.buildRequest<
      [BlockNumberArg, boolean?],
      BlockTransactionString | BlockTransactionObject
    >({
      call: RPC_CALLS.eth_getBlockByNumber,
      formatters: [
        inputFormatters.blockNumberToHex,
        inputFormatters.returnFullTxObjectParam,
      ],
    })
    this.getTransactionByHash = this.buildRequest<
      [string],
      Web3TransactionObject
    >({
      call: RPC_CALLS.eth_getTransactionByHash,
    })
    this.getTransactionReceipt = this.buildRequest<
      [string],
      Web3TransactionReceiptObject
    >({
      call: RPC_CALLS.eth_getTransactionReceipt,
    })
    this.getTransactionCount = this.buildRequest<[string, string?], string>({
      call: RPC_CALLS.eth_getTransactionCount,
      formatters: [null, inputFormatters.defaultBlockParam],
    })
    this.getGasPrice = this.buildRequest<never[], string>({
      call: RPC_CALLS.eth_gasPrice,
    })
    this.getEstimateGas = (transaction: TransactionConfig): Promise<number> =>
      this.buildRequest<[TransactionConfig], number>({
        call: RPC_CALLS.eth_estimateGas,
      })([transaction])
    this.setSafeSettings = this.buildRequest<[SafeSettings], SafeSettings>({
      call: RPC_CALLS.safe_setSettings,
    })
  }

  private buildRequest<P = never[], R = unknown>(args: BuildRequestArgs) {
    const { call, formatters } = args

    return async (params?: P): Promise<R> => {
      if (formatters && Array.isArray(params)) {
        formatters.forEach(
          (formatter: ((...args: unknown[]) => unknown) | null, i) => {
            if (formatter) {
              params[i] = formatter(params[i])
            }
          }
        )
      }

      const payload: RPCPayload<P> = {
        call,
        params: params || [],
      }

      const response = await this.communicator.send<
        Methods.rpcCall,
        RPCPayload<P>,
        R
      >(Methods.rpcCall, payload)

      return response.data
    }
  }
}

type GetBalanceParams = {
  currency?: string
}

const MAGIC_VALUE = '0x1626ba7e'
const MAGIC_VALUE_BYTES = '0x20c13b0b'

const EIP_1271_INTERFACE = new ethers.utils.Interface([
  'function isValidSignature(bytes32 _dataHash, bytes calldata _signature) external view',
])
const EIP_1271_BYTES_INTERFACE = new ethers.utils.Interface([
  'function isValidSignature(bytes calldata _data, bytes calldata _signature) public view',
])

class PermissionsError extends Error {
  public code: number
  public data?: unknown

  constructor(message: string, code: number, data?: unknown) {
    super(message)

    this.code = code
    this.data = data

    // Should adjust prototype manually because how TS handles the type extension compilation
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, PermissionsError.prototype)
  }
}
const PERMISSIONS_REQUEST_REJECTED = 4001

// Commented out due to issues with decorators in vite
// const hasPermission = (required: Methods, permissions: Permission[]): boolean =>
//   permissions.some((permission) => permission.parentCapability === required)
// const requirePermission =
//   () => (_: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
//     const originalMethod = descriptor.value

//     descriptor.value = async function () {
//       // @ts-expect-error accessing private property from decorator. 'this' context is the class instance
//       const wallet = new Wallet((this as Safe).communicator)

//       let currentPermissions = await wallet.getPermissions()

//       if (!hasPermission(propertyKey as Methods, currentPermissions)) {
//         currentPermissions = await wallet.requestPermissions([
//           { [propertyKey as Methods]: {} },
//         ])
//       }

//       if (!hasPermission(propertyKey as Methods, currentPermissions)) {
//         throw new PermissionsError(
//           'Permissions rejected',
//           PERMISSIONS_REQUEST_REJECTED
//         )
//       }

//       return originalMethod.apply(this)
//     }

//     return descriptor
//   }

class Safe {
  private readonly communicator: Communicator

  constructor(communicator: Communicator) {
    this.communicator = communicator
  }

  async getChainInfo(): Promise<ChainInfo> {
    const response = await this.communicator.send<
      Methods.getChainInfo,
      undefined,
      ChainInfo
    >(Methods.getChainInfo, undefined)

    return response.data
  }

  async getInfo(): Promise<SafeInfo> {
    console.log('GET SAFE INFO')
    const response = await this.communicator.send<
      Methods.getSafeInfo,
      undefined,
      SafeInfo
    >('getSafeInfo', undefined)

    return response.data
  }

  // There is a possibility that this method will change because we may add pagination to the endpoint
  async experimental_getBalances({
    currency = 'usd',
  }: GetBalanceParams = {}): Promise<SafeBalances> {
    const response = await this.communicator.send<
      Methods.getSafeBalances,
      { currency: string },
      SafeBalances
    >(Methods.getSafeBalances, {
      currency,
    })

    return response.data
  }

  private async check1271Signature(
    messageHash: string,
    signature = '0x'
  ): Promise<boolean> {
    const safeInfo = await this.getInfo()

    const encodedIsValidSignatureCall = EIP_1271_INTERFACE.encodeFunctionData(
      'isValidSignature',
      [messageHash, signature]
    )

    const payload = {
      call: RPC_CALLS.eth_call,
      params: [
        {
          to: safeInfo.safeAddress,
          data: encodedIsValidSignatureCall,
        },
        'latest',
      ],
    }
    try {
      const response = await this.communicator.send<
        Methods.rpcCall,
        RPCPayload<[TransactionConfig, string]>,
        string
      >(Methods.rpcCall, payload)

      return response.data.slice(0, 10).toLowerCase() === MAGIC_VALUE
    } catch (err) {
      return false
    }
  }

  private async check1271SignatureBytes(
    messageHash: string,
    signature = '0x'
  ): Promise<boolean> {
    const safeInfo = await this.getInfo()
    const msgBytes = arrayify(messageHash)

    const encodedIsValidSignatureCall =
      EIP_1271_BYTES_INTERFACE.encodeFunctionData('isValidSignature', [
        msgBytes,
        signature,
      ])

    const payload = {
      call: RPC_CALLS.eth_call,
      params: [
        {
          to: safeInfo.safeAddress,
          data: encodedIsValidSignatureCall,
        },
        'latest',
      ],
    }

    try {
      const response = await this.communicator.send<
        Methods.rpcCall,
        RPCPayload<[TransactionConfig, string]>,
        string
      >(Methods.rpcCall, payload)

      return response.data.slice(0, 10).toLowerCase() === MAGIC_VALUE_BYTES
    } catch (err) {
      return false
    }
  }

  calculateMessageHash(message: string): string {
    return ethers.utils.hashMessage(message)
  }

  calculateTypedMessageHash(typedMessage: EIP712TypedData): string {
    return ethers.utils._TypedDataEncoder.hash(
      typedMessage.domain,
      typedMessage.types,
      typedMessage.message
    )
  }

  async getOffChainSignature(messageHash: string): Promise<string> {
    const response = await this.communicator.send<
      Methods.getOffChainSignature,
      string,
      string
    >(Methods.getOffChainSignature, messageHash)

    return response.data
  }

  async isMessageSigned(
    message: string | EIP712TypedData,
    signature = '0x'
  ): Promise<boolean> {
    let check: (() => Promise<boolean>) | undefined
    if (typeof message === 'string') {
      check = async (): Promise<boolean> => {
        const messageHash = this.calculateMessageHash(message)
        const messageHashSigned = await this.isMessageHashSigned(
          messageHash,
          signature
        )
        return messageHashSigned
      }
    }

    if (isObjectEIP712TypedData(message)) {
      check = async (): Promise<boolean> => {
        const messageHash = this.calculateTypedMessageHash(message)
        const messageHashSigned = await this.isMessageHashSigned(
          messageHash,
          signature
        )
        return messageHashSigned
      }
    }
    if (check) {
      const isValid = await check()

      return isValid
    }

    throw new Error('Invalid message type')
  }

  async isMessageHashSigned(
    messageHash: string,
    signature = '0x'
  ): Promise<boolean> {
    const checks = [
      this.check1271Signature.bind(this),
      this.check1271SignatureBytes.bind(this),
    ]

    for (const check of checks) {
      const isValid = await check(messageHash, signature)
      if (isValid) {
        return true
      }
    }

    return false
  }

  async getEnvironmentInfo(): Promise<EnvironmentInfo> {
    const response = await this.communicator.send<
      Methods.getEnvironmentInfo,
      undefined,
      EnvironmentInfo
    >(Methods.getEnvironmentInfo, undefined)

    return response.data
  }

  // Commented out due to issues with decorators in vite
  // @requirePermission()
  // async requestAddressBook(): Promise<AddressBookItem[]> {
  //   const response = await this.communicator.send<
  //     Methods.requestAddressBook,
  //     undefined,
  //     AddressBookItem[]
  //   >(Methods.requestAddressBook, undefined)

  //   return response.data
  // }
}
type PermissionRequest = {
  [method: string]: Record<string, unknown>
}

enum RestrictedMethods {
  requestAddressBook = 'requestAddressBook',
}
class Wallet {
  private readonly communicator: Communicator

  constructor(communicator: Communicator) {
    this.communicator = communicator
  }

  async getPermissions(): Promise<Permission[]> {
    const response = await this.communicator.send<
      Methods.wallet_getPermissions,
      undefined,
      Permission[]
    >(Methods.wallet_getPermissions, undefined)

    return response.data
  }

  async requestPermissions(
    permissions: PermissionRequest[]
  ): Promise<Permission[]> {
    if (!this.isPermissionRequestValid(permissions)) {
      throw new PermissionsError(
        'Permissions request is invalid',
        PERMISSIONS_REQUEST_REJECTED
      )
    }

    try {
      const response = await this.communicator.send<
        Methods.wallet_requestPermissions,
        PermissionRequest[],
        Permission[]
      >(Methods.wallet_requestPermissions, permissions)

      return response.data
    } catch {
      throw new PermissionsError(
        'Permissions rejected',
        PERMISSIONS_REQUEST_REJECTED
      )
    }
  }

  isPermissionRequestValid(permissions: PermissionRequest[]): boolean {
    return permissions.every((pr: PermissionRequest) => {
      if (typeof pr === 'object') {
        return Object.keys(pr).every((method) => {
          if (
            Object.values(RestrictedMethods).includes(
              method as RestrictedMethods
            )
          ) {
            return true
          }

          return false
        })
      }

      return false
    })
  }
}

// eslint-disable-next-line
type Callback = (response: any) => void
type InterfaceMessageEvent = MessageEvent<Response>

type SDKRequestData<M extends Methods = Methods, P = unknown> = {
  id: RequestId
  params: P
  env: {
    sdkVersion: string
  }
  method: M
}

// i.e. 0-255 -> '00'-'ff'
const dec2hex = (dec: number): string => dec.toString(16).padStart(2, '0')

const generateId = (len: number): string => {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

const generateRequestId = (): string => {
  if (typeof window !== 'undefined') {
    return generateId(10)
  }

  return new Date().getTime().toString(36)
}

const getSDKVersion = (): string => {
  // Strip out version tags like `beta.0` in `1.0.0-beta.0`
  return '1.0.2'
}

type ErrorResponse = {
  id: RequestId
  success: false
  error: string
  version?: string
}

class MessageFormatter {
  static makeRequest = <M extends Methods = Methods, P = unknown>(
    method: M,
    params: P
  ): SDKRequestData<M, P> => {
    const id = generateRequestId()

    return {
      id,
      method,
      params,
      env: {
        sdkVersion: getSDKVersion(),
      },
    }
  }

  static makeResponse = (
    id: RequestId,
    data: MethodToResponse[Methods],
    version: string
  ): SuccessResponse => ({
    id,
    success: true,
    version,
    data,
  })

  static makeErrorResponse = (
    id: RequestId,
    error: string,
    version: string
  ): ErrorResponse => ({
    id,
    success: false,
    error,
    version,
  })
}

type Response<T = MethodToResponse[Methods]> =
  | ErrorResponse
  | SuccessResponse<T>
class InterfaceCommunicator implements Communicator {
  private readonly allowedOrigins: RegExp[] | null = null
  private callbacks = new Map<string, Callback>()
  private debugMode = false
  private isServer = typeof window === 'undefined'

  constructor(allowedOrigins: RegExp[] | null = null, debugMode = false) {
    this.allowedOrigins = allowedOrigins
    this.debugMode = debugMode

    if (!this.isServer) {
      window.addEventListener('message', this.onParentMessage)
    }
  }

  private isValidMessage = ({
    origin,
    data,
    source,
  }: InterfaceMessageEvent): boolean => {
    const emptyOrMalformed = !data
    const sentFromParentEl = !this.isServer && source === window.parent
    const majorVersionNumber =
      typeof data.version !== 'undefined' &&
      parseInt(data.version.split('.')[0])
    const allowedSDKVersion = majorVersionNumber
      ? majorVersionNumber >= 1
      : !!majorVersionNumber
    let validOrigin = true
    if (Array.isArray(this.allowedOrigins)) {
      validOrigin =
        this.allowedOrigins.find((regExp) => regExp.test(origin)) !== undefined
    }

    return (
      !emptyOrMalformed && sentFromParentEl && allowedSDKVersion && validOrigin
    )
  }

  private logIncomingMessage = (msg: InterfaceMessageEvent): void => {
    console.info(
      `Safe Apps SDK v1: A message was received from origin ${msg.origin}. `,
      msg.data
    )
  }

  private onParentMessage = (msg: InterfaceMessageEvent): void => {
    if (this.isValidMessage(msg)) {
      this.debugMode && this.logIncomingMessage(msg)
      this.handleIncomingMessage(msg.data)
    }
  }

  private handleIncomingMessage = (
    payload: InterfaceMessageEvent['data']
  ): void => {
    const { id } = payload

    const cb = this.callbacks.get(id)
    if (cb) {
      cb(payload)

      this.callbacks.delete(id)
    }
  }

  public send = <M extends Methods, P, R>(
    method: M,
    params: P
  ): Promise<SuccessResponse<R>> => {
    const request = MessageFormatter.makeRequest(method, params)

    if (this.isServer) {
      throw new Error("Window doesn't exist")
    }

    window.parent.postMessage(request, '*')
    return new Promise((resolve, reject) => {
      this.callbacks.set(request.id, (response: Response<R>) => {
        if (!response.success) {
          reject(new Error((response as ErrorResponse).error))
          return
        }

        resolve(response)
      })
    })
  }
}
class SafeAppsSDK {
  private readonly communicator: Communicator
  public readonly eth: Eth
  public readonly txs: TXs
  public readonly safe: Safe
  public readonly wallet: Wallet

  constructor(opts: Opts = {}) {
    const { allowedDomains = null, debug = false } = opts

    this.communicator = new InterfaceCommunicator(allowedDomains, debug)
    this.eth = new Eth(this.communicator)
    this.txs = new TXs(this.communicator)
    this.safe = new Safe(this.communicator)
    this.wallet = new Wallet(this.communicator)
  }
}
interface Web3TransactionObject {
  hash: string
  nonce: number
  blockHash: string | null
  blockNumber: number | null
  transactionIndex: number | null
  from: string
  to: string | null
  value: string
  gasPrice: string
  gas: number
  input: string
}
function getLowerCase(value: string): string {
  if (value) {
    return value.toLowerCase()
  }
  return value
}

class SafeAppProvider extends EventEmitter implements EIP1193Provider {
  private readonly safe: SafeInfo
  private readonly sdk: SafeAppsSDK
  private submittedTxs = new Map<string, Web3TransactionObject>()

  constructor(safe: SafeInfo, sdk: SafeAppsSDK) {
    super()
    this.safe = safe
    this.sdk = sdk
  }

  async connect(): Promise<void> {
    this.emit('connect', { chainId: this.chainId })
    return
  }

  async disconnect(): Promise<void> {
    return
  }

  public get chainId(): number {
    return this.safe.chainId
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async request(request: { method: string; params?: any[] }): Promise<any> {
    const { method, params = [] } = request

    switch (method) {
      case 'eth_accounts':
        return [this.safe.safeAddress]

      case 'net_version':
      case 'eth_chainId':
        return `0x${this.chainId.toString(16)}`

      case 'personal_sign': {
        const [message, address] = params

        if (this.safe.safeAddress.toLowerCase() !== address.toLowerCase()) {
          throw new Error('The address or message hash is invalid')
        }

        const response = await this.sdk.txs.signMessage(message)
        const signature =
          'signature' in response ? response.signature : undefined

        return signature || '0x'
      }

      case 'eth_sign': {
        const [address, messageHash] = params

        if (
          this.safe.safeAddress.toLowerCase() !== address.toLowerCase() ||
          !messageHash.startsWith('0x')
        ) {
          throw new Error('The address or message hash is invalid')
        }

        const response = await this.sdk.txs.signMessage(messageHash)
        const signature =
          'signature' in response ? response.signature : undefined

        return signature || '0x'
      }

      case 'eth_signTypedData':
      case 'eth_signTypedData_v4': {
        const [address, typedData] = params
        const parsedTypedData =
          typeof typedData === 'string' ? JSON.parse(typedData) : typedData

        if (this.safe.safeAddress.toLowerCase() !== address.toLowerCase()) {
          throw new Error('The address is invalid')
        }

        const response = await this.sdk.txs.signTypedMessage(parsedTypedData)
        const signature =
          'signature' in response ? response.signature : undefined
        return signature || '0x'
      }

      case 'eth_sendTransaction':
        const tx = {
          value: '0',
          data: '0x',
          ...params[0],
        }

        // Some ethereum libraries might pass the gas as a hex-encoded string
        // We need to convert it to a number because the SDK expects a number and our backend only supports
        // Decimal numbers
        if (typeof tx.gas === 'string' && tx.gas.startsWith('0x')) {
          tx.gas = parseInt(tx.gas, 16)
        }

        const resp = await this.sdk.txs.send({
          txs: [tx],
          params: { safeTxGas: tx.gas },
        })

        // Store fake transaction
        this.submittedTxs.set(resp.safeTxHash, {
          from: this.safe.safeAddress,
          hash: resp.safeTxHash,
          gas: 0,
          gasPrice: '0x00',
          nonce: 0,
          input: tx.data,
          value: tx.value,
          to: tx.to,
          blockHash: null,
          blockNumber: null,
          transactionIndex: null,
        })
        return resp.safeTxHash

      case 'eth_blockNumber':
        const block = await this.sdk.eth.getBlockByNumber(['latest']) // eslint-disable-line no-case-declarations

        return block.number

      case 'eth_getBalance':
        return this.sdk.eth.getBalance([getLowerCase(params[0]), params[1]])

      case 'eth_getCode':
        return this.sdk.eth.getCode([getLowerCase(params[0]), params[1]])

      case 'eth_getTransactionCount':
        return this.sdk.eth.getTransactionCount([
          getLowerCase(params[0]),
          params[1],
        ])

      case 'eth_getStorageAt':
        return this.sdk.eth.getStorageAt([
          getLowerCase(params[0]),
          params[1],
          params[2],
        ])

      case 'eth_getBlockByNumber':
        return this.sdk.eth.getBlockByNumber([params[0], params[1]])

      case 'eth_getBlockByHash':
        return this.sdk.eth.getBlockByHash([params[0], params[1]])

      case 'eth_getTransactionByHash':
        let txHash = params[0] // eslint-disable-line no-case-declarations
        try {
          const resp = await this.sdk.txs.getBySafeTxHash(txHash)
          txHash = resp.txHash || txHash
        } catch (e) {} // eslint-disable-line no-empty
        // Use fake transaction if we don't have a real tx hash
        if (this.submittedTxs.has(txHash)) {
          return this.submittedTxs.get(txHash)
        }
        return this.sdk.eth.getTransactionByHash([txHash]).then((tx) => {
          // We set the tx hash to the one requested, as some provider assert this
          if (tx) {
            tx.hash = params[0]
          }
          return tx
        })

      case 'eth_getTransactionReceipt': {
        let txHash = params[0]
        try {
          const resp = await this.sdk.txs.getBySafeTxHash(txHash)
          txHash = resp.txHash || txHash
        } catch (e) {} // eslint-disable-line no-empty
        return this.sdk.eth.getTransactionReceipt([txHash]).then((tx) => {
          // We set the tx hash to the one requested, as some provider assert this
          if (tx) {
            tx.transactionHash = params[0]
          }
          return tx
        })
      }

      case 'eth_estimateGas': {
        return this.sdk.eth.getEstimateGas(params[0])
      }

      case 'eth_call': {
        return this.sdk.eth.call([params[0], params[1]])
      }

      case 'eth_getLogs':
        return this.sdk.eth.getPastLogs([params[0]])

      case 'eth_gasPrice':
        return this.sdk.eth.getGasPrice()

      case 'wallet_getPermissions':
        return this.sdk.wallet.getPermissions()

      case 'wallet_requestPermissions':
        return this.sdk.wallet.requestPermissions(params[0])

      case 'safe_setSettings':
        return this.sdk.eth.setSafeSettings([params[0]])

      default:
        throw Error(`"${request.method}" not implemented`)
    }
  }

  // this method is needed for ethers v4
  // https://github.com/ethers-io/ethers.js/blob/427e16826eb15d52d25c4f01027f8db22b74b76c/src.ts/providers/web3-provider.ts#L41-L55
  send(request: any, callback: (error: any, response?: any) => void): void {
    if (!request) callback('Undefined request')
    this.request(request)
      .then((result) =>
        callback(null, { jsonrpc: '2.0', id: request.id, result })
      )
      .catch((error) => callback(error, null))
  }
}

export class NoSafeContext extends Error {
  public constructor() {
    super('The app is loaded outside safe context')
    this.name = NoSafeContext.name
    Object.setPrototypeOf(this, NoSafeContext.prototype)
  }
}

/**
 * @param options - Options to pass to `@safe-global/safe-apps-sdk`.
 */
export interface GnosisSafeConstructorArgs {
  actions: Actions
  options?: Opts
}

export class GnosisSafe extends Connector {
  /** {@inheritdoc Connector.provider} */
  public declare provider?: SafeAppProvider

  private readonly options?: Opts
  private eagerConnection?: Promise<void>

  /**
   * A `SafeAppsSDK` instance.
   */
  public sdk: SafeAppsSDK | undefined

  constructor({ actions, options }: GnosisSafeConstructorArgs) {
    super(actions)
    this.options = options
  }

  /**
   * A function to determine whether or not this code is executing on a server.
   */
  private get serverSide() {
    return typeof window === 'undefined'
  }

  /**
   * A function to determine whether or not this code is executing in an iframe.
   */
  private get inIframe() {
    if (this.serverSide) return false
    if (window !== window.parent) return true
    return false
  }

  private async isomorphicInitialize(): Promise<void> {
    if (this.eagerConnection) return

    this.sdk = new SafeAppsSDK(this.options)

    const safe = await Promise.race([
      this.sdk.safe.getInfo(),
      new Promise<undefined>((resolve) => setTimeout(resolve, 500)),
    ])

    if (safe) {
      this.provider = new SafeAppProvider(safe, this.sdk)
    }

    this.eagerConnection = Promise.resolve()
  }

  /** {@inheritdoc Connector.connectEagerly} */
  public async connectEagerly(): Promise<void> {
    if (!this.inIframe) return

    const cancelActivation = this.actions.startActivation()

    try {
      await this.isomorphicInitialize()
      if (!this.provider) throw new NoSafeContext()

      this.actions.update({
        chainId: this.provider.chainId,
        accounts: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          await this.sdk!.safe.getInfo().then(({ safeAddress }) => safeAddress),
        ],
      })
    } catch (error) {
      cancelActivation()
      throw error
    }
  }

  public async activate(): Promise<void> {
    if (!this.inIframe) throw new NoSafeContext()

    // only show activation if this is a first-time connection
    let cancelActivation: () => void
    if (!this.sdk) cancelActivation = this.actions.startActivation()

    return this.isomorphicInitialize()
      .then(async () => {
        if (!this.provider) throw new NoSafeContext()

        this.actions.update({
          chainId: this.provider.chainId,
          accounts: [
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await this.sdk!.safe.getInfo().then(
              ({ safeAddress }) => safeAddress
            ),
          ],
        })
      })
      .catch((error) => {
        cancelActivation?.()
        throw error
      })
  }
}
