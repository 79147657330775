import { AppMode } from '@/constants/topMenu'

export interface TopMenuState {
  isOpen: boolean
  activeSubmenuItem: string
  activeMenuItem: string
  activeTab: string
  appMode: AppMode
}

export interface TopMenuContextProps extends TopMenuState {
  toggleMenu: (open: boolean) => void
  setActiveSubmenuItem: (activeSubmenuItem: string) => void
  setActiveMenuItem: (activeMenuItem: string) => void
  setActiveTab: (activeTab: string) => void
  setAppMode: (appMode: AppMode) => void
}

export enum TopMenuActionTypes {
  TOGGLE_MENU = 'TOGGLE_MENU',
  SET_ACTIVE_SUBMENU_ITEM = 'SET_ACTIVE_SUBMENU_ITEM',
  SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  SET_APP_MODE = 'SET_APP_MODE',
}

export type TopMenuAction =
  | { type: TopMenuActionTypes.TOGGLE_MENU; payload: boolean }
  | { type: TopMenuActionTypes.SET_ACTIVE_SUBMENU_ITEM; payload: string }
  | { type: TopMenuActionTypes.SET_ACTIVE_MENU_ITEM; payload: string }
  | { type: TopMenuActionTypes.SET_ACTIVE_TAB; payload: string }
  | { type: TopMenuActionTypes.SET_APP_MODE; payload: AppMode }
